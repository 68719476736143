<template lang="pug">
.text-background.text-effect
  BaseSwitch.text-background__switch.effect__switch(
    name="text-background"
    :is-checked="isEnabled('text-bg')"
    @change="toggleEnableTextBackground('text-bg')"
    label="Text Bg"
  )
  VueSlideUpDown.text-background__content.effect__content(
    :class="{'effect-content--enabled': isEnabled('text-bg')}"
    :active="true"
  )
    .text-background__color.effect__color
      span.title Colour
      button.color.btn-add-color(
        :class="{ 'is-active': isTextBackground }"
        type="button"
        :style="colorStyle"
        @click="openColorPicker"
      )
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="200"
      title="Width"
      :value="textX"
      @blur="onBlurInput($event, 'x')"
      @update="updateTextX"
    )
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="200"
      title="Height"
      :value="textY"
      @blur="onBlurInput($event, 'y')"
      @update="updateTextY"
    )
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="100"
      title="Rounding"
      :value="borderRadius"
      @blur="onBlurInput($event, 'borderRadius')"
      @update="updateBorderRadius"
    )
    //- Transparency 100 = Opacity 0
    //- they are inverse
    BaseSlider.effects__base-slider(
      is-dark=true
      :class="{ 'is-disabled': isGradient }"
      :min="0"
      :max="100"
      title="Transparent"
      :value="opacity"
      @blur="onBlurInput($event, 'opacity', '%')"
      @update="updateOpacity"
      suffix="%"
    )

    BaseButton.effects__reset-button(
      :is-canvas="true"
      is-dark=true
      is-full-width=true
      @click="resetTextBg"
    ) Reset

    p.smaller.text-bg-transparent-text(
      v-if="isGradient"
    ) Transparent is only available for solid background
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import VueSlideUpDown from 'vue-slide-up-down';
import { textDataFormat } from '@/assets/scripts/variables';
import effectsMixin from '@/components/mixins/effects-mixins';

export default {
  name: 'TextBackground',
  components: {
    VueSlideUpDown,
  },
  mixins: [effectsMixin],
  computed: {
    ...mapState(['isTextBackground']),
    isGradient() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type !== 'texts') return false;
      return this.getActiveElements[0].data.textBackground.color === 'object';
    },
    textX() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type !== 'texts') return false;
      return this.getActiveElements[0].data.textBackground.x;
    },
    textY() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type !== 'texts') return false;
      return this.getActiveElements[0].data.textBackground.y;
    },
    borderRadius() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type !== 'texts') return false;
      return this.getActiveElements[0].data.textBackground.borderRadius;
    },
    opacity() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type !== 'texts') return false;
      const opacity = this.getActiveElements[0].data.textBackground.opacity * 100;
      return Math.floor(100 - opacity);
    },
    colorStyle() {
      const styleObject = {};

      let background = '#0284FE';

      if (this.getActiveElements.length && this.getActiveElements[0].type === 'texts') {
        const { color } = this.getActiveElements[0].data.textBackground;
        const isGradient = typeof color === 'object';

        if (isGradient) {
          const { points, degree } = color;
          background = `linear-gradient(${degree}deg`;

          points.forEach((point) => {
            background += `, ${point.color} ${point.percentage}%`;
          });

          background += ')';
        } else if (color.color) {
          background = color.color;
        } else {
          background = color;
        }
      }

      styleObject.background = background;

      return styleObject;
    },
  },
  mounted() {
    if (!this.isEnabled('text-bg')) {
      this.toggleEnableTextBackground('text-bg');
    }
  },
  methods: {
    ...mapMutations('canvasElements', [
      'updateCanvasElementTextBackgroundX',
      'updateCanvasElementTextBackgroundY',
    ]),
    updateBorderRadius(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        // eslint-disable-next-line
        this.updateCanvasElementTextBackground({ borderRadius: parseInt(value) });
        this.$root.$emit('canvasElementSizeUpdated');
      }
    },
    updateOpacity(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        const opacity = (100 - parseInt(value, 10)) / 100;
        this.updateCanvasElementTextBackground({ opacity });
        this.$root.$emit('canvasElementSizeUpdated');
      }
    },
    updateTextX(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        // eslint-disable-next-line
        this.updateCanvasElementTextBackgroundX(parseInt(value));
        this.$root.$emit('canvasElementSizeUpdated');
      }
    },
    updateTextY(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        // eslint-disable-next-line
        this.updateCanvasElementTextBackgroundY(parseInt(value));
        this.$root.$emit('canvasElementSizeUpdated');
      }
    },
    onBlurInput(event, type, suffix) {
      this.onBlurEffectInput(event, {
        effect: 'textBackground',
        selectedInput: type,
        suffix,
      });
    },
    openColorPicker() {
      this.setIsTextBackground(true);
      this.$emit('open-colorpicker');
    },
    resetTextBg() {
      const elementId = this.getActiveElements[0].data.id || null;
      const colorObject = {
        id: elementId,
        color: [textDataFormat.textBackground.color],
        silent: true,
      };

      this.setIsTextBackground(true);

      // didn't use async await because with it the 'updateCanvasElementColor' mutation still not receive 'isTextBackground' properly.
      setTimeout(() => {
        this.catchHistory('element');
        if (elementId) this.updateCanvasElementColor(colorObject);

        this.updateCanvasElementTextBackgroundX(textDataFormat.textBackground.x);
        this.updateCanvasElementTextBackgroundY(textDataFormat.textBackground.y);
        this.updateCanvasElementTextBackground({
          opacity: textDataFormat.textBackground.opacity,
          borderRadius: textDataFormat.textBackground.borderRadius,
        });
        this.$root.$emit('canvasElementSizeUpdated');
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-bg-transparent-text {
  background: $darkGrey600;
  color: $light;
  font-size: 0.75rem;
  margin-top: 12px;
  font-weight: 400;
  padding: 15px;
  border-radius: 5px;
  line-height: 1.5;
}
</style>
