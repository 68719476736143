<template lang="pug">
BaseButton.control-general__layer-button(
  :icon-before="icon"
  :is-canvas="true"
  @click="changeLayerElement"
) {{ title }}
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'LayerButton',
  props: {
    icon: { type: String, default: '' },
    title: { type: String, default: '' },
    direction: { type: String, default: '' },
  },
  methods: {
    ...mapMutations('canvasElements', ['updateCanvasElementIndex']),
    changeLayerElement() {
      this.updateCanvasElementIndex(this.direction);
    },
  },
};
</script>

<style lang="scss"></style>
