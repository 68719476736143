<template lang="pug">
.category-container
  template(v-if="!loader")
    ul.grid-lists
      .col-1of2.col-half
        Category(
          v-for="(category, index) in evenCategories"
          :key="`${index}-${category.name}`"
          :id='category.id'
          :name='category.name'
          :previews='category.items'
          :slug='category.slug'
          :image='category.background_image'
          :meta='category.category_meta'
        )
      .col-2of2.col-half
        Category(
          v-for="(category, index) in oddCategories"
          :key="`${index}-${category.name}`"
          :id='category.id'
          :name='category.name'
          :previews='category.items'
          :slug='category.slug'
          :image='category.background_image'
          :meta='category.category_meta'
        )
  template(v-else)
    BasePreloader(
      :type=8
    )
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Category from './children/Category.vue';

export default {
  name: 'CategoryContainer',
  components: {
    Category,
  },
  props: {
    elements: { type: Object, default: () => ({}) },
    loader: { type: Boolean },
  },
  computed: {
    ...mapState(['showSwap']),
    ...mapGetters('canvasElements', ['getSwappedElement']),
    categoriesWithItems() {
      if (this.elements && this.elements.categories && this.elements.categories.length) {
        if (this.swappedElementType === 'shapes') {
          return this.elements.categories.filter(
            category => category.total_items > 0
              && category.category_meta.findIndex(cm => cm.key === 'is_shape' && cm.value) > -1,
          );
        }
        return this.elements.categories.filter(category => category.total_items > 0);
      }
      return [];
    },
    evenCategories() {
      return this.categoriesWithItems.filter((category, index) => index % 2 === 0);
    },
    oddCategories() {
      return this.categoriesWithItems.filter((category, index) => index % 2 !== 0);
    },
    swappedElementType() {
      if (this.showSwap) return this.getSwappedElement.type;
      return null;
    },
  },
};
</script>

<style lang="scss">
.category-container {
  height: 100%;

  .tab__head + & {
    height: calc(100% - 42px);
  }
}
</style>
