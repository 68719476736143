<template lang="pug">
AssetsContainer(
  :selected-upload-space="selectedUploadSpace"
  @do-select-file="$emit('do-select-file', $event)"
)
  template(v-slot:navigation)
    AssetsNavigation

  template(v-slot:search-bar, v-if="hasSearchBar")
    AssetsSearch(@is-searching="setIsSearching")

  template(v-slot:music-categories, v-if="getActiveGroup === 'music'")
    BaseButton.btn-view-music-category(
      v-if="getActiveSubGroup !== 'upload' && !isOnMusicTeamUploadsTab"
      :is-dark="true"
      :is-tall="true"
      :is-canvas="true"
      @click="openMusicCategories"
    ) Categories
    .music__current-track-container(v-if="getSelectedMusic.title")
      p.current-track__header Current Track :
      p.current-track__value(:class="{ 'has-new-track': hasNewTrack }") {{ getSelectedMusic.title }}
      button.icon-cross-line(type="button", @click="removeMusic")
    .music__uploading-loader(v-if="isUploadingMusicFile && getActiveSubGroup === 'upload'")
      .loader-header
        i.header-icon.icon-music
        h4.header-title Uploading Track ...
      BasePreloader.loader(
        :type="12"
        :width="35"
      )

  template(v-slot:favourite-filter, v-if="getActiveGroup === 'favourites'")
    FavouritesFilterNav

  template(v-slot:branded-asset-container, v-if="getActiveGroup === 'style'")
    BrandedAssetContainer

  template(v-slot:shortcut-container, v-if="getActiveGroup === 'shortcut'")
    ShortcutContainer

  template(v-slot:brand-tab, v-if="isOnBrandTab")
    BrandTab(@do-select-file="$emit('do-select-file', $event)")

  template(v-slot:sub-groups, v-if="hasSubGroups && !isOnBrandTab")
    SubGroups

  template(v-slot:category, v-if="hasCategories")
    CategoryItemLiteContainer(v-if="isLiteMode")
    template(v-else-if="getActiveGroupings.group !== 'designs'")
      CategoryContainer(
        :elements="getSidebarCategories[getActiveSubGroup]",
        :loader="showLoader"
      )

  template(v-slot:category-items="categoryItemsProps", v-if="hasCategoryItems")
    template(v-if="isShowShutterstockGuidelines && !isLiteMode")
      .shutterstock-credits__container
        .credits__overlay
        .credits__container
          p.credits
            | {{ creditsGroupPluralText }} by&nbsp;
            a(
              href="https://shutterstock.com/"
              target="_blank"
            ) Shutterstock

        .shutterstock-tooltip-box__wrapper
          button.icon.shutterstock-toggler(
            type="button",
            title="View Guidelines",
            @click="isShowShutterstockCredit = !isShowShutterstockCredit"
          )
            svg(
              width="19",
              height="19",
              viewBox="0 0 21 21",
              fill="none",
              xmlns="http://www.w3.org/2000/svg"
            )
              circle(cx="10.5682", cy="10.5682", r="10.0682", stroke="#E1E4E8")
              path(
                d="M10.5665 8.22629C9.99988 8.22629 9.71655 7.98296 9.71655 7.49629C9.71655 7.00296 9.99988 6.75629 10.5665 6.75629C11.1332 6.75629 11.4165 7.00296 11.4165 7.49629C11.4165 7.98296 11.1332 8.22629 10.5665 8.22629ZM11.2465 13.9463H9.87655V8.94629H11.2465V13.9463Z"
                fill="#E1E4E8"
              )

          .tooltip-box(
            :class="{ 'is-show': isShowShutterstockCredit }",
            v-click-outside="onClickOutsideTooltip"
          )
            button.icon-cross-line.tooltip__close-icon(
              type="button"
              title="Close Guidelines"
              @click="isShowShutterstockCredit = false"
            )
            .tooltip
              h3 Stock {{ creditsGroupSingularText }} Use Guidelines
              div
                | Shutterstock {{ creditsGroupPluralText }} are available for use at no additional cost in your
                | OFFEO video, with the following guidelines:
              ul
                li Content is for digital use within OFFEO only and may not be used for printing.
                li You may not use the content as a trademark for a business.
                li
                  | You may not portray a person in a way that may be offensive, including: in connection with adult-oriented services
                  | or ads for dating services; in connection with the promotion of tobacco products; in connection with
                  | political endorsements; with pornographic, defamatory, unlawful, offensive or immoral content; and as suffering from,
                  | or being treated for, a physical or mental ailment.
                li You may only use the content in campaigns and content created on OFFEO, and not with other website or content services.

    template(v-else-if="isShowUnsplashCredit || isShowPixabayCredit")
      .media-credits__container
        .credits__overlay
        .credits__container
          p.credits
            | {{ isShowUnsplashCredit ? 'Photos' : 'Videos' }} by&nbsp;
            a(
              v-if="isShowUnsplashCredit"
              href="https://unsplash.com/"
              target="_blank"
            ) Unsplash
            a(
              v-else
              href="https://pixabay.com/"
              target="_blank"
            ) Pixabay

    template(
      v-if="!isLiteMode && isMyFilesCategory && !showMoveMultipleFilesContainer"
    )
      .personal-team-uploads__toggler(v-if="isInTeamWorkspace")
        BaseButton.uploads-space-dropdown__button(
          :is-canvas="true"
          :icon-after="uploadsDropdownIconAfter"
          @click="toggleUploadsDropdown"
        ) {{ selectedUploadSpaceText }}

        .uploads-space-dropdown(
          v-if="isShowUploadsDropdown"
          v-click-outside="closeUploadsDropdown"
        )
          .uploads-dropdown__item
            BaseButton.my-uploads__button(
              :class="{ 'is-active': selectedUploadSpace === 'personal' }"
              :is-canvas="true"
              :is-text="true"
              :plain-hover="true"
              @click="setSelectedUploadSpace('personal')"
            ) My Uploads
            BaseButton.icon-checked(
              v-if="selectedUploadSpace === 'personal'"
              :is-icon="true"
              :plain-hover="true"
            )
          span.divider-line
          .uploads-dropdown__item
            BaseButton.team-uploads__button(
              :class="{ 'is-active': selectedUploadSpace === 'team' }"
              :is-canvas="true"
              :is-text="true"
              :plain-hover="true"
              @click="setSelectedUploadSpace('team')"
            ) Team Uploads
            BaseButton.icon-checked(
              v-if="selectedUploadSpace === 'team'"
              :is-icon="true"
              :plain-hover="true"
            )

      .my-file__tabs
        BaseButton.tab__item(
          v-for="tab in myFilesTab"
          :key="tab.value"
          :class="{ 'is-active': fileActiveTab === tab.value }"
          :is-text="true"
          :plain-hover="true"
          @click="changeFilesTab(tab.value)"
        ) {{ tab.name }}

    //- .media-option__tabs(v-if="isOnMediaTab && !isMediaMaskTab")
    //-     BaseButton.tab__item(
    //-       v-for="tab in mediaOption[getActiveSubGroup]"
    //-       :key="tab.value"
    //-       :class="{ 'is-active': getActiveMediaOption === tab.value }"
    //-       :is-text="true"
    //-       :plain-hover="true"
    //-       @click="changeMediaOption(tab.value)"
    //-     ) {{ tab.name }}

    CategoryItemLiteContainer(v-if="isLiteMode && !isOnMusicTab")
    CategoryItemContainer(
      v-else,
      :file-active-tab="fileActiveTab"
      :elements="categoryItemElements"
      :loader="showLoader"
      :totalPages="totalStockPages"
      :currentPage="currentPage"
      :is-unsplash-image="isShowUnsplashCredit"
      :selected-upload-space="selectedUploadSpace"
      @endScroll="checkMorePage"
      @select-folder="selectFolder"
      :class="{ 'is-multi-selecting': isMultiSelecting }"
    )

  template(
    v-slot:my-file-action-buttons,
    v-if="isMyFilesCategory && !showMoveMultipleFilesContainer"
  )
    MyFileActionButtons
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import { debounce } from 'debounce';
import { isEmptyObject } from '@/assets/scripts/utilities';
import { groups, subgroups } from '@/assets/scripts/enums';

import CollectionsApi from '@/services/api/CollectionsApi';
import UserApi from '@/services/api/UserApi';
import TemplateApi from '@/services/api/TemplateApi';
import userFilesAndFolderMixins from '@/components/mixins/user-files-and-folder-mixins';

import AssetsSearch from './children/AssetsSearch.vue'; // eslint-disable-line
import AssetsContainer from '@/components/ProjectCanvas/AssetsSidebar/AssetsContainer.vue';
import AssetsNavigation from '@/components/ProjectCanvas/AssetsSidebar/AssetsNavigation.vue';
import SubGroups from '@/components/ProjectCanvas/AssetsSidebar/SubGroups.vue';
import CategoryContainer from '@/components/ProjectCanvas/AssetsSidebar/CategoryContainer.vue';
import CategoryItemLiteContainer from '@/components/ProjectCanvas/AssetsSidebar/CategoryItemLiteContainer.vue';
import CategoryItemContainer from '@/components/ProjectCanvas/AssetsSidebar/CategoryItemContainer.vue';
import FavouritesFilterNav from './children/FavouritesFilterNav.vue'; // eslint-disable-line
import MyFileActionButtons from './children/MyFileActionButtons.vue'; // eslint-disable-line
import BrandTab from '@/components/ProjectCanvas/AssetsSidebar/BrandTab.vue';
import ShortcutContainer from './children/ShortcutContainer.vue';
import BrandedAssetContainer from './children/BrandedAssetContainer.vue';

export default {
  name: 'AssetsSidebar',
  components: {
    AssetsSearch,
    AssetsContainer,
    AssetsNavigation,
    SubGroups,
    CategoryContainer,
    CategoryItemLiteContainer,
    CategoryItemContainer,
    FavouritesFilterNav,
    MyFileActionButtons,
    ShortcutContainer,
    BrandTab,
    BrandedAssetContainer,
  },
  mixins: [userFilesAndFolderMixins],
  data() {
    return {
      hasSearchBar: false,
      hasSubGroups: false,
      hasCategories: false,
      hasCategoryItems: true,
      showLoader: true,
      currentPage: 1,
      isRequestingApi: false,
      showMusic: true,
      totalPages: {
        photo: 1,
        video: 1,
        music: 1,
        images: 1,
        myvideos: 1,
        teamImages: 1,
        teamVideos: 1,
      },
      isMultiSelecting: false,
      hasRemovedBackground: false,
      isShowShutterstockCredit: false,
      myFilesTab: [
        { name: 'All files', value: 'files' },
        { name: 'Folders', value: 'folders' },
      ],
      mediaOption: {
        photo: [
          // { name: 'Shutterstock', value: 'photo-shutterstock' },
          { name: 'Unsplash', value: 'photo-unsplash' },
        ],
        video: [
          // { name: 'Shutterstock', value: 'video-shutterstock' },
          { name: 'Pixabay', value: 'video-pixabay' },
        ],
      },
      fileActiveTab: 'files',
      isShowUploadsDropdown: false,
      selectedUploadSpace: 'personal',
      hasNewTrack: false,
      isSearching: false,
    };
  },
  methods: {
    ...mapMutations([
      'setShowMusicCategories',
      'setShowDesignContainer',
      'setShowSidebar',
      'setShowTrim',
      'setShowPermanentTrimElement',
    ]),
    ...mapMutations('assetsSidebar', [
      'setActiveCategory',
      'clearActiveCategory',
      'setActiveFileCategory',
      'addElementToSidebar',
      'addCategoriesToSidebar',
      'setUserFolders',
      'addNewUserFolder',
      'setIsMultipleFileSelection',
      'setActiveSubGroup',
      'setShowDesignPreloader',
      'setBrandedDesignColors',
      'setActiveGroup',
      'setActiveMediaOption',
      'setShutterstockSearchId',
      'setMultipleSelectedFiles',
      'setShowMoveMultipleFilesContainer',
      'resetActiveMediaElements',
      'setTeamFolders',
      'setTeamMusicFoldersSearchResult',
      'setActiveKeyword',
    ]),
    ...mapMutations('canvasLayouts', ['setCanvasSectionPaddingRight']),
    ...mapMutations('canvasElements', ['updateSelectedMusic']),
    ...mapActions(['setUserFavourites']),
    checkMorePage(resetSearch = false) {
      // console.log('checkMorePage', resetSearch, this.isRequestingApi);
      if (this.isRequestingApi || this.showLoader) return;
      const { group, subgroup } = this.getActiveGroupings;

      if (
        group !== groups.MEDIA
        && group !== groups.MUSIC
        && group !== groups.MYFILES
      ) {
        return;
      }
      if (
        subgroup !== subgroups.VIDEOS
        && subgroup !== subgroups.PHOTOS
        && subgroup !== subgroups.IMAGES
        && !(this.getStockEndpointVersion === 'v2' && group === subgroups.MUSIC)
      ) return;
      if (this.currentPage >= this.totalStockPages) {
        return;
      }

      // Call next page if it's Stock Tab
      // Add to the existing getSidebarElemetns
      if (resetSearch) this.currentPage = 1;
      else this.currentPage += 1;
      this.getElements();
    },
    setCurrentPage() {
      if (this.categoryItemElements && this.categoryItemElements.length) {
        if ((this.isOnMediaTab && !this.isMediaMaskTab) || this.isOnMusicTab) {
          const page = Math.round(this.categoryItemElements.length / this.stockItemPerPage);
          this.currentPage = page;
        } else {
          this.currentPage = 1;
        }
      } else {
        this.currentPage = 1;
      }
    },
    // eslint-disable-next-line func-names
    getElements: debounce(function (_forceReload = false) {
      let forceReload = _forceReload;
      let apiVersion = 'v1';
      // when user click on remove background and user went back to upload tab, force reload to show the latest removed bg image
      if (this.hasRemovedBackground && this.isOnImageUploadTab) {
        forceReload = true;
      }
      if (this.currentPage === 1 || forceReload) this.showLoader = true;
      // Get data for:
      // Sub groups '/api/get-category-lists/${group}/${subgroup}'
      let { keyword } = this.getActiveGroupings;
      const { group, subgroup, category } = this.getActiveGroupings;
      let { slug } = category;

      console.log('getElements current page is', this.currentPage, slug, keyword, this.showLoader);

      const params = {};
      let endpoint = '';
      const responseObjectKey = 'results';

      /*
        TYPE: BRANDED DESIGNS COLLECTION - Layout category
        endpoint: branded-collections/designs?type=template
       */
      if (group === groups.BRANDEDDESIGNS) {
        // design listing is done in getDesigns()
        return;
      }

      /*
        TYPE: DESIGNS COLLECTION
        endpoint: collections/designs?type=template
       */
      if (group === groups.DESIGNS) {
        // design listing is done in getDesigns()
        return;
      }

      /*
        TYPE: TEXT TEMPLATE COLLECTION
        endpoint: collections/designs?type=text
       */
      if (group === groups.TEXTS) {
        endpoint = 'designs';
        params.type = 'text';
        params.page = 1;
        params.item_per_page = 100;
      }

      /*
        TYPE: MUSIC COLLECTION
        endpoint: collections/music?menu=upload
       */
      if (group === groups.MUSIC) {
        endpoint = 'music';

        if (subgroup === subgroups.UPLOAD) {
          apiVersion = 'v2';
          // change endpoint to get into user-files
          // endpoint: collections/user-files?menu=audio
          endpoint = 'user-files';
          params.menu = 'audio';
        } else if (subgroup === subgroups.TEAM_UPLOAD) {
          apiVersion = 'v2';
          // change endpoint to get into team-files
          // endpoint: collections/team-files?menu=audio
          endpoint = 'team-files';
          params.menu = 'audio';
          params.team_id = this.teamId;
          params.folder_id = this.activeFolder;

          if (!keyword) {
            keyword = '';
            slug = 'all';
          } else {
            slug = keyword;
          }
        } else {
          /* eslint-disable */
          if (this.getStockEndpointVersion === 'v2') {
            params.item_per_page = this.stockItemPerPage;
            params.type = 'audio';
            params.page = this.currentPage;
            slug = category.id > 0 ? category.id : keyword;

            if (!slug) {
              keyword = 'uplifting';
              slug = keyword;
            }
            // console.log('keyword', keyword, slug);
          } else if (!keyword) {
            keyword = '';
            slug = !!category?.slug ? category?.slug : 'all';
          } else {
            slug = keyword;
          }
          /* eslint-enable */
        }
      }

      /*
        TYPE: STOCKS COLLECTION
        endpoint: collections/stocks?menu=upload
       */
      if (group === groups.MEDIA) {
        if (subgroup === subgroups.VIDEOS || subgroup === subgroups.PHOTOS) {
          endpoint = 'stocks';

          params.item_per_page = this.stockItemPerPage;
          params.type = subgroup === subgroups.VIDEOS ? 'videos' : 'images';
          params.page = this.currentPage;
          // console.log('this.currentPage; ', keyword, this.currentPage);

          if (!keyword || forceReload) {
            keyword = subgroup === subgroups.VIDEOS ? 'abstract' : 'scenery';
          }
          slug = keyword;
          // console.log('keyword', keyword, slug);
        }
      }

      /*
        TYPE: USER FILES COLLECTION
        endpoint: collections/user-files
       */
      if (group === groups.MYFILES) {
        apiVersion = 'v2';
        endpoint = this.isSelectedTeamUpload ? 'team-files' : 'user-files';
        if (this.isSelectedTeamUpload) params.team_id = this.teamId;

        params.menu = subgroup;
        slug = this.getActiveFileCategory;

        if (this.activeFolder > 0) {
          params.folder_id = this.activeFolder;
        }

        if (subgroup !== subgroups.LOGO) {
          // paginated user files
          params.item_per_page = 20;
          params.page = this.currentPage;
        }
      }

      /*
        TYPE: USER FAVOURITE COLLECTION
        endpoint: collections/user-favorites
       */
      if (group === groups.FAVOURITES) {
        endpoint = 'user-favorites';
        /*
        filter
        - design
        - text
        - elements
        - backgrounds
        - myfiles
        */
        const filters = [
          'design',
          'text',
          'elements',
          'backgrounds',
          'myfiles',
        ]; // TODO: make it dynamic
        params.type = filters.join(',');
      }

      /*
        TYPE: ELEMENTS & BACKGROUND COLLECTION
        endpoint: collections/{$group}
       */
      if (group === groups.ELEMENTS || group === groups.BACKGROUNDS) {
        if (subgroup === subgroups.CUSTOMELEMENTS) {
          endpoint = `custom/${group}`;
        } else {
          endpoint = `${group}`;
        }

        params.menu = subgroup;
      }

      // GLOBAL API PARAMETERS

      if (group !== groups.TEXTS && keyword !== '') {
        params.search = keyword;
      }

      if (group !== groups.TEXTS) {
        if (category.id > 0) {
          params.categories = category.id;
        } else {
          delete params.search;
          // by using global_search, it will search per tags and category text
          params.global_search = keyword;
        }
      }

      if (!endpoint) return; // make sure endpoint was set properly;

      // console.log('getElements request', endpoint, `${group}/${subgroup}/${slug}`, this.getSidebarElements[`${group}/${subgroup}/${slug}`]);

      const sidebarElementKey = `${group}/${subgroup}/${slug}`;

      if (group === groups.FAVOURITES) {
        // console.log('group is favourites');
        this.showLoader = false;
        this.setDisplayState();
      } else if (
        !this.getSidebarElements[sidebarElementKey]
        || this.getSidebarElements[sidebarElementKey].length === 0
        || group === groups.MEDIA
        || group === groups.MYFILES
        || (group === groups.MUSIC && (this.getActiveSubGroup === subgroups.UPLOAD || this.getActiveSubGroup === subgroups.TEAM_UPLOAD))
        || this.isShutterstockMusic
        || forceReload === true
      ) {
        this.isRequestingApi = true;

        const isOnShutterstockOption = this.getActiveMediaOption.includes('shutterstock');
        // const getEndpointVersion = (endpoint === 'stocks' && isOnShutterstockOption) || endpoint === 'music' ? 'v2' : apiVersion;
        const getEndpointVersion = (endpoint === 'stocks' && isOnShutterstockOption) ? 'v2' : apiVersion;

        CollectionsApi.getItems(
          endpoint,
          params,
          getEndpointVersion,
        )
          .then((response) => {
            this.isRequestingApi = false;
            let results = response.data[responseObjectKey];
            let pagination = '';

            // console.log('addElementToSidebar', slug, group, results[0].id);

            // console.log('pagination', results, typeof results.pagination !== 'undefined');

            // is paginated
            if (typeof results.pagination !== 'undefined') {
              // eslint-disable-next-line
              pagination = results.pagination;
              results = results.data;
            }

            // has search_id
            if (typeof response.data.search_id !== 'undefined') {
              // update store for license usage
              this.setShutterstockSearchId(response.data.search_id);
            }

            /** Note: Only for Uploads tab && Music tab with upload subgroup
              User-Files' response format
              results: {
                files: [],
                folders : [],
              }
            */
            // Team Uploads (Team [images,videos,music])

            const userFileFolders = response.data.results.folders || [];

            if (this.isSelectedTeamUpload) this.setTeamFolders(userFileFolders);

            if (group === groups.MUSIC && (subgroup === subgroups.UPLOAD || subgroup === subgroups.TEAM_UPLOAD)) {
              results = results.files;
            }
            if (this.isSearchingTeamMusic) this.setTeamMusicFoldersSearchResult(userFileFolders);


            if (group === groups.MYFILES && subgroup !== subgroups.LOGO) {
              const { files, folders } = results;
              // eslint-disable-next-line
              pagination = files.pagination;
              results = files.data;

              if (!this.userFolders.length && !this.isSelectedTeamUpload) this.setUserFolders(folders);
            }

            const assetsSidebarKey = {
              group,
              subgroup,
              category,
              slug,
            };

            this.addElementToSidebar({
              group,
              subgroup,
              category,
              slug,
              activeFolder: params.folder_id || null,
              data: results,
            });

            if (group === groups.MEDIA || this.isShutterstockMusic) {
              if (subgroup === subgroups.PHOTOS) {
                this.totalPages.photo = response.data.total_pages;
              }
              if (subgroup === subgroups.VIDEOS) {
                this.totalPages.video = response.data.total_pages;
              }
              if (subgroup === subgroups.MUSIC) {
                this.totalPages.music = response.data.total_pages;
              }
            }

            /**
              User-files Pagination
            */
            const filesPagination = {};

            // --- Note: Comment out first, currently music missing pagination object
            // if ((group === groups.MUSIC && (subgroup === subgroups.UPLOAD || subgroup === subgroups.TEAM_UPLOAD)) || group === groups.MYFILES) {
            //   filesPagination = response.data.results.files.pagination;
            // }

            if (group === groups.MUSIC && subgroup === subgroups.TEAM_UPLOAD) {
              this.totalPages.teamMusic = filesPagination.total_pages || 1;
            }

            if (group === groups.MYFILES && filesPagination) {
              const totalPages = filesPagination.total_pages;

              const imageTab = this.isSelectedTeamUpload ? 'teamImages' : 'images';
              const videoTab = this.isSelectedTeamUpload ? 'teamVideos' : 'myvideos';

              if (subgroup === subgroups.IMAGES) {
                this.totalPages[imageTab] = totalPages || 1;
              }
              if (subgroup === subgroups.VIDEOS) {
                this.totalPages[videoTab] = totalPages || 1;
              }
            }

            this.setDisplayState();

            this.$nextTick(() => {
              // NOTE
              // sometimes, user type another keyword before this is done
              // and it will cause No Elements Found
              // in that case, showLoader shouldn't set to false
              // therefore, we need to check whether it's still the same slug
              const newGroup = this.getActiveGroupings.group;
              const newSubgroup = this.getActiveGroupings.subgroup;
              const newSlug = this.getActiveGroupings.category.slug;
              const newKeyword = this.getActiveGroupings.keyword;

              let newUsedSlug = newGroup === groups.MEDIA || newGroup === groups.MUSIC ? newKeyword : newSlug;
              const isMusicTabNotUpload = newGroup === groups.MUSIC && newSubgroup !== subgroups.UPLOAD && newSubgroup !== subgroups.TEAM_UPLOAD;

              if (this.isOnImageUploadTab) {
                this.hasRemovedBackground = false;
              }

              if (
                !newUsedSlug && (newGroup === groups.MEDIA || newGroup === groups.MUSIC)
              ) {
                if (subgroup !== subgroups.MASKS && newGroup !== groups.MUSIC) {
                  newUsedSlug = newSubgroup === subgroups.VIDEOS ? 'abstract' : 'scenery';
                } else if (isMusicTabNotUpload) {
                  newUsedSlug = 'uplifting';
                } else {
                  newUsedSlug = 'all';
                }
              }

              // check whether if it's the same slug
              // if it's not, don't hide the loader
              // refer to NOTE above
              if (
                slug === newUsedSlug
                || (this.isShutterstockMusic && category.id > 0)
                || (this.isMyFilesCategory && slug === this.getActiveFileCategory)
              ) {
                this.showLoader = false;
              }

              this.isRequestingApi = false;

              if (forceReload || (groups.DESIGNS && !results.length)) {
                this.setShowDesignPreloader(false);
              }

              // to get the next pages
              if (
                typeof pagination !== 'undefined'
                && pagination.total_pages > params.page
                && (newGroup === groups.DESIGN || newGroup === groups.MYFILES)
              ) {
                params.start_id = results[results.length - 1].id;
                delete params.page;
                delete params.item_per_page;
                // console.log('postGetItems', endpoint, params, assetsSidebarKey);
                this.postGetItems(endpoint, params, assetsSidebarKey, 0, apiVersion);
              }

              // TODO: check correct condition to show music elements here?
              this.showLoader = false;
            });
          })
          .catch((error) => {
            console.log(error);
            this.isRequestingApi = false;
            this.showLoader = false;
          });
      } else {
        this.showLoader = false;
        this.isRequestingApi = false;
        this.setShowDesignPreloader(false);
        this.setDisplayState();
      }
    }, 500),
    postGetItems(endpoint = '', params = {}, assetsSidebarKey = {}, tries = 0, apiVersion = 'v1') {
      // console.log('postGetItems', endpoint, params, assetsSidebarKey, tries);

      const responseObjectKey = 'results';
      const {
        group, subgroup, category, slug,
      } = assetsSidebarKey;

      CollectionsApi.getItems(endpoint, params, apiVersion)
        .then((response) => {
          const newGroup = this.getActiveGroupings.group;
          const newSubgroup = this.getActiveGroupings.subgroup;
          let newSlug = this.getActiveGroupings.category.slug;

          if (this.isMyFilesCategory) newSlug = this.getActiveFileCategory;

          if (
            group !== newGroup
            || subgroup !== newSubgroup
            || slug !== newSlug
          ) {
            return;
          } // skip, there is new api request

          let results = response.data[responseObjectKey];

          // v2 - user-files - format
          /**
           * results = {
           *  files: [],
           *  folders: [],
           * }
           */
          if (group === groups.MYFILES) {
            results = response.data.results.files;
          }
          // console.log('results', results);
          this.addElementToSidebar({
            group,
            subgroup,
            category,
            slug,
            mergeResults: true,
            data: results.data || results,
          });
        })
        .catch(() => {
          if (tries > 3) return;
          setTimeout(() => {
            this.postGetItems(endpoint, params, assetsSidebarKey, tries + 1, apiVersion);
          }, 1000);
        });
    },
    getCategories() {
      // Sub groups '/api/get-category-lists/${group}/${subgroup}'
      const { group, subgroup } = this.getActiveGroupings;
      if (group === groups.FAVOURITES) return;

      this.showLoader = true;
      // console.log('!!! GET CATEGORIES !!!')

      if (
        ((group === groups.DESIGNS || group === groups.BRANDEDDESIGNS) && subgroup !== 'all')
        || group === groups.TEXTS
        || group === groups.MEDIA
        || group === groups.MUSIC
        || group === groups.MYFILES
        || group === groups.FAVOURITES
        || (group === groups.BACKGROUNDS && subgroup !== subgroups.IMAGES)
      ) {
        // Reset the category to trigger getElements()
        this.clearActiveCategory();
        return;
      }

      // console.log('getCategories: group, subgroup, category', group, subgroup, category.id)

      const params = {
        sort: 'asc',
        sort_by: 'order',
        group,
        menu: subgroup,
      };

      if (
        this.getSidebarCategories[subgroup]
        && Object.keys(this.getSidebarCategories[subgroup]).length === 0
      ) {
        if (subgroup === subgroups.CUSTOMELEMENTS) {
          let customCategories = this.getCustomElements.map(
            element => element.element_category_id,
          );
          // eslint-disable-next-line
          customCategories = new Set([].concat.apply([], customCategories));
          customCategories = [...customCategories];
          params.cats = customCategories.toString();
          params.is_custom = true;
          params.user = this.userId;
        }

        CollectionsApi.getCategories(params)
          .then((response) => {
            // console.log( response );
            this.addCategoriesToSidebar({
              subgroup,
              data: {
                categories: response.data.results,
              },
            });
            this.$nextTick(() => {
              this.showLoader = false;
            });
          })
          .catch(() => {
            // console.log(error);
          });
      } else {
        this.showLoader = false;
      }
    },
    getUserFolders(isForceReload = false) {
      if (this.userFolders.length && !isForceReload) return; // data already in vuex
      const params = {
        sort: 'desc',
        sort_by: 'id',
      };
      UserApi.getUserFolders(params)
        .then((response) => {
          this.setUserFolders(response.data.results);
        })
        .catch(() => {
          // console.log(error);
        });
    },
    setDisplayState() {
      // console.log('setDisplayState');
      if (
        this.getActiveGroup === groups.DESIGNS
        || this.getActiveGroup === groups.BRANDEDDESIGNS
        || this.getActiveGroup === groups.STYLE
        || this.getActiveGroup === groups.SHORTCUT
      ) {
        this.hasSearchBar = false;
        this.hasSubGroups = false;
        this.hasCategories = false;
        this.hasCategoryItems = false;
      }

      if (
        this.getActiveGroup === groups.TEXTS
        || this.getActiveGroup === groups.FAVOURITES
      ) {
        this.hasSearchBar = false;
        this.hasSubGroups = false;
        this.hasCategories = false;
        this.hasCategoryItems = true;
      }

      if (this.getActiveGroup === groups.MEDIA) {
        this.hasSearchBar = this.getActiveSubGroup !== subgroups.MASKS;
        this.hasSubGroups = true;
        this.hasCategories = false;
        this.hasCategoryItems = true;
      }

      if (this.getActiveGroup === groups.ELEMENTS) {
        this.hasSubGroups = true;
        this.hasSearchBar = true;

        if (this.getActiveCategory.slug === 'all') {
          this.hasCategories = true;
          this.hasCategoryItems = false;
        } else {
          this.hasCategories = false;
          this.hasCategoryItems = true;
        }
      }

      if (this.getActiveGroup === groups.BACKGROUNDS) {
        this.hasSubGroups = true;
        this.hasSearchBar = true;

        if (
          this.getActiveSubGroup !== subgroups.IMAGES
          || this.getActiveCategory.slug !== 'all'
        ) {
          this.hasCategories = false;
          this.hasCategoryItems = true;
        } else {
          this.hasCategories = true;
          this.hasCategoryItems = false;
        }
      }

      if (this.getActiveGroup === groups.MUSIC) {
        this.hasSearchBar = true;
        this.hasSubGroups = true;
        this.hasCategories = false;
        this.hasCategoryItems = true;
      }

      if (this.getActiveGroup === groups.MYFILES) {
        this.hasSearchBar = false;
        this.hasSubGroups = true;
        this.hasCategories = false;
        this.hasCategoryItems = true;
      }

      if (this.getActiveGroup === groups.BRAND) {
        this.hasSearchBar = false;
        this.hasSubGroups = true;
        this.hasCategories = false;
        this.hasCategoryItems = false;
      }
    },
    changeFilesTab(value) {
      this.fileActiveTab = value;
      if (value === 'files') {
        if (this.getActiveSubGroup === subgroups.LOGO) {
          this.setActiveSubGroup('images');
        }
        this.setActiveFolder(null);
      }
      this.setMultipleSelectedFiles([]);
    },
    changeMediaOption(option) {
      this.setActiveMediaOption(option);
    },
    openMusicCategories() {
      this.setShowMusicCategories(true);
    },
    toggleShowMusic() {
      this.showMusic = !this.showMusic;
    },
    retrieveBrandedDesignColors() {
      if (this.getIsTemplate) {
        const colorKeyPrefix = 'color';
        const params = {
          group: colorKeyPrefix,
        };

        TemplateApi.getLayoutSettings(params)
          .then((response) => {
            if (response.data.success) {
              const retrievedColors = response.data[`${colorKeyPrefix}`];
              const brandedDesignColors = {};

              retrievedColors.forEach((color) => {
                brandedDesignColors[color.key] = color.value;
              });

              this.setBrandedDesignColors(brandedDesignColors);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    checkRemoveBg() {
      if (this.isOnImageUploadTab) {
        this.getElements(true);
      } else {
        this.hasRemovedBackground = true;
      }
    },
    onClickOutsideTooltip(event) {
      const paths = event.path || event.composedPath();
      // detection on clicking the button with class 'icon-info-line'
      const isTriggerIcon = paths.findIndex(path => (
        path.className
            && typeof path.className === 'string'
            && path.className.includes('shutterstock-toggler')
      ),
        // to make sure the result true/false
        // eslint-disable-next-line
      ) > -1;

      // if you click the button that open project-buttons, isTriggerIcon = true. Outside it, isTriggerIcon = false
      if (!isTriggerIcon) {
        this.isShowShutterstockCredit = false;
      }
    },
    removeMusic() {
      this.updateSelectedMusic({});
      // close music trimmer that opened when we use music.
      this.setShowTrim(false);
      this.setShowPermanentTrimElement(false);
    },
    setIsSearching(value) {
      this.isSearching = value;
    },
    showMockPreloader() {
      this.showLoader = true;

      setTimeout(() => {
        this.showLoader = false;
      }, 750);
    },
    toggleUploadsDropdown() {
      this.isShowUploadsDropdown = !this.isShowUploadsDropdown;
    },
    closeUploadsDropdown() {
      this.isShowUploadsDropdown = false;
    },
    setSelectedUploadSpace(space) {
      this.setActiveFolder(null);
      this.setActiveSubGroup(subgroups.IMAGES);
      this.setActiveFileCategory(space);
      this.selectedUploadSpace = space;
      this.isShowUploadsDropdown = false;
    },
    selectFolder() {
      this.showLoader = true;

      // just like in dashboard, when clicking folder will clear the search and get in to the folder
      // add "return" because when change keyword it will trigger watcher that calls "getElements" that will do the fetching
      if (this.isSearchingTeamMusic) {
        this.setActiveKeyword('');
        this.clearActiveCategory();
        return;
      }
      this.getElements();
    },
  },
  computed: {
    ...mapState([
      'showSwap',
      'showColorTab',
    ]),
    ...mapState('assetsSidebar', [
      'userFolders',
      'activeFolder',
      'activeFavFilter',
      'skeletonList',
      'isMultipleFileSelection',
      'showMoveMultipleFilesContainer',
      'isUploadingFile',
      'isUploadingMusicFile',
    ]),
    ...mapState('userData', ['userId']),
    ...mapState('team', ['teamId']),
    ...mapGetters(['getFavouritedCollection', 'getIsTemplate', 'isLiteMode']),
    ...mapGetters('assetsSidebar', [
      'getActiveGroup',
      'getActiveSubGroup',
      'getActiveCategory',
      'getActiveKeyword',
      'getActiveFileCategory',
      'getActiveGroupings', // Group, SubGroup, and Category
      'getActiveMediaOption',
      'getSidebarElements',
      'getSidebarCategories',
      'getCustomElements',
      'getStockEndpointVersion',
    ]),
    ...mapGetters('canvasElements', ['getSelectedMusic']),
    ...mapGetters('brand', ['hasSetupBrand']),
    ...mapGetters('team', ['isInTeamWorkspace']),
    categoryItemElements() {
      const { group, subgroup } = this.getActiveGroupings;
      let { keyword } = this.getActiveGroupings;
      let { slug } = this.getActiveCategory;

      // console.log('categoryItemElements', this.getActiveGroup, groups.FAVOURITES);
      if (this.getActiveGroup === groups.FAVOURITES) {
        return this.getFavouritedCollection(this.activeFavFilter);
      }

      if (group === groups.MEDIA) {
        if (!keyword) {
          if (subgroup !== subgroups.MASKS) {
            keyword = subgroup === subgroups.VIDEOS ? 'abstract' : 'scenery';
          } else {
            keyword = 'all';
          }
        }
        slug = keyword;
      }

      if (this.isShutterstockMusic) {
        if (this.getActiveCategory.id > 0) {
          slug = this.getActiveCategory.id;
        } else if (!keyword) {
          slug = 'uplifting';
        }
      }

      if (group === groups.MYFILES) {
        slug = this.getActiveFileCategory;
      }

      const sidebarElementKey = `${group}/${subgroup}/${slug}`;

      const elements = this.getSidebarElements[sidebarElementKey] || [];

      // console.log('${group}/${subgroup}/${slug}', this.getSidebarElements, `${group}/${subgroup}/${slug}`, elements);

      // console.log(`${group}/${subgroup}/${slug}`, subgroups, elements);
      // for users uploaded files with foldering function
      if (
        typeof elements !== 'undefined'
        && ((group === groups.MYFILES && subgroup !== subgroups.LOGO) || (this.isOnMusicTeamUploadsTab && !this.isSearchingTeamMusic))
      ) {
        // need to be == because it may compare null and undefined for newly added video
        /* eslint-disable */
        const parentId = 'folder_id';
        return elements.filter(
          element => this.activeFolder == element[parentId] || typeof element.fileName !== 'undefined',
        );
        /* eslint-enable */
      }

      // console.log('categoryItemElements', `${group}/${subgroup}/${slug}`, elements, this.getSidebarElements);
      return elements;
    },
    totalStockPages() {
      const { group, subgroup } = this.getActiveGroupings;

      if (group === groups.MEDIA || this.isShutterstockMusic) {
        if (subgroup === subgroups.PHOTOS) return this.totalPages.photo;
        if (subgroup === subgroups.VIDEOS) return this.totalPages.video;
        if (subgroup === subgroups.MUSIC) return this.isInTeamWorkspace ? this.totalPages.teamMusic : this.totalPages.music;
      }

      if (group === groups.MYFILES) {
        if (subgroup === subgroups.IMAGES) {
          return this.isInTeamWorkspace ? this.totalPages.teamImages : this.totalPages.images;
        }
        if (subgroup === subgroups.VIDEOS) {
          return this.isInTeamWorkspace ? this.totalPages.teamVideos : this.totalPages.myvideos;
        }
      }

      return 1;
    },
    isOnMusicTab() {
      return this.getActiveGroup === groups.MUSIC;
    },
    isOnMusicTeamUploadsTab() {
      return this.isOnMusicTab && this.getActiveSubGroup === subgroups.TEAM_UPLOAD;
    },
    isOnBrandTab() {
      return this.getActiveGroup === groups.BRAND;
    },
    isMyFilesCategory() {
      return this.getActiveGroup === groups.MYFILES;
    },
    isOnMediaTab() {
      return this.getActiveGroup === groups.MEDIA;
    },
    isMediaMaskTab() {
      return this.getActiveGroup === groups.MEDIA && this.getActiveSubGroup === subgroups.MASKS;
    },
    isOnImageUploadTab() {
      return (
        this.getActiveGroup === groups.MYFILES
        && this.getActiveSubGroup === subgroups.IMAGES
      );
    },
    creditsGroupSingularText() {
      const text = this.getActiveSubGroup;
      return `${text[0].toUpperCase()}${text.substring(1)}`;
    },
    creditsGroupPluralText() {
      return `${this.creditsGroupSingularText}${
        this.getActiveSubGroup !== 'music' ? 's' : ''
      }`;
    },
    stockItemPerPage() {
      if (this.getStockEndpointVersion === 'v2') return 24;
      return 20;
    },
    isShutterstockMusic() {
      return (
        this.getStockEndpointVersion === 'v2'
        && this.getActiveGroup === groups.MUSIC
        && this.getActiveSubGroup === subgroups.MUSIC
      );
    },
    isShowShutterstockGuidelines() {
      return false;
      // const isMediaShutterstockAssets = this.getActiveMediaOption.includes('shutterstock');
      // return (
      //   (this.getActiveGroup === 'media'
      //     && (this.getActiveSubGroup === 'photo' || this.getActiveSubGroup === 'video')
      //     && isMediaShutterstockAssets
      //   )
      //   || (this.getActiveGroup === 'music' && this.getActiveSubGroup === 'music')
      // );
    },
    isShowUnsplashCredit() {
      const isOnUnsplashOption = this.getActiveMediaOption.includes('unsplash');
      return (
        (
          this.getActiveGroup === 'media'
          && this.getActiveSubGroup === 'photo'
          && isOnUnsplashOption
        )
      );
    },
    isShowPixabayCredit() {
      const isOnPixabayOption = this.getActiveMediaOption.includes('pixabay');
      return (
        (
          this.getActiveGroup === 'media'
          && this.getActiveSubGroup === 'video'
          && isOnPixabayOption
        )
      );
    },
    uploadsDropdownIconAfter() {
      return this.isShowUploadsDropdown ? 'icon-up-arrow' : 'icon-down-arrow';
    },
    selectedUploadSpaceText() {
      return this.selectedUploadSpace === 'team' ? 'Team Uploads' : 'My Uploads';
    },
    isSearchingTeamMusic() {
      const { slug } = this.getActiveCategory;
      return this.isOnMusicTeamUploadsTab && slug && slug !== 'all';
    },
    isSelectedTeamUpload() {
      return (this.isMyFilesCategory && this.selectedUploadSpace === 'team') || this.isOnMusicTeamUploadsTab;
    },
  },
  watch: {
    getActiveGroup(newValue, oldValue) { // eslint-disable-line
      this.setDisplayState();
      console.log('~~ getActiveGroup', newValue, oldValue);

      this.setActiveFolder(null);
      this.setCurrentPage();

      // Reset media active option when switching tab/group
      if (this.getActiveMediaOption !== 'photo-unsplash') {
        this.setActiveMediaOption('photo-unsplash');
      }
      this.setActiveFileCategory('personal');
      this.selectedUploadSpace = 'personal';

      if (this.showMoveMultipleFilesContainer) {
        this.setShowMoveMultipleFilesContainer(false);
      }

      if (!this.hasCategories) {
        this.getElements();
      }
    },
    getActiveSubGroup(val, oldVal) {
      if (this.isMyFilesCategory && this.selectedUploadSpace !== 'team') this.getUserFolders();

      // Reset media active option when switching subgroup
      const mediaOption = Object.keys(this.mediaOption);
      const isMediaSubgroup = mediaOption.includes(val) && mediaOption.includes(oldVal);

      if (
        this.isOnMediaTab
        && !this.isMediaMaskTab
        && isMediaSubgroup
        && val !== oldVal
      ) {
        console.log('getActiveSubGroup', val);
        // const option = `${val}-shutterstock`;
        // this.setActiveMediaOption(option);

        const activeOption = {
          photo: 'photo-unsplash',
          video: 'video-pixabay',
        };
        this.setActiveMediaOption(activeOption[val] || activeOption.photo);
      }

      this.getCategories();
      this.setDisplayState();

      // console.log('~~ getActiveSubGroup', val, oldVal);
      this.currentPage = 1;
      this.getElements();
    },
    getActiveCategory(newVal, oldVal) {
      const isOnGraphicsTab = this.getActiveGroup === groups.ELEMENTS;
      const isOnBackgroundTab = this.getActiveGroup === groups.BACKGROUNDS;
      const tabWithMockPreloader = isOnBackgroundTab || isOnGraphicsTab;

      if (newVal.slug && newVal.slug === oldVal.slug) return;
      // don't run this if they are the same
      // console.log('getActiveCategory', newVal.slug, oldVal && oldVal.slug);

      const { category } = this.getActiveGroupings;

      if (category === '') {
        this.setDisplayState();
        return;
      }

      // avoid wrong result glance when clearing search keyword in these tabs.
      if (category.slug === 'all' && tabWithMockPreloader) {
        this.showMockPreloader();
      }

      // console.log('category.slug', category.slug);

      this.currentPage = 1;
      // only force reload if thre is a category
      this.getElements(category.slug && category.slug !== 'all');
    },
    getActiveKeyword(keyword) {
      console.log('getActiveKeyword', keyword);
      const { group } = this.getActiveGroupings;
      const isForceReload = this.isOnMusicTeamUploadsTab;

      // this is to avoid double calling when user clear keyword
      // since getActiveCategory is going to be called as well
      if (group === groups.MEDIA && keyword === '') return;
      this.currentPage = 1;
      this.getElements(isForceReload);
    },
    getActiveGroupings: {
      handler() {
        if (this.isMultipleFileSelection) {
          this.setIsMultipleFileSelection(false);
        }
      },
      deep: true,
    },
    getActiveFileCategory: {
      handler(newVal, oldVal) {
        if (oldVal && newVal !== oldVal) {
          this.selectedUploadSpace = newVal;
        }
      },
      immediate: true,
    },
    activeDesignFormat() {
      // template size format ['square', 'vertical', 'landscape']
      this.setShowDesignPreloader(true);
      this.getElements(true); //
    },
    showSwap(val) {
      if (val) {
        this.setMultipleSelectedFiles([]);
      }
    },
    updateMultiSelecting(value) {
      this.isMultiSelecting = !!value;
    },
    'getSelectedMusic.title': {
      handler() {
        this.hasNewTrack = true;

        // trigger newTrack css animation then prepare to give animation again for the next selected music.
        setTimeout(() => {
          this.hasNewTrack = false;
        }, 1500);
      },
    },
    activeFolder: {
      handler(val) {
        if (val && val !== 0) this.fileActiveTab = 'folders';
        if (val === 0) {
          this.fileActiveTab = 'files';
          this.setActiveFolder(null);
        }
      },
      immediate: true,
    },
    skeletonList: {
      handler(val) {
        const { subgroup } = this.getActiveGroupings;
        const { group } = this.getActiveGroupings;
        if (
          isEmptyObject(val)
          && group === groups.MYFILES
          && subgroup === 'logos'
        ) {
          // Re-get logos after uploading
          this.getElements();
        }
      },
      deep: true,
      immediate: true,
    },
    isUploadingFile: {
      handler(val) {
        if (val && this.activeFolder === null) this.fileActiveTab = 'files';
      },
      immediate: true,
    },
    isSearching: {
      handler(val) {
        // console.log('!! calling collection - isSearching', this.isRequestingApi, val);
        if (this.isRequestingApi) {
          this.showLoader = true;
          return;
        }
        this.showLoader = val;
      },
      immediate: true,
    },
    getActiveMediaOption: {
      handler(newVal, oldVal) {
        if (oldVal && newVal !== oldVal) {
          this.showLoader = true;

          // set it back to first page when switching option
          if (this.currentPage > 1) {
            this.currentPage = 1;
          }
          this.resetActiveMediaElements();
          this.getElements();
        }
      },
      immediate: true,
    },
    selectedUploadSpace: {
      handler(newVal, oldVal) {
        if (oldVal && newVal !== oldVal) {
          this.setActiveFolder(null);
        }
        const { group, subgroup } = this.getActiveGroupings;
        const sidebarElementKey = `${group}/${subgroup}/${newVal}`;
        const elements = this.getSidebarElements[sidebarElementKey] || [];
        if (!elements.length) {
          this.currentPage = 1;
          this.showLoader = true;
          this.getElements();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.isLiteMode) {
      // for lite mode default should be media tab
      this.setActiveGroup(groups.MEDIA);
      this.setCanvasSectionPaddingRight(0);
    } else {
      this.retrieveBrandedDesignColors();
      this.getElements();
    }

    this.setDisplayState();
    this.$root.$on('multi-selecting', this.updateMultiSelecting);
    this.$root.$on('remove-bg-done', this.checkRemoveBg);
  },
  beforeDestroy() {
    this.$root.$off('multi-selecting', this.updateMultiSelecting);
    this.$root.$off('remove-bg-done', this.checkRemoveBg);
  },
};
</script>

<style lang="scss">
.shutterstock-credits__container,
.media-credits__container {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;

  .credits__overlay {
    background-image: url('~@/assets/images/media/shutterstock-bg-gradient.png');
    background-size: contain;
    position: absolute;
    left: 0;
    bottom: -8px;
    padding: 150px 10px 30px;
    height: 25%;
    width: 100%;
    pointer-events: none;
  }

  .credits__container {
    position: absolute;
    left: -15px;
    bottom: 21px;
    width: 100%;
    display: flex;
    justify-content: center;

    .credits {
      color: $light;
      font-weight: 400;
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      margin: 0;

      a {
        color: $light !important;
        margin-left: 2px;
        text-decoration: none;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.media-credits__container {
  .credits__container {
    left: 0;
  }

  .credits {
    a {
      margin-left: 0;
    }
  }
}

.shutterstock-credits__container {
  .shutterstock-tooltip-box__wrapper {
    position: absolute;
    top: -38px;
    right: 85px;
    display: flex;
    z-index: 10;
    cursor: pointer;

    @include smallest {
      right: 40px;
    }

    .icon {
      color: $light;
      padding: 0;
    }

    .tooltip-box {
      opacity: 0;
      visibility: hidden;
      transform: translateY(25px);
      transition: 0.3s;

      position: absolute;
      background: rgba($darkGrey700, 0.95);
      top: -220px;
      right: -43px;
      cursor: default;
      width: 285px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      padding: 30px 8px 25px 15px;

      @include smallest {
        width: 230px;
        right: -16px;
      }

      &.is-show {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }

    .tooltip {
      right: 5px;
      color: $light;
      width: 100%;
      height: 150px;
      overflow: auto;
      font-size: 0.6875em;
      line-height: 1.4;
      padding-right: 8px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba($darkGrey600, 0);
      }

      &::-webkit-scrollbar-thumb {
        background-color: $darkGrey800;
      }

      h3 {
        margin-top: 0;
        font-size: 0.8125rem;
        font-weight: 500;
      }

      strong {
        font-weight: 500;
      }

      ul {
        padding: 0 20px;
        margin-bottom: 0;

        li:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }

    .tooltip__close-icon {
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: 0.875rem;
      color: $light;
      padding: 0;
    }
  }
}

.assets__title {
  color: $darkGrey;
  font-weight: 600;
  margin: 0;
  font-size: 1em;
}

.btn-view-music-category {
  margin-top: 10px;
  background: $darkGrey900;
  height: 40px;
  overflow: initial; // fix issue sometimes button goes to 28px when selecting music.

  &:hover {
    color: $light;
    background: $darkGrey5 !important;
  }

  .lite-mode & {
    margin-top: 0;
  }
}

.music__current-track-container {
  display: block !important;
  width: 100%;
  margin-top: 10px;
  border-radius: 4px;
  background: $darkGrey600;
  padding: 15px 20px;
  position: relative;
  color: $lightGrey100;

  .current-track__header {
    display: block;
    font-size: 0.6875rem;
    margin: 0;
    margin-bottom: 10px;
  }

  .current-track__value {
    display: block;
    margin: 0;
    font-size: 0.9375rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 95%;

    &.has-new-track {
      animation: newTrack 1.5s;
    }
  }

  @keyframes newTrack {
    0% {
      color: $lightGreen2;
    }
    10% {
      color: $lightGreen2;
    }
    100% {
      color: $lightGrey100;
    }
  }

  .icon-cross-line {
    position: absolute;
    font-size: 0.75rem;
    top: 10px;
    right: 10px;
    padding: 0;
    color: $lightGrey100;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: $lightGrey700;
    }
  }
}

.personal-team-uploads__toggler {
  margin-top: 15px;
  position: relative;

  .uploads-space-dropdown__button {
    position: relative;
    width: 100%;
    font-size: 0.875rem;
    background: $darkGrey5;
    color: $light !important;

    &:hover {
      background: $darkGrey900;
    }

    .btn__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .uploads-space-dropdown {
    position: absolute;
    padding: 15px 8px;
    top: 35px;
    left: 8px;
    border-radius: 4px;
    background: $darkGrey600;
    z-index: 25;
    width: 240px;
    -webkit-box-shadow: 0px 0px 1px rgb(10 31 68 / 10%), 0px 26px 26px rgb(10 31 68 / 12%);
    box-shadow: 0px 0px 1px rgb(10 31 68 / 10%), 0px 26px 26px rgb(10 31 68 / 12%);
  }

  .divider-line {
    display: block;
    border: 1px solid $darkGrey400;
    margin: 10px 0;
  }

  .uploads-dropdown__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    padding: 0 8px;

    .my-uploads__button,
    .team-uploads__button {
      color: $light;

      &:hover,
      &.is-active {
        color: $green700 !important;
      }

      &.is-active {
        user-select: none;
        pointer-events: none;
      }
    }

    .icon-checked {
      color: $green700;
      font-size: 18px;
    }
  }
}

.music__uploading-loader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px 10px 20px;
  margin-top: 10px;
  border-radius: 4px;
  background: $darkGrey7;
  color: $lightGrey500;

  .loader-header {
    display: flex;
    align-items: center;

    .header-icon {
      font-size: 0.875rem;
      margin-right: 15px;
    }

    .header-title {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 0.75rem;
      margin-bottom: -1px;
    }
  }

  .loader {
    width: 25px;
    height: 25px;
  }
}

.my-file__tabs,
.media-option__tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 10px;
  font-size: 20px;
  user-select: none;
  background: none;

  .tab__item {
    color: $light !important;
    opacity: 0.4;
    font-size: 14.5px;
    background: none;
    padding: 0 0 5px 0;
    border-radius: 0;

    &:hover {
      opacity: 1;
    }

    &:hover,
    &:focus,
    &:active {
      background: none;
    }

    &.is-active {
      opacity: 1;
      cursor: context-menu;
      border-bottom: 2px solid $green700;
      background: none;
    }

    &:nth-child(1) {
      margin-right: 8px;
    }

    &:nth-child(2) {
      margin-left: 8px;
    }
  }
}
</style>
