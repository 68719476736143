<template lang="pug">
  div.edit-tab__edit-stroke-tab
    template(v-for="(stroke, index) in elementStroke")
      div.edit-stroke-color
        h4.stroke-title Stroke Color
        button.stroke-color__button(
          type="button"
          :style="{ background: borderColor(stroke) }"
          @click="toggleColorTab(getId(stroke, `stroke-${index}`))"
        )

    template(v-if="elementStroke.length")
      BaseSlider.edit-stroke__size-slider(
        :min="0"
        :max="100"
        title="Size"
        :is-dark="true"
        :value="strokeSize"
        @update="setStrokeSize"
      )
</template>

<script>
import {
  mapGetters, mapMutations, mapActions,
} from 'vuex';

export default {
  name: 'EditStrokeTab',
  props: {
    elementStroke: { type: Array, default: () => [] },
  },
  computed: {
    ...mapGetters('canvasElements', ['getActiveElements', 'getCanvasElements', 'getActiveColorId']),
    strokeSize() {
      let size = 0;
      if (this.getActiveElements.length && this.getActiveElements[0].data.stroke.length) {
        size = this.elementStroke[0].stroke || 0;
      }
      return size;
    },
  },
  methods: {
    ...mapActions(['toggleColorTab']),
    ...mapActions('canvasHistory', ['catchHistory']),
    ...mapMutations('canvasElements', ['updateCanvasElementStroke', 'updateActiveColorId']),
    getId(color, index) {
      if (color.nm) return color.nm;
      return index;
    },
    borderColor(stroke) {
      return stroke.strokeColor;
    },
    setStrokeSize(value) {
      this.catchHistory('element');
      const strokeObject = {};

      strokeObject.stroke = [];

      strokeObject.stroke.push({
        stroke: value,
        strokeColor: this.getActiveElements[0].data.stroke[0].strokeColor,
      });

      this.updateCanvasElementStroke(strokeObject);
    },
  },
};
</script>

<style lang="scss">
.edit-tab__edit-stroke-tab {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  color: $light;

  .edit-stroke-color {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .stroke-title {
      font-size: 0.875rem;
      font-weight: 500;

      @include smallest {
        font-size: 0.8125rem;
      }
    }

    .stroke-color__button {
      width: 30px;
      height: 30px;
      outline: 0;
      border: 0;
      border-radius: 4px;
    }
  }

  .edit-stroke__size-slider {
    margin-top: 20px;
    width: 100%;

    .slider__container {
      display: flex;
      justify-content: space-between;
    }

    .slider__title {
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 10px;

      @include smallest {
        font-size: 0.8125rem;
      }
    }

    .range-wrapper {
      max-width: calc(100% - 70px);

      @include smallest {
        max-width: calc(100% - 60px);
      }
    }

    .slider__value {
      width: 50px;
      background: $darkGrey600 !important;
      border: 0 !important;
      outline: 0;

      @include smallest {
        width: 40px;
      }
    }

    input[type='range']::-webkit-slider-thumb {
      background: $light !important;
      width: 14px !important;
      height: 14px !important;
      margin-top: -1px;
    }
  }
}
</style>
