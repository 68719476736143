<template lang="pug">
.controls__item
  template(v-if="!isVideo && !isMultiple && !isBrandedGraphic")
    button.btn--text.btn--control.btn--with-loader(
      v-if="showThisControlButton('Remove BG')"
      :class="{'is-loading': isLoadingShutterstockAsset}"
      type="button"
      data-title="Remove Background"
      :disabled="hasRemovedBg || isLoadingShutterstockAsset"
      @click="showRemoveBg"
    )
      template(v-if="isLoadingShutterstockAsset")
        BasePreloader(
          :width="30"
          :type="12"
        )
      template(v-else)
        svg(
          v-if="isDarkMode"
          width="19"
          height="18"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        )
          rect(y="3.87695" width="15.508" height="15.508" rx="1" fill="white")
          rect(opacity="0.5" x="5.15234" y="0.5" width="14.508" height="14.508" rx="0.5" stroke="white")
          path(d="M14 16.5556V6.44444C14 5.65 13.35 5 12.5556 5H2.44444C1.65 5 1 5.65 1 6.44444V16.5556C1 17.35 1.65 18 2.44444 18H12.5556C13.35 18 14 17.35 14 16.5556ZM5.26111 12.93L6.77778 14.7572L9.01667 11.8756C9.16111 11.6878 9.45 11.6878 9.59444 11.8828L12.1294 15.2628C12.31 15.5011 12.1367 15.8406 11.8406 15.8406H3.18111C2.87778 15.8406 2.71167 15.4939 2.89944 15.2556L4.69778 12.9444C4.835 12.7567 5.10944 12.7494 5.26111 12.93Z" fill="#364866")
        svg(
          v-else
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        )
          rect(y="3.87695" width="15.508" height="15.508" rx="1" fill="#182C4F")
          rect(opacity="0.5" x="5.15234" y="0.5" width="14.508" height="14.508" rx="0.5" stroke="#182C4F")
          path(d="M14 16.5556V6.44444C14 5.65 13.35 5 12.5556 5H2.44444C1.65 5 1 5.65 1 6.44444V16.5556C1 17.35 1.65 18 2.44444 18H12.5556C13.35 18 14 17.35 14 16.5556ZM5.26111 12.93L6.77778 14.7572L9.01667 11.8756C9.16111 11.6878 9.45 11.6878 9.59444 11.8828L12.1294 15.2628C12.31 15.5011 12.1367 15.8406 11.8406 15.8406H3.18111C2.87778 15.8406 2.71167 15.4939 2.89944 15.2556L4.69778 12.9444C4.835 12.7567 5.10944 12.7494 5.26111 12.93V12.93Z" fill="#FCFCFD")


  ConfirmationControl(
    v-if="!isMask && !isMultiple && !isBrandedGraphic && showThisControlButton('Crop')"
    :is-icon="true"
    :is-show="showCrop"
    data-title="Crop"
    button-class="icon-crop"
    @show-confirmation="cropElement"
    @confirm="confirmCrop"
    @cancel="cancelCrop"
  )

  template(v-if="isVideo && !isLoop")
    button.btn--text.btn--control.btn--with-loader(
      type="button"
      data-title="Trim Video"
      :class="{ 'is-active': showTrimOverlay }"
      @click="showTrim"
    )
      BasePreloader(
        v-if="isLoadingShutterstockAsset"
        :width="30"
        :type="12"
      )
      i.icon-trim(v-else)
</template>

<script>
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

import ConfirmationControl from './children/ConfirmationControl.vue';

export default {
  name: 'ControlMedia',
  mixins: [darkModeMixin],
  props: {
    isLiteVersion: { type: Boolean, default: false },
    isVideo: { type: Boolean, default: false },
    isBrandedGraphic: { type: Boolean, default: false },
    hiddenMenu: { type: Array, default: () => [] },
  },
  components: {
    ConfirmationControl,
  },
  computed: {
    ...mapState([
      'showCrop',
      'showTrimOverlay',
      'showPermanentTrimElement',
      'permanentTrimElement',
    ]),
    ...mapState('canvasElements', ['shutterstockLoadingIds']),
    ...mapGetters(['isLiteMode']),
    ...mapGetters('canvasElements', ['getActiveElements']),
    ...mapGetters('userData', ['isFreeUser']),
    isLoop() {
      if (!this.isVideo) return false;
      return this.getActiveElements[0].animation_type === 'loop';
    },
    hasRemovedBg() {
      return this.getActiveElements[0].data.has_removed_bg;
    },
    isLoadingShutterstockAsset() {
      const activeElementData = this.getActiveElements[0].data;
      return this.shutterstockLoadingIds[activeElementData.src_id]
        ? this.shutterstockLoadingIds[activeElementData.src_id].includes(activeElementData.id)
        : false;
    },
    isMultiple() {
      if (this.getActiveElements.length > 1) return true;
      return false;
    },
    isMedia() {
      let isMedia = true;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (element.type !== 'videos' && element.type !== 'images' && element.type !== 'masks') {
          isMedia = false;
          return true;
        }
      });

      return isMedia;
    },
    isPng() {
      let isPng = true;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        console.log('1', !element.data.url || element.data.url.split('.').pop() !== 'png');
        if (!element.data.url || element.data.url.split('.').pop() !== 'png') {
          isPng = false;
          return true;
        }
      });

      return isPng;
    },
    isMask() {
      let isMask = false;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (element.type === 'masks') {
          isMask = true;
          return true;
        }
      });

      return isMask;
    },
  },
  methods: {
    ...mapMutations([
      'setShowCrop',
      'setShowTrimOverlay',
      'setShowBackgroundRemover',
      'setShowRemoveBgConfirmation',
      'setShowUpgradeSubscription',
    ]),
    ...mapMutations('canvasElements', [
      'updateCroppedElement',
      'updateTrimmedElement',
      'updateRemoveBgElement',
      'confirmCropElement',
    ]),
    ...mapActions('canvasHistory', ['catchHistory']),
    ...mapActions('canvasElements', ['saveProject']),
    cropElement() {
      this.setShowCrop(true);
      this.updateCroppedElement(this.getActiveElements[0]);
      // pause temp audio and canvas when opening crop
      this.$root.$emit('pause-all-audio');
    },
    confirmCrop() {
      this.catchHistory('element');
      this.confirmCropElement();
      this.setShowCrop(false);
      this.$root.$emit('canvasElementSizeUpdated');
    },
    cancelCrop() {
      this.setShowCrop(false);
    },
    showTrim() {
      this.setShowTrimOverlay(true);
      this.updateTrimmedElement(this.getActiveElements[0]);

      // pause temp audio and canvas when opening trim video
      this.$root.$emit('pause-all-audio');
    },
    showRemoveBg() {
      if (this.isFreeUser) {
        this.setShowUpgradeSubscription(true);
      } else {
        this.saveProject(); // save element first
        // this.setShowBackgroundRemover(true); // magic edge overlay
        this.updateRemoveBgElement(this.getActiveElements[0]);
        this.setShowRemoveBgConfirmation(true); // remove.bg overlay

        // pause temp audio and canvas when opening remove bg
        this.$root.$emit('pause-all-audio');
      }
    },
    showThisControlButton(text) {
      if (!this.isLiteMode) return true;
      if (this.hiddenMenu.includes(text) && this.isLiteVersion) return true;
      if (!this.hiddenMenu.includes(text) && !this.isLiteVersion) return true;
      return false;
    },
  },
};
</script>

<style lang="scss">
.controls__item {
  .icon-crop,
  .icon-trim {
    font-size: 1.25rem !important;
  }

  .btn--with-loader {
    &.is-loading {
      padding: 0;
      display: inline-flex;
      align-content: center;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
