<template lang="pug">
  .dropdown-position
    template(v-for="(position, index) in positions")
      button.btn.btn--text(
        type="button"
        @click.prevent="doUpdateLogoPosition(position.direction); isSelected = position.direction"
        :class="isSelected === position.direction ? 'is-active' : ''"
      )
        span.label {{position.label}}
        span.icon(v-show="isSelected === position.direction")
          i.icon.icon-checked
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      positions: [
        {
          label: 'Top Left',
          direction: 'top-left',
        },
        {
          label: 'Top Center',
          direction: 'top-center',
        },
        {
          label: 'Top Right',
          direction: 'top-right',
        },
        {
          label: 'Bottom Left',
          direction: 'bottom-left',
        },
        {
          label: 'Bottom Center',
          direction: 'bottom-center',
        },
        {
          label: 'Bottom Right',
          direction: 'bottom-right',
        },
      ],
      showDropdown: true,
      isSelected: '',
    };
  },
  computed: {
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getCanvasSize',
    ]),
  },
  methods: {
    ...mapMutations('canvasElements', [
      'updateCanvasElementRect',
    ]),
    doUpdateLogoPosition(direction) {
      const { id } = this.getActiveElements[0].data;
      const elementWidth = this.getActiveElements[0].size.width;
      const elementHeight = this.getActiveElements[0].size.height;

      let position = {};

      /* eslint-disable */
      switch (direction) {
        case 'top-left':
          position = {
            x: parseInt(elementWidth / 2) + 30,
            y: parseInt(elementHeight / 2) + 30,
          };
          break;

        case 'top-center':
          position = {
            x: parseInt(this.getCanvasSize.width / 2),
            y: parseInt(elementHeight / 2) + 30,
          };
          break;

        case 'top-right':
          position = {
            x: this.getCanvasSize.width - (parseInt(elementWidth / 2) + 30),
            y: parseInt(elementHeight / 2) + 30,
          };
          break;

        case 'bottom-left':
          position = {
            x: parseInt(elementWidth / 2) + 30,
            y: this.getCanvasSize.height - (parseInt(elementHeight / 2) + 30),
          };
          break;

        case 'bottom-center':
          position = {
            x: parseInt(this.getCanvasSize.width / 2),
            y: this.getCanvasSize.height - (parseInt(elementHeight / 2) + 30),
          };
          break;

        case 'bottom-right':
          position = {
            x: this.getCanvasSize.width - (parseInt(elementWidth / 2) + 30),
            y: this.getCanvasSize.height - (parseInt(elementHeight / 2) + 30),
          };
      }
      /* eslint-enable */

      const logo = {
        id,
        position,
      };

      this.updateCanvasElementRect(logo);
      this.$root.$emit('canvasElementSizeUpdated');
    },
  },
};
</script>
<style lang="scss" scoped>
  .dropdown-position {
    button {
      width: 100%;
      outline: none;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        font-size: 0.6rem;
      }

      &.is-active,
      &:hover {
        background-color: $btnPrimaryBackground;
        color: #fff;
      }
    }
  }
</style>
