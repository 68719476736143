<template lang="pug">
.controls__general
  BaseButton.btn--control.controls__group-button(
    v-if="isMultiple && !isGroupElements"
    :is-canvas="true"
    is-primary-hollow=true
    @click="groupElements()"
  ) Group
  BaseButton.btn--control.controls__ungroup-button(
    v-if="isGroupElements"
    :is-canvas="true"
    is-primary-hollow=true
    @click="ungroupElements()"
  ) Ungroup

  template(
    v-if="!isLiteMode"
  )
    //- TooltipMenu(
    //-   buttonText="Logo Position"
    //-   :isShort="true"
    //-   title="Position"
    //-   v-if="getActiveElements[0].is_brand === true"
    //- )
    //-   .controls__section
    //-     p.title Alignment
    //-     .controls__buttons
    //-       LogoPositionControl
    BaseButton.btn--control.controls__add-pack-button(
      v-if="showAddElementToPack"
      :is-canvas="true"
      is-primary-hollow=true
      @click="addActiveElementToPack"
    ) Add to pack


  TooltipMenu.tooltip--animate.tooltip--position(
    v-if="showThisControlButton('Position')"
    button-class="icon-position btn--text"
    no-header=true
    data-title="Position"
  )
    .controls__section-wrapper
      template(v-if="getActiveElements.length === 1 && getCanvasElements.length > 1")
      .controls__section
        p.title Layer
        .controls__buttons
          template(v-for="item in layer")
            LayerButton(
              :icon="item.icon"
              :title="item.title"
              :direction="item.direction"
            )
      .controls__section
        p.title Align
        .controls__buttons.controls--align
          template(v-for="(item, index) in alignment")
            AlignButton(
              :index="index"
              :icon="item.icon"
              :title="item.title"
              :direction="item.direction"
            )
      template(v-if="!isLiteMode && !checkActiveElementHasText")
        .controls__section
          p.title Fit to
          .controls__buttons.fit-to
            template(v-for="(item, index) in fitTo")
              FitToButton(
                :index="index"
                :icon="item.icon"
                :title="item.title"
                :direction="item.direction"
            )
      .controls__section
        p.title Rotate
        .controls__buttons
          template(v-for="(item, index) in rotate")
            RotateButton(
              :index="index"
              :icon="item.icon"
              :title="item.title"
              :direction="item.direction"
            )
      template(v-if="!isLiteMode && getActiveElements.length === 1")
        .controls__section
          p.title Flip
          .controls__buttons
            template(v-for="item in flip")
              FlipButton(
                :icon="item.icon"
                :title="item.title"
                :direction="item.direction"
              )

  template(
    v-if="!isLiteMode"
  )
    button.btn--text.controls__transparency-button.controls__general-icon(
      v-click-outside="onClickOutsideTransparency"
      type="button"
      data-title="Transparency"
      :class="{ 'is-active': isShowTransparencyTooltip }"
    )
      button.transparency-icon__button(
        type="button"
        @click="isShowTransparencyTooltip = !isShowTransparencyTooltip"
      )
        TransparencySvg

      TooltipMenu(
        :no-header="true"
        :no-tooltip-button="true"
        :is-show-tooltip="isShowTransparencyTooltip"
      )
        BaseSlider(
          :min="0"
          :max="100"
          :value="transparency"
          @update="setOpacity"
          suffix="%"
        )

    button.btn--icon.btn-duplicate-layer.js-duplicate-layer(
      type="button"
      data-title="Duplicate (Ctrl + D)"
      @click="duplicateLayers"
    )
      i.icon.icon-copy-line

    button.btn--icon.btn-delete-active-element.js-delete-active-element(
      type="button"
      data-title="Delete"
      @click="deleteElement"
    )
      i.icon.icon-delete

  GlobalEvents(
    v-if="getActiveElements.length && !isLiteMode"
    :filter="(event, handler, eventName) => allowGlobalEvents(event)"
    @keydown.ctrl.shift.37.prevent="alignElement('left')"
    @keydown.ctrl.shift.38.prevent="alignElement('top')"
    @keydown.ctrl.shift.39.prevent="alignElement('right')"
    @keydown.ctrl.shift.40.prevent="alignElement('bottom')"
    @keydown.ctrl.shift.186.prevent="alignElement('middle')"
    @keydown.ctrl.shift.222.prevent="alignElement('center')"
    @keydown.ctrl.219.exact.prevent="updateLayerIndex('backward')"
    @keydown.ctrl.shift.219.exact.prevent="updateLayerIndex('back')"
    @keydown.ctrl.221.exact.prevent="updateLayerIndex('forward')"
    @keydown.ctrl.shift.221.exact.prevent="updateLayerIndex('front')"
    @keydown.meta.shift.37.prevent="alignElementMac('left')"
    @keydown.meta.shift.38.prevent="alignElementMac('top')"
    @keydown.meta.shift.39.prevent="alignElementMac('right')"
    @keydown.meta.shift.40.prevent="alignElementMac('bottom')"
    @keydown.meta.shift.186.prevent="alignElementMac('middle')"
    @keydown.meta.shift.222.prevent="alignElementMac('center')"
    @keydown.prevent.meta.219.exact="updateLayerIndexMac('backward')"
    @keydown.prevent.meta.shift.219.exact="updateLayerIndexMac('back')"
    @keydown.prevent.meta.221.exact="updateLayerIndexMac('forward')"
    @keydown.prevent.meta.shift.221.exact="updateLayerIndexMac('front')"
  )
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { isNumber, isMac } from '@/assets/scripts/utilities';
import { minDuration, maxDuration } from '@/assets/scripts/variables';
import cloneDeep from 'lodash.clonedeep';
import { randomId } from '@/assets/scripts/utilities';
import { groups } from '@/assets/scripts/enums';
import { debounce } from 'debounce';

import TooltipMenu from './children/TooltipMenu.vue';
import AlignButton from './children/AlignButton.vue';
import FlipButton from './children/FlipButton.vue';
import RotateButton from './children/RotateButton.vue';
import LayerButton from './children/LayerButton.vue';
import LogoPositionControl from './children/LogoPositionControl.vue';
import FitToButton from './children/FitToButton.vue';
import TransparencySvg from './children/TransparencySvg.vue';

export default {
  name: 'ControlGeneral',
  components: {
    TooltipMenu,
    AlignButton,
    FlipButton,
    RotateButton,
    LayerButton,
    LogoPositionControl,
    FitToButton,
    TransparencySvg,
  },
  props: {
    isLiteVersion: { type: Boolean, default: false },
    isMedia: { type: Boolean, default: false },
    hiddenMenu: { type: Array, default: () => [] },
  },
  data() {
    return {
      layer: [
        {
          title: 'Forward',
          icon: 'icon-layer-up',
          direction: 'forward',
        },
        {
          title: 'Backward',
          icon: 'icon-layer-down',
          direction: 'backward',
        },
      ],
      rotate: [
        {
          title: 'Left',
          icon: 'icon-rotate-left',
          direction: 'ccw',
        },
        {
          title: 'Right',
          icon: 'icon-rotate-right',
          direction: 'cw',
        },
      ],
      flip: [
        {
          title: 'Horizontal',
          icon: 'icon-flip-vertical', // icon is wrong, so need to swap
          direction: 'x',
        },
        {
          title: 'Vertical',
          icon: 'icon-flip-horizontal', // icon is wrong, so need to swap
          direction: 'y',
        },
      ],
      alignment: [
        {
          title: 'Align left',
          icon: 'icon-align-left',
          direction: 'left',
        },
        {
          title: 'Align center horizontally',
          icon: 'icon-align-center-horizontal',
          direction: 'center',
        },
        {
          title: 'Align right',
          icon: 'icon-align-right',
          direction: 'right',
        },
        {
          title: 'Align top',
          icon: 'icon-align-top',
          direction: 'top',
        },
        {
          title: 'Align center vertically',
          icon: 'icon-align-center-vertical',
          direction: 'middle',
        },
        {
          title: 'Align bottom',
          icon: 'icon-align-bottom',
          direction: 'bottom',
        },
      ],
      fitTo: [
        {
          title: 'Width',
          icon: 'icon-fit-to-width',
          direction: 'width',
        },
        {
          title: 'Height',
          icon: 'icon-fit-to-height',
          direction: 'height',
        },
      ],
      opacity: 100,
      transparency: 0,
      isShowTransparencyTooltip: false,
    };
  },
  computed: {
    ...mapState(['animationList', 'showSwap', 'showCrop']),
    ...mapState('canvasElements', ['activeElementsIds']),
    ...mapGetters(['getCanvasZoom', 'isLiteMode']),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getSwappedElement',
      'getCanvasElements',
      'getCanvasElementsAnimation',
    ]),
    ...mapGetters('assetsSidebar', ['getActiveGroup']),
    ...mapGetters('userData', ['isAdmin', 'isDesigner', 'isMasterDesigner']),
    minAnimation() {
      return minDuration.fullAnimation;
    },
    checkActiveElements() {
      if (this.getActiveElements.length > 1) {
        return false;
      } else {
        return true;
      }
    },
    checkActiveElementHasText() {
      let activeElements = this.getActiveElements;
      let elementTypes = activeElements.map(function(value) {
        return value.type;
      });

      const allText = (elementTypes) => elementTypes.some((v) => v === 'texts');

      if (allText(elementTypes)) {
        return true;
      } else {
        return false;
      }
    },
    isMultiple() {
      return this.activeElementsIds.length > 1;
    },
    isGroupElements() {
      let isGroup = true;

      this.getActiveElements.forEach((element) => {
        if (element.data.isGroup === undefined || !element.data.isGroup) {
          isGroup = false;
        }
      });

      return isGroup;
    },
    showAddElementToPack() {
      try {
        if (this.getActiveElements.length === 1 && this.isDesigner) {
          const { file_id, menu } = this.getActiveElements[0];
          return file_id && menu === groups.MYFILES;
        }
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
  methods: {
    ...mapMutations([
      'setIsSwapOpened',
      'setActiveAnimationList',
      'setShowSidebar',
      'setShowDesignerElementUploadModal',
      'setDesignerElementUploadUserFile',
    ]),
    ...mapMutations('canvasElements', [
      'updateCanvasElementTime',
      'updateCurrentSceneDuration',
      'updateCanvasElementOpacity',
      'updateCanvasElementTimelineSettings',
      'updateCanvasElementIndex',
      'emptyActiveElements',
    ]),
    ...mapMutations('assetsSidebar', [
      'setActiveSubGroup',
      'setActiveGroup',
      'setActiveCategory',
      'clearActiveKeyword',
    ]),
    ...mapActions('canvasElements', [
      'setAllActiveElementsOpacity',
      'setAllActiveElementsAlignment',
      'deleteElementFromCanvas',
      'addElementsToGroup',
      'removeElementsFromGroup',
    ]),
    ...mapActions('canvasHistory', ['catchHistory']),
    allowGlobalEvents(event) {
      return event.target.tagName !== 'INPUT'
      && event.target.tagName !== 'TEXTAREA'
      && event.target.getAttribute('contenteditable') !== 'true'
      && !this.showSwap
      && !this.showCrop;
    },
    updateTransparency() {
      let opacity =
        this.getActiveElements.length === 0 ? 100 : this.getActiveElements[0].opacity * 100;
      this.transparency = Math.floor(100 - opacity);
    },
    alignElement(direction) {
      this.setAllActiveElementsAlignment(direction);
      setTimeout(() => {
        this.$root.$emit('canvasElementSizeUpdated');
      }, 10);
    },
    alignElementMac(direction) {
      if (!isMac()) return;
      this.alignElement(direction);
    },
    updateLayerIndex(direction) {
      console.log('updateLayerIndex', direction);
      this.updateCanvasElementIndex(direction);
      this.$root.$emit('canvasElementsReorder');
    },
    updateLayerIndexMac(direction) {
      if (!isMac()) return;
      this.updateLayerIndex(direction);
    },
    deleteElement() {
      this.getActiveElements.forEach((activeElement) => {
        this.deleteElementFromCanvas(activeElement.data.id);
      });
    },
    duplicateLayers() {
      this.$root.$emit('duplicate-layers');
    },
    checkIfNumber($event) {
      return isNumber($event);
    },
    triggerChange(value) {
      console.log('asd', value);
    },
    setOpacity(value) {
      const opacity = {
        opacity: Math.floor(100 - value) / 100,
      };
      this.catchHistory('element');
      this.setAllActiveElementsOpacity(opacity);
      this.updateTransparency();
    },
    showThisControlButton(text) {
      if (!this.isLiteMode) return true;
      if (this.hiddenMenu.includes(text) && this.isLiteVersion) return true;
      if (!this.hiddenMenu.includes(text) && !this.isLiteVersion) return true;
      return false;
    },
    groupElements() {
      if (!this.isMultiple || this.isGroupElements) return;

      const activeElements = cloneDeep(this.getActiveElements);
      const groupId = randomId();
      const groupNames = [];

      // if already have group name, use that name
      this.getCanvasElements.forEach((element) => {
        if (element.data.isGroup && element.data.groupName) {
          groupNames.push(element.data.groupName);
        }
      });

      // generate group name
      let i = 1;
      let groupName = '';

      while (true) {
        groupName = `Group ${i}`;
        if (groupNames.includes(groupName)) {
          i += 1;
          continue;
        }
        break;
      }

      // prepare elements to form group
      activeElements.forEach((element) => {
        element.data.isGroup = true;
        element.data.groupId = groupId;
        element.data.groupName = groupName;
        element.data.groupLock = false;
        element.data.groupShow = true;
        element.data.groupFold = false;
      });
      this.addElementsToGroup(activeElements);
    },
    ungroupElements() {
      const activeElements = cloneDeep(this.getActiveElements);
      const { groupId } = activeElements[0].data;

      activeElements.forEach((element) => {
        element.data.isGroup = false;
        element.data.groupId = null;
        element.data.groupName = null;
      });

      this.removeElementsFromGroup({ activeElements, groupId });
      this.emptyActiveElements();
    },
    onClickOutsideTransparency() {
      this.isShowTransparencyTooltip = false;
    },
    addActiveElementToPack() {
      try {
        this.setShowDesignerElementUploadModal(true);
        const activeElement = this.getActiveElements[0];
        this.setDesignerElementUploadUserFile(activeElement);
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    activeElementsIds: {
      handler(val) {
        if (this.getActiveElements.length) {
          this.updateTransparency();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.controls__general {
  display: flex;
  align-items: center;
  margin-left: auto;

  @include non-desktop {
    margin-left: 0;
  }

  .controls__buttons {
    &:not(.controls--align) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    &.fit-to {
      .btn--icon {
        width: calc(100% / 4);
        height: 33px;
        text-align: center;
        vertical-align: top;

        .icon-fit-to-width {
          font-size: inherit;
        }

        .icon-fit-to-height {
          font-size: initial;
        }
      }
    }
  }

  .btn-delete-active-element,
  .btn-duplicate-layer {
    width: 16px;
    height: 16px;
  }

  .btn-duplicate-layer {
    i {
      font-size: 1.3125rem;
    }
  }

  .btn-delete-active-element {
    i {
      color: var(--red-white);
      font-size: 1.3125rem;
    }
  }

  .controls__group-button,
  .controls__ungroup-button,
  .controls__add-pack-button {
    border-color: var(--blue800-white) !important;
    padding: 9px 8px;
    width: 80px;
    color: var(--blue800-white);

    &:hover {
      background: var(--blue800-white) !important;
      color: var(--white-darkgrey700);
    }
  }

  .controls__add-pack-button {
    width: 90px;
  }

  .controls__transparency-button {
    rect {
      fill: var(--darkgrey800-white) !important;
    }

    .tooltip-menu__content {
      width: 300px;
      right: -140px;
      top: 31px;
    }

    .transparency-icon__button {
      display: flex;
      align-items: center;
    }
  }
}

.tooltip--position {
  padding-top: 2px;

  .controls__section-wrapper {
    overflow: visible;
  }

  .title {
    margin: 0;
    font-size: 0.875em;
  }

  .icon-position {
    font-size: 1.3125rem !important;
    color: $darkGrey800;
    margin: 0;
  }

  .tooltip-menu__content {
    top: 48px;
    width: 250px;
    right: -123px;
    padding: 20px 0 15px;
  }

  @include fb-requirement {
    display: none !important;
  }
}

.controls {
  &__section {
    &-wrapper {
      overflow-y: auto;
      margin-right: -20px;
      padding-right: 20px;

      @include mobile {
        max-height: 48vh;
      }

      @include non-mobile {
        max-height: 66vh;
      }
    }

    .top-controls & {
      padding-right: 20px;
      padding-left: 20px;

      &:not(:last-child) {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid var(--lightgrey400-darkblue2);
      }

      .btn {
        margin: 0 7px 7px 0;
        text-align: left;
        padding: 7px;
        display: inline-block;
      }

      .icon {
        font-size: 1.375em;
      }
    }
  }
}

.control-general__fit-to-button,
.control-general__rotate-button,
.control-general__layer-button,
.control-general__flip-button,
.control-general__align-button {
  width: 50%;
  padding: 10px 5px;
  height: 34px;
  background: var(--lightgrey300-darkgrey);
  color: var(--darkgrey-white);

  &:hover {
    background: var(--lightgrey400-white);
    color: $darkGrey;
  }

  &:last-of-type {
    margin-right: 0 !important;
  }

  .btn__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    font-size: 1.0625rem;
  }

  span {
    font-size: 0.6875rem;
    font-weight: 400;
  }
}

.control-general__align-button {
  width: 31%;

  &:nth-of-type(3) {
    margin-right: 0 !important;
  }
}

.controls__section {
  .title {
    margin-bottom: 10px;
  }
}

.controls__general-icon {
  margin: 0 4px !important;
}
</style>
