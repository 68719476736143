<template lang="pug">
BaseButton.control-general__flip-button(
  :icon-before="icon"
  :is-canvas="true"
  @click="flipElement"
) {{ title }}
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'FlipButton',
  props: {
    icon: { type: String, default: '' },
    title: { type: String, default: '' },
    direction: { type: String, default: '' },
  },
  methods: {
    ...mapActions('canvasElements', ['updateCanvasElementFlip']),
    flipElement() {
      console.log('this', this.direction);
      this.updateCanvasElementFlip({ direction: this.direction });
      this.$root.$emit('canvasElementSizeUpdated');
    },
  },
};
</script>

<style lang="scss"></style>
