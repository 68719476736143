<template lang="pug">
.zoom__option
  //- Use this to check current canvas zoom
  //- input(
  //-   type="text"
  //-   :value="getCanvasZoom"
  //- )
  .zoom__container
    button.btn-toggle-zoom.bottom-bar__button(
      type="button"
      data-title="Change zoom"
      @click="showZoom(false)"
      @mouseenter="showZoom(false)"
      @mouseleave="hideZoom(false)"
    )
      i.icon.icon-zoom
    .slider-container.zoom__slider(
      :class="{ 'is-show': isShow, 'is-dark': isDarkMode }"
      @mouseenter="showZoom(true)"
      @mouseleave="hideZoom(true)"
    )
      .range-wrapper
        span.range-before(
          :style="zoomSliderStyle"
        )
        input.slider.js-slider.js-zoom-slider(
          type="range"
          step="5"
          min="10"
          max="200"
          ref="zoom-slider"
          v-model="currentZoom"
        )
      //- button.btn-canvas-zoom-fit(
      //-   type="button"
      //-   title="Fit to screen"
      //-   @click="zoomToFit"
      //- )
      //-   i.icon.icon-fit-to-screen
  GlobalEvents(
    v-if="!isLiteMode"
    :filter="(event, handler, eventName) => allowGlobalEvents(event)"
    @keydown.ctrl.187.prevent ="zoomIn"
    @keydown.ctrl.107.prevent ="zoomIn"
    @keydown.ctrl.-.prevent ="zoomOut"
    @keydown.ctrl.48.prevent ="zoomToFit"
    @keydown.ctrl.96.prevent ="zoomToFit"
    @keydown.meta.187.prevent ="zoomInMac"
    @keydown.meta.107.prevent ="zoomInMac"
    @keydown.meta.-.prevent ="zoomOutMac"
    @keydown.meta.48.prevent ="zoomToFitMac"
    @keydown.meta.96.prevent ="zoomToFitMac"
  )
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { debounce } from 'debounce';
import { isMac } from '@/assets/scripts/utilities';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'CanvasZoom',
  mixins: [darkModeMixin],
  props: {
    isShowMoreOptions: { type: Boolean, default: false },
  },
  data() {
    return {
      translateX: 100,
      isShow: false,
      isShowPersist: false,
    };
  },
  computed: {
    ...mapState(['showSwap', 'showCrop']),
    ...mapGetters(['getCanvasZoom', 'getCanvasZoomFit', 'isLiteMode']),
    ...mapGetters('canvasElements', ['getActiveElements']),
    currentZoom: {
      get() {
        return this.getCanvasZoom * 100;
      },
      set(value) {
        const zoom = value / 100;
        this.changeZoom(zoom);
      },
    },
    zoomSliderStyle() {
      const styleObject = {};

      styleObject.transform = `translateX(${this.translateX}%)`;

      return styleObject;
    },
  },
  methods: {
    ...mapMutations(['setCanvasZoom']),
    allowGlobalEvents(event) {
      return event.target.tagName !== 'INPUT'
      && event.target.tagName !== 'TEXTAREA'
      && event.target.getAttribute('contenteditable') !== 'true'
      && !this.showSwap
      && !this.showCrop;
    },
    zoomOut() {
      let newZoom = this.getCanvasZoom - 0.05;

      if (newZoom < 0.1) newZoom = 0.1;
      this.changeZoom(newZoom);
    },
    zoomOutMac() {
      if (!isMac()) return;
      this.zoomOut();
    },
    zoomIn() {
      let newZoom = this.getCanvasZoom + 0.05;

      if (newZoom > 2) newZoom = 2;
      this.changeZoom(newZoom);
    },
    zoomInMac() {
      if (!isMac()) return;
      this.zoomIn();
    },
    wheelZoom(e) {
      if (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) {
        e.preventDefault();
        if (e.wheelDelta / 120 > 0) {
          this.zoomIn();
        } else {
          this.zoomOut();
        }
      }
    },
    calculateTranslate() {
      const slider = this.$refs['zoom-slider'];
      const value = this.getCanvasZoom * 100;
      const min = parseInt(slider.getAttribute('min'), 10);
      const max = parseInt(slider.getAttribute('max'), 10);
      const percentage = ((value - min) / (max - min)) * 100;

      this.translateX = percentage;
    },
    zoomToFit() {
      this.changeZoom(this.getCanvasZoomFit);
    },
    zoomToFitMac() {
      if (!isMac()) return;
      this.zoomToFit();
    },
    changeZoom(zoom) {
      // console.log('zoom-updated');
      this.setCanvasZoom(zoom);

      if (this.getActiveElements.length) {
        // if there is active element, recalculate the resizer
        this.$root.$emit('canvasElementSizeUpdated');
      }
    },
    showZoom(persist) {
      if (persist) this.isShowPersist = true;
      else this.isShow = true;
      this.$emit('set-show-canvas-zoom', this.isShow);
    },
    // eslint-disable-next-line func-names
    hideZoom: debounce(function (removePersist) {
      if (removePersist) this.isShowPersist = false;
      else if (this.isShowPersist) return;

      this.isShow = false;
      this.$emit('set-show-canvas-zoom', this.isShow);
    }, 300),
    checkClickElement(e) {
      const isZoom = e?.path?.findIndex(path => (
        path?.className
            && typeof path?.className === 'string'
            && path?.className.indexOf('zoom__container') > -1
      )) > -1;

      if (!isZoom) {
        this.isShow = false;
      }
    },
  },
  watch: {
    getCanvasZoom() {
      this.calculateTranslate();
    },
    isShowMoreOptions: {
      handler(val) {
        if (val) this.isShow = false;
      },
      immediate: true,
    },
  },
  mounted() {
    document.body.addEventListener('mousedown', this.checkClickElement);
    document.body.addEventListener('wheel', this.wheelZoom, { passive: false });

    this.$root.$on('zoom-fit-to-screen', () => {
      this.zoomToFit();
    });
  },
};
</script>

<style lang="scss">
.btn-canvas-zoom-fit,
.btn-toggle-zoom {
  background: transparent;
}

.btn-toggle-zoom {
  padding: 0 !important;
  font-size: 24px !important;
  width: 25px !important;
  height: 25px !important;
  margin: 2px 4px !important;

  .icon-zoom {
    color: var(--black-white);
  }
}

.btn-canvas-zoom-fit {
  padding-right: 0;
  padding-left: 15px;
}

.zoom__container {
  position: relative;

  .slider-container {
    position: absolute;
    width: 280px;
    transition: transform 0.15s ease-in-out;
    top: -16px;
    left: 50%;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -90%);
    padding: 10px 20px;
    background: var(--lightgrey100-darkgrey600);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    &.is-show {
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, -100%);
    }

    &.is-dark {
      input[type='range']::-webkit-slider-thumb {
        background: $light !important;
      }

      .range-wrapper .range-before {
        background: $light;

        &:after {
          background: $lightGrey800;
        }
      }
    }

    .range-wrapper {
      max-width: none;
      width: 100%;
    }
  }
}
</style>
