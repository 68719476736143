<template lang="pug">
.brand-tab-color__palette(:class="{'is-edit-modal': isEditModal}")
  .color__header
    .color__title
      input(
        v-if="isEditModal"
        type="text"
        ref="name"
        v-model="paletteName"
        v-autowidth="{maxWidth: '350px', comfortZone: 0}"
        @dblclick="editName"
        @keyup.enter="$event.target.blur()"
        @blur="confirmName"
      )
      p.title(v-else) {{ paletteName }}

      button(
        v-if="isEditModal"
        type="button"
        title="Edit palette name"
        @click="editName"
      )
        i.icon.icon-edit

    button.color__delete(
      v-if="isEditModal"
      type="button"
      @click="deletePalette()"
    ) Delete

  .color-palette__box
    button.color__button.btn-add-color(
      v-if="!isMaximumColor && (isAdmin || isDesigner || !isTeamMember) && isEditModal"
      type='button'
      @click="addColor()"
    )
      i.icon.icon-plus

    div.color__button-container(
      v-for="(color, i) in colors"
      :key="`color-${i}`"
    )
      ColorButton(
        :class="{ 'is-active': i === activePaletteIndex && name === activePaletteName }"
        :color="color"
        :index="i"
        :is-palette-color="isEditModal"
        category="solid"
        @click="changeColor(i)"
        @delete="deleteColor(i)"
      )
      .edit-modal__colorpicker(v-if="isShowColorPicker(name, i)")
        ColorPicker(
          :color="defaultColor"
          :is-brand-palette="true"
          :brand-palette="selectedBrandPaletteColor"
        )
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'; // eslint-disable-line
import { maxColor } from '@/assets/scripts/variables';

import ColorButton from './ColorButton.vue';
import ColorPicker from '@/components/ProjectCanvas/AssetsSidebar/children/ColorPicker.vue';

export default {
  name: 'ColorPalette',
  components: {
    ColorButton,
    ColorPicker,
  },
  data() {
    return {
      isReadOnly: true,
      previousName: '',
      paletteName: '',
      selectedBrandPaletteColor: {},
    };
  },
  props: {
    name: { type: String, default: '' },
    index: { type: Number, default: 0 },
    colors: { type: Array, default: () => [] },
    isEditModal: { type: Boolean, default: false },
  },
  computed: {
    ...mapState(['showColorPicker']),
    ...mapState('team', ['teamId']),
    ...mapGetters('team', ['isTeamMember', 'getActiveTeamColors', 'getActiveTeamBrand', 'isInTeamWorkspace']),
    ...mapGetters('userData', ['isFreeUser', 'isDesigner', 'isAdmin']),
    ...mapGetters('canvasElements', ['getProjectBrandId']),
    activePaletteName() {
      return this.selectedBrandPaletteColor.name;
    },
    activePaletteIndex() {
      return this.selectedBrandPaletteColor.index;
    },
    defaultColor() {
      return this.selectedBrandPaletteColor.color || '#000000';
    },
    isMaximumColor() {
      const colorsLength = this.colors.length;
      let isMaximum = false;

      if (this.isInTeamWorkspace) {
        isMaximum = false;
      } else if (this.isFreeUser && colorsLength > maxColor.free) {
        isMaximum = true;
      } else if (!this.isFreeUser && colorsLength > maxColor.premium) {
        isMaximum = true;
      }
      return isMaximum;
    },
  },
  watch: {
    name: {
      handler(value) {
        this.paletteName = value;
        this.updatePreviousName(value);
      },
      immediate: true,
    },
    showColorPicker(val) {
      if (!val) {
        // since the "this.setShowColorPicker(false);" has been handled at CanvasContainer.vue
        this.selectedBrandPaletteColor = {};
      }
    },
  },
  methods: {
    ...mapMutations(['setShowColorPicker', 'setBottomRightNotif']),
    ...mapActions('team', [
      'addTeamColorAction',
      'deleteTeamColorAction',
      'deleteTeamPalette',
      'renameTeamPalette',
    ]),
    ...mapActions('assetsSidebar', ['promptConfirmDeleteUserFile']),
    editName() {
      this.isReadOnly = false;

      setTimeout(() => {
        this.$refs.name.focus();
      }, 1);
    },
    confirmName(event) {
      const newValue = event.target.value;
      const nameIsNotChanged = newValue === this.previousName;

      this.$refs.name.setSelectionRange(0, 0);
      this.isReadOnly = true;

      const selectedBrand = this.getActiveTeamBrand;
      const colorPalette = selectedBrand.colors && Object.values(selectedBrand.colors);
      const isPaletteNameExist = colorPalette.find(
        (palette) => palette.name.toLowerCase() === newValue.toLowerCase() // eslint-disable-line
      );

      if (!newValue || nameIsNotChanged) {
        this.paletteName = this.previousName;
      } else if (isPaletteNameExist && isPaletteNameExist !== {}) {
        this.paletteName = this.previousName;
        this.setBottomRightNotif({
          title: 'Hey there!',
          message: 'This palette name is already exist',
          duration: 5000,
          type: 'error',
        });
      } else {
        this.renameTeamPalette({
          index: this.index,
          name: newValue,
        });
      }
    },
    addColor() {
      this.addTeamColorAction({
        teamId: this.teamId,
        brandId: this.getProjectBrandId,
        paletteName: this.name,
      });

      this.$nextTick(() => {
        // auto open color picker when user add new color
        let index = 0;
        // eslint-disable-next-line
        this.getActiveTeamColors.find((color) => {
          if (color.name === this.name) {
            index = color.colors.length - 1;
          }
        });
        const colorObject = {
          name: this.name,
          color: '#000000',
          index,
        };

        this.openColorPicker(colorObject);
      });
    },
    deleteColor(index) {
      const colorObject = {
        teamId: this.teamId,
        brandId: this.getProjectBrandId,
        paletteName: this.name,
        index,
      };
      this.deleteTeamColorAction(colorObject);
    },
    changeColor(index) {
      const colorObject = {
        name: this.name,
        color: this.colors[index],
        index,
      };

      this.openColorPicker(colorObject);
    },
    deletePalette() {
      this.promptConfirmDeleteUserFile({
        itemId: this.name,
        isPalette: true,
      });
    },
    updatePreviousName(value) {
      this.previousName = value;
    },
    isShowColorPicker(paletteName, index) {
      return this.showColorPicker && this.activePaletteName === paletteName && this.activePaletteIndex === index;
    },
    openColorPicker(colorObject) {
      // colorObject consist of { name, color, index }
      this.selectedBrandPaletteColor = colorObject;
      this.setShowColorPicker(true);
    },
  },
};
</script>

<style lang="scss">
.brand-tab-color__palette {
  position: relative;
  width: 100%;

  &:last-of-type {
    margin-bottom: 20px;
  }

  &.is-edit-modal {
    width: auto;
    border: 1px solid $lightGrey400;
    padding: 15px 20px 12px;
    margin-bottom: 20px;
    border-radius: 4px;
    min-width: 325px;
    min-height: 100px;

    .color__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .color__title {
      input,
      button {
        color: var(--darkgrey900-white);
      }
    }

    .edit-modal__colorpicker {
      position: absolute;
      z-index: 30;
      top: 30px;
      left: 5px;
      width: 240px;
      min-height: 300px;

      .colorpicker {
        top: 0;
        left: 0;
        width: 100%;
      }

      .vc-input__input {
        margin: 0;
      }

      .tool-modal__main {
        background: var(--lightgrey-darkgrey600);
      }

      .vc-input__input {
        color: var(--darkgrey800-lightgrey400) !important;
      }
    }
  }

  .color__header {
    margin-bottom: 10px;
  }

  .color__title {
    max-width: calc(100% - 60px);

    input,
    button {
      background: transparent;
      color: $lightWhite;
    }

    input {
      padding: 2px 0;
      background: transparent;
      border: 0;
      outline: 0;
      border-bottom: 1px solid transparent;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:focus:not(:read-only) {
        border-color: $subBlue;
      }
    }

    .title {
      font-weight: 400;
      font-size: 0.875rem;
      margin: 0;
      padding: 2px 0;
      border-bottom: 1px solid transparent;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
     }
  }

  .color__delete {
    color: $salmon;
    font-size: 0.75rem;
  }

  .color-palette__box {
    display: flex;
    flex-wrap: wrap;

    .color__button-container {
      position: relative;
    }

    .color__button {
      &.is-active {
        transform: scale(1.1);
      }

      &:hover {
        background: $lightGrey300;
        transition: background 0.25s ease-in-out;
      }
    }

    .btn-add-color {
      color: var(--darkgrey800-white);
      border: 1px solid $lightGrey400;

      &:hover {
        background-color: $btnPrimaryHollowHover;
        color: $darkGrey800;
      }
    }
  }
}
</style>
