<template lang="pug">
.timeline-controller(
  :class="{ 'lite-mode': isLiteMode }"
  v-click-outside="onClickOutside"
)
  template(v-if="isCanvasLoading")
    BasePreloader.play-all__preloader(
      :type="2"
      :width="32"
    )
  template(v-else)
    button.bottom-bar__button.btn-first-frame.js-first-frame(
      v-if="!isLiteMode && !isMobile"
      type='button'
      @click="playFromBeginning"
      data-title="Play from beginning"
    )
      i.icon.icon-play-from-start

    .timeline-play__button
      button.bottom-bar__button.btn-play(
        :class="{ 'is-playing': getIsPlaying }"
        type="button"
        @click="playOnClick"
        data-title="Play (Spacebar)"
      )
        i.icon.icon-play
      span.btn-play__background

  .timeline-controller__time(
    v-if="!isLiteMode && !isMobile"
    @mouseover="isTimeShow = true"
    @mouseleave="getIsPlaying ? isTimeShow = true : isTimeShow = false"
  )
    input.timeline-controller__slider(
      type="range"
      step="0.1"
      min="0"
      ref="slider"
      :max="getCurrentSceneDuration"
      v-model.number="currentTime"
    )
    span.timeline-controller__number-wrapper
      span.timeline-controller__number(
        :class="{ 'is-show': isTimeShow }"
        v-html="timelineTime.toFixed(1)"
        :style="timeStyleObject"
      )
  audio#selected-audio(
    :src="getSelectedMusic.url"
    ref="selectedAudio"
    @play="audioOnPlay"
    @timeupdate="fadeAudioEffect"
  )
  GlobalEvents(
    v-if="!isDownloadPreviewOpened && !showTrimOverlay && !showGenieModal && !isTrimmingVideosBeforeUpload"
    :filter="(event, handler, eventName) => allowGlobalEvents(event)"
    @keydown.prevent.space.exact="checkIfPlaying"
    @keydown.prevent.space.ctrl.exact="playTimelineController"
    @keydown.prevent.space.meta.exact="playTimelineControllerMac"
  )
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { Linear, TimelineLite } from 'gsap/TweenMax';
import { isMac } from '@/assets/scripts/utilities';
import { fauxZero } from '@/assets/scripts/variables';

let timelineControllerTimeout;

export default {
  name: 'TimelineController',
  props: {
    isMobile: { type: Boolean, default: false },
  },
  data() {
    return {
      fadeInStart: false,
      fadeOutAudiointervalID: '',
      fadeInAudiointervalID: '',
      fadeOutInterval: 75,
      fadeOutDuration: 1.5,
      fadeInAudioStart: 1.5,
      timelineBar: new TimelineLite(),
      isTimeShow: false,
      hasFadeIn: false,
      hasFadeOut: false,
      timeStart: 0,
      timeEnd: 0,
      timelineTime: 0,
    };
  },
  computed: {
    ...mapState([
      'showTrim',
      'showTrimOverlay',
      'showGenieModal',
      'showCrop',
      'showSwap',
      'isCanvasLoading',
    ]),
    ...mapState('canvasElements', ['isTrimmingMusic', 'previousSceneDuration']),
    ...mapState('assetsSidebar', ['isTrimmingVideosBeforeUpload']),
    ...mapGetters([
      'getCurrentTime',
      'getIsPlaying',
      'getIsTempAudioPlaying',
      'isDownloadPreviewOpened',
      'showStoryboardOverlay',
      'isLiteMode',
    ]),
    ...mapGetters('canvasElements', [
      'getMainTimeline',
      'getCurrentSceneDuration',
      'getSelectedMusic',
      'getCurrentAudioTime',
      'getAllScenesDuration',
      'isDoingSplitText',
    ]),
    ...mapGetters('exportDetails', ['getExportDetailStates']),
    currentTime: {
      get() {
        return this.timelineTime;
      },
      set(val) {
        // console.log('setting time', val);
        this.timelineTime = val;
        this.pauseCanvas();

        // trigger blur so that spacebar will trigger playback
        this.$refs.slider.blur();
      },
    },
    timeStyleObject() {
      const styleObject = {};
      const percentage = this.timelineTime / this.getCurrentSceneDuration;

      styleObject.left = `${percentage * 100}%`;
      return styleObject;
    },
  },
  methods: {
    ...mapMutations('canvasElements', ['updateTrimmedElement', 'setPreviousSceneDuration']),
    ...mapMutations([
      'isPlaying',
      'setCurrentTime',
      'setShowTrim',
      'setIsShowMusicToolbarTrimmer',
      'setIsDownloadPreviewOpened',
      'setIsProjectPreview',
      'setDownloadPreviewOption',
      'setIsMusicTrimToolbarLoading',
    ]),
    allowGlobalEvents(event) {
      return event.target.tagName !== 'INPUT'
      && event.target.tagName !== 'TEXTAREA'
      && event.target.getAttribute('contenteditable') !== 'true'
      && !this.showSwap
      && !this.showCrop;
    },
    calculateInputProgress(currentDuration, allDuration) {
      // Calculate for the left and right line
      document.querySelectorAll('.timeline-controller__slider').forEach((el) => {
        // eslint-disable-next-line
        el.oninput = function () {
          const valPercent = currentDuration / allDuration;
          const style = `background-image: -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${
            valPercent
          }, var(--black-white)), color-stop(${
            valPercent
          }, var(--lightgrey700-darkgrey900)));`;
          // eslint-disable-next-line
          el.style = style;
        };
        el.oninput();
      });

      // Set the previous duration to be default or 0
      if (this.previousSceneDuration !== 0) this.setPreviousSceneDuration(0);
    },
    playOnClick() {
      // console.log('playOnClick', this.isLiteMode);
      if (this.isLiteMode || this.isMobile) {
        // for lite mode, this button is to play from beginning
        if (this.getIsPlaying) {
          this.isPlaying(false);
        } else if (this.isMobile) {
          this.$root.$emit('canvasElementSizeUpdated');
          this.$nextTick(() => {
            this.playFromBeginning();
          });
        } else {
          this.playFromBeginning();
        }
        return;
      }

      this.playTimelineController();
    },
    checkIfPlaying() {
      if (this.getExportDetailStates.isExporting) return;

      if (this.getIsPlaying) {
        this.playTimelineController();
      } else {
        this.playFromBeginning();
      }
    },
    playFromBeginning() {
      if (this.isCanvasLoading) return;

      // need to start from 0.05 to avoid flicker
      this.setCurrentTime(fauxZero);
      this.isPlaying(false);
      this.playTimelineController();
    },
    playTimelineController() {
      // console.log('playTimelineController', this.getIsPlaying, this.showStoryboardOverlay);
      if (!this.isMobile && this.showStoryboardOverlay) return;

      if (this.getIsPlaying) {
        // everything will be handled by watcher
        this.isPlaying(false);
      } else {
        let currentAnimationTime = this.getCurrentTime;
        // console.log('playTimelineController', currentAnimationTime, 'getCurrentSceneDuration', this.getCurrentSceneDuration);

        // if the current time is already the end of the duration or end - 0.1,
        // play from beginning
        if (currentAnimationTime >= this.getCurrentSceneDuration - 0.1) currentAnimationTime = 0;
        if (currentAnimationTime < 0.1) currentAnimationTime = fauxZero;

        // console.log('play currentAnimationTime', currentAnimationTime);

        this.timelineBar.seek(currentAnimationTime);
        this.getMainTimeline.seek(currentAnimationTime);
        const stateCheck = setInterval(() => {
          if (!this.isDoingSplitText) {
            clearInterval(stateCheck);
            this.isPlaying(true);
            this.timelineBar.play();
            this.getMainTimeline.play();
            // this.$refs.selectedAudio.play();
            this.playAudio();
          }
        }, 100);
      }
    },
    playTimelineControllerMac() {
      if (!isMac()) return;
      this.playTimelineController();
    },
    openProjectPreview() {
      this.setIsDownloadPreviewOpened(true);
      this.setIsProjectPreview(true);
      this.setDownloadPreviewOption('video');

      // pause temp audio and canvas when opening project preview
      this.$root.$emit('pause-all-audio');
      if (this.getIsPlaying) this.playTimelineController();
    },
    setTimelineControllerTimeline() {
      const timeObj = { time: 0 };
      const totalDuration = this.getCurrentSceneDuration;

      if (this.isMobile) {
        // console.log('this.getCurrentSceneDuration * 0.9', this.getCurrentSceneDuration * 0.9);
        // for mobile, when paused, always shows 90%
        const defaultTime = this.getCurrentSceneDuration * 0.9;
        this.setCurrentTime(defaultTime);
        this.timelineTime = defaultTime;
      }
      // console.log('timeline controller mounted', this.getCurrentSceneDuration);

      this.timelineBar.kill().clear();

      const updateTime = () => {
        // console.log('timeObj', timeObj.time, Math.round(timeObj.time * 10) / 10)
        this.timelineTime = Math.round(timeObj.time * 10) / 10;
        // this.setCurrentTime(parseFloat(timeObj.time.toFixed(1)));
      };

      const completeTime = () => {
        // console.log('completeTime', this.timelineBar._time);
        this.timelineBar.pause();
        this.getMainTimeline.pause();
        this.timelineBar.seek(fauxZero);
        this.getMainTimeline.seek(fauxZero);
        this.setCurrentTime(0);
        this.isPlaying(false);
      };

      const time = Math.round((totalDuration - fauxZero) * 100) / 100; // make sure it rounds to 0.05 instead of 0.049999

      this.timelineBar.addLabel('Start', 0);
      this.timelineBar.to(
        timeObj,
        totalDuration,
        {
          time: `+=${time}`,
          onUpdate: updateTime,
          onComplete: completeTime,
          ease: Linear.easeNone,
        },
        'Start',
      );
      this.timelineBar.pause();

      this.timelineBar.seek(this.getCurrentTime);
    },
    pauseAudio() {
      if (this.$refs.selectedAudio) {
        this.$refs.selectedAudio.pause();
      }
    },
    playAudio() {
      // console.log('play audio', this.getSelectedMusic.url);
      if (!this.getSelectedMusic.url) return;

      if (parseInt(this.getSelectedMusic.volume, 10) === 0) return;

      if (!this.getIsPlaying) {
        // pause audio
        this.pauseAudio();
      }

      this.hasFadeIn = this.getSelectedMusic.fade_in || false;
      this.hasFadeOut = this.getSelectedMusic.fade_out || false;
      this.volume = parseFloat(this.getSelectedMusic.volume / 100) || 1;
      this.timeEnd = this.getAllScenesDuration; // get current scene duration

      const splitTime = this.getSelectedMusic.url.split('#t=');
      if (splitTime.length > 1) {
        const time = splitTime[1].split(',');
        // eslint-disable-next-line
        (this.timeStart = parseFloat(time[0])), (this.timeEnd = parseFloat(time[1]));
      }

      this.$refs.selectedAudio.currentTime = this.getCurrentAudioTime;

      // fixed fade in not working if trimmed
      this.fadeInAudioStart = this.timeStart + 1.5;

      // console.log('play audio ---->', this.timeStart, this.getCurrentAudioTime, this.$refs.selectedAudio.currentTime, this.fadeInAudioStart);

      if (this.$refs.selectedAudio.currentTime < this.fadeInAudioStart && this.hasFadeIn) {
        this.$refs.selectedAudio.volume = 0; // start 0 for fade in
      } else {
        this.$refs.selectedAudio.volume = this.volume;
      }
      if (this.getSelectedMusic.url) this.$refs.selectedAudio.play();
    },
    audioOnPlay() {
      if (!this.getSelectedMusic.url) return;

      const maxVol = this.volume;
      const audioDOM = this.$refs.selectedAudio;
      let tmpVolume = 0;

      /**
       * AUDIO FADE IN EFFECT
       */
      if (
        this.getCurrentAudioTime < this.fadeInAudioStart
        && this.hasFadeIn
        && this.fadeInStart === false
      ) {
        audioDOM.volume = 0;
        this.fadeInAudiointervalID = setInterval(() => {
          if (tmpVolume < maxVol) {
            tmpVolume += 0.05;
            audioDOM.volume = tmpVolume.toFixed(2);
            // console.log('audio has fadein', audioDOM.volume);
          } else {
            audioDOM.volume = maxVol;
            this.fadeInStart = false;
            clearInterval(this.fadeInAudiointervalID);
            // console.log('audio fadein clear interval');
          }
        }, this.fadeOutInterval);
      } else {
        audioDOM.volume = maxVol;
        clearInterval(this.fadeInAudiointervalID);
      }
    },
    fadeAudioEffect() {
      if (!this.getSelectedMusic.url) return;

      let tmpVolume = this.volume;
      const maxVol = this.volume;
      let fadeOutTime = parseFloat(this.getAllScenesDuration - this.fadeOutDuration);
      const audioDOM = this.$refs.selectedAudio;

      if (this.timeStart) {
        fadeOutTime += this.timeStart; // offset when there is start time
      }

      // console.log('fadeAudioEffect', fadeOutTime, audioDOM.currentTime, this.getCurrentAudioTime, this.hasFadeOut, this.fadeOutInterval);

      /**
       * AUDIO FADE OUT EFFECT
       */
      if (audioDOM.currentTime >= fadeOutTime && this.hasFadeOut) {
        if (audioDOM.volume === tmpVolume) {
          audioDOM.volume = maxVol;
          this.fadeInStart = false;
          clearInterval(this.fadeInAudiointervalID);
          this.fadeOutAudiointervalID = setInterval(() => {
            // Reduce volume by 0.05 as long as it is above 0
            // This works as long as you start with a multiple of 0.05!
            if (tmpVolume > 0) {
              tmpVolume -= 0.05;
              // tmpVolume -= 0.1; //  0.1 for 1 sec duration
              // limit to 2 decimal places
              // also converts to string, works ok

              if (tmpVolume <= 0) {
                audioDOM.volume = 0;
              } else {
                audioDOM.volume = tmpVolume.toFixed(2);
              }
            } else {
              // Stop the setInterval when 0 is reached
              clearInterval(this.fadeOutAudiointervalID);
            }
          }, this.fadeOutInterval);
        }
      }
    },
    clearAudioFadeIntervals() {
      this.fadeInStart = false;
      clearInterval(this.fadeInAudiointervalID);
      clearInterval(this.fadeOutAudiointervalID);
    },
    pauseCanvas() {
      this.timelineBar.pause();
      this.getMainTimeline.pause();
      let time = this.timelineTime;
      if (time === this.getCurrentSceneDuration) {
        time = Math.round((time - fauxZero) * 100) / 100; // make sure it rounds to 0.05 instead of 0.049999
      }

      if (this.isMobile) {
        // console.log('this.getCurrentSceneDuration * 0.9', this.getCurrentSceneDuration * 0.9);
        // for mobile, when paused, always shows 90%
        time = this.getCurrentSceneDuration * 0.9;
      }
      this.setCurrentTime(time);

      if (this.getSelectedMusic.url) {
        this.pauseAudio();
        this.clearAudioFadeIntervals();
      }
    },
    onPauseAllAudio() {
      if (this.getIsPlaying) this.playTimelineController();
    },
    showTrimmer() {
      if (!this.getSelectedMusic.url) return;

      const selectedMusic = this.getSelectedMusic;
      const splitTime = selectedMusic.url.split('#t=');
      let timeStart = 0;
      let timeEnd = this.$refs.selectedAudio.duration;
      const maxDuration = this.$refs.selectedAudio.duration;

      if (splitTime.length > 1) {
        const time = splitTime[1].split(',');
        timeStart = parseFloat(time[0]);

        if (typeof time[1] !== 'undefined') timeEnd = parseFloat(time[1]);
      }

      if (timeEnd - timeStart > this.getAllScenesDuration) timeEnd = parseFloat(timeStart + this.getAllScenesDuration);

      selectedMusic.type = 'audio';
      selectedMusic.max_duration = maxDuration;
      selectedMusic.data = {
        time_start: timeStart,
        time_end: timeEnd,
      };

      this.setShowTrim(true);
      this.setIsShowMusicToolbarTrimmer(true);
      this.updateTrimmedElement(selectedMusic);

      // pause temp audio and canvas when trimming audio
      this.$root.$emit('pause-all-audio');
    },
    onClickOutside() {
      if (this.isMobile) {
        this.pauseCanvas();
        this.$root.$emit('pause-all-audio');
      }
    },
  },
  beforeDestroy() {
    this.$root.$off('playAudio', this.playAudio);
    this.$root.$off('pauseAudio', this.pauseAudio);
    this.$root.$off('pause-all-audio', this.onPauseAllAudio);
  },
  mounted() {
    // console.log('<==> setTimelineControllerTimeline')
    this.$root.$on('playAudio', this.playAudio);
    this.$root.$on('pauseAudio', this.pauseAudio);
    this.$root.$on('pause-all-audio', this.onPauseAllAudio);
  },
  watch: {
    getIsPlaying(val) {
      this.isTimeShow = val;
      // this is handled by watcher because music and other section can pause the canvas
      if (!val) {
        this.pauseCanvas();
      }
    },
    getIsTempAudioPlaying() {
      if (this.getIsPlaying && this.getIsTempAudioPlaying) {
        this.checkIfPlaying();
      }
    },
    getCurrentSceneDuration: {
      handler(val) {
        let currentDuration = this.currentTime;
        if (this.previousSceneDuration !== 0) {
          const previousDurationPercentage = val / (this.previousSceneDuration / this.currentTime);
          currentDuration = previousDurationPercentage;
        }

        this.timelineTime = currentDuration;
        this.calculateInputProgress(currentDuration, val);
        clearTimeout(timelineControllerTimeout);
        timelineControllerTimeout = setTimeout(() => {
          this.setTimelineControllerTimeline();
        }, 500);
      },
      immediate: true,
    },
    currentTime(val) {
      this.calculateInputProgress(val, this.getCurrentSceneDuration);
    },
    getCurrentTime(val) {
      let currentAnimationTime = val;
      if (currentAnimationTime < 0.1) currentAnimationTime = fauxZero;
      if (currentAnimationTime === this.getCurrentSceneDuration) {
        currentAnimationTime = Math.round((currentAnimationTime - fauxZero) * 100) / 100; // make sure it rounds to 0.05 instead of 0.049999
      }

      this.timelineTime = val;
      this.timelineBar.seek(val);
      this.getMainTimeline.seek(currentAnimationTime);
    },
    timelineTime(val) {
      this.$emit('update-time', val);
    },
    getSelectedMusic(val) {
      // fadein fix for audio with default time start
      if (val.url) {
        const splitTime = this.getSelectedMusic.url.split('#t=');
        if (splitTime.length > 1) {
          const time = splitTime[1].split(',');
          this.fadeInAudioStart = parseFloat(time[0]) + 1.5;
        } else {
          this.fadeInAudioStart = 1.5;
        }
      }
    },
    'getSelectedMusic.url': {
      handler() {
        if (!this.isTrimmingMusic) {
          this.setIsMusicTrimToolbarLoading(true);

          const audio = this.$refs.selectedAudio;
          audio.onloadedmetadata = () => {
            this.showTrimmer();
          };
        }
      },
    },
  },
};
</script>

<style lang="scss">
.timeline-controller {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 320px);
  z-index: 25;
  margin-left: 5px;

  &.lite-mode {
    width: 100% !important;
    justify-content: flex-end !important;

    .bottom-bar__button {
      width: 60px !important;
      height: 60px !important;
      margin-bottom: 20px !important;
    }

    .btn-play {
      width: 60px !important;
      height: 60px !important;

      .icon {
        font-size: 60px !important;
        line-height: 60px !important;
      }
    }

    .btn-play__background {
      top: 8px !important;
      left: 6.5px !important;
      width: 42px !important;
      height: 42px !important;
    }
  }

  .bottom-bar.has-more-options & {
    width: calc(100% - 160px);

    @include fb-requirement {
      width: 100%;
    }
  }

  .timeline-controller__time {
    width: 100%;
    position: relative;
    margin-left: 12px;

    @include fb-requirement {
      width: 100%;
      margin-left: 10px;
    }
  }

  .timeline-controller__number-wrapper {
    position: absolute;
    width: calc(100% - 10px);
    margin: 0 5px;
    display: block;
    top: 0;
    left: 0;
  }

  .timeline-controller__number {
    position: absolute;
    width: 50px;
    height: 30px;
    color: var(--darkgrey800-white);
    text-align: center;
    font-size: 0.875rem;
    pointer-events: none;
    left: 0;
    top: -25px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.15s linear;
    display: block;
    margin-left: 0px;
    padding: 6px;
    background: var(--lightgrey600-darkgrey4);
    border-radius: 100px;

    &.is-show {
      opacity: 1;
    }
  }

  .timeline-controller__slider {
    margin: 20px 0;
    transition: all 0.15s linear;
    position: relative;
    z-index: 2;
    border-radius: 5px;

    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    height: 3px;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    &:focus {
      outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
    }

    &::-ms-track {
      width: 100%;
      cursor: pointer;

      /* Hides the slider so custom styles can be added */
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: var(--darkgrey800-white);
      cursor: pointer;
      margin-top: -6px;
      /* You need to specify a margin in Chrome,
    but in Firefox and IE it is automatic */
      transition: transform 0.3s cubic-bezier(0, 0, 0, 1);

      &:hover {
        transform: scale(1.3);
      }
    }

    &::-moz-range-thumb,
    &::-ms-thumb {
      border: 1px solid var(--darkgrey800-white);
      height: 15px;
      width: 15px;
      border-radius: 15px;
      background: var(--darkgrey800-white);
      cursor: pointer;
      transition: transform 0.3s cubic-bezier(0, 0, 0, 1);

      &:hover {
        transform: scale(1.3);
      }
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 7px;
      cursor: pointer;
      box-shadow: none;
      background: transparent;
      border-radius: 5px;
    }

    &:focus::-webkit-slider-runnable-track {
      background: transparent;
    }

    &::-moz-range-track {
      width: 100%;
      height: 7px;
      cursor: pointer;
      box-shadow: none;
      background: transparent;
      border-radius: 5px;
    }

    &::-ms-track {
      width: 100%;
      height: 7px;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      border-width: 16px 0;
      color: transparent;
    }
    &::-ms-fill-lower {
      background: transparent;
      border: 0.2px solid var(--darkgrey800-white);
      border-radius: 5px;
      box-shadow: none;
    }
    &:focus::-ms-fill-lower {
      background: transparent;
    }
    &::-ms-fill-upper {
      background: transparent;
      border: 0.2px solid var(--darkgrey800-white);
      border-radius: 5px;
      box-shadow: none;
    }
    &:focus::-ms-fill-upper {
      background: transparent;
    }
  }

  .timeline-controller__button {
    padding: 2px;
    font-size: 0.75em;
    background: transparent;
    display: inline-flex;
    margin: 0;
    margin-left: 5px;
    height: 22px;
    justify-content: center;
    align-items: center;
    color: var(--darkgrey800-white);
    transition: color 0.25s ease-in-out;
    cursor: pointer;
    vertical-align: middle;
    border: 0;

    &:hover {
      .icon {
        color: var(--darkgrey800-white);
      }
    }

    .icon {
      font-size: 1.125rem;
      color: var(--darkgrey800-white);
    }

    &.btn-play-all {
      font-weight: 600;

      @include fb-requirement {
        display: none;
      }
    }
  }

  .bottom-bar__button {
    position: relative;
    top: 0;
    left: 0;
    width: 38px;
    height: 38px;
    padding: 0;
    font-size: 2.375rem;

    &.btn-first-frame {
      background: transparent;
      color: var(--black-white);
      width: 34px;
      height: 34px;
      padding: 4px;
      font-size: 25px;
    }

    &.btn-play {
      width: 34px;
      height: 34px;
      z-index: 3;
      background: none;
      border-radius: 50%;
      color: $componentBlue;
      transform-origin: center center;
      transition: color 0.3s cubic-bezier(0, 0, 0, 1), transform 0.3s cubic-bezier(0, 0, 0, 1);

      &::after {
        // tooltip when hover
        font-size: 0.65rem;
        height: 14px;
        top: -23px;
      }

      .icon {
        line-height: 26px;
      }

      &.is-playing,
      &:hover {
        color: $blue500;
      }

      &:active {
        color: $blue800;
      }

      &:hover {
        transform: scale(1.15);
      }

      &.is-playing {
        .icon::before {
          content: $icon-pause;
        }
      }
    }
  }
  .play-all__preloader {
    width: 68px;
    padding: 0;
  }

  .timeline-play__button {
    position: relative;
  }

  .btn-play__background {
    position: absolute;
    top: 4px;
    left: 6px;
    width: 28px;
    height: 28px;
    background: $light;
    border-radius: 50%;
    z-index: 1;
  }
}
</style>
