<template lang="pug">
.layer-empty-state
  p.text No Layers Found
  BaseButton.add-graphics__button(
    is-primary=true
    :is-canvas="true"
    @click="openGraphicsTab"
  ) Add Graphics
</template>

<script>
import { mapMutations } from 'vuex';
import { groups } from '@/assets/scripts/enums';

export default {
  name: 'LayerEmptyState',
  methods: {
    ...mapMutations(['setShowSidebar']),
    ...mapMutations('assetsSidebar', [
      'setActiveGroup',
      'clearActiveKeyword',
      'clearActiveCategory',
    ]),
    openGraphicsTab() {
      // clear out things in sidebar on switching to graphics tab
      this.clearActiveKeyword();
      this.clearActiveCategory();

      this.setShowSidebar(true);
      this.setActiveGroup(groups.ELEMENTS);
    },
  },
};
</script>

<style lang="scss">
.layer-empty-state {
  text-align: center;
  padding: 10px 10px 20px;
  width: 209px;
  line-height: 1.3;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  position: relative;
  z-index: 4;
  margin-top: -15px;

  .text {
    font-size: 0.875rem;
    font-weight: 600;
    margin-top: 10px;
    color: var(--darkgrey-white);
  }

  .add-graphics__button {
    padding-left: 15px;
    padding-right: 15px;
    height: 38px;
    width: 80%;
    background: $blue3;
    font-size: 0.8rem;

    &:hover {
      color: $blue3;
    }
  }
}
</style>
