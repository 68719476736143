import cloneDeep from 'lodash.clonedeep';
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';
import { maxDuration } from '@/assets/scripts/variables';

const templateMixin = {
  data() {
    return {
      isSelected: false,
      tempProject: {}, // this is for smart resize
    };
  },
  computed: {
    ...mapState([
      'isTemplate',
    ]),
    ...mapState('canvasElements', [
      'hasAutoWidthElement',
    ]),
    ...mapGetters(['isLiteMode']),
    ...mapGetters('canvasElements', [
      'getScenes',
    ]),
    // maxDurationProject is also in lite mode and new scene mixins
    maxDurationProject() {
      if (this.isLiteMode) {
        return maxDuration.liteProject;
      }
      return this.isFreeUser ? maxDuration.freeUserProject : maxDuration.payingUserProject;
    },
  },
  methods: {
    ...mapMutations([
      'setShowSidebar',
      'setShowCanvasPreloader',
      'setShowDesignContainer',
    ]),
    ...mapMutations('assetsSidebar', [
      'setActiveGroup',
    ]),
    ...mapMutations('canvasElements', [
      'updateActiveSceneId',
      'updateCurrentSceneDuration',
      'updateCanvasSize', // mutation so that it doesn't save history
      'updateProjectDetails',
      'updateHasAutoWidthElement',
      'updateLastSelectedTemplateId',
    ]),
    ...mapActions('canvasElements', [
      'updateScenesElements',
    ]),
    applyDesign(data) {
      console.log('appyDesign');
      this.isSelected = true;
      const { response, assignModularAsLastTemplate } = data;
      const projectDetails = response.data.results;
      const assignNewId = true;

      let hasAuto = false;

      // if the size change, check hasAutoWidthElement
      if (
        !(typeof projectDetails.selected_template !== 'undefined'
        && projectDetails.selected_template.canvas_width === projectDetails.canvas_width
        && projectDetails.selected_template.canvas_height === projectDetails.canvas_height)
      ) {
        const scenesCopy = cloneDeep(projectDetails.scenes);

        for (let i = 0; i < scenesCopy.length; i += 1) {
          const { elements } = scenesCopy[i];

          for (let j = 0; j < elements.length; j += 1) {
            const element = elements[j];

            if (element.size.width === 'auto'
              || element.size.height === 'auto'
              || typeof element.position.x === 'undefined'
              || typeof element.position.y === 'undefined') {
              hasAuto = true;
            }
          }
        }

        console.log('hasAuto is', hasAuto);
        this.updateHasAutoWidthElement(hasAuto);
      }

      this.setShowSidebar(true);
      this.setActiveGroup('texts');

      this.updateScenesElements({
        assignNewId,
        newScenes: cloneDeep(projectDetails.scenes),
        assignModularAsLastTemplate,
      });

      // for lite mode, need to check total scenes
      if (this.isLiteMode) {
        let totalDuration = 0;
        for (let i = 0; i < this.getScenes.length; i += 1) {
          let { duration } = this.getScenes[i];
          if (duration > maxDuration.liteScene) {
            duration = maxDuration.liteScene;
          }
          totalDuration += duration;
        }
        projectDetails.duration = totalDuration;
      }

      this.updateProjectDetails(projectDetails);

      let activeSceneId = this.getActiveSceneId;

      if (typeof response.data.current_scene_id !== 'undefined' && response.data.current_scene_id !== '') {
        activeSceneId = response.data.current_scene_id;
      }
      console.log('activeSceneId', activeSceneId, this.getActiveSceneId);

      // if (isReplace) {
      //   activeSceneId = this.getActiveSceneId;
      // }

      if (this.isAddNewScene) {
        activeSceneId = projectDetails.scenes[projectDetails.scenes.length - 1].id;
      }

      this.updateActiveSceneId(activeSceneId);

      // check for the overshot duration
      const maxDurationAllowed = this.maxDurationProject;
      const overDuration = response.data.results.duration - maxDurationAllowed;

      console.log('overDuration', overDuration, maxDurationAllowed, this.getCurrentSceneDuration);
      // if overshot, need to replace the duration
      if (overDuration > 0 && overDuration < this.getCurrentSceneDuration) {
        const newSceneDuration = this.getCurrentSceneDuration - overDuration;
        this.updateCurrentSceneDuration(newSceneDuration);
      }

      // lastSelectedTemplateId 0 means  that the scenes container will open design templates instead of modular modal
      // eslint-disable-next-line
      const lastSelectedTemplateId = typeof projectDetails.selected_template !== 'undefined' && assignModularAsLastTemplate ? projectDetails.selected_template.id : 0;

      this.updateLastSelectedTemplateId(lastSelectedTemplateId);

      this.tempProject = {
        width: projectDetails.canvas_width,
        height: projectDetails.canvas_height,
        oldWidth: projectDetails.canvas_width,
        oldHeight: projectDetails.canvas_height,
        sceneId: activeSceneId,
      };

      if (typeof projectDetails.selected_template !== 'undefined') {
        this.tempProject.oldWidth = projectDetails.selected_template.canvas_width;
        this.tempProject.oldHeight = projectDetails.selected_template.canvas_height;
      }
      console.log('updateCanvasSizeAndShow', hasAuto, this.hasAutoWidthElement);

      // for smart resize,
      // it wouldn't work if there is auto width or height
      if (!hasAuto) {
        this.updateCanvasSizeAndShow();
      }
    },
    updateCanvasSizeAndShow() {
      this.setShowDesignContainer(false);
      this.setShowSidebar(true);
      this.setActiveGroup('texts');

      // update new size
      this.updateCanvasSize(this.tempProject);

      // console.log(this.getActiveGroup)
      setTimeout(() => {
        this.setShowCanvasPreloader(false);
        this.$root.$emit('canvas-container-reset-timeline');
      }, 2000);
    },
  },
  watch: {
    hasAutoWidthElement(val) {
      console.log('this.isSelected', this.isSelected);
      if (this.isSelected && !val) {
        console.log('hasAuto', val);
        this.updateCanvasSizeAndShow();
      }
    },
  },
};

export default templateMixin;
