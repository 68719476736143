<template lang="pug">
.branded-style__container--user
  template(v-if="hasSetupBrand")
    template(v-for="item in group")
      .branded-style__group(
        :key="item.key"
      )
        .branded-style__header
          span.brand-name-title {{ item.name }}
          BaseButton.btn-reset-color(
            v-if="item.key === 'color'"
            isText=true
            @click="resetColor"
          ) Reset

        template(v-if="item.key === 'color'")
          .brand-color__container
            .brand-colors
              .brand-color.light(:style="{background: colorLight}")
              .brand-color.vibrant(:style="{background: colorVibrant}")
              .brand-color.dark(:style="{background: colorDark}")
            BaseButton.btn-cycle-color(
              @click="cycleColor"
              isPrimary=true
              iconBefore="icon-cycle"
            ) Cycle

        template(v-else-if="item.key === 'font'")
          ul.grid-lists(
            ref="gridList"
          )
            li.grid-item(
              v-for="fontPairing in getFontPairings"
              :class="{ 'is-active': isSelectedPairing(fontPairing) }"
              @click="updateHeaderFonts(fontPairing)"
            )
              .h1(
                :style="fontStyle(fontPairing.h1, fontPairing.h1_emphasis)"
              ) {{ fontPairing.h1 }}
              .h2(
                :style="fontStyle(fontPairing.h2, fontPairing.h2_emphasis)"
              ) {{ fontPairing.h2 }}
  template(v-else)
    .setup-brand
      img(src="@/assets/images/empty-state/brand-setup.png")
      .setup-brand__text
        a(:href="brandSetupUrl") Setup your brand
        br
        | &nbsp;to view your personalised designs.
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import cloneDeep from 'lodash.clonedeep';

import BrandApi from '@/services/api/BrandApi';
import CategoryItem from '@/components/ProjectCanvas/AssetsSidebar/children/CategoryItem.vue';

export default {
  name: 'BrandedAssetContainerDesigner',
  data() {
    return {
      group: [
        { key: 'color', name: 'Your Brand Color' },
        { key: 'font', name: 'Brand Font Pairing Presets' },
      ],
      colorLight: '',
      colorVibrant: '',
      colorDark: '',
      permutations: [],
      currentPermutation: 0,
    };
  },
  components: {
    CategoryItem,
  },
  computed: {
    ...mapGetters('brand', [
      'getUserBrand',
      'hasSetupBrand',
      'getFontPairings',
    ]),
    ...mapGetters('canvasElements', [
      'getProjectId',
      'getSceneCanvasElementsByType',
      'getCanvasElements',
      'getCanvasBackground',
    ]),
    brandSetupUrl() {
      return `${process.env.VUE_APP_DASHBOARD_URL}/brand-setup?return=${this.getProjectId}`;
    },
    userBrandColors() {
      if (this.getUserBrand.brandMeta.color) {
        return this.getUserBrand.brandMeta.color;
      }
      return JSON.parse(this.getUserBrand.brandMeta.find(meta => meta.key === 'color').value);
    },
    selectedFont() {
      let hasSameH1 = true;
      let hasSameH2 = true;

      const selectedFont = {
        h1: '',
        h2: '',
      };

      // compare whether all the h1 has same font family and all h2 has same font family
      this.getSceneCanvasElementsByType('texts').forEach((font) => {
        if (font.data.layerTag.h1) {
          // this is on first loop
          if (hasSameH1 && !selectedFont.h1) {
            selectedFont.h1 = font.data.fontFamily;
          }
          if (selectedFont.h1 !== font.data.fontFamily) {
            hasSameH1 = false;
            selectedFont.h1 = '';
          }
        }

        if (font.data.layerTag.h2) {
          // this is on first loop
          if (hasSameH2 && !selectedFont.h2) {
            selectedFont.h2 = font.data.fontFamily;
          }
          if (selectedFont.h2 !== font.data.fontFamily) {
            hasSameH2 = false;
            selectedFont.h2 = '';
          }
        }
      });

      return selectedFont;
    },
  },
  methods: {
    ...mapMutations('brand', [
      'setFontPairings',
    ]),
    ...mapMutations('canvasElements', [
      'updateActiveFontFamily',
      'updateCanvasElementTextStyle',
      'updateCanvasBackground',
      'updateCanvasElementStroke',
      'updateCanvasElementTextBackground',
      'updateCanvasElementTextShadow',
      'updateCanvasElementMediaShadow',
      'updateCanvasElementGroundShadow',
      'updateCanvasElementColor',
    ]),
    ...mapActions('canvasElements', [
      'updateCanvasElementFontFamily',
    ]),
    isSelectedPairing(fontPairing) {
      if (fontPairing.h1 === this.selectedFont.h1 && fontPairing.h2 === this.selectedFont.h2) return true;
      return false;
    },
    fontStyle(fontFamily, fontEmphasis) {
      const fontData = { fontFamily };
      const emphasis = fontEmphasis.split(',');
      fontData.fontWeight = emphasis[0] > 400 ? 'bold' : 'normal';
      fontData.fontStyle = emphasis[1] ? emphasis[1] : 'normal';
      return fontData;
    },
    permutate(array) {
      const result = [];

      const permute = (arr, m = []) => {
        if (arr.length === 0) {
          result.push(m);
        } else {
          for (let i = 0; i < arr.length; i += 1) {
            const curr = arr.slice();
            const next = curr.splice(i, 1);
            permute(curr.slice(), m.concat(next));
          }
        }
      };

      permute(array);

      return result;
    },
    updateHeaderFonts(fontPairing) {
      console.log('updateHeaderFonts', fontPairing);
      this.getSceneCanvasElementsByType('texts').forEach((font) => {
        if (font.data.layerTag.h1) {
          this.updateFontFamilyAndStyle(font.data.id, fontPairing.h1, fontPairing.h1_emphasis);
        } else if (font.data.layerTag.h2) {
          this.updateFontFamilyAndStyle(font.data.id, fontPairing.h2, fontPairing.h2_emphasis);
        }
      });
    },
    updateFontFamilyAndStyle(fontId, fontFamily, fontEmphasis) {
      console.log('updateFontFamilyAndStyle', fontId, fontFamily, fontEmphasis);
      this.updateActiveFontFamily(fontFamily);
      setTimeout(() => {
        const font = {
          fontFamily,
          fontCategory: 'latin',
          fontId,
        };

        this.updateCanvasElementFontFamily(font);
      }, 100);

      const emphasis = fontEmphasis.split(',');
      const isBold = emphasis[0] > 400;
      const isItalic = emphasis[1] && emphasis[1] === 'italic';

      this.updateCanvasElementTextStyle({
        style: 'fontWeight',
        value: this.isBold ? 400 : 700, // toggle between bold and regular
        fontId,
      });

      this.updateCanvasElementTextStyle({
        style: 'textItalic',
        value: isItalic,
        fontId,
      });
    },
    resetColor() {
      const light = this.colorLight;
      const vibrant = this.colorVibrant;
      const dark = this.colorDark;

      this.colorLight = this.userBrandColors.light;
      this.colorVibrant = this.userBrandColors.vibrant;
      this.colorDark = this.userBrandColors.dark;

      this.updateColor(light, vibrant, dark);
    },
    cycleColor() {
      const light = this.colorLight;
      const vibrant = this.colorVibrant;
      const dark = this.colorDark;

      // Get next permutation
      this.currentPermutation = this.currentPermutation >= (this.permutations.length - 1) ? 0 : this.currentPermutation + 1;

      [this.colorLight, this.colorVibrant, this.colorDark] = this.permutations[this.currentPermutation];

      this.updateColor(light, vibrant, dark);
    },
    updateColor(light, vibrant, dark) {
      // Update scene background
      this.getCanvasElements.forEach((element) => {
        // Element color
        let hasChanged = false;

        const colorObject = {};
        colorObject.color = [];

        colorObject.id = element.data.id;

        if (typeof element.data.color[0] === 'string') {
          if (element.data.color[0] === light) {
            colorObject.color.push(this.colorLight);
            hasChanged = true;
          } else if (element.data.color[0] === vibrant) {
            colorObject.color.push(this.colorVibrant);
            hasChanged = true;
          } else if (element.data.color[0] === dark) {
            colorObject.color.push(this.colorDark);
            hasChanged = true;
          }
        } else if (element.data.color[0] && element.data.color[0].points) {
          colorObject.color = cloneDeep(element.data.color[0]);

          colorObject.color.points.forEach((point, idx) => {
            if (point.color === light) {
              colorObject.color.points[idx].color = this.colorLight;
              hasChanged = true;
            } else if (point.color === vibrant) {
              colorObject.color.points[idx].color = this.colorVibrant;
              hasChanged = true;
            } else if (point.color === dark) {
              colorObject.color.points[idx].color = this.colorDark;
              hasChanged = true;
            }
          });

          colorObject.color = [colorObject.color];
        }

        if (hasChanged) this.updateCanvasElementColor(colorObject);

        // TODO: Multi-style text color

        // Stroke color
        if (typeof element.data.stroke !== 'undefined' && typeof element.data.stroke[0].strokeColor !== 'undefined') {
          hasChanged = false;
          const strokeObject = {};

          strokeObject.stroke = [];
          strokeObject.id = element.data.id;

          let { strokeColor } = element.data.stroke[0];

          if (element.data.stroke[0].strokeColor === light) {
            strokeColor = this.colorLight;
            hasChanged = true;
          } else if (element.data.stroke[0].strokeColor === vibrant) {
            strokeColor = this.colorVibrant;
            hasChanged = true;
          } else if (element.data.stroke[0].strokeColor === dark) {
            strokeColor = this.colorDark;
            hasChanged = true;
          }

          strokeObject.stroke.push({
            stroke: element.data.stroke[0].stroke,
            strokeColor,
          });

          if (hasChanged) this.updateCanvasElementStroke(strokeObject);
        }

        // TODO: Effects color
        // textBackground - updateCanvasElementTextBackground
        // textShadow - updateCanvasElementTextShadow
        // mediaShadow - updateCanvasElementMediaShadow
        // groundShadow - updateCanvasElementGroundShadow
        if (typeof element.data.textBackground !== 'undefined' && element.data.textBackground.isEnabled) {
          hasChanged = false;

          const textBackgroundObject = {};

          textBackgroundObject.id = element.data.id;
          textBackgroundObject.borderRadius = element.data.textBackground.borderRadius;
          textBackgroundObject.opacity = element.data.textBackground.opacity;
          textBackgroundObject.x = element.data.textBackground.x;
          textBackgroundObject.y = element.data.textBackground.y;

          if (typeof element.data.textBackground.color === 'string') {
            if (element.data.textBackground.color === light) {
              textBackgroundObject.color = this.colorLight;
              hasChanged = true;
            } else if (element.data.textBackground.color === vibrant) {
              textBackgroundObject.color = this.colorVibrant;
              hasChanged = true;
            } else if (element.data.textBackground.color === dark) {
              textBackgroundObject.color = this.colorDark;
              hasChanged = true;
            }
          } else if (element.data.textBackground.color && element.data.textBackground.color.points) {
            textBackgroundObject.color = cloneDeep(element.data.textBackground.color);

            textBackgroundObject.color.points.forEach((point, idx) => {
              if (point.color === light) {
                textBackgroundObject.color.points[idx].color = this.colorLight;
                hasChanged = true;
              } else if (point.color === vibrant) {
                textBackgroundObject.color.points[idx].color = this.colorVibrant;
                hasChanged = true;
              } else if (point.color === dark) {
                textBackgroundObject.color.points[idx].color = this.colorDark;
                hasChanged = true;
              }
            });
          }

          if (hasChanged) this.updateCanvasElementTextBackground(textBackgroundObject);
        }

        if (typeof element.data.textShadow !== 'undefined' && element.data.textShadow.isEnabled) {
          hasChanged = false;

          const textShadowObject = {};

          textShadowObject.id = element.data.id;

          textShadowObject.blur = element.data.textShadow.blur;
          textShadowObject.opacity = element.data.textShadow.opacity;
          textShadowObject.x = element.data.textShadow.x;
          textShadowObject.y = element.data.textShadow.y;

          if (element.data.textShadow.color === light) {
            textShadowObject.color = this.colorLight;
            hasChanged = true;
          } else if (element.data.textShadow.color === vibrant) {
            textShadowObject.color = this.colorVibrant;
            hasChanged = true;
          } else if (element.data.textShadow.color === dark) {
            textShadowObject.color = this.colorDark;
            hasChanged = true;
          }

          if (hasChanged) this.updateCanvasElementTextShadow(textShadowObject);
        }

        if (typeof element.data.mediaShadow !== 'undefined' && element.data.mediaShadow.isEnabled) {
          hasChanged = false;

          const mediaShadowObject = {};

          mediaShadowObject.id = element.data.id;

          mediaShadowObject.blur = element.data.mediaShadow.blur;
          mediaShadowObject.opacity = element.data.mediaShadow.opacity;
          mediaShadowObject.x = element.data.mediaShadow.x;
          mediaShadowObject.y = element.data.mediaShadow.y;

          if (element.data.mediaShadow.color === light) {
            mediaShadowObject.color = this.colorLight;
            hasChanged = true;
          } else if (element.data.mediaShadow.color === vibrant) {
            mediaShadowObject.color = this.colorVibrant;
            hasChanged = true;
          } else if (element.data.mediaShadow.color === dark) {
            mediaShadowObject.color = this.colorDark;
            hasChanged = true;
          }

          if (hasChanged) this.updateCanvasElementMediaShadow(mediaShadowObject);
        }

        if (typeof element.data.groundShadow !== 'undefined' && element.data.groundShadow.isEnabled) {
          hasChanged = false;

          const groundShadowObject = {};

          groundShadowObject.id = element.data.id;

          groundShadowObject.blur = element.data.groundShadow.blur;
          groundShadowObject.opacity = element.data.groundShadow.opacity;
          groundShadowObject.y = element.data.groundShadow.y;
          groundShadowObject.scaleX = element.data.groundShadow.scaleX;
          groundShadowObject.scaleY = element.data.groundShadow.scaleY;

          if (element.data.groundShadow.color === light) {
            groundShadowObject.color = this.colorLight;
            hasChanged = true;
          } else if (element.data.groundShadow.color === vibrant) {
            groundShadowObject.color = this.colorVibrant;
            hasChanged = true;
          } else if (element.data.groundShadow.color === dark) {
            groundShadowObject.color = this.colorDark;
            hasChanged = true;
          }

          if (hasChanged) this.updateCanvasElementGroundShadow(groundShadowObject);
        }
      });

      // Update background
      let canvasBackground = typeof this.getCanvasBackground.color !== 'undefined' ? this.getCanvasBackground.color : this.getCanvasBackground;
      if (typeof canvasBackground === 'string') {
        let color = '';

        if (canvasBackground === light) {
          color = this.colorLight;
        } else if (canvasBackground === vibrant) {
          color = this.colorVibrant;
        } else if (canvasBackground === dark) {
          color = this.colorDark;
        }

        if (color !== '') this.updateCanvasBackground(color);
      } else if (this.getCanvasBackground.points) {
        canvasBackground = this.getCanvasBackground;
        let hasChanged = false;

        canvasBackground.points.forEach((point, idx) => {
          if (point.color === light) {
            canvasBackground.points[idx].color = this.colorLight;
            hasChanged = true;
          } else if (point.color === vibrant) {
            canvasBackground.points[idx].color = this.colorVibrant;
            hasChanged = true;
          } else if (point.color === dark) {
            canvasBackground.points[idx].color = this.colorDark;
            hasChanged = true;
          }
        });

        if (hasChanged) this.updateCanvasBackground(canvasBackground);
      }
    },
  },
  mounted() {
    if (this.hasSetupBrand) {
      this.colorLight = this.userBrandColors.light;
      this.colorVibrant = this.userBrandColors.vibrant;
      this.colorDark = this.userBrandColors.dark;

      this.permutations = this.permutate([this.userBrandColors.light, this.userBrandColors.vibrant, this.userBrandColors.dark]);

      // Check if font pairings already set
      if (!this.getFontPairings.length) {
        // Get font pairings
        const fontPairingCategory = parseInt(this.getUserBrand.brandMeta.find(meta => meta.key === 'font_pairing_category_id').value, 10) || -1;
        const params = {
          cat_id: fontPairingCategory,
        };

        BrandApi.retrieveFontPairings(params)
          .then((response) => {
            this.setFontPairings(response.data.results);
          }).catch((error) => {
            console.error(error);
          })
          .then(() => {
            this.$emit('api-requested');
          });
      } else {
        this.$emit('api-requested');
      }
    } else {
      this.$emit('api-requested');
    }
  },
};
</script>

<style lang="scss">
.branded-style__container--user {
  .setup-brand {
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 600;
    margin: 10px 0;
    position: relative;

    img {
      width: 100%;
    }

    .setup-brand__text {
      position: absolute;
      bottom: 0;
      width: 200px;

      @include small() {
        position: relative;
        width: 100%;
      }

      a {
        color: $blue;
      }
    }
  }

  .branded-style__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .brand-name-title,
    .brand-pairing-title {
      color: $lightWhite;
    }

    .brand-name-title {
      font-size: 0.875rem;
    }

    .brand-pairing-title {
      font-size: 0.75rem;
    }

    .btn-reset-color {
      font-size: 0.75rem;
      padding: 0;
      font-weight: 500;
      color: $lightGrey700;
      text-decoration: underline;

      &:hover {
        background: none;
      }
    }
  }

  .brand-color__container {
    display: flex;
    width: 100%;
    height: 30px;
    margin-top: 10px;

    .brand-colors {
      flex: 1;
      display: flex;
      margin-right: 15px;
      // border: 1px solid $borderGrey;
      border-radius: $componentBorderRadius;

      .brand-color {
        flex: 1;

        &:first-of-type {
          border-top-left-radius: $componentBorderRadius;
          border-bottom-left-radius: $componentBorderRadius;
        }
        &:last-of-type {
          border-top-right-radius: $componentBorderRadius;
          border-bottom-right-radius: $componentBorderRadius;
        }
      }
    }

    .btn-cycle-color {
      color: $lightWhite;
      background: $darkGrey2;
      border: 0;

      &:hover {
        background: $btnDarkHollowText;
      }

      &:active {
        background: $darkBg2;
      }

      .icon {
        font-size: 0.75em;
        margin-left: 0;
      }
    }
  }

  .grid-item {
    background: $darkGrey500;
    color: $lightWhite;
    margin: 0;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: $componentBorderRadius;
    border: 1px solid $darkGrey500;
    transition: background-color 0.3s cubic-bezier(0, 0, 0, 1), border 0.3s cubic-bezier(0, 0, 0, 1);

    &:hover {
      background: $darkGrey300;
    }

    &.is-active {
      background: $darkGrey300;
      border-color: $lightWhite;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
