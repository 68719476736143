<template lang="pug">
button.btn.layer-tag(
  :class="`layer-tag--${value}`"
  type="button"
  @click="$emit('click')"
) {{ name }}
</template>

<script>
export default {
  name: 'LayerTag',
  props: {
    value: { type: String, default: '' },
    name: { type: String, default: '' },
  },
};
</script>

<style lang="scss">
.layer-tag {
  border-radius: 20px;
  width: 100%;
  margin-bottom: 8px;
  padding: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.remove-layer-tag--overlay,
.layer-tag--overlay {
  background: $lighterGreen;
  color: $blue;

  &:hover,
  &:focus {
    background: darken($lighterGreen, 7%);
  }
}

.remove-layer-tag--fg,
.layer-tag--fg {
  background: $sectionBlue;
  color: $blue;

  &:hover,
  &:focus {
    background: darken($sectionBlue, 7%);
  }
}

.remove-layer-tag--logo,
.layer-tag--logo {
  background: $yellow;
  color: $blue;

  &:hover,
  &:focus {
    background: darken($yellow, 7%);
  }
}

.remove-layer-tag--hero,
.layer-tag--hero {
  background: $blue;
  color: $light;

  &:hover,
  &:focus {
    background: darken($blue, 7%);
  }
}

.layer-tag--h1,
.remove-layer-tag--h1,
.layer-tag--h2,
.remove-layer-tag--h2,
.layer-tag--h3,
.remove-layer-tag--h3 {
  background: $textBlack;
  color: $light;

  &:hover,
  &:focus {
    background: lighten($textBlack, 7%);
  }
}

.remove-layer-tag--bg,
.layer-tag--bg {
  background: $timelineGrey;
  color: $light;

  &:hover,
  &:focus {
    background: darken($timelineGrey, 7%);
  }
}

.remove-layer-tag--hero-bg,
.layer-tag--hero-bg {
  background: $darkGreen;
  color: $light;

  &:hover,
  &:focus {
    background: darken($darkGreen, 7%);
  }
}


.remove-layer-tag--clear,
.layer-tag--clear {
  background: $salmon;
  color: $light;

  &:hover,
  &:focus {
    background: $salmonHover;
  }
}
</style>
