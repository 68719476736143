import axios from 'axios';
import $store from '@/store/store';

export default {
  uploadCanvasItems(table, file, params = {}) {
    const formData = new FormData();
    // console.log('file-->', typeof file, file);

    if (typeof file === 'number') {
      // user_file_id
      formData.append('user_file_id', file);
    } else {
      // binary file
      formData.append('file', file);
    }
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });

    const { loginToken } = $store.state.userData;
    return axios.post(`${process.env.VUE_APP_ADMIN_API}/api/canvas-items/${table}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        // Accept: 'application/json',
        'Login-Token': loginToken,
      },
    });
  },
};
