<template lang="pug">
.element-glitch.effect
  BaseSwitch.element-glitch__switch.effect__switch(
    name="element-glitch"
    :isChecked="isEnabled('glitch')"
    :isBeta="true"
    @change="toggleEnable('glitch')"
    label="Glitch"
  )
  VueSlideUpDown.element-glitch__content.effect__content(
    :class="{'effect-content--enabled': isEnabled('glitch')}"
    :active="true"
  )
    .glitch__color.effect__color(v-if="isTextEffectsType")
      span.title Colour 1
      //- this first colorpicker get second-colorpicker class because there is chance user open the below colorpicker then open this one
      button.second-picker.color.btn-add-color(
        :class="{ 'is-active': isTextGlitch }"
        type="button"
        :style="colorStyle1"
        @click="openColorPicker('color1')"
      )
    .glitch__color.effect__color(v-if="isTextEffectsType")
      span.title Colour 2
      button.color.btn-add-color(
        :class="{ 'is-active': isTextGlitch }"
        type="button"
        :style="colorStyle2"
        @click="openColorPicker('color2')"
      )
    BaseSlider.effects__base-slider.slider--extra-margin-top(
      is-dark=true
      :min="0"
      :max="maxDistance"
      title="Distance"
      :value="distance"
      :step="0.5"
      @blur="onBlurInput($event, 'distance', 'x')"
      @update="updateDistance"
      suffix="x"
    )
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="100"
      title="Speed"
      :value="speed"
      @blur="onBlurInput($event, 'speed', '%')"
      @update="updateSpeed"
      suffix="%"
    )

    BaseButton.effects__reset-button(
      :is-canvas="true"
      is-dark=true
      is-full-width=true
      @click="resetGlitch"
    ) Reset
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import VueSlideUpDown from 'vue-slide-up-down';
import { elementFormat, textDataFormat } from '@/assets/scripts/variables';
import { hexToRgb } from '@/assets/scripts/utilities';
import effectsMixin from '@/components/mixins/effects-mixins';

export default {
  name: 'GlitchControls',
  components: {
    VueSlideUpDown,
  },
  mixins: [effectsMixin],
  computed: {
    ...mapState(['isTextGlitch']),
    activeElement() {
      return this.getActiveElements[0];
    },
    selectedGlitch() {
      return this.isTextEffectsType ? 'textGlitch' : 'mediaGlitch';
    },
    colorStyle1() {
      const styleObject = {};
      if (this.isMediaEffectsType) return styleObject;

      let glitchColor = '#ff00c1';

      if (this.getActiveElements.length) {
        const { color1 } = this.activeElement.data[this.selectedGlitch];

        const { r, g, b } = hexToRgb(color1);
        glitchColor = `rgb(${r}, ${g}, ${b})`;
      }

      styleObject.background = glitchColor;

      return styleObject;
    },
    colorStyle2() {
      const styleObject = {};
      if (this.isMediaEffectsType) return styleObject;

      let glitchColor = '#00fff9';

      if (this.getActiveElements.length) {
        const { color2 } = this.activeElement.data[this.selectedGlitch];

        const { r, g, b } = hexToRgb(color2);
        glitchColor = `rgb(${r}, ${g}, ${b})`;
      }

      styleObject.background = glitchColor;

      return styleObject;
    },
    distance() {
      return this.activeElement.data[this.selectedGlitch].distance;
    },
    speed() {
      return this.activeElement.data[this.selectedGlitch].speed;
    },
    maxDistance() {
      return this.isTextEffectsType ? 5 : 12;
    },
  },
  mounted() {
    if (!this.isEnabled('glitch')) {
      this.toggleEnable('glitch');
    }
  },
  methods: {
    ...mapMutations(['setActiveTextGlitchColorType']),
    updateDistance(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        if (this.isTextEffectsType) this.updateCanvasElementTextGlitch({ distance: value });
        if (this.isMediaEffectsType) this.updateCanvasElementMediaGlitch({ distance: value });

        this.$root.$emit('canvas-container-reset-timeline');
      }
    },
    updateSpeed(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        if (this.isTextEffectsType) this.updateCanvasElementTextGlitch({ speed: value });
        if (this.isMediaEffectsType) this.updateCanvasElementMediaGlitch({ speed: value });

        this.$root.$emit('canvas-container-reset-timeline');
      }
    },
    onBlurInput(event, type, suffix) {
      this.onBlurEffectInput(event, {
        effect: this.selectedGlitch,
        selectedInput: type,
        suffix,
      });
    },
    openColorPicker(type) {
      if (this.isTextEffectsType) this.setIsTextGlitch(true);

      this.setActiveTextGlitchColorType(type);
      this.$emit('open-colorpicker');

      this.$root.$emit('canvas-container-reset-timeline');
    },
    resetGlitch() {
      this.catchHistory('element');

      if (this.isTextEffectsType) {
        this.updateCanvasElementTextGlitch({
          color1: textDataFormat.textGlitch.color1,
          color2: textDataFormat.textGlitch.color2,
          distance: textDataFormat.textGlitch.distance,
          speed: textDataFormat.textGlitch.speed,
        });
      }

      if (this.isMediaEffectsType) {
        this.updateCanvasElementMediaGlitch({
          distance: elementFormat.data.mediaGlitch.distance,
          speed: elementFormat.data.mediaGlitch.speed,
        });
      }

      this.$root.$emit('canvas-container-reset-timeline');
    },
  },
};
</script>

<style lang="scss">
</style>
