<template lang="pug">
  div.upload-tab__performance-information-modal(
    :class="{ 'is-show': isShow }"
  )
    BaseButton.icon-cross-line.information-modal__close-button(
      is-canvas=true
      is-text=true
      @click="closeModal"
    )
    p.information-modal-text To speed up the web performance, we will be compressing the images / videos during the edit mode.
    p.information-modal-text.text--margin-y Your downloaded image/video will be in high quality.

    BaseButton.information-modal__button(
      is-canvas=true
      is-primary=true
      @click="closeModal"
    ) Got it
</template>

<script>
export default {
  name: 'UploadPerformanceInfoModal',
  props: {
    isShow: { type: Boolean, default: false },
  },
  methods: {
    closeModal() {
      this.$emit('close-information-modal');
    },
  },
};
</script>

<style lang="scss">
.upload-tab__performance-information-modal {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 50px 15px 15px;
  color: $light;
  background: $darkGrey900;
  display: flex;
  flex-direction: column;
  z-index: 5;
  transform: translateY(40px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;

  &.is-show {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .information-modal__close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.125rem;
    color: $light;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: $lightGrey5;
    }
  }

  .information-modal-text {
    line-height: 1.6;
    font-size: 0.75rem;
    padding: 0 10px;
    font-weight: 400;
    margin: 0;

    &.text--margin-y {
      margin: 15px 0;
    }
  }

  .information-modal__button {
    font-size: 0.875rem;
    font-weight: 600;
    height: 42px;
  }
}
</style>
