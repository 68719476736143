<template lang="pug">
.timeline-empty-state
  .timeline-empty-state__wrapper
    h4.title Timeline is empty
    p.subtitle Get started quickly with a template
    BaseButton.open-design-button(
      is-primary=true
      :is-canvas="true"
      @click="openDesignTemplate"
    ) View Designs
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'TimelineEmptyState',
  methods: {
    ...mapMutations(['setShowDesignContainer']),
    ...mapMutations('assetsSidebar', ['setShowDesignPreloader']),
    openDesignTemplate() {
      this.setShowDesignPreloader(false);
      this.setShowDesignContainer(true);
    },
  },
};
</script>

<style lang="scss">
.timeline-empty-state {
  text-align: center;
  line-height: 1.3;
  margin: 0;
  top: 0;
  left: 360px;
  right: 0;
  height: 100%;
  position: absolute;
  background: var(--white-darkgrey4);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-height: 210px;

  @include smallest {
    left: 260px;
  }

  @include fb-requirement {
    left: 240px;
  }

  #main-wrapper.is-sidebar-collapsed:not(.has-extra-sidebar-opened) & {
    left: 0;
  }

  .title {
    font-weight: 600;
    font-size: 1.0625rem;
    color: var(--darkgrey-white);
    margin: 0;
  }

  .subtitle {
    font-weight: 400;
    margin: 10px 0 20px;
    font-size: 0.875rem;
    color: var(--darkgrey-white);
  }

  .open-design-button {
    position: relative;
    z-index: 2;
    width: 150px;
    height: 35px;
    background: $blue3;

    &:hover {
      color: $blue3;
    }
  }
}

.timeline-empty-state__wrapper {
  position: relative;
  z-index: 2;
}
</style>
