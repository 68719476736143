<template lang="pug">
.fontfamily-dropdown(
  :class="{ 'is-active': isFontFamilyOpened, 'is-light': isUseLightMode }"
)
  .font-search
    input.fontfamily__input(
      type="text"
      autocomplete="off"
      placeholder="Search...."
      v-model="fontFamilyInput"
    )
    button.btn-clear-fontfamily(
      type="button"
      :class="{ 'is-show': searchValue.length > 0 }"
      @click="clearSearch"
    )
      i.icon.icon-cancel
    i.icon.icon-search

  .font-tab__options
    BaseButton.tab-option(
      v-for="(option, index) in fontTabOption"
      :key="index"
      :class="{'is-selected': selectedOption === option.value }"
      :is-text="true"
      :is-hollow="true"
      :is-full-width="true"
      padding="11px 0"
      @click="switchFontOption(option.value)"
    ) {{ option.name }}

  template(v-if="selectedOption === 'library'")
    .fontcategory(
      v-if="!isSearching"
      :class="{ 'is-active': isFontCategoryOpened }"
      v-click-outside="onClickOutsideCategory"
    )
      span.fontcategory__input(
        v-html="selectedFontCategoryName"
        @click="isFontCategoryOpened = !isFontCategoryOpened"
      )
      .fontcategory__list
        template(v-for="category in getActiveOffeoFontsCategory")
          template(v-if="category.value")
            .option(
              v-html="category.text"
              @click="updateSelectedFontCategory(category)"
              :class="{ 'is-selected': isSelectedFontCategory(category) }"
            )

    .fontfamily
      .fontfamily__selected(v-if="!isSearching")
        .fontfamily__title Selected Font
        .font-selected(:class="{'is-team': isBrandFont}")
          template(v-if="fontFamilyName && !isCustomFont")
            img(
              :src="fontSelected"
              @load="selectedFontLoaded"
              @error="selectedFontError"
            )
            span(
              id="selected-font"
              v-html="fontFamilyText"
              :style="{ 'font-family': fontFamilyName }"
            )
            template(v-if="fontCategory !== 'latin' && fontCategory !== 'latin-ext'")
              img.image--category(:src="fontCategorySelected")
          template(v-else)
            span(id="selected-font" v-html="fontFamilyText" :style="{ 'font-family': fontFamilyName }")

      template(v-if="featuredFontFamilies.length && !isSearching")
        .fontfamily__title Featured Fonts
        .fontfamily__featured-fonts
          FontFamilyItem(
            v-for="(font, index) in featuredFontMaximumVisible"
            :key="font.fontFamily"
            :text="font.text"
            :fontFamily="font.fontFamily"
            :fontCategory="selectedFontCategoryIfNotSearch(font)"
            :isSelected="isFontFamilySelected(font)"
            :is-light="isUseLightMode"
            :is-brand-font="isBrandFont"
            @select="selectFontFamily"
          )

      template(v-if="isShowCustomFontsByTeamBrandSection")
        div(v-for="(teamBrand, index) in getTeamCustomFontsByBrand" :key="index")
          .fontfamily__title {{ teamBrand.brand_name }}
          .fontfamily__all-fonts
            FontFamilyItem(
              v-for="(font, index) in teamBrand.custom_font_list"
              :key="font.fontFamily"
              :text="font.text"
              :fontFamily="font.fontFamily"
              :fontCategory="selectedFontCategoryIfNotSearch(font)"
              :isSelected="isFontFamilySelected(font)"
              :is-light="isUseLightMode"
              :is-brand-font="isBrandFont"
              @select="selectFontFamily"
            )

      //- not show in team workspace except when searching
      template(v-if="isShowAllCustomFontsSection")
        .fontfamily__title {{ !isSearching ? 'All Fonts' : 'Results' }}
        .fontfamily__all-fonts
          FontFamilyItem(
            v-for="(font, index) in fontMaximumVisible"
            :key="font.fontFamily"
            :text="font.text"
            :fontFamily="font.fontFamily"
            :fontCategory="selectedFontCategoryIfNotSearch(font)"
            :isSelected="isFontFamilySelected(font)"
            :is-light="isUseLightMode"
            :is-brand-font="isBrandFont"
            @select="selectFontFamily"
          )

      template(v-if="isSearching && !fontFamilies.length")
        .empty-state__all-fonts
          img(src="@/assets/images/empty-state/emo-flat.svg")
          p.text
            span Opps..!
            br
            | No fonts Found.
          BaseButton(
            :is-canvas="true"
            :is-primary="true"
            :is-tall="true"
            @click="clearSearch"
          ) Back to all fonts

  template(v-else-if="selectedOption === 'upload'")
    BaseButton.btn-upload-custom-font(
      :href="dashboardBrandUrl"
      :new-tab="true"
      :is-canvas="true"
      :is-full-width="true"
      icon-before="icon-plus"
    ) Upload Font

    .dropdown__upload-tab-container(:class="{'container--margin-top': isInTeamWorkspace && this.getTeamCustomFontsByBrand.length }")
      template(v-if="isInTeamWorkspace && this.getTeamCustomFontsByBrand.length")
        div(v-for="(teamBrand, index) in getTeamCustomFontsByBrand" :key="index")
          .fontfamily__title {{ teamBrand.brand_name }}
          .fontfamily__all-fonts
            FontFamilyItem(
              v-for="(font, index) in teamBrand.custom_font_list"
              :key="`custom-${font.fontFamily}`"
              :text="font.text"
              :fontFamily="font.fontFamily"
              fontCategory="custom"
              :isSelected="isFontFamilySelected(font)"
              :is-light="isUseLightMode"
              :is-brand-font="isBrandFont"
              @select="selectFontFamily"
            )

      template(v-else-if="!isInTeamWorkspace && (isPayingUser || isAdmin || isDesigner) && customFontFamilies.length")
        FontFamilyItem(
          v-for="(font, index) in customFontMaximumVisible"
          :class="{ 'is-last': index === customFontMaximumVisible.length - 1 }"
          :key="`custom-${font.fontFamily}`"
          :text="font.text"
          :fontFamily="font.fontFamily"
          fontCategory="custom"
          :isSelected="isFontFamilySelected(font)"
          :is-light="isUseLightMode"
          :is-brand-font="isBrandFont"
          @select="selectFontFamily"
        )

      template(v-else-if="isShowUploadSectionEmptyState")
        .custom-font__empty-state
          i.icon.icon-up-arrow-long
          p.text
            | Add your custom fonts
            br
            | (Opens&nbsp;
            strong “Brands”&nbsp;
            | in new tab for uploading)
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import scrollMonitor from 'scrollmonitor';
import vClickOutside from 'v-click-outside';
import { defaultFallbackFont, dashboardUrl } from '@/assets/scripts/variables';
import FontFamilyItem from './FontFamilyItem.vue'; // eslint-disable-line

import textMixin from '@/components/mixins/text-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';
import mobileFonts from '@/assets/data/mobile-fonts.json';

const NEW_ITEMS = 30;

export default {
  name: 'FontFamilyDropdown',
  mixins: [textMixin, darkModeMixin],
  components: {
    FontFamilyItem,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    isBrandFont: { type: Boolean, default: false },
    font: { type: Object, default: () => {} },
    onlyDark: { type: Boolean, default: false }, // e.g "FontTab.vue" at canvas sidebar only use dark version
  },
  data() {
    return {
      selectedOption: 'library',
      fontTabOption: [
        { name: 'Library', value: 'library' },
        { name: 'Upload', value: 'upload' },
      ],
      mobileFonts,
      isFontCategoryOpened: false,
      isFontFamilyOpened: false,
      currentVisible: NEW_ITEMS,
      selectedFontCategory: this.fontCategory || 'latin',
      fontFamilies: [],
      customFontFamilies: [],
      featuredFontFamilies: [],
      isSearching: false,
      searchValue: '',
    };
  },
  computed: {
    ...mapState('team', ['teamSlug']),
    ...mapGetters('canvasElements', ['getActiveElements']),
    ...mapGetters(['getActiveOffeoFonts', 'getActiveOffeoFontsCategory']),
    ...mapGetters('userData', ['isPayingUser', 'isAdmin', 'isDesigner']),
    ...mapGetters('team', ['isInTeamWorkspace', 'getTeamCustomFontsByBrand']),
    isUseLightMode() {
      return this.onlyDark ? false : !this.isDarkMode;
    },
    dashboardBrandUrl() {
      const personalUrl = `${dashboardUrl()}/brands`;
      const teamUrl = `${dashboardUrl()}/team/${this.teamSlug}/team-brands`;
      const getUrl = this.isInTeamWorkspace ? teamUrl : personalUrl;

      return getUrl;
    },
    isShowCustomFontsByTeamBrandSection() {
      // only show on team workspace when opening "custom" category
      return this.isInTeamWorkspace && this.getTeamCustomFontsByBrand.length && !this.isSearching && this.isCustomSelectedFontCategory;
    },
    isShowAllCustomFontsSection() {
      const isTeamWorkspaceRulesFulfilled = this.isInTeamWorkspace && !this.isCustomSelectedFontCategory && this.fontFamilies.length;
      const isNonTeamWorkspaceRulesFulfilled = !this.isInTeamWorkspace && this.fontFamilies.length;
      const isSearching = this.isSearching && this.fontFamilies.length;
      return isSearching || isTeamWorkspaceRulesFulfilled || isNonTeamWorkspaceRulesFulfilled;
    },
    isShowUploadSectionEmptyState() {
      return (!this.isInTeamWorkspace && !this.customFontFamilies.length) || (this.isInTeamWorkspace && !this.getTeamCustomFontsByBrand.length);
    },
    fontFamily() {
      if (this.font && this.font.fontFamily) {
        return this.font.fontFamily;
      }
      if (this.getActiveElements.length === 0) return defaultFallbackFont;
      return this.getActiveElements[0].data.fontFamily;
    },
    currentFontObj() {
      return this.getActiveOffeoFonts.find((o) => o.fontFamily === this.fontFamily); // eslint-disable-line
    },
    fontFamilyName() {
      const fontObj = this.currentFontObj;
      let fontFamilyName = defaultFallbackFont;
      if (fontObj) {
        fontFamilyName = fontObj.fontFamily;
      }
      return fontFamilyName;
    },
    fontFamilyText() {
      const fontObj = this.currentFontObj;
      let fontFamilyText = 'Lorem Ipsum';
      if (fontObj) {
        fontFamilyText = fontObj.text;
      }
      return fontFamilyText;
    },
    fontCategory() {
      if (this.getActiveElements.length === 0) return 'latin';
      return this.getActiveElements[0].data.fontCategory;
    },
    isCustomFont() {
      return this.fontCategory === 'custom';
    },
    isCustomSelectedFontCategory() {
      return this.selectedFontCategory === 'custom';
    },
    maximumVisible() {
      return this.currentVisible;
    },
    selectedFontCategoryName() {
      const selectedCategory = this.getActiveOffeoFontsCategory;

      const fontObj = selectedCategory.find((o) => o.value === this.selectedFontCategory); // eslint-disable-line
      // console.log('selectedFontCategory', this.selectedFontCategory);
      // if there is no object or no value, default to latin
      if (!fontObj || !fontObj.value) return 'latin';
      this.updateFontFamilies(fontObj.value);
      return fontObj.text;
    },
    fontSelected() {
      return this.isUseLightMode
        ? this.imageUrlLight(this.fontFamilyName)
        : this.imageUrl(this.fontFamilyName);
    },
    fontCategorySelected() {
      return this.isUseLightMode
        ? this.imageCategoryUrlLight(this.fontFamilyName, this.fontCategory)
        : this.imageCategoryUrl(this.fontFamilyName, this.fontCategory);
    },
    customFontMaximumVisible() {
      return this.customFontFamilies.filter(
        (font, index) => index < this.maximumVisible && font.text,
      );
    },
    fontMaximumVisible() {
      return this.fontFamilies.filter((font, index) => index < this.maximumVisible && font.text);
    },
    featuredFontMaximumVisible() {
      return this.featuredFontFamilies.filter(
        (font, index) => index < this.maximumVisible && font.text,
      );
    },
    fontFamilyInput: {
      get() {
        return this.searchValue;
      },
      set(value) {
        this.searchValue = value;
        this.searchFontFamilies();
      },
    },
  },
  mounted() {
    if (this.getActiveElements.length) this.selectedFontCategory = this.getActiveElements[0].data.fontCategory;
  },
  methods: {
    switchFontOption(option) {
      this.selectedOption = option;
    },
    addNewItems() {
      this.currentVisible += NEW_ITEMS;
    },
    addScrollWatch(type, fonts) {
      // eslint-disable-next-line
      const _self = this;

      // eslint-disable-next-line
      this.$nextTick(function () {
        if (fonts.length <= this.currentVisible) return;

        const containerElement = document.getElementsByClassName(`fontfamily__${type}`)[0];

        if (containerElement) {
          const containerMonitor = scrollMonitor.createContainer(containerElement);
          const childElement = containerElement.lastElementChild;
          const elementWatcher = containerMonitor.create(childElement);
          elementWatcher.enterViewport(() => {
            elementWatcher.destroy();
            _self.addNewItems();
            _self.addScrollWatch(type, fonts);
          });
        }
      });
    },
    isSelectedFontCategory(category) {
      return this.selectedFontCategory === category.value;
    },
    isFontFamilySelected(font) {
      return this.fontFamily === font.fontFamily;
    },
    updateSelectedFontCategory(category) {
      this.selectedFontCategory = category.value;
    },
    updateFontFamilies(fontValue) {
      let allFonts = [];
      allFonts = this.getActiveOffeoFonts.filter((font) => {
        const isSelectedCategory = Array.isArray(font.category) && font.category.indexOf(fontValue) > -1;
        return isSelectedCategory;
      });

      this.fontFamilies = allFonts;

      this.featuredFontFamilies = allFonts.filter(font => this.mobileFonts.includes(font.fontFamily));

      this.customFontFamilies = this.getActiveOffeoFonts.filter((font) => {
        const isCustom = !font.category || (Array.isArray(font.category) && font.category.indexOf('custom') > -1);
        return isCustom;
      });
      this.isFontCategoryOpened = false;
    },
    searchFontFamilies() {
      const fontValue = this.selectedFontCategory;

      if (this.searchValue === '') {
        this.isSearching = false;
        this.updateFontFamilies(fontValue);
      } else {
        this.isSearching = true;
        const acronymFonts = [];
        const startingKeywordFonts = [];
        const normalSearchFonts = [];

        /* eslint-disable */
        this.getActiveOffeoFonts.forEach((font) => {
          const isCustom =
            (this.isPayingUser || this.isAdmin || this.isDesigner) &&
            (!font.category ||
              (Array.isArray(font.category) && font.category.indexOf('custom') > -1));
          const isSelectedCategory = Array.isArray(font.category) && font.category.indexOf(fontValue) > -1;

          const hasAcronymKeyword = font.text
            .match(/\b(\w)/g)
            .join('')
            .toLowerCase()
            .indexOf(this.searchValue.toLowerCase()) > -1;
          const hasStartingKeyword = this.searchValue.toLowerCase()
            === font.text.slice(0, this.searchValue.length).toLowerCase();
          const hasSearchKeyword = font.text.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1;

          if (hasAcronymKeyword && (isCustom || isSelectedCategory)) {
            acronymFonts.push(font);
          }
          if (hasStartingKeyword && (isCustom || isSelectedCategory)) {
            startingKeywordFonts.push(font);
          }
          if (hasSearchKeyword && (isCustom || isSelectedCategory)) {
            normalSearchFonts.push(font);
          }
        });
        /* eslint-enable */

        // new Set will remove any duplicates
        this.fontFamilies = Array.from(
          new Set([...startingKeywordFonts, ...normalSearchFonts, ...acronymFonts]),
        );
      }
    },
    onClickOutsideCategory() {
      this.isFontCategoryOpened = false;
    },
    clearSearch() {
      this.searchValue = '';
      this.searchFontFamilies();
    },
    selectedFontCategoryIfNotSearch(font) {
      if (!this.isSearching) return this.selectedFontCategory;

      const isCustom = (this.isPayingUser || this.isAdmin || this.isDesigner)
        && (!font.category || (Array.isArray(font.category) && font.category.indexOf('custom') > -1));
      // for custom font, need to stay as custom
      if (isCustom) return 'custom';
      return this.selectedFontCategory;
    },
    selectedFontLoaded(event) {
      event.target.style.removeProperty('display');
      document.getElementById('selected-font').style.display = 'none';
    },
    selectedFontError(event) {
      // eslint-disable-next-line
      event.target.style.display = 'none';
    },
    selectFontFamily(font) {
      if (this.isBrandFont) {
        this.$emit('select', font);
      }
    },
  },
  watch: {
    fontFamilies() {
      this.addScrollWatch('all-fonts', this.fontFamilies);
    },
    featuredFontFamilies() {
      this.addScrollWatch('featured-fonts', this.featuredFontFamilies);
    },
    fontFamilyText() {
      if (!this.isSearching && !!document.getElementById('selected-font')) {
        document.getElementById('selected-font').style.removeProperty('display');
      }
    },
    selectedOption(val) {
      if (val === 'upload') {
        this.searchValue = '';
        this.isSearching = false;
      }
    },
    searchValue(val) {
      if (val) this.selectedOption = 'library';
    },
  },
};
</script>

<style lang="scss">
.font-tab__options {
  display: flex;
  justify-content: center;
  margin: 10px 0 12px;

  .tab-option {
    color: $light;
    opacity: 0.4;
    transition: opacity 0.3s ease-in-out;

    &.is-selected {
      opacity: 1;
    }

    .btn__circle {
      display: none;
    }

    &:hover,
    &:active {
      color: $light;
      opacity: 1;
    }

    &:nth-child(1) {
      margin-right: 4px;
    }

    &:nth-child(2) {
      margin-left: 4px;
    }

    .fontfamily-dropdown.is-light & {
      color: $darkGrey900;
    }
  }
}

.btn-upload-custom-font {
  padding: 12px;
  font-size: 14px;
  background: var(--blue700-darkgrey900);

  .btn__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    color: var(--blue700-darkgrey900);
  }
}

.dropdown__upload-tab-container {
  overflow-y: auto;

  &.container--margin-top {
    margin-top: 20px;
  }
}

.empty-font {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
  margin: 10px 0;
  color: $light;
}

.fontfamily-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 30px);
}

.font-search {
  cursor: pointer;
  text-align: left;
  border-radius: 4px;
  font-size: 0.875em;
  color: $darkGrey;
  outline: none;
  position: relative;

  .fontfamily__input {
    background: $darkGrey600;
    outline: 0;
    border-radius: 4px;

    &::placeholder {
      color: $lightGrey800;
      padding-left: 4px;
      font-size: 12px;
    }

    .fontfamily-dropdown.is-light & {
      background: $light;
      border: 1px solid $defaultBorder;
      color: $darkGrey900;
    }
  }

  .icon {
    color: $lightGrey800;
  }

  .icon-search {
    position: absolute;
    pointer-events: none;
    background: transparent;
    right: 5px;
    top: 6px;
    font-size: 23px;
    cursor: auto;
  }
}

.btn-clear-fontfamily {
  position: absolute;
  right: 32px;
  top: 10px;
  display: flex;
  cursor: pointer;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  color: $lightGrey800;
  background: transparent;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;
  font-size: 1.25rem;

  &:hover {
    color: $blue;
  }

  &.is-show {
    opacity: 1;
    pointer-events: auto;
  }
}

.font__group {
  width: 200px;
  background: $light;
  position: absolute;
  font-size: 0.75rem;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
}

.fontcategory__list,
.fontfamily__featured-fonts,
.fontfamily__all-fonts {
  .option {
    padding: 15px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: capitalize;
    min-height: 35px;
    font-size: 0.875em;
    border-bottom: 1px solid $darkGrey600;
    color: $lightWhite;

    .fontfamily-dropdown.is-light & {
      border-color: $defaultBorder;
      color: $darkGrey;
    }

    &.is-last,
    &:last-child {
      border-bottom: 0;
      margin-bottom: 10px;
    }

    &.has-image {
      padding: 10px;
    }

    &:hover::after {
      opacity: 0.2;
    }

    &.is-selected {
      &::after {
        opacity: 0.4;
      }

      &::before {
        content: $icon-checked;
        font-family: 'icomoon';
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        z-index: 2;
        color: $green700;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;

      .fontfamily-dropdown.is-light & {
        background: $lightGrey400;
      }
    }
  }
}

.fontcategory {
  position: relative;

  &.is-active {
    .fontcategory__button {
      transform: rotate(180deg);
    }

    .fontcategory__list {
      pointer-events: auto;
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    .fontcategory__input {
      border-color: $darkGrey200;
      background: $darkGrey400;

      &::before {
        content: $icon-up-arrow;
      }

      &:hover {
        background-color: $darkGrey400;
      }

      .fontfamily-dropdown.is-light & {
        background: $light;
        border-color: $defaultBorder;

        &:hover {
          background-color: $lightGrey300;
        }
      }
    }

    & + .fontfamily {
      pointer-events: none;
      opacity: 0.7;
      &::after {
        opacity: 0.8;
      }
    }
  }
}

.fontcategory__input {
  display: block;
  cursor: pointer;
  margin: 0 0 10px 0;
  padding: 12px 10px;
  text-transform: capitalize;
  position: relative;
  border: 1px solid var(--darkgrey400-darkgrey900);
  color: $lightGrey500;
  background-color: var(--darkgrey400-darkgrey900);
  font-size: 0.75rem;
  border-radius: $componentBorderRadius;
  transition: background-color 0.3s cubic-bezier(0, 0, 0, 1), border-color 0.3s cubic-bezier(0, 0, 0, 1);

  .fontfamily-dropdown.is-light & {
    background: $light;
    border-color: $defaultBorder;
    color: $darkGrey;

    &:hover {
      background-color: $lightGrey300;
    }

    &::before {
      color: $darkGrey;
    }
  }

  &:hover {
    background-color: $darkGrey400;
    border-color: $darkGrey400;
  }

  &::before {
    content: $icon-down-arrow;
    font-family: 'icomoon';
    position: absolute;
    right: 4px;
    top: 6px;
    font-size: 20px;
    color: $lightWhite;
  }
}

.fontcategory__button {
  position: absolute;
  pointer-events: none;
  background: transparent;
  right: 5px;
  top: 10px;
  font-size: 10px;
}

.fontcategory__list {
  position: absolute;
  z-index: 2;
  padding: 5px 10px;
  margin-top: -10px;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  background: $darkGrey600;
  max-height: 230px;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;

  .fontfamily-dropdown.is-light & {
    background: $light;
  }

  .option {
    padding: 12px;
  }
}

.fontfamily {
  position: relative;
  transition: opacity 0.25s ease-in-out;
  height: calc(100% - 41px);
  margin: 0 -20px;
  width: calc(100% + 40px);
  padding: 10px 13px 0;
  overflow: auto;
  overflow-x: hidden;

  @include smallest {
    margin: 0 -15px;
    width: calc(100% + 30px);
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    left: -10px;
    top: 0;
    width: calc(100% + 20px);
    height: 1px;
    background: $darkGrey600;

    .fontfamily-dropdown.is-light & {
      background: $light;
    }

    @include smallest {
      left: 0;
      width: 100%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
    background: $darkGrey700;

    .fontfamily-dropdown.is-light & {
      background: $lightGrey300;
    }
  }

  .icon-search {
    position: absolute;
    top: 10px;
    right: 5px;
  }

  .font-selected {
    padding: 3px 0 10px 10px;
    position: relative;
    font-size: 0.875em;
    display: flex;
    align-items: center;
    overflow: hidden;
    max-width: 100%;
    flex-wrap: wrap;
    margin: 0 6px 5px 0;
    color: $lightWhite;

    .fontfamily-dropdown.is-light & {
      color: $darkGrey800;
    }

    &::before {
      content: $icon-checked;
      font-family: 'icomoon';
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
      z-index: 2;
      color: $green700;
    }

    &.is-team {
      // since in team-brand-font, sometimes the selected font section is empty for team-custom-fonts. so at least there should be a span.
      span {
        display: block !important;
      }
    }

    span {
      padding: 5px;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      opacity: 0.4;
      transition: opacity 0.25s ease-in-out;
    }
  }

  .image--category {
    opacity: 0.3;
  }
}

.fontfamily__title {
  color: $lightGrey500;
  font-size: 12px;
  font-weight: 100;
  padding: 5px 0 5px 5px;
  width: 100%;

  &:not(:nth-child(1)) {
    margin-top: 10px;
  }

  .font-selected & {
    margin-left: -5px;
  }
}

.fontfamily__input {
  padding: 10px 55px 10px 10px;
  background: $darkGrey800;
  color: $lightGrey500;
  width: 100%;
}

.empty-state__all-fonts {
  margin-top: 12px;
  text-align: center;

  .text {
    color: $light;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    .fontfamily-dropdown.is-light & {
      color: $darkGrey900;
    }

    span {
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.custom-font__empty-state {
  margin-top: 12px;
  text-align: center;

  .icon {
    color: $green600;
    font-size: 22px;
  }

  .text {
    color: $light;
    font-size: 11px;
    font-weight: 300;
    line-height: 16px;
    margin: 8px 0 0 0;

    .fontfamily-dropdown.is-light & {
      color: $darkGrey900;
    }

    strong {
      font-weight: 600;
    }
  }
}
</style>
