<template lang="pug">
BaseButton.control-general__align-button(
  :is-canvas="true"
  :data-title="title"
  @click="alignElement"
)
  i.icon(
    :class="icon"
  )
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AlignButton',
  props: {
    icon: { type: String, default: '' },
    title: { type: String, default: '' },
    direction: { type: String, default: '' },
    index: { type: Number, default: 0 },
  },
  computed: {
    ...mapGetters('canvasElements', ['getActiveElements']),
  },
  methods: {
    ...mapActions('canvasElements', ['setAllActiveElementsAlignment']),
    alignElement() {
      this.$root.$emit('alignElements', this.direction);
      setTimeout(() => {
        this.$root.$emit('canvasElementSizeUpdated');
      }, 10);
    },
  },
};
</script>

<style lang="scss"></style>
