<template lang="pug">
BaseDialog.header__create-image-dialog(
  isShow=true
  text="Do you want to create thumbnail/s for"
  noButton=true
  @confirm="$emit('confirm')"
  @closeOverlay="$emit('close')"
)
  .dialog-modal__buttons
    BaseButton(
      is-primary=true
      is-tall=true
      :is-canvas="true"
      @click="$emit('confirmAllScenes')"
    ) All Scenes
    BaseButton(
      is-primary-hollow=true
      is-tall=true
      :is-canvas="true"
      @click="$emit('confirmCurrentScene')"
    ) Current Scene
    BaseButton.cancel-button(
      is-hollow=true
      is-tall=true
      :is-canvas="true"
      @click="$emit('close')"
    ) Cancel
</template>

<script>
export default {
  name: 'CreateImageDialog',
};
</script>

<style lang="scss">
.header__create-image-dialog {
  .cancel-button {
    color: var(--darkgrey-white);

    &:hover {
      color: $darkGrey;
    }
  }
}

.dialog-modal__content {
  max-width: 600px;

  .dialog-modal__buttons {
    width: 100%;

    .btn {
      max-width: 150px;
    }
  }
}
</style>
