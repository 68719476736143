import { toCamel } from '@/assets/scripts/utilities';

const categoryMixins = {
  methods: {
    getActiveCategoryData(categoryData) {
      const categorySpecialOptions = process.env.VUE_APP_SPECIAL_GENERAL_CATEGORIES_OPTIONS.split(',');
      const activeCategoryData = categoryData;

      const categoryOptions = [];

      categoryData.meta.forEach((meta) => {
        if (categorySpecialOptions.includes(meta.key)) {
          // Special options are only true or false
          const addSpecialOption = (meta.value === '1');
          if (addSpecialOption) {
            activeCategoryData[toCamel(meta.key)] = addSpecialOption;
          }
        } else {
          categoryOptions.push(meta);
        }
      });

      if (categoryOptions.length) {
        activeCategoryData.meta = categoryOptions;
      }

      return activeCategoryData;
    },
  },
};

export default categoryMixins;
