<template lang="pug">
button.common-sizes-item(
  type="button"
  @click="$emit('click', { width, height })"
)
  .common-sizes-item__image
    img(:src="sizeImage")
  .common-sizes-item__content
    p.name {{ name }}
    p.size {{ `${width} x ${height}` }}
</template>

<script>
export default {
  name: 'CommonSizesItem',
  props: {
    name: { type: String, default: '' },
    image: { type: String, default: '' },
    width: { type: Number, default: 0 },
    height: { type: Number, default: 0 },
  },
  computed: {
    sizeImage() {
      // eslint-disable-next-line
      return require(`@/assets/images/sizes/${this.image}`);
    },
  },
};
</script>

<style lang="scss">
.common-sizes-item {
  width: 33%;
  flex: 33%;
  max-width: calc(33% - 20px);
  margin: 0 20px 20px 0;
  box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
  border: 0;
  cursor: pointer;
  padding: 0;
  text-align: left;

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.common-sizes-item__image {
  background-color: $lightGrey;
  display: flex;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.15s ease-in-out;
  }
}

.common-sizes-item__content {
  padding: 20px;
  background-color: var(--white-darkgrey800);

  .name {
    font-size: 1em;
    color: var(--darkgrey400-white);
    margin: 0 0 10px;
  }

  .size {
    font-size: 0.75em;
    color: var(--darkgrey-white);
    margin: 0;
  }
}
</style>
