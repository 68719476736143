<template lang="pug">
.controls__item.controls__item--text
  .controls__group
    BaseButton.btn--control.btn-open-font(
      v-if="showThisControlButton('Change Font Family')"
      :class="{'is-active': showFontTab, 'is-dark': isDarkMode}"
      @click="toggleFont"
      is-hollow=true
      :is-canvas="true"
      icon-after="icon-down-arrow"
    ) {{ fontFamilyText }}

    TooltipMenu.tooltip-menu--font-size(
      v-if="showThisControlButton('Change Font Size')"
      :class="{'is-dark': isDarkMode}"
      data-title="Change Font Size"
      :buttonText="fontSizeString"
      noHeader=true
      buttonClass="btn btn--hollow btn-font-size"
    )
      BaseSlider(
        :min="10"
        :max="400"
        :step="5"
        :value="fontSize"
        @update="setFontSize"
      )

    //- prevent duplicate color-toggler from ControlContainer.vue
    ColorToggler(
      v-if="!isBrandedGraphic"
      :is-text="true"
    )

    button.btn--control.btn--icon(
      v-if="showThisControlButton('Change Text Alignment')"
      type='button'
      data-title="Align"
      @click="changeTextAlignment"
    )
      template(v-if="textAlignment === 'left' || textAlignment === 'initial'")
        i.icon.icon-align-left1
      template(v-if="textAlignment === 'center'")
        i.icon.icon-align-center1
      template(v-if="textAlignment === 'right'")
        i.icon.icon-align-right1

    template(v-if="isShowFontWeightDropdown" )
      FontWeightUploadDropdown.font-weight-dropdown(
        direction="right"
        data-title="Font Weight"
        :custom-options="true"
        :custom-font-weights="activeFontWeightList"
        :custom-italic-font-weights="activeItalicFontWeightList"
        :font-weight-value="activeFontWeight"
        :is-italic-value="isItalic"
        @change="changeFontWeight"
      )
    template(v-else)
      button.btn--control.btn--icon.btn-font-bold(
        v-if="showThisControlButton('Bold')"
        type='button'
        data-title="Bold"
        :disabled="!isAbleToToggleBold"
        @click="toggleBold"
        :class="{ 'is-active': isBoldActive }"
      )
        i.icon.icon-bold
      button.btn--control.btn--icon.btn-font-italic(
        v-if="showThisControlButton('Italic')"
        type='button'
        data-title="Italic"
        :disabled="!isAbleToToggleItalic"
        @click="toggleItalic"
        :class="{ 'is-active': isItalicActive }"
      )
        i.icon.icon-italic


    TooltipMenu.tooltip--text-spacing(
      v-if="showThisControlButton('Text Spacing')"
      data-title="Text Spacing"
      buttonText="<i class='icon icon-text-spacing'></i>"
      noHeader=true
      buttonClass="btn--icon"
    )
      BaseSlider(
        :min="-20"
        :max="50"
        title="Spacing"
        :value="letterSpacing"
        @update="setLetterSpacing"
      )
      BaseSlider(
        :min="0.5"
        :max="3"
        title="Height"
        :value="lineHeight"
        :step="0.1"
        @update="setLineHeight"
      )

    button.btn--control.btn--icon.btn-uppercase(
      v-if="showThisControlButton('Uppercase')"
      type='button'
      data-title="Uppercase"
      @click="toggleUppercase"
      :class="{ 'is-active': isUppercase }"
    )
      i.icon.icon-caps

    button.btn--control.btn--icon.btn--vertical-align(
      v-if="showThisControlButton('Change Vertical Alignment')"
      type='button'
      data-title="Vertical Align"
      @click="changeVerticalAlignment"
    )
      template(v-if="verticalAlignment === 'top' || verticalAlignment === 'initialTop'")
        i.icon.icon-paragraph-top
      template(v-if="verticalAlignment === 'middle'")
        i.icon.icon-paragraph-middle
      template(v-if="verticalAlignment === 'bottom'")
        i.icon.icon-paragraph-bottom

  GlobalEvents(
    v-if="!isLiteMode"
    :filter="(event, handler, eventName) => allowGlobalEvents(event)"
    @keydown.ctrl.66.prevent="toggleBold"
    @keydown.ctrl.73.prevent="toggleItalic"
    @keydown.meta.66.prevent="toggleBoldMac"
    @keydown.meta.73.prevent="toggleItalicMac"
    @keydown.ctrl.shift.49.prevent="changeTextAlignment"
    @keydown.meta.shift.49.prevent="changeTextAlignmentMac"
  )
  //- for lite mode, bold and italic all text instead of doing multi style
  GlobalEvents(
    v-if="isLiteMode"
    @keydown.ctrl.66.prevent="toggleBold"
    @keydown.ctrl.73.prevent="toggleItalic"
    @keydown.meta.66.prevent="toggleBoldMac"
    @keydown.meta.73.prevent="toggleItalicMac"
  )
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'; // eslint-disable-line

import TooltipMenu from './children/TooltipMenu.vue'; // eslint-disable-line
import ColorToggler from '@/components/ProjectCanvas/ControlBar/children/ColorToggler.vue';
import FontWeightUploadDropdown from '@/components/ProjectCanvas/BrandTab/children/FontWeightUploadDropdown.vue'; // eslint-disable-line

import darkModeMixin from '@/components/mixins/dark-mode-mixins';
import {
  isMac,
  getSelectedText,
  getHasFontWeight,
  getHasBoldFontWeight,
  getHasItalicFontWeight,
} from '@/assets/scripts/utilities';
import { defaultFallbackFont } from '@/assets/scripts/variables';

export default {
  name: 'ControlText',
  components: {
    TooltipMenu,
    ColorToggler,
    FontWeightUploadDropdown,
  },
  mixins: [darkModeMixin],
  props: {
    isLiteVersion: { type: Boolean, default: false },
    isBrandedGraphic: { type: Boolean, default: false },
    hiddenMenu: { type: Array, default: () => [] },
  },
  data() {
    return {
      textAlignment: 'left',
      verticalAlignment: 'top',
      fontSize: 100,
      letterSpacing: 0,
      lineHeight: 1,
    };
  },
  computed: {
    ...mapState(['showFontTab']),
    ...mapState('canvasElements', ['activeFontFamily']),
    ...mapGetters(['getActiveOffeoFonts', 'isLiteMode']),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getActiveElementsIds',
      'getCanvasElementById',
    ]),
    fontSizeString() {
      return this.fontSize.toString();
    },
    hasBold() {
      if (!this.isSameFontFamily) return false;
      if (!this.activeFontFamily) console.error('Error: missing font family');
      if (
        this.getActiveElements.length === 0
        || this.getActiveElements[0].type !== 'texts'
        || !this.activeFontFamily
      ) return false;

      try {
        if (this.isItalic) return getHasBoldFontWeight(this.currentFontObj) && getHasItalicFontWeight(700, this.currentFontObj); // eslint-disable-line
        return getHasBoldFontWeight(this.currentFontObj);
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    isAbleToToggleBold() {
      if (!this.isSameFontFamily) return false;
      if (!this.activeFontFamily) console.error('Error: missing font family');
      if (
        this.getActiveElements.length === 0
        || this.getActiveElements[0].type !== 'texts'
        || !this.activeFontFamily
      ) return false;

      try {
        if (this.isBold && this.isItalic) return getHasItalicFontWeight(400, this.currentFontObj);
        else if (this.isBold) return getHasFontWeight(400, this.currentFontObj); // eslint-disable-line
        else if (this.isItalic) return getHasItalicFontWeight(700, this.currentFontObj); // eslint-disable-line
        return getHasBoldFontWeight(this.currentFontObj);
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    hasItalic() {
      if (!this.isSameFontFamily || !this.isSameFontWeight) return false; // cover issue where = the regular font has italic, but bold doesn't have, selecting both that have the same font family, it will be able to set both element/font to italic while supposely the bold font cannot.
      if (!this.activeFontFamily) console.error('Error: missing font family');
      if (
        this.getActiveElements.length === 0
        || this.getActiveElements[0].type !== 'texts'
        || !this.activeFontFamily
      ) return false;

      try {
        return getHasItalicFontWeight(this.activeFontWeight, this.currentFontObj);
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    isAbleToToggleItalic() {
      if (!this.isSameFontFamily || !this.isSameFontWeight) return false; // cover issue where = the regular font has italic, but bold doesn't have, selecting both that have the same font family, it will be able to set both element/font to italic while supposely the bold font cannot.
      if (!this.activeFontFamily) console.error('Error: missing font family');
      if (
        this.getActiveElements.length === 0
        || this.getActiveElements[0].type !== 'texts'
        || !this.activeFontFamily
      ) return false;

      try {
        if (this.isItalic) return getHasFontWeight(this.activeFontWeight, this.currentFontObj);
        return getHasItalicFontWeight(this.activeFontWeight, this.currentFontObj);
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    isSingleText() {
      if (this.getActiveElements.length === 1 && this.getActiveElements[0].type === 'texts') return true;
      return false;
    },
    isText() {
      let isText = true;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (element.type !== 'texts') {
          isText = false;
          return true;
        }
      });

      return isText;
    },
    isSameFontFamily() {
      let isSameFont = true;
      const { fontFamily } = this.getActiveElements[0].data;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (element.data.fontFamily !== fontFamily) {
          isSameFont = false;
          return true;
        }
      });

      return isSameFont;
    },
    isSameFontWeight() {
      const foundDifference = this.getActiveElements.some(element => element.data.fontWeight !== this.activeFontWeight);
      return !foundDifference;
    },
    activeFontWeightList() {
      return this.currentFontObj.weight;
    },
    activeItalicFontWeightList() {
      return this.currentFontObj.italic;
    },
    activeFontWeight() {
      const fontWeight = this.getActiveElements[0].data.fontWeight; // eslint-disable-line
      return fontWeight;
    },
    isBold() {
      const isAnyElementHasNoBold = this.getActiveElements.some(element => element.data.fontWeight !== 700);
      return !isAnyElementHasNoBold;
    },
    isBoldActive() {
      if (this.isBold && this.isItalic) {
        return this.hasBold && this.hasItalic;
      }
      return this.hasBold && this.isBold;
    },
    isItalic() {
      let isItalic = true;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (!element.data.textItalic) {
          isItalic = false;
          return true;
        }
      });

      return isItalic;
    },
    isItalicActive() {
      if (this.isBold && this.isItalic) {
        return this.hasBold && this.hasItalic;
      }
      return this.hasItalic && this.isItalic;
    },
    isUppercase() {
      let isUppercase = true;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (!element.data.textUppercase) {
          isUppercase = false;
          return true;
        }
      });

      return isUppercase;
    },

    /* eslint-disable */
    currentFontObj() {
      const fallbackFontObject = this.getActiveOffeoFonts.find((font) => font.fontFamily === defaultFallbackFont);
      const selectedFontObject = this.getActiveOffeoFonts.find(
        (font) => font.fontFamily === this.activeFontFamily
      );

      return selectedFontObject || fallbackFontObject;
    },
    /* eslint-enable */
    fontFamilyText() {
      const fontObj = this.currentFontObj;
      let fontFamilyText = 'Lorem Ipsum';
      if (fontObj) {
        fontFamilyText = fontObj.text;
      }
      return fontFamilyText;
    },
    hasMultipleFontWeights() {
      if (!this.isSameFontFamily) return false;
      const { activeFontWeightList, activeItalicFontWeightList } = this;
      // if a font has font weight other than bold and regular, show
      const hasAnotherFontWeight = activeFontWeightList.includes(200) || activeFontWeightList.includes(300) || activeFontWeightList.includes(500) || activeFontWeightList.includes(600); // eslint-disable-line
      const hasAnotherItalicFontWeight = activeItalicFontWeightList.includes(200) || activeItalicFontWeightList.includes(300) || activeItalicFontWeightList.includes(500) || activeItalicFontWeightList.includes(600); // eslint-disable-line

      return hasAnotherFontWeight || hasAnotherItalicFontWeight;
    },
    isShowFontWeightDropdown() {
      if (!this.isSameFontFamily) return false;
      // eslint-disable-next-line
      return this.showThisControlButton('Bold') && this.showThisControlButton('Italic') && this.hasMultipleFontWeights && this.isSameFontFamily && this.isSameFontWeight;
    },
  },
  methods: {
    ...mapMutations([
      'setShowFontTab',
      'setShowSidebar',
    ]),
    ...mapMutations('canvasElements', [
      'updateCanvasElementFontSize',
      'updateCanvasElementTextAlignment',
      'updateCanvasElementVerticalAlignment',
      'updateCanvasElementTextStyle',
      'updateCanvasElementContent',
      'updateCanvasElementFontWeight',
    ]),
    ...mapActions('canvasHistory', ['catchHistory']),
    allowGlobalEvents(event) {
      return event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA' && event.target.getAttribute('contenteditable') !== 'true';
    },
    toggleFont() {
      this.setShowSidebar({ boolean: true, maintainShowFontTabState: true });
      this.setShowFontTab(!this.showFontTab);
    },
    changeTextAlignment() {
      let newAlignment = 'left';

      if (this.textAlignment === 'left') {
        newAlignment = 'center';
      } else if (this.textAlignment === 'center') {
        newAlignment = 'right';
      }

      this.textAlignment = newAlignment;

      this.catchHistory('element');
      this.updateCanvasElementTextAlignment(newAlignment);
    },
    changeTextAlignmentMac() {
      if (!isMac()) return;
      this.changeTextAlignment();
    },
    changeVerticalAlignment() {
      let newAlignment = 'top';

      if (this.verticalAlignment === 'top') {
        newAlignment = 'middle';
      } else if (this.verticalAlignment === 'middle') {
        newAlignment = 'bottom';
      }

      this.verticalAlignment = newAlignment;

      this.catchHistory('element');
      this.updateCanvasElementVerticalAlignment(newAlignment);
    },
    changeVerticalAlignmentMac() {
      if (!isMac()) return;
      this.changeVerticalAlignment();
    },
    changeFontWeight(fontStyle) {
      let fontWeight = 400;
      let enableItalic = false;

      if (fontStyle.subValue.includes('italic')) {
        enableItalic = true;
      }

      // value received from teamFontDropdown can be 'light' / 'light-italic'
      if (fontStyle.value.includes('extra-light')) {
        fontWeight = 200;
      } else if (fontStyle.value.includes('light')) {
        fontWeight = 300;
      } else if (fontStyle.value.includes('medium')) {
        fontWeight = 500;
      } else if (fontStyle.value.includes('semi-bold')) {
        fontWeight = 600;
      } else if (fontStyle.value.includes('bold')) {
        fontWeight = 700;
      }

      this.updateCanvasElementFontWeight({
        fontWeight,
        enableItalic,
      });
    },
    toggleBold() {
      if (!this.isAbleToToggleBold) return;
      const selectedText = getSelectedText();
      const textTemp = document.getElementsByClassName('text-temp')[0];

      this.catchHistory('element');

      if (selectedText && textTemp && !this.isLiteMode) {
        console.log('isLiteMode');
        document.execCommand('bold');
        const content = textTemp.getElementsByClassName('text-content')[0].innerHTML;
        if (content) {
          this.updateCanvasElementContent({
            id: this.getActiveElementsIds[0],
            content,
          });
        }
      } else {
        console.log('not isLiteMode');
        const toggledFontWeightValue = this.isBold ? 400 : 700;
        const isSelectedFontWeightHasItalic = getHasFontWeight(toggledFontWeightValue, this.currentFontObj, 'italic');

        if (!isSelectedFontWeightHasItalic) {
          // cover case where the font doesn't have regular-italic, but has bold-italic
          this.updateCanvasElementTextStyle({
            style: 'textItalic',
            value: false,
          });
        }
        this.updateCanvasElementTextStyle({
          style: 'fontWeight',
          value: toggledFontWeightValue,
        });
      }
    },
    toggleBoldMac() {
      if (!isMac()) return;
      this.toggleBold();
    },
    toggleItalic() {
      if (!this.isAbleToToggleItalic) return;
      const selectedText = getSelectedText();
      const textTemp = document.getElementsByClassName('text-temp')[0];

      this.catchHistory('element');

      if (selectedText && textTemp && !this.isLiteMode) {
        document.execCommand('italic');
        const content = textTemp.getElementsByClassName('text-content')[0].innerHTML;
        if (content) {
          this.updateCanvasElementContent({
            id: this.getActiveElementsIds[0],
            content,
          });
        }
      } else {
        this.updateCanvasElementTextStyle({
          style: 'textItalic',
          value: !this.isItalic,
        });
      }
    },
    toggleItalicMac() {
      if (!isMac()) return;
      this.toggleItalic();
    },
    toggleUppercase() {
      this.catchHistory('element');

      this.updateCanvasElementTextStyle({
        style: 'textUppercase',
        value: !this.isUppercase,
      });
    },
    setFontSize(value) {
      const newValue = value;

      this.catchHistory('element');

      this.fontSize = newValue;

      this.getActiveElementsIds.forEach((id) => {
        this.updateCanvasElementFontSize({
          id,
          fontSize: newValue,
        });
      });

      // when font size is changed, textElement will emit the canvas-element-font-updated
    },
    setLetterSpacing(value) {
      this.catchHistory('element');
      this.letterSpacing = value;
      this.updateCanvasElementTextStyle({
        style: 'letterSpacing',
        value,
      });
    },
    setLineHeight(value) {
      this.catchHistory('element');
      this.lineHeight = value;
      this.updateCanvasElementTextStyle({
        style: 'lineHeight',
        value,
      });
    },
    updateControlTextBasedOnActiveElements() {
      this.textAlignment = this.getActiveElements[0].data.textAlign;
      this.verticalAlignment = this.getActiveElements[0].data.verticalAlign;
      this.fontSize = parseInt(this.getActiveElements[0].data.fontSize, 10);
      this.letterSpacing = this.getActiveElements[0].data.letterSpacing;
      this.lineHeight = this.getActiveElements[0].data.lineHeight;
    },
    updateFontSize(element) {
      const id = element ? element.id : null;
      // console.log('updateFontSize', id)
      if (id && this.getCanvasElementById(id)) {
        // eslint-disable-next-line
        this.fontSize = parseInt(this.getCanvasElementById(id).data.fontSize);
      }
    },
    showThisControlButton(text) {
      if (!this.isLiteMode) return true;
      if (this.hiddenMenu.includes(text) && this.isLiteVersion) return true;
      if (!this.hiddenMenu.includes(text) && !this.isLiteVersion) return true;
      return false;
    },
  },
  mounted() {
    // console.log('123 mounted', JSON.parse(JSON.stringify(this.getActiveElements[0].data)));
    this.updateControlTextBasedOnActiveElements();
    // console.log(' CONTROL TEXT MOUNTED', this.isLiteVersion)

    this.$root.$on('canvas-element-font-updated', this.updateFontSize);
  },
  destroyed() {
    // this.$root.$off('canvas-element-font-style-updated');
    // need to off to avoid memory leak
    this.$root.$off('canvas-element-font-updated', this.updateFontSize);
  },
  watch: {
    getActiveElementsIds() {
      this.updateControlTextBasedOnActiveElements();
    },
  },
};
</script>

<style lang="scss">
.controls__item {
  .btn--icon {
    &.is-active {
      color: var(--blue-white);
    }
  }

  .btn--vertical-align {
    .icon-paragraph-middle {
      font-size: 1.125rem;
    }

    &:not(.icon-paragraph-middle) {
      font-size: 1rem;
    }
  }

  .btn-font-size {
    padding: 9px 8px;

    .is-dark & {
      border-color: $light !important;
      color: $light;
    }
  }

  .btn-uppercase {
    font-size: 1.25rem !important;
  }
}

.controls__item--text {
  .controls__group {
    .btn {
      @include fb-requirement {
        margin: 0 4px;
      }
    }
  }

  .font-weight-dropdown {
    height: 32px;
    font-weight: 500;
    width: 85px;

    &.is-active {
      &::after {
        opacity: 0 !important;
      }
    }

    .team-font-dropdown__selected {
      height: 100%;
      padding: 5px 10px;
      font-weight: 500;
      margin: 0;
      background: transparent;
      border-color: var(--darkgrey900-white);
      color: var(--darkgrey900-white);

      .icon {
        font-size: 1rem;
        top: -2px;
      }

      .btn__content span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 85%;
      }

      &:hover {
        background: var(--lightblue2-darkgrey5);
        border-color: var(--lightblue2-white);
      }
    }

    .team-font-dropdown__list,
    .team-font-dropdown__selected .name {
      font-size: 0.75rem;
    }

    .team-font-dropdown__content {
      width: 130px;

      .sub-lists.direction--right {
        right: -160px;
      }
    }
  }
}

.tooltip--text-spacing {
  .tooltip-menu__content--noheader {
    min-width: 350px;
    left: -155px;
  }

  .slider {
    display: flex;
    align-items: center;
  }

  .slider__title {
    min-width: 80px;
  }

  .btn-tooltip-menu {
    font-size: 1rem;
  }
}

.btn-open-font {
  width: 86px;
  justify-content: flex-start;
  position: relative;
  padding: 7px;

  &.is-dark {
    border-color: $light !important;
    color: $light;
  }

  &:hover,
  &.is-active {
    background: $lightBlue2 !important;
    border-color: $lightBlue2 !important;

    &.is-dark {
      background: $darkGrey5 !important;
      border-color: $light !important;
    }
  }

  .btn__content {
    display: flex;
    align-items: center;
  }

  .icon {
    font-size: 1rem;
    position: absolute;
    right: 2px;
    margin-left: 0;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60px;
  }
}

.top-controls .tooltip-menu--font-size {
  .btn-tooltip-menu {
    min-width: 45px;
  }

  .tooltip-menu__content {
    top: 47px;
    left: -110px;
  }
}
</style>
