<template lang="pug">
.filters__item(
  :class="{ 'is-selected': isSelected }"
  @click="updateImageFilter"
)
  .filters__image
    .filters__content(:class="item.value")
      img.image(:src="filterThumbnail")
  span.filters__name(v-html="item.name")
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { filterList } from '@/assets/scripts/variables';

export default {
  name: 'FilterItem',
  props: {
    item: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters('canvasElements', ['getActiveElements']),
    filterThumbnail() {
      const selectedElement = this.getActiveElements[0];
      const { isImage } = selectedElement;

      /* eslint-disable */
      return (
        (isImage
          ? selectedElement.data && selectedElement.data.url
          : selectedElement.data && selectedElement.data.thumb)
        || require('@/assets/images/media/filter_image.jpg')
      );
      /* eslint-enable */
    },
    isSelected() {
      if (this.getActiveElements.length === 0) {
        // eslint-disable-next-line
        if (filterValue === 'none') return true;
        return false;
      }
      const activeElements = this.getActiveElements;
      const filterValue = this.item.value;

      const activeFilters = activeElements[0].filters;

      const noFilter = !activeFilters
        || (activeElements[0].filters && !activeFilters.name && filterValue === 'none');
      const isSelectedFilter = activeFilters && activeFilters.name === filterValue;

      if (noFilter || isSelectedFilter) return true;
      return false;
    },
  },
  methods: {
    ...mapActions('canvasElements', ['updateCanvasElementFilter']),
    updateImageFilter() {
      const name = this.item.value;
      const filter = {
        blur: 0,
        brightness: 1,
        contrast: 1,
        hue: 0,
        name,
        saturate: 1,
        sepia: 0,
      };

      if (filterList[name]) {
        // eslint-disable-next-line
        for (const prop in filterList[name]) {
          filter[prop] = filterList[name][prop];
        }
      }

      this.updateCanvasElementFilter(filter);
    },
  },
};
</script>

<style lang="scss">
.filters__item {
  width: 32%;
  margin: 12px 4px 0 0;
  cursor: pointer;

  &:nth-child(3n) {
    margin-right: 0;
  }

  &.is-selected,
  &:hover,
  &:focus {
    .filters__image {
      border-color: $blue;
    }
  }
}

.filters__image {
  width: 100%;
  position: relative;
  border: 1px solid $darkGrey700;
  border-radius: 4px;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.filters__content {
  height: 100%;
  left: 0;
  position: absolute !important;
  top: 0;
  width: 100%;

  .image {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.filters__name {
  color: $lightWhite;
  display: block;
  font-size: 0.75rem;
  margin: 12px 0 0;
  text-align: center;
  font-weight: 500;
}
</style>
