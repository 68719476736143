<template lang="pug">
.upload-music-modal
  CanvasModal(
    title="Select an audio"
    :isShow="true"
    @closeOverlay="closeOverlay"
  )
    .upload-music-modal__top
      label.btn-upload-music.btn.btn--primary--hollow(
        @drop.prevent="doDropFile($event, 'music')"
        @dragover.prevent
      )
        input(
          type="file"
          id="manual-file-upload"
          ref="fileUpload"
          :accept="mobileFileAccept"
          @change="doSelectFile()"
        )
        i.icon.icon-plus
        span.label Upload
    BasePreloader.upload-music-modal__preloader(
      v-if="isFetching"
    )
    .upload-music-modal__list(
      v-else
      @dragenter="dragEnter"
      @dragexit="dragLeave"
    )
      //--------------
      //- when it's empty
      //--------------
      .empty-list(
        v-if="showEmptyList"
        @click="showFileUpload"
      )
        i.icon.icon-upload1
        .title You haven't uploaded any files yet
        .text Drag and drop files here. You can add MP3
      DropZone(
        v-if="showDropZone"
        title="Drop to upload your music here"
        text="You can add MP3."
        @dropZoneDrop="drop"
        @dropZoneDragLeave="dragLeave"
      )
      .upload-music-modal__skeleton(
        v-for="skeleton in skeletonList"
      )
        BasePreloader
      LiteModeUploadMusicItem(
        v-for="element in elements"
        :class="{'is-active': element.id === selectedMusic.id}"
        :key="element.id"
        :id="element.id"
        :title="element.title"
        :music="element"
        @select="selectMusic"
      )
    template(#bottom)
      BaseButton(
        isPrimary=true
        isTall=true
        :isDisabled="!selectedMusic.id"
        @click="confirmMusic"
      ) Use this track

</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { groups, subgroups } from '@/assets/scripts/enums';

import uploadingMixin from '@/components/mixins/uploading-mixins';
import LiteModeUploadMusicItem from './children/LiteModeUploadMusicItem.vue';
import CollectionsApi from '@/services/api/CollectionsApi';
import DropZone from '@/components/SmartCreate/PageOverlay/children/DropZone.vue';

export default {
  name: 'LiteModeUploadMusic',
  mixins: [
    uploadingMixin,
  ],
  data() {
    return {
      isFetching: true,
      selectedMusic: {},
      isDragEnter: false,
    };
  },
  components: {
    LiteModeUploadMusicItem,
    DropZone,
  },
  computed: {
    ...mapState('assetsSidebar', ['skeletonList']),
    ...mapGetters('assetsSidebar', ['getSidebarElements']),
    ...mapGetters('canvasElements', ['getSelectedMusic']),
    elements() {
      const elements = this.getSidebarElements['music/upload/all'];
      console.log(typeof elements);
      if (!elements) return undefined;
      return elements.filter(element => element.file);
    },
    showDropZone() {
      return this.isDragEnter;
    },
    showEmptyList() {
      return !this.isFetching && this.elements.length === 0 && Object.keys(this.skeletonList).length === 0;
    },
  },
  mounted() {
    if (typeof this.elements === 'undefined') {
      const category = {
        id: -1,
        slug: 'all',
      };
      CollectionsApi.getItems('user-files', { menu: 'audio' })
        .then((response) => {
          const { results } = response.data;

          this.addElementToSidebar({
            group: groups.MUSIC,
            subgroup: subgroups.UPLOAD,
            category,
            slug: 'all',
            data: results,
          });

          this.isFetching = false;
          this.checkIfSelectedMusicFromUpload();
        });
    } else {
      this.isFetching = false;
      this.checkIfSelectedMusicFromUpload();
    }
  },
  methods: {
    ...mapMutations(['setShowLiteUploadMusic']),
    ...mapMutations('assetsSidebar', [
      'setActiveSubGroup',
      'addElementToSidebar',
    ]),
    ...mapMutations('canvasElements', [
      'updateSelectedMusic',
    ]),
    ...mapMutations([
      'isPlaying',
    ]),
    closeOverlay() {
      this.setShowLiteUploadMusic(false);
    },
    selectMusic(music) {
      console.log('music', music);
      this.selectedMusic = music;
    },
    confirmMusic() {
      let currentMusicSettings = this.getSelectedMusic;

      if (!currentMusicSettings
        || currentMusicSettings.length === 0) {
        // currentMusicSettings sometimes returns as [] instead of Object
        // due to BE sending back audio as [] if user has removed audio
        // if it is [], change it back to this settings
        currentMusicSettings = {
          volume: 100,
          fade_in: false,
          fade_out: false,
        };
      }

      const musicUrl = `${this.selectedMusic.file}`;

      console.log('currentMusicSettings', currentMusicSettings, musicUrl);
      this.updateSelectedMusic({
        url: musicUrl,
        title: this.selectedMusic.title,
        artist: this.selectedMusic.artist,
        fade_in: currentMusicSettings.fade_in,
        fade_out: currentMusicSettings.fade_out,
        volume: currentMusicSettings.volume,
      });
      this.closeOverlay();
    },
    checkIfSelectedMusicFromUpload() {
      if (!this.getSelectedMusic
        || !this.getSelectedMusic.url
        || this.elements.length === 0) return;

      console.log('getSelectedMusic', this.getSelectedMusic.url);
      for (let i = 0; i < this.elements.length; i += 1) {
        const music = this.elements[i];

        // selectedMusic.url may have t=start,end added
        // therefore, we use includes
        if (this.getSelectedMusic.url && this.getSelectedMusic.url.includes(music.file)) {
          this.selectedMusic = music;
          break;
        }
      }
    },
    drop(file) {
      this.isDragEnter = false;
      if (!file.type.includes('audio')) {
        const message = 'Upload failed. Only file type mp3 is accepted.';
        this.alertError('', message, 3000, 'TopCenterNotif', true);
        return;
      }

      this.files = [file];
      this.$nextTick(() => {
        this.doCheckFiles();
      });
    },
    dragEnter() {
      this.isDragEnter = true;
    },
    dragLeave() {
      this.isDragEnter = false;
    },
    showFileUpload() {
      if (this.$refs.fileUpload) this.$refs.fileUpload.click();
    },
  },
  watch: {
    elements: {
      handler(val) {
        console.log('elements updated', val);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.upload-music-modal {
  .content--bottom {
    justify-content: flex-end;
  }
}

.upload-music-modal__preloader {
  min-height: 500px;
  margin-top: -50px;
}

.upload-music-modal__list {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  min-height: 400px;
}

.upload-music-modal__top {
  text-align: right;
  margin-bottom: 20px;

  .btn-upload-music {
    margin-left: auto;
    min-width: 190px;
    text-align: left;
    justify-content: flex-start;

    input {
      display: none;
    }
  }
}

.upload-music-modal__skeleton,
.upload-music-modal__item {
  text-align: center;
  display: inline-flex;
  width: calc(100% / 6 - 20px);
  margin: 5px 10px 10px;
  flex-direction: column;
  cursor: pointer;
  padding: 0;
}

.upload-music-modal__skeleton {
  position: relative;
  &::after {
    content: '';
    padding-top: 100%;
    display: block;
  }

  .preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.drop-zone-container,
.empty-list {
  height: 300px;
  width: 620px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: $componentBorderRadius;

  @include mobile() {
    width: calc(100% - 40px);
    height: 250px;
  };
}
</style>
