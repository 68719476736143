<template lang="pug">
.top-controls(
  ref="topControls"
  :class="{'is-dark': isDarkMode}"
)
  .controls__wrapper(v-if="getActiveElements.length || isSwapping")
    .controls__content
      template(v-if="!isLiteMode")
        BaseButton.btn-open-animate(
          :class="{'is-dark': isDarkMode, 'is-active': showAnimationTab}"
          is-primary-hollow=true
          :is-canvas="true"
          @click="toggleAnimationList"
        )
          svg.animation-icon(id="animateSvg" width="15" height="10" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg")
            ellipse(cx="5.25379" cy="5.76291" rx="5.25379" ry="5.46408" fill="white")
            ellipse(opacity="0.2" cx="11.6366" cy="5.76291" rx="5.25379" ry="5.46408" fill="white")
          p Animate

      BaseButton.control--edit-button(
        v-if="isShowEditButton"
        :class="{'is-dark': isDarkMode, 'is-active': showEditTab}"
        :is-canvas="true"
        is-primary-hollow=true
        @click="toggleEditTab"
      )
        i.icon-adjustments
        p Edit

      template(
        v-if="!isText && !isMultiple && !isBrandedGraphic"
      )
        ConfirmationControl.controls__replace-all-image(
          v-if="!isLiteMode"
          :is-icon="true"
          :is-show="showSwap"
          :is-done-button-loading="isDoneButtonLoading"
          :data-title="replaceText"
          button-class="icon-replace-all-image"
          @show-confirmation="swapElement"
          @confirm="confirmSwap"
          @cancel="cancelSwap"
        )

      ColorToggler(
        :is-branded-graphic="isBrandedGraphic"
        v-if="isBrandedGraphic"
      )

      ControlText(
        v-if="isText"
        :hidden-menu="hiddenMenu"
        :is-branded-graphic="isBrandedGraphic"
      )
      ControlMedia(
        v-if="isMedia"
        :isVideo="isVideo"
        :isWebm="isWebm"
        :isBrandedGraphic="isBrandedGraphic"
        :hidden-menu="hiddenMenu"
      )
      ControlElement(
        v-if="!isMedia && !isText"
        @toggle-color-tab="toggleColorTab"
      )

      button.control--mask-button.btn--text.btn--control(
        v-if="isImage && !isMultiple"
        :class="{'is-active': showMaskTab}"
        type='button'
        data-title='Mask'
        @click="toggleMaskTab"
      )
        svg(width="20" height="18" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg")
          rect(x="0.75" y="0.75" width="19.6111" height="18.5" rx="1.25" stroke="white" stroke-width="1.5")
          path(d="M7.58266 8.63249C7.78028 8.33299 8.21972 8.33299 8.41734 8.63249L10.9525 12.4746C11.1718 12.8071 10.9334 13.25 10.5352 13.25H5.46485C5.06657 13.25 4.82816 12.8071 5.04751 12.4746L7.58266 8.63249Z" fill="white")
          path(opacity="0.5" d="M11.5573 5.8435C11.7446 5.48666 12.2554 5.48666 12.4427 5.8435L15.9457 12.5176C16.1205 12.8506 15.879 13.25 15.503 13.25H8.49699C8.12099 13.25 7.87952 12.8506 8.05427 12.5176L11.5573 5.8435Z" fill="white")
    ControlGeneral(
      :isMedia="isMedia"
      :hidden-menu="hiddenMenu"
    )
  template(v-else-if="isOnMusicTab && showTrim")
    ControlMusic(
      :timeline-time="timelineTime"
    )
  template(v-else-if="!isLiteMode && getCanvasElements.length")
    BaseButton.basic-controller(
      :class="{'is-dark': isDarkMode}"
      is-hollow=true
      :is-canvas="true"
      @click="openGenieModal"
    ) Animate all
    template(v-if="hasImageOrMask")
      BaseButton.basic-controller(
        :class="{'is-dark': isDarkMode}"
        is-hollow=true
        :is-canvas="true"
        @click="openMultipleSwap()"
      ) Replace images

  template(
    v-if="isLiteMode && getActiveElements.length"
  )
    button.btn--icon.btn-toggle-lite-mode-control(
      v-show="hiddenMenu.length"
      :class="{ 'is-active': showLiteModeControl }"
      type="button"
      @click="toggleShowLiteModeControl"
    )
      i.icon.icon-options-vertical
    ControlLiteMode(
      v-click-outside="hideShowLiteModeControl"
      v-show="showLiteModeControl"
      :isText="isText"
      :isMedia="isMedia"
      :isVideo="isVideo"
      :isWebm="isWebm"
      :isBrandedGraphic="isBrandedGraphic"
      @toggle-color-tab="toggleColorTab"
      @update-menu="updateHiddenMenu"
    )

  GlobalEvents(
    v-if="!isLiteMode"
    @keydown.ctrl.71.exact.prevent="groupElements"
    @keydown.meta.71.exact.prevent="groupElements"
  )
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import vClickOutside from 'v-click-outside';

import { groups, subgroups, editTabs } from '@/assets/scripts/enums';
import { randomId } from '@/assets/scripts/utilities';

import categoryMixins from '@/components/mixins/category-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

import ControlText from '@/components/ProjectCanvas/ControlBar/ControlText.vue';
import ControlMedia from '@/components/ProjectCanvas/ControlBar/ControlMedia.vue';
import ControlElement from '@/components/ProjectCanvas/ControlBar/ControlElement.vue';
import ControlGeneral from '@/components/ProjectCanvas/ControlBar/ControlGeneral.vue';
import ControlMusic from '@/components/ProjectCanvas/ControlBar/ControlMusic.vue';
import ConfirmationControl from '@/components/ProjectCanvas/ControlBar/children/ConfirmationControl.vue';
import ControlLiteMode from '@/components/ProjectCanvas/ControlBar/ControlLiteMode.vue';
import ColorToggler from '@/components/ProjectCanvas/ControlBar/children/ColorToggler.vue';

export default {
  name: 'ControlContainer',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [categoryMixins, darkModeMixin],
  components: {
    ControlText,
    ControlMedia,
    ControlElement,
    ControlGeneral,
    ControlMusic,
    ConfirmationControl,
    ControlLiteMode,
    ColorToggler,
  },
  props: {
    timelineTime: { type: Number, default: 0 },
  },
  data() {
    return {
      hiddenMenu: [],
      elementStroke: [],
      showLiteModeControl: false,
      isDoneButtonLoading: false,
    };
  },
  computed: {
    ...mapState([
      'showSwap',
      'showTrim',
      'showColorPicker',
      'showAnimationTab',
      'showEditTab',
      'showMaskTab',
    ]),
    ...mapState('canvasElements', ['elementsList', 'activeElementsIds']),
    ...mapGetters(['isTextBackground', 'isTextShadow', 'getCanvasZoom', 'isLiteMode']),
    ...mapGetters('userData', ['isDesigner']),
    ...mapGetters('assetsSidebar', ['getActiveGroup']),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getActiveElementsIds',
      'getCanvasBackground',
      'getActiveColorId',
      'getCanvasElementById',
      'getScenes',
      'getProjectDetails',
      'getSwappedElement',
      'getCanvasElements',
    ]),
    hasImageOrMask() {
      // eslint-disable-next-line
      for (let i = 0; i < this.elementsList.allIds.length; i++) {
        const elementId = this.elementsList.allIds[i];

        const element = this.elementsList.byId[elementId];

        // don't include element.isImage or png because they can be graphics
        if (element.type === 'images' || element.type === 'masks') return true;
      }
      return false;
    },
    isText() {
      let isText = false;
      if (this.getActiveElements.length) {
        if (this.getActiveElements[0].type === 'texts') isText = true;
        if (
          this.getActiveElements.length > 1
          && this.getActiveElements.findIndex(activeEle => activeEle.type !== 'texts') === -1
        ) {
          isText = true;
        }
      }
      return isText;
    },
    isMedia() {
      let isMedia = false;
      // console.log('ismedia', this.getActiveElements[0].data.url)
      if (this.getActiveElements.length) {
        if (
          (this.getActiveElements[0].isImage
            || this.getActiveElements[0].type === 'videos'
            || this.isWebm)
          && !this.isSvg
        ) {
          isMedia = true;
        }

        // if (this.getActiveElements.length > 1
        //   && (this.getActiveElements.findIndex(activeEle => !activeEle.isImage) === -1
        //   || this.getActiveElements.findIndex(activeEle => activeEle.type !== 'videos') === -1
        //   || this.getActiveElements.findIndex(activeEle => activeEle.data.url && activeEle.data.url.split('.').pop() === 'webm') === -1)) isMedia = true;
      }
      return isMedia;
    },
    isImage() {
      if (this.getActiveElements.length) {
        const activeElement = this.getActiveElements[0];
        return activeElement.isImage || activeElement.type === 'images' || this.isPng;
      }
      return false;
    },
    isPng() {
      let isPng = true;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (!element.data.url || element.data.url.split('.').pop() !== 'png') {
          isPng = false;
          return true;
        }
      });

      return isPng;
    },
    isMask() {
      let isMask = false;
      if (this.getActiveElements.length === 1 && this.getActiveElements[0].type === 'masks') {
        isMask = true;
      }
      return isMask;
    },
    isSvg() {
      let isSvg = false;

      if (this.getActiveElements.length === 1 && !this.isMask) {
        const { url } = this.getActiveElements[0].data;
        if (url && url.split('.').pop() === 'svg') {
          isSvg = true;
        }
      }
      return isSvg;
    },
    isSvgWithStroke() {
      // can't use 'isSvg' above because previously the 'isSvg' from 'ControlElement.vue' is not the same when moved here.
      let isSvgWithStroke = true;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (
          element.type !== 'shapes'
          || (element.data.url && element.data.url.split('.').pop() !== 'svg')
        ) {
          isSvgWithStroke = false;
          return true;
        }
      });

      return isSvgWithStroke;
    },
    elementHasStroke() {
      return this.isSvgWithStroke && this.elementStroke.length;
    },
    isBrandedGraphic() {
      if (
        this.getActiveElements.length === 1
        && this.getActiveElements[0].isBrandedAsset
        && this.getActiveElements[0].data.svg_url
      ) {
        return true;
      }
      return false;
    },
    isWebm() {
      if (
        this.getActiveElements.length === 1
        && this.getActiveElements[0].data.url
        && this.getActiveElements[0].data.url.split('.').pop() === 'webm'
      ) {
        return true;
      }
      return false;
    },
    isVideo() {
      if (
        this.getActiveElements.length === 1
        && (this.getActiveElements[0].type === 'videos' || this.isWebm)
      ) {
        return true;
      }
      return false;
    },
    isMultiple() {
      return this.activeElementsIds.length > 1;
    },
    isGroupElements() {
      let isGroup = true;

      this.getActiveElements.forEach((element) => {
        if (element.data.isGroup === undefined || !element.data.isGroup) {
          isGroup = false;
        }
      });

      return isGroup;
    },
    isMultipleWithMixedElementTypes() {
      let foundTextElement = false;
      let foundOtherElement = false;

      this.getActiveElements.forEach((element) => {
        if (element.type === 'texts') foundTextElement = true;
        if (element.type !== 'texts') foundOtherElement = true;
      });

      return foundTextElement && foundOtherElement;
    },
    canvasBackground() {
      let background = '';
      if (this.getCanvasBackground.color1) {
        background = `linear-gradient(${this.getCanvasBackground.degree}deg, ${this.getCanvasBackground.color1}, ${this.getCanvasBackground.color2})`;
      } else {
        background = this.getCanvasBackground.color ? this.getCanvasBackground.color : '#ffffff';
      }
      // console.log('background', background)
      return background;
    },
    replaceText() {
      let { type } = this.getActiveElements[0];
      if (type.slice(-1) === 's') type = type.slice(0, type.length - 1);
      type = type === 'mask' ? 'image' : type;
      return `Replace ${type}`;
    },
    isSwapping() {
      return this.showSwap && this.getSwappedElement;
    },
    isOnMusicTab() {
      return this.getActiveGroup === groups.MUSIC;
    },
    isShowEditButton() {
      /*
      only show edit button if :
      - it isn't a multiple selection of other elements ( e.g image, etc )
      - its a text element
      - they are a multiple selection consist of text elements. ( no other elements except the text )
      */
      return !this.isMultiple || (this.isText && !this.isMultipleWithMixedElementTypes);
    },
  },
  methods: {
    ...mapMutations([
      'closeExtraLeftSidebar',
      'setIsTextBackground',
      'setIsTextShadow',
      'setIsShowGenieModal',
      'setShowMultipleSwap',
      'setShowModularTemplate',
      'setIsSwapOpened',
      'setShowSidebar',
      'setShowColorPicker',
      'setShowLiteReplaceModal',
      'setActiveAnimationList',
      'setShowAnimationTab',
      'setShowEditTab',
      'setShowMaskTab',
      'setActiveEditTabGroup',
      'setEditTabEffectsType',
    ]),
    ...mapMutations('canvasElements', [
      'updateActiveColorId',
      'updateSwappedElement',
      'insertElementToCanvas',
      'updateCanvasElementStroke',
    ]),
    ...mapMutations('assetsSidebar', [
      'setActiveSubGroup',
      'setActiveGroup',
      'setActiveCategory',
      'clearActiveKeyword',
    ]),
    ...mapMutations('canvasLayouts', ['setCanvasSectionPaddingTop']),
    ...mapActions('canvasHistory', ['catchHistory']),
    ...mapActions('canvasElements', ['addElementsToGroup']),
    ...mapActions(['toggleColorTab']),
    updateHiddenMenu(menu) {
      this.hiddenMenu = menu;
    },
    openMultipleSwap() {
      this.setShowMultipleSwap(true);

      // pause temp audio and canvas when opening crop
      this.$root.$emit('pause-all-audio');
    },
    openGenieModal() {
      this.setIsShowGenieModal(true);

      // pause temp audio and canvas when opening geniemate
      this.$root.$emit('pause-all-audio');
    },
    swapElement() {
      console.log('---swapping element---');
      this.updateSwappedElement(this.getActiveElements[0]);
      this.setIsSwapOpened(true);
      this.setShowSidebar(true);

      // swap element by relevant
      const activeElement = this.getActiveElements[0];

      // set active tab of swap element
      const group = activeElement.menu || activeElement.data.menu || groups.MEDIA;
      const subgroup = activeElement.isCustom
        ? subgroups.CUSTOMELEMENTS
        : activeElement.category[0] || activeElement.type;
      const category = activeElement.sub_category_name;

      this.setActiveGroup(group);
      this.setActiveSubGroup(subgroup);
      // for masks, ensure it goes to photos tab

      // console.log('swapElement', group, subgroup, category);
      if ((subgroup === 'masks' || subgroup === 'images') && group === 'media') {
        this.setActiveSubGroup('photo');
      } else if (subgroup === 'videos' && group === 'media') this.setActiveSubGroup('video');
      else if (subgroup === 'logos' && group === 'my-files') this.setActiveSubGroup('logos');

      this.$nextTick(() => {
        if ('sub_category_id' in activeElement) {
          const id = activeElement.sub_category_id;
          if (id !== null) {
            if (category.length && category !== '') {
              let activeCategoryData = {
                id: id[0],
                slug: category[0].toLowerCase(),
                name: category[0],
              };
              if ('sub_category_meta' in activeElement) {
                activeCategoryData.meta = activeElement.sub_category_meta || [];
                activeCategoryData = this.getActiveCategoryData(activeCategoryData);
              }
              this.setActiveCategory(activeCategoryData);
              this.setActiveSubGroup(subgroup);
            } else {
              this.setActiveCategory({ id: id[0] });
            }
          }
        }
      });

      // pause temp audio and canvas when opening swap
      this.$root.$emit('pause-all-audio');
    },
    confirmSwap() {
      this.catchHistory('element');
      const { id } = this.getSwappedElement.data;
      const tempElement = cloneDeep(this.getSwappedElement);
      const swapTemp = document.body.querySelector('.swap-temp');

      if (swapTemp.querySelector('video')) {
        tempElement.size.width = swapTemp.querySelector('video').offsetWidth / this.getCanvasZoom;
        tempElement.size.height = swapTemp.querySelector('video').offsetHeight / this.getCanvasZoom;
      } else if (swapTemp.querySelector('img')) {
        // size and zoom ratio already computed in canvas-elements.js
        // tempElement.size.width = swapTemp.querySelector('img').clientWidth / this.getCanvasZoom;
        // tempElement.size.height = swapTemp.querySelector('img').clientHeight / this.getCanvasZoom;
      }

      const newElement = {
        element: tempElement,
        isSwap: true,
        id,
      };
      this.insertElementToCanvas(newElement);
      this.setIsSwapOpened(false);
      this.clearActiveKeyword();

      this.$root.$emit('remove-hovered');
      this.$root.$emit('canvasElementSizeUpdated');
      // console.log('this', this)
    },
    cancelSwap() {
      this.setIsSwapOpened(false);
      this.clearActiveKeyword();
    },
    groupElements() {
      if (!this.isMultiple || this.isGroupElements) return;

      const activeElements = [];
      const groupId = randomId();
      const groupNames = [];

      // active elements should be in order
      for (let i = 0; i < this.getCanvasElements.length; i += 1) {
        const element = cloneDeep(this.getCanvasElements[i]);

        if (element.data && element.data.id) {
          const elementId = element.data.id;

          if (this.getActiveElementsIds.includes(elementId)) {
            activeElements.push(element);
          }
        }
      }

      // if already have group name, use that name
      this.getCanvasElements.forEach((element) => {
        if (element.data.isGroup && element.data.groupName) {
          groupNames.push(element.data.groupName);
        }
      });

      // generate group name
      let i = 1;
      let groupName = '';

      // eslint-disable-next-line
      while (true) {
        groupName = `Group ${i}`;
        if (groupNames.includes(groupName)) {
          i += 1;
          // eslint-disable-next-line
          continue;
        }
        break;
      }

      // prepare elements to form group
      activeElements.forEach((element) => {
        /* eslint-disable */
        element.data.isGroup = true;
        element.data.groupId = groupId;
        element.data.groupName = groupName;
        element.data.groupLock = false;
        element.data.groupShow = true;
        element.data.groupFold = false;
        /* eslint-enable */
      });

      this.addElementsToGroup(activeElements);
    },
    toggleShowLiteModeControl() {
      this.showLiteModeControl = !this.showLiteModeControl;
    },
    hideShowLiteModeControl(e) {
      const isToggleButton = e?.path?.findIndex(
        path => path.className
            && typeof path.className === 'string'
            && path.className.indexOf('btn-toggle-lite-mode-control') > -1,
      ) > -1;
      if (!isToggleButton) {
        this.showLiteModeControl = false;
      }
    },

    toggleAnimationList(e) {
      // console.log('toggleAnimationList ~e', e, [...this.activeElementsIds])
      const position = e.target.getBoundingClientRect();
      this.setShowSidebar({ boolean: true, maintainAnimationTabState: true }); // avoid issue where opening animation tab when timeline is closed will not shrink timeline.
      this.setShowAnimationTab(!this.showAnimationTab);
      this.setActiveAnimationList({
        type: 'in',
        id: [...this.activeElementsIds],
        position,
        animationPosition: 'top',
      });
    },
    toggleEditTab() {
      // change sidebar nav to prevent conflicts with background colour tab
      if (this.getActiveGroup === groups.BACKGROUNDS) {
        if (this.isText) this.setActiveGroup(groups.TEXTS);
        else this.setActiveGroup(groups.ELEMENTS);
      }

      if (this.isText) this.toggleTextEditTab();
      else this.toggleMediaEditTab();
    },
    toggleMediaEditTab() {
      const showAdjustEditTabGroup = (this.isMedia || this.isPng) && !this.isBrandedGraphic;
      const showFilterEditTabGroup = !this.isWebm && this.isMedia && !this.isMask && !this.isBrandedGraphic;
      const showUpdateEditTabGroup = !this.isMedia && !this.isText && this.elementHasStroke;

      if (!showAdjustEditTabGroup || !showFilterEditTabGroup) {
        this.setActiveEditTabGroup(editTabs.EFFECTS);
      } else {
        this.setActiveEditTabGroup(editTabs.ADJUST);
      }

      if (showUpdateEditTabGroup) {
        this.setActiveEditTabGroup(editTabs.UPDATE);
      }

      this.setShowSidebar({ boolean: true, maintainEditTabState: true }); // avoid issue where opening edit tab when timeline is closed will not shrink timeline.
      this.setEditTabEffectsType('media');
      this.updateActiveColorId('effects');
      this.setShowEditTab(!this.showEditTab);
    },
    toggleTextEditTab() {
      this.setActiveEditTabGroup(editTabs.EFFECTS);
      this.setShowSidebar({ boolean: true, maintainEditTabState: true }); // avoid issue where opening edit tab when timeline is closed will not shrink timeline.
      this.setEditTabEffectsType('text');
      this.updateActiveColorId('effects');
      this.setShowEditTab(!this.showEditTab);
    },
    toggleMaskTab() {
      this.setShowSidebar({ boolean: true, maintainMaskTabState: true }); // avoid issue where opening animation tab when timeline is closed will not shrink timeline.
      this.setShowMaskTab(!this.showMaskTab);
    },

    // ----------------------
    // element stroke section
    // ----------------------
    updateStroke() {
      let strokeArray = [];

      if (this.getActiveElements.length && this.getActiveElements[0].data.stroke) {
        strokeArray = this.getActiveElements[0].data.stroke;
      }

      this.elementStroke = strokeArray;
    },
    onCanvasElementStrokeUpdated() {
      setTimeout(() => {
        this.updateStroke();
      }, 500);
    },
  },
  watch: {
    getActiveElements: {
      handler(val) {
        if (val.length === 0) {
          this.closeExtraLeftSidebar();
          this.showLiteModeControl = false;
        }

        this.updateStroke();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.setCanvasSectionPaddingTop(this.$refs.topControls.offsetHeight);
    // to allow double click on mask to swap
    this.$root.$on('swap-mask', () => {
      this.swapElement();
    });

    this.updateStroke();
    this.$root.$on('canvas-element-stroke-updated', this.onCanvasElementStrokeUpdated);
    this.$root.$on('control-container-replace-image-is-loading', (isLoading) => {
      this.isDoneButtonLoading = isLoading;
    });
  },
  beforeDestroy() {
    this.$root.$off('swap-mask');
    this.$root.$off('control-container-replace-image-is-loading');

    // need to off to avoid memory leak
    this.$root.$off('canvas-element-stroke-updated', this.onCanvasElementStrokeUpdated);
  },
};
</script>

<style lang="scss">
.top-controls {
  background: var(--lightgrey100-darkgrey600);
  cursor: context-menu;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  height: 55px;
  left: 0;
  padding: 10px 10px 10px 436px;
  position: absolute;
  right: 200px;
  top: 0;
  z-index: 8;

  .controls__wrapper {
    width: 100%;
    display: flex;
    align-content: center;
  }

  @include smallest {
    padding-left: 350px;
  }

  @include fb-requirement {
    right: 150px;
    padding-left: 328px;
  }

  .btn--hollow {
    border-color: $textBlack;
  }

  .btn {
    margin: 0 5px;
    line-height: 1;
  }

  .btn--text,
  .btn--icon {
    background: transparent;
    padding: 4px;
    margin: 0 2px;
    border-radius: 4px;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.25s ease-in-out;
    line-height: 1;
    font-size: 1.125rem;
    color: $darkGrey800;

    @include smallest {
      margin: 0 1px;
    }

    &:hover,
    &.is-active {
      background: $borderGrey;
      color: $darkGrey800;
    }

    &:disabled {
      opacity: 0.3;
      cursor: auto;
    }

    &.icon-replace-all-image {
      font-size: 1.25rem;
    }
  }

  &.is-dark {
    .btn--text,
    .btn--icon {
      color: $light;
      background: transparent;

      &:hover,
      &.is-active {
        background: $darkGrey5;
      }

      &.icon-replace-all-image {
        font-size: 1.1875rem;
      }
    }
  }

  .btn--text {
    font-size: 1.125rem;
  }

  .collapse-all &,
  .main-sidebar-is-hidden & {
    padding-left: 40px;
  }

  .btn-text {
    &:hover {
      background: $darkGrey;
    }

    &:disabled,
    &:disabled:hover {
      background: $lightBg;
      color: $lightBg;
      pointer-events: auto;
    }

    &:not([data-control='align']).is-active {
      background: $btnPrimaryBackground;
      color: #fff;

      &:hover {
        background: $btnPrimaryBackground;
      }
    }

    &[data-control='shadow'] {
      svg {
        width: 12px;
        position: static;
      }

      .shadow-color {
        fill: $darkGrey;
      }

      &.is-active {
        .shadow-color {
          fill: #fff;
        }
      }
    }

    &[data-control] {
      height: 24px;
    }
  }

  .control--edit-button,
  .btn-open-animate {
    border-color: $blue800;
    padding: 9px 8px;
    width: 95px;
    transition: border-color 0.15s ease-in-out;

    &.is-active {
      background: $blue800;
      color: $light;
    }

    &.is-dark {
      color: $light;
      border-color: $light;

      &.is-active,
      &:hover {
        border-color: $darkGrey5;
        background: $darkGrey5;

        .animation-icon {
          ellipse {
            fill: $green600;
          }
        }

        .icon-adjustments {
          color: $green600;
        }
      }
    }

    &:not(.is-dark) {
      .animation-icon {
        ellipse {
          fill: $blue800;
        }
      }

      &.is-active,
      &:hover {
        .animation-icon {
          ellipse {
            fill: $light;
          }
        }
      }
    }

    .btn__content span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .animation-icon {
      margin-right: 6px;
      transform: rotateY(180deg);
    }

    p {
      margin: 0;
    }
  }

  .control--edit-button {
    width: 75px;
    height: 32px;

    p {
      margin-left: 5px;
    }

    .icon-adjustments {
      font-size: 0.875rem;
    }
  }

  .control--mask-button {
    rect {
      stroke: var(--darkgrey-white);
    }

    path {
      fill: var(--darkgrey-white);
    }
  }

  .btn-selected-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;

    .icon {
      margin-left: 3px;
      transition: transform 0.25s ease-in-out;
    }

    &.is-active {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  .btn-swap-all,
  .btn-genie {
    margin-left: auto;
  }

  .btn-swap-all {
    .icon {
      margin-right: 8px;
    }

    & + .btn-genie {
      margin-left: 5px;
    }
  }

  .controls__item {
    height: 100%;
    align-items: center;
    display: inline-flex;
  }

  .btn[data-control='align'] {
    &[data-align='center'] {
      .icon::before {
        content: $icon-paragraph-center;
      }
    }
    &[data-align='right'] {
      .icon::before {
        content: $icon-paragraph-right;
      }
    }
  }

  .btn-toggle-lite-mode-control {
    min-width: 24px;
    padding: 4px;
  }

  .basic-controller {
    width: 130px;
    padding: 10px;
    font-weight: 400;
    transition: border-color 0.15s ease-in-out;

    &.is-dark {
      color: $light;
      border-color: $light;
    }

    &:hover,
    &.is-active {
      background: $lightBlue2;
      border-color: $lightBlue2;

      &.is-dark {
        background: $darkGrey5;
        border-color: $darkGrey5;
      }
    }
  }
}

.controls__content {
  display: flex;
  align-items: center;
}

.controls__group {
  align-items: center;
  display: inline-flex;
  height: 100%;
  margin: 0 10px;
  padding: 0 10px;
  vertical-align: middle;
  position: relative;

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    bottom: 4px;
    width: 1px;
    background: $darkGrey;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  &:first-child {
    margin-left: 0;
    padding-left: 0;

    &:before {
      display: none;
    }
  }

  &:last-child {
    margin-right: 0;
    padding-right: 0;

    &:after {
      display: none;
    }
  }

  & + .controls__group {
    margin-left: 0;
    padding-left: 0;

    &:before {
      display: none;
    }
  }
}

.controls__subgroup {
  display: inline-block;
  position: relative;
}

.controls__subgroup--fontfamily {
  width: 110px;
  margin: 0 10px;

  &::before {
    font-family: 'icomoon';
    position: absolute;
    right: 10px;
    top: 2px;
    font-size: 12px;
    transition: transform 0.25s ease-in-out;
    line-height: 25px;
    pointer-events: none;
  }

  .text-input {
    background: #fff;
    cursor: pointer;
    padding: 5px 30px 5px 10px;
    font-size: 0.75rem;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }

  .nice-select {
    background: #fff;
    z-index: 2;
    font-size: 0.75rem;
    height: 30px;
    line-height: 30px;
    border-radius: 0;
    padding-left: 10px;
    padding-right: 20px;

    &::before {
      right: 10px;
      top: 2px;
    }

    .option {
      min-height: 30px;
      line-height: 30px;
      padding-left: 10px;
      padding-right: 20px;
    }

    .list {
      background: #fff;
      max-height: 200px;
      left: 0;
      box-shadow: none;
      border-radius: 0;
      transform: translateY(-10px);
    }

    &.open {
      &::before {
        transform: rotate(-180deg);
      }

      .list {
        transform: translateY(0);
      }

      & ~ .selectize-control {
        .selectize-dropdown::after {
          content: '';
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(240, 240, 240, 0.8);
        }
      }
    }
  }

  .selectize-control.single .selectize-input,
  .nice-select {
    &:after {
      display: none;
    }

    &::before {
      font-family: 'icomoon';
      position: absolute;
      line-height: 30px;
      transition: transform 0.25s ease-in-out;
    }
  }

  .selectize-control.single .selectize-input {
    background: $lightBg;
    border-radius: 0;
    box-shadow: none;
    cursor: text;

    input {
      cursor: text;
    }

    &::before {
      background: transparent;
      height: 30px;
      left: auto;
      right: 3px;
      width: 17px;
      top: 0;
    }
  }

  .selectize-control {
    width: 100%;
    padding-top: 30px;
    background: $darkGrey;
  }

  .selectize-dropdown-content {
    position: relative;
  }

  .nice-select,
  .selectize-control {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transform: translate(0, -10px);
    pointer-events: none;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  }

  .nice-select,
  .selectize-control,
  .selectize-dropdown {
    min-width: 200px;
  }

  .selectize-dropdown {
    display: block !important;
    top: 64px !important;
    box-shadow: none;
    border-radius: 0;
  }

  .selectize-dropdown .option {
    padding: 2px 8px;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;

    &:hover,
    &.focus,
    &.selected,
    &.selected:hover,
    &.selected.focus {
      background: #fff;
      color: $darkGrey;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $darkGrey;
      opacity: 0;
      pointer-events: none;
    }

    &.active,
    &.selected {
      background: transparent;
    }

    &:hover:after,
    &.active:after {
      opacity: 0.1;
    }

    &.selected:after {
      opacity: 0.3;
    }
  }

  // .selectize-dropdown .option.active,
  &.is-visible {
    .nice-select,
    .selectize-control {
      visibility: visible !important;
      opacity: 1;
      transform: translate(0, 0);
      pointer-events: auto !important;
    }

    &::before {
      transform: translateY(-2px) rotate(-180deg);
    }
  }
}
</style>
