<template lang="pug">
BaseHeader(
  :is-canvas="true"
)
  template(v-if="isDevMode")
    .dev-options
      select(v-model="userLevel")
        option(value="ADMIN" v-if="!getIsTemplate") Admin
        option(value="MASTERDESIGNER" v-if="getIsTemplate") Master Designer
        option(value="DESIGNER" v-if="getIsTemplate") Designer
        option(value="PAYINGUSER" v-if="!getIsTemplate") Paid User
        option(value="FREEUSER" v-if="!getIsTemplate") Free User

      select(v-if="isDesigner" v-model="modularTemplate")
        option(value="template") Not Modular Template
        option(value="modular") Modular Template

      select(v-if="!isDesigner" v-model="permutationTemplate")
        option(:value="false") Not Permutation Template
        option(:value="true") Permutation Template

  ProjectDetails.no-mobile

  BaseButton.icon-share1.btn-share-project(
    v-if="!isLiteMode && !getIsTemplate"
    title="Share Template"
    :is-light="true"
    :is-icon="true"
    :plain-hover="true"
    padding="8px"
    @click="openProjectShareProject"
  )

  BaseButton.btn-import-export-project-settings.no-mobile(
    v-if="isAdmin || isMasterDesigner"
    :is-canvas="true"
    :icon-after="showImportExportProjectSettings ? 'icon-up-arrow ' : 'icon-down-arrow'"
    @click="openImportExportProjectSettings"
  ) Project settings

  //- Hide Unlock all features button
    BaseButton.btn-unlock-features(
      v-if="isLiteMode"
      :is-canvas="true"
      :is-primary="true"
      :is-full-width="true"
      :is-loading="isSubscriptionShowLoading"
      @click="setSubscriptionModal"
    )
      span Unlock All Features
      i.icon-premium

  .download__container.no-mobile(
    v-if="!getIsTemplate"
    v-click-outside="onClickOutsideDownload"
  )
    BaseButton.btn-download-project(
      :class="{ 'is-active': showDownloadOpts }"
      :is-canvas="true"
      :is-light="true"
      :is-hollow="true"
      padding="10px 23px"
      @click="openProjectPreview('video')"
    ) Download

  .create__container(
    v-if="getIsTemplate"
    v-click-outside="onClickOutsideCreate"
  )
    BaseButton.btn-create-preview(
      :class="{ 'is-active': showCreateOpts }"
      :is-canvas="true"
      :is-light="true"
      :is-hollow="true"
      :icon-after="createOptsIcon"
      @click="toggleIsShowCreateOpts"
    ) Create Preview
    .create__list(
      v-if="showCreateOpts"
    )
      .create__button.js-create(
        @click="openCreateVideoDialog"
      ) Video
      .create__button.js-create(
        @click="openCreateImageDialog"
      ) Image

  CreateVideoDialog(
    v-if="(getIsTemplate && showCreateVideoDialog)"
    @confirm="createVideoPreview"
    @close="closeCreateVideoDialog"
  )

  CreateImageDialog(
    v-if="(getIsTemplate && showCreateImageDialog)"
    @confirmCurrentScene="createImagePreview(false)"
    @confirmAllScenes="createImagePreview(true)"
    @close="closeCreateImageDialog"
  )

  template(v-if="showHeroReplaceConfirmation")
    BaseDialog.header__overwrite-hero-thumbnail-modal(
      isShow=true
      text="This template has hero thumbnails, would you like to overwrite it?"
      noButton=true
      hideCloseButton=true
      width="700px"
    )
      .dialog-modal__buttons
        BaseButton(
          :is-primary="true"
          :is-tall="true"
          :is-canvas="true"
          @click="overWriteHeroThumbnails(true)"
        ) Overwrite

        BaseButton.retain-button(
          :is-hollow="true"
          :is-tall="true"
          :is-canvas="true"
          @click="overWriteHeroThumbnails(false)"
        ) Retain

  //- Subscribe(
  //-   v-if="!isPayingUser && showUpgradeSubscription"
  //-   :showUpgradeNextTimeButton="showUpgradeNextTimeButton"
  //-   @close-subscribe="closeSubscribeDialog"
  //- )

  //- template(
  //-   v-if="isLiteMode"
  //- )
  //-   BaseButton.btn-upload-project(
  //-     padding="10px"
  //-     @click="onClickUploadProject"
  //-   )
  //-     img(src="@/assets/images/logos/facebook.png")
  //-     span Upload to media library
  //-   .tooltip-upload-project(
  //-     v-if="showTooltipUploadProject"
  //-     v-click-outside="onClickOutsideUploadProject"
  //-   )
  //-     p Publish your designs straight to Facebook Media Library!
  //-     div.cancelled-login(v-if="hasCancelledLoggingInFbBusiness")
  //-       | You have not given enough permissions to publish. Please try connecting your account again.
  //-     BasePreloader(
  //-       v-if="isLoggingInFbBusiness"
  //-       :type=2
  //-     )
  //-     template(v-if="fbBusinessAccessToken === '' && !isLoggingInFbBusiness")
  //-       BaseButton.btn-connect-fb(
  //-         :is-primary="true"
  //-         :is-tall="true"
  //-         @click="fbBusinessLogin"
  //-       ) Connect Facebook
  //-     template(v-else-if="fbBusinessAccessToken !== '' && !isLoggingInFbBusiness")
  //-       .fbe__accounts
  //-         .fbe__account.btn-connect-fb(
  //-           @click="fbBusinessLogin"
  //-         )
  //-           .fbe__initials.icon-plus
  //-           .fbe__name Choose another business
  //-         .fbe__accounts__list(v-for="(account) in fbBusinessAccounts")
  //-           .fbe__account(
  //-             @click="fbBusinessSelect(account)"
  //-           )
  //-             .fbe__initials {{ account.name[0] }}
  //-             .fbe__name {{ account.name }}

  GlobalEvents(
    v-if="!isLiteMode"
    @keydown.ctrl.shift.p.exact.prevent="openProjectPreview"
    @keydown.meta.shift.p.exact.prevent="openProjectPreviewMac"
  )

  GlobalEvents(
    @keydown.esc.exact.prevent="escapePressed"
  )

  ShareProjectContainer(
    v-if="!isLiteMode && showShareProject"
    v-click-outside="shareProjectClickOutside"
  )

  ImportExportProjectSettingsContainer(
    v-if="showImportExportProjectSettings"
    @close="showImportExportProjectSettings = false"
    v-click-outside="importExportProjectSettingsClickOutside"
  )

</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import vClickOutside from 'v-click-outside';
import ProjectDetails from '@/components/ProjectCanvas/ProjectDetails/ProjectDetails.vue';

import CreateImageDialog from '@/components/ProjectCanvas/CanvasHeader/children/CreateImageDialog.vue';
import CreateVideoDialog from '@/components/ProjectCanvas/CanvasHeader/children/CreateVideoDialog.vue';
import ShareProjectContainer from './children/ShareProjectContainer.vue';
import ImportExportProjectSettingsContainer from './children/ImportExportProjectSettingsContainer.vue';
import BasePreloader from '@/components/UI/BasePreloader.vue';

import Subscribe from '@/components/ProjectCanvas/PageOverlay/Subscribe.vue';

import { isDevMode, isStaging } from '@/assets/scripts/variables';
import { isMac, upsert } from '@/assets/scripts/utilities';
import { groups } from '@/assets/scripts/enums';

import renderMixin from '@/components/mixins/render-mixins';

import Api from '@/services/api/Api';

export default {
  name: '',
  mixins: [renderMixin],
  components: {
    ProjectDetails,
    CreateImageDialog,
    CreateVideoDialog,
    Subscribe,
    ShareProjectContainer,
    ImportExportProjectSettingsContainer,
    BasePreloader,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      downloadOptsIcon: 'icon-down-arrow',
      showDownloadOpts: false,
      createOptsIcon: 'icon-down-arrow',
      showCreateOpts: false,
      showCreateImageDialog: false,
      showCreateVideoDialog: false,
      isDevMode: isDevMode(),
      isStaging: isStaging(),
      downloadAfterSubscribe: false,
      showHeroReplaceConfirmation: false,
      showShareProject: false,
      showImportExportProjectSettings: false,
      showUpgradeNextTimeButton: true,
      showTooltipUploadProject: false,
      isLoggingInFbBusiness: false,
      hasCancelledLoggingInFbBusiness: false,
      fbBusinessAccessToken: '',
      fbBusinessAccounts: [],
      selectedFBBusinessAccount: 0,
      isSubscriptionShowLoading: false,
    };
  },
  computed: {
    ...mapState('userData', ['userId', 'username']),
    ...mapState('canvasElements', ['scenesList']),
    ...mapGetters(['getIsTemplate', 'isLiteMode']),
    ...mapGetters('userData', [
      'isFreeUser',
      'isAdmin',
      'isMasterDesigner',
      'isDesigner',
      'isPayingUser',
    ]),
    ...mapGetters('assetsSidebar', ['getActiveGroup']),
    ...mapGetters('canvasElements', [
      'getProjectDetails',
      'getProjectPreviews',
      'getActiveSceneId',
      'getProjectId',
    ]),
    userLevel: {
      get() {
        let level = 'PAYINGUSER';

        if (this.isDesigner) {
          if (this.isMasterDesigner) level = 'MASTERDESIGNER';
          else level = 'DESIGNER';
        } else if (this.isFreeUser) {
          level = 'FREEUSER';
        } else if (this.isAdmin) {
          level = 'ADMIN';
        } else if (this.isPayingUser) {
          level = 'PAYINGUSER';
        }

        return level;
      },
      set(value) {
        let level = 'premium';
        let role = null;

        if (value === 'ADMIN') role = 1;
        if (value === 'MASTERDESIGNER') role = 2;
        if (value === 'DESIGNER') role = 3;
        if (value === 'PAYINGUSER') role = 4;
        if (value === 'FREEUSER') {
          role = 4;
          level = 'free';
        }

        if (level) this.setUserLevel(level);
        if (role) this.setUserRole(role);
      },
    },
    modularTemplate: {
      get() {
        let template = 'template';

        if (this.getProjectDetails.isModular) {
          template = 'modular';
        }

        return template;
      },
      set(value) {
        let isModular = true;

        if (value === 'template') isModular = false;

        this.setModular(isModular);
      },
    },
    permutationTemplate: {
      get() {
        return !!this.getProjectDetails.isPermutation;
      },
      set(value) {
        this.setPermutation(value);
      },
    },
    hasHeroPreviews() {
      if (!this.getProjectPreviews) return false;

      if (typeof this.getProjectPreviews.images !== 'undefined') {
        return (
          typeof this.getProjectPreviews.images.hero_w_bg !== 'undefined'
          || typeof this.getProjectPreviews.images.hero_wo_bg !== 'undefined'
        );
      }

      return false;
    },
    fbBusinessExternalBusinessId() {
      return `${this.username}-${this.userId}`;
    },
  },
  async beforeMount() {
    await this.initFacebookSDK();
  },
  methods: {
    ...mapMutations([
      'setIsDownloadPreviewOpened',
      'setDownloadPreviewOption',
      'setDownloadPreviewType',
      'setShowUpgradeSubscription',
      'setIsTemplatePreviewOpened',
      'setFBBusinessAccountForPublish',
      'setShowSubscriptionModal',
      'setShowTimeline',
      'setShowSidebar',
    ]),
    ...mapMutations('assetsSidebar', ['setActiveGroup']),
    ...mapMutations('userData', ['setUserLevel', 'setUserRole']),
    ...mapMutations('canvasElements', ['setModular', 'setPermutation']),
    ...mapActions('canvasElements', ['saveProject']),
    openProjectPreview(downloadOption = 'video', type = 'preview') {
      this.onClickOutsideDownload();

      this.setDownloadPreviewOption(downloadOption);
      this.setDownloadPreviewType(type);
      this.setIsDownloadPreviewOpened(true);
      this.saveProject();

      this.showUpgradeNextTimeButton = true;
      // pause temp audio and canvas when opening project preview
      this.$root.$emit('pause-all-audio');
    },
    openProjectPreviewMac() {
      if (!isMac()) return;
      this.openProjectPreview();
    },
    onClickOutsideDownload() {
      this.showDownloadOpts = false;
      this.downloadOptsIcon = 'icon-down-arrow';
    },
    toggleIsShowCreateOpts() {
      this.showCreateOpts = !this.showCreateOpts;
      this.createOptsIcon = this.createOptsIcon === 'icon-down-arrow' ? 'icon-up-arrow' : 'icon-down-arrow';
    },
    onClickOutsideCreate() {
      this.showCreateOpts = false;
      this.createOptsIcon = 'icon-down-arrow';
    },
    openCreateVideoDialog() {
      this.showCreateVideoDialog = true;
      this.saveProject();
      this.toggleIsShowCreateOpts();
    },
    closeCreateVideoDialog() {
      this.showCreateVideoDialog = false;
    },
    openCreateImageDialog() {
      this.showCreateImageDialog = true;
      this.saveProject();
      this.toggleIsShowCreateOpts();
    },
    closeCreateImageDialog() {
      this.showCreateImageDialog = false;
    },
    createVideoPreview() {
      // TODO: Create preview video
      this.processRender('mp4');
      this.showCreateVideoDialog = false;
    },
    createImagePreview(forAllScenes) {
      // console.log('createImagePreview', this.getActiveSceneId, this.getProjectPreviews, this.getProjectDetails, JSON.parse(JSON.stringify(this.scenesList.allIds)));

      this.closeCreateImageDialog();

      if (forAllScenes) {
        if (this.hasHeroPreviews) {
          // has hero scene previews
          this.showHeroReplaceConfirmation = true;
        } else {
          const sceneLists = JSON.parse(JSON.stringify(this.scenesList.allIds));
          const sceneId = forAllScenes ? sceneLists.join(',') : this.getActiveSceneId;
          this.downloadImage('jpg', sceneId);
        }
      } else {
        this.setIsTemplatePreviewOpened(true);
      }
    },
    overWriteHeroThumbnails(createHeroThumbnails = true) {
      this.showHeroReplaceConfirmation = false;

      const sceneLists = JSON.parse(JSON.stringify(this.scenesList.allIds));
      const sceneId = sceneLists.join(',');

      this.downloadImage('jpg', sceneId, createHeroThumbnails);
    },
    closeSubscribeDialog() {
      this.setShowUpgradeSubscription(false);
      this.downloadAfterSubscribe = false;
    },
    openProjectShareProject() {
      this.showUpgradeNextTimeButton = false;
      if (this.showShareProject) {
        this.showShareProject = false;
        return;
      }

      this.showShareProject = true;
      // this.saveProject();
      this.$root.$emit('pause-all-audio');
    },
    openImportExportProjectSettings() {
      this.showImportExportProjectSettings = !this.showImportExportProjectSettings;
    },
    shareProjectClickOutside() {
      this.showShareProject = false;
    },
    importExportProjectSettingsClickOutside() {
      this.showImportExportProjectSettings = false;
    },
    escapePressed() {
      this.showShareProject = false;
      this.showImportExportProjectSettings = false;
      this.showTooltipUploadProject = false;
      if (this.showDownloadOpts) this.toggleIsShowDownloadOpts();
    },
    onClickUploadProject() {
      // if not yet connected show the tooltip
      if (!this.showTooltipUploadProject) {
        this.showTooltipUploadProject = true;
      }
      // TODO: if already connected
    },
    onClickOutsideUploadProject(e) {
      const isButtonToIgnoreClosing = e?.path?.findIndex(
        path => path.className
            && typeof path.className === 'string'
            && (path.className.includes('btn-upload-project')
              || path.className.includes('btn-connect-fb')),
      ) > -1;

      if (!isButtonToIgnoreClosing) {
        this.showTooltipUploadProject = false;
      }
    },
    initFacebookSDK() {
      return new Promise((resolve) => {
        // wait for facebook sdk to initialize before starting the vue app
        window.fbAsyncInit = () => {
          // eslint-disable-next-line
          FB.init({
            appId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
            cookie: true,
            xfbml: true,
            version: process.env.VUE_APP_FACEBOOK_CLIENT_VERSION,
          });

          // eslint-disable-next-line
          FB.getLoginStatus(({ authResponse }) => {
            if (authResponse) {
              this.fbBusinessAccessToken = authResponse.accessToken;
            }

            resolve();
          });
        };
        // load facebook sdk script
        // eslint-disable-next-line
        (function (d, s, id) {
          let js;
          const fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          // eslint-disable-next-line
          js = d.createElement(s);
          js.id = id;
          js.src = 'https://connect.facebook.net/en_US/sdk.js';
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      });
    },
    fbBusinessLogin() {
      this.isLoggingInFbBusiness = true;
      this.hasCancelledLoggingInFbBusiness = false;
      // eslint-disable-next-line
      FB.login(
        (response) => {
          if (response.authResponse) {
            const { accessToken } = response.authResponse;
            if (accessToken === this.fbBusinessAccessToken) {
              this.isLoggingInFbBusiness = false;
            } else {
              this.fbBusinessAccessToken = accessToken;
              this.hasCancelledLoggingInFbBusiness = false;
            }
          } else {
            this.isLoggingInFbBusiness = false;
            this.hasCancelledLoggingInFbBusiness = true;
          }
        },
        {
          scope: 'public_profile,business_creative_management,manage_business_extension',
          extras: {
            setup: {
              external_business_id: this.fbBusinessExternalBusinessId,
              timezone: 'America/Los_Angeles',
              currency: 'USD',
              business_vertical: 'CREATIVE',
              channel: 'CREATIVE',
            },
            business_config: {
              business: {
                name: 'New Business',
              },
            },
            repeat: false,
          },
        },
      );
    },
    fbBusinessSelect(account) {
      this.showTooltipUploadProject = false;
      this.setFBBusinessAccountForPublish(account);
      this.openProjectPreview('video', 'publish');
    },
    setSubscriptionModal() {
      this.isSubscriptionShowLoading = true;
      this.setShowSubscriptionModal(true);
      setTimeout(() => {
        this.isSubscriptionShowLoading = false;
      }, 5000);
    },
    setupLiteMode() {
      this.setShowTimeline(false);
      this.setShowSidebar(true);

      if (
        this.getActiveGroup !== groups.TEXTS
        && this.getActiveGroup !== groups.MUSIC
        && this.getActiveGroup !== groups.MEDIA
      ) {
        this.setActiveGroup(groups.TEXTS);
      }
    },
  },
  watch: {
    isPayingUser() {
      if (this.isPayingUser && this.downloadAfterSubscribe) {
        this.openProjectPreview();
      }
    },
    async fbBusinessAccessToken() {
      this.isLoggingInFbBusiness = true;
      const fbeBusinessDataResponse = await Api.fbeBusiness({
        fbeebid: this.fbBusinessExternalBusinessId,
        at: this.fbBusinessAccessToken,
      });
      const fbeBusinessData = fbeBusinessDataResponse.data.results;
      for (let i = 0; i < fbeBusinessData.length; i += 1) {
        this.fbBusinessAccounts = upsert(this.fbBusinessAccounts, fbeBusinessData[i]);
      }
      this.isLoggingInFbBusiness = false;
    },
  },
};
</script>

<style lang="scss">
.canvas-header {
  select {
    margin-left: 20px;
    color: $textGrey2;
    background: $darkGrey2;
    font-weight: 500;
    border: 1px solid $darkGreyBorder;

    &:focus {
      background: $btnHollowDarkFocus;
    }
    &:hover {
      background: $btnHollowDarkHover;
    }
  }

  .left {
    display: flex;
  }

  .zoom__option {
    vertical-align: middle;
  }

  .btn-download-project {
    font-size: 14px;
    margin: 0 5px;
    color: $light;

    &:hover,
    &.is-active,
    &:active {
      background: $green600;
      border-color: $green600;
    }
  }

  .btn-unlock-features {
    max-width: 170px;
    margin: 0 10px;

    &:hover {
      .btn__circle {
        width: 210px;
      }
    }

    .icon-premium {
      position: absolute;
      right: -23px;
      top: -9px;
      color: $orange600;
    }

    &:focus {
      color: $light;
      background: $blue700;
    }
  }

  .btn-create-preview {
    margin-left: auto;
    min-width: 165px;

    &:hover {
      background: $lightGrey400;
    }

    &.is-active {
      background: $lightGrey400;
      color: $darkGrey !important;
    }
  }

  .lite-mode & .btn-download-project {
    min-width: 40px;
    padding: 6px 8px;

    .icon {
      font-size: 1rem;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .create__container {
    width: 165px;
    position: relative;

    .btn-create-preview {
      position: relative;
      color: $light;
      border: 1px solid $light;

      &:hover,
      &:active {
        color: $black;
      }

      .icon--after {
        &.icon-down-arrow {
          font-size: 1.2em;
        }
      }
    }

    .create__list {
      position: absolute;
      width: 100%;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.1), 0px 26px 26px rgba(10, 31, 68, 0.12);
      padding: 10px;
      font-size: 0.875em;
      z-index: 3;

      .download__button,
      .create__button {
        border: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 15px;
        text-align: left;
        background: transparent;
        cursor: pointer;
        opacity: 1;
        position: relative;
        z-index: 2;
        text-transform: capitalize;
        // border-bottom: 1px solid $borderGrey;
        font-size: 1em;
        font-weight: 500;
        color: $darkGrey400;

        &:hover {
          background: $lightGrey200;
        }

        &:last-of-type {
          border-bottom: 0;
        }

        &.has-icon {
          justify-content: space-between;

          .icon {
            color: $yellow;
          }
        }
      }
    }
  }

  .dialog-modal {
    .dialog-modal__closebtn {
      color: var(--darkgrey-white);
    }
  }

  .btn-upload-project {
    margin-left: 10px;
    min-width: 220px;
    background: $darkGrey600;

    .btn__content span {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include fb-requirement {
      padding-left: 5px;
      padding-right: 5px;
      min-width: 186px;
    }

    img {
      margin-right: 10px;
    }
  }

  .tooltip-upload-project {
    position: absolute;
    background: $lightBg;
    border: 1px solid $defaultBorder;
    right: 0;
    top: 55px;
    width: 450px;
    text-align: center;
    color: $darkGrey500;
    padding: 20px 30px;
    min-width: 250px;
    box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
    border-radius: $componentBorderRadius;

    p {
      font-size: 1.25rem;
      line-height: 1.45;
      margin-top: 0;
    }

    .cancelled-login {
      font-size: 0.875rem;
      line-height: 1.45;
      margin-bottom: 20px;
      background-color: $red300;
      border-radius: $componentBorderRadius;
      padding: 10px;
    }

    .fbe__accounts {
      .fbe__account {
        display: flex;
        align-items: center;
        padding: 10px 30px;
        margin-left: -30px;
        margin-right: -30px;
        cursor: pointer;

        &:hover {
          background: $timelineHover;
        }
      }

      .fbe__initials {
        background: #1877f2;
        color: $light;
        font-size: 1.5rem;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        &.icon-plus {
          background: transparent;
          color: initial;
          border: 1px dashed #1877f2;
        }
      }
    }
  }

  .header__overwrite-hero-thumbnail-modal {
    .retain-button {
      color: var(--darkgrey-white);

      &:hover {
        color: $darkGrey;
      }
    }
  }
}

.btn-upgrade-subscription {
  width: 250px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 15px;

  .icon {
    color: $yellow;
  }
}

.btn-share-project {
  font-size: 1.375rem !important;
  margin: 0 10px;

  &:hover {
    color: $green600 !important;
  }
}

.btn-import-export-project-settings {
  width: 230px;
  margin-right: 10px;
  color: $light;
  background: $darkGrey600;

  &:hover,
  &.is-active,
  &:active {
    color: $black;
  }

  &:focus {
    color: $black;
    background: $light;
  }
}

.dev-options {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: auto;
  max-width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include fb-requirement {
    display: none;
  }
}
</style>
