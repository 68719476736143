<template lang="pug">
.animation-tab(
  v-click-outside="closeAnimationTab"
)
  AnimationTabNav(
    :animation-type-list="animationType"
  )

  div.animation-option__carousel(
    v-if="showAnimationOptions"
    :class="{'animation-carousel--disabled': isAnimationCarouselDisabled}"
    @click="onClickAnimationOptionCarousel"
  )
    button.carousel-icon.icon-left-arrow(
      type="button"
      :class="{'carousel-button--disabled': !hasCarouselClickedNextOnce}"
      @click="moveCarousel('prev')"
    )
    VueSlickCarousel.carousel__list(
      v-if="animationOption.length"
      ref="animationCarousel"
      v-bind="slickOptions"
      key="animation-carousel"
    )
      button.carousel__list-item(
        v-for='(option, index) in animationOption'
        :key="option.value"
        type='button',
        :class='{ "is-active": activeAnimationOption === option.value }',
        v-html='option.name',
        @click="moveCarousel('custom', index)"
      )
    button.carousel-icon.icon-right-arrow(
      type="button"
      :class="{'carousel-button--disabled': hasCarouselClickedNextOnce}"
      @click="moveCarousel('next')"
    )

  ul.animation-tab__list(:class="{'has-margin-top': !showAnimationOptions}")
    li.animation-tab__item-container(
      v-for='(transition, index) in animationListByActiveType'
      :key="transition.value"
      @click="onClickAnimationItemContainer(transition.value)"
    )
      AnimationTabItem(
        :color-class="animationTypeColorClass"
        :is-transition='isTransition',
        :transition='transition',
        :selected-transition='selectedTransition',
        :active-tab='activeAnimationOption',
        @change-animation='changeAnimation',
      )

  AnimationTabDirection(
    v-if="hasTransition && directionList.length"
    :color-class="animationTypeColorClass"
    :direction-list='directionList',
    :selected-transition='selectedTransition',
    @change-direction='changeDirection'
  )
  AnimationTabSpeed(
    v-if="showAnimationSpeed"
    :class="{'animation-tab--extra-margin-top': !directionList.length}"
    :selected-transition='selectedTransition',
    :active-tab='getActiveAnimationList.type',
  )
  AnimationTabDuration(
    v-if="showAnimationDuration"
    :class="{'animation-tab--extra-margin-top': !directionList.length}"
    :selected-transition='selectedTransition',
    :active-tab='getActiveAnimationList.type',
  )
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';

import AnimationTabNav from '../AnimationList/children/AnimationTabNav.vue';
import AnimationTabDirection from '../AnimationList/children/AnimationTabDirection.vue';
import AnimationTabSpeed from '../AnimationList/children/AnimationTabSpeed.vue';
import AnimationTabDuration from '../AnimationList/children/AnimationTabDuration.vue';
import AnimationTabItem from '../AnimationList/children/AnimationTabItem.vue';

import helperMixin from '@/components/mixins/helper-mixins';
import slickMixin from '@/components/mixins/slick-mixins';
import {
  isEmpty,
  disallowSplitText,
  checkIsCollapseSidebarButton,
  isUnsupportedEffectExist,
} from '@/assets/scripts/utilities';

export default {
  name: 'AnimationTab',
  components: {
    AnimationTabNav,
    AnimationTabDirection,
    AnimationTabSpeed,
    AnimationTabDuration,
    AnimationTabItem,
  },
  mixins: [helperMixin, slickMixin],
  data() {
    return {
      directionList: [],
      activeAnimationOptionIndex: 0,
      hasCarouselClickedNextOnce: false, // an indicator whether carousel still show the first 3 options or already show option number 2 to 4 - if true next button should be disabled, if false prev button should be disabled
      activeAnimationOption: 'default',
      showByLetterOption: true,

      animationOption: [],
      animationType: [
        {
          type: 'in',
          name: 'Entrance',
          list: [],
          class: 'is-green',
        },
        {
          type: 'during',
          name: 'Emphasis',
          list: [],
          class: 'is-orange',
        },
        {
          type: 'out',
          name: 'Exit',
          list: [],
          class: 'is-red',
        },
      ],
    };
  },
  computed: {
    ...mapState(['animationList', 'transitionList', 'showTimeline']),
    ...mapState('canvasElements', ['activeElementsIds', 'scenesList']),
    ...mapGetters(['isLiteMode']),
    ...mapGetters(['getActiveAnimationList', 'getAnimationListDirection']),
    ...mapGetters('canvasElements', ['getActiveElements', 'getCanvasElementById']),
    activeElement() {
      return this.getActiveElements[0];
    },
    showAnimationOptions() {
      return (
        !this.isLiteMode
        && this.isText
        && this.getActiveAnimationList.type !== 'during'
        && !this.isTransition
      );
    },
    slickOptions() {
      return {
        arrows: false,
        dots: false,
        draggable: false,
        focusOnSelect: false,
        infinite: false,
        speed: 500,
        centerMode: !this.isMultipleAnimationOption,
        slidesToShow: 3,
        slidesToScroll: 1,
      };
    },
    animationTypeColorClass() {
      let colorClass;

      if (this.getActiveAnimationList.type === 'in') {
        colorClass = 'is-green';
      } else if (this.getActiveAnimationList.type === 'during') {
        colorClass = 'is-orange';
      } else if (this.getActiveAnimationList.type === 'out') {
        colorClass = 'is-red';
      }

      return colorClass;
    },
    isMultipleAnimationOption() {
      return this.animationOption.length > 1;
    },
    animationListByActiveType() {
      let activeList = [];

      if (this.getActiveAnimationList.type === 'transition') {
        activeList = this.transitionList[0].list;
      } else {
        const activeType = this.animationType.find(
          type => type.type === this.getActiveAnimationList.type,
        );

        activeList = activeType.list;

        if (this.hasNonTextElements) {
          activeList = activeType.list.filter(type => !type.onlyText);
        }

        // if (this.isTextGradient) {
        //   activeList = activeList.filter(type => !type.noGradientText);
        // }

        if (this.activeAnimationOption === 'per-text') {
          activeList = activeList.filter(type => !type.noByLetter);
        } else {
          activeList = activeList.filter(type => !type.onlyByLetter);
        }
      }

      return activeList;
    },
    hasNonTextElements() {
      const hasNonText = this.getActiveElements.findIndex(el => el.type !== 'texts') >= 0;

      return hasNonText;
    },
    selectedTransition() {
      const { id } = this.getActiveAnimationList;
      let value = '';
      let direction = '';
      let option = 'default';
      let duration = 1;
      const animateType = this.getActiveAnimationList.type;

      if (!id) return { value, direction, duration };
      if (animateType === 'transition') {
        value = this.scenesList.byId[id].transition
          ? this.scenesList.byId[id[0]].transition.value
          : '';
        direction = this.scenesList.byId[id].transition
          ? this.scenesList.byId[id[0]].transition.direction
          : '';
      } else {
        // - if there is more than 1 active elements
        // - and they have different animation
        // - set selectedTransition as 'multiple'

        let tempValue = '';
        let tempDirection = '';
        let tempOption = '';
        let tempDuration = '';

        // eslint-disable-next-line
        for (let i = 0; i < id.length; i++) {
          const elementId = id[i];

          const timelineSettings = this.getCanvasElementById(elementId).timeline_settings;

          if (animateType === 'in') {
            value = timelineSettings.animateInValue ? timelineSettings.animateInValue : 'none';
            direction = timelineSettings.animateInDirection;
            option = timelineSettings.animateInOption;
            duration = timelineSettings.animateInDuration;
          } else if (animateType === 'during') {
            value = timelineSettings.animateDuringValue
              ? timelineSettings.animateDuringValue
              : 'none';
            direction = timelineSettings.animateDuringDirection;
            duration = timelineSettings.animateDuringSpeed;
          } else if (animateType === 'out') {
            value = timelineSettings.animateOutValue ? timelineSettings.animateOutValue : 'none';
            direction = timelineSettings.animateOutDirection;
            option = timelineSettings.animateOutOption;
            duration = timelineSettings.animateOutDuration;
          }

          // when it's first loop set the tempvalue and direction
          // if it's not first loop, value and direction is different
          // exit the loop and set as multiple
          // console.table({
          //   tempValue,
          //   value,
          //   tempDirection,
          //   direction,
          //   tempOption,
          //   option,
          //   tempDuration,
          //   duration
          // })
          // need to separate this because duration shouldn't affect the showing of multiple direction
          if (i !== 0 && tempDuration !== duration) {
            duration = 1;
          } else {
            tempDuration = duration;
          }

          if (
            i !== 0
            && !(tempValue === value && tempDirection === direction && tempOption === option)
          ) {
            value = 'multiple';
            direction = '';
            option = 'default';
            break;
          } else {
            tempValue = value;
            tempDirection = direction;
            tempOption = option;
          }
        }
      }

      return { value, direction, duration };
    },
    isTransition() {
      return this.getActiveAnimationList.type === 'transition';
    },
    hasTransition() {
      return this.selectedTransition && this.selectedTransition.value !== 'none';
    },
    showAnimationSpeed() {
      return (
        !this.isTransition
        && this.hasTransition
        && this.getActiveAnimationList.type === 'during'
      );
    },
    showAnimationDuration() {
      return (
        !this.isTransition
        && this.hasTransition
        && this.getActiveAnimationList.type !== 'during'
      );
    },

    isText() {
      // eslint-disable-next-line
      for (let i = 0; i < this.getActiveElements.length; i++) {
        if (this.getActiveElements[i].type !== 'texts') {
          return false;
        }
      }
      return true;
    },
    isAnimationCarouselDisabled() {
      const unsupportedEffectExist = isUnsupportedEffectExist(this.activeElement.data);
      return unsupportedEffectExist;
    },
  },
  watch: {
    getActiveAnimationList: {
      handler() {
        this.directionList = this.getAnimationListDirection(
          this.getActiveAnimationList.type,
          this.selectedTransition.value,
        );

        this.setupAnimationOption();
        this.setupActiveAnimationTab();
      },
      deep: true,
      immediate: true,
    },
    'getActiveAnimationList.type': {
      handler() {
        // this watcher is for user behaviour of moving the animation type from 'entrance' to 'exit'
        // recapture placement of vue-slick-carousel according to activeAnimationOption
        setTimeout(() => {
          // used timeout to ensure "this.$refs.animationCarousel" exist then do the setup
          this.setupCarouselSlide();
        }, 50);
      },
      deep: true,
      immediate: true,
    },
    getActiveElements: {
      handler() {
        // moved into watcher instead of keep it in 'setupAnimationOption()' method because current 'animationOption' also needs to grab the active tab
        // then reorder the list accordingly in first load, if keep it in method it will keep reordering that will break the carousel
        let showByLetterOption = true;
        if (this.getActiveElements.length) {
          this.getActiveElements.forEach((item) => {
            if (typeof item.data.content !== 'undefined' && disallowSplitText(item.data.content)) {
              showByLetterOption = false;
            }
          });
        }

        this.showByLetterOption = showByLetterOption;
      },
      deep: true,
      immediate: true,
    },
  },
  beforeMount() {
    this.animationType[0].list = this.animationList[0].list;
    this.animationType[1].list = this.animationList[1].list;
    this.animationType[2].list = this.animationList[2].list;
  },
  mounted() {
    this.directionList = this.getAnimationListDirection(
      this.getActiveAnimationList.type,
      this.selectedTransition.value,
    );
    if (this.directionList && this.directionList.length === 0) {
      const transitionIndex = this.transitionList[0].list.findIndex(
        t => t.value === this.selectedTransition.value,
      );
      if (transitionIndex >= 0) {
        this.directionList = this.transitionList[0].list[transitionIndex].direction || []; // some animation has no direction
      } else {
        this.directionList = [];
      }
    }

    if (this.animationOption.length === 1 && this.activeAnimationOption !== 'default') {
      this.changeAnimationOption('default');
    }

    this.setupCarouselSlide();
  },
  methods: {
    ...mapMutations(['setCurrentTime', 'setShowAnimationTab', 'setShowSidebar']),
    ...mapMutations('canvasElements', [
      'updateSceneTransition',
      'updateCanvasElementTimelineSettings',
      'setSelectedAnimationDirection',
    ]),
    ...mapActions('canvasElements', ['setAllActiveElementsTimeline', 'saveProjectScene']),
    ...mapActions('canvasHistory', ['catchHistory']),
    setupAnimationOption() {
      const animationOption = [];

      if (this.showByLetterOption) {
        // disabled text animation per-line
        // ISSUE: if the text is loaded but the font is not ready, it will split the content which is already on the DOM (using default generic font)
        // then when the browser finally loaded the custom font, it will now apply the font style which will push the contents due to different font text spacing.

        animationOption.push({
          name: 'Letter',
          value: 'per-text',
        });

        animationOption.push({
          name: 'Line',
          value: 'per-line',
        });

        animationOption.push({
          name: 'Word',
          value: 'per-word',
        });
      }

      animationOption.push({
        name: 'Object',
        value: 'default',
      });

      this.animationOption = animationOption;
    },
    setupCarouselSlide() {
      if (!this.$refs.animationCarousel) return;

      if (this.animationOption.length === 1 || !this.isText) {
        this.activeAnimationOptionIndex = 0;
        this.hasCarouselClickedNextOnce = false;
        return;
      }

      // it will slide to the slide where the active tab exist
      const selectedAnimationOptionIndex = this.animationOption.findIndex(option => option.value === this.activeAnimationOption);

      if (this.activeAnimationOption === 'default') {
        // by doing this we drag the carousel slider so we can see the 'object' option that is placed behind of the list. Slide it 1 time is the maximum since we only have 4 options, and only show 3 of them.
        this.$refs.animationCarousel.next();
        this.hasCarouselClickedNextOnce = true;
      } else {
        this.$refs.animationCarousel.goTo(0);
        this.hasCarouselClickedNextOnce = false;
      }

      this.activeAnimationOptionIndex = selectedAnimationOptionIndex;
    },
    closeAnimationTab(event) {
      const paths = event.path || event.composedPath();
      const isCollapseSidebarButton = checkIsCollapseSidebarButton(paths);

      if (this.showTimeline && isCollapseSidebarButton) this.setShowSidebar(false);
      this.setShowAnimationTab(false);
    },
    moveCarousel(type, index) {
      if (type === 'prev') {
        const activeIndex = this.activeAnimationOptionIndex - 1;

        this.activeAnimationOptionIndex = activeIndex;
        this.changeAnimationOption(this.animationOption[activeIndex].value);
        this.$refs.animationCarousel.prev();
        this.hasCarouselClickedNextOnce = false;
      }

      if (type === 'next') {
        const activeIndex = this.activeAnimationOptionIndex + 1;

        this.activeAnimationOptionIndex = activeIndex;
        this.changeAnimationOption(this.animationOption[activeIndex].value);
        this.$refs.animationCarousel.next();
        this.hasCarouselClickedNextOnce = true;
      }

      if (type === 'custom') {
        if (index === 2 && !this.hasCarouselClickedNextOnce) {
          this.$refs.animationCarousel.next();
          this.hasCarouselClickedNextOnce = true;
        }
        if (index === 1 && this.hasCarouselClickedNextOnce) {
          this.$refs.animationCarousel.prev();
          this.hasCarouselClickedNextOnce = false;
        }

        this.activeAnimationOptionIndex = index;
        this.changeAnimationOption(this.animationOption[index].value);
      }
    },
    changeAnimationOption(value) {
      this.activeAnimationOption = value;
      const { type } = this.getActiveAnimationList;

      // since some of the per-text is not available for default
      // and vice versa,
      // need to go back to None if user change the option
      const selectedAnimation = this.selectedTransition.value;
      const doesAnimationExist = this.animationListByActiveType.findIndex(anim => anim.value === selectedAnimation) > -1;

      // eslint-disable-next-line
      for (let i = 0; i < this.activeElementsIds.length; i++) {
        const id = this.activeElementsIds[i];
        const update = {
          id,
        };

        if (type === 'in') {
          update.animateInOption = value;
          if (!doesAnimationExist) update.animateInValue = '';
        } else if (type === 'out') {
          update.animateOutOption = value;
          if (!doesAnimationExist) update.animateOutValue = '';
        }

        this.updateCanvasElementTimelineSettings(update);
      }
      this.$root.$emit('canvas-container-reset-timeline');
    },
    setupActiveAnimationTab() {
      const { type, id } = this.getActiveAnimationList;
      let activeAnimationOption = 'default';

      if (!isEmpty(id) && id.length && (type === 'in' || type === 'out')) {
        // if there is multiple elements and they are different type
        // just set to default
        for (let i = 0; i < id.length; i += 1) {
          const element = this.getCanvasElementById(id[i]);
          const { animateInOption, animateOutOption } = element.timeline_settings;

          // for the first loop, set the active tab
          // after that, check if it's different
          if (type === 'in') {
            if (i !== 0 && activeAnimationOption !== animateInOption) {
              activeAnimationOption = 'default';
            } else {
              activeAnimationOption = animateInOption;
            }
          }
          if (type === 'out') {
            if (i !== 0 && activeAnimationOption !== animateOutOption) {
              activeAnimationOption = 'default';
            } else {
              activeAnimationOption = animateOutOption;
            }
          }
        }
      }

      this.activeAnimationOption = activeAnimationOption;
    },
    changeAnimation(animation) {
      const { value, direction, directionList } = animation;
      const option = this.activeAnimationOption;

      if (value !== 'none' && typeof directionList !== 'undefined') {
        // Update directionList only if needed
        this.directionList = directionList;
      }

      if (this.isTransition) {
        this.catchHistory('scene');
        const sceneId = this.getActiveAnimationList.id;

        this.updateSceneTransition({
          id: sceneId,
          value,
          direction,
        });

        this.saveProjectScene({ sceneId });
      } else {
        this.catchHistory('element');
        const { type } = this.getActiveAnimationList;

        const firstId = this.getActiveAnimationList.id[0];

        if (type === 'in') {
          this.setCurrentTime(0);
        } else if (type === 'during') {
          const animateInEnds = this.getCanvasElementById(firstId).timeline_settings.animateInDuration
            + this.getCanvasElementById(firstId).time_in;
          this.setCurrentTime(animateInEnds);
        } else if (type === 'out') {
          this.setCurrentTime(this.getCanvasElementById(firstId).timeline_settings.animateOutStart);
        }

        // eslint-disable-next-line
        const animation = {
          type, value, direction, option,
        };
        this.setAllActiveElementsTimeline(animation);
        this.$root.$emit('canvas-container-reset-timeline');
      }
    },
    changeDirection(direction) {
      this.catchHistory('element');

      this.setSelectedAnimationDirection(direction);
      this.changeAnimation({
        value: this.selectedTransition.value,
        direction,
      });
    },
    onClickAnimationOptionCarousel() {
      if (!this.isAnimationCarouselDisabled) return;
      this.alertWarn('', "The effects you applied doesn't support this animation setting.", 8000, 'TopCenterNotif'); // eslint-disable-line
    },
    onClickAnimationItemContainer(animationItemValue) {
      const isTransitionWithValidation = animationItemValue === 'peek' || animationItemValue === 'snake';
      const isDisabled = this.isAnimationCarouselDisabled && isTransitionWithValidation;

      if (!isDisabled) return;
      this.alertWarn('', "The effects you applied doesn't support this animation setting.", 8000, 'TopCenterNotif'); // eslint-disable-line
    },
  },
};
</script>

<style lang="scss">
.animation-tab {
  background: $darkGrey700;
  height: 100%;
  left: 72px;
  padding: 15px 30px 0;
  position: absolute;
  top: 0;
  width: 360px;
  display: flex;
  flex-direction: column;
  z-index: 3;

  @include smallest {
    width: 270px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
  }

  .animation-option__carousel {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    margin: 20px 0 15px;

    &.animation-carousel--disabled {
      .carousel-icon {
        opacity: 0.25;
        pointer-events: none;
      }

      .carousel__list {
        pointer-events: none;
      }
    }

    .carousel-icon {
      color: $light;
      font-size: 1.5rem;
      width: 15%;
      min-width: 20px;
      padding: 0;

      &.carousel-button--disabled {
        opacity: 0.25;
        pointer-events: none;
      }

      &:hover {
        color: $lightGrey700;
      }
    }

    .carousel__list {
      width: 70%;

      .carousel__list-item {
        font-size: 0.875rem;
        font-weight: 600;
        color: $light;
        padding: 0;

        &:not(.is-active) {
          opacity: 0.25;
          font-weight: 500;
        }
      }
    }
  }

  .animation-tab__list {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    padding: 0;
    margin: 0;

    &.has-margin-top {
      margin-top: 20px;
    }

    .animation-tab__item-container {
      width: 33%;
      padding: 3px;
      list-style: none;
    }
  }
}
</style>
