<template lang="pug">
.brand-tab(:class="{'tab--has-max-height': !isLogoTab}")
  .brand-tab__empty(
    v-if="!hasTeamBrands"
  )
    img(src='@/assets/images/empty-state/brand.png')
    p Stay consistent with all your designs with your brand logo, color and fonts.
    BaseButton(
      v-if="!isTeamMember"
      is-primary=true
      is-tall=true
      :href="setupBrandUrl"
    ) Setup brand
  template(v-else)
    BrandTabSwitchBrand(
      @switch-brand="isSwitchingBrand = true"
    )
    BasePreloader.overlay-preloader(
      v-if="isSwitchingBrand || isBrandLogoOverlayLoading"
      :type="12"
    )
    SubGroups
    BrandTabLogo(
      v-if="isLogoTab && !isFetchingExtraTeamLogo"
      :is-loading="isBrandLogoLoading"
      @open-colorpicker="openWatermarkColorPicker"
    )
    BrandTabColor(v-if="isColorTab")
    BrandTabFont(
      v-if="isFontTab"
      :is-loading="isFontLoading && !isSwitchingBrand"
    )
    BrandTabGraphic(
      v-if="isGraphicTab"
      @do-select-file="$emit('do-select-file', $event)"
    )
    .overlay(v-if="showColorPicker && isWatermarkColorPicker")
    ColorPicker(
      v-show="showColorPicker && isWatermarkColorPicker"
      :color="defaultColor"
      :is-watermark="true"
      @close-watermark-colorpicker="closeWatermarkColorPicker"
    )
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { subgroups } from '@/assets/scripts/enums';

import SubGroups from '@/components/ProjectCanvas/AssetsSidebar/SubGroups.vue';
import BrandTabSwitchBrand from '@/components/ProjectCanvas/AssetsSidebar/children/BrandTabSwitchBrand.vue';
import BrandTabLogo from '@/components/ProjectCanvas/AssetsSidebar/children/BrandTabLogo.vue';
import BrandTabColor from '@/components/ProjectCanvas/AssetsSidebar/children/BrandTabColor.vue';
import BrandTabFont from '@/components/ProjectCanvas/AssetsSidebar/children/BrandTabFont.vue';
import BrandTabGraphic from '@/components/ProjectCanvas/AssetsSidebar/children/BrandTabGraphic.vue';
import ColorPicker from '@/components/ProjectCanvas/AssetsSidebar/children/ColorPicker.vue';

export default {
  name: 'BrandTab',
  components: {
    SubGroups,
    BrandTabSwitchBrand,
    BrandTabLogo,
    BrandTabColor,
    BrandTabFont,
    BrandTabGraphic,
    ColorPicker,
  },
  data() {
    return {
      isLoadingLogos: true,
      isSwitchingBrand: false,

      isWatermarkColorPicker: false,
    };
  },
  computed: {
    ...mapState(['showColorPicker']),
    ...mapState('team', ['teamSlug', 'teamId', 'teamBrandIndividualLoadingType', 'isFetchingExtraTeamLogo']),
    ...mapGetters('team', [
      'hasTeamBrands',
      'getTeamLogos',
      'isAllTeamBrandsLogosFetched',
      'getActiveTeamBrandMeta',
      'isTeamMember',
    ]),
    ...mapGetters('assetsSidebar', ['getActiveGroup', 'getActiveSubGroup', 'getSidebarElements']),
    ...mapGetters('canvasElements', ['getProjectBrandId', 'getWatermark']),
    isFontLoading() {
      return this.teamBrandIndividualLoadingType === 'team-brand-font';
    },
    isBrandLogoLoading() {
      return this.isLoadingLogos && !this.isSwitchingBrand;
    },
    isBrandLogoOverlayLoading() {
      const isAddingTeamLogo = this.teamBrandIndividualLoadingType === 'add-team-logo';
      const isRemovingTeamLogo = this.teamBrandIndividualLoadingType === 'remove-team-logo';

      return this.isFetchingExtraTeamLogo || isAddingTeamLogo || isRemovingTeamLogo;
    },
    setupBrandUrl() {
      return `${process.env.VUE_APP_DASHBOARD_URL}/team/${this.teamSlug}/team-brands`;
    },
    isLogoTab() {
      return this.getActiveSubGroup === subgroups.LOGO;
    },
    isColorTab() {
      return this.getActiveSubGroup === subgroups.COLOR;
    },
    isFontTab() {
      return this.getActiveSubGroup === subgroups.FONT;
    },
    defaultColor() {
      return this.backgroundColor || '#000000';
    },
    isGraphicTab() {
      return this.getActiveSubGroup === subgroups.GRAPHICS;
    },
    backgroundColor() {
      const { color } = this.getWatermark.background;
      if (color && color.points) {
        return color.points[0].color;
      }
      return color;
    },
  },
  watch: {
    isAllTeamBrandsLogosFetched: {
      handler(val) {
        // console.log('isAllTeamBrandsLogosFetched', val);
        if (val) this.isLoadingLogos = false;
      },
      immediate: true,
    },
    getProjectBrandId: {
      handler(val) {
        this.checkExtraTeamLogo();
        this.fetchTeamFontPairingList(this.getProjectBrandId);
        this.fetchTeamBrandFontStyles(this.getProjectBrandId);

        if (!this.getActiveTeamBrandMeta) {
          this.fetchTeamBrandMeta({ brandId: val, teamId: this.teamId });
        }
      },
      immediate: true,
    },
    getActiveTeamBrandMeta(val) {
      console.log('getActiveTeamBrandMeta', !!val);
      if (val) {
        this.isSwitchingBrand = false;
      }
    },
    isShowColorPicker(val) {
      if (!val) {
        // since the "this.setShowColorPicker(false);" has been handled at CanvasContainer.vue
        this.isWatermarkColorPicker = false;
      }
    },
  },
  mounted() {
    if (!this.isAllTeamBrandsLogosFetched) this.fetchAllTeamLogos();
  },
  destroyed() {
    this.resetTotalFetchedTeamBrandsLogos();
  },
  methods: {
    ...mapActions('team', [
      'fetchTeamBrandMeta',
      'fetchTeamBrandFontStyles',
      'fetchTeamFontPairingList',
      'fetchAllTeamLogos',
      'checkExtraTeamLogo',
    ]),
    ...mapMutations(['setShowColorPicker']),
    ...mapMutations('team', ['setDefaultBrandFont', 'resetTotalFetchedTeamBrandsLogos']),
    openWatermarkColorPicker() {
      this.isWatermarkColorPicker = true;
      this.setShowColorPicker(true);
    },
    closeWatermarkColorPicker() {
      this.isWatermarkColorPicker = false;
      this.setShowColorPicker(false);
    },
  },
};
</script>

<style lang="scss">
.brand-tab {
  height: 100%;
  width: 100%;
  padding: 0;
  z-index: 6;
  display: flex;
  flex-direction: column;

  &.tab--has-max-height {
    max-height: calc(100% - 25px);
  }

  .tab--sub-groups {
    width: 100% !important;
    height: 32px;
    margin-bottom: 20px;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0.8;
    background-color: $darkGrey800;
    z-index: 4;
    cursor: pointer;
  }

  .overlay-preloader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: rgba($darkGrey800, 0.8);
    z-index: 4;
  }

  .colorpicker {
    bottom: 0;
    top: initial;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.25);
    left: 360px;

    @include smallest {
      left: 260px;
    }
  }
}

.brand-tab__empty {
  background: $darkBg2;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;

  img {
    margin-top: -2rem;
  }

  p {
    color: $light;
    text-align: center;
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
  }
}
</style>
