<template lang="pug">
CanvasModal.add-scene-modal(
  :isShow="isShow"
  @closeOverlay="closeOverlay"
  :class="{ 'has-related-scenes': modularScenes.length }"
)
  h4.header-title Add Scene

  span.template-title(
    v-if="!isLiteMode && modularScenes.length"
  ) Related Scenes
  .modular-template__content
    ModularTemplateScene(
      v-for="scene in modularScenes"
      :key="scene.id"
      :background="scene.background"
      :description="scene.description"
      :duration="scene.duration"
      :id="scene.id"
      :scene="scene"
      :is_hero_w_bg="scene.is_hero_w_bg"
      :is_hero_wo_bg="scene.is_hero_wo_bg"
      :reachMaxDuration="reachMaxDuration"
      :availableDuration="availableDuration"
      :isAddSceneModal="true"
      :width="scene.width || getCanvasSize.width"
      :height="scene.height || getCanvasSize.height"
      @select="createScene(scene)"
    )

  template(
    v-if="!isLiteMode"
  )
    span.template-title(
      v-if="modularScenes.length"
    ) Others
    button.add-scene-modal__button(
      type="button"
      :class="{'is-dark': isDarkMode}"
      @click="createBlankScene"
    )
      i.icon.icon-plus
      span.text Blank Canvas
    button.add-scene-modal__button(
      type="button"
      :class="{'is-dark': isDarkMode}"
      @click="openDesignTab"
    )
      i.icon.icon-template-menu
      span.text Designer Templates
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';

import ProjectApi from '@/services/api/ProjectApi';
import ModularTemplateScene from '@/components/ProjectCanvas/PageOverlay/children/ModularTemplateScene.vue';

import templateMixins from '@/components/mixins/template-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';
import storyboardMixin from '@/components/mixins/storyboard-mixins';

export default {
  name: 'AddSceneModal',
  components: {
    ModularTemplateScene,
  },
  mixins: [templateMixins, darkModeMixin, storyboardMixin],
  props: {
    modularScenes: { type: Array, default: () => [] },
  },
  data() {
    return {
      isShow: true,
      availableDuration: 999,
      maxAllowedDuration: 999,
      reachMaxDuration: false,
    };
  },
  computed: {
    ...mapState(['showLastSelectedTemplate']),
    ...mapState('canvasElements', [
      'elementsList',
      'templateModularId',
      'lastSelectedLiteTemplateId',
    ]),
    ...mapGetters(['getIsTemplate']),
    ...mapGetters('userData', ['isFreeUser', 'isDesigner']),
    ...mapGetters('canvasElements', [
      'getProjectId',
      'getProjectDetails',
      'getActiveSceneId',
      'getAllScenesDuration',
      'getCanvasSize',
    ]),
    isPermutationTemplate() {
      return this.getProjectDetails.isPermutation;
    },
  },
  methods: {
    ...mapMutations([
      'setThirtySecondsWarning',
      'setShowCanvasPreloader',
      'setShowModularTemplate',
      'setShowLastSelectedTemplate',
    ]),
    ...mapMutations('canvasElements', ['setModular']),
    ...mapActions('canvasElements', ['getStoryBoardPreview']),
    closeOverlay() {
      this.$emit('close');
    },
    createBlankScene() {
      this.$emit('create-blank');
    },
    openDesignTab() {
      this.$emit('open-design');
    },
    createScene(scene) {
      // the check is done before user show AddSceneModal
      console.log('CEREATE SCENE', scene.id);
      if (this.isPermutationTemplate) {
        switch (scene.scene_category_type) {
          case 'product-w-bg':
            this.$emit('create-image', scene.id);
            break;
          case 'product-wo-bg':
            this.$emit('create-image', scene.id);
            break;
          case 'text':
            this.$emit('create-text', scene.id);
            break;
          case 'text-logo':
            this.$emit('create-text-logo', scene.id);
            break;
          case 'logo':
            this.$emit('create-logo', scene.id);
            break;
          default:
            this.addTemplateScene(scene.id);
            break;
        }
      } else {
        this.addTemplateScene(scene.id);
      }
    },
    addTemplateScene(sceneId) {
      this.setShowCanvasPreloader(true);

      const params = {
        project_id: this.getProjectId,
        template_scenes: [sceneId],
      };

      if (this.elementsList.allIds.length === 0) {
        // if there is no elements, auto change the canvas dimension
        params.change_canvas_dimension = true;
      }

      const templateId = this.isLiteMode ? this.lastSelectedLiteTemplateId : this.templateModularId;

      ProjectApi.chooseTemplate(templateId, params)
        .then((response) => {
          if (response.data.success) {
            this.applyDesign({
              response,
              assignModularAsLastTemplate: true,
            });

            const { scenes } = response.data.results;

            for (let i = 0; i <= scenes.length; i += 1) {
              if (scenes[i]) {
                // use websocket
                this.wsEventGenerateStoryboardThumbnail(this.getProjectId, this.getIsTemplate, scenes[i].id);
                // setTimeout(() => {

                //   this.getStoryBoardPreview({
                //     id: this.getProjectId,
                //     sceneId: scenes[i].id,
                //     isTemplate: this.getIsTemplate,
                //   });
                // }, i * 3000);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.setShowCanvasPreloader(false);
        });

      this.setShowModularTemplate(false);
      this.setShowLastSelectedTemplate(false);
      this.setModular(true);
      this.closeOverlay(true);
    },
  },
  beforeMount() {
    if (!this.isDesigner) {
      this.maxAllowedDuration = this.maxDurationProject;
      this.availableDuration = this.maxAllowedDuration;
    }
  },
};
</script>

<style lang="scss">
.add-scene-modal {
  .content {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
  }

  &:not(.has-related-scenes) {
    .content {
      justify-content: center;
    }
  }

  .canvas-modal__content {
    background: var(--white-darkgrey900);
  }

  .canvas-modal__closebtn {
    color: var(--darkgrey-white);
    top: 30px;
    right: 30px;
  }

  .header-title {
    margin: 0;
    font-size: 1.25rem;
    color: var(--darkgrey-white);
    font-weight: 500;
  }

  .template-title {
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: var(--darkgrey-white);
    text-align: left;
    margin: 30px 0 20px;
  }

  .content {
    padding: 40px 50px;
  }
}

.add-scene-modal__button {
  width: 175px;
  border-radius: 8px;
  border: 1px solid $defaultBorder;
  box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
  transition: box-shadow 0.25s ease-in-out;
  min-height: 175px;
  margin: 0;
  padding: 0;
  margin-right: 20px;

  &:hover {
    box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
    border-color: $blue;
  }

  &.is-dark {
    border: 0;
    background: $darkGrey5;

    &:hover {
      background: darken($darkGrey5, 1%);
    }
  }

  .icon {
    display: inline-flex;
    color: var(--darkgrey-white);
    margin-bottom: 15px;
    font-size: 1.25rem;
    justify-content: center;
    align-items: center;
  }

  .text {
    width: 100%;
    display: block;
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--darkgrey-white);
  }
}

// because we lazy load the design tab, when user doesn't go to designer tab yet
// this css is not loaded
.modular-template__content {
  width: 100%;
  display: flex;
  height: calc(100% - 30px);
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0;

  .preloader {
    width: 100%;
  }
}
</style>
