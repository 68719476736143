<template lang="pug">
  button.btn-favourite(
    :class="{ 'is-active': favourite, 'is-clicked': isClicked, 'has-text': showText }"
    :title="showText ? '' : titleText"
    type="button"
    @click.prevent="toggleFavourite"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  )
    template(v-if="favourite && !isChangingIconHover")
      template(v-if="showText")
        i.icon.icon-cross-line
      template(v-else)
        i.icon.icon-bookmark
    template(v-else)
      i.icon(
        :class="favouriteIconClass"
      )

    template(v-if="showText")
      | {{ titleText }}
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { groups, subgroups } from '@/assets/scripts/enums';
import FavouritesApi from '@/services/api/FavouritesApi';

/*
  Get favourites per category
  https://api.offeo.com/api/collections/user-favorites

  type options:
  elements, text, design, audio, backgrounds, myfiles, logos

  data:
  primary id of the table element
*/
export default {
  name: 'AssetsFavourite',
  data() {
    return {
      addTitle: 'Add to Saved',
      removeTitle: 'Remove from Saved',
      favourite: false,
      isClicked: false,
      isHovered: false,
    };
  },
  props: {
    type: { type: String, required: true }, // this is using fav types
    id: { type: Number, required: true, default: 0 }, // this is the id for element
    favId: { type: Number, required: true }, // this is the id under fav table
    isFavourite: { type: Boolean, default: false },
    isHtmlShape: { type: Boolean, default: false },
    showText: { type: Boolean, default: false },
    remarks: { type: [String, Object], default: null },
    isChangingIconHover: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(['isLiteMode']),
    ...mapGetters('assetsSidebar', [
      'getActiveGroup',
      'getActiveSubGroup',
    ]),
    titleText() {
      return this.favourite ? this.removeTitle : this.addTitle;
    },
    favouriteIconClass() {
      return this.favourite ? 'icon-bookmark' : 'icon-bookmark-line';
    },
    isUploadMusicTab() {
      return (
        !this.isLiteMode
        && this.getActiveGroup === groups.MUSIC
        && this.getActiveSubGroup === subgroups.UPLOAD
      );
    },
  },
  methods: {
    ...mapMutations(['addFavouritedCollection', 'removeFavouritedCollection']),
    addFavourite() {
      this.favourite = true;

      if (!this.id) {
        console.error('Missing ID!');
        return;
      }

      const element = {
        data: this.id,
        type: this.type,
        remarks: this.remarks,
      };

      if (this.isHtmlShape) element.type = 'htmlshape';
      if (this.isUploadMusicTab) element.type = 'uploaded-audio';

      FavouritesApi.addFavourite(element)
        .then((response) => {
          // console.log('add fav', response);
          if (!response.data || !response.data.success) {
            this.favourite = false;
          } else {
            this.$emit('assets-favourite-added');
            this.addFavouritedCollection(response.data.results);
            this.$emit('favourited', response.data.results.id); // prevent issue when saving personal music uploads, cannot directly unsave back
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeFavourite() {
      this.favourite = false;

      if (!this.favId) {
        console.error('Missing ID!');
        return;
      }

      const element = {
        id: this.favId,
        type: this.type,
      };

      if (this.isHtmlShape) element.type = 'htmlshape';
      // for it to disappear faster under favourite tab
      // need to remove first
      this.removeFavouritedCollection(element);

      FavouritesApi.removeFavourite(this.favId)
        .then((response) => {
          // console.log('remove fav', response);
          // if failed then cancel the favourite
          if (!response.data || !response.data.success) {
            this.favourite = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleFavourite() {
      this.isClicked = true;

      if (this.favourite) this.removeFavourite();
      else this.addFavourite();

      setTimeout(() => {
        this.isClicked = false;
      }, 250);
    },
  },
  mounted() {
    this.favourite = this.isFavourite;
  },
};
</script>

<style lang="scss">
.btn-favourite {
  padding: 0;
  font-size: 18px;
  width: 20px;
  height: 20px;
  color: $lightWhite;

  &.is-clicked {
    animation: faved 0.5s;
  }

  &.has-text {
    width: 100%;
    text-align: left;

    &.is-active {
      color: $salmon;
    }
  }

  &:hover {
    color: $lightGrey500 !important;
  }
}

@keyframes faved {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1.2);
  }
  60% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>
