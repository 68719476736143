<template lang="pug">
.brand-palette-dropdown(
  :class="{ 'is-active': isActive }"
  v-click-outside="closeDropdown"
  :style="dropdownStyle"
)
  button.brand-palette-dropdown__selected(
    :class="{'is-dark': isDarkMode}"
    @click="$emit('toggle-dropdown')"
  )
    span.name {{ selectedOptionName }}
    i.icon.icon-down-arrow
  div.brand-palette-dropdown__list-container
    ul.brand-palette-dropdown__list(refs="dropdownOptions")
      li.brand-palette-dropdown__item(
        v-for="(option, index) in options"
        :key="option.value"
        :class="{'is-selected': option.value === value, 'is-focused': focusedIndex === index}"
        v-html="option.name"
        @click="setValue(option.value)"
        @mouseenter="resetFocusedIndex"
        @mouseleave="resetFocusedIndex"
      )

    template(v-if="hasBrandedDesigns")
      hr.brand-palette-dropdown__divider-line
      li.brand-palette-dropdown__item.item--branded-design(
        :class="{'is-selected': 'branded-designs' === value}"
        @click="setValue('branded-designs')"
        @mouseenter="resetFocusedIndex"
        @mouseleave="resetFocusedIndex"
      ) Branded Designs

  GlobalEvents(
    :filter="(event, handler, eventName) => allowEscEvent(event)"
    @keydown.prevent.esc="closeDropdown")
  GlobalEvents(
    v-if="isActive"
    @keydown.prevent.down="setFocusedButton('down')"
    @keydown.prevent.up="setFocusedButton('up')"
    @keydown.prevent.enter="setValue()"
  )
</template>

<script>
import vClickOutside from 'v-click-outside';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'BrandPaletteDropdown',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [darkModeMixin],
  data() {
    return {
      value: '',
      focusedIndex: -1,
    };
  },
  props: {
    /**
     * The options need to have value and name
     */
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * Max width need to be with px or %
     */
    maxWidth: {
      type: String,
      default: '100%',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    hasBrandedDesigns: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    selectedOptionName() {
      const selectedOption = this.options.find(opt => opt.value === this.value);
      if (selectedOption && selectedOption.name) return selectedOption.name;
      if (this.value === 'branded-designs') return 'Branded Designs';
      return this.placeholder;
    },
    dropdownStyle() {
      const dropdownStyle = {};

      dropdownStyle.maxWidth = this.maxWidth;

      return dropdownStyle;
    },
  },
  beforeMount() {
    // if there is no placeholder, automatically select the first value
    if (!this.placeholder) this.value = this.options[0].value;
  },
  methods: {
    allowEscEvent(event) {
      return event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA' && event.target.getAttribute('contenteditable') !== true;
    },
    closeDropdown() {
      this.$emit('close-dropdown');
      this.resetFocusedIndex();
    },
    setValue(value) {
      // if nothing is passed, use the one from focusedIndex
      const setValue = value || this.options[this.focusedIndex].value;
      if (!setValue) {
        console.error('Missing value');
        return;
      }
      this.value = setValue;
      this.$emit('change', setValue);
      this.closeDropdown();
    },
    resetFocusedIndex() {
      this.focusedIndex = -1;
    },
    setFocusedButton(type) {
      if (type === 'down') this.focusedIndex += 1;
      if (type === 'up') this.focusedIndex -= 1;

      if (this.focusedIndex >= this.options.length) {
        this.focusedIndex = this.options.length - 1;
      }

      if (this.focusedIndex < 0) {
        this.focusedIndex = 0;
      }
    },
  },
};
</script>

<style lang="scss">
.brand-palette-dropdown {
  position: relative;
  font-size: 0.875rem;
  color: $darkGrey;
  height: 50px;
  z-index: 5;
}

.brand-palette-dropdown__selected {
  width: 100%;
  border-radius: $componentBorderRadius;
  border: 1px solid $lightGrey100;
  cursor: pointer;
  position: relative;
  padding: 10px;
  text-align: left;
  max-height: 40px;
  outline: 0;
  color: $lightGrey100;

  &.is-dark {
    border: 0;
    background: $darkGrey600 !important;
  }

  .brand-palette-dropdown.is-active & {
    .icon {
      transform: rotate(180deg);
    }
  }

  .icon {
    position: absolute;
    right: 9px;
    top: 8px;
    font-size: 1.25rem;
  }

  .name {
    width: 90%;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.brand-palette-dropdown__list-container {
  position: absolute;
  top: 42px;
  width: 100%;
  background: var(--white-darkgrey600);
  box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
  border-radius: 0 0 $componentBorderRadius $componentBorderRadius;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  padding: 5px;

  .brand-palette-dropdown.is-active & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba($darkGrey600, 0) !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--lightgrey600-darkgrey5) !important;
  }
}

.brand-palette-dropdown__list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  max-height: 220px;
  padding-right: 5px;
}

.brand-palette-dropdown__divider-line {
  border: 0;
  border-top: 1px solid var(--grey-lightgrey100);
  opacity: 0.3;
  margin: 5px 0;
}

.brand-palette-dropdown__item {
  margin: 0;
  background: transparent;
  width: 100%;
  padding: 10px;
  text-align: left;
  border: 0;
  cursor: pointer;
  color: var(--darkgrey-lightgrey100);
  transition: color 0.15s ease-in-out;
  display: block;
  border-radius: 4px;

  &:last-child {
    border-bottom: 0;
  }

  &.item--branded-design {
    font-weight: 600;
    list-style: none;
  }

  &:hover {
    color: var(--grey-white);
  }

  &.is-focused,
  &.is-selected {
    background: var(--lightgrey-green700);
    color: var(--darkgrey-lightgrey100);
  }

  &.is-focused {
    outline-color: #4d90fe;
    outline-offset: -2px;
    outline-style: auto;
    outline-width: 5px;
  }
}
</style>
