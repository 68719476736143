<template lang="pug">
.timeline__item.notranslate(
  :class="timelineItemLeftStyle"
  @click.left.exact="leftClickLayer"
  @click.ctrl.left.exact="ctrlLeftClickLayer"
  @click.meta.left.exact="ctrlLeftClickLayerMac"
  @click.shift.left.exact="$emit('addRangeActiveElements')"
  @dblclick="editName"
  @mouseenter="$emit('hovered', index)"
  @mousedown="clickLayer"
  :style="[layerStyle, groupLayerStyle]"
)
  span.layer-number(
    v-html="indexNumber + 1"
    v-if="subItem"
    :style="[layerNumberStyle]"
  )
  span.timeline__icon(
    :class="{ 'is-folder': item.groupElement }"
  )
    template(v-if="item.groupElement")
      i.icon.icon-files-menu
    template(v-else-if="isText")
      i.icon.icon-text-option
    template(v-else-if="isHtml")
      HtmlElement(
        :color="item.data.color"
        :stroke="item.data.stroke"
        :shape="item.data.shape"
        :size="item.size"
        isLayerPreview=true
        :opacity="1"
        :borderRadius="item.data.borderRadius"
      )
    template(v-else-if="isSvg")
      SvgElement(
        :url="item.data.url"
        :color="item.data.color"
        :stroke="item.data.stroke"
        :id="item.data.id"
        :isShape="item.type === 'shapes'"
        :opacity="1"
        :isCanvas="false"
      )
    template(v-else-if="isAnimated")
      AnimatedElement(
        :data="item.data"
        :index="index"
        :opacity="1"
        :animationType="item.animation_type"
      )
    template(v-else-if="isVideo")
      template(v-if="videoThumb")
        img.timeline__preview(:src="videoThumb")
      template(v-else)
        canvas.timeline__canvas
    template(v-else)
      img.timeline__preview(
        :class="{ 'contain': item.type === 'masks' }"
        :src="item.data.url"
      )
  span.timeline__icon.timeline__arrow(
    v-if="item.groupElement"
    @click.left.exact="toggleGroupFold(item)"
  )
    i.icon.icon-right-arrow(
      :style="arrowStyle"
    )
  .timeline__name(
    :style="subItemAudioStyle"
  )
    input.timeline__input(
      v-if="!item.groupElement"
      autocomplete="off"
      type="text"
      ref="timelineInput"
      disabled
      readonly
      v-model="item.data.name"
      :placeholder="item.data.name"
      @blur="saveNewName"
      @keyup.enter="saveNewNameAndBlur"
    )
    input.timeline__input(
      v-if="item.groupElement"
      type="text"
      ref="timelineInput"
      disabled
      readonly
      v-model="item.groupName"
      :placeholder="item.groupName"
      @blur="saveNewName"
      @keyup.enter="saveNewNameAndBlur"
    )
  button.timeline__button.js-lock-layer(
    type="button"
    @click="toggleLock"
    :title="titleLock"
  )
    i.icon(
      :class="{ 'icon-lock': isLocked, 'icon-unlock': !isLocked }"
    )
  button.timeline__button.js-view-timeline(
    type="button"
    @click="toggleShow"
    :title="titleShow"
  )
    i.icon(
      :class="{ 'icon-show': !isHidden, 'icon-hide': isHidden }"
    )
  button.timeline__button.js-mute-timeline(
    v-if="isVideo && hasAudio"
    type="button"
    @click="toggleMute"
    :title="titleMute"
  )
    i.icon(
      :class="{ 'icon-sound-on': !isMuted, 'icon-sound-off': isMuted }"
    )
</template>

<script>
import { mapMutations } from 'vuex';
import layerMixin from '@/components/mixins/layer-mixins';
import SvgElement from '@/components/ProjectCanvas/CanvasContainer/children/SvgElement.vue';
import HtmlElement from '@/components/ProjectCanvas/CanvasContainer/children/HtmlElement.vue';
import AnimatedElement from '@/components/ProjectCanvas/CanvasContainer/children/AnimatedElement.vue';
import { isMac } from '@/assets/scripts/utilities';

export default {
  name: 'TimelineLayerLeft',
  mixins: [layerMixin],
  components: {
    SvgElement,
    HtmlElement,
    AnimatedElement,
  },
  props: {
    index: { type: Number, default: 0 },
    indexNumber: { type: Number, default: 0 },
    hoveredIndex: { type: Number, default: -1 },
    item: { type: Object, default: () => ({}) },
    subItem: { type: Boolean, default: false },
    isDarkMode: { type: Boolean, default: false },
  },
  computed: {
    timelineItemLeftStyle() {
      return {
        'is-locked': this.isLocked,
        'is-muted': this.isMuted,
        'is-hidden': this.isHidden,
        'is-active': this.isActive,
        'is-hovered': this.hoveredIndex === this.index,
        'is-clicked': this.clickedLayer === this.index,
        'timeline__item--line': this.isLine,
        'is-dark': this.isDarkMode,
      };
    },
    arrowStyle() {
      if (this.item.groupFold) {
        return { };
      }
      return { transform: 'rotate(90deg)' };
    },
    subItemAudioStyle() {
      if (this.subItem && this.hasAudio) {
        return { width: '52%' };
      }
      return {};
    },
    layerNumberStyle() {
      if (this.subItem && !this.item.show) {
        return {
          color: '#8A94A6',
          opacity: '0.5',
        };
      }
      return {};
    },
  },
  methods: {
    ...mapMutations('canvasElements', [
      'changeActiveElements',
      'updateCanvasElementName',
      'toggleActiveElements',
      'emptyActiveElements',
      'updateGroupName',
      'toggleGroupFold',
    ]),
    leftClickLayer() {
      if (!this.item.lock) {
        if (this.item.groupElement) {
          this.emptyActiveElements();

          this.item.elements.forEach((element) => {
            this.addActiveElements(element.data.id);
          });
        } else {
          this.changeActiveElements(this.item.data.id);
        }
      }
    },
    ctrlLeftClickLayer() {
      if (this.item.groupElement) return;

      if (!this.item.lock) {
        this.toggleActiveElements(this.item.data.id);
      }
    },
    ctrlLeftClickLayerMac() {
      if (!isMac()) return;
      this.ctrlLeftClickLayer(this.item);
    },
    editName() {
      this.$refs.timelineInput.disabled = false;
      this.$refs.timelineInput.readOnly = false;
      this.$refs.timelineInput.focus();
      this.$refs.timelineInput.setSelectionRange(0, this.$refs.timelineInput.value.length);
    },
    saveNewName(e) {
      const { value } = e.target;
      this.$refs.timelineInput.setSelectionRange(0, 0);
      this.$refs.timelineInput.disabled = true;
      this.$refs.timelineInput.readOnly = true;

      if (value) {
        if (this.item.groupElement) {
          this.updateGroupName({
            name: value,
            group: this.item,
          });
        } else {
          this.updateCanvasElementName({
            id: this.item.data.id,
            name: value,
            hasChanged: true,
          });
        }
      }
    },
    saveNewNameAndBlur(e) {
      this.saveNewName(e);
      this.$refs.timelineInput.blur();
    },
  },
};
</script>

<style scoped lang="scss">
.timeline__item {

  &.is-locked {
    .js-lock-layer {
      transform: translateX(0);
      opacity: 0.8;
    }
  }

  &.is-muted {
    .js-mute-timeline {
      transform: translateX(0);
      opacity: 0.8;
    }
  }

  &.is-hidden {
    .js-view-timeline {
      transform: translateX(0);
      opacity: 0.8;
    }

    .layers__button,
    .layers__input {
      color: $grey;
      opacity: 0.5;
    }

    .layers__icon {
      opacity: 0.4;
    }
  }

  .timeline__name {
    flex: 1 auto;
  }

  .timeline__input {
    color: var(--black-white);
    display: inline-block;
    vertical-align: middle;
    padding: 2px 5px;
    border: 0;
    background: transparent;
    border-radius: 0;
    border-bottom: 1px solid transparent;
    max-width: 140px;
    cursor: pointer;
    user-select: none;
    font-size: 0.7rem;
    font-weight: 400;
    text-overflow: ellipsis;

    &:not(:read-only) {
      cursor: auto;
      user-select: none;
    }

    &:not(:read-only):focus {
      outline: 0;
      border-bottom: 1px solid $darkGrey;
    }
  }

  .timeline__button {
    color: var(--black-white);
    align-items: center;
    background: transparent;
    cursor: pointer;
    display: inline-flex;
    height: 20px;
    justify-content: center;
    visibility: visible;
    width: 20px;
    padding: 0;
    cursor: pointer;
    margin-right: 5px;
    opacity: 0.2;
    transition: opacity 0.25s ease-in-out;

    &.is-active {
      opacity: 0.8;
      visibility: visible;
    }

    &:hover {
      opacity: 0.8;
    }

    &.layer-number {
      color: var(--darkgrey800-lightgrey400);
    }
  }
}

.layer-number {
  width: 10px;
  font-size: 0.75rem;
  color: var(--darkgrey800-lightgrey400);
}

.is-folder {
  margin: 0px !important;
}

.timeline__arrow {
  width: 12px !important;

  .icon-right-arrow {
    font-size: 14px !important;
  }
}
</style>

<style lang="scss">
.timeline__item {
  &.timeline--line .timeline__icon  {
    .element-wrap,
    svg,
    canvas,
    img {
      flex: 1 0 auto;
      max-width: none;
      width: 100px !important;
      height: auto !important;
    }
  }

  .timeline__icon {
    color: var(--darkgrey800-white);
    align-items: center;
    flex: 1 0 1;
    display: inline-flex;
    justify-content: center;
    margin-right: 8px;
    width: 32px;
    height: 32px;
    font-size: 13px;
    overflow: hidden;

    .icon {
      font-size: 25px;

      .timeline.is-compact & {
        font-size: 21px;
      }
    }

    .element-wrap,
    svg,
    canvas,
    img {
      width: 32px;
      height: auto;
      object-fit: cover;

      .timeline.is-compact & {
        width: 20px;
        height: 20px;
      }
    }

    img {
      object-fit: cover;
      width: 20px;
      height: 20px;
      border-radius: 50%;

      &.contain {
        object-fit: contain;
      }
    }

    svg {
      position: static;
    }
  }
}
</style>
