<template lang="pug">
#modal-multiple-login-warning
  CanvasModal(
    :title='multipleLoginWarningText',
    :isShow='showMultipleLoginWarning',
    @closeOverlay='closeOverlay',
    :extraClass='extraClass',
    :maxWidth='450',
    :isGrey='true',
    :hideCloseButton='true'
  )
    p
      | This may be caused by logging in with more than one computer/browser or prolonged inactivity.
      | To avoid this, only log in with one computer or browser at a time.
    p Close this tab if you wish to use OFFEO on another computer/browser.
    BaseButton(
      is-tall=true
      is-primary=true
      icon-after='icon-right-arrow-long'
      @click="goToLogin"
    ) Log in here
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import UserApi from '@/services/api/UserApi';
import helperMixins from '@/components/mixins/helper-mixins';

export default {
  name: 'MultipleLoginOverlay',
  mixins: [helperMixins],
  data() {
    return {
      isShaken: false,
      password: '',
      isSending: false,
      facebookAuth: `${process.env.VUE_APP_PUBLIC_URL}/social-auth/facebook`,
      googleAuth: `${process.env.VUE_APP_PUBLIC_URL}/social-auth/google`,
    };
  },
  props: {
    text: { type: String, default: '' },
    buttons: { type: Array, default: () => [] },
    title: {
      type: String,
      default: "<i class='icon icon-warning'></i> WARNING",
    },
  },
  computed: {
    ...mapState(['showMultipleLoginWarning', 'loginOptions', 'unauthenticatedAPI', 'isLoggedIn']),
    ...mapState('userData', ['userEmail']),
    extraClass() {
      const cssClass = [];
      if (this.hasPassword) cssClass.push('has-password');
      if (this.hasFacebook) cssClass.push('has-facebook');
      if (this.hasGoogle) cssClass.push('has-google');

      if (this.isShaken) cssClass.push('is-shaken');

      return cssClass.join(' ');
    },
    hasPassword() {
      return (this.loginOptions && this.loginOptions.password) || false;
    },
    hasFacebook() {
      return (this.loginOptions && this.loginOptions.facebook) || false;
    },
    hasGoogle() {
      return (this.loginOptions && this.loginOptions.google) || false;
    },
    multipleLoginWarningText() {
      return 'You have been logged out';
    },
  },
  methods: {
    ...mapMutations(['setShowMultipleLoginWarning', 'setUnauthenticatedAPI', 'setIsLoggedIn']),
    ...mapMutations('userData', ['setUserData']),
    ...mapMutations('account', ['showLoginModal']),
    closeOverlay() {
      this.isShaken = true;
      setTimeout(() => {
        this.isShaken = false;
      }, 500);
    },
    doLogin() {
      this.isSending = true;
      const params = {
        email: this.userEmail,
        password: this.password,
      };
      UserApi.userLogin(params, 'v2')
        .then((response) => {
          if (response.data.success) {
            // console.log('set token', process.env.VUE_APP_OAUTH_TOKEN_COOKIE, response.data.token);
            // window.$cookies.set(process.env.VUE_APP_OAUTH_TOKEN_COOKIE, response.data.token, '.offeo.com'); // set cookie in sub-domain

            this.setShowMultipleLoginWarning(false);
            const resultUser = response.data.results.user;

            const userLevel = resultUser.subscription_level;
            const userRole = resultUser.role_id;
            const userId = resultUser.id;
            const userEmail = resultUser.email;
            const userName = resultUser.name;
            const userUsername = resultUser.username;
            const userAvatar = resultUser.avatar;
            const userRegistrationData = resultUser.created_at; // eslint-disable-line
            const storageSpaceLeft = resultUser.storage_space_left;
            const removeBgCredits = resultUser.remove_bg_credits;
            const usedNewPricing = resultUser.used_new_pricing;
            const { loginToken } = resultUser;

            this.setUserData({
              userLevel,
              userRole,
              name: userName,
              username: userUsername,
              profilePicture: userAvatar,
              userId,
              storageSpaceLeft,
              userEmail,
              removeBgCredits,
              usedNewPricing,
              loginToken,
            });
            this.setIsLoggedIn(true);
            this.$emit('use-here');
          } else {
            this.alertError('', response.data.message, 3000, 'TopCenterNotif');
            this.setShowMultipleLoginWarning(true);
          }
        })
        .catch((error) => {
          // console.log(error.response);
          this.alertError('', error.response.data.message, 3000, 'TopCenterNotif');
          this.setShowMultipleLoginWarning(true);
        })
        .then(() => {
          this.password = '';
          this.isSending = false;
        });
    },
    goToLogin() {
      this.setIsLoggedIn(false);
      this.showLoginModal();
      this.setShowMultipleLoginWarning(false);
    },
  },
};
</script>

<style lang="scss">
#modal-multiple-login-warning {
  z-index: 101;

  .canvas-modal__content {
    padding: 80px 50px;
    text-align: left;
  }

  .canvas-modal__main {
    .content {
      padding: 0;
    }
  }

  .canvas-modal__header {
    background: none;
    padding: 0;
  }

  .title {
    color: $darkGrey900;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 1.2;
    margin-bottom: 10px;
    text-align: left;
    text-transform: none;
  }

  p {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    color: $darkGrey200;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
    line-height: 1.4;
    letter-spacing: -1px;
  }

  #form-login {
    padding: 10px 80px;
  }

  .form-input {
    padding: 10px 0;

    input {
      width: 100%;
    }
  }

  .social-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .or-break {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #e1e4e8;
      line-height: 0.1em;
      margin: 10px 0 20px;

      span {
        background: #fcfcfd;
        color: #a6aebc;
        padding: 0 10px;
      }
    }

    .btn-social-icon {
      background: none;
      padding: 0;
      margin: 10px;
    }
  }

  .vh {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
}
</style>
