<template lang="pug">
.text-blur.text-effect
  BaseSwitch.text-blur__switch.effect__switch(
    name="text-blur"
    :isChecked="isEnabled('blur')"
    @change="toggleEnable('blur')"
    label="Blur"
  )
  VueSlideUpDown.text-blur__content.effect__content(
    :class="{'effect-content--enabled': isEnabled('blur')}"
    :active="true"
  )
    BaseSlider.effects__base-slider.slider--extra-margin-top(
      is-dark=true
      :min="0"
      :max="20"
      :value="blur"
      @update="updateBlur"
    )

    BaseButton.effects__reset-button(
      :is-canvas="true"
      is-dark=true
      is-full-width=true
      @click="resetBlur"
    ) Reset
</template>

<script>
import VueSlideUpDown from 'vue-slide-up-down';
import { textDataFormat } from '@/assets/scripts/variables';
import effectsMixin from '@/components/mixins/effects-mixins';

export default {
  name: 'TextBlur',
  components: {
    VueSlideUpDown,
  },
  mixins: [effectsMixin],
  computed: {
    isText() {
      return this.getActiveElements.length === 0 || this.getActiveElements[0].type === 'texts';
    },
    blur() {
      if (!this.isText) return false;
      return this.getActiveElements[0].data.textBlur.blur;
    },
  },
  mounted() {
    if (!this.isEnabled('blur')) {
      this.toggleEnable('blur');
    }
  },
  methods: {
    updateBlur(value) {
      this.catchHistory('element');
      this.updateCanvasElementTextBlur({ blur: value });
    },
    resetBlur() {
      this.catchHistory('element');
      this.updateCanvasElementTextBlur({ blur: textDataFormat.textBlur.blur });
    },
  },
};
</script>
