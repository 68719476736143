<template lang="pug">
.animation-tab__duration(
  :class="{'disable-animation-duration': disableAnimationDuration, 'is-modal': isModal}"
)
    BasePreloader(
      v-if="showPreloader"
    )
    template(v-else)
      h4.duration__title Duration

      BaseSlider.animation-tab__duration-slider(
        :min="activeAnimation.min"
        :max="activeAnimation.max"
        :value="animationSpeed"
        :step="step"
        :suffix="suffix"
        @update="updateAnimationSpeed"
      )
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { isNumber, convertNumberToDecimal } from '@/assets/scripts/utilities';
import { minDuration, maxDuration } from '@/assets/scripts/variables';
import BaseSlider from '@/components/UI/BaseSlider.vue';

export default {
  name: 'AnimationTabDuration',
  props: {
    activeTab: { type: String, default: '' },
    selectedTransition: { type: Object, default: () => ({}) },
    isModal: { type: Boolean, default: false },
  },
  components: {
    BaseSlider,
  },
  data() {
    return {
      showPreloader: false,
      step: 0.1,
      rangeArray: [],
    };
  },
  computed: {
    ...mapGetters('canvasElements', ['getActiveElements', 'getCanvasElementById']),
    ...mapGetters(['getActiveAnimationList']),
    activeAnimation() {
      return this.animation[this.activeTab];
    },
    animationDuration() {
      let animationDuration = this.selectedTransition.duration;

      if (
        typeof animationDuration === 'undefined'
        || animationDuration <= this.activeAnimation.min
      ) {
        animationDuration = this.activeAnimation.min;
      }

      return animationDuration;
    },
    animationSpeed() {
      return convertNumberToDecimal(this.animationDuration); // to ensure it will return 1 decimal point (default is 1)
    },
    animation() {
      const animation = {
        in: {
          name: 'in',
          max: 3,
          min: 0.3,
        },
        out: {
          name: 'out',
          max: 3,
          min: 0.3,
        },
      };

      if (this.getActiveElements.length === 1) {
        const activeElement = this.getActiveElements[0];
        const {
          animationDuration,
          animateInDuration,
          animateOutDuration,
        } = activeElement.timeline_settings;

        let activeAnimationInMax = parseFloat((animationDuration - animateOutDuration).toFixed(1));
        if (activeAnimationInMax > 3) activeAnimationInMax = 3;
        animation.in.max = activeAnimationInMax;

        let activeAnimationOutMax = parseFloat((animationDuration - animateInDuration).toFixed(1));
        if (activeAnimationOutMax > 3) activeAnimationOutMax = 3;
        animation.out.max = activeAnimationOutMax;
      }

      return animation;
    },
    disableAnimationDuration() {
      return this.activeAnimation.min === this.activeAnimation.max;
    },
    hasTimingChanges() {
      if (this.getActiveElements.length === 0) return false;

      return this.getActiveElements[0].timeline_settings;
    },
    suffix() {
      return this.animationDuration === 1 ? 'sec' : 'secs';
    },
  },
  methods: {
    ...mapMutations('canvasElements', ['updateCanvasElementTimelineSettings']),
    ...mapActions('canvasElements', ['setAllActiveElementsTimeline']),
    checkIfNumber($event) {
      return isNumber($event);
    },
    checkIfMaximum(animate) {
      // This function will check if that is the maximum duration allowed
      let isIn = false;
      let isRight = false;
      let { animateOutDuration, animateInDuration } = animate;
      const { id } = animate;
      const element = this.getCanvasElementById(id);
      const duration = element.timeline_settings.animationDuration;

      // check whether the animation changed is from animate in or out
      if (!animateInDuration) {
        isRight = true;
        // eslint-disable-next-line
        animateInDuration = element.timeline_settings.animateInDuration;
      }
      if (!animateOutDuration) {
        isIn = true;
        // eslint-disable-next-line
        animateOutDuration = element.timeline_settings.animateOutDuration;
      }

      // set the max duration
      let maxDurationIn = maxDuration.animateInOut;
      let maxDurationOut = maxDuration.animateInOut;

      // max duration in
      if (maxDurationIn >= duration - animateOutDuration) {
        maxDurationIn = duration - animateOutDuration;

        if (maxDurationIn < minDuration.animateInOut) {
          maxDurationIn = minDuration.animateInOut;
        }
      }

      // max duration out
      if (maxDurationOut >= duration - animateInDuration) {
        maxDurationOut = duration - animateInDuration;

        if (maxDurationOut < minDuration.animateInOut) {
          maxDurationOut = minDuration.animateInOut;
        }
      }

      if (isIn) {
        if (animateInDuration > maxDurationIn) animateInDuration = maxDurationIn;
      } else if (isRight) {
        if (animateOutDuration > maxDurationOut) animateOutDuration = maxDurationOut;
      }
      return { animateInDuration, animateOutDuration };
    },
    updateAnimationSpeed(val) {
      // FOR ANIMATE
      if (!val) return;

      let duration = convertNumberToDecimal(val); // to ensure it will return 1 decimal point (default is 1)

      const { id } = this.getActiveElements[0].data;

      if (this.activeTab === 'in') {
        if (duration > this.activeAnimation.max) duration = this.activeAnimation.max;
        if (duration < this.activeAnimation.min) duration = this.activeAnimation.min;

        const tempDuration = duration;
        const { animateInDuration } = this.checkIfMaximum({ id, animateInDuration: tempDuration });
        duration = animateInDuration;
      } else if (this.activeTab === 'out') {
        if (duration > this.activeAnimation.max) duration = this.activeAnimation.max;
        if (duration < this.activeAnimation.min) duration = this.activeAnimation.min;

        // it needs to be rounded to .1
        const tempDuration = duration;
        const { animateOutDuration } = this.checkIfMaximum({
          id,
          animateOutDuration: tempDuration,
        });
        duration = animateOutDuration;
      }

      const update = {
        type: this.activeTab,
        speed: duration,
      };

      this.setAllActiveElementsTimeline(update);
      this.$root.$emit('canvas-container-reset-timeline');
    },
  },
  watch: {
    hasTimingChanges() {
      this.showPreloader = true;

      this.$nextTick(() => {
        setTimeout(() => {
          this.showPreloader = false;
          // console.log('this.animation', this.animation, this.animationDuration);
        }, 300);
      });
    },
    // showPreloader
  },
};
</script>

<style lang="scss">
.animation-tab__duration {
  &.animation-tab--extra-margin-top {
    margin-top: 35px;

    &.is-modal {
      margin-top: 20px;
    }
  }

   &.is-modal {
    display: flex;
    justify-content: center;
    align-items: center;

    .duration__title {
      margin: 0;
      width: 10%;
      min-width: 80px;
    }

    .animation-tab__duration-slider {
      width: 80%;

      .slider__container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
      }

      .slider__value {
        border: 1px solid $light;
        background: transparent;
      }

      .range-wrapper {
        width: 60%;
      }
    }
  }

  &.disable-animation-duration {
    opacity: 0.3;
    pointer-events: none;
  }

  .duration__title {
    font-size: 0.875rem;
    font-weight: 500;
    color: $light;
    margin-top: 0;
    margin-bottom: 15px;
  }

  .animation-tab__duration-slider {
    input[type='range']::-webkit-slider-thumb {
      background: $light !important;
    }

    .range-wrapper {
      max-width: calc(100% - 80px);
    }

    .slider__value {
      color: $light;
      border: 0;
      background: $darkGrey7;
      width: 70px;
    }

    .slider__container .range-wrapper .range-before {
      background: $light;

      &:after {
        background: $lightGrey800;
      }
    }
  }
}
</style>
