<template lang="pug">
.storyboard-scene-preview(
  :style="storyBoardScenePreviewStyle"
)
  BasePreloader(
    v-if="!scenePreviewUrl"
    :type=11
    text="Generating Preview"
  )
  img(
    v-else
    :src="scenePreviewUrl"
  )

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StoryBoardPreview',
  props: {
    sceneId: { type: Number, default: 0 },
    width: { type: String, default: 'auto' },
    height: { type: String, default: 'auto' },
    scenePreviewUrl: { type: String, default: '' },
  },
  computed: {
    ...mapGetters(['getIsTemplate']),
    ...mapGetters('canvasElements', ['getProjectId']),
    storyBoardScenePreviewStyle() {
      const styleObject = {};

      styleObject.width = this.width !== 'auto' ? `${this.width}px` : this.width;
      styleObject.height = this.height !== 'auto' ? `${this.height}px` : this.height;

      return styleObject;
    },
  },
};
</script>

<style lang="scss" scoped>
.storyboard-scene-preview {
  width: 100%;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
</style>
