<template lang="pug">
.user-menu(
  v-click-outside="closeUserMenu"
)
  button.user-menu__bar(
    type="button"
    @click="toggleUserMenu"
  )
    UserMenuProfilePicture
    UserMenuNameLevel(
      v-if="fullVersion"
    )
  UserMenuNavigation(
    @close-menu="closeUserMenu"
  )
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import vClickOutside from 'v-click-outside';
import UserMenuProfilePicture from './children/UserMenuProfilePicture.vue';
import UserMenuNameLevel from './children/UserMenuNameLevel.vue';
import UserMenuNavigation from './children/UserMenuNavigation.vue';

export default {
  name: 'UserMenu',
  components: {
    UserMenuProfilePicture,
    UserMenuNameLevel,
    UserMenuNavigation,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    fullVersion: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('userData', ['showUserMenu']),
  },
  methods: {
    ...mapMutations('userData', ['setShowUserMenu']),
    toggleUserMenu() {
      this.setShowUserMenu(!this.showUserMenu);
    },
    closeUserMenu() {
      if (this.showUserMenu) this.setShowUserMenu(false);
    },
  },
};
</script>

<style lang="scss"></style>
