<template lang="pug">
.control-lite-mode(
  refs="control"
)
  ControlText(
    v-if="isText"
    :is-lite-version="true"
    :hidden-menu="hiddenMenu"
  )
  ControlMedia(
    v-if="isMedia"
    :isVideo="isVideo"
    :isWebm="isWebm"
    :isBrandedGraphic="isBrandedGraphic"
    :is-lite-version="true"
    :hidden-menu="hiddenMenu"
  )
  ControlGeneral(
    :isMedia="isMedia"
    :is-lite-version="true"
    :hidden-menu="hiddenMenu"
  )
</template>

<script>
import { mapGetters } from 'vuex';

import { debounce } from 'debounce';
import { windowWidth } from '@/assets/scripts/variables';

import ControlText from '@/components/ProjectCanvas/ControlBar/ControlText.vue';
import ControlMedia from '@/components/ProjectCanvas/ControlBar/ControlMedia.vue';
import ControlGeneral from '@/components/ProjectCanvas/ControlBar/ControlGeneral.vue';
import ConfirmationControl from '@/components/ProjectCanvas/ControlBar/children/ConfirmationControl.vue';

export default {
  components: {
    ControlText,
    ControlMedia,
    ControlGeneral,
    ConfirmationControl,
  },
  props: {
    isBrandedGraphic: { type: Boolean, default: false },
    isMedia: { type: Boolean, default: false },
    isText: { type: Boolean, default: false },
    isVideo: { type: Boolean, default: false },
    isWebm: { type: Boolean, default: false },
  },
  data() {
    return {
      showMenu: true,
      smallWidth: false,
      controlContainerWidth: 300,
      menu: [],
      hiddenMenu: [],
    };
  },
  computed: {
    ...mapGetters('canvasElements', [
      'getActiveElements',
    ]),
  },
  mounted() {
    this.checkCanvasWidth();

    if (this.smallWidth) {
      this.showMenu = false;
    }

    window.addEventListener('resize', debounce(() => {
      this.checkCanvasWidth();
    }, 300));
  },
  methods: {
    clearMenu() {
      this.hiddenMenu.splice(0);
      this.menu.splice(0);
      console.log('CLEAR MENU');
    },
    checkControlContainerWidth() {
      const topControl = document.getElementsByClassName('top-controls')[0];

      if (topControl) {
        const style = getComputedStyle(topControl);
        const paddingLeft = parseInt(style.paddingLeft, 10);
        const paddingRight = parseInt(style.paddingRight, 10);
        const width = topControl.clientWidth - paddingLeft - paddingRight;

        this.controlContainerWidth = width;
      }
      console.log('controlContainerWidth', this.controlContainerWidth);
    },
    checkVisibleButton() {
      this.clearMenu();

      this.$nextTick(() => {
        console.log('checkVisibleButton');
        const controlsWrapper = document.getElementsByClassName('controls__wrapper')[0];

        if (!controlsWrapper || this.getActiveElements.length === 0) {
          return;
        }
        const controls = controlsWrapper.getElementsByClassName('btn--control');
        console.log('controls', controls.length);
        let controlsWidth = 0;

        const menu = [];
        const hiddenMenu = [];

        for (let i = 0; i < controls.length; i += 1) {
          const control = controls[i];
          const style = getComputedStyle(control);
          const marginLeft = parseInt(style.marginLeft, 10);
          const marginRight = parseInt(style.marginRight, 10);
          const width = control.clientWidth + marginLeft + marginRight;
          const buttonWidth = 10;

          const isBeyondTheVisibleContainer = controlsWidth + width + buttonWidth > this.controlContainerWidth;
          const text = control.getAttribute('title') || control.innerText;
          // console.log('controlsWidth', text, controlsWidth, width, this.controlContainerWidth);
          // console.log('control is', text);

          if (isBeyondTheVisibleContainer) {
            hiddenMenu.push(text);
          } else {
            menu.push(text);
          }
          controlsWidth += width;
        }

        this.menu = menu;
        this.hiddenMenu = hiddenMenu;
      });
    },
    checkCanvasWidth() {
      console.log('~~checkCanvasWidth~~');
      if (document.clientWidth <= windowWidth.fbRequirements) {
        this.smallWidth = true;
      } else {
        this.smallWidth = false;
      }
      this.checkControlContainerWidth();
      this.checkVisibleButton();
    },
  },
  watch: {
    getActiveElements: {
      handler() {
        this.checkVisibleButton();
      },
    },
    hiddenMenu(val) {
      console.log('hiddenMenu', val);
      this.$emit('update-menu', val);
    },
  },
};
</script>

<style lang="scss">
.control-lite-mode {
  position: absolute;
  padding: 0 10px;
  left: 0;
  top: 53px;
  // use translate instead of left so that it can have the right width based on content
  transform: translateX(340px);
  display: block;
  height: 54px;
  background: $light;
  box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.1), 0px 18px 18px rgba(10, 31, 68, 0.12);

  @include fb-requirement {
    transform: translateX(320px);
  }

  & > * {
    display: inline-flex;
  }
}
</style>
