<template lang="pug">
.category-item-lite(
  :class="{'is-active': isActive, 'is-text': isText, 'is-image': isImage, 'is-image-loading': isImage && isImageLoading }"
)
  .category-item-lite__actions
    button.category-item-lite__duplicate(
      type="button"
      title="Duplicate"
      @click="duplicateElement"
    )
      i.icon.icon-copy-line
    button.category-item-lite__delete(
      type="button"
      title="Delete"
      @click="deleteElement"
    )
      i.icon.icon-delete

  p.category-item-lite__name(
    v-if="isText || isImage"
  ) {{categoryItemName}} {{name}}
  .category-item-lite__image(
    v-if="isImage"
  )
    BaseButton.category-item-lite__replace(
      is-light-grey=true
      :is-canvas="true"
      @click="replaceElement"
    ) Replace
    BasePreloader(
      v-if="isImageLoading"
    )
    img(
      v-if="imageUrl"
      :src="imageUrl"
      @load="imageLoadedEvent"
      @click="changeActiveElements(id)"
    )
  textarea.category-item-lite__input(
    v-if="isText"
    ref="textInput"
    :isTall="true"
    :isDark="true"
    :value="text"
    :style="textInputStyle"
    rows="1"
    @keyup="onKeyUp"
    @paste="onPaste"
    @focus="changeActiveElements(id)"
  )
  BaseButton.category-item-lite__button(
    ref="animateInButton"
    :is-canvas="true"
    @click="onAnimationClick"
  )
    <svg id="animateSvg" width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="5.25379" cy="5.76291" rx="5.25379" ry="5.46408" fill="white"/>
      <ellipse opacity="0.2" cx="11.6366" cy="5.76291" rx="5.25379" ry="5.46408" fill="white"/>
    </svg>
    span {{ animateInValue }}
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { debounce } from 'debounce';

export default {
  name: 'CategoryItemLite',
  props: {
    id: { type: String, default: '', required: true },
    index: { type: Number, default: 0 },
    isImage: { type: Boolean, default: false },
    isText: { type: Boolean, default: false },
    element: { type: Object, default: () => {} },
  },
  data() {
    return {
      textHeight: 'auto',
      text: '',
      isImageLoading: false,
    };
  },
  computed: {
    ...mapState(['animationList']),
    ...mapState('canvasElements', ['activeElementsIds']),
    ...mapGetters(['isTextEditing', 'getCanvasZoom']),
    categoryItemName() {
      let name = '';
      if (this.isText) name = 'Text';
      if (this.isImage) name = 'Image';
      return name;
    },
    animateInValue() {
      let value = 'none';

      if (this.element && this.element.timeline_settings.animateInValue) {
        value = this.element.timeline_settings.animateInValue || 'none';
      }

      const animationObject = this.animationList[0].list.find(obj => obj.value === value);

      return animationObject.name;
    },
    isMask() {
      return this.element.type === 'masks';
    },
    imageUrl() {
      if (this.isMask) {
        return this.element.data.image.url;
      }
      return (this.element && this.element.data.url) || '';
    },
    name() {
      return `${this.index + 1}`;
    },
    isActive() {
      return this.activeElementsIds.includes(this.id);
    },
    textInputStyle() {
      const styleObject = {};
      let height = this.textHeight;

      if (height !== 'auto') {
        // 4 is from the border
        height = `${height + 4}px`;
      }

      styleObject.height = height;

      return styleObject;
    },
  },
  beforeDestroy() {
    this.$root.$off('lite-image-replaced', this.imageIsReplacedEvent);
  },
  mounted() {
    // use this to ensure it doesn't get called multiple times as the user is typing
    this.updateText();
    // console.log('mounted', this.id, this.index )
    this.onTextResize();
    this.$root.$on('lite-image-replaced', this.imageIsReplacedEvent);
  },
  methods: {
    ...mapMutations([
      'setActiveAnimationList',
      'setShowLiteReplaceModal',
      'setIsShowAnimationModal',
    ]),
    ...mapMutations('canvasElements', [
      'changeActiveElements',
      'insertElementToCanvas',
      'emptyActiveElements',
    ]),
    ...mapActions('canvasElements', ['updateCanvasElementContent', 'deleteElementFromCanvas']),
    ...mapActions('canvasHistory', ['catchCanvasElementState']),
    updateText() {
      if (!this.isText) return;
      this.text = this.element.data.content;
    },
    onTextResize() {
      if (!this.isText) return;
      this.height = 'auto';
      setTimeout(() => {
        const text = this.$refs.textInput;
        const height = text.scrollHeight || 'auto';
        this.textHeight = height;
        this.$root.$emit('element-stop-resizing', this.activeElementsIds);
      }, 100);
    },
    // eslint-disable-next-line func-names
    debouncedTextResize: debounce(function () {
      this.onTextResize();
    }),
    calculateRect() {
      const resizerElement = document.getElementsByClassName('resizer-element')[0];
      const textElement = resizerElement.getElementsByClassName('text-content')[0];
      return {
        h: textElement.clientHeight / this.getCanvasZoom,
      };
    },
    onKeyUp(e) {
      if (this.activeElementsIds.length) {
        const content = e.target.value;
        this.text = content;
        this.updateCanvasElementContent({
          id: this.id,
          content,
          rect: this.calculateRect(),
        });
        this.debouncedTextResize();
      }
    },
    onPaste(e) {
      e.preventDefault();
      let text = '';
      if (e.clipboardData || e.originalEvent.clipboardData) {
        text = (e.originalEvent || e).clipboardData.getData('text/plain');
      } else if (window.clipboardData) {
        text = window.clipboardData.getData('Text');
      }
      this.text = text;
      if (document.queryCommandSupported('insertText')) {
        document.execCommand('insertText', false, text);
      } else {
        document.execCommand('paste', false, text);
      }
      this.debouncedTextResize();
    },
    onAnimationClick() {
      this.changeActiveElements(this.id);
      this.openTransitionList();
    },
    openTransitionList() {
      const position = this.$refs.animateInButton.$el.getBoundingClientRect();

      this.setIsShowAnimationModal(true);
      this.setActiveAnimationList({
        type: 'in',
        id: this.id,
        position,
        animationPosition: 'right',
      });
    },
    duplicateElement() {
      this.emptyActiveElements();
      this.catchCanvasElementState(['scenesList', 'elementsList']);

      this.insertElementToCanvas({
        id: this.id,
        isDuplicate: true,
      });
    },
    deleteElement() {
      this.deleteElementFromCanvas(this.id);
    },
    replaceElement() {
      this.changeActiveElements(this.id);
      this.setShowLiteReplaceModal(true);
    },
    imageLoadedEvent() {
      this.isImageLoading = false;
    },
    imageIsReplacedEvent(id) {
      if (id === this.id) {
        this.isImageLoading = true;
      }
    },
  },
  watch: {
    isTextEditing(val) {
      if (!val && this.isActive) {
        this.updateText();
      }
    },
    imageUrl(val, oldVal) {
      if (val !== oldVal) {
        // as fallback, if after 4 sec, @load is not triggered
        // force image as loaded
        setTimeout(() => {
          this.isImageLoading = false;
        }, 4000);
      }
    },
  },
};
</script>

<style lang="scss">
.category-item-lite {
  width: 100%;
  margin-bottom: 15px;
  position: relative;

  &.is-active {
    .category-item-lite__image,
    .category-item-lite__input {
      border-color: $blue700;
    }
  }

  &.is-active,
  &:hover {
    .category-item-lite__replace {
      opacity: 1;
    }

    &.is-text {
      .category-item-lite__actions {
        opacity: 1;
      }
    }

    &.is-image {
      .category-item-lite__duplicate,
      .category-item-lite__delete {
        opacity: 1;
      }
    }
  }

  &.is-text {
    margin-bottom: 30px;
    padding-top: 20px;

    .category-item-lite__actions {
      position: absolute;
      right: 0;
      top: -5px;
      opacity: 0;
      transition: opacity 0.3s cubic-bezier(0, 0, 0, 1);
    }

    .category-item-lite__name {
      top: -10px;
    }
  }

  &.is-image {
    margin-bottom: 25px;

    .category-item-lite__duplicate,
    .category-item-lite__delete {
      z-index: 3;
      opacity: 0;
      transition: opacity 0.3s cubic-bezier(0, 0, 0, 1);
    }

    .category-item-lite__duplicate {
      position: absolute;
      right: 0;
      top: 2px;
    }

    .category-item-lite__delete {
      position: absolute;
      right: 0;
      top: 95px;
    }
  }

  &.is-image-loading {
    .category-item-lite__replace,
    img {
      opacity: 0;
    }
  }
}

.category-item-lite__input {
  display: block;
  width: 100%;
  padding: 10px 15px 10px 20px;
  background-color: $darkGrey2;
  color: $lightGrey400;
  border-radius: $componentBorderRadius;
  border: 2px solid $darkGrey2;
  font-family: $baseFont;
  font-weight: 500;
  font-size: 0.875em;
  margin: 0;
  outline: 0;
  resize: none;

  &:focus {
    color: $lightWhite;
    border-color: $blue;
  }

  @include fb-requirement {
    // on ios, if input font size is less than 16px, it will auto zoom
    font-size: 1rem;
  }
}

.category-item-lite__name {
  color: $lightGrey500;
  font-weight: 500;
  font-size: 0.75rem;
  margin-bottom: 7px;
  display: block;
  position: absolute;
  z-index: 3;
  top: 3px;
  left: 5px;
}

.category-item-lite--skeleton,
.category-item-lite__image {
  border: 2px solid $darkGrey600;
  background: $darkGrey600;
  position: relative;
  height: 120px;
  border-radius: $componentBorderRadius;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.category-item-lite__image {
  .preloader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 4;
  }
}

.category-item-lite--skeleton {
  margin-bottom: 25px;

  .name {
    position: absolute;
    color: $lightGrey600;
    background: rgba($darkGrey300, 50%);
    bottom: 0;
    left: 0;
    padding: 5px 10px;
    font-size: 0.75rem;
    width: 100%;
  }
}

.category-item-lite__replace {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 0;
  transition: color 0.3s cubic-bezier(0, 0, 0, 1), background 0.3s cubic-bezier(0, 0, 0, 1),
    opacity 0.3s cubic-bezier(0, 0, 0, 1);
}

.category-item-lite__button {
  position: relative;
  width: 100%;
  margin: 10px 0 0;
  justify-content: flex-start;
  background: $darkGrey800;

  &:focus,
  &:hover,
  &.is-active {
    background: $light;

    #animateSvg {
      ellipse {
        fill: $darkGrey100 !important;
      }
    }

    &::after {
      border-left: 4px solid $darkGrey100;
    }
  }

  span {
    margin: 0 10px;
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid $light;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.category-item-lite__duplicate,
.category-item-lite__delete {
  width: 24px;
  height: 24px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s cubic-bezier(0, 0, 0, 1);

  &:hover {
    opacity: 0.8;
  }
}

.category-item-lite__duplicate {
  color: #fff;
}

.category-item-lite__delete {
  color: $salmon;

  .icon {
    font-size: 1.25em;
  }
}
</style>
