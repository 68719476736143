<template lang="pug">
.canvas-working-space.notranslate(
    ref="canvasWorkingSpace"
    :class="{'canvas-working-space-overflow': canvasWorkingSpaceOverflow}"
  )
  .canvas-wrapper(
    ref="canvasWrapper"
  )
    #main-canvas-container(
      :style="overlayStyleObject"
    )
      .canvas-container(
        :style="overlayStyleObject"
        ref="canvasContainer"
      )
        Drop(
          @drop="doHandleDropElement"
        )
          CanvasScene(
            v-if='showCanvasScene',
            isCanvas,
            :elements='[...getCanvasElements].reverse()',
            :hoveredElementId="hoveredElement.id"
            :background='getCanvasBackground',
            animationTimeline='mainTimeline',
            :duration='getCurrentSceneDuration',
            :id='getActiveSceneId'
          )
      .canvas-overlay(:style='overlayStyleObject')
        CanvasGrid(v-if='showGrid')
        CanvasTitleSafe
        TextTemp
        SwapTemp(v-if='showSwap')
        CropTemp
        SmartGuides(:lines='smartGuides')
        ResizerOverlay
        HoverElement(:element='hoveredElement')
        MultipleSwap(v-if='showMultipleSwap')

  //- Lite mode shouldn't have any shortcut other than saving
  template(v-if="!isLiteMode")
    //- the events below won't run if the focus is on input, trimming, cropping, genie modal and swapping
    //- the shortcut belows are using keyCode `65` instead of key `a`
    //- because in mac, alt + a key will be `å`
    GlobalEvents(
      :filter='(event, handler, eventName) => allowNonLiteModeKeyEvents(event)',
      @keydown.ctrl.shift.alt.84.prevent='addTextElementToCanvas',
      @keydown.ctrl.alt.84.exact.prevent='selectAllTextElements',
      @keydown.meta.shift.alt.84.prevent='addTextToCanvasMac',
      @keydown.meta.alt.84.exact.prevent='selectAllTextElementsMac',
      @keydown.meta.alt.65.exact.prevent='selectAllElementsMac',
      @keydown.esc.prevent='emptyActiveElements'
    )

  //- These shortcuts are allowed in Lite Mode
  //- the events below will only run if the focus is on input
  GlobalEvents(
    :filter='(event, handler, eventName) => allowEscKeyEvent(event)',
    @keydown.prevent.esc='setIsTextEditing(false)'
  )
  GlobalEvents(
    :filter='(event, handler, eventName) => allowSelectAllKeyEvent(event)',
    @keydown.ctrl.alt.65.exact.prevent='selectAllElements',
  )
  GlobalEvents(
    :filter='(event, handler, eventName) => event.target.tagName !== "INPUT" && event.target.tagName !== "TEXTAREA" && !isTextEditing',
    @keydown.ctrl.c.exact.prevent='copyElements',
    @keydown.ctrl.v.exact.prevent='pasteElements',
    @keydown.meta.c.exact.prevent='copyElementsMac',
    @keydown.meta.v.exact.prevent='pasteElementsMac'
  )
  GlobalEvents(
    @keydown.ctrl.s.exact.prevent='saveProject',
    @keydown.meta.s.exact.prevent='saveProjectMac'
  )
  GlobalEvents(
    v-if="getActiveElements.length"
    :filter="(event, handler, eventName) => allowDeleteKeyEvent(event)"
    @keydown.8.exact.prevent="deleteLayer"
    @keydown.46.exact.prevent="deleteLayer"
  )
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import { debounce } from 'debounce';
import { Drop } from 'vue-drag-drop';
import cloneDeep from 'lodash.clonedeep';
import {
  randomId, isMac, isEmptyObject, isMobile,
} from '@/assets/scripts/utilities';
import {
  groups, subgroups, designTabs, favouriteFilters,
} from '@/assets/scripts/enums';
import {
  defaultFallbackFont,
  elementFormat,
  textDataFormat,
  maxDuration,
} from '@/assets/scripts/variables';

import CanvasScene from './children/CanvasScene.vue';
import CanvasGrid from './children/CanvasGrid.vue';
import CanvasTitleSafe from './children/CanvasTitleSafe.vue';
import TextTemp from './children/TextTemp.vue';
import SwapTemp from './children/SwapTemp.vue';
import CropTemp from './children/CropTemp.vue';
import ResizerOverlay from './children/ResizerOverlay.vue';
import SmartGuides from './children/SmartGuides.vue';
import HoverElement from './children/HoverElement.vue';
/* eslint-disable */
import MultipleSwap from '@/components/ProjectCanvas/PageOverlay/MultipleSwap.vue';
import Api from '@/services/api/Api';
import ProjectApi from '@/services/api/ProjectApi';
import TemplateApi from '@/services/api/TemplateApi';
import CollectionsApi from '@/services/api/CollectionsApi';

import websocketMixins from '@/components/mixins/websocket-mixins';
import storyboardMixin from '@/components/mixins/storyboard-mixins';
import helperMixin from '@/components/mixins/helper-mixins';

import FavouritesApi from '@/services/api/FavouritesApi';
/* eslint-enable */

// let animationContainerTimeout;
let historyTypeTimeout;

export default {
  name: 'CanvasContainer',
  mixins: [websocketMixins, helperMixin, storyboardMixin],
  data() {
    return {
      canvasGetTries: 0,
      canvasWorkingSpaceWidth: 1080,
      canvasWorkingSpaceHeight: 1080,
      canvasScale: 1,
      debugCanvasZoomingScales: false,
      elements: [],
      zoomTimeout: null,
      showCanvasScene: true, // fix to re-populate elements for new calculation per zoom settings.
      fetchingVideos: [],
      hoveredElement: {},
      smartGuides: [],
      canvasWorkingSpaceOverflow: false,
      isMobile: isMobile(),
    };
  },
  components: {
    CanvasScene,
    CanvasGrid,
    CanvasTitleSafe,
    TextTemp,
    SwapTemp,
    CropTemp,
    ResizerOverlay,
    SmartGuides,
    MultipleSwap,
    HoverElement,
    Drop,
  },
  computed: {
    overlayStyleObject() {
      const styleObject = {};
      const canvasWidth = this.getCanvasSize.width * this.getCanvasZoom;
      const canvasHeight = this.getCanvasSize.height * this.getCanvasZoom;

      styleObject.width = `${canvasWidth}px`;
      styleObject.height = `${canvasHeight}px`;

      return styleObject;
    },
    ...mapState([
      'showSwap',
      'showCrop',
      'showTrim',
      'showGenieModal',
      'showGrid',
      'showTimeline',
      'showAnimationTab',
      'showMaskTab',
      'showEditTab',
      'showFontTab',
      'showMultipleSwap',
      'showColorPicker',
      'showColorTab',
      'showStoryboardOverlay',
      'showLiteReplaceModal',
    ]),
    ...mapState('canvasElements', ['elementsList', 'reloadCanvas', 'shutterstockLoadingIds']),
    ...mapState('canvasHistory', ['historyType']),
    ...mapState('inspirationList', ['showRemoveBgOption']),
    ...mapState('team', ['teamBrands', 'teamId']),
    ...mapGetters([
      'getCanvasZoom',
      'getCanvasZoomFit',
      'getCurrentTime',
      'getIsTemplate',
      'projectDetails',
      'isTextEditing',
      'getDefaultFont',
      'getIsPlaying',
      'isLiteMode',
      'getFavouritedCollection',
    ]),
    ...mapGetters('assetsSidebar', ['getActiveGroup', 'getStockEndpointVersion']),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getActiveSceneId',
      'getCanvasBackground',
      'getCanvasElements',
      'getCanvasSize',
      'getCurrentSceneDuration',
      'getMainTimeline',
      'getScenes',
      'getCopiedElements',
      'getSelectedMusic',
      'getTrimmedElement',
    ]),
    ...mapGetters('assetsSidebar', ['getActiveGroup', 'getActiveSubGroup']),
    ...mapGetters('brand', ['hasSetupBrand']),
    ...mapGetters('team', ['isInTeamWorkspace']),
  },
  methods: {
    ...mapMutations([
      'setCanvasZoom',
      'setCanvasZoomFit',
      'setCurrentTime',
      'setIsTemplate',
      'setIsTextTemplate',
      'setProjectDetails',
      'setShowCanvasPreloader',
      'isPlaying',
      'setIsTextEditing',
      'setShowLastSelectedTemplate',
      'setShowColorPicker',
      'setDefaultFont',
      'setActiveDesignTab',
      'setShowAddSceneModal',
      'removeFavouritedCollection',
      'addFavouritedCollection',
      'setShowDesignContainer',
    ]),
    ...mapMutations('canvasElements', [
      'addActiveElements',
      'emptyActiveElements',
      'updateActiveSceneId',
      'updateProjectDetails',
      'updateTemplateModularId',
      'setSelectedTemplateModular',
      'updateLastSelectedTemplateId',
      'setLastSelectedLiteTemplate',
      'updateReloadCanvas',
      'updateHeroSceneWithBg',
      'updateHeroSceneWithoutBg',
      'updateProjectPreviews',
      'updateCanvasSize', // mutation so that it doesn't save history
      'updateCanvasSizeWithTemplate',
      'initStoryboardPreviews',
      'setIsLayout',
      'updateSelectedMusic',
      'updateShutterstockElement',
      'updateShutterstockLoadingIds',
      'removeShutterstockLoadingId',
      'setDefaultWatermark',
      'setWatermark',
    ]),
    ...mapMutations('assetsSidebar', [
      'updateMusicCategory',
      'updateTemplatePacks',
      'updateCustomElements',
      'setActiveGroup',
      'setDesignCategories',
      'setActiveDesignTab',
      'getShutterstockSearchId',
    ]),
    ...mapMutations('exportDetails', ['updateExportDetailState']),
    ...mapMutations('brand', ['setUserBrand']),
    ...mapActions(['setUserFavourites', 'toggleColorTab']),
    ...mapActions('canvasElements', [
      'saveProject',
      'setAutoSaveInterval',
      'addElementToCanvas',
      'updateScenesElements',
      'copyActiveElements',
      'pasteActiveElements',
      // 'createStoryBoardPreviews',
      'deleteElementFromCanvas',
      'addTextElementToCanvas',
    ]),
    ...mapActions('assetsSidebar', ['getDesigns', 'getPresetFormats', 'getDesignCategories']),
    ...mapActions('colorPreset', ['setColorBrand']),
    ...mapActions('canvasHistory', ['catchHistory']),
    ...mapActions('inspirationList', ['setTmpUserId']),
    ...mapActions(['updateShowStoryboardOverlay']),
    ...mapActions('team', ['initTeam', 'fetchTeamCustomFonts']),
    allowNonLiteModeKeyEvents(event) {
      return event.target.tagName !== 'INPUT'
      && event.target.tagName !== 'TEXTAREA'
      && event.target.getAttribute('contenteditable') !== 'true'
      && !this.showTrim
      && !this.showCrop
      && !this.showGenieModal
      && !this.showSwap;
    },
    allowEscKeyEvent(event) {
      return event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.getAttribute('contenteditable') === 'true';
    },
    allowSelectAllKeyEvent(event) {
      return event.target.tagName !== 'INPUT'
      && event.target.tagName !== 'TEXTAREA'
      && event.target.getAttribute('contenteditable') !== 'true'
      && !this.showTrim
      && !this.showCrop
      && !this.showGenieModal
      && !this.showSwap;
    },
    allowDeleteKeyEvent(event) {
      return event.target.tagName !== 'INPUT'
      && event.target.tagName !== 'TEXTAREA'
      && event.target.getAttribute('contenteditable') !== 'true'
      && !this.showSwap
      && !this.showCrop;
    },
    addTextElementToCanvas() {
      const element = JSON.parse(JSON.stringify(elementFormat));
      const textData = JSON.parse(JSON.stringify(textDataFormat));

      element.data = { ...element.data, ...textData };

      element.data.fontFamily = this.getDefaultFont.h1.fontFamily;
      if (this.getDefaultFont.h1.fontWeight && this.getDefaultFont.h1.fontWeight > 400) {
        element.data.fontWeight = 700;
      }
      if (this.getDefaultFont.h1.fontStyle && this.getDefaultFont.h1.fontStyle === 'italic') {
        element.data.textItalic = true;
      }

      element.data.fontSize = 85;

      element.data.id = randomId();
      element.type = 'texts';
      element.time_out = this.getCurrentSceneDuration;
      element.timeline_settings.animationDuration = element.time_out - element.time_in;
      element.timeline_settings.animateOutStart = element.time_out - element.timeline_settings.animateOutDuration;

      this.addElementToCanvas(element);
    },
    isCanvasHorizontallyOverflowed() {
      return this.$refs.canvasWorkingSpace.clientWidth < this.$refs.canvasWrapper.clientWidth;
    },
    isCanvasVerticallyOverflowed() {
      return this.$refs.canvasWorkingSpace.clientHeight < this.$refs.canvasWrapper.clientHeight;
    },
    isImageElement(item) {
      return (
        item.isImage
        || item.type === 'images'
        || (item.data.url && item.data.url.split('.').pop() === 'png')
      );
    },
    isTextElement(item) {
      return item.type === 'texts';
    },
    notAllowedOnLiteMode(item) {
      // only allow image and text to be clickable and interactable
      return this.isLiteMode && !(this.isImageElement(item) || this.isTextElement(item));
    },
    selectAllElements() {
      this.emptyActiveElements();

      for (let i = 0; i < this.getCanvasElements.length; i += 1) {
        const element = this.getCanvasElements[i];

        // for lite, only select text and image
        // for non lite, select everything
        if (!this.notAllowedOnLiteMode(element)) {
          this.addActiveElements(element.data.id);
        }
      }
    },
    selectAllTextElements() {
      this.emptyActiveElements();

      for (let i = 0; i < this.getCanvasElements.length; i += 1) {
        const element = this.getCanvasElements[i];

        if (element.type === 'texts') {
          this.addActiveElements(element.data.id);
        }
      }
    },
    checkClickElement(e) {
      const paths = e.path || e.composedPath();
      // This is the function that will deselect elements
      if (this.getActiveElements.length) {
        const isDrr = paths.findIndex(
          path => path.className && typeof path.className === 'string' && path.className.includes('drr'),
        ) > -1;
        const isLayer = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('layers'),
        ) > -1;
        const isTimeline = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('timeline__content'),
        ) > -1;
        const isTopControl = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('top-controls'),
        ) > -1;
        const isColorTab = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('color-tab'),
        ) > -1;
        const isEffectsTab = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('effects-tab'),
        ) > -1;
        const isFilterTab = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('filter-tab'),
        ) > -1;
        const isAdjustTab = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('adjust-tab'),
        ) > -1;
        const isAssetsSidebar = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('assets-sidebar'),
        ) > -1;
        const isResizer = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('resizer-overlay'),
        ) > -1;
        const isTextEditor = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('text-temp'),
        ) > -1;
        const isToggleTimeline = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('btn-toggle-timeline'),
        ) > -1;
        const isAnimationGroup = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('animation__group'),
        ) > -1;
        const isZoom = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('zoom__option'),
        ) > -1;
        const isGrid = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('btn-grid'),
        ) > -1;
        const isTitleSafe = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('btn-title-safe'),
        ) > -1;
        const isCanvasElement = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('canvas-element'),
        ) > -1;
        const isUndoRedo = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('btn-canvas-history'),
        ) > -1;
        const isSidebarButton = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('assets-navigation__button'),
        ) > -1;
        const isLayerTags = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('layer-tags'),
        ) > -1;
        const isMobileActions = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('mobile-project__actions'),
        ) > -1;

        const mobileOptions = document.getElementsByClassName('mobile-project__options')[0];
        const mobileActionsIsOpened = mobileOptions && mobileOptions.classList && mobileOptions.classList.contains('is-active');

        if (
          !isDrr
          && !isLayer
          && !isTimeline
          && !isTopControl
          && !isColorTab
          && !isEffectsTab
          && !isFilterTab
          && !isAdjustTab
          && !isAssetsSidebar
          && !isResizer
          && !isTextEditor
          && !isToggleTimeline
          && !isAnimationGroup
          && !this.showSwap
          && !this.showCrop
          && !(this.showTrim && this.getTrimmedElement.type === 'videos')
          && !this.showLiteReplaceModal
          && !this.showRemoveBgOption
          && !this.showAnimationTab
          && !this.showEditTab
          && !this.showMaskTab
          && !this.showFontTab
          && !isZoom
          && !isGrid
          && !isTitleSafe
          && !isCanvasElement
          && !isUndoRedo
          && !this.showColorPicker
          && !isSidebarButton
          && !isLayerTags
          && !isMobileActions
          && !mobileActionsIsOpened
        ) {
          this.emptyActiveElements();
        }
      }

      if (this.showColorPicker) {
        // only when color picker is showing
        // if the clicked area is not in color picker, close it
        const isColorPicker = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('colorpicker'),
        ) > -1;
        const isSecondPicker = paths.findIndex(
          path => path.className
              && typeof path.className === 'string'
              && path.className.includes('second-picker'),
        ) > -1;

        if (!isColorPicker) {
          // but if like in TextGlitch, where it has 2 colorpicker,
          // need to add a parameter to ensure it will not close other effects so moving between colorpicker can run well.
          if (isSecondPicker) this.setShowColorPicker({ boolean: false, maintainEffectsState: true });
          else this.setShowColorPicker(false);
        }
      }
    },
    updateZoomFit() {
      /**
       * To cover up the padding of 48px of canvasWrapper,
       * subtract canvasWorkingSpace's width/height by thrice of canvasWrapper's padding
       */
      if (!this.$refs.canvasWorkingSpace) return;

      let canvasWorkingSpaceWidth = this.$refs.canvasWorkingSpace.clientWidth;
      let canvasWorkingSpaceHeight = this.$refs.canvasWorkingSpace.clientHeight;
      const { paddingLeft, paddingRight } = getComputedStyle(this.$refs.canvasWrapper);
      const paddingValue = parseInt(paddingLeft, 10) + parseInt(paddingRight, 10);
      const canvasWidth = this.getCanvasSize.width;
      const canvasHeight = this.getCanvasSize.height;

      const timelineHeight = (document.querySelector('.timeline') && document.querySelector('.timeline').clientHeight)
        || 0;

      /**
       * Subtract canvasWorkingSpace's height by timeline's height not cover up canvas by timeline.
       * Make sure timeline's height is smaller than canvasWorkingSpace's height
       * or result will be negative and canvas will be scaled down to negative value
       */
      if (this.showTimeline && timelineHeight < this.canvasWorkingSpaceHeight) {
        this.canvasWorkingSpaceHeight = this.canvasWorkingSpaceHeight - timelineHeight;
      }
      // console.debug('canvasWorkingSpaceHeight Before subtract', canvasWorkingSpaceHeight);
      canvasWorkingSpaceWidth -= paddingValue;
      canvasWorkingSpaceHeight -= this.showTimeline ? 144 : paddingValue;

      if (canvasWorkingSpaceWidth < 100) canvasWorkingSpaceWidth = 100;
      if (canvasWorkingSpaceHeight < 100) canvasWorkingSpaceHeight = 100;

      this.canvasWorkingSpaceWidth = canvasWorkingSpaceWidth;
      this.canvasWorkingSpaceHeight = canvasWorkingSpaceHeight;

      const smallestSize = Math.min(
        this.canvasWorkingSpaceWidth / canvasWidth,
        this.canvasWorkingSpaceHeight / canvasHeight,
      );
      const method = this.showTimeline ? 'ceil' : 'floor';
      this.canvasScale = (Math[method]((smallestSize.toFixed(2) * 100) / 5) * 5) / 100;
      // console.debug('updateZoomFit',
      //   canvasWorkingSpaceWidth,
      //   canvasWorkingSpaceHeight,
      //   this.getCanvasSize,
      //   method,
      //   this.canvasScale);
      // console.log('!!!!!!!!!!CANVAS ZOOM FIT', this.canvasScale)
      this.setCanvasZoomFit(this.canvasScale);
    },
    // eslint-disable-next-line func-names
    resetMainTimeline: debounce(function () {
      // console.log('===resetMainTimeline===');
      this.getMainTimeline.clear();
      this.getMainTimeline.restart();
      this.getMainTimeline.addLabel('Start', 0);
      this.getMainTimeline.pause();
      this.$root.$emit('canvas-element-animation-updated');

      // added timeout to make sure the tweenmax already set
      setTimeout(() => {
        this.getMainTimeline.seek(this.getCurrentTime);
      }, 500);
    }),
    calculateCanvasScale(newCanvasScale, oldCanvasScale, beforeZoomedState = null) {
      const canvasZoomingScales = {
        newScale: newCanvasScale * 10,
        oldScale: oldCanvasScale * 10,
        top: this.$refs.canvasWorkingSpace.scrollTop,
        left: this.$refs.canvasWorkingSpace.scrollLeft,
        absoluteVerticalCenter:
          this.$refs.canvasWrapper.clientHeight / 2
          - this.$refs.canvasWorkingSpace.clientHeight / 2,
        absoluteHorizontalCenter:
          this.$refs.canvasWrapper.clientWidth / 2 - this.$refs.canvasWorkingSpace.clientWidth / 2,
        isCanvasVerticallyOverflowed: this.isCanvasVerticallyOverflowed(),
        isCanvasHorizontallyOverflowed: this.isCanvasHorizontallyOverflowed(),
      };

      if (beforeZoomedState) {
        if (newCanvasScale <= this.getCanvasZoomFit) {
          canvasZoomingScales.viewportVerticalCenter = canvasZoomingScales.absoluteVerticalCenter;
          canvasZoomingScales.viewportHorizontalCenter = canvasZoomingScales.absoluteHorizontalCenter;
        } else {
          canvasZoomingScales.viewportVerticalCenter = (beforeZoomedState.top / canvasZoomingScales.oldScale) * canvasZoomingScales.newScale;
          // const ns = newCanvasScale;
          // const os = oldCanvasScale;
          // const ot = beforeZoomedState.top;
          // const ibh = this.$refs.canvasWorkingSpace.clientHeight;
          // const obh = this.$refs.canvasWrapper.clientHeight;
          // Math.ceil((713/17)*11)-($0.clientHeight / (17/($1.clientHeight/($0.clientHeight/2))))
          // Math.ceil((ot / os) * ns) +- (os / (obh / (ibh / 2)))
          canvasZoomingScales.viewportHorizontalCenter = (beforeZoomedState.left / canvasZoomingScales.oldScale) * canvasZoomingScales.newScale;
        }
      } else {
        canvasZoomingScales.viewportVerticalCenter = canvasZoomingScales.absoluteVerticalCenter;
        canvasZoomingScales.viewportHorizontalCenter = canvasZoomingScales.absoluteHorizontalCenter;
      }

      return canvasZoomingScales;
    },
    scrollToCenter(beforeZoomedState, afterZoomedState) {
      const newTop = beforeZoomedState
        && !beforeZoomedState.isCanvasVerticallyOverflowed
        && afterZoomedState.isCanvasVerticallyOverflowed
        ? afterZoomedState.absoluteVerticalCenter
        : afterZoomedState.viewportVerticalCenter;
      const newLeft = beforeZoomedState
        && !beforeZoomedState.isCanvasHorizontallyOverflowed
        && afterZoomedState.isCanvasHorizontallyOverflowed
        ? afterZoomedState.absoluteHorizontalCenter
        : afterZoomedState.viewportHorizontalCenter;

      if (this.debugCanvasZoomingScales) {
        console.table(beforeZoomedState);
        console.table(afterZoomedState);
      }

      this.$refs.canvasWorkingSpace.scrollTo({
        top: newTop,
        left: newLeft,
      });
    },
    getElements() {
      this.elements = [...this.getCanvasElements].reverse();
    },
    updateZoomAndResize() {
      console.log('~updateZoomAndResize');
      this.updateZoomFit();
      this.setCanvasZoom(this.canvasScale);
      if (this.getActiveElements.length) {
        // if there is active element, recalculate the resizer
        this.$root.$emit('canvasElementSizeUpdated');
      }
    },
    canvasGet() {
      // console.log('canvasGet', this.$router.currentRoute.params.projectId );
      const projectId = this.getTemplateId() || this.canvasGetProjectId(); // check first if template, then default projects

      const isTemplate = !!this.getTemplateId();

      this.setIsTemplate(isTemplate);

      this.offeoChanelPrefix = (isTemplate ? 'template_' : 'project_') + projectId;

      // eslint-disable-next-line
      const vm = this;

      const isBlankTemplateTrigger = typeof this.$route.query.trigger !== 'undefined' && this.$route.query.trigger === 'blank';
      const isBrandedDesignTemplateTrigger = typeof this.$route.query.trigger !== 'undefined'
        && this.$route.query.trigger === 'branded-templates';

      const APIREQUEST = this.getTemplateId() ? TemplateApi : ProjectApi;
      APIREQUEST.get(projectId)
        .then((response) => {
          if (response.data.success) {
            // console.log('response api', this.getTemplateId(), this.canvasGetProjectId(), JSON.parse(JSON.stringify(response)));
            const projectDetails = cloneDeep(response.data.results);
            projectDetails.isTemplate = this.getIsTemplate;

            // console.log('this.isLiteMode && projectDetails.selected_template', this.isLiteMode, projectDetails.selected_template)

            if (this.isLiteMode && projectDetails.selected_template) {
              this.setLastSelectedLiteTemplate(projectDetails.selected_template);
            }

            if (isTemplate && projectDetails.template_category.includes('layout')) {
              this.setIsLayout(true);
            }

            if (typeof projectDetails.scenes !== 'undefined') {
              // if it's modular, update the scene elements twice
              // once for the canvas, once for the last selected template
              if (projectDetails.is_modular && !isTemplate) {
                const elements = {
                  assignModularAsLastTemplate: true,
                  isModularTemplate: true,
                  newScenes: cloneDeep(projectDetails.selected_template.scenes),
                };

                this.updateScenesElements(elements);
                this.updateLastSelectedTemplateId(projectDetails.selected_template.id);
                this.updateTemplateModularId(projectDetails.selected_template.id);
                this.setSelectedTemplateModular({
                  id: projectDetails.selected_template.id,
                  name: projectDetails.selected_template.template_name,
                });

                // Don't auto open add new scene when opening a project - basically remove auto open add scene on all instances

                // After smart create, don't open add new scene automatically
                // const locationHref = new URL(window.location.href);
                // const hideAddSceneModal =
                //   locationHref.searchParams.get('hide_add_new_scene_modal') ===
                //     'true' || false;
                // this.setShowAddSceneModal(!hideAddSceneModal);
              } else if (projectDetails.is_modular && isTemplate) {
                if (
                  typeof projectDetails.scenes.find(item => item.is_hero_w_bg) !== 'undefined'
                ) {
                  this.updateHeroSceneWithBg({
                    id: projectDetails.scenes.find(item => item.is_hero_w_bg).id,
                  });
                }
                if (
                  typeof projectDetails.scenes.find(item => item.is_hero_wo_bg) !== 'undefined'
                ) {
                  this.updateHeroSceneWithoutBg({
                    id: projectDetails.scenes.find(item => item.is_hero_wo_bg).id,
                  });
                }
              }

              // fix save correct element_id
              this.updateScenesElements({
                newScenes: cloneDeep(projectDetails.scenes),
              });

              this.updateActiveSceneId(projectDetails.scenes[0].id);

              if (isTemplate) {
                this.updateProjectPreviews(projectDetails.previews);
              }
            }

            this.initCustomFonts(projectDetails.user_id);
            this.initCustomProjectFonts(projectId, isTemplate);

            this.updateCanvasSize({
              width: projectDetails.canvas_width,
              height: projectDetails.canvas_height,
              notSmartResize: true,
            });

            this.updateCanvasSizeWithTemplate({
              width: projectDetails.canvas_width,
              height: projectDetails.canvas_height,
            });

            // for lite mode, need to check total scenes
            if (this.isLiteMode) {
              let totalDuration = 0;
              for (let i = 0; i < this.getScenes.length; i += 1) {
                let { duration } = this.getScenes[i];
                if (duration > maxDuration.liteScene) {
                  duration = maxDuration.liteScene;
                }
                totalDuration += duration;
              }
              projectDetails.duration = totalDuration;
            }

            this.updateProjectDetails(projectDetails);
            this.setCurrentTime(Math.round((projectDetails.scenes[0].duration / 2) * 10) / 10);

            if (projectDetails.type === 'text') this.setIsTextTemplate(true);

            this.initChannels(); // create websocket channels

            if (projectDetails.current_export) {
              this.updateExportDetailState({
                isExporting: true,
                isExportStarted: true,
                isTemplate: this.getIsTemplate,
                exportId: projectId,
                type: projectDetails.current_export.type,
                percent: projectDetails.current_export.percent || 0,
                queuePosition: projectDetails.current_export.queue_position || 1,
              });
            }
            setTimeout(() => {
              this.setShowCanvasPreloader(false);
            }, 1000);

            // if there is no elements on canvas, open design tab or modular selection
            // also open the project name overlay
            // console.log('length', this.elementsList.allIds.length, projectDetails.is_modular);
            if (this.elementsList.allIds.length === 0 && !isBlankTemplateTrigger) {
              if (projectDetails.is_modular) {
                this.setShowLastSelectedTemplate(true);
              } else {
                this.setActiveGroup('designs');
                this.setShowDesignContainer(true);
              }
            }

            // Get team details if user is in team workspace
            if (projectDetails.team && projectDetails.team.team_id) {
              // Check if team exists and if user is in team to get team data
              this.initTeam(projectDetails.team.team_id);

              // only set watermark if project has team
              if (projectDetails.watermark && projectDetails.watermark.size) {
                this.setWatermark(projectDetails.watermark);
              } else {
                this.setDefaultWatermark();
              }
            }
          }

          if (
            !isBrandedDesignTemplateTrigger
            && !isBlankTemplateTrigger
            && !this.isLiteMode
            && !this.isMobile
            && this.getScenes.length > 1
          ) {
            // don't show storyboard on branded design, blank template and lite mode
            this.updateShowStoryboardOverlay(true);
          }

          setTimeout(() => {
            this.setShowCanvasPreloader(false);
            this.initStoryboardPreviews(); // pull stored previews in localStorage and set it to state.
            this.createStoryBoardPreviews();
            this.updateZoomAndResize();
          }, 1000);
        })
        .catch((error) => {
          const errorMessage = (
            error
            && error.response
            && error.response.data.message
          ) || '';
          const lowercasedErrMessage = errorMessage.toLowerCase();

          if (error && error.response && error.response.status === 401) {
            // un authorized, token expired
            alert('You have been logout'); // eslint-disable-line

            window.location.href = `${process.env.VUE_APP_CANVAS_URL}/logout`;
          } else if (errorMessage === 'Only premium users can access.') {
            // free user let AccountController handle
            // window.location.href = `${process.env.VUE_APP_DASHBOARD_URL}/projects`;
          } else if (lowercasedErrMessage.includes('team member')) {
            alert('Has no permission to open this team project.'); // eslint-disable-line
            this.$router.push({ name: 'home' });
          } else if (this.canvasGetTries < 3) {
            // retry again
            setTimeout(() => {
              this.canvasGetTries += 1;
              this.canvasGet();
            }, 1000);
          } else if (errorMessage) {
            alert(errorMessage); // eslint-disable-line
            this.$router.push({ name: 'home' });
          } else {
            console.error(error);
            alert('Something went wrong on initializing the project.'); // eslint-disable-line
            this.$router.push({ name: 'home' });
          }
        });
    },
    getTemplateId() {
      return typeof this.$router.currentRoute.params !== 'undefined'
        && typeof this.$router.currentRoute.params.templateId !== 'undefined'
        ? this.$router.currentRoute.params.templateId
        : '';
    },
    canvasGetProjectId() {
      return typeof this.$router.currentRoute.params !== 'undefined'
        && typeof this.$router.currentRoute.params.projectId !== 'undefined'
        ? this.$router.currentRoute.params.projectId
        : '';
    },
    getMusicCategories() {
      CollectionsApi.getMusicCategories()
        .then((response) => {
          if (response.data && response.data.success) {
            this.updateMusicCategory(response.data.results);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTemplatePacks() {
      CollectionsApi.getTemplatePacks()
        .then((response) => {
          if (response.data && response.data.success) {
            this.updateTemplatePacks(response.data.results);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCustomElements() {
      CollectionsApi.getCustomElements()
        .then((response) => {
          if (response.data && response.data.success) {
            this.updateCustomElements(response.data.results);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    isShutterstockAsset() {
      return (
        (this.getActiveSubGroup === 'photo' || this.getActiveSubGroup === 'video')
        && this.getStockEndpointVersion === 'v2'
      );
    },
    async doHandleDropElement(data, e) {
      if (!data) return;
      const paths = e.path || e.composedPath();
      const isMask = paths.findIndex(
        path => path.className
            && typeof path.className === 'string'
            && path.className.includes('element-wrap--masks'),
      ) > -1;
      const { elementData } = data;

      // if it's an image and user drop on mask, let MaskElement handle it
      if (elementData && !(isMask && elementData.isImage)) {
        if (elementData.type !== 'text') {
          let isNewShutterstockAsset = false;
          const elementId = randomId();
          elementData.data.id = elementId;
          this.emptyActiveElements();

          if (this.isShutterstockAsset) {
            isNewShutterstockAsset = typeof this.shutterstockLoadingIds[elementData.data.src_id] === 'undefined';
            this.updateShutterstockLoadingIds({
              srcId: elementData.data.src_id,
              elementId,
            });
          }

          this.addElementToCanvas(elementData);

          if (
            this.getActiveGroup === groups.MEDIA
            && (subgroups.PHOTOS === this.getActiveSubGroup
              || subgroups.VIDEOS === this.getActiveSubGroup)
          ) {
            // is stock media
            // capture assets
            const params = {
              src_id: elementData.data.src_id,
            };

            if (this.getStockEndpointVersion === 'v1') {
              params.data_url = elementData.data.url;
              params.data_urlhd = elementData.data.urlHd || '';

              Api.captureAssets(params, this.getStockEndpointVersion);
            } else if (this.getStockEndpointVersion === 'v2') {
              await this.captureShutterstockAssetAndUpdateElement(
                params,
                elementId,
                isNewShutterstockAsset,
                elementData,
              );
            }
          }
        }
      }
    },
    async captureShutterstockAssetAndUpdateElement(
      defaultParams,
      elementId,
      isNewShutterstockAsset = false,
      data,
    ) {
      const elementData = { ...data };
      const params = { ...defaultParams };
      const srcId = params.src_id;
      if (this.getActiveSubGroup === 'photo') params.type = 'image';
      else if (this.getActiveSubGroup === 'video') params.type = 'video';
      params.search_id = this.getShutterstockSearchId;

      this.$root.$emit('capture-ss-asset', { id: params.src_id, type: params.type, elementData });

      const assetData = await Api.captureAssets(params, this.getStockEndpointVersion);

      if (this.getActiveSubGroup === 'photo') {
        elementData.data.thumb = assetData.data.results.thumb;
        elementData.data.url = assetData.data.results.url;
        elementData.data.urlHd = assetData.data.results.urlHd;
      } else if (this.getActiveSubGroup === 'video') {
        elementData.data.url = assetData.data.results.url;
        elementData.data.urlHd = assetData.data.results.urlHd;
      }

      if (assetData.data.results.exists) {
        this.updateShutterstockElement({
          id: elementId,
          elementData,
        });

        // Need to add faux delay to prevent Shutterstock watermark from appearing if image hasn't loaded before
        if (!isNewShutterstockAsset) {
          this.postShutterstockLoading(elementId, srcId, params.type);
        } else {
          setTimeout(() => {
            this.postShutterstockLoading(elementId, srcId, params.type);
          }, 1500);
        }
      }
    },
    postShutterstockLoading(elementId, srcId, type) {
      this.removeShutterstockLoadingId({ elementId, srcId });
      this.$root.$emit('capture-ss-asset-done', { id: srcId, type });
    },
    copyElements() {
      if (this.getActiveElements.length === 0) return;
      this.copyActiveElements(this.getActiveElements);
    },
    pasteElements() {
      if (this.getCopiedElements.length === 0) return;
      this.emptyActiveElements();
      this.pasteActiveElements();
    },
    saveProjectMac() {
      if (!isMac()) return;
      this.saveProject();
    },
    copyElementsMac() {
      if (!isMac()) return;
      this.copyElements();
    },
    pasteElementsMac() {
      if (!isMac()) return;
      this.pasteElements();
    },
    addTextToCanvas() {
      this.addTextElementToCanvas();
    },
    addTextToCanvasMac() {
      if (!isMac()) return;
      this.addTextElementToCanvas();
    },
    selectAllTextElementsMac() {
      if (!isMac()) return;
      this.selectAllTextElements();
    },
    selectAllElementsMac() {
      if (!isMac()) return;
      this.selectAllElements();
    },
    deleteLayer() {
      // console.log('deleteLayer', this.item.data.id);
      this.getActiveElements.forEach((activeElement) => {
        this.deleteElementFromCanvas(activeElement.data.id);
      });
    },
  },
  beforeMount() {
    this.initWebSocket(); // project export percentage?
    this.canvasGet();
    this.setTmpUserId();
    const isBrandedDesignTemplateTrigger = typeof this.$route.query.trigger !== 'undefined'
      && this.$route.query.trigger === 'branded-templates';
    if (isBrandedDesignTemplateTrigger) {
      this.setActiveGroup('designs');
      this.setActiveDesignTab(designTabs.BRANDEDDESIGNS);
      this.setShowDesignContainer(true);
    }
    this.getPresetFormats();
  },
  mounted() {
    if (process.env.VUE_APP_AUTOSAVE !== 'false') {
      this.setAutoSaveInterval();
    }

    CollectionsApi.getBrand('v2')
      .then((response) => {
        const defaultFont = {};
        defaultFont.h1 = {};
        defaultFont.h2 = {};
        defaultFont.h1.fontFamily = defaultFallbackFont;
        defaultFont.h2.fontFamily = defaultFallbackFont;
        const { fonts, brand_meta, colors } = response.data.results;

        this.setUserBrand(response.data.results);

        if (colors) {
          this.setColorBrand(response.data.results.colors);
        }

        // Cater for old and new brand font formats
        // With Brand Setup: brandmeta - selected_h1, selected_h2
        // OLD: ["Arial,100px,,"]
        // NEW DASHBOARD: { fontFamily: 'Arial', fontSize: 100 }
        // NEW BRANDED: ["Arial"]
        if (this.hasSetupBrand) {
          if (
            !isEmptyObject(JSON.parse(brand_meta.find(meta => meta.key === 'selected_h1').value))
          ) {
            defaultFont.h1 = JSON.parse(
              brand_meta.find(meta => meta.key === 'selected_h1').value,
            );
          }

          if (
            !isEmptyObject(JSON.parse(brand_meta.find(meta => meta.key === 'selected_h2').value))
          ) {
            defaultFont.h2 = JSON.parse(
              brand_meta.find(meta => meta.key === 'selected_h2').value,
            );
          }
        } else if (fonts.fontFamily) {
          defaultFont.h1.fontFamily = fonts.fontFamily;
          defaultFont.h2.fontFamily = fonts.fontFamily;
        } else if (JSON.stringify(fonts).indexOf(',') > -1) {
          const font = fonts[0].split(',');
          [defaultFont.h1.fontFamily] = font;
          [defaultFont.h2.fontFamily] = font;
        } else {
          [defaultFont.h1.fontFamily] = fonts;
          [defaultFont.h2.fontFamily] = fonts;
        }

        this.setDefaultFont(defaultFont);
      })
      .catch((error) => {
        console.log(error);
      });

    // this.resetMainTimeline();
    this.getMusicCategories();

    // Lite mode doesn't need elements and designs
    if (!this.isLiteMode && !this.isMobile) {
      this.getDesignCategories();
      this.getDesigns();
      // this.getTemplatePacks();
      this.getCustomElements();
      this.setUserFavourites();
    }

    document.body.addEventListener('mousedown', this.checkClickElement);

    this.$root.$on('CanvasGet', this.canvasGet);

    this.$root.$on(
      'canvas-container-reset-timeline',
      debounce(() => {
        // console.log('====canvas-container-reset-timeline resetMainTimeline');
        this.resetMainTimeline();
      }),
    );

    window.addEventListener(
      'resize',
      debounce(() => {
        // console.log('window is resizing');
        this.updateZoomAndResize();
      }, 300),
    );

    this.$nextTick(() => {
      this.updateZoomAndResize();
    });

    this.$root.$on('update-hovered', (element) => {
      this.hoveredElement = cloneDeep(element);
    });

    this.$root.$on('remove-hovered', () => {
      this.hoveredElement = {};
    });

    this.$root.$on('update-smart-guides', (lines) => {
      this.smartGuides = lines;
    });

    this.$root.$on(
      'timeline-size-update',
      debounce(() => {
        this.updateZoomAndResize();
      }, 300),
    );

    this.$root.$on('capture-ss-asset', ({
      id, type, elementData, isLiteModeReplaceImage,
    }) => {
      if (!process.env.VUE_APP_PUSHER_ENABLE) return; // socket disabled
      if (!this.offeoWebSocket || !this.offeoChanelPrefix) return;
      console.log('capture-ss-asset', id, type);

      const channelName = `capture_asset_${type}_${id}`;

      this.offeoWebSocket.channel(channelName).listen('WebSocketEvent', (res) => {
        if (res.data.success) {
          console.log('res.data', res.data, elementData, isLiteModeReplaceImage);
          if (isLiteModeReplaceImage) {
            const updatedElementData = elementData;
            updatedElementData.url = res.data.download_link;
            if (res.data.hd_link) updatedElementData.urlHd = res.data.hd_link;
            delete updatedElementData.notReady;
            this.$root.$emit('received-ss-asset', { id, type, elementData: updatedElementData });
          } else if (type === 'audio') {
            // Get everything after hash from selected music url
            // {"data":{"success":true,"download_link":"https:\/\/offeo-staging-assets.s3.us-east-2.amazonaws.com\/external-source\/audio\/shutterstock-472484.mp3","hd_link":null}}
            try {
              const selectedMusic = this.getSelectedMusic;
              if (typeof selectedMusic.url !== 'undefined') {
                const hash = selectedMusic.url.substring(selectedMusic.url.indexOf('#'));
                selectedMusic.srcId = id;
                selectedMusic.url = `${res.data.download_link}${hash}`;
                this.updateSelectedMusic(selectedMusic);
              }
            } catch (error) {
              console.error(error);
            }

            const favourites = this.getFavouritedCollection(favouriteFilters.MUSIC);
            console.log('favourites---', id, favourites);
            if (favourites.length) {
              this.removeFavouritedCollection({
                type: favouriteFilters.MUSIC,
                id: favourites[0].favId,
              });
              const element = {
                data: id,
                type: favouriteFilters.MUSIC,
              };

              FavouritesApi.addFavourite(element)
                .then((response) => {
                  if (response.data.success) {
                    this.addFavouritedCollection(response.data.results);
                  }
                }).catch((error) => {
                  console.log(error);
                });
            }
          } else if (type === 'image' || type === 'video') {
            const updatedElementData = elementData;
            updatedElementData.data.url = res.data.download_link;
            if (res.data.hd_link) updatedElementData.data.urlHd = res.data.hd_link;
            this.updateShutterstockElement({
              id: updatedElementData.data.id,
              elementData: updatedElementData,
            });
          }

          this.offeoWebSocket.leaveChannel(channelName);
        }
      });
    });

    this.$root.$on('capture-ss-asset-done', ({ id, type }) => {
      if (!process.env.VUE_APP_PUSHER_ENABLE) return; // socket disabled
      if (!this.offeoWebSocket || !this.offeoChanelPrefix) return;

      const channelName = `capture_asset_${type}_${id}`;
      this.offeoWebSocket.leaveChannel(channelName);
    });
  },
  destroyed() {
    this.$root.$off('CanvasGet', this.canvasGet);
    this.$root.$off('canvas-container-reset-timeline');
    this.$root.$off('update-hovered');
    this.$root.$off('remove-hovered');
    this.$root.$off('update-smart-guides');
    this.$root.$off('timeline-size-update');
    this.$root.$off('capture-music-asset');
    this.$root.$off('capture-music-asset-done');
  },
  watch: {
    reloadCanvas() {
      // for undo/redo animation changes
      // console.log('watcher reload canvas', this.reloadCanvas);
      if (this.reloadCanvas) {
        this.resetMainTimeline();
        this.updateReloadCanvas(false);
      }
    },
    getCurrentTime() {
      if (!this.getIsPlaying) {
        let currentAnimationTime = this.getCurrentTime;
        if (currentAnimationTime < 0.1) currentAnimationTime = 0.05;

        this.getMainTimeline.seek(currentAnimationTime);

        // console.log('getCurrentTime', !this.getIsPlaying, val, this.getMainTimeline._time);
      }
    },
    // getCurrentSceneDuration() {
    //     console.log('===getCurrentSceneDuration canvas container')
    //     clearTimeout(animationContainerTimeout);
    //     animationContainerTimeout = setTimeout(() => {
    //         this.resetMainTimeline();
    //     });
    // },
    getCanvasZoom(newCanvasScale, oldCanvasScale) {
      this.canvasScale = this.getCanvasZoom;
      if (this.$refs.canvasWorkingSpace && this.$refs.canvasWrapper) {
        this.showCanvasScene = false;
        const beforeZoomedState = this.calculateCanvasScale(newCanvasScale, oldCanvasScale);
        this.$nextTick(() => {
          this.showCanvasScene = true;
          const afterZoomedState = this.calculateCanvasScale(
            newCanvasScale,
            oldCanvasScale,
            beforeZoomedState,
          );
          this.scrollToCenter(beforeZoomedState, afterZoomedState);
        });

        if (this.zoomTimeout) clearTimeout(this.zoomTimeout);
        this.zoomTimeout = setTimeout(() => {
          if (!this.isMobile) {
            // on mobile, prevent it from keep refreshing
            this.$root.$emit('canvas-container-reset-timeline', {});
          }
        }, 1000);
      }
    },
    // getCanvasElementsAnimation: {
    //   handler: function(item) {
    //     console.log('!!! animation is updated', item)
    //     this.resetMainTimeline();
    //   },
    //   deep: true,
    // },
    getCanvasElements() {
      this.getElements();
      this.saveVideoElementsToBlob();
    },
    getCanvasSize: {
      handler() {
        console.log('canvas size is updated');
        // when canvas size is updated, auto zoom
        setTimeout(() => {
          this.updateZoomAndResize();
        }, 500);
      },
      deep: true,
    },
    showTimeline: {
      handler() {
        // use set time out to ensure timeline already done animating
        setTimeout(() => {
          this.updateZoomAndResize();
        }, 350);
      },
      immediate: false,
    },
    getActiveSceneId() {
      this.isPlaying(false); // pause the canvas when changing scene
      // console.log('!!!! NEW SCENE !!!!');

      setTimeout(() => {
        this.setCurrentTime(Math.round((this.getCurrentSceneDuration / 2) * 10) / 10);
        this.resetMainTimeline();
      }, 500);
    },
    historyType() {
      // if (!this.getSaveHistory) return;

      console.log('historyType', this.historyType);

      clearTimeout(historyTypeTimeout);
      historyTypeTimeout = setTimeout(() => {
        console.log('clear historyType');
        this.catchHistory('');
      }, 500);
    },
    showStoryboardOverlay(val) {
      if (!val) {
        // when show storyboard is closed, recalculate the zoom
        this.updateZoomAndResize();
      }
    },
    overlayStyleObject() {
      setTimeout(() => {
        this.canvasWorkingSpaceOverflow = false;
        const canvasContainer = document.getElementById('main-canvas-container');
        const { canvasWorkingSpace } = this.$refs;

        if (
          canvasContainer.clientWidth > canvasWorkingSpace.clientWidth
          || canvasContainer.clientHeight > canvasWorkingSpace.clientHeight
        ) {
          this.canvasWorkingSpaceOverflow = true;
        }
      }, 200);
    },
    teamBrands: {
      handler(val) {
        if (val.allIds.length && this.isInTeamWorkspace) {
          this.initTeamCustomFonts(this.teamId, this.teamBrands.allIds);
          this.fetchTeamCustomFonts();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.canvas-working-space {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.canvas-working-space-overflow {
  overflow: scroll;
}

.canvas-wrapper {
  height: fit-content;
  width: fit-content;
  margin: auto;
  padding: 48px;
  position: relative;
  overflow: visible; //for the overlay to be seen
  transition: width 0.25s ease-in-out, height 0.25s ease-in-out, transform 0.25s ease-out,
    opacity 0.15s ease-out;
  display: flex;
  justify-content: space-around;

  &.move-up {
    transform: translateY(-50px);
    opacity: 0;
    transition: transform 0.25s ease-out, opacity 0.15s 0.1s ease-out;
  }

  &.move-down {
    transform: translateY(50px);
    opacity: 0;
    transition: transform 0.25s ease-out, opacity 0.15s 0.1s ease-out;
  }
}

#main-canvas-container {
  position: relative;
  display: inline-block;
  user-select: none;
  transform-origin: top center;

  &.smooth-transition {
    .canvas-element {
      transition: 0.3s;
    }
  }
}

.canvas-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  background: #fff;
  transform-origin: top left;

  .scene {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    max-width: 100%;
  }
}

.canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 11;
  pointer-events: none;
  overflow: visible;
  white-space: nowrap;

  & > *:not(.canvas-grid):not(.canvas-title-safe) {
    pointer-events: auto;
  }
}
</style>
