<template lang="pug">
.item-audio(
  :class="{ 'is-upload': isUploadMusicTab, 'is-downloading' : isDownloading, 'is-chosen': isSelected }"
)
  .music-info(
    ref="musicInfo"
    :class="{'is-loading': isLoading}"
    @mouseenter="scrollTitleIfOverflownX(true)"
    @mouseleave="scrollTitleIfOverflownX(false)"
    @click="playAudio"
  )

    BasePreloader(
      v-if="isDownloading"
    )

    .music-info__loop-wrapper
      .music-info__loop(
        ref="musicLoop"
        :class="{ 'is-looping': isLooping }"
      )
        span.music-title(
          ref="musicTitle"
          v-html="musicTitle"
        )

  i.icon-checked.selected-music-icon(v-if="isSelected")

  div.audio-item__loader-container(v-else-if="isLoading")
    Lottie.loader__animation(:options="canvasLoaderOptions")

  div.action-buttons(
    v-else
    :class="{ 'is-playing': isAudioPlaying }"
  )
    AssetsFavourite(
      v-if="!isLiteMode && isLoggedIn && !noFavourite"
      :type="favType"
      :id="id"
      :favId="tempFavId"
      :isFavourite="isFavourite"
      :remarks="favouriteRemarks"
      @favourited="updateFavId"
      @assets-favourite-added="captureAssets"
    )

    template(v-if="!isDownloading")
      div.play-button__container
        Lottie.playing-button__background(
          v-if="isAudioPlaying"
          :width="40"
          :options="playingMusicAnimationOptions"
        )
        button.btn-play-audio.js-play-audio(
          :class="{ 'is-playing': isAudioPlaying }"
          type='button'
          @click="togglePlay"
        )
          i.icon.icon-play
          span.transparent-icon-background

      template(v-if="isUploadMusicTab")
        DeleteItem(
          :itemId="id"
        )
      button.btn.btn--hollow.btn-use-audio(
        type='button'
        @click="useMusic"
      ) Use
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import AssetsFavourite from '@/components/ProjectCanvas/AssetsSidebar/children/AssetsFavourite.vue';
import DeleteItem from '@/components/ProjectCanvas/AssetsSidebar/children/DeleteItem.vue';
import Lottie from '@/components/ProjectCanvas/AssetSidebarCanvas/LottieShared.vue';

import { groups, subgroups } from '@/assets/scripts/enums';
import rotatingColorWheelJson from '@/assets/data/rotating-color-wheel.json';
import CanvasLoader from '@/assets/data/canvas-loader.json';

import Api from '@/services/api/Api';

import websocketMixins from '@/components/mixins/websocket-mixins';

export default {
  name: 'MusicItem',
  mixins: [websocketMixins],
  props: {
    file: { type: String, default: '' },
    fileName: { type: String, default: '' },
    title: { type: String, default: '' },
    artist: [String, Array],
    activeUrl: { type: String, default: '' },
    defaultStartTime: { type: Number, default: 0 },
    id: { type: Number, required: true },
    favId: { type: Number, default: 0 },
    genres: { type: Array, default: () => [] },
    moods: { type: Array, default: () => [] },
    isDownloading: { type: Boolean, default: false }, // to know in favourites is still in capture assets or downloaded already.
    isUserFiles: { type: Boolean, default: false }, // is user's uploaded music files ( used on favourite tab )
    noFavourite: { type: Boolean, default: false },
    isTeam: { type: Boolean, default: false },
  },
  data() {
    return {
      tempFavId: this.favId,
      favType: 'audio',
      isLooping: false,
      isLoading: false,
      isOverflownX: false,
    };
  },
  components: {
    AssetsFavourite,
    DeleteItem,
    Lottie,
  },
  computed: {
    ...mapState(['isLoggedIn', 'favouritedCollection']),
    ...mapGetters(['isLiteMode']),
    ...mapGetters('canvasElements', ['getSelectedMusic', 'getAllScenesDuration']),
    ...mapGetters('assetsSidebar', [
      'getActiveGroup',
      'getActiveSubGroup',
      'getShutterstockSearchId',
      'getStockEndpointVersion',
    ]),
    isUploadMusicTab() {
      return (
        !this.isLiteMode
        && this.getActiveGroup === groups.MUSIC
        && this.getActiveSubGroup === subgroups.UPLOAD
      );
    },
    downloadLink() {
      return this.file;
      // const uploadArr = JSON.parse(this.upload);
      // const downloadLink = uploadArr[0].download_link.replace(/\\/g,"/");

      // return downloadLink;
    },
    url() {
      return this.file;
      // const audioUrl = `${homeUrl()}/public/storage/${this.downloadLink}`;
      // return audioUrl;
    },
    isAudioPlaying() {
      if (!this.activeUrl) return false;
      if (`${this.activeUrl}` === this.url) return true;
      if (`${this.activeUrl}` === `${this.url}#t=${this.defaultStartTime}`) return true; // or has default start time
      return false;
    },
    isSelected() {
      if (!this.getSelectedMusic.url) return false;
      if (
        this.getSelectedMusic.url.indexOf(this.downloadLink) >= 0
        || this.getSelectedMusic.srcId === this.id
      ) return true;
      return false;
    },
    isOnFavTab() {
      return this.getActiveGroup === groups.FAVOURITES;
    },
    isFavourite() {
      if (this.getActiveGroup === groups.FAVOURITES) return true;
      return !!(this.getFavouriteElement() && this.getFavouriteElement().id);
    },
    playingMusicAnimationOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: rotatingColorWheelJson,
      };
    },
    canvasLoaderOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: CanvasLoader,
      };
    },
    favouriteRemarks() {
      try {
        return {
          title: this.title,
          artist: this.artist[0].name || null,
        };
      } catch (error) {
        return null;
      }
    },
    subTitle() {
      return this.isDownloading ? 'Downloading...' : this.subtitle;
    },
    musicTitle() {
      const title = this.isTeam && this.fileName ? this.fileName : this.title;
      return this.isDownloading ? `Downloading... ${title}` : title;
    },
    getCurrentMusicSettings() {
      let currentMusicSettings = this.getSelectedMusic;

      if (!currentMusicSettings || currentMusicSettings.length === 0) {
        // currentMusicSettings sometimes returns as [] instead of Object
        // due to BE sending back audio as [] if user has removed audio
        // if it is [], change it back to this settings
        currentMusicSettings = {
          volume: 100,
          fade_in: false,
          fade_out: false,
        };
      }

      return currentMusicSettings;
    },
  },
  methods: {
    ...mapMutations([
      'isPlaying',
      'setIsShowMusicToolbarTrimmer',
      'setShowPermanentTrimElement',
    ]),
    ...mapMutations('canvasElements', ['updateSelectedMusic', 'emptyActiveElements']),
    togglePlay() {
      this.$emit('play-audio', `${this.url}#t=${this.defaultStartTime}`);
    },
    // eslint-disable-next-line
    playAudio() {
      if (this.isAudioPlaying || this.isSelected) return false;

      this.togglePlay();
    },
    async useMusic() {
      this.isLoading = true;
      this.isPlaying(false);

      const musicUrl = `${this.url}#t=${this.defaultStartTime}`;
      const musicObject = {
        srcId: this.id,
        url: musicUrl,
        title: this.title,
        artist: this.artist,
        fade_in: this.getCurrentMusicSettings.fade_in,
        fade_out: this.getCurrentMusicSettings.fade_out,
        volume: this.getCurrentMusicSettings.volume,
        genres: this.genres,
        moods: this.moods,
      };

      this.captureAssets(musicObject, true);

      console.log('musicUrl', musicObject.url);
      this.updateSelectedMusic(musicObject);
    },
    async captureAssets(musicObject = {}, isUsingNewMusic) {
      console.log('do captureAssets', musicObject);
      // capture assets if v2
      if (this.getStockEndpointVersion === 'v2' && !this.isUploadMusicTab && !this.isUserFiles && !this.isTeam) {
        const params = {
          src_id: this.id,
          type: 'audio',
          search_id: this.getShutterstockSearchId,
          info: {
            title: this.title,
            artist: this.artist,
            genres: this.genres,
            moods: this.moods,
          },
        };

        this.$root.$emit('capture-ss-asset', { id: params.src_id, type: params.type });

        if (musicObject && isUsingNewMusic) {
          // use shutterstock url first, otherwise it will have 3-5 sec wait
          this.updateSelectedMusic(musicObject);
        }

        const assetData = await Api.captureAssets(params, this.getStockEndpointVersion);

        if (assetData.data.results.exists) {
          // cover AudioTrimControl.vue issue where it loads forever because can't play the music / got 'null' audio url
          if (assetData.data.results.url) {
            // eslint-disable-next-line no-param-reassign
            musicObject.url = `${assetData.data.results.url}#t=${this.defaultStartTime}`;
          }

          this.$root.$emit('capture-ss-asset-done', { id: params.src_id, type: params.type });
        }
      }

      // "isUsingNewMusic" params to make sure to not replace current selected music when favouriting music
      if (isUsingNewMusic) {
        await this.resetTrimmer(); // reset music trimmer before select a new music to trim, to ensure "AudioTrimControl.vue" in "ControlMusic.vue" mount/reload again.
        this.updateSelectedMusic(musicObject);
      }

      this.emptyActiveElements();

      this.isLoading = false;
    },
    resetTrimmer() {
      this.updateSelectedMusic({});
      this.setIsShowMusicToolbarTrimmer(false);
      this.setShowPermanentTrimElement(false);
    },
    getFavouriteElement() {
      const collection = this.favouritedCollection[this.favType];

      if (!collection || collection.length === 0) return false;

      // it may be comparing string and int
      // check whether item exist since getFavourite getters will also check that
      const favourite = collection.find(
        // eslint-disable-next-line
        element => element.element_id == this.id && !!element.item,
      );

      return favourite;
    },
    updateFavId(id) {
      this.tempFavId = id;
    },
    scrollTitleIfOverflownX(start) {
      if (this.isOverflownX) this.isLooping = start;
    },
    repeatContent(till) {
      const { musicTitle } = this.$refs;
      const html = musicTitle.innerHTML;
      let counter = 0; // prevents infinite loop

      while (musicTitle.offsetWidth < till && counter < 100) {
        musicTitle.innerHTML += html;
        counter += 1;
      }
    },
  },
  mounted() {
    const { musicInfo, musicLoop } = this.$refs;
    this.isOverflownX = musicInfo.scrollWidth > musicInfo.clientWidth;

    if (this.isOverflownX) {
      this.repeatContent(musicInfo.offsetWidth);
      musicLoop.innerHTML += musicLoop.innerHTML;
    }

    if (this.isOnFavTab) {
      this.tempFavId = this.favId || 0;
    } else {
      this.tempFavId = this.isFavourite ? this.getFavouriteElement().id : 0;
    }
  },
};
</script>

<style lang="scss">
.item-audio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;

  &:hover {
    .action-buttons {
      width: auto;
      opacity: 1;
      visibility: visible;
    }
  }

  &.is-chosen {
    cursor: default;

    .music-title {
      color: $lightGreen2;
    }
  }

  .btn-favourite {
    right: 70px;
    margin: 0 5px;
  }

  .btn-use-audio {
    padding: 6px 4px;
    width: 50px;
    color: $lightWhite;
    background-color: $darkGrey2;
    border: 0;

    &:hover {
      background-color: $darkBg;
      color: $lightWhite;
    }
  }

  .selected-music-icon {
    color: $lightGreen2;
  }

  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    opacity: 0;
    visibility: 0;
    width: 0;
    padding-left: 15px;

    .btn-delete {
      color: $btnDangerHover;
      padding-left: 0;
      font-size: 1.4375rem;
      display: flex;
      align-items: center;
    }

    &.is-playing {
      width: auto;
      opacity: 1;
      visibility: visible;
    }
  }

  &.is-downloading {
    .music-info {
      padding-left: 40px;
      .preloader {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        .preloader__image {
          width: 30px;
        }
      }

      .music-title {
        opacity: 0.3;
      }

      .play-button__container {
        button {
          opacity: 0.3;
          pointer-events: none;
        }
      }
    }
  }

  .audio-item__loader-container {
    margin-left: auto;
    margin-right: -10px;

    .loader__animation {
      width: 38px !important;
      height: 38px !important;
    }
  }
}

.play-button__container {
  position: relative;
  width: 40px;
  height: 40px;

  .playing-button__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .btn-play-audio {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
    background: none;
    font-size: 1.75rem;
    cursor: pointer;
    padding: 0;
    color: $lightWhite;
    display: flex;
    align-items: center;
    transition: color 0.25s ease-in-out;

    // to add black/white background in pause button with smaller size than it's icon
    .transparent-icon-background {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 11px;
      height: 11px;
      background: $black;
      border-radius: 50%;
    }

    &:hover {
      color: $blue;

      .transparent-icon-background {
        background: $light;
      }
    }

    &.is-playing {
      color: $lightWhite;
      margin-right: 5px;
      font-size: 1.5rem;

      .icon::before {
        content: $icon-pause;
      }

      .transparent-icon-background {
        background: $black;
      }
    }

    .icon {
      z-index: 3;
      position: relative;
    }
  }
}

.grid-lists--music,
.selected-music {
  .music-info {
    width: calc(100% - 30px);
    overflow: hidden;

    @include fb-requirement {
      width: calc(100% - 85px);
      text-overflow: ellipsis;
    }

    &.is-loading {
      margin-right: 10px;
    }

    .music-info__loop-wrapper {
      display: inline-block;

      .music-info__loop {
        white-space: nowrap;

        &.is-looping {
          animation: loopLeft 10s linear infinite;
        }
      }
    }
  }
}

.music-title {
  color: $lightWhite;
  font-weight: 400;
  line-height: 2;
  padding-right: 20px;
  font-size: 0.8125rem;
}

.music-subtitle {
  display: block;
  color: $textGrey;
  line-height: 1;
  font-size: 0.6875em;
}

@keyframes loopLeft {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -50%;
  }
}
</style>
