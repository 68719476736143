<template lang="pug">
  div
    .my-file__empty-state(v-if="!isUploadingFile")
      .dropzone__container
        span
          | Drag or Click here
          br
          | to upload your media

        input(
          autocomplete="off"
          type="file"
          id="manual-file-upload"
          ref="fileUpload"
          :accept="fileAccept"
          multiple
          @change="doSelectFile"
        )

      h3.format-description Format: {{ allowedFormatType }}

    .empty-state__skeleton(v-else)
      SkeletonItem

</template>

<script>
import { isEmptyObject } from '@/assets/scripts/utilities';
import SkeletonItem from './SkeletonItem.vue';
import uploadingMixins from '@/components/mixins/uploading-mixins';

export default {
  mixins: [uploadingMixins],
  components: {
    SkeletonItem,
  },
  computed: {
    isUploadingFile() {
      return !isEmptyObject(this.skeletonList);
    },
    allowedFormatType() {
      let format = '';
      if (this.getActiveSubGroup === 'images' || this.getActiveSubGroup === 'logos') {
        format = 'JPG, JPEG , PNG';
      } else if (this.getActiveSubGroup === 'videos') {
        format = 'MP4, MOV, WEBM';
      }
      return format;
    },
  },
};
</script>

<style lang="scss">
.my-file__empty-state {
  text-align: center;
  margin-top: 15px;
  padding: 0 10px;

  .dropzone__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 175px;
    background: $darkGrey600;
    border: 2px dashed $darkGrey100;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background: $lightGrey6;
      box-shadow: 7px 9px 7px 0px rgb(0 0 0 / 15%);
    }

    span {
      font-size: 13px;
      line-height: 18px;
      color: $light;
    }
  }

  input[type='file'] {
    width: 100%;
    cursor: pointer;
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .format-description {
    color: $light;
    font-weight: 100;
    font-size: 10.5px;
  }
}

.empty-state__skeleton {
  margin-top: 10px;

  .category-item--skeleton {
    height: 100px;
    margin: 0 2px;
    width: 50%;
  }
}

</style>
