<template lang="pug">
.ruler__section(:class="{ 'is-max': index > max }")
  span.ruler__main(:data-second="index" :style="rulerMargin")
  .ruler-small__wrapper(:class="{ 'is-max': index >= max }")
    span.ruler__small(:style="rulerMargin")
    span.ruler__small(:style="rulerMargin")
    span.ruler__small(:style="rulerMargin")
    span.ruler__small(:style="rulerMargin")
    span.ruler__small(:style="rulerMargin")
    span.ruler__small(:style="rulerMargin")
    span.ruler__small(:style="rulerMargin")
    span.ruler__small(:style="rulerMargin")
    span.ruler__small(:style="rulerMargin")
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RulerSection',
  props: {
    index: { type: Number, default: 0 },
    max: { type: Number, default: 30 },
  },
  computed: {
    ...mapGetters('canvasLayouts', [
      'timelineZoomLevel',
      'timelineMargin',
    ]),
    rulerMargin() {
      const styleObject = {};
      styleObject.marginRight = `${this.timelineMargin}px`;

      if (this.timelineZoomLevel === -4) {
        styleObject.width = '1px';
      }

      return styleObject;
    },
  },
};
</script>

<style lang="scss">
.ruler__section {
  display: inline-flex;
  position: relative;

  &.is-hidden {
    visibility: hidden;
  }

  &.is-max {
    .ruler__main {
      &:after {
        opacity: 0.1;
      }
    }
  }
}

.ruler__main,
.ruler__small {
  background: var(--darkgrey800-timelineruler);
  height: 4px;
  width: 2px;
  min-width: 1px;
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  transform: translateX(-100%);
  cursor: default;
  pointer-events: none;
  font-size: 0.875rem;
  font-weight: 600;
}

.ruler__main {
  height: 10px;

  .ruler__section:not(:first-child) &::after {
    content: attr(data-second);
    font-size: 0.875em;
    color: var(--black-white);
    bottom: -14px;
    position: absolute;
    cursor: default;
    pointer-events: none;
  }

  &:first-of-type::after {
    transform: translateX(0);
    left: 4px;
  }
}

.ruler-small__wrapper {
  display: inline-flex;

  &.is-hidden {
    visibility: hidden;
  }
}
</style>
