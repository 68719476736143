<template lang="pug">
.right-sidebar(
  ref="rightSidebar"
  :class="{ 'is-collapsed': !showLayer || isLiteMode }"
)
  BaseButton.right-sidebar__storyboard-button(
    :class="{'is-dark': isDarkMode}"
    is-primary=true
    is-full-width=true
    plain-hover=true
    icon-before="icon-storyboard-lite"
    @click="showStoryboardOverlay()"
  )
    p Scenes
    div.white-icon-background(v-if="isDarkMode")


  .right-sidebar__container
    //- keep layerContainer for duplicate layer
    LayerContainer
  template(v-if="!isLiteMode")
    .layer-tags(
      v-if="isTemplate && showTagManager && getActiveElements.length >= 1"
    )
      LayerTag(
        v-for="tag in visibleLayerTags"
        :key="tag.value"
        :value="tag.value"
        :name="tag.name"
        @click="setLayerTag(tag.value)"
      )
      button.btn.layer-tag.layer-tag--clear(
        type="button"
        @click="clearLayerTag"
      ) Clear

</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import { layerTagsArray } from '@/assets/scripts/variables';
import LayerContainer from '@/components/ProjectCanvas/LayerContainer/LayerContainer.vue';
import LayerTag from '@/components/ProjectCanvas/LayerContainer/children/LayerTag.vue';

import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'RightSidebar',
  data() {
    return {
      layerTags: [],
    };
  },
  mixins: [darkModeMixin],
  components: {
    LayerContainer,
    LayerTag,
  },
  mounted() {
    this.setRightSidebarWidth(this.$refs.rightSidebar.offsetWidth);
  },
  computed: {
    ...mapState(['isTemplate', 'showTagManager', 'showLayer']),
    ...mapGetters(['isLiteMode']),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getActiveElementsIds',
      'getActiveSceneId',
      'getHeroSceneWithBg',
      'getHeroSceneWithoutBg',
    ]),
    hasMoreThanOneElement() {
      return this.getActiveElements.length !== 1;
    },
    hasNonTextElements() {
      const hasNonText = this.getActiveElements.findIndex(el => el.type !== 'texts') >= 0;

      return hasNonText;
    },
    visibleLayerTags() {
      let { layerTags } = this;

      // only show overlay, fg, bg on active hero scene
      if (
        this.getActiveSceneId !== this.getHeroSceneWithBg.id
        && this.getActiveSceneId !== this.getHeroSceneWithoutBg.id
      ) {
        layerTags = layerTags.filter(
          tag => tag.value !== 'overlay' && tag.value !== 'fg' && tag.value !== 'bg',
        );
      }

      // only show h1, h2, h3 on text layer
      // and only if it's one layer
      if (this.hasNonTextElements || this.hasMoreThanOneElement) {
        layerTags = layerTags.filter(
          tag => tag.value !== 'h1' && tag.value !== 'h2' && tag.value !== 'h3',
        );
      }

      return layerTags;
    },
  },
  methods: {
    ...mapMutations('canvasLayouts', ['setRightSidebarWidth']),
    ...mapMutations('canvasElements', [
      'emptyActiveElements',
      'updateLayerTag',
      'clearAllLayerTag',
    ]),
    ...mapActions(['updateShowStoryboardOverlay']),
    setLayerTag(value) {
      this.updateLayerTag({
        ids: this.getActiveElementsIds,
        layerTag: value,
      });
    },
    clearLayerTag() {
      this.clearAllLayerTag(this.getActiveElementsIds);
    },
    showStoryboardOverlay() {
      this.updateShowStoryboardOverlay(true);
      // stop all audio
      this.$root.$emit('pause-all-audio');
      // remove all active elements
      this.emptyActiveElements();
    },
  },
  beforeMount() {
    this.layerTags = layerTagsArray;
  },
};
</script>

<style lang="scss">
.right-sidebar {
  display: flex;
  flex-direction: column;
  height: 93.5%;
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
  z-index: 7;
  transition: transform 0.25s ease-in-out;

  .lite-mode & {
    border-left-color: $lightGrey100;
    width: 202px;

    @include fb-requirement {
      width: 152px;
    }
  }

  &.is-collapsed {
    transform: translateX(100%);

    .right-sidebar__storyboard-button {
      transform: translateX(-100%);
    }
  }
}

.right-sidebar__storyboard-button {
  height: 55px;
  padding: 10px 15px;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: $light !important;
  transition: background 0.3s ease-in-out, transform 0.25s ease-in-out;
  position: relative;

  &:hover {
    background: $blue800;
  }

  &.is-dark {
    background: $darkGrey700;

    .icon {
      color: $blue700;
    }

    &:hover {
      background: $darkGrey5;
    }
  }

  .btn__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn__circle {
    display: none;
  }

  // to add white background in play/pause button with smaller size than it's icon
  .white-icon-background {
    position: absolute;
    z-index: 1;
    top: 3px;
    left: -25px;
    width: 8px;
    height: 8px;
    background: $light;
    border-radius: 50%;
  }

  .icon {
    font-size: 1.375rem;
    margin-right: 10px;
    z-index: 3;
    position: relative;
  }

  p {
    margin: 0;
    font-weight: 500;
  }

  span {
    font-size: 0.875rem;
  }
}

.right-sidebar__container {
  display: flex;
  align-items: center;
  height: calc(100% - 57px);
  position: relative;
}

.layer-tags {
  background: $light;
  position: absolute;
  top: 53px;
  left: -121px;
  width: 120px;
  padding: 20px;
  border-radius: $componentBorderRadius;
  box-shadow: 0px 18px 18px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
}
</style>
