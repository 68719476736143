<template lang="pug">
  div
    ReplaceImagesOverlay(
      v-if="showLiteReplaceModal"
      title="Select an image"
      subtitle=""
      :preSelectedTab="currentTab"
      :isLiteVersion="true"
      :isShow="showLiteReplaceModal"
      :isLoading="isWaitingOnStock"
      @closeOverlay="setShowLiteReplaceModal(false)"
      @selected-image-object="selectImageAndClose"
      @change-tab="changeTab"
    )
    RemoveBgOption(
      v-if="showRemoveBgOption"
      :isLoading="isConverting || isWaitingOnStock"
      @confirm="checkImageAndConfirmRemoveBg"
      @close="setShowRemoveBgOption(false)"
      @back="cancelRemoveBg"
    )
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { elementFormat } from '@/assets/scripts/variables';
import { randomId, getImageSize } from '@/assets/scripts/utilities';

import ReplaceImagesOverlay from '@/components/SmartCreate/PageOverlay/ReplaceImagesOverlay.vue';
import RemoveBgOption from '@/components/SmartCreate/PageOverlay/RemoveBgOption.vue';
import uploadPermutationMixin from '@/components/mixins/upload-permutation-mixins';

import Api from '@/services/api/Api';

export default {
  name: 'LiteModeReplaceImage',
  mixins: [
    uploadPermutationMixin,
  ],
  components: {
    ReplaceImagesOverlay,
    RemoveBgOption,
  },
  data() {
    return {
      /**
       * expected format
       * replaceImageData: {
       *  url: '',
       *  urlHd: '',
       *  thumb: '',
       *  id: '', // srcId
       *  elementId: '',
       * }
       */
      replaceImageData: {},
      isConverting: false,
      currentTab: 'sample',
      isWaitingOnStock: false,
    };
  },
  computed: {
    ...mapState([
      'showLiteReplaceModal',
      'isTemplate',
    ]),
    ...mapState('inspirationList', ['showRemoveBgOption']),
    ...mapGetters(['isLiteMode']),
    ...mapGetters('assetsSidebar', [
      'getShutterstockSearchId',
      'getStockEndpointVersion',
    ]),
    ...mapGetters('canvasElements', [
      'getProjectId',
      'getActiveElements',
      'getCurrentSceneDuration',
      'getCanvasSize',
    ]),
    activeElement() {
      return this.getActiveElements[0];
    },
    isActiveElementText() {
      return this.activeElement && this.activeElement.type === 'texts';
    },
    isActiveElementMask() {
      return this.activeElement && this.activeElement.type === 'masks';
    },
  },
  mounted() {
    this.$root.$on('received-ss-asset', (data) => {
      const { id, type, elementData } = data;
      console.log('received-ss-asset', id, type, elementData);
      this.isWaitingOnStock = false;
      this.handleReplaceImageData(elementData);
      this.$root.$emit('capture-ss-asset-done', { id, type });
    });
  },
  methods: {
    ...mapMutations(['setShowLiteReplaceModal']),
    ...mapMutations('canvasElements', [
      'insertElementToCanvas',
      'updateRemoveBgElement',
      'addActiveElements',
      'emptyActiveElements',
    ]),
    ...mapMutations('inspirationList', [
      'setShowRemoveBgOption',
    ]),
    ...mapMutations('userData', [
      'setRemoveBgCredits',
    ]),
    ...mapActions('canvasElements', [
      'confirmedRemoveBgElement',
      'confirmSwappedLiteImage',
      'addElementToCanvas',
    ]),
    changeTab(tab) {
      this.isWaitingOnStock = false;
      this.currentTab = tab;
    },
    async selectImageAndClose(selectedImageObject) {
      let replaceImageData = {}; // clear the data

      if (!this.isActiveElementText) {
        replaceImageData.elementId = this.activeElement ? this.activeElement.data.id : 0;
      }

      if (selectedImageObject.id) {
        replaceImageData.id = selectedImageObject.id;
      }

      if (typeof selectedImageObject.urls !== 'undefined') {
        // stock photos
        replaceImageData.urlHd = selectedImageObject.urls.raw || selectedImageObject.urls.full || selectedImageObject.urls.regular || '';
        replaceImageData.url = selectedImageObject.urls.regular || selectedImageObject.urls.small || selectedImageObject.urls.thumb || '';
        replaceImageData.thumb = selectedImageObject.urls.small || selectedImageObject.urls.thumb || '';
      } else {
        /* users_files
         {
          "id": 367178,
          "user_id": 1,
          "folder_id": null,
          "file": "https://offeo-staging-assets.s3.us-east-2.amazonaws.com/user-uploads/1/image/1-850pQjAIHo.png",
          "preview_url": "https://offeo-staging-assets.s3.us-east-2.amazonaws.com/user-uploads/1/image/1-PbY0fLkfPn.png",
          "ext": "png",
          "is_preparing": false,
          "has_removed_bg": false
        }
       */
        // user files
        replaceImageData.urlHd = selectedImageObject.file;
        replaceImageData.url = selectedImageObject.file;
        replaceImageData.thumb = selectedImageObject.preview_url;
        replaceImageData.has_removed_bg = selectedImageObject.has_removed_bg || false;
      }

      if (this.currentTab === 'stocks') {
        // is stock media
        // capture assets
        const params = {
          src_id: replaceImageData.id,
        };

        if (this.getStockEndpointVersion === 'v1') {
          params.data_url = replaceImageData.url;
          params.data_urlhd = replaceImageData.urlHd || '';

          Api.captureAssets(params, this.getStockEndpointVersion);
        } else if (this.getStockEndpointVersion === 'v2') {
          replaceImageData = await this.captureShutterstockAssetAndUpdateElement(params, replaceImageData);
        }
      }

      console.log('2 replaceImageData.urlHd', replaceImageData.urlHd, replaceImageData.notReady);

      if (!(this.currentTab === 'stocks' && replaceImageData.notReady)) {
        // if it's not stock and there is no ready
        this.handleReplaceImageData(replaceImageData);
      } else {
        this.isWaitingOnStock = true;
      }
    },
    handleReplaceImageData(replaceImageData) {
      console.log('~handleReplaceImageData', replaceImageData.url);
      this.replaceImageData = replaceImageData;

      if (!this.activeElement) {
        // if there is no element, it means need to add new one
        this.addNewElement(this.replaceImageData);
      } else if (!this.replaceImageData.has_removed_bg && !this.isActiveElementMask) {
        // show remove bg if it hasn't been removed yet
        this.setShowRemoveBgOption(true);
      } else {
        // if it has no background, swap the element
        this.$root.$emit('lite-image-replaced', this.activeElement.data.id);
        this.confirmSwappedLiteImage(this.replaceImageData);
        this.retriggerActiveElement();
      }
      this.setShowLiteReplaceModal(false);
    },
    async captureShutterstockAssetAndUpdateElement(defaultParams, replaceImageData) {
      const elementData = { ...replaceImageData };
      const params = { ...defaultParams };
      params.type = 'image';
      params.search_id = this.getShutterstockSearchId;

      this.$root.$emit('capture-ss-asset', {
        id: params.src_id, type: params.type, elementData, isLiteModeReplaceImage: true,
      });

      const assetData = await Api.captureAssets(params, this.getStockEndpointVersion);

      console.log('0 assetData.data.results', assetData.data.results);

      if (assetData.data.results.exists) {
        elementData.urlHd = assetData.data.results.urlHd;
        elementData.url = assetData.data.results.url;
        elementData.thumb = assetData.data.results.thumb;
      // eslint-disable-next-line no-else-return
      } else {
        elementData.notReady = true;
      }

      return elementData;
    },
    addNewElement(element) {
      // this is for adding image
      const elementData = JSON.parse(JSON.stringify(elementFormat));

      elementData.menu = this.getActiveGroup;

      if (element.id) {
        elementData.file_id = element.id;
        // for stock image src_id
        if (this.currentTab === 'stocks') elementData.data.src_id = element.id;
      }

      elementData.data.has_removed_bg = !!element.has_removed_bg;
      elementData.type = 'images';
      elementData.isImage = true;

      elementData.time_out = this.getCurrentSceneDuration;
      elementData.timeline_settings.animationDuration = elementData.time_out - elementData.time_in;
      elementData.timeline_settings.animateOutStart = elementData.time_out - elementData.timeline_settings.animateOutDuration;

      elementData.filters = {};

      elementData.data.thumb = element.thumb;
      elementData.data.url = element.url;
      elementData.data.urlHd = element.urlHd;

      elementData.blend = '';
      elementData.tint = 0;
      elementData.filters = {
        blur: 0,
        brightness: 1,
        contrast: 1,
        hue: 0,
        name: '',
        saturate: 1,
        sepia: 0,
      };
      elementData.data.image = {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      };

      elementData.data.name = 'Image';

      getImageSize(elementData.data.url, (imageWidth, imageHeight) => {
        const maxWidth = this.getCanvasSize.width * 0.75;
        const maxHeight = this.getCanvasSize.height * 0.75;

        const maxSize = maxWidth > maxHeight ? maxHeight : maxWidth;// get the lowest value;

        let imageRatio = 1;
        let newImageWidth = imageWidth;
        let newImageHeight = imageHeight;

        // set minimum width to 0.75 of canvas first
        // don't allow image added to be too small, eg 100px
        if (imageWidth < maxSize) {
          imageRatio = maxSize / newImageWidth;
          newImageWidth = maxSize;
          newImageHeight = imageHeight * imageRatio;
        }

        // need to set maximum size to 0.75 of canvas
        // don't allow image added to be too big
        if (imageWidth > maxSize || imageHeight > maxSize) {
          if (imageWidth > maxSize) {
            imageRatio = maxSize / newImageWidth;
            newImageWidth = maxSize;
            newImageHeight = imageHeight * imageRatio;
          }
          if (newImageHeight > maxSize) {
            // vertical
            imageRatio = maxSize / newImageHeight;
            newImageHeight = maxSize;
            newImageWidth *= imageRatio;
          }
        }

        elementData.size = {
          width: newImageWidth,
          height: newImageHeight,
        };

        elementData.position = {
          x: this.getCanvasSize.width / 2,
          y: this.getCanvasSize.height / 2,
        };

        this.emptyActiveElements();
        console.log('elementData', elementData);
        setTimeout(() => {
          elementData.data.id = randomId();
          this.addElementToCanvas(elementData);

          if (elementData.data.src_id) {
            // for stock
            const { data } = elementData;
            const params = {
              src_id: elementData.data.src_id,
              data_url: data.url,
              data_urlhd: data.urlHd || '',
            };

            Api.captureAssets(params)
              .then(() => {
                // console.log(response);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }, 100);
      });
    },
    cancelRemoveBg() {
      this.setShowLiteReplaceModal(true);
      this.setShowRemoveBgOption(false);
    },
    retriggerActiveElement() {
      // after swap, resizer overlay doesn't refresh the width and height
      // this is a cheat
      const id = this.replaceImageData.elementId || 0;
      if (id) {
        setTimeout(() => {
          this.emptyActiveElements();
          this.addActiveElements(id);
        }, 750);
      }
    },
    checkImageAndConfirmRemoveBg(removeBg) {
      if (!removeBg) {
        // user want to keep background
        this.$root.$emit('lite-image-replaced', this.activeElement.data.id);
        this.confirmSwappedLiteImage(this.replaceImageData);
        this.setShowRemoveBgOption(false);
        this.retriggerActiveElement();
      } else {
        // user want to remove background
        this.updateRemoveBgElement(this.activeElement);
        this.removeBackgroundAndUpload();
      }
    },
    removeBackgroundAndUpload() {
      this.isConverting = true;
      console.log('removeBackgroundAndUpload', this.isConverting);
      const params = {
        imports: {
          isTemplate: this.isTemplate,
          projectId: this.getProjectId || '',
          file_url: this.replaceImageData.urlHd || this.replaceImageData.url,
        },
      };
      this.$root.$emit('lite-image-replaced', this.activeElement.data.id);

      Api.removeBg(params)
        .then((response) => {
          if (response.data.success) {
            const { user_files, remove_bg_credits } = response.data.results;
            console.log('success', this.isConverting, response.data);
            if (user_files) {
              this.alertSuccess('', 'Remove background success, updating canvas...', 5000, 'TopCenterNotif');
              this.setRemoveBgCredits(remove_bg_credits);

              const element = {
                url: user_files.file,
                urlHd: user_files.file_hd,
                thumb: user_files.preview_url,
                has_removed_bg: true,
                elementId: this.activeElement.data.id,
              };
              this.confirmSwappedLiteImage(element)
                .then(() => {
                  this.isConverting = false;
                  this.setShowRemoveBgOption(false);
                  this.retriggerActiveElement();
                });
            }
          } else {
            console.log('else', this.isConverting);
            this.isConverting = false;
            // eslint-disable-next-line
            let err = typeof response.data.message !== 'undefined' && response.data.message !== '' ? response.data.message : 'Oops, something went wrong. Try again later.';
            err += '<button type="button" class="remove-bg-learn-more">learn more</button>';
            this.alertWarn('', err, 5000, 'TopCenterNotif');
          }
        }).catch((error) => {
          console.log('error', this.isConverting, error, error.response, error.request);
          this.isConverting = false;
          let err = 'Oops, something went wrong. Try again later.';
          if (error.message) {
            err = error.message;
          }
          if (error.data && typeof error.data.message !== 'undefined' && error.data.message !== '') {
            err = error.data.message;
          }
          if (error.response && error.response.data && error.response.data.message) {
            let { message } = error.response.data;
            // eslint-disable-next-line
            if (message.includes('{\"')) {
              // means this is json
              const parsedMsg = JSON.parse(message);
              // eslint-disable-next-line
              message = parsedMsg && parsedMsg.errors && parsedMsg.errors[0] && parsedMsg.errors[0].title || err;
            }
            err = message;
          }
          this.alertError('', err, 8000, 'TopCenterNotif');
        });
    },
  },
  watch: {
    isConverting(val) {
      console.log('isConverting', val);
    },
  },
};
</script>

<style lang="scss">
</style>
