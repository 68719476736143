<template lang="pug">
.canvas-title-safe(
  :class="{ 'is-visible': showTitleSafe }"
)
  template(v-if="isVertical")
    img.canvas-title-safe__item.canvas-title-safe__instastory(src="@/assets/images/sizes/title-safe-ig-story.png")
    span.canvas-title-safe__item.canvas-title-safe__instastory
      span.canvas-title-safe__grid.grid-2
        span.canvas-title-safe__line.line-v.line-v-1
        span.canvas-title-safe__line.line-v.line-v-2
        span.canvas-title-safe__line.line-v.line-v-3
        span.canvas-title-safe__line.line-h.line-h-1
        span.canvas-title-safe__line.line-h.line-h-2
        span.canvas-title-safe__line.line-h.line-h-3
  template(v-else-if="isLandscape")
    span.canvas-title-safe__item.canvas-title-safe__youtube
      span.canvas-title-safe__grid.grid-1
      span.canvas-title-safe__grid.grid-2
        span.canvas-title-safe__line.line-v.line-v-1
        span.canvas-title-safe__line.line-v.line-v-2
        span.canvas-title-safe__line.line-v.line-v-3
        span.canvas-title-safe__line.line-h.line-h-1
        span.canvas-title-safe__line.line-h.line-h-2
        span.canvas-title-safe__line.line-h.line-h-3
  template(v-else-if="isSquare")
    span.canvas-title-safe__item.canvas-title-safe__square
      span.canvas-title-safe__grid.grid-1
      span.canvas-title-safe__grid.grid-2
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: '',
  computed: {
    ...mapState([
      'showTitleSafe',
    ]),
    ...mapGetters('canvasElements', [
      'getCanvasSize',
    ]),
    isSquare() {
      return this.getCanvasSize.width === this.getCanvasSize.height;
    },
    isVertical() {
      // eslint-disable-next-line
      return this.getCanvasSize.width / this.getCanvasSize.height == 1080 / 1920;
    },
    isLandscape() {
      // eslint-disable-next-line
      return this.getCanvasSize.width / this.getCanvasSize.height == 1920 / 1080;
    },
  },
};
</script>

<style lang="scss">
.canvas-title-safe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: none;
  pointer-events: none;

  &.is-visible {
    display: block;
  }
}

.canvas-title-safe__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.canvas-title-safe__grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 1px solid $blue;

  &.grid-1 {
    width: 90%;
    height: 90%;
    left: 5%;
    top: 5%;
  }

  &.grid-2 {
    width: 80%;
    height: 80%;
    left: 10%;
    top: 10%;

    .canvas-title-safe__instastory & {
      top: 8.5%;
      height: 83%;
    }
  }
}

.canvas-title-safe__line {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: $blue;

  &.line-h {
    width: 20px;
    height: 1px;
    top: 50%;
    transform: translateY(-50%);

    &.line-h-1 {
      left: 0;
    }

    &.line-h-2 {
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.line-h-3 {
      left: auto;
      right: 0;
    }
  }

  &.line-v {
    height: 20px;
    width: 1px;
    left: 50%;
    transform: translateX(-50%);

    &.line-v-1 {
      top: 0;
    }

    &.line-v-2 {
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &.line-v-3 {
      top: auto;
      bottom: 0;
    }
  }
}
</style>
