<template lang="pug">
.timeline(
  :class="{ 'is-collapsed': !showTimeline, 'is-compact': isCompact }"
)
  BottomBar(
    :show-max-duration-tooltip="showMaxDurationTooltip"
    @update-time="updateTime"
    @dragging-bottom-bar="draggingBottomBar"
    @hide-max-duration-tooltip="showMaxDurationTooltip = false"
  )
  .timeline__content#timeline-content(
    :class="{ 'is-sidebar-open': showSidebar }"
    @mouseleave="setHoveredLayerIndex(-1)"
    ref="timelineContent"
  )
    .timeline__number
      .timeline__title
      .timeline__numbers(
        ref="layers-number"
        @scroll="scrollingSide == 'layers-number' ? scrollBothSide($event) : null"
        @mouseenter="scrollingSide = 'layers-number'"
        @mouseleave="scrollingSide = ''"
      )
        template(v-for="(item, index) in canvasElementsGroups")
          TimelineNumber(
            :key="timelineNumberKey(item, index)"
            :item="item"
            :index="item.index"
            :indexNumber="index"
            :hoveredIndex="hoveredLayerIndex"
            @hovered="setHoveredLayerIndex"
            :layerHeight="layerHeight"
            :clickedLayer="clickedLayer"
            :isDragging="isDragging"
          )
          TimelineNumber(
            v-for="(groupItem, groupIndex) in item.elements"
            :key="timelineNumberChildKey(groupItem, groupIndex)"
            :item="groupItem"
            :index="groupItem.index"
            :hoveredIndex="hoveredLayerIndex"
            @hovered="setHoveredLayerIndex"
            :layerHeight="layerHeight"
            :clickedLayer="clickedLayer"
            :subItem="true"
            v-if="!item.groupFold"
            :isDragging="isDragging"
          )
    .timeline__left
      .timeline__title
        span.layer-title Layers
        .timeline__icons
          button.btn-fit-to-screen(
            @click="timelineFitToScreen"
          ) Fit to screen
          button.btn-zoom-buttons(
            type="button"
            title="Zoom out"
            @click="timelineZoomOut"
            :disabled="isZoomOut"
          )
            i.icon.icon-zoom-out-line
          button.btn-zoom-buttons(
            type="button"
            title="Zoom in"
            @click="timelineZoomIn"
            :disabled="isZoomIn"
          )
            i.icon.icon-zoom-in-line
        //- BaseButton.btn-layer-size(
        //-   @click="isCompact = false"
        //-   isPrimaryHollow=true
        //-   isIcon=true
        //-   title="Default layers"
        //-   :class="{ 'is-active': !isCompact }"
        //- )
        //-   i.icon(
        //-     class="icon-default"
        //-   )
        //- BaseButton.btn-layer-size(
        //-   @click="isCompact = true"
        //-   isPrimaryHollow=true
        //-   isIcon=true
        //-   title="Compact layers"
        //-   :class="{ 'is-active': isCompact }"
        //- )
        //-   i.icon(
        //-     class="icon-compact"
        //-   )
      .timeline__layers(
        v-model="canvasElements"
        ref="layers-left"
        @scroll="scrollingSide == 'layers-left' ? scrollBothSide($event) : null"
        @mouseenter="scrollingSide = 'layers-left'"
        @mouseleave="scrollingSide = ''"
      )
        draggable(
          v-model="canvasElements"
          @start="isDragging = true"
          @end="isDragging = false"
        )
          template(v-for="(item, index) in canvasElementsGroups")
            TimelineLayerLeft(
              :key="timelineLeftKey(item, index)"
              :item="item"
              :index="item.index"
              :hoveredIndex="hoveredLayerIndex"
              @hovered="setHoveredLayerIndex"
              @addRangeActiveElements="addRangeActiveElements(item.data.id, item)"
              :layerHeight="layerHeight"
              :clickedLayer="clickedLayer"
              :is-dark-mode="isDarkMode"
              @click-layer="updateClickedLayer"
              @selectLayer="selectLayer(item)"
            )
            TimelineLayerLeft(
              v-for="(groupItem, groupIndex) in item.elements"
              :key="timelineLeftChildKey(groupItem, groupIndex)"
              :item="groupItem"
              :index="groupItem.index"
              :indexNumber="groupIndex"
              :hoveredIndex="hoveredLayerIndex"
              @hovered="setHoveredLayerIndex"
              @addRangeActiveElements="addRangeActiveElements(groupItem.data.id)"
              :layerHeight="layerHeight"
              :clickedLayer="clickedLayer"
              :is-dark-mode="isDarkMode"
              @click-layer="updateClickedLayer"
              :subItem="true"
              v-if="!item.groupFold"
              @selectLayer="selectLayer(groupItem)"
            )
    .timeline__right(ref="timelineRight")
      VueDraggableResizable.timeline__bar.js-marker-timeline(
        :class="{ 'is-disabled': markerPosition === 0 }"
        :w="10"
        :h="timelineRulerHeight"
        :x="markerPosition"
        axis="x"
        :grid="[timelineGrid, 1]"
        :parent="false"
        :resizable="false"
        :style="leftZero"
        @dragging="dragMarker"
      )
        span.bar

      .timeline__ruler(
        @mouseenter="isRulerActive = true"
        @mouseleave="isRulerActive = false"
        @click="rulerClickEvent"
        :style="rulerMaxWidth"
      )
        VueDraggableResizable.timeline__length.js-current-length(
          :active="isRulerActive"
          :w="canvasDurationWidth"
          :minWidth="minRulerWidth"
          :maxWidth="maxTimelineWidth"
          :h="timelineRulerHeight"
          axis="x"
          :grid="[timelineGrid, 1]"
          :parent="false"
          :handles="['mr']"
          :draggable="false"
          :onResizeStart="startResizeDuration"
          @resizing="resizeCanvasDuration"
          @resizestop="stopResizeDuration"
        )
          .ruler
            template(v-for="(n, index) in rulerDuration")
              RulerSection(
                :index="index"
              )

          .timeline__snapping_guide(
            v-if="showTimelineSnappingGuide"
            :style="timelineSnappingGuideObject"
          )
            .animate-in-guide(
              :style="timelineSnappingAnimateInObject"
            )
            .animate-out-guide(
              :style="timelineSnappingAnimateOutObject"
            )

      .timeline__layers(
        ref="layers-right"
        @scroll="scrollingSide == 'layers-right' ? scrollBothSide($event) : null"
        @mouseenter="scrollingSide = 'layers-right'"
        @mouseleave="scrollingSide = ''; isDragging = false; isResizing = false"
        @mouseup="mouseUpEvent"
        :style="rulerMaxWidth"
      )
        template(v-for="(item, index) in timelineCanvasElements")
          TimelineLayerRight(
            :key="timelineRightKey(item, index)"
            :item="item"
            :index="item.index"
            :hoveredIndex="hoveredLayerIndex"
            :focusedIndex="focusedLayerIndex"
            @hovered="setHoveredLayerIndex"
            @focused="setFocusedLayerIndex"
            @dragging="setDragging"
            @resizing="setResizing"
            :layerHeight="layerHeight"
            :clickedLayer="clickedLayer"
            :is-dark-mode="isDarkMode"
            @click-layer="updateClickedLayer"
          )
          TimelineLayerRight(
            v-for="(groupItem, groupIndex) in item.elements"
            :key="timelineRightChildKey(groupItem, groupIndex)"
            :item="groupItem"
            :index="groupItem.index"
            :hoveredIndex="hoveredLayerIndex"
            :focusedIndex="focusedLayerIndex"
            @hovered="setHoveredLayerIndex"
            @focused="setFocusedLayerIndex"
            @dragging="setDragging"
            @resizing="setResizing"
            :layerHeight="layerHeight"
            :clickedLayer="clickedLayer"
            :is-dark-mode="isDarkMode"
            @click-layer="updateClickedLayer"
            :subItem="true"
            v-if="!item.groupFold"
          )

    .timeline__mockdisable(
      ref="mock-disable"
      :class="{ 'is-dark': isDarkMode }"
      :style="canvasDisableDuration"
    )

    .timeline__mockscroller(
      ref="layers-mock"
      @scroll="scrollingSide == 'layers-mock' ? scrollBothSide($event) : null"
      @mouseenter="scrollingSide = 'layers-mock'"
      @mouseleave="scrollingSide = ''"
    )
      .scroller(
        :style="scrollHeight"
      )
    TimelineEmptyState(
      v-if="getCanvasElements.length === 0"
    )
</template>

<script>
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';
import VueDragResize from 'vue-drag-resize';
import VueDraggableResizable from 'vue-draggable-resizable';

import draggable from 'vuedraggable';
import { debounce } from 'debounce';
import cloneDeep from 'lodash.clonedeep';
import {
  pixelToSec,
  secToPixel,
  snap,
  fauxZero,
  layerTimelineHeight,
  windowWidth,
  timelineZoomsKey,
  timelineZooms,
  maxDuration,
} from '@/assets/scripts/variables';

import RulerSection from './children/RulerSection.vue';
import TimelineNumber from './children/TimelineNumber.vue';
import TimelineLayerLeft from './children/TimelineLayerLeft.vue';
import TimelineLayerRight from './children/TimelineLayerRight.vue';
import TimelineEmptyState from '@/components/ProjectCanvas/TimelineContainer/children/TimelineEmptyState.vue';
import BottomBar from '@/components/ProjectCanvas/BottomBar/BottomBar.vue';

import liteModeMixin from '@/components/mixins/lite-mode-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

let setIsCanvasLoadingTimeout;

export default {
  name: 'TimelineContainer',
  mixins: [liteModeMixin, darkModeMixin],
  components: {
    RulerSection,
    TimelineNumber,
    TimelineLayerLeft,
    TimelineLayerRight,
    TimelineEmptyState,
    draggable,
    BottomBar,
    pixelToSec,
    VueDragResize,
    VueDraggableResizable,
  },
  props: {
    timelineTime: { type: Number, default: 0 },
  },
  data() {
    return {
      extraContainerHeight: 16, // to adjust canvas section padding in timeline on and off (has compact class which changing width) mode
      minDurationRuler: 15,
      isRulerActive: false,
      scrollingSide: '',
      timelineRulerHeight: 34,
      timelineGrid: snap,
      hoveredLayerIndex: -1,
      focusedLayerIndex: -1,
      markerPosition: 0,
      isDragging: false,
      isResizing: false,
      isCompact: false,
      clickedLayer: -1,
      canvasElementsGroups: [],
      isGroupSelect: false,
      selectItem: null,
      selectItemGroupIndex: null,
      isCanvasDurationResizing: false,
      showMaxDurationTooltip: false,

      minZoomLevel: timelineZoomsKey[0],
      maxZoomLevel: timelineZoomsKey[timelineZoomsKey.length - 1],

      offsetScroll: 10,
    };
  },
  computed: {
    ...mapState(['showSidebar', 'showTimeline', 'timelineSnappingGuide', 'isCanvasLoading']),
    ...mapState('canvasElements', ['isChangingElements']),
    ...mapGetters('canvasElements', [
      'getCanvasElements',
      'getCanvasElementById',
      'getCurrentSceneDuration',
      'getActiveElementsIndexes',
      'getCanvasElementIndexById',
      'getActiveElements',
      'getCanvasElementsGroups',
    ]),
    ...mapGetters('canvasLayouts', ['timelineZoomLevel', 'timelineMultiplier']),
    canvasElements: {
      // eslint-disable-next-line vue/return-in-computed-property
      get() {
        if (!this.isChangingElements) {
          // eslint-disable-next-line
          this.canvasElementsGroups = this.getCanvasElementsGroups;

          const elements = [];
          let groupId = null;

          // for sorting elements,
          // grouping is done with computed elements.
          // so the elements are not prepared in canvasElements yet.
          // we need to setup the elements for sorting groups and elements.

          this.getCanvasElements.forEach((element) => {
            if (element.data.isGroup) {
              if (groupId !== element.data.groupId) {
                elements.push({
                  groupId: element.data.groupId,
                  groupName: element.data.groupName,
                });

                elements.push(element);
                groupId = element.data.groupId ? element.data.groupId : null;
              } else {
                elements.push(element);
                groupId = element.data.groupId ? element.data.groupId : null;
              }
            } else {
              elements.push(element);
            }
          });

          return elements;
        }
        return [];
      },
      set(value) {
        this.reorderCanvasElements({
          isGroupSelect: this.isGroupSelect,
          selectedId: this.selectItem.data.id,
          newElements: value,
        });
      },
    },
    timelineCanvasElements() {
      if (!this.isChangingElements) {
        return this.getCanvasElementsGroups;
      }
      return this.canvasElementsGroups;
    },
    // canvasDuration: {
    //   get() {
    //     return this.getCurrentSceneDuration;
    //   },
    //   set(value) {
    //     let duration = pixelToSec(value);
    //     if (duration <= 1) duration = 1;
    //     this.updateCurrentSceneDuration(duration);
    //   },
    // },
    rulerDuration() {
      // always add buff to the ruler to have space at the end for layers when resizing
      let duration = this.getCurrentSceneDuration + 3;
      if (duration <= this.minDurationRuler) {
        duration = this.minDurationRuler;
      }

      if (this.getCurrentSceneDuration === 30) {
        duration = this.getCurrentSceneDuration + 5;
      }
      return Math.ceil(duration);
    },
    minRulerWidth() {
      return secToPixel(1) * this.timelineMultiplier;
    },
    canvasDurationWidth() {
      return secToPixel(this.getCurrentSceneDuration) * this.timelineMultiplier;
    },
    maxTimelineWidth() {
      // added 3 seconds offset
      return secToPixel(this.maxDurationScene) * this.timelineMultiplier;
    },
    canvasDisableDuration() {
      const styleObject = {};

      let leftScroll = `${this.canvasDurationWidth + 312}`;
      if (this.offsetScroll > 10) {
        // Handle scroll event
        leftScroll = Number(leftScroll);
        leftScroll -= this.offsetScroll;
      }

      if (this.showSidebar) {
        // leftScroll - Follow the timeline__content class padding-left when sidebar opened
        leftScroll = Number(leftScroll);
        if (window.innerWidth >= 1600) {
          leftScroll += 360;
        } else {
          leftScroll += 270;
        }
      }
      styleObject.left = `${leftScroll}px`;

      return styleObject;
    },
    rulerMaxWidth() {
      const styleObject = {};

      const width = secToPixel(this.rulerDuration) * this.timelineMultiplier;
      styleObject.width = `${width + 10}px`;

      return styleObject;
    },
    scrollHeight() {
      const styleObject = {};

      // const layerHeight = 43;
      styleObject.height = `${this.getCanvasElements.length * this.layerHeight}px`;

      return styleObject;
    },
    layerHeight() {
      // if (this.isCompact) return layerTimelineHeight.compact;
      return layerTimelineHeight.compact;
    },
    timelineSnappingGuideObject() {
      const styleObject = {};

      let { left, width } = this.timelineSnappingGuide;

      if (left < 0) left = 0;

      if (width < 0) width = 0;

      styleObject.left = `${left}px`;
      styleObject.width = `${width}px`;

      return styleObject;
    },
    timelineSnappingAnimateInObject() {
      const styleObject = {};
      let { animateInWidth } = this.timelineSnappingGuide;
      if (animateInWidth < 0) animateInWidth = 0;
      styleObject.width = `${animateInWidth}px`;
      if (animateInWidth === 0) styleObject.borderRight = 'none';
      return styleObject;
    },
    timelineSnappingAnimateOutObject() {
      const styleObject = {};
      let { animateOutWidth } = this.timelineSnappingGuide;
      if (animateOutWidth < 0) animateOutWidth = 0;
      styleObject.width = `${animateOutWidth}px`;

      if (animateOutWidth === 0) styleObject.borderLeft = 'none';

      return styleObject;
    },
    showTimelineSnappingGuide() {
      return this.isDragging || this.isResizing;
    },
    leftZero() {
      const styleObject = {};

      if (this.markerPosition === 0) {
        styleObject.left = '0px';
      }

      return styleObject;
    },
    isZoomOut() {
      return this.timelineZoomLevel <= this.minZoomLevel;
    },
    isZoomIn() {
      return this.timelineZoomLevel >= this.maxZoomLevel;
    },
  },
  methods: {
    ...mapMutations(['setCurrentTime', 'setIsCanvasLoading']),
    ...mapMutations('canvasElements', [
      'reorderCanvasElements',
      'updateCurrentSceneDuration',
      'addActiveElements',
      'updateCanvasElementsEndingTime',
    ]),
    ...mapMutations('canvasLayouts', ['setCanvasSectionPaddingBottom', 'setTimelineZoomLevel']),
    ...mapActions(['setTimelineSnappingGuide']),

    // START - Keys
    timelineNumberKey(item, index) {
      // group doesn't have id
      const id = item.data.id || `group-${index}`;
      return `timeline-number-${id}`;
    },
    timelineNumberChildKey(item, index) {
      const { id } = item.data;
      return `timeline-number-${id}-${index}`;
    },
    timelineLeftKey(item, index) {
      // group doesn't have id
      const id = item.data.id || `group-${index}`;
      return `timeline-left-${id}`;
    },
    timelineLeftChildKey(item, index) {
      const { id } = item.data;
      return `timeline-left-${id}-${index}`;
    },
    timelineRightKey(item, index) {
      // group doesn't have id
      const id = item.data.id || `group-${index}`;
      return `timeline-right-${id}`;
    },
    timelineRightChildKey(item, index) {
      const { id } = item.data;
      return `timeline-right-${id}-${index}`;
    },
    // END - Keys

    // eslint-disable-next-line func-names
    updateCanvasSectionPaddingBottom: debounce(function () {
      this.setCanvasSectionPaddingBottom(
        this.showTimeline ? this.$refs.timelineContent.offsetHeight + this.extraContainerHeight : 0,
      );
    }, 10),
    setHoveredLayerIndex(index) {
      if (!this.isDragging) {
        // only set the hoveredLayerIndex when none of the element is being dragged
        this.hoveredLayerIndex = index;
      }
    },
    setFocusedLayerIndex(index) {
      this.focusedLayerIndex = index;
    },
    addRangeActiveElements(item, group = false) {
      if (this.getActiveElementsIndexes.length) {
        let startingIndex = Math.min(...this.getActiveElementsIndexes);
        let endingIndex = this.getCanvasElementIndexById(item);

        if (endingIndex < startingIndex) {
          startingIndex = this.getCanvasElementIndexById(item);
          endingIndex = Math.min(...this.getActiveElementsIndexes);
        }

        if (!group.groupElement) {
          // eslint-disable-next-line
          for (let i = startingIndex; i <= endingIndex; i++) {
            const element = this.getCanvasElements[i];
            this.addActiveElements(element.data.id);
          }
        } else {
          group.elements.forEach((element) => {
            this.addActiveElements(element.data.id);
          });
        }
      } else {
        this.addActiveElements(item);
      }
    },
    setMinimumRuler() {
      const rulerWidth = document.getElementsByClassName('timeline__ruler')[0].clientWidth;
      this.minDurationRuler = Math.ceil(pixelToSec(rulerWidth));
    },
    startResizeDuration() {
      this.isCanvasDurationResizing = true;
    },
    stopResizeDuration(left, top, width) {
      const value = width;
      const duration = pixelToSec(value / this.timelineMultiplier);

      if (duration === this.maxDurationScene) {
        this.showMaxDurationTooltip = true;
      }

      setTimeout(() => {
        this.isCanvasDurationResizing = false;
      }, 100);
    },
    resizeCanvasDuration(left, top, width) {
      const oldValue = this.getCurrentSceneDuration;
      const value = width;
      const duration = pixelToSec(value / this.timelineMultiplier);

      this.updateCurrentSceneDuration(duration);
      this.updateCanvasElementsEndingTime({
        oldValue,
        duration,
      });
    },
    scrollBothSide(e) {
      const number = this.$refs['layers-number'];
      const left = this.$refs['layers-left'];
      const right = this.$refs['layers-right'];
      const mock = this.$refs['layers-mock'];
      const { scrollTop } = e.target;
      // console.log('e', e.target)

      if (e.target !== right) right.scrollTop = scrollTop;
      if (e.target !== left) left.scrollTop = scrollTop;
      if (e.target !== number) number.scrollTop = scrollTop;
      if (e.target !== mock) mock.scrollTop = scrollTop;
    },
    rulerClickEvent(e) {
      if (
        e.target.className.indexOf('vdr-stick') === -1
        || e.target.className.indexOf('js-current-length') === -1
      ) {
        if (!this.isCanvasDurationResizing) {
          this.setCurrentTime(pixelToSec(e.offsetX / this.timelineMultiplier));
        }
      }
    },
    dragMarker(rect) {
      const marker = rect < 0 ? 0 : rect;
      let time = pixelToSec(marker / this.timelineMultiplier);
      if (time === this.getCurrentSceneDuration) {
        time -= fauxZero;
      }
      this.setCurrentTime(time);
    },
    setRuler(rect) {
      const { left, width } = rect;
      const rightPos = left + width;
      const rightSec = pixelToSec(rightPos);
      const offset = 3;

      let newRulerDuration = this.rulerDuration;

      // if the location is closer towards the max right position of the ruler
      // generate more ruler
      if (rightSec >= this.rulerDuration - offset) {
        newRulerDuration = this.rulerDuration + offset;
      }

      // if the new ruler is bigger than 33 sec
      // keep it as 33 sec
      if (newRulerDuration > this.maxDurationScene + offset) {
        newRulerDuration = this.maxDurationScene + offset;
      }

      this.minDurationRuler = newRulerDuration;
    },
    setDragging(rect, canvasElementId) {
      // this function is to ensure the hover highlight is not shown on other layer when one elemnet is dragged
      this.isDragging = true;

      if (rect) {
        // extend the ruler if the timeline is getting closer to the limit
        this.setRuler(rect);
      }

      if (!canvasElementId) return;

      if (!this.isCanvasLoading) this.setIsCanvasLoading(true);

      this.$nextTick(() => {
        if (!this.getCanvasElementById(canvasElementId)) return;
        const baseGuide = cloneDeep(this.timelineSnappingGuide);
        const { time_in, time_out } = this.getCanvasElementById(canvasElementId);
        const { handle } = rect;

        if (handle === 'animate-in') {
          baseGuide.animateInWidth = rect.width;
          baseGuide.left = secToPixel(time_in);
          baseGuide.width = secToPixel(time_out - time_in);
        } else if (handle === 'animate-out') {
          // console.log('setDragging animate out', rect);
          baseGuide.animateOutWidth = rect.width;
          baseGuide.left = secToPixel(time_in);
          baseGuide.width = secToPixel(time_out - time_in);
        } else {
          baseGuide.animateInWidth = 0;
          baseGuide.animateOutWidth = 0;
          baseGuide.left = rect.left;
          baseGuide.width = rect.width;
        }

        this.setTimelineSnappingGuide(baseGuide);
      });

      clearTimeout(setIsCanvasLoadingTimeout);
      setIsCanvasLoadingTimeout = setTimeout(() => {
        this.setIsCanvasLoading(false);
      }, 300);
    },
    setResizing(rect, canvasElementId) {
      // this function is to ensure the hover highlight is not shown on other layer when one elemnet is dragged
      this.isResizing = true;
      if (rect) {
        // extend the ruler if the timeline is getting closer to the limit
        this.setRuler(rect);
      }

      if (!this.isCanvasLoading) this.setIsCanvasLoading(true);

      this.$nextTick(() => {
        const baseGuide = JSON.parse(JSON.stringify(this.timelineSnappingGuide));
        const { time_in, time_out } = this.getCanvasElementById(canvasElementId);
        const { handle } = rect;

        if (handle === 'animate-in') {
          baseGuide.animateInWidth = rect.width;
          baseGuide.left = secToPixel(time_in);
          baseGuide.width = secToPixel(time_out - time_in);
        } else if (handle === 'animate-out') {
          // console.log('setDragging animate out', rect);
          baseGuide.animateOutWidth = rect.width;
          baseGuide.left = secToPixel(time_in);
          baseGuide.width = secToPixel(time_out - time_in);
        } else {
          baseGuide.animateOutWidth = 0;
          baseGuide.animateInWidth = 0;
          baseGuide.left = rect.left;
          baseGuide.width = rect.width;
        }

        this.setTimelineSnappingGuide(baseGuide);
      });

      clearTimeout(setIsCanvasLoadingTimeout);
      setIsCanvasLoadingTimeout = setTimeout(() => {
        this.setIsCanvasLoading(false);
      }, 300);
    },
    mouseUpEvent() {
      // need to use timeout because dragging is still emitted one more time after mouseup
      setTimeout(() => {
        this.isDragging = false;
        this.isResizing = false;
      }, 100);
    },
    updateClickedLayer(index) {
      this.clickedLayer = index;

      setTimeout(() => {
        this.clickedLayer = -1;
      }, 600);
    },
    updateTime(val) {
      this.$emit('update-timeline-time', val);
    },
    selectLayer(item) {
      if (item.groupElement) {
        this.isGroupSelect = true;
        this.selectItem = null;
        this.selectItemGroupIndex = null;
      } else {
        this.isGroupSelect = false;
        this.selectItem = item;

        this.canvasElements.forEach((element, index) => {
          if (element.groupId && element.groupId === this.selectItem.data.groupId) {
            this.selectItemGroupIndex = index;
          }
        });
      }
    },
    draggingBottomBar() {
      this.updateCanvasSectionPaddingBottom();
    },
    timelineFitToScreen() {
      const layerRightWidth = this.$refs.timelineRight.clientWidth;
      const currentDurationWidth = secToPixel(this.getCurrentSceneDuration);
      let selectedZoom = 0;

      for (let i = 0; i < timelineZoomsKey.length; i += 1) {
        const key = timelineZoomsKey[i];
        const { multiplier } = timelineZooms[key];

        // loop until the currentDurationWidth is bigger than layerRightWidth
        // if it's still smaller, then use that zoom
        if (currentDurationWidth * multiplier > layerRightWidth) {
          break;
        }

        selectedZoom = key;
      }

      this.setTimelineZoomLevel(selectedZoom);
    },
    timelineZoomOut() {
      // only allow maximum
      console.log('zoom out', this.timelineZoomLevel, this.minZoomLevel);
      if (this.timelineZoomLevel < this.minZoomLevel) return;
      this.setTimelineZoomLevel(this.timelineZoomLevel - 1);
    },
    timelineZoomIn() {
      if (this.timelineZoomLevel > this.maxZoomLevel) return;
      this.setTimelineZoomLevel(this.timelineZoomLevel + 1);
    },
    setMinZoomLevel() {
      const layerRightWidth = this.$refs.timelineRight && this.$refs.timelineRight.clientWidth;
      const maxWidth = secToPixel(maxDuration.scene);

      let selectedZoom = 0;

      for (let i = 0; i < timelineZoomsKey.length; i += 1) {
        const key = timelineZoomsKey[i];
        const { multiplier } = timelineZooms[key];

        if (maxWidth * multiplier >= layerRightWidth) {
          break;
        }

        selectedZoom = parseInt(key, 10);
      }
      // default will be -4, unless you can already see the whole 30sec
      this.minZoomLevel = Math.max(selectedZoom, timelineZoomsKey[0]);
    },
  },
  mounted() {
    this.offsetScroll = this.$refs.timelineRight.scrollLeft;
    // TODO: make sure the components are properly updated since reloadComponent is removed
    this.setMinimumRuler();
    this.setMinZoomLevel();

    if (window.screen.width <= windowWidth.smallLaptopSize) {
      this.isCompact = true;
    }

    this.updateCanvasSectionPaddingBottom();

    window.addEventListener(
      'resize',
      debounce(() => {
        this.setMinZoomLevel();
      }),
    );

    this.$refs.timelineRight.addEventListener(
      'scroll',
      debounce(() => {
        this.offsetScroll = this.$refs.timelineRight.scrollLeft;
      }, 50),
    );
  },
  watch: {
    // getCurrentSceneDuration() {
    // Middle point of new scene duration
    // this.markerPosition = (secToPixel(this.getCurrentSceneDuration) / 2);
    // },
    showTimeline() {
      this.updateCanvasSectionPaddingBottom();
      this.$nextTick(() => {
        this.setMinZoomLevel();
        this.timelineFitToScreen();
      });
    },
    timelineTime(val) {
      if (!this.isCanvasDurationResizing) {
        this.markerPosition = secToPixel(val) * this.timelineMultiplier;
      }
    },
    getCanvasElements: {
      handler() {
        if (!this.isChangingElements) {
          this.$nextTick(() => {
            this.canvasElementsGroups = [];
            this.$nextTick(() => {
              this.canvasElementsGroups = this.getCanvasElementsGroups;
            });
          });
        }
      },
      deep: true,
    },
    timelineMultiplier: {
      handler(val) {
        this.timelineGrid = 1;
        this.markerPosition = secToPixel(this.timelineTime) * val;

        if (!this.$refs.timelineRight) return;

        const layerRightWidth = this.$refs.timelineRight.clientWidth;
        let rulerWidth = 100;

        if (this.timelineZoomLevel < 0) {
          if (this.timelineZoomLevel === -1) {
            rulerWidth = 80;
          } else if (this.timelineZoomLevel === -2) {
            rulerWidth = 60;
          } else if (this.timelineZoomLevel === -3) {
            rulerWidth = 40;
          } else if (this.timelineZoomLevel === -4) {
            rulerWidth = 20;
          }
        }

        let rulerSectionCount = 0;
        let totalRulerWidth = 0;

        while (totalRulerWidth < layerRightWidth && rulerSectionCount < 30) {
          totalRulerWidth += rulerWidth;
          rulerSectionCount += 1;
        }

        this.minDurationRuler = rulerSectionCount;

        setTimeout(() => {
          this.timelineGrid = snap * val;
        }, 500);
      },
    },
  },
};
</script>

<style lang="scss">
.timeline {
  background: var(--lightgrey100-darkgrey600);
  margin: auto;
  text-align: left;
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-flow: row wrap;
  transform-origin: bottom;
  transition: all 0.35s ease-in-out;
  width: calc(100% - 200px - 66px);
  left: 66px;
  right: 200px;
  z-index: 8;
  bottom: 0;
  padding-bottom: 14px;
  box-shadow: 0 0 10px rgba(200, 200, 200, 0.2);
  transition: transform 0.25s ease-in-out, width 0.25s ease-in-out, right 0.25s ease-in-out;

  &.is-collapsed {
    transform: translateY(100%);

    .bottom-bar {
      padding-bottom: 10px;
      height: 52px;
    }
  }
}

.timeline__content {
  width: 100%;
  display: flex;
  min-height: 63px;
  z-index: 1;
  position: relative;
  flex-wrap: wrap;
  transition: height 0.3s cubic-bezier(0, 0, 0, 1);

  &.is-sidebar-open {
    padding-left: 360px;

    @include smallest {
      padding-left: 270px;
    }
  }
}

.timeline__number,
.timeline__left {
  height: 100%;
}

.timeline__number {
  width: 32px;
  max-width: 32px;
  flex: 1 100%;
  z-index: 3;
  color: var(--black-white);
  background: var(--lightgrey100-darkgrey600);

  .timeline__title,
  .timeline__item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
  }
}

.timeline__left {
  margin-right: 10px;
  width: 270px;
  max-width: 270px;
  border-right: 1.5px solid var(--lightgrey400-darkgrey500);
  position: static;
  z-index: 3;
  overflow: visible;
  flex: 1 100%;
  background: var(--lightgrey100-darkgrey600);

  .timeline__title {
    justify-content: flex-start;
    // margin-left: -25px;

    .layer-title {
      user-select: none;
    }

    span {
      font-size: 12.5px;
      line-height: 16px;
      color: var(--black-white);
      margin-left: -20px;
      font-weight: 500;
    }

    .timeline__icons {
      margin-left: auto;

      .btn-fit-to-screen {
        height: 24px;
        width: 95px;
        font-size: 12px;
        padding: 0px;
        border-radius: 4px;
      }

      .btn-fit-to-screen,
      .btn-zoom-buttons {
        color: $light;
        background-color: $timelineGreyButton;
        font-weight: 500;
        margin-right: 5px;
        transition: background-color 0.3s cubic-bezier(0, 0, 0, 1);

        &:hover {
          background-color: $darkGrey300;
        }

        &:active {
          background-color: $darkGrey300;
        }
      }

      .btn-zoom-buttons {
        height: 24px;
        border-radius: 4px;
        position: relative;
        top: 4px;

        .icon {
          position: relative;
          top: 0px;
          font-size: 18px;
        }

        &:disabled {
          background-color: $lightGrey400;
          color: $lightGrey700;
          cursor: auto;
          pointer-events: none;
        }
      }
    }
  }

  .btn-layer-size {
    font-size: 1.25em;
    margin: 0 3px;

    &.is-active {
      background: $btnPrimaryBackground;

      color: $light;
    }
  }

  .ps__rail-y {
    z-index: 2;
    top: 40px !important;
  }

  .timeline__item {
    padding-right: 8px;
    cursor: pointer;
  }
}

.timeline__right {
  position: relative;
  max-width: calc(100% - 302px);
  margin-left: -10px;
  padding-left: 10px;
  padding-bottom: 10px;
  transform: translateY(0);
  transition: transform 0.5s ease-out;
  overflow: hidden;
  overflow-x: visible;
  flex: 1 auto;
  height: calc(100% + 10px);

  .timeline__item {
    padding-left: 10px;

    &.is-hidden {
      .timeline__path {
        opacity: 0.3;
      }
    }
  }

  .timeline__layers {
    margin-left: -10px;
    position: relative;
  }
}

.timeline__mockdisable {
  position: absolute;
  right: 10px;
  top: 0;
  width: auto;
  height: 100%;
  overflow: auto;
  z-index: 1;
  background: rgba($lightGrey700, 0.4);
  pointer-events: none;

  &.is-dark {
    background: rgba($black, 0.3);
  }
}

.timeline__mockscroller {
  position: absolute;
  right: 0;
  top: 35px;
  height: calc(100% - 35px);
  overflow: auto;
  width: 10px;
  z-index: 22;
  overflow: auto;
}

.timeline__layers,
.timeline__numbers {
  max-height: 60vh;
  overflow: auto;
  height: calc(100% - 30px);

  // @include smallest {
  //   max-height: 150px;
  // }
}

.timeline__right .timeline__layers,
.timeline__left .timeline__layers,
.timeline__numbers {
  &::-webkit-scrollbar {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

.timeline__title {
  height: 35px;
  background: var(--lightgrey100-darkgrey600);
  position: relative;
  z-index: 2;
  border-bottom: 1.5px solid var(--lightgrey400-darkgrey500);
  font-size: 0.875em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline__length,
.timeline__bar {
  position: absolute;
  display: block;
  top: 0;
  background: transparent;
  border: 0;
  width: 10px;
  height: 100%;
  left: 0;
  z-index: 10;

  .bar {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    height: 100%;
    width: 1px;
    background: var(--blue700-white);
    display: block;
  }

  &.vdr.active::before {
    display: none;
  }
}

.timeline__bar {
  transition: transform 0.1s linear, left 0.1s linear;
  z-index: 4 !important;
  height: 100% !important;
}

.timeline__length {
  background: var(--white-darkgrey300);

  &.active::before {
    outline: 0;
  }

  &::after {
    content: '';
    display: block;
    pointer-events: none;
    width: 2px;
    right: -1px;
    background: var(--darkgrey800-white);
  }

  &::after,
  .handle {
    border: 0;
    height: 100% !important;
    top: 0;
    right: 0;
    position: absolute;
  }

  .handle {
    background: transparent;
    cursor: ew-resize;
    width: 5px;
  }
}

.timeline__bar {
  height: calc(100% - 5px);
  cursor: pointer;
}

.js-marker-timeline {
  margin-left: 5px;

  &.is-disabled {
    transform: translate(0px, 0px) !important;
  }
}

.timeline__item {
  height: 43px;
  width: 100%;
  margin-top: 0;
  border-bottom: 1.5px solid var(--lightgrey400-darkgrey500);
  background: var(--lightgrey100-darkgrey600);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  position: relative;
  transition: background 0.25s ease-in-out;

  &.is-hovered:not(.is-hidden):not(.is-locked),
  &.is-active:not(.is-hidden):not(.is-locked) {
    background: rgba($blue600, 0.1);

    &.is-dark {
      background: rgba($green600, 0.1);
    }
  }

  &.is-locked,
  &.is-hidden {
    &.is-clicked {
      animation: blinkLocked 0.2s ease 3;
    }
  }

  &.is-hidden {
    opacity: 0.9;

    .timeline__button,
    .timeline__input {
      color: $grey;
      opacity: 0.5;
    }

    .timeline__icon {
      opacity: 0.3;
    }
  }
}

.timeline__ruler {
  white-space: nowrap;
  height: 35px;
  position: relative;
  z-index: 2;
  border-bottom: 1.5px solid var(--lightgrey400-darkgrey500);
}

.timeline__snapping_guide {
  height: 34px;
  position: absolute;
  border-left: 1px solid $snappingGuide;
  border-right: 1px solid $snappingGuide;
  width: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  .animate-in-guide {
    border-right: 1px dashed $snappingGuide;
    width: 50px;
  }

  .animate-out-guide {
    border-left: 1px dashed $snappingGuide;
    width: 50px;
  }
}

.ruler {
  cursor: default;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: flex-start;
  pointer-events: none;
  padding-left: 1px; // added padding for snapping guide alignment
}

@keyframes blinkLocked {
  50% {
    background: var(--lightgrey-darkgrey4);
  }
}
</style>
