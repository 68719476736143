<template lang="pug">
.category-item-container(:class="{ 'inside-folder': activeFolder > 0 || getActiveSubGroup === 'logos' }")
  .item-element-wrapper(:class="{'element-wrapper--overflow-hidden': isOnMusicTab}")
    .my-folder-options(
      v-if="showFolderOptions"
      :style="styleObject"
      @mouseenter="isHoveringOptions = true"
      @mouseleave="closeFolderOptions(true)"
    )
      BaseButton.btn-rename(
        isText=true
        @click.stop.prevent="triggerFocusFolderName"
      )
        | Rename
      DeleteItem(
        @click.stop.prevent=""
        :itemId="hoveredId"
        isFolder=true
        text="Delete Folder"
        :showIcon="false"
      )

    .my-file-options(
      v-if="showFileOptions"
      :style="styleObject"
      @mouseenter="isHoveringOptions = true"
      @mouseleave="closeFileOptions(true)"
    )
      span.file-name(
        v-if="isMyFilesCategory && hoveredFileName"
      ) {{ hoveredFileName }}
      AssetsFavourite(
        v-if="hoveredIsNotLogo && !showRetry"
        :type="hoveredFavType"
        :id="hoveredId"
        :favId="hoveredFavId"
        :isFavourite="hoveredIsFavourite"
        :isHtmlShape="hoveredIsHtml"
        :showText="true"
      )

      VideoTrimButton(
        v-if="showVideoTrimButton"
        :id="hoveredId"
        :url="hoveredVideoUrl"
      )

      button.btn-retry(
        v-if="showRetry"
        title="Retry upload"
        @click="retryUpload"
      )
        span Retry upload
        i.icon.icon-replay

      DeleteItem(
        v-if="isMyFilesCategory"
        :itemId="hoveredId"
        text="Delete"
      )

    template(v-if="getActiveGroup === 'texts'")
      .insert-text__wrapper
        BaseButton.btn-add-text.btn-add-heading(
          :is-full-width="true"
          padding="10px"
          :is-canvas="true"
          @click="addTextElementToCanvas('heading')"
        ) + Add a Heading
        BaseButton.btn-add-text.btn-add-subheading(
          :is-full-width="true"
          padding="10px"
          :is-canvas="true"
          @click="addTextElementToCanvas('subheading')"
        ) + Add a Sub-Heading
    template(v-if="isOnMusicTab")
      audio#temp-audio(
        :src="tempAudioUrl"
        ref="tempAudio"
      )

    template(v-if="showFolders && !loader")
      //- Note: Personal uploads always have 1 folder (Logo folder)
      .my-files__folders-tab(
        :class="{ 'no-folder': selectedUploadSpace === 'team' && !selectedFolderLevel.length }"
      )
        BaseButton.btn-add-folder(
          v-if="selectedUploadSpace === 'personal'"
          :is-text="true"
          :plain-hover="true"
          icon-before="icon-plus"
          :is-loading="isCreatingFolder"
          @click="createUserFolder"
        ) Add Folder

        ul.myfolder__list
          li.grid-item(v-if="selectedUploadSpace === 'personal' && (getActiveSubGroup === 'images' || getActiveSubGroup === 'logos')")
            .logo-folder(@click="setActiveSubGroup('logos')")
              svg#logoFolderSvg(width='30' height='16' viewbox='0 0 30 16' fill='none' xmlns='http://www.w3.org/2000/svg')
                path(d='M8.59 0.59C8.21 0.21 7.7 0 7.17 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2H10L8.59 0.59Z' fill='white')
                path.triangle(opacity='0.5' d='M6.96676 8.0176C7.16438 7.71809 7.60381 7.71809 7.80143 8.0176L9.80384 11.0523C10.0232 11.3848 9.78477 11.8277 9.3865 11.8277H5.38169C4.98342 11.8277 4.74501 11.3848 4.96436 11.0523L6.96676 8.0176Z' fill='#273A5B')
                path.triangle(d='M10.3267 5.68951C10.514 5.33266 11.0248 5.33266 11.2121 5.68951L14.0492 11.0949C14.2239 11.4278 13.9825 11.8272 13.6065 11.8272H7.93232C7.55631 11.8272 7.31485 11.4278 7.4896 11.0949L10.3267 5.68951Z' fill='#273A5B')

              BaseButton.logo-folder__button(
                :is-text="true"
                :plain-hover="true"
              ) Logos

          template(v-if="selectedFolderLevel.length")
            li.grid-item(
              v-for="item in selectedFolderLevel"
              :key="`folder-${item.id}`"
              :ref="`folder-${item.id}`"
              @mouseleave="closeFolderOptions"
            )
              FolderItem(
                v-bind="item"
                :ref="`folder-item-${item.id}`"
                :class="{ 'has-new-folder': selectedNewFolder.id === item.id }"
                :isHoveringFolderOptions="isHoveringOptions"
                :isRenamingFolder="isRenamingFolder"
                :hoveredId="hoveredId"
                :is-my-files-category="isMyFilesCategory"
                :is-team="selectedUploadSpace === 'team'"
                @toggle-folder-options="openFolderOptions($event, `folder-${item.id}`)"
                @folder-name-blurred="isRenamingFolder = false"
                @edit-folder="editFolder"
                @delete-folder="deleteFolder"
                @select-folder="selectFolder"
              )
          template(v-else-if="selectedUploadSpace === 'team'")
            li.empty-folder__text No folder available...

    template(v-else-if="isInsideFolder || isOnMusicTeamUploadsTab")
      Drop(
        @drop="dropFilesToMainFolder"
        @dragover="isDropping = true"
        @dragleave="isDropping = false"
      )
        .root-folder-wrapper(
          :class="{ 'is-dropping': isDropping }"
        )
          //- added another v-if of "isInsideFolder" since music team uploads tab also can access when in root folder
          .selected-folder__header(v-if="isInsideFolder")
            BaseButton.icon-left-arrow.btn-back(
              :is-icon="true"
              :plain-hover="true"
              @click="decreaseFolderLevel"
            )
            .selected-folder
              template(v-if="selectedFolder && selectedFolder.folder_name")
                i.icon.icon-files-menu
                span.folder-name {{ selectedFolder.folder_name }}

              template(v-else-if="getActiveSubGroup === 'logos'")
                svg(width='20' height='16' viewbox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg')
                  path(d='M8.59 0.59C8.21 0.21 7.7 0 7.17 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2H10L8.59 0.59Z' fill='white')
                  path.triangle(opacity='0.5' d='M6.96676 8.0176C7.16438 7.71809 7.60381 7.71809 7.80143 8.0176L9.80384 11.0523C10.0232 11.3848 9.78477 11.8277 9.3865 11.8277H5.38169C4.98342 11.8277 4.74501 11.3848 4.96436 11.0523L6.96676 8.0176Z' fill='#273A5B')
                  path.triangle(d='M10.3267 5.68951C10.514 5.33266 11.0248 5.33266 11.2121 5.68951L14.0492 11.0949C14.2239 11.4278 13.9825 11.8272 13.6065 11.8272H7.93232C7.55631 11.8272 7.31485 11.4278 7.4896 11.0949L10.3267 5.68951Z' fill='#273A5B')
                span.folder-name Logos

          //- Team Function - folder section
          TeamUploadsFolderDropdown(
            v-if="isShowTeamUploadsFolderDropdown && insideFolderList.length && !loader"
            :inside-folder-list="insideFolderList"
            :is-my-files-category="isMyFilesCategory"
            :is-team="isShowTeamUploadsFolderDropdown"
            @select-folder="selectFolder"
          )

    template(v-else-if="showMoveMultipleFilesContainer && isMyFilesCategory")
      .move-file__container
        BaseButton.icon-left-arrow.btn-back(
          :is-icon="true"
          :plain-hover="true"
          @click="setShowMoveMultipleFilesContainer(false)"
          @mouseleave="closeFolderOptions"
        )
        p.title Select folder
        .all-files__folder(
          v-if="activeFolder > 0"
          @click="moveToRootFiles"
        )
          svg#allFilesSvg(width='20' height='16' viewbox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg')
            path.icon-folder(d='M8.59 0.59C8.21 0.21 7.7 0 7.17 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2H10L8.59 0.59Z' fill='#22C993')
            path.icon-all-files(d='M10.5 5C10.6034 5 10.6996 5.02855 10.7859 5.07422H10.7915L13.8011 6.69206C13.9176 6.74139 14 6.86033 14 7C14 7.13867 13.9188 7.25761 13.8036 7.30794V7.31445L10.789 8.92708L10.7865 8.92578C10.6999 8.97178 10.6037 9 10.5 9C10.3963 9 10.3001 8.97178 10.2135 8.92578L10.211 8.92708L7.19638 7.31445V7.30794C7.0812 7.25761 7 7.13867 7 7C7 6.86033 7.08241 6.74139 7.19886 6.69206L10.2085 5.07422H10.2141C10.3004 5.02855 10.3966 5 10.5 5ZM13.212 8.37565L13.8011 8.69206C13.9176 8.74139 14 8.86033 14 9C14 9.13867 13.9188 9.25761 13.8036 9.30794V9.31445L10.789 10.9271L10.7865 10.9258C10.6999 10.9718 10.6037 11 10.5 11C10.3963 11 10.3001 10.9718 10.2135 10.9258L10.211 10.9271L7.19638 9.31445V9.30794C7.0812 9.25761 7 9.13867 7 9C7 8.86033 7.08241 8.74139 7.19886 8.69206L7.788 8.37565C8.71232 8.87132 9.97391 9.54577 9.98855 9.55143C10.1524 9.6281 10.324 9.66667 10.5 9.66667C10.6766 9.66667 10.8488 9.62778 11.0133 9.55078C11.0273 9.54545 12.2874 8.87165 13.212 8.37565ZM13.212 10.3757L13.8011 10.6921C13.9176 10.7414 14 10.8603 14 11C14 11.1387 13.9188 11.2576 13.8036 11.3079V11.3145L10.789 12.9271L10.7865 12.9258C10.6999 12.9718 10.6037 13 10.5 13C10.3963 13 10.3001 12.9718 10.2135 12.9258L10.211 12.9271L7.19638 11.3145V11.3079C7.0812 11.2576 7 11.1387 7 11C7 10.8603 7.08241 10.7414 7.19886 10.6921L7.788 10.3757C8.71232 10.8713 9.97391 11.5458 9.98855 11.5514C10.1524 11.6281 10.324 11.6667 10.5 11.6667C10.6766 11.6667 10.8488 11.6278 11.0133 11.5508C11.0273 11.5454 12.2874 10.8717 13.212 10.3757Z' fill='white')
          span.folder-name All Files
        ul.myfolder__list
          template(v-if="selectedFolderLevel.length")
            li.grid-item(
              v-for="item in selectedFolderLevel"
              :key="`folder-${item.id}`"
              :ref="`folder-${item.id}`"
            )
              FolderItem(
                v-bind="item"
                :ref="`folder-item-${item.id}`"
                :is-my-files-category="isMyFilesCategory"
                :is-moving-file="showMoveMultipleFilesContainer"
              )
          template(v-else)
            li.empty-folder__text No folder available...

    template(v-if="loader")
      template(v-if="isOnMusicTab")
        BasePreloader(
          :type=10
        )
      template(v-else)
        BasePreloader(
          :type=8
        )

    template(v-else)

      template(v-if="isOnMediaPixabayTab && !isPixabayAvailable")
        div.media-pixabay__not-available-message
          p.message Pixabay is currently not supported in your region.
          //- p.message
          //-   | Pixabay is currently not supported in your region. Please&nbsp;try
          //-   BaseButton.link-text(
          //-     :is-text="true"
          //-     :is-canvas="true"
          //-     @click="goToShutterstockVideos"
          //-   ) Shutterstock videos
          //-   | .
      template(v-else)
        template(
          v-if="!isMyFilesCategory || isMyFilesAllowed"
        )
          ul.grid-lists(
            :class="gridListClass"
            ref="gridList"
            @scroll="closeFileOptions"
          )
            template(v-if="isOnMusicTab")
              li.grid-item(v-for="item in visibleElements" :key="`${item.type}-${item.id}`")
                MusicItem(
                  :file="item.file"
                  :file-name="isOnMusicTeamUploadsTab ? item.file_name : ''"
                  :title="item.title"
                  :artist="item.artist"
                  :activeUrl="tempAudioUrl"
                  :is-user-files="item.isUserFiles"
                  :defaultStartTime="item.start_time"
                  :id="parseInt(item.id)"
                  :favId="item.favId"
                  :genres="item.genres ? item.genres : []"
                  :moods="item.moods ? item.moods : []"
                  :is-downloading="item.is_downloading || false"
                  :no-favourite="isOnMusicTeamUploadsTab"
                  :is-team="isOnMusicTeamUploadsTab"
                  @play-audio="playAudio"
                )
            template(v-else-if="isLineCategory")
              .col-1of1
                li.grid-item(v-for="item in visibleElements" :key="`${item.type}-${item.id}`" @mouseleave="closeFileOptions")
                  CategoryItem(
                    :isLineCategory="isLineCategory"
                    :isHoveringFileOptions="isHoveringOptions"
                    :hoveredId="hoveredId"
                    v-bind="item"
                    @toggle-file-options="showFileOptions = true"
                  )

            //- Note: only for Text, Stocks(Media), my-files/Upload, Saved
            template(v-else-if="!showMoveMultipleFilesContainer")
              template(v-if="evenElements.length || oddElements.length")
                .col-1of2.col-half
                  li.grid-item(
                    v-for="item in evenElements"
                    :key="`${item.type}-${item.id}`"
                    :ref="`${item.type}-${item.id}`"
                    @mouseleave="closeFileOptions"
                  )
                    CategoryItem(
                      v-bind="item"
                      :isHoveringFileOptions="isHoveringOptions"
                      :hoveredId="hoveredId"
                      @toggle-file-options="openFileOptions($event, `${item.type}-${item.id}`)"
                      :start_trim="item.start_trim || 0"
                      :end_trim="item.end_trim || null"
                      :is-unsplash-image="isUnsplashImage"
                      :is-selected-team-upload="selectedUploadSpace === 'team'"
                      @delete-file="deleteFile"
                    )
                .col-1of2.col-half
                  li.grid-item(
                    v-for="item in oddElements"
                    :key="`${item.type}-${item.id}`"
                    :ref="`${item.type}-${item.id}`"
                    @mouseleave="closeFileOptions"
                  )
                    CategoryItem(
                      v-bind="item"
                      :isHoveringFileOptions="isHoveringOptions"
                      :hoveredId="hoveredId"
                      @item-is-loaded="itemIsLoaded"
                      @toggle-file-options="openFileOptions($event, `${item.type}-${item.id}`)"
                      :start_trim="item.start_trim || 0"
                      :end_trim="item.end_trim || null"
                      :is-unsplash-image="isUnsplashImage"
                      :is-selected-team-upload="selectedUploadSpace === 'team'"
                      @delete-file="deleteFile"
                    )

      template(v-else)
        MyFileEmptyState(v-if="selectedUploadSpace === 'personal' && showFileEmptyState")

        //- Note: After discussion, we plan to leave it first
          .stock__empty-state(v-if="isStock || isGraphicTab || isBackgroundTab")
            img(src="@/assets/images/empty-state/emo-flat.svg")
            p.text
              span Opps..!
              br
              | No Element Found.
            BaseButton.button(
              :is-canvas="true"
              :is-primary="true"
              :is-tall="true"
            ) Explore {{ getActiveGroup === 'elements' ? "Graphics" : getActiveGroup }}

        .music__empty-state(v-if="getActiveGroup === 'music' && getActiveSubGroup === 'upload'")
          i.icon.icon-up-arrow-long
          p.text
            | Add your own track
            br
            | (.MP3 files)

        .favourite__empty-state(v-if="getActiveGroup === 'favourites'")
          h2.title No saved {{ getEmptyFavouriteFilter(activeFavFilter) }}
          h3.subtitle
            | Favourite {{ emptyFavouriteSubtitle(activeFavFilter) }}
            | by clicking the icon at top left of the element
          i.icon.icon-down-arrow-long
          template
            img(
              v-if="activeFavFilter === 'audio'"
              src="@/assets/images/empty-state/saved-music-empty-state.svg")
            img(
              v-else
              src="@/assets/images/empty-state/saved-empty-state.svg")

        .empty-element(
          v-if="isStock || isGraphicTab || isBackgroundTab || isOnMusicLibraryTab || isOnMusicTeamUploadsTab || isOnTeamUploadFilesTab"
        ) No elements found.
</template>

<script>
import {
  mapMutations, mapGetters, mapActions, mapState,
} from 'vuex';
import scrollMonitor from 'scrollmonitor';
import cloneDeep from 'lodash.clonedeep';
import { debounce } from 'debounce';
import { Drop } from 'vue-drag-drop';
import vClickOutside from 'v-click-outside';
import { groups, subgroups, favouriteFilters } from '@/assets/scripts/enums';

import helperMixins from '@/components/mixins/helper-mixins';
import uploadingMixins from '@/components/mixins/uploading-mixins';
import userFilesAndFolderMixins from '@/components/mixins/user-files-and-folder-mixins';

import Lottie from '@/components/ProjectCanvas/AssetSidebarCanvas/LottieShared.vue';
import CategoryItem from './children/CategoryItem.vue'; // eslint-disable-line
import MusicItem from './children/MusicItem.vue'; // eslint-disable-line
import FolderItem from './children/FolderItem.vue'; // eslint-disable-line
import TeamUploadsFolderDropdown from './children/TeamUploadsFolderDropdown.vue'; // eslint-disable-line
import DeleteItem from '@/components/ProjectCanvas/AssetsSidebar/children/DeleteItem.vue';
import VideoTrimButton from '@/components/ProjectCanvas/AssetsSidebar/children/VideoTrimButton.vue';
import AssetsFavourite from '@/components/ProjectCanvas/AssetsSidebar/children/AssetsFavourite.vue';
import MyFileEmptyState from '@/components/ProjectCanvas/AssetsSidebar/children/MyFileEmptyState.vue';

import Api from '@/services/api/Api';

let scrollTimeout;

export default {
  name: 'CategoryItemContainer',
  components: {
    CategoryItem,
    MusicItem,
    FolderItem,
    TeamUploadsFolderDropdown,
    Lottie,
    DeleteItem,
    AssetsFavourite,
    Drop,
    VideoTrimButton,
    MyFileEmptyState,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [
    userFilesAndFolderMixins,
    uploadingMixins,
    helperMixins,
  ],
  props: {
    fileActiveTab: { type: String, default: 'files' },
    elements: { type: Array, default: () => [] },
    loader: { type: Boolean },
    totalPages: { type: Number, default: 1 },
    currentPage: { type: Number, default: 1 },
    isUnsplashImage: { type: Boolean, default: false },
    selectedUploadSpace: { type: String, default: 'personal' },
  },
  data() {
    return {
      currentVisible: 30,
      tempAudioUrl: '',
      hasElements: true,
      isLineCategory: false,
      scrollWatchTimeout: null,
      cloneElements: [],
      hoveredId: 0,
      hoveredFileName: '',
      hoveredIsHtml: false,
      hoveredFavId: 0,
      hoveredOptionsTop: 0,
      hoveredOptionsLeft: 0,
      hoveredIsNotLogo: true,
      hoveredFavType: '',
      hoveredIsFavourite: false,
      hoveredVideoUrl: '',
      loadedItems: 0,
      showFileOptions: false,
      isHoveringOptions: false,
      showFolderOptions: false,
      isRenamingFolder: false,
      isHoveringFileOptions: false,
      showRetry: false,
      showVideoTrimButton: false,
      selectedNewFolder: {},
      isPixabayAvailable: true,
    };
  },
  methods: {
    ...mapMutations(['isTempAudioPlaying']),
    ...mapActions('canvasElements', ['addTextElementToCanvas']),
    ...mapMutations('canvasElements', ['emptyActiveElements', 'updateLayerTag']),
    ...mapMutations('assetsSidebar', ['setShowMoveMultipleFilesContainer', 'setActiveSubGroup', 'setActiveMediaOption']),
    ...mapActions('assetsSidebar', ['promptConfirmDeleteUserFile']),
    addNewItems() {
      this.currentVisible += this.newItems;
    },
    // eslint-disable-next-line func-names
    addScrollWatch: debounce(function () {
      const { group, subgroup } = this.getActiveGroupings;
      // console.log('~~addScrollWatch~~', this.currentPage, this.totalPages, this.currentVisible, this.elements.length);
      // This is for lazy loading the elements
      // For stock it needs to call the getElements
      this.$nextTick(() => {
        // this is to call next page
        const isShutterstockMusic = this.getStockEndpointVersion === 'v2'
          && group === groups.MUSIC
          && subgroup === subgroups.MUSIC;

        // console.log(this.elements.length, this.currentVisible, this.currentPage, this.totalPages);

        if (this.elements && this.elements.length <= this.currentVisible) {
          // this is to call next page
          this.$emit('endScroll');
          // if it's not stock and not shutterstock music
          // or it's stock and has total pages already
          if ((!this.isStock && !isShutterstockMusic) || this.currentPage === this.totalPages) {
            return;
          }
        }

        const containerElement = document.getElementsByClassName('grid-lists')[0];

        // console.log('containerElement', containerElement);
        if (containerElement) {
          const containerMonitor = scrollMonitor.createContainer(containerElement);

          const gridItemElement = containerElement.getElementsByClassName('grid-item');
          const lastIndex = gridItemElement.length - 1;
          const childElement = gridItemElement[lastIndex];
          const elementWatcher = containerMonitor.create(childElement);
          // console.log('childElement is', lastIndex);

          // set timeout so that it doesn't call nonstop
          this.scrollWatchTimeout = setTimeout(() => {
            elementWatcher.enterViewport(() => {
              // console.log('enterViewport', this.currentVisible, elementWatcher);
              this.addNewItems();
              this.addScrollWatch();
              elementWatcher.destroy();
              // console.log('end enterViewport', this.currentVisible);
            });
            elementWatcher.exitViewport(() => {
              this.addNewItems();
              this.addScrollWatch();
              elementWatcher.destroy();
            });
            clearTimeout(this.scrollWatchTimeout);
          }, 500);
        }
      });
    }, 500),
    playAudio(url, forcePlay = false) {
      const { tempAudio } = this.$refs;

      if (forcePlay) {
        // force play is from selected music
        this.isTempAudioPlaying(true);
        tempAudio.volume = typeof this.getSelectedMusic.volume !== 'undefined'
          ? this.getSelectedMusic.volume / 100
          : 1;
      } else {
        // make sure selected music is paused
        this.$root.$emit('pause-selected-audio');
        tempAudio.volume = 1;
        if (this.tempAudioUrl === url) {
          this.isTempAudioPlaying(!this.getIsTempAudioPlaying);
        } else {
          this.isTempAudioPlaying(true);
        }
        this.tempAudioUrl = '';
      }

      // console.log('playAudio url', url, this.getIsTempAudioPlaying);
      console.log('playAudio');

      if (this.getIsTempAudioPlaying) {
        this.tempAudioUrl = url;

        this.$nextTick(() => {
          tempAudio.play();
        });
      } else {
        tempAudio.pause();
        tempAudio.currentTime = 0;
      }
    },
    pauseAudio() {
      const { tempAudio } = this.$refs;
      this.isTempAudioPlaying(false);
      this.tempAudioUrl = '';

      if (tempAudio) {
        tempAudio.pause();
        tempAudio.currentTime = 0;
      }
    },
    checkSidebarElements() {
      const { group, subgroup, category } = this.getActiveGroupings;
      if (group === groups.FAVOURITES) return;

      let selectedSlug = category.slug;
      if (group === groups.MYFILES) selectedSlug = this.getActiveFileCategory;
      const sidebarElements = this.getSidebarElements[`${group}/${subgroup}/${selectedSlug}`];

      this.hasElements = !!(typeof sidebarElements !== 'undefined' && sidebarElements.length);

      // if (typeof sidebarElements === 'undefined' && group === groups.MEDIA && (subgroup === subgroups.VIDEOS || subgroup === subgroups.PHOTOS)) {
      //   this.$emit('endScroll', true);
      if (
        !(
          group === groups.MEDIA
          && (subgroup === subgroups.VIDEOS || subgroup === subgroups.PHOTOS)
        )
        && !(
          this.getStockEndpointVersion === 'v2'
          && group === groups.MUSIC
          && subgroup === subgroups.MUSIC
        )
        && typeof sidebarElements !== 'undefined'
        && sidebarElements.length
      ) {
        this.addScrollWatch();
      }
    },
    checkIsLineCategory() {
      return this.getActiveCategory.isLine || false;
    },
    openFolderOptions(id, ref) {
      const clientRect = this.$refs[ref][0].getBoundingClientRect();
      this.hoveredOptionsTop = clientRect.top - document.getElementById('main-header').getBoundingClientRect().height;
      this.hoveredOptionsLeft = clientRect.right
        - document.querySelector('.assets-container .assets-navigation').getBoundingClientRect()
          .width;

      this.hoveredId = id;
      this.showFolderOptions = true;
    },
    closeFolderOptions(forceClose = false) {
      if (forceClose) this.isHoveringOptions = false;
      setTimeout(() => {
        if (!this.isHoveringOptions) {
          this.showFolderOptions = false;
          this.isRenamingFolder = false;

          this.hoveredOptionsTop = 0;
          this.hoveredOptionsLeft = 0;

          this.hoveredId = 0;
        }
      }, 10);
    },
    openFileOptions(emitData, ref) {
      const {
        elementData, fileName, isNotLogo, favId, favType, isFavourite, showRetry,
      } = emitData;

      // console.log('elementData',elementData);

      const clientRect = this.$refs[ref][0].getBoundingClientRect();
      this.hoveredOptionsTop = clientRect.top - document.getElementById('main-header').getBoundingClientRect().height;
      this.hoveredOptionsLeft = clientRect.right
        - document.querySelector('.assets-container .assets-navigation').getBoundingClientRect()
          .width;

      this.hoveredId = elementData.file_id;
      this.hoveredIsHtml = elementData.data.isHtml;
      this.hoveredIsNotLogo = isNotLogo;
      this.hoveredFavId = favId;
      this.hoveredFavType = favType;
      this.hoveredIsFavourite = isFavourite;
      this.showRetry = showRetry;

      this.hoveredVideoUrl = '';

      if (this.isMyFilesCategory) {
        this.hoveredFileName = fileName;

        if (elementData.type === 'videos') {
          this.showVideoTrimButton = true;

          this.hoveredVideoUrl = elementData.data.url || elementData.data.thumb;
        }
      }

      this.showFileOptions = true;
    },
    closeFileOptions(forceClose = false) {
      // return; // style debugging purposes

      if (forceClose) this.isHoveringOptions = false;
      setTimeout(() => {
        if (!this.isHoveringOptions) {
          this.showFileOptions = false;

          this.hoveredOptionsTop = 0;
          this.hoveredOptionsLeft = 0;

          this.hoveredId = 0;
          this.hoveredIsHtml = false;
          this.hoveredIsNotLogo = true;
          this.hoveredFavId = 0;
          this.hoveredFavType = '';
          this.hoveredIsFavourite = false;
          this.showRetry = false;
          this.hoveredVideoUrl = '';
          this.showVideoTrimButton = false;

          if (this.isMyFilesCategory) {
            this.hoveredFileName = '';
          }
        }
      }, 10);
    },
    itemIsLoaded() {
      if (this.loadedItems > this.newItems) return;

      this.loadedItems = this.loadedItems + 1;

      // reached max but its still short to show the scroll bar,
      // call add item again
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        // console.log('itemIsLoaded', this.$refs.gridList.scrollHeight, this.$refs.gridList.offsetHeight);
        if (
          this.loadedItems === this.newItems
          && this.$refs.gridList
          && this.$refs.gridList.scrollHeight <= this.$refs.gridList.offsetHeight
        ) this.addNewItems();
      }, 2000);
    },
    triggerFocusFolderName() {
      this.showFolderOptions = false;
      const folder = `folder-item-${this.hoveredId}`;
      this.$refs[folder][0].focusFolderName();
      this.isRenamingFolder = true;
    },
    retryUpload() {
      if (!this.showRetry) return;

      console.log('retry upload');

      this.closeFileOptions(true);

      Api.retryUpload({
        file_id: this.hoveredId,
      })
        .then(() => {
          this.alertSuccess('Hey there!', 'Retrying upload...', 3000, 'TopCenterNotif');
          this.showRetry = false;
        })
        .catch((error) => {
          console.log(error);
          this.alertError('', 'Retrying upload failed.', 5000, 'TopCenterNotif');
        });
    },
    onPlaySelectedAudio({ url, play }) {
      this.pauseAudio(); // pause all audio
      if (play) this.playAudio(url, true);
    },
    onPauseAllAudio() {
      this.$root.$emit('pause-selected-audio');
      this.pauseAudio();
    },
    editFolder(id) {
      this.hoveredId = id;
      this.triggerFocusFolderName();
    },
    deleteFolder(id) {
      this.promptConfirmDeleteUserFile({
        itemId: id,
        isFolder: true,
      });
    },
    deleteFile(id) {
      this.hoveredId = id;
      this.promptConfirmDeleteUserFile({ itemId: id });
    },
    decreaseFolderLevel() {
      if (this.selectedUploadSpace === 'personal' && !this.isOnMusicTeamUploadsTab) {
        if (this.getActiveSubGroup === 'logos') this.setActiveSubGroup('images');
        this.setActiveFolder(null);
      } else { // Team
        const folderDestination = this.getFolderList.find(folder => folder === this.selectedFolder);
        this.setActiveFolder(folderDestination.parent_id);
        // since at music tab, user can do searching and will keep replacing data, so any change on team music uploads should constantly fetch API
        if (this.isOnMusicTeamUploadsTab) this.$emit('select-folder');
      }
    },
    moveToRootFiles() {
      this.setMoveAllFilesFolder(0);
      this.moveMultipleFiles();
      this.setActiveFolder(null);
    },
    selectFolder() {
      this.$emit('select-folder');
    },
    checkPixabayAvailability() {
      const video = document.createElement('video');
      video.src = 'https://cdn.pixabay.com/video/2016/09/06/4968-181688475_small.mp4';

      video.onloadeddata = () => {
        this.isPixabayAvailable = true;
      };
      video.onerror = () => {
        this.isPixabayAvailable = false;
      };
    },
    goToShutterstockVideos() {
      this.setActiveMediaOption('video-shutterstock');
    },
    /** Empty States - Favourite Tab */
    getEmptyFavouriteFilter(filter) {
      let name = filter;
      if (filter === 'elements') name = 'graphics';
      else if (filter === 'myfiles') name = 'uploads';

      return name;
    },
    emptyFavouriteSubtitle(filter) {
      let subtitle = '';
      if (filter === 'elements') subtitle = 'a graphic';
      else if (filter === 'backgrounds') subtitle = 'a background';
      else if (filter === 'myfiles') subtitle = 'an upload';
      else if (filter === 'audio') subtitle = 'an audio';

      return subtitle;
    },
  },
  computed: {
    ...mapState(['showSwap']),
    ...mapGetters(['getIsPlaying', 'getIsTempAudioPlaying', 'getDefaultFont']),
    ...mapGetters('assetsSidebar', [
      'getActiveGroup',
      'getActiveSubGroup',
      'getActiveCategory',
      'getActiveGroupings',
      'getActiveFileCategory',
      'getSidebarElements',
      'getStockEndpointVersion',
      'getActiveMediaOption',
    ]),
    ...mapGetters('canvasElements', [
      'getCurrentSceneDuration',
      'getSelectedMusic',
      'getSwappedElement',
    ]),
    ...mapGetters('userData', ['isDesigner']),
    ...mapState('assetsSidebar', [
      'userFolders',
      'teamFolders',
      'activeFolder',
      'isMultipleFileSelection',
      'activeFavFilter',
      'skeletonList',
      'showMoveMultipleFilesContainer',
      'teamMusicFoldersSearchResult',
    ]),
    isStock() {
      return this.getActiveGroup === groups.MEDIA && this.getActiveSubGroup !== subgroups.MASKS;
    },
    isInsideFolder() {
      const isFolder = this.isMyFilesCategory && this.fileActiveTab === 'folders';
      const isOnPersonalLogo = this.getActiveSubGroup === 'logos' && this.selectedUploadSpace !== 'team';

      return (
        !this.showMoveMultipleFilesContainer
        && (((isFolder || this.isOnMusicTeamUploadsTab) && this.showbackButton) || isOnPersonalLogo)
      );
    },
    newItems() {
      if (this.isStock && this.getStockEndpointVersion === 'v2') return 24;
      return 20;
    },
    maximumVisible() {
      return this.currentVisible;
    },
    filteredElements() {
      const { group, subgroup } = this.getActiveGroupings;
      const elements = cloneDeep(this.elements);

      if (
        group === 'my-files'
        && Object.keys(this.skeletonList).length
        && elements
        && elements.length
      ) {
        // console.log('WENT IN HEREE~!~!!!', elements[0].fileName)
        if (elements[1]) console.log('FILE NAME 2', elements[1].fileName);
        for (let i = 0; i < Object.keys(this.skeletonList).length; i += 1) {
          const id = Object.keys(this.skeletonList)[i];
          // console.log('skeleton subgroup', id, this.skeletonList[id].type, subgroup)
          if (this.skeletonList[id].type === subgroup) {
            elements.unshift(this.skeletonList[id]);
          }
        }
      }
      // if (elements.length) console.log('is there skeleton', Object.keys(this.skeletonList).length, elements[0].fileName, elements[0].file_name);
      // if (elements.length && elements[1]) console.log('2 is there skeleton', elements[1].fileName, elements[1].file_name)

      return elements;
    },
    visibleElements() {
      if (this.filteredElements && this.filteredElements[0]) {
        console.log(
          'VISIBLE ELEMNETS',
          this.filteredElements[0].fileName,
          this.filteredElements[0].file_name,
        );
      }
      return this.filteredElements
        ? this.filteredElements.filter((element, index) => index < this.maximumVisible)
        : [];
    },
    evenElements() {
      return this.filteredElements
        ? this.filteredElements.filter(
          (element, index) => index < this.maximumVisible && index % 2 === 0,
        )
        : [];
    },
    oddElements() {
      return this.filteredElements
        ? this.filteredElements.filter(
          (element, index) => index < this.maximumVisible && index % 2 !== 0,
        )
        : [];
    },
    isShowTeamFolders() {
      return this.selectedUploadSpace === 'team' || this.isOnMusicTeamUploadsTab;
    },
    getFolderList() {
      return !this.isShowTeamFolders ? this.userFolders : this.teamFolders;
    },
    selectedFolderLevel() {
      // Selected folder based on level (root or inside folder)
      let folders = !this.isShowTeamFolders ? this.userFolders : this.teamFolders;

      if (this.selectedUploadSpace === 'team') {
        // get based on selected folder
        folders = folders.filter(folder => folder.parent_id === this.activeFolder);
      }

      if (
        typeof folders === 'undefined'
        || folders.length === 0
        || !Array.isArray(folders)
      ) return [];
      return folders;
    },
    insideFolderList() {
      let folderData = [];
      const { slug } = this.getActiveCategory;
      const isSearchingMusic = this.isOnMusicTeamUploadsTab && slug && slug !== 'all';

      if (isSearchingMusic) folderData = this.teamMusicFoldersSearchResult;
      else if (this.getFolderList.length) folderData = this.getFolderList.filter(folder => folder.parent_id === this.activeFolder);

      return folderData;
    },
    selectedFolder() {
      let folderData = [];
      if (this.getFolderList.length) {
        // eslint-disable-next-line
        this.getFolderList.find((folder) => {
          if (folder.id === this.activeFolder) {
            folderData = folder;
          }
        });
      }
      return folderData;
    },
    showFolders() {
      return (
        this.fileActiveTab === 'folders'
        && this.activeFolder === null
        && this.getActiveGroup === groups.MYFILES
        && this.getActiveSubGroup !== subgroups.LOGO
        && !this.isOnMusicTeamUploadsTab
      );
    },
    showSkeleton() {
      console.log('showSkeleton,=', Object.keys(this.skeletonList).length, this.skeletonList);
      return !!Object.keys(this.skeletonList).length;
    },
    matchedCategorySkeleton() {
      // only return the one with matching category, eg. video/image
      if (this.getActiveGroup !== groups.MYFILES) return [];
      const idArray = Object.keys(this.skeletonList);
      console.log('idArray', idArray);
      const skeletonArray = idArray.map(id => this.skeletonList[id]);
      return skeletonArray.filter(item => item.type === this.getActiveSubGroup);
    },
    isOnMusicTab() {
      return (
        this.getActiveGroup === groups.MUSIC
        || (this.getActiveGroup === groups.FAVOURITES
          && this.activeFavFilter === favouriteFilters.MUSIC)
      );
    },
    isMyFilesCategory() {
      return this.getActiveGroup === groups.MYFILES;
    },
    isMediaTab() {
      return this.getActiveGroup === groups.MEDIA;
    },
    isGraphicTab() {
      return this.getActiveGroup === groups.ELEMENTS;
    },
    isBackgroundTab() {
      return this.getActiveGroup === groups.BACKGROUNDS;
    },
    styleObject() {
      const styleObject = {};

      styleObject.top = `${this.hoveredOptionsTop}px`;
      styleObject.left = `${this.hoveredOptionsLeft}px`;

      return styleObject;
    },
    isSwapping() {
      return this.showSwap && this.getSwappedElement;
    },
    isMyFilesAllowed() {
      if (
        (this.isMyFilesCategory && this.fileActiveTab === 'files')
        || (this.isMyFilesCategory && this.fileActiveTab === 'folders' && this.showbackButton)
        || this.getActiveSubGroup === 'logos'
      ) {
        return true;
      }
      return false;
    },
    gridListClass() {
      const cssClass = [];
      if (this.isOnMusicTab) cssClass.push('grid-lists--music');
      if (this.isMyFilesCategory) cssClass.push('grid-lists--my-files');
      if (this.isMediaTab) cssClass.push('grid-lists--media');
      if (this.isMultipleFileSelection) cssClass.push('has-actions');

      return cssClass.join(' ');
    },
    showFileEmptyState() {
      return (
        this.isMyFilesCategory
        && (this.fileActiveTab === 'files' || this.activeFolder > 0 || this.getActiveSubGroup === subgroups.LOGO)
      );
    },
    isOnMediaPixabayTab() {
      return this.getActiveMediaOption === 'video-pixabay';
    },
    isOnTeamUploadFilesTab() {
      return this.isMyFilesCategory && this.fileActiveTab === 'files' && this.selectedUploadSpace === 'team';
    },
    isOnMusicLibraryTab() {
      return this.isOnMusicTab && this.getActiveSubGroup === subgroups.MUSIC;
    },
    isOnMusicTeamUploadsTab() {
      return this.getActiveGroup === groups.MUSIC && this.getActiveSubGroup === subgroups.TEAM_UPLOAD;
    },
    isShowTeamUploadsFolderDropdown() {
      return this.selectedUploadSpace === 'team' || this.isOnMusicTeamUploadsTab;
    },
  },
  beforeMount() {
    const defaultItemHeight = 60; // assuming 60px minimum height for each item.
    const initialVisibleItems = Math.floor(window.innerHeight / defaultItemHeight);
    this.currentVisible = this.currentVisible < initialVisibleItems ? initialVisibleItems : this.currentVisible;
  },
  beforeDestroy() {
    this.$root.$off('play-selected-audio', this.onPlaySelectedAudio);
    this.$root.$off('pause-all-audio', this.onPauseAllAudio);
  },
  mounted() {
    this.isLineCategory = this.checkIsLineCategory();
    this.checkSidebarElements();
    this.checkPixabayAvailability();
    this.$root.$on('play-selected-audio', this.onPlaySelectedAudio);
    this.$root.$on('pause-all-audio', this.onPauseAllAudio);

    if (this.getActiveGroup === groups.TEXTS) {
      // console.log('TEXTS');
      this.addScrollWatch();
    }
  },
  watch: {
    // issue: Text tab doesn't have loader
    loader(val) {
      // console.log('loader', val, this.skeletonList);
      // when loader has disappeared, trigger scrollwatch
      if (!val) this.addScrollWatch();
    },
    getIsPlaying() {
      if (this.getIsPlaying && this.getIsTempAudioPlaying) {
        this.playAudio(this.tempAudioUrl);
      }
    },
    getActiveGroup() {
      // console.log('!!getActiveGroup', this.getActiveGroup);
      if (this.getActiveGroup !== 'music' && this.getIsTempAudioPlaying) {
        this.playAudio(this.tempAudioUrl);
      }
      if (this.newItems > this.currentVisible) {
        this.currentVisible = this.newItems;
      }
    },
    getSidebarElements: {
      handler() {
        // console.log(val, 'getSidebarElements');
        // console.log('!!get sidebar elements');
        this.checkSidebarElements();
      },
      deep: true,
    },
    getActiveCategory() {
      this.isLineCategory = this.checkIsLineCategory();
    },
    selectedFolderLevel: {
      handler(val) {
        if (this.isCreatingFolder) {
          // eslint-disable-next-line
          this.selectedNewFolder = val[0];

          setTimeout(() => {
            this.selectedNewFolder = {};
          }, 1500);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-element {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
  margin: 10px 0;
  margin-top: 20px;
  color: $lightWhite;
}

.insert-text__wrapper {
  padding: 0 10px 0 7px;
}

.btn-add-text {
  margin-bottom: 10px;
  background: $darkGrey5;
  color: $light;
  border: none;
  transition: box-shadow 0.2s ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: $darkGrey900;
  }
}

.btn-add-heading {
  font-size: 1.325em;
  font-weight: 800;
}

.btn-add-subheading {
  font-size: 0.9em;
  font-weight: 600;
}

.btn-add-bodytext {
  font-size: 0.8em;
  font-weight: 100;
}

.file-name {
  font-size: 1.125em;
  font-weight: 600;
  padding: 0 10px;
  word-break: break-all;
}
</style>

<style lang="scss">
.category-item-container {
  &.inside-folder {
    .grid-lists--my-files {
      max-height: calc(100% - 80px);
    }
  }

  .myfolder__list {
    margin: 10px 0 0 0;
    padding: 0;

    &.list--team {
      padding: 0 10px;
      max-height: 210px;
      margin-right: 3px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      overflow-x: hidden;
      border-bottom-left-radius: $componentBorderRadius;
      border-bottom-right-radius: $componentBorderRadius;
      background: $darkGrey500;
      margin-top: 0;

      &.is-renaming-folder {
        .grid-item {
          background: $darkGrey500 !important;
        }
      }
    }

    &.single-folder {
      overflow: visible;
    }

    .grid-item {
      margin: 0;
      overflow: visible;

      &.is-hovering-folder-options {
        .icon-preview {
          color: $green600 !important;
        }

        .input-field {
          color: $green600 !important;
        }
      }
    }

    .empty-folder__text {
      list-style-type: none;
      color: $lightGrey100;
      text-align: center;
    }
  }
}

.my-folder-options,
.my-file-options {
  position: absolute;
  padding: 10px;
  width: 100%;
  max-width: 275px;
  border-radius: 4px;
  width: calc(100% - 8px);
  background: $light;
  z-index: 25;
  box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.1), 0px 26px 26px rgba(10, 31, 68, 0.12);
}

.my-folder-options {
  max-width: 175px;

  button {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    font-size: 1em;
    padding: 10px;
    font-weight: 500;
    margin: 0;

    &.btn-delete {
      color: $red;
    }

    &:hover {
      background: $lightGrey2;
    }
  }
}

.logo-folder {
  display: flex;
  align-items: center;
  padding: 5px 0;

  &:hover,
  &:active,
  &.is-active {
    #logoFolderSvg {
      path {
        fill: $green600;

        &.triangle {
          fill: $light;
        }
      }
    }

    .logo-folder__button {
      color: $green600 !important;
    }
  }
}

#logoFolderSvg {
  transform: scale(1.2);
  margin-left: 5px;
}

.logo-folder__button {
  width: 100%;
  min-height: 30px;
  color: $light !important;
  text-align: left;
  font-size: 14px !important;
  margin-left: 15px;

  .btn__content {
    span {
      font-weight: 400;
    }
  }
}

.my-file-options {
  > * {
    border-bottom: 1px solid $canvasBg;
    display: block;
    margin: 10px 0;

    &:last-child {
      margin-top: 0;
      margin-bottom: 0;
    }

    &:first-child,
    &:last-child {
      border-bottom: none;
    }
  }

  .btn-delete,
  .btn-retry,
  .btn-favourite,
  .btn-trim-video {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: auto;
    font-size: 1em;
    padding: 10px;
    color: $red;
    font-weight: 500;
    margin: 0;

    &:hover {
      background: $lightGrey2;
    }

    .icon {
      margin-right: 10px;
    }
  }

  .btn-favourite,
  .btn-trim-video {
    flex-direction: row;
    justify-content: flex-start;
    color: $inputLabel;
  }
}

.root-folder-wrapper {
  .btn-back-folder {
    margin-top: 10px;
    padding-left: 12px;
    color: $textGrey3;

    &:hover {
      background: transparent;
    }

    .icon {
      color: $blue;
    }
  }

  .selected-folder__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;

    .btn-back {
      flex: 0 0 10%;
      color: $light;
      font-size: 20px;
    }

    .selected-folder {
      flex: 0 0 80%;
      display: flex;
      justify-content: center;
      align-items: center;

      .folder-name,
      .icon-files-menu {
        color: $light;
      }

      .folder-name {
        margin: 0 10px;
        font-size: 13px;
      }
    }
  }
}

.move-file__container {
  padding: 15px;
  overflow: auto;
  min-height: 100%;

  &::-webkit-scrollbar-track {
    background-color: rgba($darkGrey600, 0);
    transition: background-color 0.5s ease-in-out;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($darkGrey200, 0);
    transition: background-color 0.5s ease-in-out;
  }

  &:hover::-webkit-scrollbar-track {
    background-color: $darkGrey600;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: $darkGrey200;
  }

  .btn-back {
    color: $light;
    font-size: 20px;

    &:hover,
    &:focus,
    &:active {
      color: $green600;
    }
  }

  .title {
    color: $light;
    font-weight: 600;
  }

  .all-files__folder {
    display: flex;
    align-items: center;
    margin-top: 30px;
    padding: 0 10px;
    cursor: pointer;

    &:hover,
    &:active,
    &.is-active {
      #allFilesSvg {
        .icon-folder {
          fill: $green600;
        }

        .icon-all-files {
          fill: $light;
        }
      }

      .folder-name {
        color: $green600;
      }
    }

    #allFilesSvg {
      transform: scale(1.3);

      .icon-folder {
        fill: $light;
      }

      .icon-all-files {
        fill: $darkGrey700;
      }
    }

    .folder-name {
      color: $light;
      margin-left: 18px;
      font-size: 14px;
    }
  }
}

.media-pixabay__not-available-message {
  color: $light;
  text-align: left;
  margin-top: 10px;
  background: $darkGrey600;
  padding: 12px 15px;
  border-radius: 5px;

  .message {
    margin: 0;
    padding: 0;
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1.8;
  }

  .link-text {
    display: inline-block;
    color: $light;
    margin-left: 5px;
    padding: 0;
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1.8;
    transition: color 0.3s ease-in-out;

    span {
      text-decoration: underline;
    }

    &:hover {
      color: $lightGrey500;
    }
  }
}

.grid-lists {
  &.grid-lists--my-files {
    max-height: calc(100% - 65px);
    padding-bottom: 4.5rem !important;

    @include smallest() {
      max-height: calc(100% - 75px);
    }
  }

  &.has-actions {
    height: calc(100% - 55px);
    margin-bottom: 60px;
    padding-bottom: 30px !important;

    @include smallest() {
      padding-bottom: 50px !important;
    }
  }

  &.grid-lists--media {
    padding-bottom: 30px !important;
  }
}

.my-files__folders-tab {
  margin-top: 20px;
  padding-left: 20px;
  overflow: auto;
  padding-bottom: 20px;

  @include smallest() {
    padding-bottom: 70px;
  }

  &.no-folder {
    padding-left: 0 !important;
  }

  .btn-add-folder {
    color: $light;
    text-align: left;
    font-size: 16px;
    padding-right: 5px;

    .btn__content {
      display: flex;
      align-items: center;

      .icon {
        margin: 0 22px 0 2.5px;
      }
    }

    &:hover,
    &:active {
      color: $green600;
    }
  }
}

.stock__empty-state {
  margin-top: 35px;
  text-align: center;

  .text {
    color: $light;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    span {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .button {
    text-transform: capitalize;
    font-weight: 400;
  }
}

.music__empty-state {
  margin-top: 12px;
  text-align: center;
  position: absolute;
  left: 48px;

  .icon {
    color: $green600;
    font-size: 22px;
  }

  .text {
    color: $light;
    font-size: 13px;
    font-weight: 300;
    line-height: 16px;
    margin: 8px 0 0 0;
    text-align: left;
  }
}

.favourite__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;

  .title {
    margin: 0 0 8px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $light;
    text-transform: capitalize;
  }

  .subtitle {
    margin: 0;
    font-size: 11px;
    font-weight: 300;
    line-height: 16px;
    color: $light;
    text-align: center;
  }

  .icon {
    color: $green600;
    font-size: 22px;
    margin: 12px 0;
  }
}

.btn-uploads-dropdown {
  color: inherit;
  font-size: inherit;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
    background: transparent;
  }

  &> .icon--after {
    font-size: 0.688em;
    margin-top: 3px;
    margin-left: 10px;
  }

  &.is-disabled {
    pointer-events: none;
  }
}

.btn-uploads-dropdown__option {
  color: $darkGrey400;
  font-size: inherit;
  justify-content: space-between;
  padding: 10px;

  &:hover {
    background: $lightGrey200
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $canvasBg;
  }

  .icon--after {
    color: $green700;
  }
}

</style>
