<template lang="pug">
.swap-temp(
  :class="{ 'is-masks': isMask, 'is-loading': isLoading}"
)
  Drop(
    @drop="swapElement"
  )
    template(v-if="this.getActiveElements.length === 1 && tempElement.data && tempElement.data.id")
      drr.swap-temp__container(
        :x="containerObject.x"
        :y="containerObject.y"
        :w="containerObject.width"
        :h="containerObject.height"
        :angle="containerObject.angle"
        :draggable="false"
        :resizable="false"
        :rotatable="false"
        :style="swapContainerStyle"
        @dblclick.native="$root.$emit('confirm')"
      )
        BasePreloader.swap-temp__preloader(
          v-if="isLoading"
        )
    CanvasElement(
      :item="tempElement"
      :animationTimeline="animationTimeline"
      isSwap=true
      @element-loaded="debounceHandleElementLoaded"
    )
    template(v-if="isMask && showMasksTemp")
      //- use this to test if innerbound is correct
      //- drr.innerbound-temp(
      //-   :x="innerBound.x"
      //-   :y="innerBound.y"
      //-   :w="innerBound.w"
      //-   :h="innerBound.h"
      //- )
      drr.masks-temp(
        :x="masksTemp.x"
        :y="masksTemp.y"
        :w="masksTemp.w"
        :h="masksTemp.h"
        :angle="masksTemp.angle"
        :innerBound="innerBound"
        :selected="true"
        :aspectRatio="true"
        ref="maskTemp"
        :rotatable="false"
        :extraStyle="masksStyle"
        :onlyAspectResize="true"
        @drag="updateMasksRect"
        @resize="updateMasksRect"
        @dragstart="updateOriginRect"
        @resizestart="updateOriginRect"
        @dblclick.native="$root.$emit('confirm')"
      )
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import { Drop } from 'vue-drag-drop';
// eslint-disable-next-line
import { debounce } from 'debounce';
import drr from '@/plugins/@minogin/vue-drag-resize-rotate';

import CanvasElement from '@/components/ProjectCanvas/CanvasContainer/children/CanvasElement.vue';

export default {
  name: 'SwapTemp',
  data() {
    return {
      tempElement: {},
      animationTimeline: 'temp',
      isMask: false,
      maskUrl: '',
      masksTemp: {
        x: 0,
        y: 0,
        w: 5,
        h: 5,
        angle: 0,
      },
      originalRect: {
        x: 0,
        y: 0,
        w: 5,
        h: 5,
        angle: 0,
      },
      svgRatio: 1,
      showMasksTemp: true, // this is to force drr position to return to the correct place
      originalWidth: 0,
      originalHeight: 0,
      imageUrl: '',
      isLoading: false,
    };
  },
  components: {
    CanvasElement,
    drr,
    Drop,
  },
  computed: {
    ...mapGetters(['getCanvasZoom']),
    ...mapGetters('canvasElements', ['getActiveElements', 'getSwappedElement']),
    innerBound() {
      const innerBound = {
        x: this.tempElement.position.x,
        y: this.tempElement.position.y,
        w: this.tempElement.size.width,
        h: this.tempElement.size.height,
      };

      return innerBound;
    },
    masksStyle() {
      const styleObject = {};

      if (this.tempElement.type === 'masks' && this.tempElement.data.image.url) {
        styleObject.backgroundImage = `url(${this.tempElement.data.image.url})`;
        styleObject.backgroundSize = '100% 100%';
      }

      return styleObject;
    },
    containerObject() {
      const containerObject = {};

      containerObject.width = this.getActiveElements[0].size.width * this.getCanvasZoom;
      containerObject.height = this.getActiveElements[0].size.height * this.getCanvasZoom;
      containerObject.x = this.getActiveElements[0].position.x * this.getCanvasZoom;
      containerObject.y = this.getActiveElements[0].position.y * this.getCanvasZoom;
      containerObject.angle = this.getActiveElements[0].rotate;

      this.updateSwapContainer(containerObject);

      return containerObject;
    },
    isImage() {
      // don't include this.tempElement.isImage because it can be graphics
      return this.tempElement.type === 'images';
    },
    isWebm() {
      return this.tempElement.data.url && this.tempElement.data.url.split('.').pop() === 'webm';
    },
    isVideo() {
      return this.tempElement.type === 'videos'; // && !this.isWebm;
    },
    isShape() {
      return this.tempElement.type === 'shapes';
    },
    swapContainerStyle() {
      const styleObject = {};

      // styleObject.backgroundImage = `url(${this.imageUrl})`;
      // styleObject.backgroundSize = 'cover';

      return styleObject;
    },
    // isMask() {
    // console.log('computed isMask', this.tempElement.type);
    // return this.tempElement.type === 'masks';
    // },
  },
  methods: {
    ...mapMutations('canvasElements', [
      'updateSwapContainer',
      'updateSwappedElementMaskImage',
      'updateSwappedElement',
    ]),
    swapElement({ elementData }) {
      this.updateSwappedElement(elementData);
    },
    updateMasksTemp() {
      // console.log('  updateMasksTemp', this.tempElement.data.image.isNew);
      let svg = document.body.querySelector('.swap-temp .canvas-element svg');

      if (!svg) {
        svg = document.body.querySelector('.is-swapping .canvas-element svg');
      }
      // if (svg && typeof svg !== null) {
      // console.log('svg', svg, typeof svg)
      const clipPath = svg.querySelector('clipPath');
      // eslint-disable-next-line
      const svgImage = svg.querySelector('image');
      const viewBoxArr = svg.getAttribute('viewBox').split(' ');
      const containerWidth = viewBoxArr[2];
      // eslint-disable-next-line
      const containerHeight = viewBoxArr[3];

      this.svgRatio = containerWidth / (this.tempElement.size.width * this.getCanvasZoom);

      // eslint-disable-next-line
      const diff = {
        left: svg.getBoundingClientRect().left - clipPath.getBoundingClientRect().left,
        top: svg.getBoundingClientRect().top - clipPath.getBoundingClientRect().top,
      };

      const imageLeft = typeof this.tempElement.data.image.left !== 'undefined'
        ? this.tempElement.data.image.left
        : this.tempElement.data.image.x;
      const imageTop = typeof this.tempElement.data.image.top !== 'undefined'
        ? this.tempElement.data.image.top
        : this.tempElement.data.image.y;

      const image = {
        width: parseFloat(this.tempElement.data.image.width / this.svgRatio) / this.getCanvasZoom,
        height: parseFloat(this.tempElement.data.image.height / this.svgRatio) / this.getCanvasZoom,
        left: imageLeft / this.svgRatio / this.getCanvasZoom,
        top: imageTop / this.svgRatio / this.getCanvasZoom,
      };
      // console.table(image);

      // console.log('svg image', svgImage !== null ? svgImage.getAttribute('width') : null, this.tempElement.data.image.width);

      const element = {
        left: this.tempElement.position.x - this.tempElement.size.width / 2,
        top: this.tempElement.position.y - this.tempElement.size.height / 2,
        width: this.tempElement.size.width,
        height: this.tempElement.size.height,
      };
      // console.table(element);

      this.masksTemp.x = element.left + image.left + image.width / 2;
      this.masksTemp.y = element.top + image.top + image.height / 2;
      this.masksTemp.w = image.width;
      this.masksTemp.h = image.height;

      this.showMasksTemp = true;

      // console.log('this.masksTemp', this.masksTemp);
    },
    updateMasksRect(rect) {
      // update the mask image position
      let svg = document.body.querySelector('.swap-temp .canvas-element svg');

      if (!svg) {
        svg = document.body.querySelector('.is-swapping .canvas-element svg');
      }

      const svgImage = svg.querySelector('image');

      // compare the difference between the original x, y, w, h
      // and the current position x, y, w, h
      const diff = {
        x: rect.x - rect.w / 2 - (this.originalRect.x - this.originalRect.w / 2),
        y: rect.y - rect.h / 2 - (this.originalRect.y - this.originalRect.h / 2),
        w: rect.w - this.originalRect.w,
        h: rect.h - this.originalRect.h,
      };

      // console.log('rect', rect.x, this.originalRect.x, diff.x);

      // previous x, y, w, h
      const old = {
        x: parseFloat(svgImage.getAttribute('x')),
        y: parseFloat(svgImage.getAttribute('y')),
        w: parseFloat(svgImage.getAttribute('width')),
        h: parseFloat(svgImage.getAttribute('height')),
      };

      const updatedRect = {
        x: old.x + diff.x * this.svgRatio * this.getCanvasZoom,
        y: old.y + diff.y * this.svgRatio * this.getCanvasZoom,
        width: old.w + diff.w * this.svgRatio * this.getCanvasZoom,
        height: old.h + diff.h * this.svgRatio * this.getCanvasZoom,
      };

      svgImage.setAttribute('x', updatedRect.x);
      svgImage.setAttribute('y', updatedRect.y);
      svgImage.setAttribute('width', updatedRect.width);
      svgImage.setAttribute('height', updatedRect.height);

      this.tempElement.data.image.left = updatedRect.x;
      this.tempElement.data.image.top = updatedRect.y;
      this.tempElement.data.image.width = updatedRect.width;
      this.tempElement.data.image.height = updatedRect.height;

      this.updateSwappedElementMaskImage({
        left: updatedRect.x,
        top: updatedRect.y,
        width: updatedRect.width,
        height: updatedRect.height,
      });
      this.updateOriginRect(rect);
    },
    updateOriginRect(rect) {
      this.originalRect.x = rect.x;
      this.originalRect.y = rect.y;
      this.originalRect.w = rect.w;
      this.originalRect.h = rect.h;
      this.originalRect.angle = rect.angle;
    },
    setupTempElement(val) {
      if (this.tempElement && this.tempElement.data && this.tempElement.image) console.log('  setupTempElement', this.tempElement.data.image.isNew);
      if (!val.type) return false;
      this.tempElement = cloneDeep(val);

      const video = document.body.querySelector('.swap-temp video');
      if (video) {
        if (video.readyState >= 3) {
          video.load();
        } else {
          video.onloadedmetadata = () => {
            video.load();
          };
        }
        // mask stuff will be set on load
        // in handleElementLoaded
        // CHEAT when replacing mask, this function will get called again after handleElementLoaded run
        // also in case it's still loading after 5 sec
        setTimeout(() => {
          this.isLoading = false;
        }, 5000);
      }

      return false;
    },
    setIsNewToFalse() {
      this.tempElement.data.image.isNew = false;
    },
    debounceHandleElementLoaded() {
      // only toggle debounce on replace image
      // for mask, it will conflict showing the placeholder if there's a debounce.
      if (this.isMask) {
        this.handleElementLoaded();
        return;
      }

      setTimeout(() => {
        this.handleElementLoaded();
      }, 300);
    },
    handleElementLoaded() {
      console.log('~~~handleElementLoaded!~~~');
      this.setImageUrl();
      if (!this.isMask) return;
      // console.log('next tick', document.body.querySelector('.swap-temp svg'));
      setTimeout(() => {
        this.updateMasksTemp();
      }, 200);
    },
    isTaintedCanvas() {
      try {
        return false;
        // eslint-disable-next-line
      } catch (err) {
        return err.code === 18;
      }
    },
    setImageUrl() {
      // console.log('setImageUrl', this.isImage, this.isVideo, this.tempElement.data.url, this.tempElement);
      if (!this.isImage && !this.isVideo && !this.isShape) {
        this.isLoading = false;
        return;
      }

      if (this.isImage) {
        this.imageUrl = this.tempElement.data.url;
        this.isLoading = false;
      }

      if (this.isVideo) {
        setTimeout(() => {
          const selectedVideo = document.body.querySelector('.swap-temp video');
          // console.log('selectedVideo', selectedVideo);

          if (selectedVideo) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const width = this.tempElement.size.width * this.getCanvasZoom;
            const height = this.tempElement.size.height * this.getCanvasZoom;

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(selectedVideo, 0, 0, width, height);

            if (!this.isTaintedCanvas(ctx) && canvas.toDataURL('image/jpg')) {
              this.imageUrl = canvas.toDataURL('image/jpg');
            } else if (this.tempElement.data.thumb) {
              this.imageUrl = this.tempElement.data.thumb;
            }
          }
          this.isLoading = false;
        }, 500);
      }
    },
  },
  watch: {
    getSwappedElement: {
      handler(item) {
        // this will be triggered when user click on CategoryItem when swapping
        // console.log('!!getSwappedElement', this.isLoading, this.isMask, item);
        if (this.isMask) {
          // console.log('is mask', item.data.image.isNew, item.data.image.url);

          // console.log('this.maskUrl', this.maskUrl);
          this.maskUrl = item.data.image.url;

          if (!item.data.image.isNew) {
            // this is to make sure the size and position is updated
            // based on MaskElements calculation
            const tempElement = cloneDeep(item);

            tempElement.size.width *= this.getCanvasZoom;
            tempElement.size.height *= this.getCanvasZoom;
            tempElement.position.x *= this.getCanvasZoom;
            tempElement.position.y *= this.getCanvasZoom;
            this.setupTempElement(tempElement);
          }
        } else {
          this.isLoading = true;
          const newImage = cloneDeep(item);
          newImage.size.width *= this.getCanvasZoom;
          newImage.size.height *= this.getCanvasZoom;
          newImage.position.x *= this.getCanvasZoom;
          newImage.position.y *= this.getCanvasZoom;
          this.setupTempElement(newImage);
        }

        if (this.isShape) {
          this.isLoading = false;
        }
      },
      deep: true,
    },
    maskUrl(val) {
      // console.log('maskUrl', val);
      // only run this when the url is changed
      // not when ther rest of swappedElement details changed
      const tempElement = cloneDeep(this.getSwappedElement);
      this.maskUrl = val;
      this.showMasksTemp = false;

      tempElement.size.width *= this.getCanvasZoom;
      tempElement.size.height *= this.getCanvasZoom;
      tempElement.position.x *= this.getCanvasZoom;
      tempElement.position.y *= this.getCanvasZoom;
      this.setupTempElement(tempElement);
    },
    isLoading(val) {
      this.$root.$emit('control-container-replace-image-is-loading', val);
    },
  },
  beforeMount() {
    const tempElement = cloneDeep(this.getSwappedElement);

    this.isMask = tempElement.type === 'masks';

    // console.log('beforeMount', this.isMask, tempElement.data.image.url);

    if (this.isMask) {
      // console.log('beforeMount', tempElement.data.image.url);
      this.maskUrl = tempElement.data.image.url;
    }

    tempElement.size.width *= this.getCanvasZoom;
    tempElement.size.height *= this.getCanvasZoom;
    tempElement.position.x *= this.getCanvasZoom;
    tempElement.position.y *= this.getCanvasZoom;
    this.setupTempElement(tempElement);
  },
  mounted() {
    this.setImageUrl();

    this.$root.$on('image-is-set', () => {
      this.setIsNewToFalse();
    });

    this.$root.$on('replace-image-is-loading', (isLoading) => {
      this.isLoading = isLoading;
    });
  },
  destroyed() {
    this.$root.$off('image-is-set');
    this.$root.$off('replace-image-is-loading');
  },
};
</script>

<style lang="scss">
.swap-temp {
  display: block;

  .canvas-element.has-opacity {
    opacity: 0.6;
  }

  &.is-loading {
    .swap-temp__container {
      background: rgba($grey, 0.5) !important;
    }

    .canvas-element {
      opacity: 0;
    }
  }
}

.swap-temp__container {
  outline: 2px solid blue;
  z-index: 2;
  pointer-events: none;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.masks-temp {
  opacity: 0.3;
}

.innerbound-temp {
  outline: 2px solid yellow;
}

.swap-temp__preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
