<template lang="pug">
.effects-tab
  button.effects__back-button.icon-to-left(
    type="button"
    @click="$emit('reset-active-effect-group')"
  )

  template(v-if="activeEffectGroup === 'text-bg'")
    TextBackground(
      @open-colorpicker="openColorPicker()"
    )

  template(v-if="activeEffectGroup === 'drop-shadow'")
    TextShadow(
      @open-colorpicker="openColorPicker()"
    )

  template(v-if="activeEffectGroup === 'blur'")
    TextBlur

  template(v-if="activeEffectGroup === 'reflection'")
    ReflectionControls

  template(v-if="activeEffectGroup === 'ground-shadow'")
    GroundShadowControls(
      @open-colorpicker="openColorPicker()"
    )

  template(v-if="activeEffectGroup === 'glow'")
    GlowControls(
      @open-colorpicker="openColorPicker()"
    )

  template(v-if="activeEffectGroup === 'light-sweep'")
    LightSweepControls(
      @open-colorpicker="openColorPicker()"
    )

  template(v-if="activeEffectGroup === 'glitch'")
    GlitchControls(
      @open-colorpicker="openColorPicker()"
    )

  template(v-if="activeEffectGroup === 'outline'")
    OutlineControls(
      @open-colorpicker="openColorPicker()"
    )

  template(v-if="activeEffectGroup === 'text-3d'")
    Text3DControls(
      @open-colorpicker="openColorPicker()"
    )

  //- cannot use v-if on colorpicker so that it can save the color
  ColorPicker(
    :color="textColor()"
    :elementId="elementId"
    @close="closeColorPicker"
  )
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';

import ColorPicker from './children/ColorPicker.vue';
import TextBackground from './children/TextBackground.vue';
import TextShadow from './children/TextShadow.vue';
import TextBlur from './children/TextBlur.vue';
import ReflectionControls from './children/ReflectionControls.vue';
import GroundShadowControls from './children/GroundShadowControls.vue';
import GlowControls from './children/GlowControls.vue';
import LightSweepControls from './children/LightSweepControls.vue';
import GlitchControls from './children/GlitchControls.vue';
import OutlineControls from './children/OutlineControls.vue';
import Text3DControls from './children/Text3DControls.vue';

export default {
  name: 'TextEffects',
  components: {
    GroundShadowControls,
    ReflectionControls,
    TextBlur,
    ColorPicker,
    TextBackground,
    TextShadow,
    GlowControls,
    LightSweepControls,
    GlitchControls,
    OutlineControls,
    Text3DControls,
  },
  props: {
    activeEffectGroup: { type: String, default: '' },
  },
  data() {
    return {
      elementId: '',
    };
  },
  computed: {
    ...mapState(['showColorPicker']),
    ...mapGetters('canvasElements', ['getActiveElements']),
  },
  methods: {
    ...mapMutations(['setShowColorPicker']),
    ...mapMutations('canvasElements', [
      'updateActiveColorId',
    ]),
    closeColorPicker() {
      console.log('closecolorpicker');
      this.setShowColorPicker(false);
    },
    openColorPicker() {
      this.elementId = this.getActiveElements[0].data.id;
      this.updateActiveColorId(0);
      this.setShowColorPicker(true);
    },
    textColor() {
      let color = '#000';
      if (
        this.getActiveElements.length
        && this.getActiveElements[0].type === 'texts'
        && this.getActiveElements[0].data.color[0]
      ) {
        // eslint-disable-next-line
        color = this.getActiveElements[0].data.color[0].color;
      }
      return color;
    },
  },
};
</script>
