<template lang="pug">
ToolModal.colorpicker(
  :class="colorpickerClass"
  :is-show="showColorPicker"
  ref="colorPicker"
  @closeOverlay="closeColorPicker"
)
  .tab
    //- HEADER TAB
    //- only show the tab head when there are 2 options
    .tab__head(v-if="showGradientColor")
      button.tab__item.js-tab(
        type="button"
        :class="{ 'is-active': activeTab === 'solid' }"
        @click="changeTab('solid')"
      ) Solid
      button.tab__item.js-tab(
        type="button"
        :class="{ 'is-active': activeTab === 'gradient' }"
        @click="changeTab('gradient')"
      ) Gradient
    //- CONTENT
    .tab__body
      //- SOLID TAB
      .tab__item(
        data-tab="color-solid",
        :class="{ 'is-active': activeTab === 'solid' }"
      )
        .colorpicker__group
          Chrome(v-model="solidColor" @input="onChangeSolidColor")

      //- GRADIENT TAB
      .tab__item(
        data-tab="color-gradient",
        v-if="showGradientColor",
        :class="{ 'is-active': activeTab === 'gradient' }"
      )
        .colorpicker__group(ref="elColorPickerGroup")
          .gradient-loader-container(v-if="isGradientPointLoading")
            Lottie.loader__animation(:options="canvasLoaderOptions")
          .gradient-tool__container(
            :class="{ 'container--hidden': isGradientPointLoading }"
          )
            .gradient-sample(
              :class="{ 'is-disabled': isSVG || isAnimated }",
              @click="addNewGradientPoint"
            )
              canvas(ref="elGradientSample")
            .gradient__picker
              template(v-for="(point, pointIndex) in gradientColorObj.points")
                GradientPointer(
                  :key="`gradient-pointer-${pointIndex}`"
                  :id="pointIndex"
                  :point="point"
                  :parentW="gradientPointerParentW"
                  :maxX="gradientSampleW"
                  :selectedId="selectedGradientColorPointIndex"
                  :removable="isGradientPointRemovable"
                  :draggable="!isSVG && !isAnimated"
                  :is-adding-or-removing-point="isAddOrRemoveGradientPoint"
                  @start-gradient-loader="isGradientPointLoading = true"
                  @stop-gradient-loader="isGradientPointLoading = false"
                  @reset-is-add-remove-point="isAddOrRemoveGradientPoint = false"
                  @updating="updateGradientPoint"
                  @select="updateSelectedGradientColorPointIndex"
                  @remove="removeGradientPoint"
                )
          Chrome(v-model="gradientColor")
          .gradient__subgroup(v-if="hasGradientAngle")
            BaseAnglepicker(
              v-if="activeTab === 'gradient'"
              :angle="angleValue"
              @input="setAngleValue"
            )
</template>

<script>
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';
import { Chrome } from 'vue-color';
import VueDragResize from 'vue-drag-resize';
import cloneDeep from 'lodash.clonedeep';

import { groups, subgroups } from '@/assets/scripts/enums';
import {
  angleToDegree,
  degreeToAngle,
  rgbToHex,
  getSelectedText,
  isMobile,
} from '@/assets/scripts/utilities';
import CanvasLoader from '@/assets/data/canvas-loader.json';

import GradientPointer from './GradientPointer.vue'; // eslint-disable-line
import Lottie from '@/components/ProjectCanvas/AssetSidebarCanvas/LottieShared.vue'; // eslint-disable-line

import elementMixins from '@/components/mixins/element-mixins'; // eslint-disable-line
/**
 * TODO: remove commented codes related to multiple gradient color blocker for animated text Element
 */
export default {
  name: 'ColorPicker',
  components: {
    GradientPointer,
    Chrome,
    VueDragResize,
    Lottie,
  },
  mixins: [elementMixins],
  data() {
    return {
      activeTab: 'solid',
      activeSolid: '#000000',
      activeGradient: {
        color1: '#000000',
        color2: '#808080',
        degree: 0,
      },
      settingUp: true, // this is to not trigger the event when opening the tab
      colorTimeout: null,
      colorObject: {},
      gradientColorObj: {
        points: [
          {
            color: '#000000',
            percentage: 0,
          },
          {
            color: '#808080',
            percentage: 100,
          },
        ],
        degree: 0,
      },
      isGradientPointLoading: true,
      isAddOrRemoveGradientPoint: false, // only when adding or removing gradient point we need to do preloader / remount the GradientPointer.vue
      selectedGradientColorPointIndex: 0,
      gradientPointerParentW: 190,
      gradientSampleW: 190,
      isFromProjectColors: false,
      ctrlKeyDown: false,
      isMobile: isMobile(),
      isBrandPaletteColorChanged: false,
    };
  },
  props: {
    color: { type: String, default: '' },
    elementId: { type: String, default: '' },
    isWatermark: { type: Boolean, default: false },
    isFromColorTab: { type: Boolean, default: false },
    isBrandPalette: { type: Boolean, default: false },
    /**
     * brandPalette: { name, color, index }
     */
    brandPalette: { type: Object, default: () => {} },
  },
  computed: {
    ...mapState([
      'showSidebar',
      'showColorTab',
      'showColorPicker',
      'showEditTab',
    ]),
    ...mapState('team', ['teamId']),
    ...mapGetters([
      'isTextBackground',
      'isTextShadow',
      'isTextGlow',
      'isTextLightSweep',
      'isTextGlitch',
      'isTextOutline',
      'isText3D',
      'isTextEditing',
      'isGroundShadow',
      'isMediaShadow',
      'isMediaGlow',
      'isMediaBorder',
      'isLiteMode',
      'getActiveTextGlitchColorType',
    ]),
    ...mapGetters('colorPreset', [
      'getColorBrand',
    ]),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getActiveElementsIds',
      'getCanvasElementById',
      'getCanvasBackground',
      'getActiveColorId',
      'getWatermark',
      'getProjectBrandId',
    ]),
    ...mapGetters('assetsSidebar', ['getActiveGroup', 'getActiveSubGroup']),
    colorpickerClass() {
      const cssClass = [];
      if (this.showColorPicker) cssClass.push('is-active');
      if (this.isFromColorTab) cssClass.push('colorpicker--color-tab');
      if (this.showEditTab) cssClass.push('colorpicker--margin-top');
      if (this.isTextGlitch && this.getActiveTextGlitchColorType === 'color2') cssClass.push('colorpicker--double-margin-top'); // got 2 colorpicker in the effect
      if (this.isFromBackgroundColourTab) {
        cssClass.push('colorpicker--background-colour-tab');
      }

      return cssClass.join(' ');
    },
    isFromBackgroundColourTab() {
      return this.showSidebar
      && this.getActiveGroup === groups.BACKGROUNDS
      && this.getActiveSubGroup === subgroups.COLOUR;
    },
    canvasLoaderOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: CanvasLoader,
      };
    },
    angleValue() {
      return degreeToAngle(this.gradientColorObj.degree);
    },
    solidColor: {
      get() {
        return this.activeSolid;
      },
      set(value) {
        // console.log('value is changing', value)
        this.activeSolid = value.hex;
      },
    },
    gradientColor: {
      get() {
        return this.gradientColorObj.points[
          this.selectedGradientColorPointIndex
        ].color;
      },
      set(value) {
        this.gradientColorObj.points[
          this.selectedGradientColorPointIndex
        ].color = value.hex;
      },
    },
    isText() {
      if (this.getActiveElements.length === 0) return false;
      return this.getActiveElements[0].type === 'texts';
    },
    showGradientColor() {
      if (this.isBrandPalette) return false;
      if (this.isAnActiveElement && this.isEffectColor) return false;
      // console.log('showGradientColor', this.getActiveElements);
      if (this.getActiveElements.length === 0 || this.isBackgroundColor) {
        return true;
      } // if this is background color
      // console.log('isTextBackground', this.isText, this.isTextBackground);
      if (this.isText && (
        this.isTextShadow
        || this.isTextGlow
        || this.isTextLightSweep
        || this.isTextGlitch
        || this.isTextOutline
        || this.isText3D
      )) return false;
      if (this.isText && this.isTextEditing) return false; // if user start editing the text
      if (this.isText && getSelectedText() && !this.isLiteMode) return false; // if user highlighting small part of the text instead of whole layer
      if (this.getActiveElements[0].animated) {
        // when animated check if it is a gradient
        if (this.getActiveColorId && this.getActiveColorId.startsWith('#gradient')) {
          return true;
        }
        return false;
      }
      if (this.getActiveColorId && this.getActiveColorId.substring(6, 0) === 'stroke') {
        return false;
      }
      return true;
    },
    canMultipleGradientPoints() {
      return this.showGradientColor && (!this.isSVG || this.isHtml);
    },
    hasGradientAngle() {
      if (this.getActiveElements.length && this.getActiveElements[0].animated) {
        return false;
      }
      return true;
    },
    isGradientPointRemovable() {
      const { points } = this.gradientColorObj;
      return points.length > 2;
    },
    isStroke() {
      return this.getActiveColorId && this.getActiveColorId.toString().startsWith('stroke');
    },
    url() {
      return this.hasActiveElement ? this.getActiveElements[0].data.url : null;
    },
    isSVG() {
      const { url } = this;
      return !!(
        url
        && this.getActiveElements[0].type === 'shapes'
        && url.split('.').pop() === 'svg'
      );
    },
    isHtml() {
      return this.hasActiveElement && this.getActiveElements[0].data.isHtml;
    },
    isAnimated() {
      return this.hasActiveElement && this.getActiveElements[0].animated;
    },
    hasActiveElement() {
      return this.getActiveElements.length === 1;
    },
    isAnActiveElement() {
      return (
        this.getActiveElements.length && this.getActiveElements.length === 1
      );
    },
    isEffectColor() {
      return this.isGroundShadow || this.isMediaShadow || this.isMediaGlow || this.isMediaBorder;
    },
    isBackgroundColor() {
      return this.getActiveColorId === 'background' || this.getActiveColorId === null;
    },
  },
  created() {
    if (window && window.innerWidth > 1600) {
      this.gradientPointerParentW = 235;
      this.gradientSampleW = 235;
    }
  },
  mounted() {
    // added here so at ColorPalette.vue, can use "v-if" for the multiple ColorPicker, so can decrease a lot of component renderings.
    if (this.isBrandPalette) this.activeSolid = this.color;

    this.$refs.colorPicker.$el.addEventListener('keydown', this.checkKeyDown);
    this.$refs.colorPicker.$el.addEventListener('keyup', this.checkKeyUp);
  },
  beforeDestroy() {
    if (this.isBrandPalette) this.saveBrandPaletteColor();

    this.$refs.colorPicker.$el.removeEventListener('keydown', this.checkKeyDown);
    this.$refs.colorPicker.$el.removeEventListener('keyup', this.checkKeyUp);
  },
  methods: {
    ...mapMutations('canvasElements', [
      'updateCanvasElementStroke',
      'updateCanvasElementContent',
      'updateCanvasElementColor',
    ]),
    ...mapMutations('team', ['changeTeamColor']),
    ...mapActions('canvasElements', ['updateCanvasBackground']),
    ...mapActions('canvasHistory', ['catchHistory']),
    ...mapActions('team', ['changeTeamColorAction']),
    changeTab(tab) {
      // console.log(this.getActiveElements[0]);
      this.activeTab = tab;

      if (this.isText) {
        // console.log(tab === 'gradient' && !this.hasInvalidAnimationForGradient);
        // this.updateCanvasElementTextGradient({ isEnabled: tab === 'gradient' && !this.hasInvalidAnimationForGradient });
      }
    },
    updateColor(isSolid, colorhex) {
      // for desktop use the scene background
      // avoid conflicting history between scene and element
      // if (!this.isBackgroundColor && this.isMobile) {
      //   this.catchHistory('element');
      // }
      const hasActiveElements = !!this.getActiveElements.length;
      let color;
      if (!this.isBackgroundColor) {
        color = hasActiveElements
          ? cloneDeep(this.getActiveElements[0].data.color)
          : [cloneDeep(this.getCanvasBackground)];
        if (hasActiveElements) {
          // color is looking for array of object, therefore need to convert
          if (this.isMediaShadow) color = [this.getActiveElements[0].data.mediaShadow.color];
          if (this.isGroundShadow) color = [this.getActiveElements[0].data.groundShadow.color];
          if (this.isMediaGlow) color = [this.getActiveElements[0].data.mediaGlow.color];
          if (this.isMediaBorder) color = [this.getActiveElements[0].data.mediaBorder.color];
        }
      } else {
        color = [colorhex];
      }

      const id = hasActiveElements ? this.getActiveElements[0].data.id : null;

      if (this.isStroke) {
        // if it's for stroke color
        this.catchHistory('element');
        const colorObject = {};

        const activeElementsStroke = this.getActiveElements[0].data.stroke;

        colorObject.stroke = [];

        if (activeElementsStroke.length > 1) {
          // if there are more than 1 color
          const activeElementsColor = this.getActiveElements[0].data.color;
          for (let i = 0; i < activeElementsColor.length; i += 1) {
            const { stroke, strokeColor } = this.getActiveElements[0].data.stroke[i];

            if (this.getActiveColorId.substring(7) === i.toString()) {
              // if this is the one being changed
              colorObject.stroke.push({
                stroke,
                strokeColor: colorhex,
              });
            } else {
              // otherwise, keep the old value
              colorObject.stroke.push({
                stroke,
                strokeColor,
              });
            }
          }
        } else {
          // #49ptgu - for filled shapes we default with 0 stroke
          // if user changed the color, set to 10
          const stroke = this.getActiveElements[0].data.stroke[0].stroke || 10;
          colorObject.stroke.push({
            stroke,
            strokeColor: colorhex,
          });
        }
        colorObject.silent = true;
        this.colorObject = colorObject;
        this.$root.$emit('canvas-element-stroke-updated');
        this.updateCanvasElementStroke(colorObject);
      } else {
        const selectedText = getSelectedText();
        // console.log({ selectedText });
        if (
          this.getActiveElements.length
          && this.getActiveElements[0].type === 'texts'
          && selectedText
          && !this.isLiteMode
        ) {
          document.execCommand('foreColor', false, colorhex);
          const textContent = document
            .getElementsByClassName('text-temp')[0]
            .getElementsByClassName('text-content')[0];
          const content = textContent.innerHTML;
          const activeDataColor = this.getActiveElements[0].data.color[0];

          if (content) {
            const passedContent = {
              id: this.getActiveElementsIds[0],
              content,
            };

            // if user was using gradient text before this
            // reset it to the first colour of the gradient
            if (typeof activeDataColor.color !== 'string') {
              const newColor = activeDataColor.color.points[0].color;
              // console.log('newColor is', newColor);

              passedContent.color = [newColor];
              this.colorObject = {
                id,
                color: [
                  newColor, // this need to be newColor instead of colorhex or else when user deselect, the whole text will change color
                ],
                silent: true,
              };
            }
            this.updateCanvasElementContent(passedContent);
          }
        } else {
          color.forEach((cl, index) => {
            const currentColor = cl;
            // console.log('currentColor', cloneDeep(currentColor))
            // if it's an element
            if (id && !this.isBackgroundColor) {
              // some color may not have nm
              // use == because getActiveColorId is string
              if (
                // eslint-disable-next-line
                (!currentColor.nm && this.getActiveColorId == index)
                || (currentColor.nm && currentColor.nm === this.getActiveColorId)
                || this.isTextBackground
                || this.isTextShadow
                || this.isTextGlow
                || this.isTextLightSweep
                || this.isTextGlitch
                || this.isTextOutline
                || this.isText3D
                || this.isMediaShadow
                || this.isMediaGlow
                || this.isMediaBorder
                || this.isGroundShadow
              ) {
                if (isSolid) {
                  if (currentColor.nm) {
                    // currentColor.nm means this is an animated json
                    // need to keep as object format
                    color[index].color = colorhex;
                  } else {
                    color[index] = colorhex;
                  }
                } else {
                  color[index] = this.gradientColorObj;
                }
              }
            } else if (isSolid) {
              // bg color
              color[index] = colorhex;
            } else {
              // bg color
              color[index || 0] = this.gradientColorObj;
            }
          });

          const colorObject = {
            id,
            color,
            silent: true,
            isWatermark: this.isWatermark,
            isBrandPalette: this.isBrandPalette,
          };

          if (this.isBackgroundColor) {
            // for mobile, insert new svg shape to present as background
            // Add layer for background color
            const element = this.setupBackgroundColor(color);
            colorObject.id = element.data.id;

            if (!this.isWatermark) this.addBackgroundColorLayer(element, colorObject);
          }

          if (this.isBrandPalette) {
            colorObject.id = this.brandPalette.name;
            colorObject.index = this.brandPalette.index;
          }

          console.log(
            '~~~updateColor',
            hasActiveElements,
            { colorObject },
            cloneDeep(color),
            isSolid,
            colorhex,
            id,
          );

          this.colorObject = colorObject;

          if (!this.isText && this.getActiveElements.length <= 1) {
            this.updateCanvasElementColor(colorObject);
          } else {
            const activeElementIds = cloneDeep(this.getActiveElementsIds);

            activeElementIds.forEach((elementId) => {
              colorObject.id = elementId;
              this.updateCanvasElementColor(colorObject);
            });
          }
        }
      }
      clearTimeout(this.colorTimeout);
    },
    closeColorPicker() {
      if (!this.showColorPicker) return;
      // console.log('closeColorPicker:ColorPicker');
      this.$emit('close');
    },
    setAngleValue(value) {
      this.gradientColorObj.degree = angleToDegree(value);
    },
    addNewGradientPoint(e) {
      this.isAddOrRemoveGradientPoint = true;
      if (this.isSVG || this.isAnimated) return;
      const canvas = this.$refs.elGradientSample;
      const { offsetX } = e;
      // offsetY is vertically center position
      const offsetY = canvas.offsetHeight / 2;
      const ctx = canvas.getContext('2d');
      const pixelData = ctx.getImageData(offsetX, offsetY, 1, 1).data;
      const color = rgbToHex(pixelData[0], pixelData[1], pixelData[2]);
      const percentage = this.positionToPercentage(offsetX);
      let index = this.gradientColorObj.points.findIndex(point => point.percentage > percentage);
      console.log(index);
      if (index < 0) {
        // If `index` is unexpected, set it as the last point.
        index = this.gradientColorObj.points.length;
      }

      this.gradientColorObj.points.splice(index, 0, { color, percentage });
      console.log(
        {
          offsetX, offsetY, pixelData, index, percentage, color,
        },
        this.gradientColorObj.points,
      );
      this.$nextTick(() => {
        this.updateSelectedGradientColorPointIndex(index);
      });
    },
    // TODO: duplicated in GradientPointer.vue
    positionToPercentage(position) {
      return (position / this.gradientSampleW) * 100;
    },
    prepareForMultipleGradients() {
      if (this.showGradientColor) {
        // console.debug('prepareForMultipleGradients');
        this.updateGradientPointerParentW();
        this.updateGradientSampleW();
        this.updateCanvasSize();
        this.updateGradientSampleColor();
      }
    },
    removeGradientPoint(index) {
      this.isAddOrRemoveGradientPoint = true;

      if (this.isGradientPointRemovable) {
        if (this.selectedGradientColorPointIndex === index) {
          this.selectedGradientColorPointIndex -= 1;
        }
        this.gradientColorObj.points.splice(index, 1);
      }
    },
    updateCanvasSize() {
      const { elGradientSample } = this.$refs;
      elGradientSample.width = elGradientSample.clientWidth;
      elGradientSample.height = elGradientSample.clientHeight;
    },
    updateGradientPoint(index, percentage) {
      const updatingPoint = this.gradientColorObj.points[index];
      updatingPoint.percentage = percentage;
    },
    updateGradientPointerParentW() {
      const { elColorPickerGroup } = this.$refs;
      if (elColorPickerGroup) {
        this.gradientPointerParentW = elColorPickerGroup.offsetWidth;
      }
    },
    updateGradientSampleColor() {
      const canvas = this.$refs.elGradientSample;
      if (!this.$refs.elGradientSample) return;
      const ctx = canvas.getContext('2d');
      const { clientWidth, clientHeight } = canvas;
      const { points } = this.gradientColorObj;
      ctx.clearRect(0, 0, clientWidth, clientHeight);

      const canvasGradient = ctx.createLinearGradient(0, 0, clientWidth, 0);
      // console.log('canvasGradient', canvasGradient);
      points.forEach((point) => {
        // fix Error in callback for watcher "gradientColorObj": "IndexSizeError: Failed to execute 'addColorStop' on 'CanvasGradient':
        // The provided value (1.13298) is outside the range (0.0, 1.0)."
        // causing for text gradient picker to spoil
        let range = point.percentage / 100;
        if (range < 0) range = 0;
        if (range > 1) range = 1;
        canvasGradient.addColorStop(range, point.color);
      });
      ctx.fillStyle = canvasGradient;
      ctx.fillRect(0, 0, clientWidth, clientHeight);
    },
    updateGradientSampleW() {
      const { elColorPickerGroup } = this.$refs;
      if (elColorPickerGroup) {
        this.gradientSampleW = elColorPickerGroup.clientWidth - 29;
      }
    },
    updateSelectedGradientColorPointIndex(index) {
      this.selectedGradientColorPointIndex = index;
    },
    checkKeyDown(e) {
      const { target, keyCode, key } = e;
      const isColorInput = target.className.includes('vc-input__input');

      // color input should only allow hexadecimal and maximum 6 characters
      if (isColorInput) {
        if (keyCode === 17 || keyCode === 91) {
          this.ctrlKeyDown = true;
          return;
        }

        const isDeleteOrBackspace = keyCode === 8 || keyCode === 46;
        const isArrowKey = keyCode >= 37 && keyCode <= 40;
        const hexRegExp = new RegExp(/^[0-9A-Fa-f]$/i);
        const isCopyPasteKey = this.ctrlKeyDown && (keyCode === 65 || keyCode === 67 || keyCode === 86);

        if (isCopyPasteKey) {
          return;
        }

        // if the input is not hexadecimal, don't accept
        if (!hexRegExp.test(key) && !isDeleteOrBackspace && !isArrowKey) {
          e.preventDefault();
        }

        // if the input already has 6 value
        // and none of the text is highlighted
        // don't allow to add anymore letter
        if (
          target.value.length === 6
          && !getSelectedText()
          && !isDeleteOrBackspace
          && !isArrowKey
        ) {
          e.preventDefault();
        }
      }
    },
    checkKeyUp(e) {
      const { target, keyCode } = e;
      const isColorInput = target.className.includes('vc-input__input');

      if (isColorInput) {
        if (keyCode === 17 || keyCode === 91) {
          this.ctrlKeyDown = false;
        }
      }

      const hexRegExp = new RegExp(/[0-9A-Fa-f]{6}/g);

      if (target.value.length >= 6) {
        if (!hexRegExp.test(target.value)) {
          target.value = 'FFFFFF';
        }

        target.value = target.value.substring(0, 6);
      }
    },
    onChangeSolidColor() {
      this.isBrandPaletteColorChanged = true;
    },
    saveBrandPaletteColor() {
      if (!this.isBrandPaletteColorChanged) {
        // if there is no any color change, dont need to show "successfully changed color" message
        this.isBrandPaletteColorChanged = false;
        return;
      }

      this.changeTeamColorAction({
        brandId: this.getProjectBrandId,
        teamId: this.teamId,
        paletteName: this.brandPalette.name,
        index: this.brandPalette.index,
        color: this.activeSolid,
      });
      this.isBrandPaletteColorChanged = false;
    },
  },
  watch: {
    gradientColorObj: {
      handler() {
        if (this.showColorPicker && this.activeTab === 'gradient' && !this.settingUp) {
          this.catchHistory('scene');
          clearTimeout(this.colorTimeout);
          this.colorTimeout = setTimeout(() => {
            this.updateColor(false);
          }, 250);
          this.updateGradientSampleColor();
        }
      },
      deep: true,
      immediate: true,
    },
    activeSolid: {
      handler(val) {
        if (!this.showColorPicker || !this.activeTab === 'solid') return;
        if (this.isBrandPalette) {
          this.catchHistory('scene');
          clearTimeout(this.colorTimeout);
          this.colorTimeout = setTimeout(() => {
            this.changeTeamColor({
              brandId: this.getProjectBrandId,
              name: this.brandPalette.name,
              index: this.brandPalette.index,
              color: val,
            });
          }, 200);
        } else if (!this.settingUp) {
          this.catchHistory('scene');
          clearTimeout(this.colorTimeout);
          this.colorTimeout = setTimeout(() => {
            this.updateColor(true, val);
          }, 200);
        }
      },
      deep: true,
      immediate: true,
    },
    activeTab: {
      handler(val) {
        // TODO: might need to update after SVG was updated to multiple gradient points.
        // console.debug('activeTab:watcher', val);
        if (val === 'solid') {
          const originalColor = this.activeSolid;
          const color = originalColor === '#000000' ? this.gradientColorObj.points[0].color : originalColor;

          if (!this.isTextEditing) {
            // if user is editing text, don't need to trigger this section
            // so that it doesn't auto save to new color
            this.activeSolid = '';
            this.activeSolid = color;
          }

          if (this.isFromProjectColors) {
            this.activeSolid = this.colorObject.color;
          }
        } else {
          if (!this.isFromProjectColors) {
            // console.log('this.gradientColorObj.points', this.gradientColorObj.points)
            const originalColor = this.gradientColorObj.points ? this.gradientColorObj.points[0].color : '#000000';
            const color = originalColor === '#000000' ? this.activeSolid : originalColor;
            this.gradientColorObj.points[0].color = '';
            this.gradientColorObj.points[0].color = color;
          } else {
            this.isFromProjectColors = false;
          }

          this.prepareForMultipleGradients();
        }
      },
    },
    showColorTab(val) {
      if (val && !this.showGradientColor && this.activeTab !== 'solid') {
        this.activeTab = 'solid';
      }
      // console.debug('watcher:showColorTab', {
      //   activeTab: this.activeTab,
      //   showGradientColor: this.showGradientColor,
      // });
    },
    showColorPicker(val) {
      console.log('showColorPicker', val);
      if (val) {
        this.catchHistory('element');
        this.settingUp = true;
        // when opening the tab, set the active color based on element / background
        let solidColor = '#000000';
        let gradientColorObj = JSON.parse(JSON.stringify(this.gradientColorObj));
        let isSolid = true;

        // need to use timeout cause isBackground and isTextShadow is only added after a bit of time
        setTimeout(() => {
          // console.log('timeout showColorPicker', this.isText, this.isTextBackground, this.isTextShadow);

          if (this.elementId && !this.isBackgroundColor) {
            // if it's an element and not background color
            const element = this.getCanvasElementById(this.elementId);
            // console.log({ element });
            // selectedColor is for element with multiple colors like animated json
            // if there is no color with same `nm`, use the first color
            const selectedColor = element.data.color.find(c => c.nm === this.getActiveColorId)
              || element.data.color[0];

            if (this.isEffectColor) {
              if (this.isGroundShadow) {
                // console.log('is ground shadow');
                solidColor = element.data.groundShadow.color;
              } else if (this.isMediaShadow) {
                // console.log('is media shadow');
                solidColor = element.data.mediaShadow.color;
              } else if (this.isMediaGlow) {
                // if media glow
                solidColor = element.data.mediaGlow.color;
              } else if (this.isMediaBorder) {
                // if media border
                solidColor = element.data.mediaBorder.color;
              }
            } else if (this.isText) {
              // if text
              if (this.isTextBackground) {
                // if text background
                const textBgColor = element.data.textBackground.color;
                if (typeof textBgColor === 'string') {
                  solidColor = textBgColor;
                } else {
                  isSolid = false;
                  gradientColorObj = element.data.textBackground.color;
                }
              } else if (this.isTextShadow) {
                // if text shadow
                solidColor = element.data.textShadow.color;
              } else if (this.isTextGlow) {
                // if text glow
                solidColor = element.data.textGlow.color;
              } else if (this.isTextLightSweep) {
                // if text has light sweep effect
                solidColor = element.data.textLightSweep.color;
              } else if (this.isTextGlitch) {
                // if text glitch
                const selectedGlitchColor = this.getActiveTextGlitchColorType === 'color1' ? 'color1' : 'color2';
                solidColor = element.data.textGlitch[selectedGlitchColor];
              } else if (this.isTextOutline) {
                // if text outline
                solidColor = element.data.textOutline.color;
              } else if (this.isText3D) {
                // if text 3d
                solidColor = element.data.text3D.color;
              } else if (typeof element.data.color[0] === 'string') {
                // eslint-disable-next-line
                solidColor = element.data.color[0];
              } else {
                isSolid = false;
                // eslint-disable-next-line
                gradientColorObj = element.data.color[0];
              }
            } else if (this.isStroke) {
              // check if it's stroke
              const index = this.getActiveColorId.substring(7);
              if (element.data.stroke[index].color) {
                solidColor = element.data.stroke[index].strokeColor;
              }
              // console.log('index is', index, color1);
            } else if (typeof selectedColor === 'string') {
              solidColor = selectedColor;
            } else if (typeof selectedColor.color === 'string') {
              // for solid color for animated json
              // since animated json need `nm` key, so color has to be an object
              solidColor = selectedColor.color;
            } else if (selectedColor.scalar) {
              // Sometimes returning key is scalar
              solidColor = selectedColor.scalar;
            } else {
              isSolid = false;
              gradientColorObj = selectedColor;
            }
          } else if (this.isBrandPalette) {
            solidColor = this.color;
          } else if (this.isWatermark) {
            const backgroundColor = this.getWatermark.background.color;
            if (typeof backgroundColor === 'string') {
              solidColor = backgroundColor;
            } else if (backgroundColor.points) {
              isSolid = false;
              gradientColorObj = backgroundColor;
            }
          } else {
            // if it's a background color
            const canvasBackground = this.getCanvasBackground;
            if (typeof canvasBackground === 'string') {
              solidColor = canvasBackground;
            } else if (canvasBackground.color) {
              // for solid color for background
              // since laravel expecting object
              solidColor = canvasBackground.color;
            } else if (canvasBackground.scalar) {
              // Sometimes returning key is scalar
              solidColor = canvasBackground.scalar;
            } else {
              isSolid = false;
              gradientColorObj = canvasBackground;
              // console.debug('showColorPicker', { canvasBackground });
            }
          }

          this.activeSolid = solidColor;
          this.gradientColorObj = gradientColorObj;
          if (this.isTextEditing) isSolid = true;

          this.activeTab = isSolid ? 'solid' : 'gradient';
          if (!isSolid) {
            this.updateGradientSampleColor();
          }
          // console.debug('activeTab', this.activeTab);
          setTimeout(() => {
            this.settingUp = false;
          }, 300);
        }, 100);
        // this.prepareForMultipleGradients();
      } else {
        // when closing, save the color to project color
        const { colorObject } = this;
        colorObject.silent = false;
        if (this.isStroke) {
          this.updateCanvasElementStroke(colorObject);
        } else {
          if (this.isWatermark) {
            this.$emit('close-watermark-colorpicker');
            colorObject.isWatermark = true;
          }

          this.updateCanvasElementColor(colorObject);
        }
      }
    },
    isTextEditing(val) {
      if (val) {
        this.changeTab('solid');
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/styles/components/chromeColorPicker.scss';

.colorpicker {
  top: 200px;
  z-index: 30;
  width: calc(100% - 57px);
  left: 30px;
  user-select: none;
  max-height: 100%;
  overflow: auto;

  .assets-container & {
    background: $darkGrey700;
  }

  @include smallest {
    width: calc(100% - 27px);
    left: 17px;

    .tool-modal__header,
    .tool-modal__main {
      padding-left: 13px;
      padding-right: 13px;
    }
  }

  &.colorpicker--double-margin-top {
    top: 265px;
  }

  &.colorpicker--color-tab {
    top: 170px;
    left: 29px;

    @include smallest {
      left: 15px;
      top: 160px;
    }

    // because when 'showEditTab' is true, got back button above
    &.colorpicker--margin-top {
      top: 215px;

      @include smallest {
        top: 205px;
      }
    }
  }

  &.colorpicker--background-colour-tab {
    top: 205px;

    @include smallest {
      top: 195px;
    }
  }

  .tool-modal__main {
    background: $darkGrey600;
  }

  .tab__head {
    display: flex;
    justify-content: center;

    .tab__item {
      width: 35%;
      padding: 5px;
      font-size: 0.875rem;
      font-weight: 500;
      color: $darkGrey300;
      margin: 0;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: $light;
      }

      &.is-active {
        color: $light;
      }
    }
  }

  p {
    margin: 0;
  }

  &.no-gradient {
    .tab__head {
      .tab__item[data-tab='color-gradient'] {
        display: none;
      }
    }
  }

  &.no-angle {
    [data-type='angle'] {
      display: none;
    }
    [data-tab='color-gradient'] .vc-ps-fields__hex .vc-input__input {
      margin-top: 35px;
    }
  }

  .tool-modal__header {
    display: none;
  }

  .tab__head + .tab__body {
    .tab__item {
      padding: 15px 0 0;
    }
  }

  p {
    margin-bottom: 5px;
  }

  .ui-anglepicker {
    background: transparent;
    width: 22px;
    height: 22px;
    border-color: $darkGrey;
  }

  .ui-anglepicker-dot {
    display: none;
  }

  .ui-anglepicker-line {
    margin-top: -1px;
    height: 7px;
    margin-left: 5px;
    width: 7px;
    background: $darkGrey;
    border-radius: 50%;
  }

  .ui-anglepicker-dragging .ui-anglepicker-line,
  .ui-anglepicker:hover .ui-anglepicker-line {
    background: $darkGrey;
  }

  .ui-anglepicker,
  .js-angle {
    display: inline-block;
    vertical-align: middle;
  }

  .anglepicker {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .anglepicker__picker {
      background: $darkGrey4;
      width: 45px;
      height: 45px;

      .picker {
        width: 15px;
        height: 15px;
        background: $lightGrey400;
        cursor: pointer;
      }
    }

    .anglepicker__value {
      width: 60px;
      margin-left: 10px;
    }

    input[type='text'] {
      padding: 5px 10px;
      border: 1px solid $grey7;
      font-size: 0.875rem !important;
      color: $lightGrey400;
      background: transparent;
      width: 60px;
      height: 30px;

      &:focus {
        border-color: $light;
      }
    }
  }

  .title {
    text-align: left;
    font-weight: 600;
    font-size: 0.875rem;
    color: $textGrey3;
  }

  .color__content {
    max-height: 300px;
    overflow: auto;
    overflow-x: hidden;
    margin-right: -10px;
    width: calc(100% + 10px);

    @include smallest {
      max-height: 130px;
    }
  }

  .colorpicker__group {
    position: relative;

    .gradient-loader-container {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      .loader__animation {
        width: 45px !important;
        height: 45px !important;
      }
    }

    .gradient-tool__container {
      &.container--hidden {
        opacity: 0;
      }
    }
  }
}

.colorpicker__group--right {
  width: calc(100% - 160px);
  position: absolute;
  right: 10px;
  top: 31px;
  z-index: 2;
}

.gradient__picker {
  margin-top: 7px;
  margin-bottom: 7px;
  text-align: left;
  height: 32px;
  position: relative;
  width: 100%;
}

.gradient-sample {
  // use fixed size of 235 to match the calculation in js
  width: calc(235px + 27px);
  height: 24px;
  line-height: 24px;
  margin: 0;
  cursor: pointer;

  @include smallest {
    // use fixed size of 190 to match the calculation in js
    width: calc(190px + 27px);
  }

  canvas {
    height: 10px;
    width: 100%;
    background: $light;
    border-radius: 8px;
  }

  &.is-disabled {
    cursor: auto;
    pointer-events: none;
  }
}
</style>
