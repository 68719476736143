<template lang="pug">
.assets-container(ref="assetsContainer")
  slot(name="navigation")

  .assets-sidebar(
    v-if="isShow"
    :class="classObject"
  )
    .assets-sidebar__content(:class="classObject")
      //- Lite mode doesn't need search, category, shortcub, subgroup
      template(v-if="!isLiteMode")

        slot(name="search-bar")

        slot(name="favourite-filter")

        slot(name="shortcut-container")

      .assets-lists(:class="{'extra-max-height': isGraphicTab || isBackgroundImage}")
        template(v-if="isLiteMode && !isLiteMusic")
          BaseButton.btn-add-lite-element(
            :is-canvas="true"
            @click="addLiteElementOnClick"
          ) {{ addLiteElementText }}

        //- Lite mode doesn't need search, category, shortcub, subgroup
        template(v-if="!isLiteMode")

          template(v-if="isOnMusicUploadTab")
            slot(name="sub-groups")

          template(v-if="showUploadButtons && selectedUploadSpace === 'personal'")
            UserFileUpload(
              :is-assets-container-upload="true"
              :use-extra-margin-top="isOnMusicUploadTab"
              @do-select-file="$emit('do-select-file', $event)"
            )

            UploadPerformanceInfoModal(
              :is-show="isFirstUploadTabVisit && !isOnMusicUploadTab"
              @close-information-modal="setIsFirstUploadTabVisit(false)"
            )

          template(v-if="!isOnMusicUploadTab")
            slot(name="sub-groups")

        slot(name="music-categories")

        template(v-if="!isLiteMode")

          slot(name="user-folders")

        slot(name="category")

        slot(name="category-items")

        slot(name="branded-asset-container")

        slot(name="my-file-action-buttons")

        slot(name="selected-music")

        slot(name="brand-tab")

        button.btn-collapse-sidebar(
          v-if="!isLiteMode"
          type="button"
          title="Close sidebar"
          @click="closeSidebar"
        )
          i.icon.icon-left-arrow

      template
        div.lite__unlock-features-button-container(v-if="isLiteMode")
          BaseButton.lite__unlock-features-button(
            :is-canvas="true"
            :is-primary="true"
            :is-full-width="true"
            :is-loading="isSubscriptionShowLoading"
            @click="setSubscriptionModal"
          )
            span {{ liteUnlockFeaturesButtonText }}
          i.icon-premium

  //- don't set ColorTab with v-if
  //- because it's needed to store the color on close colorpicker
  ColorTab(v-if="showColorTab || isBackgroundColourTab")
  FontTab(v-if="showFontTab && getActiveElements.length")
  AnimationTab(v-if="showAnimationTab")
  MaskTab(v-if="showMaskTab")
  EditTab(v-if="showEditTab")
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import VueCookies from 'vue-cookies';
import { debounce } from 'debounce';

import { groups, subgroups, groupsOrder } from '@/assets/scripts/enums';
import uploadingMixin from '@/components/mixins/uploading-mixins';
// import websocketMixins from '@/components/mixins/websocket-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

import EditTab from '@/components/ProjectCanvas/AssetsSidebar/EditTab.vue';
import ColorTab from '@/components/ProjectCanvas/AssetsSidebar/ColorTab.vue';
import AnimationTab from '@/components/ProjectCanvas/AssetsSidebar/AnimationTab.vue';
import MaskTab from '@/components/ProjectCanvas/AssetsSidebar/MaskTab.vue';
import FontTab from '@/components/ProjectCanvas/AssetsSidebar/FontTab.vue';
import DropboxSelector from '@/components/ProjectCanvas/AssetsSidebar/DropboxSelector.vue';
import UserFileUpload from '@/components/ProjectCanvas/AssetsSidebar/children/UserFileUpload.vue';
import UploadingModal from '@/components/ProjectCanvas/PageOverlay/UploadingModal.vue';
import UploadPerformanceInfoModal from './children/UploadPerformanceInfoModal.vue';

export default {
  name: 'AssetsContainer',
  mixins: [uploadingMixin, darkModeMixin],
  components: {
    EditTab,
    ColorTab,
    AnimationTab,
    MaskTab,
    FontTab,
    DropboxSelector,
    UserFileUpload,
    UploadingModal,
    UploadPerformanceInfoModal,
  },
  props: {
    selectedUploadSpace: { type: String, default: 'personal' },
  },
  data() {
    return {
      isShow: true,
      isSubscriptionShowLoading: false,

      startSlideUpSidebarAnimation: false,
      startSlideDownSidebarAnimation: false,
      previousSidebarGroup: '',

      isFirstUploadTabVisit: true,
    };
  },
  computed: {
    ...mapState([
      'showSidebar',
      'showFontTab',
      'showColorTab',
      'showAnimationTab',
      'showMaskTab',
      'showEditTab',
      'maintainShowBackgroundColorTab',
    ]),
    ...mapGetters(['isLiteMode']),
    ...mapGetters('canvasElements', ['getActiveElements']),
    ...mapGetters('canvasLayouts', ['rightSidebarWidth']),
    ...mapGetters('assetsSidebar', ['getActiveGroup', 'getActiveSubGroup']),
    classObject() {
      const classList = [];
      if (!this.showSidebar) {
        classList.push('is-hidden');
      }

      if (this.isLiteMode) {
        classList.push('content--space-between');
      }

      if ((this.isTextsTab && !this.isLiteMode) || this.isMyFilesCategory) {
        classList.push('no-padding-top');
      }

      if (this.isFavouriteTab) {
        classList.push('extra-padding-top');
      }

      if (this.startSlideUpSidebarAnimation) {
        classList.push('slide-up-animation');
      }

      if (this.startSlideDownSidebarAnimation) {
        classList.push('slide-down-animation');
      }

      return classList.join(' ');
    },
    isLiteImage() {
      return this.isLiteMode && this.getActiveGroup === groups.MEDIA;
    },
    isLiteText() {
      return this.isLiteMode && this.getActiveGroup === groups.TEXTS;
    },
    isLiteMusic() {
      return this.isLiteMode && this.getActiveGroup === groups.MUSIC;
    },
    isGraphicTab() {
      return this.getActiveGroup === groups.ELEMENTS;
    },
    isBackgroundImage() {
      return this.getActiveGroup === groups.BACKGROUNDS && this.getActiveSubGroup === 'images';
    },
    addLiteElementText() {
      let element = 'Image';
      if (this.isLiteText) element = 'Text';
      if (this.isLiteMusic) element = 'Music';
      return `+ Add ${element}`;
    },
    isOnMusicUploadTab() {
      return this.getActiveGroup === groups.MUSIC && this.getActiveSubGroup === subgroups.UPLOAD;
    },
    isTextsTab() {
      return this.getActiveGroup === groups.TEXTS;
    },
    isMyFilesCategory() {
      return this.getActiveGroup === groups.MYFILES;
    },
    isFavouriteTab() {
      return this.getActiveGroup === groups.FAVOURITES;
    },
    liteUnlockFeaturesButtonText() {
      let text = '';
      if (this.isLiteImage) text = 'Add Video / Graphics';
      if (this.isLiteText) text = 'Upload Font';
      if (this.isLiteMusic) text = 'Upload Music';
      return text;
    },
    isBackgroundColourTab() {
      return (
        this.showSidebar
        && this.getActiveGroup === groups.BACKGROUNDS
        && this.getActiveSubGroup === subgroups.COLOUR
      ) || this.maintainShowBackgroundColorTab;
    },
  },
  methods: {
    ...mapMutations([
      'setShowSidebar',
      'setShowUpgradeSubscription',
      'setShowLiteReplaceModal',
      'setShowLiteUploadMusic',
      'isPlaying',
      'setShowSubscriptionModal',
      'setShowColorTab',
    ]),
    ...mapMutations('assetsSidebar', ['setActiveSubGroup']),
    ...mapMutations('canvasLayouts', [
      'setCanvasSectionPaddingLeft',
      'setCanvasSectionPaddingRight',
    ]),
    ...mapMutations('canvasElements', ['emptyActiveElements']),
    ...mapActions('canvasElements', ['addTextElementToCanvas']),
    ...mapActions(['toggleColorTab']),
    closeSidebar() {
      // so when opening the animation tab / color tab / etc, the collapse sidebar button can have pointer cursor ( instead of putting 'pointer-events: none' )
      // another reason is so when click collapse button, can close tabs 1 by 1 instead of closing all of them
      if (
        this.showEditTab
        || (this.showColorTab && this.getActiveGroup !== groups.BACKGROUNDS)
        || this.showAnimationTab
        || this.showMaskTab
      ) {
        return;
      }

      this.setShowSidebar(false);
      if (this.getActiveGroup === groups.BACKGROUNDS && this.getActiveSubGroup === subgroups.COLOUR) {
        this.setShowColorTab(false);
      }
    },
    updateCanvasSectionPaddingLeft() {
      if (this.isShow) {
        this.setCanvasSectionPaddingLeft(this.$refs.assetsContainer.offsetWidth);
      } else {
        this.setCanvasSectionPaddingLeft(0);
      }
    },
    // createVideoTrimmerChannel() {
    //   if (!process.env.VUE_APP_PUSHER_ENABLE) return; // socket disabled
    //   const channelName = `user_file_${this.userId}`;
    //   this.offeoWebSocket
    //     .channel(channelName)
    //     .listen('WebSocketEvent', ({ data }) => {
    //       data.data.forEach((respondedFile) => {
    //         if (respondedFile.video_trim_complete) {
    //           const filename = respondedFile.original_name;
    //           const index = this.notifiedFiles.findIndex(val => val === filename);
    //           if (index < 0) {
    //             this.$notify({
    //               group: 'BottomRightNotif',
    //               type: 'success',
    //               title: 'Hey there!',
    //               text: `${respondedFile.original_name} is ready for use in your project.`,
    //               duration: 3000,
    //             });
    //             if (this.getActiveGroup === 'my-files') {
    //               this.setActiveSubGroup('videos');
    //               this.setActiveCategory({
    //                 id: 'all',
    //                 slug: 'all',
    //               });
    //             }
    //           } else {
    //             this.notifiedFiles.push(filename);
    //           }
    //         }
    //       });
    //     });
    // },
    addLiteElementOnClick() {
      this.isPlaying(false);
      this.emptyActiveElements();

      if (this.isLiteText) {
        this.addTextElementToCanvas('heading');
      }

      if (this.isLiteImage) {
        this.setShowLiteReplaceModal(true);
      }

      if (this.isLiteMusic) {
        this.setShowLiteUploadMusic(true);
      }
    },
    setSubscriptionModal() {
      this.isSubscriptionShowLoading = true;
      this.setShowSubscriptionModal(true);
      setTimeout(() => {
        this.isSubscriptionShowLoading = false;
      }, 5000);
    },
    setIsFirstUploadTabVisit() {
      const firstVisitCookieName = process.env.VUE_APP_FIRST_UPLOAD_TAB_VISIT_COOKIE;
      VueCookies.set(firstVisitCookieName, false);

      this.isFirstUploadTabVisit = false;
    },
    detectFirstUploadTabVisit() {
      const firstVisitCookieName = process.env.VUE_APP_FIRST_UPLOAD_TAB_VISIT_COOKIE;
      const isFirstVisit = VueCookies.get(firstVisitCookieName) === null;

      this.isFirstUploadTabVisit = isFirstVisit;
    },
  },
  watch: {
    isShow() {
      this.$nextTick(() => {
        this.updateCanvasSectionPaddingLeft();
      });
    },
    showSidebar(val) {
      // when closing, add class hidden before fully hiding it
      if (val) this.isShow = true;
      else {
        setTimeout(() => {
          this.isShow = false;
        }, 300);
      }
    },
    getActiveGroup(newSidebarGroup) {
      const previousGroupOrderNumber = groupsOrder[this.previousSidebarGroup];
      const currentGroupOrderNumber = groupsOrder[newSidebarGroup];
      const isSelectingToBottom = previousGroupOrderNumber < currentGroupOrderNumber;

      if (isSelectingToBottom) {
        this.startSlideUpSidebarAnimation = true;
      } else {
        this.startSlideDownSidebarAnimation = true;
      }

      setTimeout(() => {
        this.startSlideUpSidebarAnimation = false;
        this.startSlideDownSidebarAnimation = false;

        this.previousSidebarGroup = newSidebarGroup;
      }, 650);
    },
  },
  mounted() {
    this.updateCanvasSectionPaddingLeft();
    window.addEventListener(
      'resize',
      debounce(() => {
        this.updateCanvasSectionPaddingLeft();
      }, 300),
    );

    this.previousSidebarGroup = this.getActiveGroup;
    this.detectFirstUploadTabVisit();
  },
};
</script>

<style lang="scss">
.assets-container {
  display: inline-block;
  vertical-align: top;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;

  & * {
    &::-webkit-scrollbar-track {
      background-color: rgba($darkGrey600, 0);
      transition: background-color 0.5s ease-in-out;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($darkGrey200, 0);
      transition: background-color 0.5s ease-in-out;
    }

    &:hover::-webkit-scrollbar-track {
      background-color: $darkGrey600;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: $darkGrey200;
    }
  }

  &.show-only-user-menu {
    position: static;

    .assets-navigation {
      position: static;
    }

    .nav-item__user {
      // need to be on top of storyboard
      z-index: 23;
      position: fixed;
      bottom: 0;
      left: 0;
    }
  }

  .col-1fifth {
    width: 20%;
    float: left;
  }
  .col-1third {
    width: 33.333%;
    float: left;
  }
  .col-1of1 {
    width: calc(100% - 4px);
  }
  .col-half {
    width: 49.5%;
    float: left;
    min-height: 100%;
    padding: 0;
  }

  .switch {
    .slider {
      background: $darkGrey100;
    }
  }
}

.assets-sidebar {
  width: 360px;
  height: 100%;
  display: inline-flex;
  transition: transform 0.25s ease-in-out;
  background: $darkGrey700;
  position: relative;

  @include smallest {
    width: 270px;
  }

  @include fb-requirement {
    width: 240px;
  }

  &.is-hidden {
    transform: translateX(-110%);
  }

  .assets-sidebar__content {
    display: inline-flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 10px 30px 0;

    @include smallest {
      padding: 10px 15px 0;
    }

    &.content--space-between {
      justify-content: space-between;
    }

    &.no-padding-top {
      padding: 0 30px;

      @include smallest {
        padding: 0 15px;
      }
    }

    &.extra-padding-top {
      padding: 15px 30px 0;

      @include smallest {
        padding: 15px 15px 0;
      }
    }

    &.slide-up-animation {
      animation: slideUp 0.65s cubic-bezier(0.12, 0.71, 0.13, 0.98);
    }

    &.slide-down-animation {
      animation: slideDown 0.65s cubic-bezier(0.12, 0.71, 0.13, 0.98);
    }

    @keyframes slideUp {
      0% {
        opacity: 0.35;
        transform: translateY(15px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @keyframes slideDown {
      0% {
        opacity: 0.35;
        transform: translateY(-15px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.grid-lists {
  overflow: auto;

  &::-webkit-scrollbar-track {
    background-color: rgba($darkGrey600, 0);
    transition: background-color 0.5s ease-in-out;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($darkGrey200, 0);
    transition: background-color 0.5s ease-in-out;
  }

  &:hover::-webkit-scrollbar-track {
    background-color: $darkGrey600;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: $darkGrey200;
  }
}

.grid-item {
  background-color: transparent;
  display: inline-block;
  margin: 0 2px;
  position: relative;
  padding: 2px 4px;
  width: 100%;
  top: 0 !important;
  left: 0 !important;
  cursor: pointer;
  overflow: hidden;

  &.is-alpha-video .animated-icon {
    font-size: 16px;
    &::before {
      content: $icon-play-triangle;
    }
  }
}

.btn-collapse-sidebar {
  padding: 5px 3px 5px 0;
  height: 90px;
  display: flex;
  align-items: center;
  background: $darkGrey700;
  color: $lightGrey700;
  position: absolute;
  z-index: 5;
  right: 1px;
  top: 42vh;
  border-radius: 0 10px 10px 0;
  transform: translateX(100%);
  cursor: pointer;
  box-shadow: 2px 3px 4px rgba(10, 31, 68, 0.05);
  transition: color 0.25s ease-in-out;
  font-size: 1rem;

  &:hover {
    background: $darkGrey700;
    color: $lightWhite;
  }

  &:focus {
    background: $darkGrey700 !important;
    color: $lightGrey700;
  }

  .icon {
    position: relative;
    right: 2px;
  }
}

.assets-lists {
  width: 100%;
  flex: 1 auto;
  padding: 10px 0 0;
  transition: transform 0.35s ease-in-out, border-radius 0.5s ease-in-out;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;

  .lite-mode & {
    padding: 0;
    max-height: 90%;
  }

  .search-container + & {
    max-height: calc(100% - 46px);
  }

  > *:not(.btn-collapse-sidebar):not(.credits) {
    display: flex;
    width: 100%;
  }

  &.extra-max-height {
    max-height: calc(100% - 38px);
  }

  &.no-padding-top {
    padding-top: 0;
  }

  .tab__head {
    flex: 0 0 auto;
  }

  .category-item-container {
    flex: auto;
    height: 90%;
    max-height: calc(100% - 51px);
    transition: height 0.25s ease-in-out, max-height 0.25s ease-in-out;
    z-index: 1;

    &:first-child {
      max-height: 100%; // text tab
    }

    &.inside-folder {
      max-height: calc(100% - 100px);
    }
  }

  .tab__head + .category-item-container {
    // graphics tab + myfiles [images&videos] tab
    max-height: calc(100% - 35px);

    &.is-multi-selecting {
      // my files tab when there is multiple selection
      .grid-lists {
        padding-bottom: 20px;
      }
    }
  }

  .category-item-container {
    .grid-lists--music {
      padding-bottom: 20px;
    }
  }

  .btn-view-music-category ~ .category-item-container {
    // library music tab
    max-height: calc(100% - 140px);

    .lite-mode & {
      max-height: calc(100% - 120px);
    }
  }

  .item-element-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    &.element-wrapper--overflow-hidden {
      overflow: hidden; // cover in lite music, the preloader overlap the buttons
    }
  }

  .accordion {
    height: 100%;

    &.ps {
      padding-bottom: 30px;
    }
  }

  .btn-tooltip {
    width: 16px !important;
    height: 16px !important;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 0.875em;
    vertical-align: middle;
    cursor: pointer;
    transition: opacity 0.25s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }

  &.has-category {
    border-bottom-right-radius: 0;
  }

  &.is-collapsed {
    transform: translateX(-800px);
    pointer-events: none;
  }

  .grid-lists {
    height: 100%;
    width: 100%;
    position: relative;
    margin: 10px 0 0;
    padding: 0;
    user-select: none;
    overflow-x: hidden;

    &.grid--equal-height {
      display: flex;
      flex-wrap: wrap;
    }

    &--music {
      padding-right: 5px;
    }

    &::after {
      content: '';
      display: table;
    }

    &#item-designs {
      height: calc(100vh - 127px);
    }

    .no-search & #item-elements {
      max-height: calc(100vh - 96px);
    }

    &#item-my-files-uploads {
      // max-height: calc(100vh - 313px);
      max-height: calc(100vh - 240px);
    }
  }
}

.lite__unlock-features-button-container {
  position: relative;

  .icon-premium {
    position: absolute !important;
    right: 7px;
    top: 14px;
    color: $orange600;
    font-size: 0.75rem;
  }
}

.lite__unlock-features-button {
  margin: 10px 0 15px;
  padding-top: 12px;
  padding-bottom: 12px;

  &:focus {
    color: $light;
    background: $blue700;
  }
}

.sidebar-icon-top {
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  width: 15px;
  opacity: 0;
  color: $darkGrey;
  transition: 0.3s;
  cursor: pointer;

  &.is-active {
    opacity: 0.8;
  }

  &.delete-folder {
    right: auto;
    left: 0;
  }
}

.category-item__animated_preview {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  position: absolute;
  top: 4px;
  left: 5px;
  opacity: 0;
}

.btn-add-lite-element {
  padding-top: 14px;
  padding-bottom: 14px;
  margin-bottom: 15px;
  font-size: 0.9em;
  background: var(--darkgrey500-darkgrey900);
  transition: box-shadow 0.2s ease-in-out, background 0.2s ease-in-out, color 0.2s ease-in-out;
}
</style>
