<template lang="pug">
  .brand-tab-graphic
    .tab-graphic__header
      h1 Brand Graphic
      BaseButton.manage-graphic__button(
        :is-text="true"
        :plain-hover="true"
      ) Manage

    template
      UserFileUpload(@do-select-file="$emit('do-select-file', $event)")
</template>

<script>
import UserFileUpload from '@/components/ProjectCanvas/AssetsSidebar/children/UserFileUpload.vue';

export default {
  name: 'BrandTabGraphic',
  components: {
    UserFileUpload,
  },
};
</script>

<style lang="scss">
.tab-graphic__header {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 5px;

  h1 {
    font-size: 14px;
    color: $light;
  }

  .manage-graphic__button {
    color: $lightGrey700;
    text-decoration: underline;
  }
}
</style>
