<template lang="pug">
BaseDialog(
  isShow=true
  text="Do you want to create a video preview?"
  button1Text="Create Preview"
  @confirm="$emit('confirm')"
  @closeOverlay="$emit('close')"
)
</template>

<script>
export default {
  name: 'CreateVideoDialog',
};
</script>

<style lang="scss">
.dialog-modal__content {
  max-width: 600px;

  .dialog-modal__buttons {
    width: 100%;

    .btn {
      max-width: 150px;
    }
  }
}
</style>
