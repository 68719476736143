<template lang="pug">
//- hover element style is for the position, size and rotate of the element
//- the child style is for the animate in and out transform
.hover-element(
  :class="{ 'is-show': element.width }"
  :style="styleObject"
)
  .hover-element__child(
    :style="childStyleObject"
  )
</template>

<script>
import { mapGetters } from 'vuex';
import Vector from '@minogin/vector';
import textMixin from '@/components/mixins/text-mixins';

export default {
  name: 'HoverElement',
  mixins: [textMixin],
  props: {
    element: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters([
      'getCanvasZoom',
    ]),
    ...mapGetters('canvasElements', [
      'getCanvasElementById',
      'getCanvasElements',
      'getCanvasSize',
    ]),
    styleObject() {
      const canvasElement = this.getCanvasElementById(this.element.id);
      if (canvasElement === undefined) return;

      if (canvasElement.data.isGroup) {
        // eslint-disable-next-line
        return this.groupStyleObject(canvasElement);
      }

      const styleObject = {};

      if (this.element.width) {
        styleObject.width = `${this.element.width}px`;
        styleObject.height = `${this.element.height}px`;
        styleObject.top = `${this.element.top}px`;
        styleObject.left = `${this.element.left}px`;
        styleObject.transform = this.element.transform;
      }

      // eslint-disable-next-line
      return styleObject;
    },
    childStyleObject() {
      const styleObject = {};

      if (this.element) {
        styleObject.transform = this.element.childTransform;
      }

      return styleObject;
    },
  },
  methods: {
    groupStyleObject(canvasElement) {
      const groupElements = this.getCanvasElements.filter(el => el.data.isGroup
               && el.data.groupId == canvasElement.data.groupId); // eslint-disable-line

      let styleObject = {
        x: 0,
        y: 0,
        w: 2,
        h: 2,
      };

      const boundingBox = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      };

      // eslint-disable-next-line
      for (let i = 0; i < groupElements.length; i++) {
        const element = groupElements[i];

        // eslint-disable-next-line
        if (element.size.width === 'auto' || element.size.height === 'auto' || element.size.width === null || element.size.height === null) continue;

        const position = {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        };

        if (i === 0) {
          boundingBox.left = this.getCanvasSize.width;
          boundingBox.top = this.getCanvasSize.height;
        }

        position.left = (element.position.x - element.size.width / 2) * this.getCanvasZoom;
        position.right = (element.position.x + element.size.width / 2) * this.getCanvasZoom;
        position.top = (element.position.y - element.size.height / 2) * this.getCanvasZoom;
        position.bottom = (element.position.y + element.size.height / 2) * this.getCanvasZoom;

        if (element.rotate !== 0 && groupElements.length !== 1) {
          // eslint-disable-next-line
          const { rotate } = element;

          const radAngle = Vector.rad(element.rotate);

          const relative = {
            top: position.top - element.position.y * this.getCanvasZoom,
            left: position.left - element.position.x * this.getCanvasZoom,
            bottom: position.bottom - element.position.y * this.getCanvasZoom,
            right: position.right - element.position.x * this.getCanvasZoom,
          };

          const topLeft = new Vector(relative.left, relative.top).rotate(radAngle);
          const topRight = new Vector(relative.right, relative.top).rotate(radAngle);
          const bottomLeft = new Vector(relative.left, relative.bottom).rotate(radAngle);
          const bottomRight = new Vector(relative.right, relative.bottom).rotate(radAngle);

          const mostCorner = {
            top: Math.min(topLeft.y, topRight.y, bottomLeft.y, bottomRight.y),
            bottom: Math.max(topLeft.y, topRight.y, bottomLeft.y, bottomRight.y),
            left: Math.min(topLeft.x, topRight.x, bottomLeft.x, bottomRight.x),
            right: Math.max(topLeft.x, topRight.x, bottomLeft.x, bottomRight.x),
          };

          position.left = element.position.x * this.getCanvasZoom + mostCorner.left;
          position.right = element.position.x * this.getCanvasZoom + mostCorner.right;
          position.top = element.position.y * this.getCanvasZoom + mostCorner.top;
          position.bottom = element.position.y * this.getCanvasZoom + mostCorner.bottom;
        }

        if (boundingBox.top > position.top) boundingBox.top = position.top;
        if (boundingBox.bottom < position.bottom) boundingBox.bottom = position.bottom;
        if (boundingBox.left > position.left) boundingBox.left = position.left;
        if (boundingBox.right < position.right) boundingBox.right = position.right;

        const width = Math.abs(boundingBox.right - boundingBox.left);
        const height = Math.abs(boundingBox.bottom - boundingBox.top);

        styleObject = {
          left: `${boundingBox.left}px`,
          top: `${boundingBox.top}px`,
          width: `${width}px`,
          height: `${height}px`,
        };
      }

      return styleObject;
    },
  },
};
</script>

<style lang="scss">
.hover-element {
  position: absolute;
  z-index: 20;
  pointer-events: none !important;
  display: none;

  &.is-show {
    display: block;
  }
}

.hover-element__child {
  outline: 2px solid #5AACFF;
  // background: rgba(59, 84, 162, 0.2);
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
