<template lang="pug">
  BaseButton.animation-tab__item(
    :class="[{'is-selected': isSelected}, colorClass, extraClass, transition.value]"
    :plain-hover="true"
    @click="changeAnimation"
  )
    span.word {{ transitionName(transition) }}
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { isUnsupportedEffectExist } from '@/assets/scripts/utilities';

export default {
  name: 'AnimationTabItem',
  props: {
    colorClass: { type: String, default: 'is-green' },
    transition: { type: Object, default: () => ({}) },
    selectedTransition: { type: Object, default: () => ({}) },
    activeTab: { type: String, default: '' }, // default of per-text
    isTransition: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('canvasElements', ['selectedAnimationDirection']),
    ...mapGetters(['getActiveAnimationList']),
    ...mapGetters('canvasElements', ['getActiveElements']),
    activeElement() {
      return this.getActiveElements[0];
    },
    isAnimationTabItemDisabled() {
      const { value } = this.transition;

      const unsupportedEffectExist = this.activeElement ? isUnsupportedEffectExist(this.activeElement.data) : false;
      const isTransitionWithValidation = value === 'peek' || value === 'snake';

      return unsupportedEffectExist && isTransitionWithValidation;
    },
    isSelected() {
      if (this.selectedTransition.value === this.transition.value) return true;
      if (this.selectedTransition.value === '' && this.transition.value === 'none') return true;
      return false;
    },
    extraClass() {
      const cssClass = [];

      if (this.isAnimationTabItemDisabled) cssClass.push('is-disabled');
      if (this.getActiveAnimationList.type === 'out') cssClass.push('is-out');

      return cssClass.join(' ');
    },
  },
  methods: {
    transitionName(transition) {
      if (!transition.shortName) return transition.name;
      return transition.shortName;
    },
    changeAnimation() {
      if (!this.isSelected) {
        const animation = {
          value: this.transition.value,
          direction: '',
          directionList: this.transition.direction,
        };

        if (this.transition.direction.length !== 0) {
          // use the previous selected direction instead of default
          let defaultDirection = this.selectedAnimationDirection;
          if (!this.transition.direction.includes(defaultDirection)) {
            // eslint-disable-next-line
            defaultDirection = this.transition.direction[0];
          }

          animation.direction = defaultDirection;
        }

        this.$emit('change-animation', animation);
      }
    },
  },
};
</script>

<style lang="scss">
.animation-tab__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 93px;
  padding: 5px;
  color: $disabledGrey;
  background: $mobileDarkBlue;
  border-radius: 8px;
  font-weight: 400;
  font-size: 0.8125rem;
  opacity: 1;

  @include smallest {
    height: 72px;
  }

  .btn__circle {
    width: 150px !important;
    height: 150px !important;
    background: $mobileDarkBlue;
  }

  &:not(.is-selected) {
    opacity: 0.4;
  }

  &:hover {
    opacity: 1;
    color: $disabledGrey;
  }

  &:focus {
    background: $mobileDarkBlue;
  }

  &.is-selected {
    color: $light;

    &.is-green {
      background: $green600;

      .btn__circle {
        background: $green600;
      }
    }

    &.is-orange {
      background: $orange600;

      .btn__circle {
        background: $orange600;
      }
    }

    &.is-red {
      background: $red400;

      .btn__circle {
        background: $red400;
      }
    }
  }

  &.is-disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  // ---------------------------
  // in animations on hover
  // ---------------------------
  &.fade-in:hover {
    .word {
      animation: fade-in 1s 0.2s;
    }
  }

  &.bounce-in:hover {
    .word {
      animation: bounce-in 1s 0.2s;
    }
  }

  &.fly-in:hover {
    .word {
      animation: fly-in 2s 0.2s ease-in-out;
    }
  }

  &.jack-in-the-box:hover {
    .word {
      animation: jack-in-the-box 1s 0.2s;
    }
  }

  &.light-speed:hover {
    .word {
      animation: light-speed 1s 0.2s;
    }
  }

  &.mask-in:hover {
    .word {
      animation: mask-in 1s 0.2s;
    }
  }

  &.push-in:hover {
    .word {
      animation: push-in 1s 0.2s;
    }
  }

  &.peek:hover {
    .word {
      animation: peek 1s 0.2s;
    }
  }

  &.roll-in:hover {
    .word {
      animation: roll-in 1s 0.2s;
    }
  }

  &.rotate-in:hover {
    .word {
      animation: rotate-in 1s 0.2s;
    }
  }

  &.rubber-band:hover {
    &:not(.is-out) {
      .word {
        animation: rubber-band 1s 0.2s;
      }
    }

    &.is-out {
      .word {
        animation: rubber-band 1s 0.5s reverse;
      }
    }
  }

  &.slide-in:hover {
    .word {
      animation: slide-in 1s 0.2s;
    }
  }

  &.snake:hover {
    .word {
      animation: snake 1s 0.2s ease-in-out 3;
    }
  }

  &.wipe-in:hover {
    .word {
      animation: wipe-in-wrapper 1s 0.2s;
    }
  }

  &.zoom-in:hover {
    &:not(.is-out) {
      .word {
        animation: zoom-in 1s 0.2s;
      }
    }

    &.is-out {
      .word {
        animation: zoom-in 1s 0.2s reverse;
      }
    }
  }

  &.zoom-out:hover {
    &:not(.is-out) {
      .word {
        animation: zoom-out 1s 0.2s;
      }
    }

    &.is-out {
      .word {
        animation: zoom-out 1s 0.2s reverse;
      }
    }
  }

  // ---------------------------
  // out animations on hover
  // ---------------------------
  &.fade-out:hover {
    .word {
      animation: fade-in 1s 0.2s reverse;
    }
  }

  &.bounce-out:hover {
    .word {
      animation: bounce-in 1s 0.2s reverse;
    }
  }

  &.fly-out:hover {
    .word {
      animation: fly-in 2s 0.2s reverse ease-in-out;
    }
  }

  &.hinge:hover {
    .word {
      animation: hinge 2s 0.2s;
    }
  }

  &.mask-out:hover {
    .word {
      animation: mask-in 1s 0.2s reverse;
    }
  }

  &.push-out:hover {
    .word {
      animation: push-in 1s 0.2s reverse;
    }
  }

  &.roll-out:hover {
    .word {
      animation: roll-in 1s 0.2s reverse;
    }
  }

  &.rotate-out:hover {
    .word {
      animation: rotate-in 1s 0.2s reverse;
    }
  }

  &.slide-out:hover {
    .word {
      animation: slide-in 1s 0.2s reverse;
    }
  }

  &.wipe-out:hover {
    .word {
      animation: wipe-in-wrapper 1s 0.2s reverse;
    }
  }
  // ---------------------------
  // during animations on hover
  // ---------------------------
  &.heartbeat:hover {
    .word {
      animation: heartbeat 1s 0.2s 3;
    }
  }

  &.flash:hover {
    .word {
      animation: flash 1s 0.2s 3;
    }
  }

  &.shake:hover {
    .word {
      animation: shake 0.2s 0.2s 3;
    }
  }

  &.slide:hover {
    .word {
      animation: slide-in 3s 0.2s linear reverse;
    }
  }

  &.slow-scale-in:hover {
    .word {
      animation: zoom-in 5s 0.2s linear reverse;
    }
  }

  &.slow-scale-out:hover {
    .word {
      animation: zoom-out 5s 0.2s linear reverse;
    }
  }

  &.swing:hover {
    .word {
      animation: swing 1s 0.2s 3;
      transform-origin: top center;
    }
  }

  &.rotate-cw:hover {
    .word {
      animation: rotate 5s 0.2s linear;
    }
  }

  &.rotate-ccw:hover {
    .word {
      animation: rotate 5s 0.2s linear reverse;
    }
  }

  &.floating:hover {
    .word {
      animation: floating 2s 0.2s 3 ease-in-out;
    }
  }
}

// animations list
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce-in {
  from,
  0%,
  50%,
  70%,
  85%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  70% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  85% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fly-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes light-speed {
  from {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(-30deg);
    transform: translate3d(-100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  70% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg);
    opacity: 1;
  }

  90% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes mask-in {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes push-in {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes roll-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotateY(180deg);
    transform: translateX(-100%) rotateY(180deg);
  }

  70% {
    opacity: 1;
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0) rotateY(0);
    transform: translateX(0) rotateY(0);
  }
}

@keyframes rotate-in {
  0% {
    opacity: 0;
    -webkit-transform: scaleX(180deg);
    transform: translateX(-100%) rotateZ(180deg);
  }

  70% {
    opacity: 1;
  }

  to {
    -webkit-transform: translateX(0) rotateZ(0);
    transform: translateX(0) rotateZ(0);
    opacity: 1;
  }
}

@keyframes rubber-band {
  0% {
    opacity: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: scaleX(1.2);
    transform: scaleX(1.2);
  }

  70% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
  }

  85% {
    -webkit-transform: scaleX(1.05);
    transform: scaleX(1.05);
  }

  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  30% {
    opacity: 1;
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(4);
    transform: scale(4);
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes zoom-out {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  25% {
    -webkit-transform: translateX(-5%);
    transform: translateX(-5%);
  }

  75% {
    -webkit-transform: translateX(5%);
    transform: translateX(5%);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes swing {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }

  25% {
    -webkit-transform: rotateZ(-5deg);
    transform: rotateZ(-5deg);
  }

  75% {
    -webkit-transform: rotateZ(5deg);
    transform: rotateZ(5deg);
  }

  100% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes floating {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes wipe-in-wrapper {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes wipe-in {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    -webkit-transform: rotateZ(80deg);
    transform: rotateZ(80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    -webkit-transform: rotateZ(60deg);
    transform: rotateZ(60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes snake {
  from,
  to {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(30%);
  }
}

@keyframes peek {
  25% {
    transform: rotateX(30deg) translateY(20%) rotate(-13deg);
  }
  50% {
    transform: translateY(-40%) rotate(3deg) scale(1.1);
  }
}

@keyframes jack-in-the-box {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes typewriter {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    width: auto;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  25% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
