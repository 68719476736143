<template lang="pug">
#import-export-project-settings-wrapper
  .import__export-button-wrapper

    BaseButton.btn-download-project.btn-import-project(
      :disabled="isExporting"
      :is-canvas="true"
      @click="exportProjectSettings"
    ) {{ exportingText }}

    BaseButton.btn-download-project(
      is-primary=true
      :is-canvas="true"
      @click="importProjectSettings"
    ) Import

    input.input-import-file(
      type="file"
      ref="importFile"
      @change="doReadFileImport"
    )

  BaseDialog(
    :isShow="showImportNotif"
    hideCloseButton=true
    @confirm="confirmImport"
    @closeOverlay="showImportNotif = false"
  )
    div(
      v-html="baseDialogText"
    )
    div(
      class="template-status-selection"
      v-if="isTemplate"
    )
      span Template status:
      select(
        v-model="templateStatus"
      )
        option(value="published") Published
        option(value="privated") Privated
        option(value="publishing") Publishing
        option(value="progress") Progress

</template>

<script>
import {
  mapMutations, mapGetters, mapState, mapActions,
} from 'vuex';
import helperMixins from '@/components/mixins/helper-mixins';
import ProjectApi from '@/services/api/ProjectApi';
import TemplateApi from '@/services/api/TemplateApi';
import Api from '@/services/api/Api';


export default {
  name: 'showImportExportProjectSettings',
  mixins: [helperMixins],
  data() {
    return {
      newProjectDetails: {},
      isExporting: false,
      importedProjectDetails: {},
      showImportNotif: false,
      templateStatus: 'progress',
    };
  },
  computed: {
    ...mapState(['isTemplate']),
    ...mapGetters('canvasElements', ['getProjectId']),
    exportingText() {
      return this.isExporting ? 'Exporting...' : 'Export';
    },
    baseDialogText() {
      // console.log('baseDialogText', this.importedProjectDetails);
      const { details, scenes } = this.importedProjectDetails;
      if (
        typeof details !== 'undefined'
        && (typeof details.project_name !== 'undefined'
          || typeof details.template_name !== 'undefined')
      ) {
        const type = this.isTemplate ? 'template' : 'project';
        let text = `This will overwrite current ${type}, confirm to import a ${type}:<br>`;

        text += '<ul style="text-align: left;">';

        if (this.isTemplate) {
          text += `<li>Template: ${details.template_name}</li>`;
        } else {
          text += `<li>Project: ${details.project_name}</li>`;
        }
        text += `<li>Duration: ${details.duration}</li>`;
        text += `<li>Dimension: ${details.canvas_width}x${details.canvas_height}</li>`;
        text += `<li>Scenes: ${scenes.length}</li>`;
        text += '</ul>';

        return text;
      }

      return '';
    },
  },
  methods: {
    ...mapMutations(['setCurrentTime', 'setShowCanvasPreloader']),
    ...mapMutations('canvasElements', [
      'updateActiveSceneId',
      'updateCanvasSize',
      'updateProjectDetails',
      'addSceneToArray',
      'updateHasChanges',
    ]),
    ...mapActions('canvasElements', ['updateScenesElements']),
    exportProjectSettings() {
      if (this.isExporting) return;

      this.isExporting = true;
      this.newProjectDetails = {};

      const params = {
        id: this.getProjectId,
        is_template: this.isTemplate,
      };

      Api.exportProjectDetails(params)
        .then((response) => {
          if (response.data.success) {
            const projectDetails = response.data.results;
            this.newProjectDetails = projectDetails;
            this.newProjectDetails.is_template = this.isTemplate;
            this.downloadJsonFile();
          }
        })
        .catch(() => {
          this.isExporting = false;
          this.alertError(
            '',
            'Oops, something went wrong. Try again later.',
            5000,
            'TopCenterNotif',
          );
        });
    },
    importProjectSettings() {
      console.log('exportProjectSettings');
      this.$refs.importFile.value = '';
      this.$refs.importFile.click();
    },
    downloadJsonFile() {
      this.alertSuccess('', 'Exporting config file...', 5000, 'TopCenterNotif');
      const fileId = this.isTemplate ? `template-${this.getProjectId}` : this.getProjectId;
      const filename = `export-${fileId}.json`;
      const jsonStr = JSON.stringify(this.newProjectDetails);
      const element = document.createElement('a');
      element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(jsonStr)}`);
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      this.isExporting = false;
    },
    doReadFileImport(event) {
      const file = event.target.files[0]; // FileList object

      if (file.type !== 'application/json') {
        this.alertError('', 'Please upload valid config file', 3000, 'TopCenterNotif');
        return;
      }

      const reader = new FileReader();

      reader.readAsText(file, 'UTF-8');
      reader.onload = (evt) => {
        this.importedProjectDetails = JSON.parse(evt.target.result);

        if (typeof this.importedProjectDetails === 'object') {
          // console.log(this.importedProjectDetails);

          if (this.importedProjectDetails.is_template && !this.isTemplate) {
            this.alertError(
              '',
              'Failed, you are about to import a project config to a template. Import cancelled',
              5000,
              'TopCenterNotif',
            );
            return;
          }
          if (!this.importedProjectDetails.is_template && this.isTemplate) {
            this.alertError(
              '',
              'Failed, you are about to import a template config to a project. Import cancelled',
              5000,
              'TopCenterNotif',
            );
            return;
          }
          this.showImportNotif = true;

          if (this.isTemplate) {
            this.templateStatus = this.importedProjectDetails.template_status || 'progress';
          }
        }
      };
      reader.onerror = (evt) => {
        console.error(evt);
        this.alertError('', evt, 5000, 'TopCenterNotif');
      };
    },
    confirmImport() {
      this.setShowCanvasPreloader(true);
      this.showImportNotif = false;

      const params = this.importedProjectDetails;

      if (this.isTemplate) {
        params.details.template_status = this.templateStatus;
      }

      params.id = this.getProjectId;

      Api.importProjectDetails(params)
        .then((response) => {
          if (response.data.success) {
            this.updateHasChanges(false);
            // to prevent showing reload confirmation

            this.$nextTick(() => {
              window.location.reload();
            });
          }
        })
        .catch(() => {
          this.isExporting = false;
          this.alertError(
            '',
            'Oops, something went wrong. Try again later.',
            5000,
            'TopCenterNotif',
          );
        });
    },
    importScenes(idx = 0, activeScene = -1) {
      // console.log('importScenes', idx, activeScene, this.importedProjectDetails.scenes.length);
      if (idx >= this.importedProjectDetails.scenes.length) {
        this.setShowCanvasPreloader(false);
        this.$emit('close');
        return;
      }
      const scene = JSON.parse(JSON.stringify(this.importedProjectDetails.scenes[idx]));

      const APIREQUEST = this.isTemplate ? TemplateApi : ProjectApi;

      const projectSceneParams = {
        background: scene.background,
        duration: scene.duration,
        transition: scene.transition,
        scene_elements: scene.elements,
      };

      if (this.isTemplate) {
        if (scene.is_hero_wo_bg === true) projectSceneParams.is_hero_wo_bg = true;
        if (scene.is_hero_w_bg === true) projectSceneParams.is_hero_w_bg = true;
        projectSceneParams.modular_category_id = scene.modular_category_id;
      }

      // console.log('activeScene', activeScene);

      if (activeScene !== -1) {
        console.log('updateProjectScene -1');
        // replace the first scene which are require upon reseting the canvas
        APIREQUEST.updateProjectScene(this.getProjectId, activeScene, projectSceneParams)
          .then((response) => {
            if (response.data.success) {
              console.log('updateProjectScene -1', response.data.new_scene_details);
              const newScenes = [response.data.new_scene_details];
              this.updateScenesElements({ newScenes });
              this.importScenes(idx + 1);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        APIREQUEST.addProjectScene(this.getProjectId, projectSceneParams)
          .then((response) => {
            if (response.data.success) {
              const result = response.data.results;
              this.addSceneToArray({
                newId: result.id,
                background: result.background,
                duration: result.duration,
                elements: result.elements,
                transition: result.transition,
              });
              this.updateTemplateSceneMeta(idx, result.id);
              this.importScenes(idx + 1);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    updateTemplateSceneMeta(idx = 0, sceneId) {
      if (!this.isTemplate) return;

      const scene = JSON.parse(JSON.stringify(this.importedProjectDetails.scenes[idx]));

      const APIREQUEST = this.isTemplate ? TemplateApi : ProjectApi;

      const projectSceneParams = {
        modular_category_id: scene.modular_category_id,
        duration: scene.duration,
        is_hero_wo_bg: scene.is_hero_wo_bg,
        is_hero_w_bg: scene.is_hero_w_bg,
      };

      if (scene.is_hero_wo_bg === true) projectSceneParams.is_hero_wo_bg = true;
      if (scene.is_hero_w_bg === true) projectSceneParams.is_hero_w_bg = true;

      APIREQUEST.updateProjectScene(this.getProjectId, sceneId, projectSceneParams)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
#import-export-project-settings-wrapper {
  position: absolute;
  right: 2px;
  top: 58px;
  width: 400px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.1), 0px 26px 26px rgba(10, 31, 68, 0.12);
  z-index: 20;
  display: flex;
  flex-direction: column;

  > div {
    padding: 10px 15px;
  }

  .import__export-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-import-file {
    display: none;
  }

  .template-status-selection {
    text-align: left;
    margin-left: 20px;
  }

  .btn-download-project {
    &:hover,
    &.is-active,
    &:active {
      background: $light;
      color: $black;
    }
  }
}
</style>
