<template lang="pug">
.folder-item(
  @click="selectFolder"
  @mouseenter="showOptionsOnHover = true"
  @mouseleave="showOptionsOnHover = false"
  :class="[folderItemClass, uploadFolderOptionClass]"
)
  Drop(
    @drop="dropFilesToFolder"
    @dragover="isDropping = true"
    @dragleave="isDropping = false"
  )
    .icon-preview
      i.icon.icon-files-menu
      .folder-details
        .folder-input
          BaseInputField(
            ref="folderName"
            v-model="folderName"
            :is-read-only="!(isRenamingFolder && id === hoveredId)"
            @blur="$emit('folder-name-blurred')"
            @change="updateFolderName"
          )

        button.folder-dots(
          v-if="isFolderOptionShow && !isMyFilesCategory && !isTeam"
          :class="{ 'is-hovering-folder-options': (isHoveringFolderOptions && id === hoveredId) }"
          @click.stop.prevent="$emit('toggle-folder-options', id)"
        )
          i.icon.icon-options

        .my-files__folder-options(
          v-else-if="isFolderOptionShow && isMyFilesCategory && !isTeam"
          :class="{ 'is-hovering-folder-options': (isHoveringFolderOptions && id === hoveredId) }"
        )
          BaseButton.icon-edit-name.folder-option(
            :is-icon="true"
            :plain-hover="true"
            @click.stop.prevent="$emit('edit-folder', id)"
          )
          BaseButton.icon-trash-solid.folder-option(
            :is-icon="true"
            :plain-hover="true"
            @click.stop.prevent="$emit('delete-folder', id)"
          )

</template>

<script>
import { Drop } from 'vue-drag-drop';
import userFilesAndFolderMixins from '@/components/mixins/user-files-and-folder-mixins';
import DeleteItem from './DeleteItem.vue';

export default {
  name: 'FolderItem',
  inheritAttrs: false,
  mixins: [userFilesAndFolderMixins],
  components: {
    DeleteItem,
    Drop,
  },
  props: {
    id: { type: Number, default: 0 },
    folder_name: { type: String, default: 'Folder' },
    isFromModalOverlay: { type: Boolean, default: false },
    isHoveringFolderOptions: { type: Boolean, default: false },
    hoveredId: { type: Number, default: -1 },
    isRenamingFolder: { type: Boolean, default: false },
    isMyFilesCategory: { type: Boolean, default: false },
    isMovingFile: { type: Boolean, default: false },
    isTeam: { type: Boolean, default: false },
  },
  beforeMount() {
    this.folderName = this.folder_name;
  },
  methods: {
    selectFolder() {
      if (this.isTeam) {
        this.setActiveFolder(this.id);
        this.$emit('select-folder');
        return;
      }

      if (this.isRenamingFolder) return;

      if (!this.isMovingFile) {
        if (this.isFromModalOverlay) {
          if (this.id === this.activeFolder) return; // disable selection of current folder

          this.setActiveFolder(this.id);
          this.setMoveAllFilesFolder(this.id);
          this.setShowMoveMultipleFilesContainer(false);
        } else {
          this.setActiveFolder(this.id);
        }
      } else {
        this.setMoveAllFilesFolder(this.id);
        this.moveMultipleFiles();
        this.setActiveFolder(this.id);
      }

      this.$emit('select-folder');
    },
    focusFolderName() {
      this.$refs.folderName.$refs.baseInput.focus();
    },
  },
  computed: {
    folderItemClass() {
      if (this.isFromModalOverlay) {
        let folderClass = '';
        if (this.id === this.activeFolder) {
          folderClass = 'is-current-folder';
        } else if (this.id === this.moveAllFilesActiveFolder) {
          folderClass = 'selected-folder';
        }
        return folderClass;
      }
      return this.isDropping ? 'is-dropping' : '';
    },
    isFolderOptionShow() {
      return (
        !this.isFromModalOverlay
        && !this.isRenamingFolder
        && !this.isMovingFile
        && (this.showOptionsOnHover || (this.isHoveringFolderOptions && this.id === this.hoveredId))
      );
    },
    uploadFolderOptionClass() {
      const classList = [];
      if (this.isMyFilesCategory) {
        classList.push('uploads-tab');
      }

      if (this.isMyFilesCategory && this.showOptionsOnHover) {
        classList.push('uploads-folder-active');
      }

      return classList.join(' ');
    },
  },
};
</script>

<style lang="scss">
.folder-item {
  overflow: hidden;
  margin: 0;
  padding: 5px 10px;
  border-radius: $componentBorderRadius;

  &.is-dropping {
    .icon-preview {
      background: $blue;
    }
    .icon-preview,
    .input-field {
      color: $light !important;
    }
  }

  &.has-new-folder {
    .icon-preview,
    .input-field {
      animation: newFolder 1.5s;
    }

    @keyframes newFolder {
      0% {
        color: $lightGreen2;
      }
      10% {
        color: $lightGreen2;
      }
      100% {
        color: $lightGrey100;
      }
    }
  }

  &.uploads-tab {
    padding: 5px 0;

    .folder-details {
      padding-left: 15px;

      .input-field__wrapper .input-field {
        font-size: 14px;
      }
    }
  }

  &.uploads-folder-active {
    .icon-preview,
    .input-field {
      color: $green600 !important;
    }
  }

  .icon-preview {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 30px;
    font-size: 30px;
    color: $light;
  }

  .folder-dots {
    font-size: 0.625em;
    padding: 0;
    color: $lightWhite;
    transition: 0.3s;
    z-index: 10;
    cursor: pointer;
    pointer-events: auto;

    .icon-options {
      display: block;
    }
  }

  .folder-details {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;

    .folder-input {
      width: calc(100% - 18px);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .input-field__wrapper {
      width: 100%;
      display: flex;

      .input-field {
        background: transparent;
        width: 100%;
        padding: 0;
        font-size: 12px;
        font-weight: 400;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid transparent;
        text-overflow: ellipsis;

        &:active,
        &:hover {
          border-bottom: 1px solid $defaultBorder;
        }
      }
    }
  }

  .folder-label {
    font-size: 11px;
    color: $darkGrey900;
    display: block;
    margin-top: 5px;
  }
}

.myfolder__list {
  .icon-preview,
  .input-field {
    color: $light;
  }
}

.my-files__folder-options {
  display: flex;

  .folder-option {
    color: $light;
    font-size: 18px;

    &:hover,
    &:active,
    &:focus {
      color: $light;
    }
  }
}
</style>
