import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';

import websocketMixins from '@/components/mixins/websocket-mixins';

const storyboardMixin = {
  mixins: [websocketMixins],
  computed: {
    ...mapGetters([
      'getIsTemplate',
    ]),
    ...mapGetters('canvasElements', [
      'getProjectId',
    ]),
    ...mapState('canvasElements', [
      'scenesList',
      'activeSceneId',
      'storyBoardPreviews',
    ]),
  },
  methods: {
    ...mapActions('canvasElements', [
      'resetStoryBoardPreviews',
    ]),
    wsEventGenerateStoryboardThumbnail(id, isTemplate = false, sceneId = '') {
      // need to clear the preview first before generating new one
      if (
        typeof this.storyBoardPreviews[sceneId] !== 'undefined'
        && this.storyBoardPreviews[sceneId] !== ''
      ) { return; }

      const {
        VUE_APP_AWS_API_GATEWAY_CANVAS_URI, VUE_APP_CANVAS_URL,
      } = process.env;

      this.awsWebsocketSend({
        url: typeof VUE_APP_AWS_API_GATEWAY_CANVAS_URI !== 'undefined' ? VUE_APP_AWS_API_GATEWAY_CANVAS_URI : VUE_APP_CANVAS_URL.split('/')[2],
        id,
        isTemplate,
        sceneId,
        deviceScaleFactor: 0.3,
        // time,
      }, 'generateStoryboardThumbnail');
    },
    createStoryBoardPreviews(item) {
      const isReset = item && item.isReset ? item.isReset : false;

      if (isReset) {
        this.resetStoryBoardPreviews();
      }
      // get all previews by all scenes;
      const isTemplate = this.getIsTemplate;
      const id = this.getProjectId;
      const scenesList = this.scenesList.allIds;

      for (let i = 0; i < scenesList.length; i++) {
        if (this.activeSceneId !== scenesList[i]) {
          this.wsEventGenerateStoryboardThumbnail(id, isTemplate, scenesList[i]);
        }
      }
    },
  },
};

export default storyboardMixin;
