<template lang="pug">
button.upload-music-modal__item(
  type="button"
  @click="$emit('select', music)"
)
  i.icon.icon-music-menu
  span.name {{ title }}
  DeleteItem(
    :itemId="id"
  )
</template>

<script>
import DeleteItem from '@/components/ProjectCanvas/AssetsSidebar/children/DeleteItem.vue';

export default {
  components: {
    DeleteItem,
  },
  props: {
    id: { type: [String, Number] },
    title: { type: String, default: '' },
    music: { type: Object, default: () => {} },
  },
};
</script>

<style lang="scss">
.upload-music-modal__item {
  position: relative;

  .icon-music-menu {
    width: 100%;
    box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
    border-radius: $componentBorderRadius;
    display: flex;
    background: $light;
    color: $darkGrey800;
    border: 2px solid $light;
    font-size: 3rem;
    position: relative;

    &::after {
      content: '';
      padding-top: 100%;
      display: block;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover,
  &.is-active {
    .icon-music-menu {
      border-color: $blue;
    }

    .btn-delete {
      opacity: 1;
      visibility: visible;
    }
  }

  .name {
    display: block;
    font-size: 0.75rem;
    font-weight: 600;
    margin: 0.5rem 0;
    text-align: center;
    width: 100%;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
  }

  .btn-delete {
    position: absolute;
    color: $salmon;
    right: 0;
    bottom: 30px;
    font-size: 1.25rem;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  }
}
</style>
