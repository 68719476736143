<template lang="pug">
drr.text-temp.is-table(
  :class="[{ 'is-show': isTextEditing }, textClass]"
  :x="itemObject.x"
  :y="itemObject.y"
  :w="itemObject.width"
  :h="itemObject.height"
  :angle="itemObject.angle"
  :selectable="false"
  :isAutoHeight="isText"
)
  .text-content.is-table-cell(
    contenteditable=true
    :style="textStyleObject"
    @keyup="onKeyUp"
    ref="textContent"
    @paste="onPaste"
    v-html="text"
    :dir="isArabicHebrew ? 'rtl' : 'ltr'"
    @keydown.ctrl.66="checkIfHasBold"
    @keydown.ctrl.73="checkIfHasItalic"
    @keydown.ctrl.85="checkIfHasUnderline"
    @keydown.meta.66="checkIfHasBoldMac"
    @keydown.meta.73="checkIfHasItalicMac"
    @keydown.meta.85="checkIfHasUnderlineMac"
  )
</template>

<script>
/* eslint-disable */
import { mapGetters, mapMutations, mapActions } from 'vuex';
import drr from '@/plugins/@minogin/vue-drag-resize-rotate';
import textMixin from '@/components/mixins/text-mixins';
import {
  isMac,
  selectAllText,
  cleanUpContentHTMLEntity,
  isArabicText,
  isHebrewText,
  getHasFontWeight,
  getHasBoldFontWeight,
  getHasItalicFontWeight,
} from '@/assets/scripts/utilities';

export default {
  name: 'TextTemp',
  components: {
    drr,
  },
  data() {
    return {
      text: '',
      textBuffer: '',
      editingLayer: '',
    }
  },
  mixins: [textMixin],
  computed: {
    ...mapGetters([
      'getActiveOffeoFonts',
      'getCanvasZoom',
      'isTextEditing',
    ]),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getCanvasElementById',
      'getActiveElementToChange',
    ]),
    isText() {
      if (this.getActiveElements.length === 0) return false;
      return this.getActiveElements.length === 1 && this.getActiveElements[0].type === 'texts';
    },
    textClass() {
      let textClass = '';

      if (this.getActiveElements.length == 0 || !this.getActiveElements[0].data.content) return '';

      if (this.getActiveElements[0].data.textAlign === 'center') {
        textClass = 'is-center';
      } else if (this.getActiveElements[0].data.textAlign === 'right') {
        textClass = 'is-right';
      }

      return textClass;
    },
    itemObject() {
      let itemObject = {
        x: 0,
        y: 0,
        width: 200,
        height: 200,
        angle: 0,
      };

      const element = this.getActiveElements[0];
      if (element && element.type === 'texts') {
        if (!(element.size.width === 'auto' || element.size.height === 'auto')) {
          itemObject = {
            width: element.size.width * this.getCanvasZoom,
            height: element.size.height * this.getCanvasZoom,
            angle: element.rotate,
          };

          if (typeof element.position.x === 'undefined') {
            itemObject.x = element.position.x + (element.size.width / 2);
            itemObject.y = element.position.y + (element.size.height / 2);
          } else {
            itemObject.x = element.position.x * this.getCanvasZoom;
            itemObject.y = element.position.y * this.getCanvasZoom;
          }
        }
      }

      return itemObject;
    },
    textStyleObject() {
      const fontObject = {};

      if (this.getActiveElements.length && this.getActiveElements[0].type === 'texts') {

        const data = this.getActiveElements[0].data;

        if (data) {
          return this.allTextElementStyles(this.getActiveElements[0], true);
        }
      }

      return fontObject;
    },
    textElement() {
      if (this.getActiveElements.length == 0 || !this.getActiveElements[0].data.content) return '';
      return this.getActiveElements[0].data.content;
    },
    isArabicHebrew(){
      return isArabicText(this.text) || isHebrewText(this.text);
    },
    hasBold() {
      const fontObj = this.getActiveOffeoFonts.find(font => font.fontFamily === this.textStyleObject.fontFamily);
      const isBold = this.getActiveElements[0].data.fontWeight === 700;
      const isItalic = this.getActiveElements[0].data.textItalic;

      if (isBold && isItalic) return getHasItalicFontWeight(400, fontObj);
      else if (isBold) return getHasFontWeight(400, fontObj); // eslint-disable-line
      else if (isItalic) return getHasItalicFontWeight(700, fontObj); // eslint-disable-line
      return getHasBoldFontWeight(fontObj);
    },
    hasItalic() {
      const fontObj = this.getActiveOffeoFonts.find(font => font.fontFamily === this.textStyleObject.fontFamily);
      const fontObjWeight = this.getActiveElements[0].data.fontWeight;
      const isItalic = this.getActiveElements[0].data.textItalic;

      if (isItalic) return getHasFontWeight(fontObjWeight, fontObj);
      return getHasItalicFontWeight(fontObjWeight, fontObj);
    },
  },
  methods: {
    ...mapMutations([
      'setIsTextEditing',
    ]),
    ...mapMutations('canvasElements', [
      // 'updateCanvasElementContent',
      'updateCanvasElementName',
      'updateCanvasElementRect',
      'changeActiveElements',
      'updateActiveElementToChange',
    ]),
    ...mapActions('canvasElements', [
      'deleteElementFromCanvas',
      'updateCanvasElementContent',
    ]),
    onKeyUp(e) {
      if (this.getActiveElements.length) {
        let content = e.target.textContent;
        this.textBuffer = content;
        this.updateCanvasElementContent({
          id: this.editingLayer,
          content,
          rect: this.calculateRect(),
        });
      }
    },
    onPaste(e) {
      e.preventDefault();
      var text = '';
      if (e.clipboardData || e.originalEvent.clipboardData) {
        text = (e.originalEvent || e).clipboardData.getData('text/plain');
      } else if (window.clipboardData) {
        text = window.clipboardData.getData('Text');
      }
      text = cleanUpContentHTMLEntity(text);
      if (document.queryCommandSupported('insertText')) {
        document.execCommand('insertText', false, text);
      } else {
        document.execCommand('paste', false, text);
      }
    },
    // doUpdateSize() {
    //   const size = {
    //     id: window.localStorage.getItem('text_element_id'),
    //     size: {
    //       width: this.$refs.textContent.offsetWidth / this.getCanvasZoom,
    //       height: this.$refs.textContent.offsetHeight / this.getCanvasZoom
    //     }
    //   }

    //   this.updateCanvasElementRect(size);
    //   this.$root.$emit('canvasElementSizeUpdated');
    // },
    calculateRect() {
      return {
        h: this.$refs.textContent.clientHeight / this.getCanvasZoom,
      };
    },
    checkBlurTarget(e) {
      const paths = e.path || e.composedPath();

      const isControlBold = paths.findIndex((path) => {
        return path.className && typeof path.className === 'string' && path.className.indexOf('btn-font-bold') > -1;
      }) > -1;
      const isControlItalic = paths.findIndex((path) => {
        return path.className && typeof path.className === 'string' && path.className.indexOf('btn-font-italic') > -1;
      }) > -1;
      const isControlColor = paths.findIndex((path) => {
        return path.className && typeof path.className === 'string' && path.className.indexOf('btn-change-text-color') > -1;
      }) > -1;
      const isColorTab = paths.findIndex((path) => {
        return path.className && typeof path.className === 'string' && path.className.indexOf('color-tab') > -1;
      }) > -1;
      const isTextTemp = paths.findIndex((path) => {
        return path.className && typeof path.className === 'string' && path.className.indexOf('text-temp') > -1;
      }) > -1;

      if (!isControlBold
        && !isControlItalic
        && !isControlColor
        && !isColorTab
        && !isTextTemp
        && this.isTextEditing) {
          this.blurEvent();
        }
    },
    blurEvent() {
      this.setIsTextEditing(false);
    },
    // checkFontStyle(e) {
      // const isTextTemp = e?.path?.findIndex((path) => {
      //   return path.className && typeof path.className === 'string' && path.className.indexOf('text-temp') > -1;
      // }) > -1;

      // if (isTextTemp) {
      //   const textBold = document.queryCommandState('bold');
      //   const textItalic = document.queryCommandState('italic');
      //   this.$root.$emit('canvas-element-font-style-updated', { textBold, textItalic });
      //   // console.log('mouseup query', document.queryCommandState('bold'), document.queryCommandState('italic'))
      // }
    // },
    checkIfHasBold(e) {
      console.log('checkIfHasBold', this.hasBold)
      if (!this.hasBold) e.preventDefault();
    },
    checkIfHasBoldMac() {
      if (!isMac()) return;
      this.checkIfHasBold();
    },
    checkIfHasItalic(e) {
      console.log('checkIfHasItalic', this.hasItalic)
      if (!this.hasItalic) e.preventDefault();
    },
    checkIfHasItalicMac(e) {
      if (!isMac()) return;
      this.checkIfHasItalic();
    },
    checkIfHasUnderline(e) {
      e.preventDefault();
    },
    checkIfHasUnderlineMac(e) {
      if (!isMac()) return;
      this.checkIfHasUnderline();
    },
  },
  mounted() {
    document.body.addEventListener('mousedown', this.checkBlurTarget);
    // document.body.addEventListener('mouseup', this.checkFontStyle);
  },
  beforeDestroy() {
    console.log('text temp destroyed')
    document.body.removeEventListener('mousedown', this.checkBlurTarget);
    // document.body.removeEventListener('mouseup', this.checkFontStyle);
  },
  watch: {
    isTextEditing(item) {
      console.log('isTextEditing', item)
      if (item) {
        setTimeout(() => {
          // console.log('isTextEditing', this.textElement, this.getActiveElements);
          // console.log('isTextEditing', this.textElement);
          this.editingLayer = this.getActiveElements[0].data.id;
          this.text = this.textElement;
          this.textBuffer = this.textElement;
          this.$refs.textContent.focus();
          selectAllText(this.$refs.textContent);
        });
      } else {
        const layer = this.getCanvasElementById(this.editingLayer);
        let content = this.textBuffer;
        const textTarget = this.$refs.textContent;
        // console.table({
        //   content: layer.data.content,
        //   textBuffer: this.textBuffer,
        //   editingLayer: this.editingLayer,
        //   innerHTML: textTarget.innerHTML,
        // })

        if (layer && textTarget) {
          if (this.textBuffer === '') {
            this.deleteElementFromCanvas(this.editingLayer);
            this.editingLayer = '';

            if (this.getActiveElementToChange != '') {
              this.changeActiveElements(this.getActiveElementToChange);
              this.updateActiveElementToChange('');
            }
          } else if (layer.data.hasNotChanged) {
            this.updateCanvasElementName({
              id: this.editingLayer,
              name: textTarget.textContent,
            });
          }

          if (this.textBuffer && textTarget.innerHTML && this.textBuffer !== textTarget.innerHTML) {
            content = cleanUpContentHTMLEntity(textTarget.innerHTML);
            this.updateCanvasElementContent({
              id: this.editingLayer,
              content,
            });
            this.textBuffer = cleanUpContentHTMLEntity(textTarget.innerHTML);
          }
        }
        this.text = '';
      }
    },
  },
};
</script>

<style lang="scss">
.drr.text-temp {
  position: absolute;
  pointer-events: none !important;
  opacity: 0;
  visibility: hidden;
  cursor: text;

  // combining -moz-selection and selection will not work
  &::-moz-selection {
    background: $defaultHighlightBg;
  }

  &::selection {
    background: $defaultHighlightBg;
  }

  *::-moz-selection {
    color: inherit;
    background: $defaultHighlightBg;
  }

  *::selection {
    color: inherit;
    background: $defaultHighlightBg;
  }

  &:focus {
    outline: 0;
  }

  &.is-show {
    opacity: 1;
    visibility: visible;
    border: 0;
    outline: 1px dashed $lightBlue !important;
    // height: auto !important;

    .text-content {
      pointer-events: auto;
      white-space: pre-wrap;
    }
  }

  &.is-show,
  &.is-show:hover {
    &::before {
      display: none;
    }

    .text-content::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      outline: 2px dashed $lightGrey;
      pointer-events: none;
    }
  }

  .text-content {
    pointer-events: auto;
    width: 100%;
    height: 100%;
    display: inline-block;

    &:focus {
      outline: 0;
    }
  }

  .is-table {
    display: table;
  }

  .is-table-cell {
    display: table-cell !important;
  }

  // &.is-center {
  //   .text-content {
  //     left: 50%;
  //     transform: translateX(-50%);
  //   }
  // }

  // &.is-right {
  //   .text-content {
  //     left: 100%;
  //     transform: translateX(-100%);
  //   }
  // }
}
</style>
