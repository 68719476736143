<template lang="pug">
.adjust-tab(:class="{'is-mobile': isMobile}")
  h3.adjust__title(v-if="isMobile")
    span Adjustments
    BaseButton.adjust__reset(
      is-dark=true
      @click="resetFilter"
    ) Reset
  .canvas-control__row.adjust__blend
    span.title Blend Mode
    BaseDropdown.blend-dropdown(
      :options="blendOptions"
      :value="blend"
      @change="changeElementBlend"
    )

  .adjust__content
    BaseSlider.adjust-slider--color-wave(
      is-dark=true
      isColorWave=true
      :min="-100"
      :max="100"
      title="Tint"
      :value="elementTint"
      @blur="onBlurAdjustInput($event, 'tint')"
      @update="updateElementTint"
      v-if="isNotAlphaVideo"
    )
    BaseSlider.adjust-slider--color-wave(
      is-dark=true
      isColorWave=true
      :min="-180"
      :max="180"
      title="Hue"
      :value="elementHue"
      @blur="onBlurAdjustInput($event, 'hue')"
      @update="updateElementHue"
    )
    BaseSlider(
      is-dark=true
      :min="-100"
      :max="100"
      title="Contrast"
      :value="elementContrast"
      @blur="onBlurAdjustInput($event, 'contrast')"
      @update="updateElementContrast"
    )
    BaseSlider(
      is-dark=true
      :min="-100"
      :max="100"
      title="Saturation"
      :value="elementSaturation"
      @blur="onBlurAdjustInput($event, 'saturate')"
      @update="updateElementSaturation"
    )
    BaseSlider(
      is-dark=true
      :min="-100"
      :max="100"
      title="Brightness"
      :value="elementBrightness"
      @blur="onBlurAdjustInput($event, 'brightness')"
      @update="updateElementBrightness"
    )
    BaseSlider(
      is-dark=true
      :min="0"
      :max="100"
      title="Sepia"
      :value="elementSepia"
      @blur="onBlurAdjustInput($event, 'sepia')"
      @update="updateElementSepia"
    )
    BaseButton.btn-reset-filter(
      :is-canvas="true"
      is-dark=true
      is-full-width=true
      @click="resetFilter"
    ) Reset
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isEmpty } from '@/assets/scripts/utilities';

// GUIDE FOR FILTERS
//                slider     filter (db)       css      default slider     default css
// opacity        0 - 100       0 - 1         0 - 1          100                1
// contrast    -100 - 100       0 - 2         0 - 2           0                 1
// saturate    -100 - 100       0 - 2         0 - 2           0                 1
// brightness  -100 - 100       0 - 2         0 - 2           0                 1
// sepia          0 - 100       0 - 1         0 - 1           0                 0
// blur           0 - 100       0 - 100       0 - 100         0                 0
// hue         -180 - 180       0 - 360       0 - 360         0                 0

export default {
  name: 'AdjustTab',
  props: {
    isMobile: { type: Boolean, default: false },
  },
  data() {
    return {
      blend: 'normal',
      tint: 0,
      hue: 0,
      contrast: 1,
      saturate: 1,
      brightness: 1,
      sepia: 0,
      blendOptions: [
        {
          name: 'Normal',
          value: 'normal',
        },
        {
          name: 'Multiply',
          value: 'multiply',
        },
        {
          name: 'Screen',
          value: 'screen',
        },
        {
          name: 'Overlay',
          value: 'overlay',
        },
        {
          name: 'Darken',
          value: 'darken',
        },
        {
          name: 'Lighten',
          value: 'lighten',
        },
        {
          name: 'Color Dodge',
          value: 'color-dodge',
        },
        {
          name: 'Color Burn',
          value: 'color-burn',
        },
        {
          name: 'Difference',
          value: 'difference',
        },
        {
          name: 'Exclusion',
          value: 'exclusion',
        },
        {
          name: 'Hue',
          value: 'hue',
        },
        {
          name: 'Saturation',
          value: 'saturation',
        },
        {
          name: 'Color',
          value: 'color',
        },
        {
          name: 'Luminosity',
          value: 'luminosity',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('canvasElements', ['getActiveElements']),
    elementTint() {
      return this.tint;
    },
    elementHue() {
      return this.hue;
    },
    elementContrast() {
      return Math.round((this.contrast - 1) * 100);
    },
    elementSaturation() {
      return Math.round((this.saturate - 1) * 100);
    },
    elementBrightness() {
      return Math.round((this.brightness - 1) * 100);
    },
    elementSepia() {
      return this.sepia * 100;
    },
    isNotAlphaVideo() {
      if (this.getActiveElements.length === 0) return false;

      if (
        this.getActiveElements.length === 1
        && (this.getActiveElements[0].type !== 'videos'
          || (this.getActiveElements[0].type === 'videos'
            && this.fileExt(this.getActiveElements[0].data.url) !== 'webm'))
      ) {
        return true;
      }

      return false;
    },
    isMedia() {
      if (this.getActiveElements.length === 0) return false;
      return (
        this.getActiveElements[0].isImage
        || this.getActiveElements[0].type === 'videos'
        || (this.getActiveElements[0].data.url
          && this.fileExt(this.getActiveElements[0].data.url) === 'webm')
      );
    },
  },
  methods: {
    ...mapActions('canvasElements', ['updateCanvasElementFilter']),
    changeElementBlend(value) {
      this.blend = value;
      const filter = {
        blend: value,
      };
      this.updateCanvasElementFilter(filter);
    },
    resetFilter() {
      this.updateCanvasElementFilter({
        blend: 'normal',
        tint: 0,
        blur: 0,
        brightness: 1,
        contrast: 1,
        hue: 0,
        name: 'none',
        saturate: 1,
        sepia: 0,
      });

      this.blend = 'normal';
      this.tint = 0;
      this.brightness = 1;
      this.contrast = 1;
      this.hue = 0;
      this.saturate = 1;
      this.sepia = 0;
    },
    fileExt(url) {
      return url.split('.').pop();
    },
    updateFilter() {
      const activeElement = this.getActiveElements[0];
      // for some filter, it may have 0 value which may not be equal to the slider
      // refer to variables.js
      this.blend = !isEmpty(activeElement.blend) ? activeElement.blend : 'normal';
      this.tint = !isEmpty(activeElement.tint) ? activeElement.tint : 0;
      if (!isEmpty(activeElement.filters.hue)) {
        this.hue = activeElement.filters.hue === 0 ? 0 : activeElement.filters.hue - 360;
      }
      this.contrast = !isEmpty(activeElement.filters.contrast) ? activeElement.filters.contrast : 1;
      this.saturate = !isEmpty(activeElement.filters.saturate) ? activeElement.filters.saturate : 1;
      this.brightness = !isEmpty(activeElement.filters.brightness)
        ? activeElement.filters.brightness
        : 1;
      this.sepia = !isEmpty(activeElement.filters.sepia) ? activeElement.filters.sepia : 0;
    },
    updateElementTint(value) {
      if (!Number.isNaN(value)) {
        this.tint = parseInt(value, 10);
        const filter = {
          tint: this.tint,
          name: 'none',
        };
        this.updateCanvasElementFilter(filter);
      }
    },
    updateElementHue(value) {
      if (!Number.isNaN(value)) {
        // const hue = parseInt(value, 10) < 0 ? parseInt(value, 10) + 360 : parseInt(value, 10);
        const hue = value === 0 ? 0 : parseInt(value, 10) + 360;

        this.hue = value;
        const filter = {
          hue,
          name: 'none',
        };
        this.updateCanvasElementFilter(filter);
      }
    },
    updateElementContrast(value) {
      if (!Number.isNaN(value)) {
        const contrast = parseInt(value, 10) / 100 + 1;
        this.contrast = parseFloat(contrast.toFixed(2));
        const filter = {
          contrast: this.contrast,
          name: 'none',
        };
        this.updateCanvasElementFilter(filter);
      }
    },
    updateElementSaturation(value) {
      if (!Number.isNaN(value)) {
        const saturate = parseInt(value, 10) / 100 + 1;
        this.saturate = parseFloat(saturate.toFixed(2));
        const filter = {
          saturate: this.saturate,
          name: 'none',
        };
        this.updateCanvasElementFilter(filter);
      }
    },
    updateElementBrightness(value) {
      if (!Number.isNaN(value)) {
        const brightness = parseInt(value, 10) / 100 + 1;
        this.brightness = parseFloat(brightness.toFixed(2));
        const filter = {
          brightness: this.brightness,
          name: 'none',
        };
        // console.log(filter);
        this.updateCanvasElementFilter(filter);
      }
    },
    updateElementSepia(value) {
      if (!Number.isNaN(value)) {
        const sepia = parseInt(value, 10) / 100;
        this.sepia = parseFloat(sepia.toFixed(2));
        const filter = {
          sepia: this.sepia,
          name: 'none',
        };
        this.updateCanvasElementFilter(filter);
      }
    },
    onBlurAdjustInput(event, type) {
      const activeElement = this.getActiveElements[0];
      const { value } = event.target;

      let oldValue = '';
      if (type === 'tint') {
        oldValue = activeElement[type];
      } else if (type === 'hue') {
        oldValue = activeElement.filters.hue === 0 ? 0 : activeElement.filters.hue - 360;
      } else if (type === 'contrast') {
        oldValue = Math.round((activeElement.filters[type] - 1) * 100);
      } else if (type === 'saturate') {
        oldValue = Math.round((activeElement.filters[type] - 1) * 100);
      } else if (type === 'brightness') {
        oldValue = Math.round((activeElement.filters[type] - 1) * 100);
      } else if (type === 'sepia') {
        oldValue = activeElement.filters[type] * 100;
      }

      if (!value) {
        // eslint-disable-next-line no-param-reassign
        event.target.value = oldValue;
      }
    },
  },
  mounted() {
    if (this.isMedia) {
      this.updateFilter();
    }
  },
};
</script>

<style lang="scss">
.adjust-tab {
  margin-top: 25px;

  &.is-mobile {
    margin-top: 0 !important;

    .range-wrapper {
      max-width: none !important;
    }

    .slider {
      margin: 9px 0 !important;
    }
  }

  .title {
    font-size: 0.875rem !important;
    font-weight: 500;
    display: block;

    @include smallest {
      font-size: 0.8125rem !important;
    }

    & + .slider-container {
      padding-top: 0;
    }
  }

  .btn-reset-filter {
    margin-top: 20px;
    background: $darkGrey5;
    font-size: 0.875rem;

    @include smallest {
      font-size: 0.8125rem;
    }

    &:hover {
      background: $darkGrey5;
      color: $light;
    }
  }

  .adjust__content {
    .slider {
      margin: 20px 0;

      &:not(.adjust-slider--color-wave) {
        .range-before {
          background: $lightGrey400 !important;
        }
      }
    }

    .slider__container {
      display: flex;
      justify-content: space-between;
    }

    .slider__title {
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 5px;

      @include smallest {
        font-size: 0.8125rem;
      }
    }

    .range-wrapper {
      max-width: calc(100% - 70px);

      @include smallest {
        max-width: calc(100% - 60px);
      }
    }

    .slider__value {
      width: 50px;
      background: $darkGrey600 !important;
      border: 0 !important;
      outline: 0;

      @include smallest {
        width: 40px;
      }
    }

    input[type='range']::-webkit-slider-thumb {
      background: $light !important;
      width: 14px !important;
      height: 14px !important;
      margin-top: -1px;
    }
  }

  .blend-dropdown {
    width: 110px;
    height: 36px;
    z-index: 10;
    font-size: 0.8125rem;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background-color: rgba($black, 0) !important;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--lightgrey600-darkgrey5) !important;
    }

    .dropdown__selected {
      border: 0;
      color: $light;
      background-color: $darkGrey7;
      padding: 9px 10px;
    }

    .dropdown__list {
      background: $darkGrey7;
      max-height: 200px;
      overflow: auto;
    }

    .icon {
      right: 9px;
      top: 9px;
      font-size: 1rem;
      background-color: $darkGrey7;
    }

    .dropdown__item {
      border: 0;
      font-weight: 400;
      color: $lightGrey400;

      &:hover {
        background: transparent;
        color: $light;
      }

      &.is-focused,
      &.is-selected {
        background: $green700;
        color: $light;
        font-weight: 500;
      }
    }
  }
}
</style>
