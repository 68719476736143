<template lang="pug">
.element-reflection.effect
  BaseSwitch.element-reflection__switch.effect__switch(
    name="element-reflection"
    :isChecked="isEnabled('reflection')"
    :isBeta="true"
    @change="toggleEnable('reflection')"
    label="Reflection"
  )
  VueSlideUpDown.element-reflection__content.effect__content(
    :class="{'effect-content--enabled': isEnabled('reflection')}"
    :active="true"
  )
    BaseSlider.effects__base-slider.slider--extra-margin-top(
      is-dark=true
      :min="-100"
      :max="100"
      title="Distance"
      :value="distance"
      @blur="onBlurInput($event, 'distance')"
      @update="updateDistance"
    )
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="100"
      title="Fade off"
      :value="size"
      @blur="onBlurInput($event, 'size')"
      @update="updateSize"
    )
    //- Transparency 100 = Opacity 0
    //- they are inverse
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="100"
      title="Transparent"
      :value="opacity"
      @blur="onBlurInput($event, 'opacity', '%')"
      @update="updateOpacity"
      suffix="%"
    )
    //-
      BaseSlider(
        is-dark=true
        :min="0"
        :max="100"
        title="Blur Reflection"
        :value="blur"
        @blur="onBlurInput($event, 'blur')"
        @update="updateBlur"
      )

    BaseButton.effects__reset-button(
      :is-canvas="true"
      is-dark=true
      is-full-width=true
      @click="resetReflection"
    ) Reset

    .disclaimer(
      v-if="shouldDisplayDisclaimer"
    )
      strong Note:
      |  Any glitches seen while editing will not appear in the final export.
</template>

<script>
import VueSlideUpDown from 'vue-slide-up-down';
import { elementFormat } from '@/assets/scripts/variables';
import effectsMixin from '@/components/mixins/effects-mixins';

export default {
  name: 'ReflectionControls',
  components: {
    VueSlideUpDown,
  },
  mixins: [effectsMixin],
  computed: {
    activeElement() {
      return this.getActiveElements[0];
    },
    isWebm() {
      return this.activeElement.data.url && this.activeElement.data.url.split('.').pop() === 'webm';
    },
    isVideo() {
      return this.activeElement.type === 'videos' || this.isWebm;
    },
    opacity() {
      const opacity = this.activeElement.data.reflection.opacity * 100;
      return Math.floor(100 - opacity);
    },
    size() {
      return this.activeElement.data.reflection.size;
    },
    distance() {
      return this.activeElement.data.reflection.distance;
    },
    shouldDisplayDisclaimer() {
      return this.isVideo && this.isEnabled('reflection');
    },
  },
  mounted() {
    if (!this.isEnabled('reflection')) {
      this.toggleEnable('reflection');
    }
  },
  methods: {
    updateOpacity(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        const opacity = (100 - parseInt(value, 10)) / 100;
        this.updateCanvasElementReflection({ opacity });
      }
    },
    updateSize(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementReflection({ size: value });
      }
    },
    // updateBlur(value) {
    //   if (!Number.isNaN(value)) {
    //     this.catchHistory('element');
    //     this.updateCanvasElementReflection({ blur: value });
    //   }
    // },
    updateDistance(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementReflection({ distance: value });
      }
    },
    onBlurInput(event, type, suffix) {
      this.onBlurEffectInput(event, {
        effect: 'reflection',
        selectedInput: type,
        suffix,
      });
    },
    resetReflection() {
      this.catchHistory('element');

      this.updateCanvasElementReflection({
        opacity: elementFormat.data.reflection.opacity,
        size: elementFormat.data.reflection.size,
        distance: elementFormat.data.reflection.distance,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.disclaimer {
  background: $darkGrey600;
  color: $light;
  font-size: 0.75rem;
  margin-top: 12px;
  font-weight: 400;
  padding: 15px;
  border-radius: 5px;
  line-height: 1.5;
}
</style>
