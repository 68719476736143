<template lang="pug">
  .file-upload-container.user-file-upload(:class="{'container--only-margin-top': useExtraMarginTop}")
    .upload-buttons
      label.btn-local.btn(
        :class="{'is-dark': isDarkMode}"
      )
        input(
          autocomplete="off"
          type="file"
          id="manual-file-upload"
          ref="fileUpload"
          :accept="fileAccept"
          multiple
          @change="$emit('do-select-file', $event.target)"
        )
        i.icon.icon-plus
        span.label Upload
        .tooltip-wrapper
          template(v-if="isAssetsContainerUpload")
            i.icon.icon-info-line
            .tooltip
              div
                | The file should be in PNG, JPG or JPEG with maximum size of 50MB.
                | Video files should be MP4, MOV or WEBM with a maximum size of 1 GB.
          template(v-else)
            i.icon.icon-info
            .tooltip
              div Supports only
              .file-format {{ allowedFormat }}
              //- TODO: update with user actual limit
              div
                strong You have {{ storageSpaceLeft }} left
      //- Temporarily hide google drive button
      //- div.btn-google-drive.btn.btn--primary--hollow
      //-   GoogleDriveSelector
      div.btn-dropbox
        DropboxSelector(
          :onlyImage="true"
        )
</template>

<script>
import uploadingMixin from '@/components/mixins/uploading-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

// import GoogleDriveSelector from '@/components/ProjectCanvas/AssetsSidebar/GoogleDriveSelector.vue';
import DropboxSelector from '@/components/ProjectCanvas/AssetsSidebar/DropboxSelector.vue';

export default {
  name: 'UserFileUpload',
  mixins: [uploadingMixin, darkModeMixin],
  components: {
    // GoogleDriveSelector,
    DropboxSelector,
  },
  props: {
    isAssetsContainerUpload: { type: Boolean, default: false },
    useExtraMarginTop: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
  .file-upload-container.user-file-upload {
    margin-bottom: 10px;

      &.container--only-margin-top {
        margin-bottom: 0;
        margin-top: 10px;
      }

    .upload-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 10px);
      z-index: 2;

      .btn {
        margin: 0 7px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .btn-local {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 100%;
        padding: 12px 10px;
        background: var(--blue700-darkgrey900);
        transition: color 0.2s ease-in-out, background 0.2s ease-in-out;

        &.btn {
          overflow: visible;
        }

        &:not(.is-dark) {
          &:hover,
          &:active {
            background: $light;
            color: $blue700;

            .tooltip-wrapper .icon {
              transition: color 0.2s ease-in-out;
              color: $blue700;
            }
          }
        }

        &.is-dark {
          &:hover,
          &:active {
            background: $darkGrey5;
            color: $light;

            .icon-plus {
              transition: color 0.2s ease-in-out;
              color: $green600;
            }
          }
        }

        .icon {
          font-size: 0.875rem;

          &.icon-info-line {
            font-size: 1rem;
          }
        }

        input[type='file'] {
          display: none;
        }

        .label {
          display: block;
          margin: 0 8px;
          font-size: 0.875rem;
        }

        .tooltip-wrapper {
          position: relative;
          z-index: 2;

          &:hover {
            .tooltip {
              opacity: 1;
              display: block;
            }
          }

          .icon {
            color: $lightWhite;
          }

          .tooltip {
            display: none;
            opacity: 0;
            transition: 0.3s;
            position: absolute;
            background-color: $green700;
            color: $light;
            top: -12px;
            left: 115px;
            padding: 15px;
            width: 270px;
            margin-left: -79px;
            text-align: left;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            border-radius: $componentBorderRadius;

            &::after {
              content: ' ';
              position: absolute;
              left: -14px;
              top: 12px;
              border-top: 8px solid transparent;
              border-right: 18px solid $green700;
              border-left: none;
              border-bottom: 8px solid transparent;
            }
          }
        }
      }

      .btn-google-drive,
      .btn-dropbox {
        padding: 0;
      }
    }
  }
</style>
