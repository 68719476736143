<template lang="pug">
BaseDialog.template-warning(
  isShow=true
  isDanger=true
  text="You have selected a new template.<br>Do you want to add new scene or replace your scene?"
  @confirm="addScene"
  @closeOverlay="cancelChange"
  button1Text="Add scene"
  button2Text="Replace scene"
  button2NoClose=true
  @button-two-click="replaceScene"
)
</template>

<script>
export default {
  name: 'TemplateWarning',
  methods: {
    addScene() {
      this.$emit('add');
    },
    replaceScene() {
      this.$emit('replace');
    },
    cancelChange() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
</style>
