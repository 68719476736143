<template lang='pug'>
  .team-uploads__folder-dropdown
    .myfolder-collapsible(:class="{ 'is-collapsed': isFolderCollapsed }")
      .myfolder-collapsible__left
        BaseButton.btn-uploads-dropdown(
          :class="{ 'is-disabled': !isInTeamWorkspace }"
          :is-text="true"
          :is-canvas="true"
          :icon-after="isInTeamWorkspace ? 'icon-dropdown' : ''"
        ) Folder List
      .myfolder-collapsible__right
        button.icon(
          :class="showFolderClass"
          @click="isFolderCollapsed = !isFolderCollapsed"
        )

    VueSlideUpDown.myfolder__list.list--team(
      :class="{ 'single-folder': insideFolderList.length === 1 }"
      :active="!isFolderCollapsed"
    )
      li.grid-item(
        v-for="item in insideFolderList"
        :key="`folder-${item.id}`"
        :ref="`folder-${item.id}`"
      )
        FolderItem(
          v-bind="item"
          :is-my-files-category="isMyFilesCategory"
          :is-team="isTeam"
          @select-folder="$emit('select-folder')"
        )
</template>

<script>
import { mapGetters } from 'vuex';
import VueSlideUpDown from 'vue-slide-up-down';
import FolderItem from './FolderItem.vue';

export default {
  name: 'TeamUploadsFolderDropdown',
  components: {
    VueSlideUpDown,
    FolderItem,
  },
  props: {
    insideFolderList: { type: Array, default: () => [] },
    isMyFilesCategory: { type: Boolean, default: false },
    isTeam: { type: Boolean, default: false },
  },
  data() {
    return {
      isFolderCollapsed: false,
    };
  },
  computed: {
    ...mapGetters('team', ['isInTeamWorkspace']),
    showFolderClass() {
      return this.isFolderCollapsed ? 'icon-down-arrow' : 'icon-up-arrow';
    },
  },
};
</script>

<style lang="scss" scoped>
.myfolder-collapsible {
  position: relative;
  color: $lightWhite;
  background-color: $darkGrey100;
  border-top-left-radius: $componentBorderRadius;
  border-top-right-radius: $componentBorderRadius;
  margin-top: 10px;
  margin-right: 3px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.is-collapsed {
    border-bottom-left-radius: $componentBorderRadius;
    border-bottom-right-radius: $componentBorderRadius;
  }

  .icon {
    cursor: pointer;
    color: $lightWhite;
    font-size: 20px;
    font-weight: 500;
  }
}

.myfolder-collapsible__left {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875em;
  cursor: default;
}
</style>
