<template lang="pug">
.font-tab(
  v-click-outside="closeFontTab"
  :class="{ 'is-open': showFontTab }"
)
  FontFamilyDropdown(
    :only-dark="true"
  )
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import FontFamilyDropdown from '@/components/ProjectCanvas/ControlBar/children/FontFamilyDropdown.vue';
import { checkIsCollapseSidebarButton } from '@/assets/scripts/utilities';

export default {
  name: 'FontTab',
  components: {
    FontFamilyDropdown,
  },
  props: {
    string: { type: String, default: '' },
    number: { type: Number, default: 0 },
    array: { type: Array, default: () => [] },
    object: { type: Object, default: () => ({}) },
    boolean: { type: Boolean, default: false },
  },
  computed: {
    ...mapState(['showFontTab', 'showTimeline']),
  },
  methods: {
    ...mapMutations(['setShowSidebar', 'setShowFontTab']),
    closeFontTab(event) {
      const paths = event.path || event.composedPath();
      const isCollapseSidebarButton = checkIsCollapseSidebarButton(paths);

      if (this.showTimeline && isCollapseSidebarButton) this.setShowSidebar(false);
      this.setShowFontTab(false);
    },
  },
};
</script>

<style lang="scss">
.font-tab {
  background: $darkGrey700;
  height: 100%;
  left: 72px;
  padding: 10px 30px 0;
  position: absolute;
  top: 0;
  width: 360px;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transform: translateX(10px);
  transition: transform 0.25s 0.25s ease-in-out,
              opacity 0.25s 0.25s ease-in-out,
              visibility 0.25s 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 3;

  @include smallest {
    width: 270px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &.is-open {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}
</style>
