<template lang="pug">
  div
    button.assets-navigation__button(
      ref="button"
      type="button"
      :class="{ 'is-hidden': isHidden, 'is-active': isActive, 'is-dark': isDarkMode }"
      @click="activateTab(group)"
    )
      i(:class="[ iconClass ? 'icon ' + iconClass : 'icon' ]")
      span.title(
        v-if="name"
        v-html="name"
      )
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import { groups, subgroups } from '@/assets/scripts/enums';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';
import elementMixins from '@/components/mixins/element-mixins';

export default {
  name: 'AssetsButton',
  props: {
    group: { type: String, default: '' },
    iconClass: { type: String, default: '' },
    name: { type: String, default: '' },
  },
  mixins: [darkModeMixin, elementMixins],
  data() {
    return {
      activeSwap: '',
    };
  },
  computed: {
    ...mapState([
      'showMultipleSwap',
      'showSwap',
      'showSidebar',
      'showFontTab',
      'showColorTab',
      'showAnimationTab',
      'showMaskTab',
      'showEditTab',
      'showDesignContainer',
    ]),
    ...mapGetters('canvasElements', ['getSwappedElement', 'getActiveElements']),
    ...mapGetters('assetsSidebar', ['getActiveGroup', 'getActiveSubGroup']),
    isActive() {
      if (this.group === 'designs' && this.showDesignContainer) {
        return true;
      }

      if (this.getActiveGroup === this.group && this.group !== 'designs') {
        return true;
      }
      return false;
    },
    isHidden() {
      if (this.activeSwap) {
        if (
          this.group === groups.DESIGNS
          || this.group === groups.STYLE
          || this.group === groups.BRANDEDDESIGNS
          || this.group === groups.TEXTS
          || this.group === groups.MUSIC
          || this.group === groups.FAVOURITES
        ) return true;
        if (
          (this.activeSwap === 'images'
            || this.activeSwap === 'masks'
            || this.activeSwap === 'logos')
          && this.group === groups.ELEMENTS
        ) return true;

        if (this.activeSwap === 'patterns' && this.group !== 'backgrounds') return true;

        if (this.activeSwap === 'shapes' && this.group !== 'elements') return true;

        if (this.activeSwap === 'videos') {
          const [category] = this.getSwappedElement.category;
          if ((category === 'overlays' || category === 'effects') && this.group !== 'elements') return true;
        }
        // if ((this.activeSwap === 'patterns') && (this.group !== groups.BACKGROUNDS && this.group !== groups.FAVOURITES)) return true;
      }

      return false;
    },
  },
  methods: {
    ...mapMutations([
      'setShowSidebar',
      'setIsAddNewScene',
      'setShowColorTab',
      'setIsTextBackground',
      'setIsTextShadow',
      'closeExtraLeftSidebar',
      'setShowDesignContainer',
    ]),
    ...mapMutations('assetsSidebar', [
      'setActiveGroup',
      'clearActiveKeyword',
      'setActiveCategory',
      'clearActiveCategory',
      'setActiveSubGroup',
      'setShowDesignPreloader',
    ]),
    ...mapMutations('canvasElements', ['updateActiveColorId', 'emptyActiveElements']),
    ...mapActions(['toggleColorTab']),
    activateTab(group) {
      this.clearActiveKeyword();
      // clear out category on switching tabs

      this.clearActiveCategory();

      if (group === 'designs') {
        // if design, set isAddNewScene to false
        // so that it will show the TemplateWarning when user choose template
        this.setIsAddNewScene(false);
        // Keep opening the tab, so will have 2 active group at sidebar\
        this.setShowDesignPreloader(true);
        this.setShowDesignContainer(true);
      }

      if (this.showSwap) {
        if (this.getActiveGroup !== group) {
          this.setActiveGroup(group);

          const isImage = this.getActiveElements.length > 0 ? this.getActiveElements[0].isImage : '';

          this.$nextTick(() => {
            // if (group === 'elements') {
            //   this.setActiveCategory({id: 'all', slug: 'all'})
            //   this.setActiveSubGroup('graphics');
            // }

            // if (this.activeSwap === 'graphics' && isImage) {
            //   this.setActiveSubGroup('images')
            // }

            if (this.activeSwap === 'graphics' && isImage) {
              if (group === 'elements') {
                this.clearActiveCategory();
                this.setActiveSubGroup('graphics');
              } else if (group === 'media') {
                this.setActiveSubGroup('photo');
              } else if (group === 'backgrounds') {
                this.setActiveSubGroup(this.isImageBackground ? 'images' : 'videos');
              } else if (group === 'my-files') {
                this.setActiveSubGroup('images');
              }
            } else if (this.activeSwap === 'videos') {
              if (group === 'elements') {
                this.clearActiveCategory();
                this.setActiveSubGroup('overlays');
              }
            } else if (group === 'elements') {
              this.clearActiveCategory();
              this.setActiveSubGroup('graphics');
            }
          });
        }
      } else if (this.getActiveGroup !== group) {
        if (!this.showSidebar) this.setShowSidebar(true);
        if (group !== 'designs') this.setActiveGroup(group);
        this.$root.$emit('doRetrieveCategoriesTags');

        // Check if active background is image or animated and set subgroup accordingly
        if (this.getActiveGroup === 'backgrounds') {
          // From elementMixins
          if (
            this.lastElementIsBackground
            && (this.isImageBackground || this.isAnimatedBackground)
          ) {
            this.setActiveSubGroup(this.isImageBackground ? 'images' : 'videos');
          } else if (!this.showColorTab) {
            // If is color, just open color tab
            this.toggleColorTab('background');
            return;
          }
        }

        if (this.getActiveGroup === groups.BRAND) this.setActiveSubGroup(subgroups.LOGO);

        this.setShowColorTab(false);
      } else {
        // if it is the same tab, check whether any other sidebar is opened
        let hasOpenedTab = false;

        // Don't need to close color tab for backgrounds
        if (this.showColorTab && this.getActiveGroup !== 'backgrounds') {
          if (this.isTextBackground) this.setIsTextBackground(false);
          if (this.isTextShadow) this.setIsTextShadow(false);
          // need to add timeout so it doesn't clash with the closing of colorpicker
          // there is a function in colorpicker that will save the color based on the active colorid
          setTimeout(() => {
            this.setShowColorTab(false);
            this.updateActiveColorId(null);
          }, 100);
          hasOpenedTab = true;
        }

        if (this.showFontTab || this.showEditTab || this.showAnimationTab || this.showMaskTab) {
          // console.log('closeExtraLeftSidebar');
          this.closeExtraLeftSidebar();
          hasOpenedTab = true;
        }

        if (this.getActiveGroup === 'backgrounds' && this.getActiveSubGroup === 'colour') {
          // If sidebar was showing, close it
          if (this.showSidebar) this.setShowColorTab(false);
          else this.toggleColorTab('background');
        }

        if (!hasOpenedTab) {
          this.setShowSidebar(!this.showSidebar);
        }
      }

      if (group === 'my-files') {
        this.setActiveSubGroup('images');
      }

      if (!this.showSwap) {
        this.emptyActiveElements();
      }
    },
  },
  watch: {
    showSwap(value) {
      if (value) {
        const { type } = this.getSwappedElement;
        this.activeSwap = type;
      } else {
        this.activeSwap = '';
      }
    },
    showMultipleSwap(value) {
      if (value) {
        this.activeSwap = 'images';
      } else {
        this.activeSwap = '';
      }
    },
  },
};
</script>

<style lang="scss">
.assets-navigation__button {
  align-items: center;
  background: none;
  border: 0;
  color: $textGrey;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  text-align: center;
  user-select: none;
  width: 54px;
  padding: 10px 0;
  border-radius: 27px 0 0 27px;
  margin: auto;
  margin-bottom: 15px;
  transition: background-color 0.3s cubic-bezier(0, 0, 0, 1), opacity 0.3s ease-in-out;

  .lite-mode & {
    margin-bottom: 0;
  }

  @include smallest {
    padding: 8px 0;
  }

  &.is-hidden {
    display: none;
  }

  &:first {
    margin-top: 10px;
  }

  .title {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1;
    width: 100%;
    margin-top: 6px;
    display: block;
  }

  .icon {
    font-size: 1.125rem;

    &.icon-text-menu2 {
      font-size: 0.875rem;
    }

    &.icon-upload-menu2 {
      font-size: 1.25rem;
    }

    &.icon-music-menu2 {
      font-size: 1rem;
    }

    &.icon-saved-menu2 {
      font-size: 1.0625rem;
    }
  }

  .icon-brand-1 {
    font-size: 23px;
  }

  &.is-active,
  &:hover {
    color: $lightWhite;
  }

  &.is-dark {
    color: $lightWhite;
    opacity: 0.3;

    &.is-active,
    &:hover {
      opacity: 1;
    }
  }
}
</style>
