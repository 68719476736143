<template lang="pug">
.element-text-3d.effect
  BaseSwitch.element-text-3d__switch.effect__switch(
    name="element-text-3d"
    :isChecked="isEnabled('text-3d')"
    :isBeta="true"
    @change="toggleEnable('text-3d')"
    label="3D Text"
  )
  VueSlideUpDown.element-text-3d__content.effect__content(
    :class="{'effect-content--enabled': isEnabled('text-3d')}"
    :active="true"
  )
    .text-3d__color.effect__color
      span.title Extrude Color
      button.color.btn-add-color(
        :class="{ 'is-active': isText3D }"
        type="button"
        :style="colorStyle"
        @click="openColorPicker"
      )
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="6"
      title="Distance"
      :value="distance"
      @blur="onBlurInput($event, 'distance')"
      @update="updateDistance"
    )
    .effects__button-list-container
      p.button-list__title Angle
      .button-list
        BaseButton.button-list__item(
          v-for="angle in angleList"
          :key="angle.value"
          :plain-hover="true"
          :is-canvas="true"
          :class="{'is-selected': isSelectedAngle(angle.value), 'button--hidden': angle.value === 'center-center'}"
          @click="updateAngle(angle.value)"
        )
          i(:class="angle.icon")

    BaseButton.effects__reset-button(
      :is-canvas="true"
      is-dark=true
      is-full-width=true
      @click="reset3DText"
    ) Reset
</template>

<script>
import { mapState } from 'vuex';
import VueSlideUpDown from 'vue-slide-up-down';
import { textDataFormat, defaultText3dDistance } from '@/assets/scripts/variables';
import { hexToRgb } from '@/assets/scripts/utilities';
import effectsMixin from '@/components/mixins/effects-mixins';

export default {
  name: 'Text3DControls',
  components: {
    VueSlideUpDown,
  },
  mixins: [effectsMixin],
  data() {
    return {
      angleList: [
        {
          value: 'top-left',
          icon: 'icon-to-top-left',
        },
        {
          value: 'top-center',
          icon: 'icon-to-top',
        },
        {
          value: 'top-right',
          icon: 'icon-to-top-right',
        },
        {
          value: 'center-left',
          icon: 'icon-to-left',
        },
        {
          value: 'center-center',
          icon: 'icon-from-center',
        },
        {
          value: 'center-right',
          icon: 'icon-to-right',
        },
        {
          value: 'bottom-left',
          icon: 'icon-to-bottom-left',
        },
        {
          value: 'bottom-center',
          icon: 'icon-to-bottom',
        },
        {
          value: 'bottom-right',
          icon: 'icon-to-bottom-right',
        },
      ],
    };
  },
  computed: {
    ...mapState(['isText3D']),
    activeElement() {
      return this.getActiveElements[0];
    },
    colorStyle() {
      const styleObject = {};

      let textColor = '#ffffff';

      if (this.getActiveElements.length) {
        const { color } = this.activeElement.data.text3D;

        const { r, g, b } = hexToRgb(color);
        textColor = `rgb(${r}, ${g}, ${b})`;
      }

      styleObject.background = textColor;

      return styleObject;
    },
    distance() {
      return this.activeElement.data.text3D.distance;
    },
    selectedAngle() {
      return this.activeElement.data.text3D.selectedAngle;
    },
  },
  mounted() {
    if (!this.isEnabled('text-3d')) {
      this.toggleEnable('text-3d');
    }
  },
  methods: {
    isSelectedAngle(angle) {
      const splitAngle = angle.split('-');
      const angleX = splitAngle[1];
      const angleY = splitAngle[0];

      return this.selectedAngle.x === angleX && this.selectedAngle.y === angleY;
    },
    updateDistance(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementText3D({ distance: value, isModifyingDistance: true });

        this.$root.$emit('canvasElementSizeUpdated');
      }
    },
    updateAngle(value) {
      const splitAngle = value.split('-');
      const angleY = splitAngle[0];
      const angleX = splitAngle[1];

      this.catchHistory('element');
      this.updateCanvasElementText3D({ selectedAngle: { x: angleX, y: angleY } });
    },
    onBlurInput(event, type) {
      this.onBlurEffectInput(event, {
        effect: 'text3D',
        selectedInput: type,
      });
    },
    openColorPicker() {
      this.setIsText3D(true);
      this.$emit('open-colorpicker');
    },
    reset3DText() {
      this.catchHistory('element');

      this.updateCanvasElementText3D({
        color: textDataFormat.text3D.color,
        distance: defaultText3dDistance,
        selectedAngle: textDataFormat.text3D.selectedAngle,
      });

      this.$root.$emit('canvasElementSizeUpdated');
    },
  },
};
</script>

<style lang="scss">
</style>
