<template lang="pug">
.tooltip-menu(
  v-click-outside="onClickOutside"
  :class="{'is-dark': isDarkMode}"
)
  button.btn--control.btn-tooltip-menu(
    v-if="!noTooltipButton && dataTitle"
    type='button'
    :class="[ { 'is-active': isActiveClass, }, buttonClass ]"
    :title="buttonTitle"
    :data-title="dataTitle"
    v-html="buttonText"
    @click="toggleShow"
  )
  button.btn--control.btn-tooltip-menu(
    v-else-if="!noTooltipButton"
    type='button'
    :class="[ { 'is-active': isActiveClass, }, buttonClass ]"
    :title="buttonTitle"
    v-html="buttonText"
    @click="toggleShow"
  )
  template(v-if="isShow")
    template(v-if="noHeader")
      .tooltip-menu__content.tooltip-menu__content--noheader
        slot
    template(v-else)
      ToolModal.tooltip-menu__content(
        :class="[{ 'tooltip-menu__content--short': isShort }, cssClass]"
        @closeOverlay="closeOverlay"
        :isShow="isShow"
        :title="title"
      )
        slot
</template>

<script>
import vClickOutside from 'v-click-outside';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'TooltipMenu',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [darkModeMixin],
  data() {
    return {
      isShow: false,
    };
  },
  props: {
    noTooltipButton: { type: Boolean, default: false },
    isShowTooltip: { type: Boolean, default: false }, // used together with noTooltipButton, for example in ControlGeneral - Transparency
    buttonTitle: { type: String, default: '' },
    dataTitle: { type: String, default: '' },
    buttonText: { type: String, default: '' },
    buttonClass: { type: String, default: 'btn--text' },
    cssClass: { type: String, default: '' },
    hoverException: { type: String, default: '' },
    title: { type: String, default: '' },
    isShort: { type: Boolean, default: false },
    noHeader: { type: Boolean, default: false },
    enableHighlightEffect: { type: Boolean, default: true },
  },
  computed: {
    isActiveClass() {
      return this.isShow && this.enableHighlightEffect;
    },
  },
  methods: {
    toggleShow() {
      this.isShow = !this.isShow;
      this.$emit('toggleShow', this.isShow);
    },
    closeOverlay() {
      this.isShow = false;
      this.$emit('closeOverlay');
    },
    onClickOutside() {
      this.isShow = false;
    },
  },
  watch: {
    isShowTooltip(val) {
      // set timeout to avoid set the "this.isShow" in the same time with "onClickOutside" function
      setTimeout(() => {
        this.isShow = val;
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.tooltip-menu {
  display: inline-block;
  position: relative;

  body:not(.collapse-all):not(.collapse-half) &.is-hover,
  body:not(.collapse-all):not(.collapse-half) &.is-active {
    .tooltip-menu__content {
      transform: translate(-50%, 0);
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.is-dark {
    .tooltip-menu__content--noheader {
      background: $darkGrey600;
      border-color: $darkGrey600;
    }

    .slider__title {
      color: $light;
    }

    input[type='range']::-webkit-slider-thumb {
      background: $light !important;
    }

    .slider__value {
      color: $light;
      border-color: $light;
    }

    .slider__container .range-wrapper .range-before {
      background: $light;

      &:after {
        background: $lightGrey800;
      }
    }

    .title {
      color: $lightGrey100;
    }
  }
}

.tooltip-menu__content {
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;

  .top-controls & {
    top: 45px;
  }
}

.tooltip-menu__content--noheader {
  position: absolute;
  background: $lightBg;
  border: 1px solid $defaultBorder;
  right: -10px;
  padding: 20px 20px 15px;
  min-width: 275px;
  box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
  border-radius: $componentBorderRadius;
}

.tooltip-menu__content--nopadding {
  padding: 0;
}

.tooltip-menu__content--short {
  width: 170px;

  .canvas-control__row {
    .btn {
      width: auto;
      padding: 3px;
      font-size: 0.875em;

      &:hover {
        color: $darkGreyHover;
      }
    }
  }
}

.canvas-control__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;

  .js-slider-value {
    padding: 0px 5px;
  }

  .color__subgroup {
    margin-bottom: 0;
  }

  .slider-container {
    padding: 0;
    justify-content: flex-end;
  }

  .form-group {
    margin-bottom: 8px;
  }

  .js-text-size,
  .text__group .option {
    font-size: 0.9em;
  }

  .title {
    width: auto;
    min-width: 90px;
    margin-right: 5px;
    text-align: left;
    font-size: 0.9em;
    flex: 1 0 auto;
  }

  .content {
    flex: 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    &.color__subgroup {
      position: static;
    }
  }

  .content--full {
    width: 100%;
    justify-content: space-between;

    .btn {
      width: auto;
      margin: 0;
    }
  }

  .divider {
    background: $darkGrey;
    border: 0;
    display: inline-block;
    flex: 0 1 1px;
    height: 19px;
    margin: 0 5px;
    width: 1px;
  }

  .btn {
    width: calc(50% - 6px);
    margin: 0 2px;
    text-align: center;
  }

  .btn-rotate-layer {
    width: 40px;
    margin: 0;
    font-size: 1.25em;
    padding: 5px;

    &:first-child {
      border-radius: 20px 0 0 20px;
      border-right: 0;
    }

    &:last-child {
      border-radius: 0 20px 20px 0;
    }
  }

  .btn-animate-control {
    font-size: 0.75em;
    width: calc(70% - 8px);
    margin-top: 6px;

    .value {
      text-align: center;
      display: none;
      font-weight: 500;
    }

    .default {
      opacity: 0.8;
    }

    &.is-active,
    &:hover,
    &.has-value:not(.disable-animation) {
      background: $darkGrey;
      border-color: $darkGrey;
      color: #fff;
    }

    &.has-value:not(.disable-animation) {
      .value {
        display: inline-block;
      }
      .default {
        display: none;
      }
    }

    &.disable-animation {
      background: $lightGrey;
      border-color: $lightGrey;
      color: $lightGrey;

      &:hover ~ .text {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }
    }

    & ~ .text {
      font-size: 0.875em;
      margin-top: 6px;
      position: absolute;
      left: 8px;
      top: 58px;
      visibility: hidden;
      opacity: 0;
      transform: translateY(-5px);
      transition: transform 0.25s ease-in-out, visibility 0.25s ease-in-out,
        opacity 0.25s ease-in-out;

      text-align: left;
      color: $darkGrey;
      border: none;
      z-index: 9;
    }
  }
}

.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  width: 100%;

  input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    margin: 9px auto;
    background: transparent;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 7px;
    cursor: pointer;
    background: transparent;
    border-radius: 3px;
  }
  input[type='range']::-webkit-slider-thumb {
    height: 14px;
    width: 14px;
    border-radius: 14px;
    background: $darkGrey;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3px;
  }
  input[type='range']:focus::-webkit-slider-runnable-track {
    background: transparent;
  }
  input[type='range']::-moz-range-track {
    width: 100%;
    height: 7px;
    cursor: pointer;
    background: transparent;
    border-radius: 5px;
  }
  input[type='range']::-ms-track {
    width: 100%;
    height: 7px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
    border-radius: 5px;
  }
  input[type='range']::-ms-fill-lower {
    background: transparent;
    border-radius: 14px;
  }
  input[type='range']::-ms-fill-upper {
    background: transparent;
    border-radius: 14px;
  }
  input[type='range']::-moz-range-thumb,
  input[type='range']::-ms-thumb {
    height: 14px;
    width: 14px;
    border-radius: 14px;
    background: $darkGrey;
    cursor: pointer;
  }
  input[type='range']::-ms-thumb {
    height: 2px;
  }
  input[type='range']:focus::-ms-fill-lower {
    background: transparent;
  }
  input[type='range']:focus::-ms-fill-upper {
    background: transparent;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .text {
    font-size: 0.75em;
    width: 63px;

    & ~ .range-wrapper {
      width: 50%;
      margin: 0 5px;
    }
  }
}

.range-wrapper {
  max-width: calc(100% - 50px);
  position: relative;
  overflow: hidden;
  width: 100%;
  line-height: 1;

  .range-before {
    width: 100%;
    height: 2px;
    top: 12px;
    background: $darkGrey;
    // transform: translateX(-100%);
    // left: 0;
    left: -100%;
    display: block;
    position: absolute;

    &::after {
      content: '';
      width: 100%;
      height: 2px;
      transform: translateX(100%);
      position: absolute;
      top: 0;
      left: 0;
      background: $defaultBorder;
    }
  }

  input {
    position: relative;
    z-index: 2;
  }
}

.js-slider-value {
  width: 40px;
  margin-left: 10px;
  background: transparent;
  color: $darkGrey;
  text-align: center;
  padding: 5px;
  font-size: 0.875em;
  border: 1px solid $defaultBorder;
  border-radius: $componentBorderRadius;
}
</style>
