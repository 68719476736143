<template lang="pug">
BaseButton.tab__item(
  type="button"
  :class="subGroupsButtonClass"
  :is-hollow="true"
  :plain-hover="true"
  @click="subgroupClickHandler"
)
  i.icon(v-if="itemIcon" :class="itemIcon")
  span.element__text.text(v-else) {{ itemValue }}
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import { groups } from '@/assets/scripts/enums';

export default {
  name: 'SubGroupsButton',
  props: {
    itemKey: { type: String, default: '' },
    itemValue: { type: String, default: '' },
    itemIcon: { type: String, default: '' },
    activeSwap: { type: String, default: '' },
    groups: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapState(['showSwap', 'showColorTab']),
    ...mapGetters('assetsSidebar', ['getActiveGroup', 'getActiveSubGroup']),
    ...mapGetters('canvasElements', ['getActiveElements', 'getSwappedElement']),
    subGroupsButtonClass() {
      const cssClass = [];
      if (this.getActiveSubGroup === this.itemKey || (this.itemKey === 'images' && this.getActiveSubGroup === 'logos')) cssClass.push('nav--active');
      if (this.isHidden) cssClass.push('is-disabled');
      if (this.isOnMusicTab || this.isMyFilesCategory || this.isOnMediaTab) cssClass.push('tab__item--2-column');

      return cssClass.join(' ');
    },
    isHidden() {
      if (this.getActiveSubGroup === this.itemKey) return false;
      const isImage = this.getActiveElements.length > 0 ? this.getActiveElements[0].isImage : '';
      const isCustom = this.getActiveElements.length > 0 ? this.getActiveElements[0].isCustom : '';

      if (this.activeSwap) {
        if (
          (this.activeSwap === 'images' || this.activeSwap === 'logos')
          && this.itemKey !== 'photos'
          && this.itemKey !== 'photo'
          && this.itemKey !== 'images'
          && this.itemKey !== 'logos'
        ) return true;

        if (
          this.activeSwap === 'masks'
          && this.itemKey !== 'photos'
          && this.itemKey !== 'photo'
          && this.itemKey !== 'images'
          && this.itemKey !== 'logos'
        ) return true;

        if (this.activeSwap === 'videos') {
          const [category] = this.getSwappedElement.category;
          if (category === 'graphics' && this.itemKey !== 'graphics') return true;
          if (category === 'effects' && this.itemKey !== 'effects') return true;
          if (
            this.itemKey !== 'video'
            && this.itemKey !== 'videos'
            && this.itemKey !== 'overlays'
            && this.itemKey !== 'effects'
          ) return true;
        }

        if (
          this.activeSwap === 'graphics'
          && isImage
          && this.itemKey !== 'graphics'
          && this.itemKey !== 'images'
          && this.itemKey !== 'photos'
          && this.itemKey !== 'photo'
          && this.itemKey !== 'logos'
          && isCustom
          && this.itemKey !== 'customElements'
        ) return true;

        if (this.activeSwap === 'shapes' && this.itemKey !== 'shapes') return true;

        if (this.activeSwap === 'patterns' && this.itemKey !== 'patterns') return true;
      }
      return false;
    },
    isOnMusicTab() {
      return this.getActiveGroup === groups.MUSIC;
    },
    isOnMediaTab() {
      return this.getActiveGroup === groups.MEDIA;
    },
    isMyFilesCategory() {
      return this.getActiveGroup === groups.MYFILES;
    },
  },
  methods: {
    ...mapMutations(['setShowColorTab']),
    ...mapMutations('assetsSidebar', ['setActiveSubGroup', 'clearActiveCategory']),
    ...mapActions(['toggleColorTab']),
    doChangeActiveSubGroup() {
      if (this.getActiveGroup === 'elements') {
        this.clearActiveCategory();
      }
      this.$root.$emit('doRetrieveCategoriesTags');
    },
    subgroupClickHandler() {
      this.setActiveSubGroup(this.itemKey);
      if (this.itemKey === 'colour') {
        if (!this.showColorTab) this.toggleColorTab('background');
      } else {
        this.setShowColorTab(false);
        this.doChangeActiveSubGroup();
      }
    },
  },
  // mounted() {
  //   if (this.groups[this.getActiveGroup].length) {
  //     // When a new 'Group' is selected, select the first category as the 'Category'
  //     if (!this.activeSwap) {
  //       this.setActiveSubGroup(this.groups[this.getActiveGroup][0].key)
  //     }
  //   }
  // },
};
</script>

<style lang="scss">
.assets-lists .tab__item.is-disabled {
  color: $darkGrey100 !important;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.4;
}
</style>
