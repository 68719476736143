<template lang="pug">
.timeline__item(
  :class="timelineNumberStyle"
  @mouseenter="$emit('hovered', index)"
  :style="[layerStyle, groupLayerStyle, borderLeftColor]"
)
  span.timeline__button.layer-number(
    v-html="indexNumber + 1"
    v-if="!subItem"
  )
</template>

<script>
import layerMixin from '@/components/mixins/layer-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'TimelineNumber',
  mixins: [layerMixin, darkModeMixin],
  props: {
    index: { type: Number, default: 0 },
    indexNumber: { type: Number, default: 0 },
    hoveredIndex: { type: Number, default: -1 },
    item: { type: Object, default: () => ({}) },
    isDragging: { type: Boolean, default: false },
    subItem: { type: Boolean, default: false },
  },
  computed: {
    isLocked() {
      return this.item.lock === true;
    },
    isHidden() {
      return this.item.show === false;
    },
    isHovered() {
      return !this.isDragging && this.hoveredIndex === this.index;
    },
    timelineNumberStyle() {
      return {
        'is-locked': this.isLocked,
        'is-hidden': this.isHidden,
        'is-active': this.isActive && !this.isDragging,
        'is-hovered': this.isHovered,
        'is-clicked': this.clickedLayer === this.index,
        'is-dark': this.isDarkMode,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline__item {
  border-bottom: 1.5px solid var(--lightgrey400-darkgrey500);
  border-left: 3px solid;
}

.layer-number {
  font-weight: 400;
  color: var(--darkgrey800-lightgrey400);
}
</style>
