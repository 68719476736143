<template lang="pug">
.layer-container
  template(v-if="!isLiteMode")
    button.btn-collapse-layer(
      type="button"
      title="Close layer"
      @click="toggleLayer"
    )
      i.icon.icon-right-arrow
    .layer-container__manager(
      v-if="isTemplate && getCanvasElements.length"
    )
      BaseSwitch.layer-tag__switch(
        name="layer-tag"
        :isChecked="showTagManager"
        @change="toggleEnableHeroTag"
        label="Hero Tag Manager"
      )
    LayerEmptyState(
      v-if="!getCanvasElements.length"
    )
    h3.title(v-else) Layers
    draggable.layers(
      v-if="getCanvasElements.length"
      v-model="canvasElements"
      :class="{'is-dark': isDarkMode}"
    )
      template(v-for="(item, index) in canvasElementsGroups")
        LayerItem(
          :key="`layer-${index}`"
          :has-new-background="hasNewBackground"
          :item="item"
          :index="index"
          @addRangeActiveElements="addRangeActiveElements(item.data.id, item)"
          :showTag="showTagManager"
          @selectLayer="selectLayer(item)"
        )
        LayerItem(
          v-for="(groupItem, groupIndex) in item.elements"
          v-if="!item.groupFold"
          :key="`layer-${index}-${groupIndex}`"
          :item="groupItem"
          :index="index"
          @addRangeActiveElements="addRangeActiveElements(groupItem.data.id)"
          :showTag="showTagManager"
          :subItem="true"
          @selectLayer="selectLayer(groupItem)"
        )
      .layer-item(style="display: none;")
  GlobalEvents(
    :filter="(event, handler, eventName) => allowGlobalEvents(event)"
    @keydown.ctrl.68.exact.prevent="duplicateLayers"
    @keydown.meta.68.exact.prevent="duplicateLayersMac"
  )
</template>

<script>
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';
import draggable from 'vuedraggable';

import cloneDeep from 'lodash.clonedeep';
import LayerItem from './children/LayerItem.vue';
import LayerEmptyState from '@/components/ProjectCanvas/LayerContainer/children/LayerEmptyState.vue';
import { isMac, randomId } from '@/assets/scripts/utilities';

import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'LayerContainer',
  components: {
    LayerItem,
    draggable,
    LayerEmptyState,
  },
  mixins: [darkModeMixin],
  data() {
    return {
      canvasElementsGroups: [],
      hasNewBackground: false,
      isGroupSelect: false,
      selectItem: null,
      selectItemGroupIndex: null,
    };
  },
  computed: {
    ...mapState(['isTemplate', 'showLayer', 'showSwap', 'showCrop', 'showTagManager', 'showTimeline']),
    ...mapState('canvasElements', ['isChangingElements', 'changeBackgroundCount']),
    ...mapGetters(['isLiteMode']),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getCanvasElements',
      'getActiveElementsIndexes',
      'getCanvasElementIndexById',
      'getCanvasElementsIds',
      'getCanvasElementsGroups',
    ]),
    ...mapGetters('canvasLayouts', ['rightSidebarWidth']),
    /* eslint-disable */
    canvasElements: {
      get() {
        if (!this.isChangingElements) {
          this.canvasElementsGroups = this.getCanvasElementsGroups;

          const elements = [];
          let groupId = null;

          // for sorting elements,
          // grouping is done with computed elements.
          // so the elements are not prepared in canvasElements yet.
          // we need to setup the elements for sorting groups and elements.
          this.getCanvasElements.forEach((element) => {
            if (element.data.isGroup) {
              if (groupId !== element.data.groupId) {
                elements.push({
                  groupId: element.data.groupId,
                  groupName: element.data.groupName,
                });

                elements.push(element);
                groupId = element.data.groupId ? element.data.groupId : null;
              } else {
                elements.push(element);
                groupId = element.data.groupId ? element.data.groupId : null;
              }
            } else {
              elements.push(element);
            }
          });

          return elements;
        }
      },
      /* eslint-enable */
      set(value) {
        const selectedElementId = (this.selectItem && this.selectItem.data.id) || null;
        const selectedGroupId = (this.selectItem && this.selectItem.groupId) || null;
        const selectedId = this.isGroupSelect ? selectedGroupId : selectedElementId;

        this.reorderCanvasElements({
          isGroupSelect: this.isGroupSelect,
          selectedId,
          newElements: value,
          getCanvasElementsGroups: this.getCanvasElementsGroups,
        });
      },
    },
  },
  methods: {
    ...mapMutations(['setShowTagManager', 'setShowLayer', 'setShowTimeline']),
    ...mapMutations('canvasElements', [
      'addActiveElements',
      'emptyActiveElements',
      'insertElementToCanvas',
      'reorderCanvasElements',
    ]),
    ...mapMutations('canvasLayouts', ['setCanvasSectionPaddingRight']),
    ...mapActions('canvasElements', ['copyActiveElements', 'pasteActiveElements']),
    ...mapActions('canvasHistory', ['catchCanvasElementState']),
    allowGlobalEvents(event) {
      return event.target.tagName !== 'INPUT'
      && event.target.tagName !== 'TEXTAREA'
      && event.target.getAttribute('contenteditable') !== 'true'
      && !this.showSwap
      && !this.showCrop;
    },
    addRangeActiveElements(item, group = false) {
      if (this.getActiveElementsIndexes.length) {
        let startingIndex = Math.min(...this.getActiveElementsIndexes);
        let endingIndex = this.getCanvasElementIndexById(item);

        if (endingIndex < startingIndex) {
          startingIndex = this.getCanvasElementIndexById(item);
          endingIndex = Math.min(...this.getActiveElementsIndexes);
        }

        // console.table({
        //   startingIndex,
        //   endingIndex,
        //   getCanvasElementIndexById: this.getCanvasElementIndexById(id),
        //   id,
        // })

        if (!group.groupElement) {
          // eslint-disable-next-line
          for (let i = startingIndex; i <= endingIndex; i++) {
            const element = this.getCanvasElements[i];
            this.addActiveElements(element.data.id);
          }
        } else {
          group.elements.forEach((element) => {
            this.addActiveElements(element.data.id);
          });
        }
      } else {
        this.addActiveElements(item);
      }
    },
    duplicateLayers() {
      this.catchCanvasElementState(['scenesList', 'elementsList']);

      const activeElements = cloneDeep(this.getActiveElements);
      if (activeElements.length) {
        this.emptyActiveElements();
      }

      const groupIds = [];
      activeElements.forEach((element) => {
        if (element.data.isGroup && !groupIds.includes(element.data.groupId)) {
          groupIds.push(element.data.groupId);
        } else if (!element.data.isGroup) {
          this.insertElementToCanvas({
            id: element.data.id,
            isDuplicate: true,
          });
        }
      });

      groupIds.forEach((groupId) => {
        const activeGroupElements = activeElements.filter(element => element.data.isGroup && element.data.groupId === groupId);

        const groupElements = this.getCanvasElements.filter(element => element.data.isGroup && element.data.groupId === groupId);

        const newGroupId = randomId();
        /* eslint-disable */
        activeGroupElements.forEach((element) => {
          if (activeGroupElements.length === groupElements.length) {
            element.data.groupId = newGroupId;
            element.data.groupName = `${element.data.groupName} - Copied`;
          }
        });
        /* eslint-enable */

        this.copyActiveElements(activeGroupElements);
        this.pasteActiveElements();
      });
    },
    duplicateLayersMac() {
      if (!isMac()) return;
      this.duplicateLayers();
    },
    toggleEnableHeroTag() {
      this.setShowTagManager(!this.showTagManager);
    },
    selectLayer(item) {
      if (item.groupElement) {
        this.isGroupSelect = true;
        this.selectItem = item;
        this.selectItemGroupIndex = null;
      } else {
        this.isGroupSelect = false;
        this.selectItem = item;

        this.canvasElements.forEach((element, index) => {
          if (element.groupId && element.groupId === this.selectItem.data.groupId) {
            this.selectItemGroupIndex = index;
          }
        });
      }
    },
    toggleLayer() {
      if (this.showTimeline) this.setShowTimeline(false);

      if (!this.showLayer && !this.isLiteMode) {
        this.setCanvasSectionPaddingRight(this.rightSidebarWidth);
      } else {
        this.setCanvasSectionPaddingRight(0);
      }

      this.setShowLayer(!this.showLayer);
    },
  },
  mounted() {
    if (this.showLayer && !this.isLiteMode) {
      this.setCanvasSectionPaddingRight(this.rightSidebarWidth);
    }

    this.$root.$on('duplicate-layers', () => {
      console.log('on:duplicate-layers');
      this.duplicateLayers();
    });
  },
  watch: {
    changeBackgroundCount() {
      this.hasNewBackground = true;

      // trigger greenHighlight css animation in LayerItem.vue then prepare to give animation again if there is a background change later on.
      setTimeout(() => {
        this.hasNewBackground = false;
      }, 1500);
    },
    getCanvasElements: {
      handler() {
        if (!this.isChangingElements) {
          this.$nextTick(() => {
            this.canvasElementsGroups = [];
            this.$nextTick(() => {
              this.canvasElementsGroups = this.getCanvasElementsGroups;
            });
          });
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.layer-container {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  border-radius: 8px 0 0 8px;
  background: var(--white-darkgrey700);

  .title {
    font-size: 0.975rem;
    font-weight: 600;
    margin: 0;
    padding: 18px;
    border-bottom: 1px solid var(--bordergrey-darkblue2);
    color: var(--baseblack-white);
  }
}

.layer-container__manager {
  font-size: 0.875em;
  padding: 10px;
  border-bottom: 1px solid $borderGrey;
  margin-left: -10px;
  margin-top: -10px;
  margin-bottom: 10px;
  width: calc(100% + 20px);
}

.layer-tag__switch {
  font-size: 0.875em;
  text-align: center;
  background: #eee;
  padding: 8px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;

  &.switch .slider {
    margin: -4px 0;
  }
}

.layers {
  background: var(--white-darkgrey700);
  height: 100%;
  overflow: auto;
  border-radius: 0 0 0 8px;

  &.is-dark {
    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background-color: $darkGrey800;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $darkGrey200;
      border-radius: 4px;

      &:hover {
        background-color: $darkGrey200;
      }
    }
  }
}

.btn-collapse-layer {
  padding: 5px 0 5px 3px;
  height: 90px;
  display: flex;
  align-items: center;
  background: var(--white-darkgrey700);
  color: var(--black-white);
  position: absolute;
  z-index: 5;
  left: 1px;
  top: 35vh;
  border-radius: 10px 0 0 10px;
  transform: translateX(-100%);
  cursor: pointer;
  transition: background 0.25s ease-in-out, padding 0.25s ease-in-out;
  font-size: 1rem;

  .icon {
    position: relative;
    left: 2px;
    transition: transform 0.25s ease-in-out;
  }

  .right-sidebar.is-collapsed & {
    padding: 5px;

    .icon {
      left: 0;
      transform: rotate(180deg);
    }
  }
}
</style>
