<template lang="pug">
.project-settings(
  v-click-outside="onClickOutside"
)
  BaseButton.btn-project-settings(
    :is-dark="true"
    :is-light="true"
    :is-canvas="true"
    @click="openProjectSettings"
  ) Resize

  .project-settings__container(
    :class="{ 'is-show': isOpened }"
  )
    .project-settings__overlay(
      @click="isOpened = false"
    )
    .project-settings__content
      .project-settings__size
        .project-settings__field
          label(
            for="project-width"
            :class="{ 'is-disabled': isFreeUser }"
          ) Width
          BaseInputField#project-width(
            ref="projectWidth"
            :value="projectWidth"
            @blur="correctProjectWidth"
            @change="updateProjectWidth"
            suffix="px"
            isNumber=true
            :isDisabled="isFreeUser"
            @focus="$event.target.select()"
            isDark=true
          )
        .project-settings__field
          label(
            for="project-height"
            :class="{ 'is-disabled': isFreeUser }"
          ) Height
          BaseInputField#project-height(
            ref="projectHeight"
            :value="projectHeight"
            @blur="correctProjectHeight"
            @change="updateProjectHeight"
            suffix="px"
            isNumber=true
            :isDisabled="isFreeUser"
            @focus="$event.target.select()"
            isDark=true
          )
        .project-settings__size--preset
          .project-settings__field
            label
              | Project Sizes
          .project-settings__options
            BaseButton.btn-size-option(
              :class="{ 'is-active': isSquare }"
              :is-text="true"
              :is-hollow="true"
              :plain-hover="true"
              @click="changeSize('square')"
            )
              .project-settings__option.project-settings__option--square
              span.project-settings__option-text
                | Square
            BaseButton.btn-size-option(
              :class="{ 'is-active': isVertical }"
              :is-text="true"
              :is-hollow="true"
              :plain-hover="true"
              @click="changeSize('vertical')"
            )
              .project-settings__option.project-settings__option--vertical
              span.project-settings__option-text
                | Vertical
            BaseButton.btn-size-option(
              :class="{ 'is-active': isLandscape }"
              :is-text="true"
              :is-hollow="true"
              :plain-hover="true"
              @click="changeSize('landscape')"
            )
              .project-settings__option.project-settings__option--landscape
              span.project-settings__option-text
                | Landscape
            BaseButton.btn-size-option(
              :class="{ 'is-active': !isSquare && !isVertical && !isLandscape }"
              :is-text="true"
              :is-hollow="true"
              :plain-hover="true"
              @click="openCustomSize"
              :iconBefore="iconBefore"
            ) See<br/>more

        .project-settings__alert(
          v-if="showResizeAlert"
          @click.stop="showResizeAlert = false"
        )
          i.icon.icon-alert
          span.content(v-html="settingsAlert")

      .project-settings__actions
        BaseButton(
          :is-success="true"
          :is-full-width="true"
          :is-canvas="true"
          :is-tall="true"
          @click="changeProjectDetails"
        ) Apply
        BaseButton.btn--cancel(
          :is-full-width="true"
          :is-tall="true"
          :is-text="true"
          @click="isOpened = false"
        ) Cancel
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import vClickOutside from 'v-click-outside';
import { projectDimensionLimit } from '@/assets/scripts/variables';
import storyboardMixin from '@/components/mixins/storyboard-mixins';

export default {
  name: 'ProjectSettings',
  mixins: [storyboardMixin],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showResizeAlert: false,
      isOpened: false,
      canvasWidth: 0,
      canvasHeight: 0,
      projectDimensionLimit,
    };
  },
  computed: {
    ...mapGetters('userData', ['isFreeUser']),
    ...mapGetters(['getIsTemplate']),
    ...mapGetters('canvasElements', ['getProjectDetails', 'getCanvasSize']),
    projectWidth() {
      return this.canvasWidth.toString();
    },
    projectHeight() {
      return this.canvasHeight.toString();
    },
    iconBefore() {
      if (this.isFreeUser) return 'icon-star';
      return '';
    },
    isSquare() {
      return this.canvasWidth === 1080 && this.canvasHeight === 1080;
    },
    isLandscape() {
      return this.canvasWidth === 1920 && this.canvasHeight === 1080;
    },
    isVertical() {
      return this.canvasWidth === 1080 && this.canvasHeight === 1920;
    },
    settingsAlert() {
      const { min, max } = this.projectDimensionLimit;
      return `Your design should be minimum of <strong>${min}px</strong> and maximum of <strong>${max}px</strong>.`;
    },
  },
  methods: {
    ...mapMutations(['setShowCommonSizes', 'setShowUpgradeSubscription']),
    ...mapMutations('canvasElements', ['updateProjectDetails']),
    ...mapActions('canvasElements', [
      'saveProject',
      'updateCanvasSize',
    ]),
    openProjectSettings() {
      this.isOpened = true;
    },
    onClickOutside() {
      this.isOpened = false;
    },
    correctProjectWidth(value) {
      const newWidth = parseInt(value, 10);
      if (newWidth < this.projectDimensionLimit.min) {
        this.canvasWidth = parseInt(this.projectDimensionLimit.min, 10);
        this.showAlert();
      } else if (newWidth > this.projectDimensionLimit.max) {
        this.canvasWidth = parseInt(this.projectDimensionLimit.max, 10);
        this.showAlert();
      }
    },
    correctProjectHeight(value) {
      const newHeight = parseInt(value, 10);
      if (newHeight < this.projectDimensionLimit.min) {
        this.canvasHeight = parseInt(this.projectDimensionLimit.min, 10);
        this.showAlert();
      } else if (newHeight > this.projectDimensionLimit.max) {
        this.canvasHeight = parseInt(this.projectDimensionLimit.max, 10);
        this.showAlert();
      }
    },
    updateProjectWidth(value) {
      const newWidth = parseInt(value, 10);
      this.canvasWidth = newWidth;
    },
    updateProjectHeight(value) {
      const newHeight = parseInt(value, 10);
      this.canvasHeight = newHeight;
    },
    changeProjectDetails() {
      const params = {};
      if (this.getIsTemplate) params.template_name = this.getProjectDetails.name;
      else params.project_name = this.getProjectDetails.name;

      this.updateProjectDetails(params);
      this.updateCanvasSize({
        width: this.canvasWidth,
        height: this.canvasHeight,
      });
      this.isOpened = false;

      this.saveProject({
        allScenes: true,
      });

      this.createStoryBoardPreviews({
        isReset: true,
      });
    },
    changeSize(size) {
      let width = 1080;
      let height = 1080;

      if (size === 'vertical') {
        height = 1920;
      }
      if (size === 'landscape') {
        width = 1920;
      }

      this.canvasHeight = height;
      this.canvasWidth = width;
    },
    openCustomSize() {
      this.isOpened = false;

      if (this.isFreeUser) {
        this.setShowUpgradeSubscription(true);
      } else {
        this.setShowCommonSizes(true);
      }
    },
    showAlert() {
      this.showResizeAlert = true;
      setTimeout(() => {
        this.showResizeAlert = false;
      }, 3000);
    },
  },
  watch: {
    isOpened(val) {
      if (val) {
        this.canvasWidth = this.getCanvasSize.width;
        this.canvasHeight = this.getCanvasSize.height;
      }
    },
  },
};
</script>

<style lang="scss">
.project-settings .btn-project-settings {
  margin: 0 15px;
  line-height: 8px;
  background: $darkGrey6;
  color: $light;
  border: 0;
  padding: 13px 16px;
  font-size: 0.875rem;

  &:hover {
    background: $light;
    color: $darkGrey6;
  }

  &:active {
    background: $darkBg2;
  }
}

.project-settings__container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 101;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s ease-in-out;

  &.is-show {
    opacity: 1;
    pointer-events: initial;
  }
}

.project-settings__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.project-settings__content {
  position: absolute;
  top: 54px;
  left: 72px;
  z-index: 2;
  padding: 30px;
  background: $darkBg2;
  box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
  width: 400px;
  text-align: left;
  border-radius: 4px;
}

.project-settings__field {
  label {
    font-size: 0.75em;
    color: $textGrey3;
    display: block;
    margin-bottom: 5px;

    &.is-disabled {
      color: $grey;
    }
  }

  input {
    width: 100%;
  }

  .project-settings__size & {
    display: inline-block;
    width: calc(50% - 10px);
    vertical-align: bottom;

    & + .project-settings__field {
      margin-left: 19px;
    }
  }
}

.project-settings__size {
  margin: 20px 0;
  margin-top: 0;
  position: relative;
}

.project-settings__size--preset {
  margin-top: 10px;

  .project-settings__options {
    display: flex;

    .btn {
      text-decoration: underline;
      margin-right: 10px;
      color: $textBlack;

      &:hover {
        color: $textBlack;
      }
    }

    .btn-size-option {
      display: inline-flex;
      flex-direction: column;
      justify-content: space-evenly;
      border: 1px solid $borderGrey;
      text-decoration: none;
      width: 75px;
      height: 75px;
      margin: 5px;
      margin-top: 0;
      background: transparent;
      border-color: $darkGrey2;
      color: $inputText2;

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }

      .project-settings__option {
        margin-top: -15px;
        border: 2px solid $inputText2;
        border-radius: $componentBorderRadius;

        &--square {
          width: 15px;
          height: 15px;
        }
        &--vertical {
          width: 15px;
          height: 30px;
        }
        &--landscape {
          width: 30px;
          height: 15px;
        }
      }

      .project-settings__option-text {
        position: absolute;
        bottom: -24px;
        left: -15px;
      }

      &:hover,
      &:focus,
      &.is-active {
        color: $lightWhite;
        border-color: $blue;
        background: transparent;

        .icon {
          text-decoration: none;
        }

        .project-settings__option {
          border: 2px solid $lightWhite;
        }
      }
    }
  }
}

.project-settings__alert {
  color: #f07503;
  position: absolute;
  top: 65px;
  padding: 10px;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;

  &:before {
    content: '';
    height: 0;
    width: 0;
    left: 0;
    top: 0;
    border: 8px solid transparent;
    border-bottom-color: #fff;
    position: absolute;
    transform: translate(12px, -16px);
  }

  .icon {
    width: 40px;
  }
  .content {
    font-size: 0.85em;
  }
}

.project-settings__actions {
  display: flex;
}

.btn--cancel {
  color: $textGrey3 !important;

  &:hover,
  &:focus {
    background: transparent;
  }
}
</style>
