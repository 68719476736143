import {
  mapGetters, mapMutations, mapActions,
} from 'vuex';
import { minDuration, maxDuration } from '@/assets/scripts/variables';

const newSceneMixin = {
  data() {
    return {
      leftoverDuration: this.isLiteMode ? maxDuration.liteScene : maxDuration.scene,
    };
  },
  computed: {
    ...mapGetters(['isLiteMode']),
    ...mapGetters('userData', [
      'isFreeUser',
    ]),
    ...mapGetters('canvasElements', [
      'getScenes',
      'getCurrentSceneDuration',
      'getAllScenesDuration',
    ]),
    isMax() {
      if (this.isFreeUser && this.getAllScenesDuration > maxDuration.freeUserProject) return true;
      console.log('is max', this.leftoverDuration, this.minDurationScene);
      if (this.leftoverDuration < this.minDurationScene) return true;
      return false;
    },
    // These 3 are also in lite mode mixins
    maxDurationScene() {
      return this.isLiteMode ? maxDuration.liteScene : maxDuration.scene;
    },
    minDurationScene() {
      return this.isLiteMode ? minDuration.liteScene : minDuration.scene;
    },
    // maxDurationProject is also in lite mode and template mixins
    maxDurationProject() {
      if (this.isLiteMode) {
        return maxDuration.liteProject;
      }
      return this.isFreeUser ? maxDuration.freeUserProject : maxDuration.payingUserProject;
    },
  },
  methods: {
    ...mapMutations([
      'setThirtySecondsWarning',
      'setShowMaxSceneWarning',
      'setShowTemplateWarning',
    ]),
    ...mapActions('canvasElements', [
      'saveProject',
    ]),
    checkMax() {
      // console.log('maxDurationProject', this.maxDurationProject, this.getAllScenesDuration);
      // if the total duration will be more than what's allowed for the project, set the max
      this.leftoverDuration = this.maxDurationProject - this.getAllScenesDuration;

      if (this.leftoverDuration > this.maxDurationScene) {
        this.leftoverDuration = this.maxDurationScene;
      }
    },
    checkForMaxScenes(isReplace) {
      // checkFreeUserThirtySeconds requires totalDuration, so only check it if there is totalDuration
      console.log('checkmax', this.isMax, this.totalDuration, this.getAllScenesDuration);
      if (this.isMax
        || (this.totalDuration && this.checkFreeUserThirtySeconds(isReplace))) {
        console.log(this.isMax, this.totalDuration, this.checkFreeUserThirtySeconds(isReplace));
        this.setThirtySecondsWarning(true);
        this.setShowTemplateWarning(false);
        return true;
      }

      if (this.getScenes.length >= process.env.VUE_APP_MAX_SCENES) {
        this.setShowMaxSceneWarning(true);
        this.setShowTemplateWarning(false);
        return true;
      }

      return false;
    },
    checkFreeUserThirtySeconds(isReplace) {
      if (!this.isFreeUser) return false;

      if (isReplace) {
        const durationWithoutThisScene = this.getAllScenesDuration - this.getCurrentSceneDuration;
        if (durationWithoutThisScene + this.totalDuration > this.maxDurationScene) {
          return true;
        }
      } else if (this.getAllScenesDuration + this.totalDuration > this.maxDurationScene) {
        return true;
      }
      return false;
    },
  },
  watch: {
    getAllScenesDuration: {
      handler() {
        // console.log('getAllScenesDuratio 1n', val);
        this.checkMax();
      },
      immediate: true,
    },
  },
};

export default newSceneMixin;
