import { render, staticRenderFns } from "./SwapScene.vue?vue&type=template&id=66e8ceaa&lang=pug&"
import script from "./SwapScene.vue?vue&type=script&lang=js&"
export * from "./SwapScene.vue?vue&type=script&lang=js&"
import style0 from "./SwapScene.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports