<template lang="pug">
div.animation-tab__navigation
  BaseButton.tab__item(
    v-for="nav in animationTypeList"
    :key="nav.name"
    type="button"
    :class="[{ 'nav--active': getActiveAnimationList.type === nav.type}, nav.class ]"
    :is-hollow="true"
    :is-canvas="true"
    @click="changeActiveAnimationType(nav)"
  )
    span.element__text.text {{ nav.name }}
</template>

<script>
import {
  mapState, mapGetters, mapMutations,
} from 'vuex';

export default {
  name: 'AnimationTabNav',
  props: {
    animationTypeList: { type: Array, default: () => [] },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('canvasElements', ['activeElementsIds']),
    ...mapGetters(['getActiveAnimationList']),
  },
  methods: {
    ...mapMutations(['closeActiveAnimationList', 'setActiveAnimationList']),
    changeActiveAnimationType(value) {
      const newAnimation = {
        type: value.type,
        id: this.activeElementsIds,
        position: this.getActiveAnimationList.position,
        animationPosition: this.getActiveAnimationList.position.position,
      };
      this.setActiveAnimationList(newAnimation);
    },
  },
};
</script>

<style lang="scss">
.animation-tab__navigation {
  display: flex;
  flex-wrap: nowrap;

  .tab__item {
    color: $lightGrey400;
    background: transparent !important;
    text-transform: capitalize;
    font-weight: 500;
    margin-right: 5px;
    height: 36px;
    width: 33%;
    padding: 8px;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    &:last-of-type {
      margin-right: 0;
    }

    &.is-green {
      border-color: $green600;
    }

    &.is-orange {
      border-color: $orange600;
    }

    &.is-red {
      border-color: $red400;
    }

    &:not(.nav--active) {
      color: $darkBlue5;
      border-color: $darkBlue5;
      font-weight: 400;
    }

    &:hover {
      color: $lightGrey400;

      &.is-green {
        border-color: $green600;
      }

      &.is-orange {
        border-color: $orange600;
      }

      &.is-red {
        border-color: $red400;
      }
    }

    .btn__circle {
      display: none;
    }
  }
}
</style>
