<template lang="pug">
Drop.resizer-element(
  :style="styleObject"
  :class="{ 'is-text': isText, 'is-mask': isMask, 'group-style': groupStyle }"
  @drop="handleDrop"
  v-touch:tap="doubleTapResizer"
  @dblclick.native="doubleClickResizer"
)
  //- text would need actual html because the height is auto
  //- the html would be needed to estimate the correct height
  template(v-if="isText")
    .text-content(
      :style="fontObject"
      v-html="itemText"
      ref="text"
    )

  GlobalEvents(
    :filter="(event, handler, eventName) => allowEnterEvent(event)"
    @keydown.enter.exact.prevent="editText"
  )
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';

import { Drop } from 'vue-drag-drop';
import Vector from '@minogin/vector';

import elementMixin from '@/components/mixins/element-mixins';
import textMixin from '@/components/mixins/text-mixins';
import { cleanUpContentHTMLEntity, randomId, neutralize } from '@/assets/scripts/utilities';

export default {
  name: 'ResizerElement',
  mixins: [elementMixin, textMixin],
  data() {
    return {
      ratio: 1,
      originalSize: {},
      tempSize: {},
      middleSticks: [
        'tm', 'mr', 'bm', 'ml',
      ],
      prevSetHeight: null,
      prevSetWidth: null,
      tapTimeStamp: null,
    };
  },
  components: {
    Drop,
  },
  props: {
    index: { type: Number, default: 0 },
    item: { type: Object, default: () => ({}) },
    totalElements: { type: Number, default: 0 },
    angle: { type: Number, default: 0 },
    parentRect: { type: Object, default: () => ({}) },
    originalRect: { type: Object, default: () => ({}) },
    isChanging: { type: Boolean, default: false },
    isResizing: { type: Boolean, default: false },
    isTextEditing: { type: Boolean, default: false },
    stick: { type: String, default: '' },
  },
  computed: {
    ...mapState({
      isCropping: 'showCrop',
    }),
    ...mapGetters([
      'getCanvasZoom',
    ]),
    ...mapGetters('canvasElements', [
      'getOriginalSizeById',
      'getActiveElements',
    ]),
    isImage() {
      return this.item.isImage || this.item.type === 'images' || (this.item.data.url && this.item.data.url.split('.').pop() === 'png');
    },
    isVideo() {
      return this.item.type === 'videos' || (this.item.data.url && this.fileExt(this.item.data.url) === 'webm');
    },
    isCroppable() {
      return (this.isImage || this.isVideo) && !this.isMask;
    },
    isText() {
      return this.item.type === 'texts';
    },
    isMask() {
      return this.item.type === 'masks';
    },
    isRoundedCornerHtml() {
      return this.item.data.isHtml && this.item.data.shape.indexOf('-rounded') > -1;
    },
    itemText() {
      if (!this.item.data.content) return '';
      return cleanUpContentHTMLEntity(this.item.data.content);
    },
    itemObject() {
      const { x } = this.item.position;
      const { y } = this.item.position;

      const itemObject = {
        x: x * this.getCanvasZoom,
        y: y * this.getCanvasZoom,
        width: this.item.size.width * this.getCanvasZoom,
        height: this.item.size.height * this.getCanvasZoom,
        angle: this.item.rotate,
      };

      if (this.totalElements === 1) {
        itemObject.angle = 0;
      }

      return itemObject;
    },
    styleObject() {
      const styleObject = {};

      styleObject.height = `${this.percentageObject.height * 100}% !important`;
      styleObject.width = `${this.percentageObject.width * 100}% !important`;
      styleObject.top = `${this.percentageObject.top * 100}%`;
      styleObject.left = `${this.percentageObject.left * 100}%`;
      styleObject.transform = `rotate(${this.itemObject.angle}deg)`;
      return styleObject;
    },
    percentageObject() {
      const percentageObject = {};

      // originalRect is the original position of resizer overlay before being resized/ moved
      // offsetX is the top position of the resizer overlay
      // offsetY is the left position of the resizer overlay
      // height is the ratio of this element height against the resizer overlay height
      // width is the ratio of this element width against the resizer overlay width
      percentageObject.offsetX = this.originalRect.x - (this.originalRect.w / 2);
      percentageObject.offsetY = this.originalRect.y - (this.originalRect.h / 2);
      percentageObject.height = this.itemObject.height / this.originalRect.h;
      percentageObject.width = this.itemObject.width / this.originalRect.w;
      percentageObject.angle = this.itemObject.angle - this.originalRect.angle;
      percentageObject.borderRadius = (this.item.data.borderRadius * this.getCanvasZoom) / this.originalRect.w;

      // itemX is the distance between this element x to top of resizer overlay
      // rectX is the distance between resizer overlay x to top of resizer overlay
      const itemX = this.itemObject.x - percentageObject.offsetX;
      const rectX = this.originalRect.x - percentageObject.offsetX;
      const itemY = this.itemObject.y - percentageObject.offsetY;
      const rectY = this.originalRect.y - percentageObject.offsetY;

      // x is the difference between itemX and rectX over the rectX
      percentageObject.x = (itemX - rectX) / rectX;
      percentageObject.y = (itemY - rectY) / rectY;

      const itemLeft = this.itemObject.x - this.itemObject.width / 2 - percentageObject.offsetX;
      const itemTop = this.itemObject.y - this.itemObject.height / 2 - percentageObject.offsetY;
      const rectLeft = this.originalRect.x - this.originalRect.w / 2 - percentageObject.offsetX;
      const rectTop = this.originalRect.y - this.originalRect.h / 2 - percentageObject.offsetY;

      percentageObject.top = (itemTop - rectTop) / this.originalRect.h;
      percentageObject.left = (itemLeft - rectLeft) / this.originalRect.w;

      return percentageObject;
    },
    fontObject() {
      const fontObject = {};
      if (!this.isText) {
        return false;
      }
      if (this.item.data) {
        // console.log('is canvas?',  this.isSelectable)
        return this.allTextElementStyles(this.item, true);
      }

      return fontObject;
    },
    isMiddleStick() {
      return this.middleSticks.includes(this.stick);
    },
    computedOriginalSize() {
      return this.getOriginalSizeById(this.item.data.id);
    },
    groupStyle() {
      if (this.item.data.isGroup && this.getActiveElements.length > 1) {
        let isGroupStyle = true;
        const { groupId } = this.item.data;

        this.getActiveElements.forEach((element) => {
          if (element.data.groupId !== groupId) {
            isGroupStyle = false;
          }
        });

        return isGroupStyle;
      }

      return false;
    },
  },
  methods: {
    ...mapMutations(['setIsTextEditing', 'setShowCrop']),
    ...mapMutations('canvasElements', [
      'updateCanvasElementFontSize',
      'emptyActiveElements',
      'addElementToCanvas',
      'updateCanvasElementOriginalSize',
      'addActiveElements',
      'updateCroppedElement',
    ]),
    ...mapActions('canvasHistory', [
      'catchHistory',
    ]),
    allowEnterEvent(event) {
      return event.target.tagName !== 'INPUT'
      && event.target.tagName !== 'TEXTAREA'
      && event.target.getAttribute('contenteditable') !== 'true'
      && !this.isTextEditing;
    },
    fileExt(url) {
      return url.split('.').pop();
    },
    updateRatio() {
      const width = this.parentRect.w;
      const { fontSize } = this.item.data;
      const ratio = parseFloat((fontSize / width).toFixed(3));
      this.ratio = ratio;
      // console.log('!! update ratio !! ', ratio, this.item.data.id)
    },
    rotateElement(angle) {
      this.catchHistory('element');

      const offsetX = this.originalRect.x - (this.originalRect.w / 2);
      const offsetY = this.originalRect.y - (this.originalRect.h / 2);

      const rectX = this.originalRect.x - offsetX;
      const rectY = this.originalRect.y - offsetY;

      const rotate = this.percentageObject.angle + this.angle;

      let relative = {
        x: this.percentageObject.x * rectX,
        y: this.percentageObject.y * rectY,
      };

      if (angle !== 0) {
        const radAngle = Vector.rad(angle);
        relative = new Vector(relative.x, relative.y).rotate(radAngle);
      }

      const x = relative.x + rectX + offsetX;
      const y = relative.y + rectY + offsetY;

      // console.log('isChanging', this.isChanging)
      const position = {
        id: this.item.data.id,
        position: {
          x: x / this.getCanvasZoom,
          y: y / this.getCanvasZoom,
        },
        rotate,
      };
      // console.log('rotate', rotate, val.x)
      this.updateCanvasElementRect(position);
    },
    handleDrop(data, e) {
      console.log('handleDrop');
      if (!data) return;
      const isMask = e?.path?.findIndex(path => path.className && typeof path.className === 'string' && path.className.indexOf('is-mask') > -1) > -1;
      const { elementData } = data;

      if (!elementData) return;
      if (isMask && elementData.isImage) {
        // if it's an image and user drop on mask, let MaskElement handle it
        this.$root.$emit('image-drop-on-mask', {
          data,
          id: this.item.data.id,
        });
      } else if (elementData.type !== 'text') {
        // add to canvas when user drag
        elementData.data.id = randomId();
        this.emptyActiveElements();
        this.addElementToCanvas(elementData);
      }
    },
    doubleClickResizer() {
      this.emptyActiveElements();
      this.addActiveElements(this.item.data.id);

      if (this.isCroppable) {
        this.setShowCrop(true);
        this.updateCroppedElement(this.getActiveElements[0]);
        // pause temp audio and canvas when opening crop
        this.$root.$emit('pause-all-audio');
      }
    },
    doubleTapResizer() {
      const now = new Date().getTime();
      const timesince = now - this.tapTimeStamp;

      if ((timesince < 600) && (timesince > 0)) {
        // double tap
        if (this.isText) this.setIsTextEditing(true);
      }

      this.tapTimeStamp = new Date().getTime();
    },
    updateRect() {
      // console.log('parentRect', this.parentRect, this.isChanging);
      if (this.isChanging) {
        this.catchHistory('element');

        const borderRadius = this.percentageObject.borderRadius * this.parentRect.w / this.getCanvasZoom;
        const width = this.percentageObject.width * this.parentRect.w / this.getCanvasZoom;
        const height = this.percentageObject.height * this.parentRect.h / this.getCanvasZoom;
        const image = {};

        if (this.isMiddleStick && this.isCroppable) {
          const intToPercentage = val => `${val}%`;
          const parseAsFixedFloat = val => val; // parseFloat(val.toFixed(3));
          const { originalSize, tempSize } = this;

          if (this.stick === 'tm') {
            console.debug('tm changes', { width, height });
            const originalHeight = originalSize.height.value / (originalSize.height.multiplier / 100);
            const originalHeightWithTop = originalHeight - originalSize.top.value;
            const hasHeightMultiplier = originalSize.height.multiplier > 100;
            if (height > originalHeightWithTop) {
              // console.log('newHeight is bigger than original height', hasHeightMultiplier);
              const diffHeight = height - this.prevSetHeight;
              const diffWidth = diffHeight * (
                hasHeightMultiplier
                  ? (originalSize.width.value / originalHeight)
                  : this.item.data.aspectRatio.wh
              );
              const expectedWholeWidth = originalSize.width.value + diffWidth;
              const expectedWidthMultiplier = parseAsFixedFloat(
                ((expectedWholeWidth / width) * 100),
              );
              const expectedHeightMultiplier = hasHeightMultiplier ? tempSize.height.multiplier : 100;
              const expectedTopMultiplier = 0;
              const expectedLeftSize = originalSize.left.value - (diffWidth / 2);
              const expectedLeftMultiplier = (expectedLeftSize / width) * 100;
              image.top = expectedTopMultiplier;
              image.left = expectedLeftMultiplier;
              image.width = expectedWidthMultiplier;
              image.height = expectedHeightMultiplier;
            } else {
              // console.log('newHeight is smaller than original height');
              const expectedHeightMultiplier = (originalSize.height.value / height) * 100;
              const diffHeight = height - originalHeight;
              const expectedTopSize = originalSize.top.value + diffHeight;
              const expectedTopMultiplier = (expectedTopSize / height) * 100;
              this.prevSetHeight = height;

              image.top = expectedTopMultiplier;
              image.left = originalSize.left.multiplier;
              image.width = originalSize.width.multiplier;
              image.height = expectedHeightMultiplier;
            }
          } else if (this.stick === 'mr') {
            // console.debug('mr changes', { width, height, originalSize });
            const absoluteOriginalWidth = originalSize.width.value + originalSize.left.value;
            const hasLeft = originalSize.left.value !== 0;
            if (width > absoluteOriginalWidth) {
              // console.log('newWidth is bigger than original width');
              const diffWidth = width - this.prevSetWidth;
              const diffHeight = diffWidth * (
                hasLeft
                  ? (originalSize.height.value / absoluteOriginalWidth)
                  : this.item.data.aspectRatio.hw
              );
              const expectedWholeHeight = originalSize.height.value + diffHeight;
              const expectedHeightMultiplier = parseAsFixedFloat(
                ((expectedWholeHeight / tempSize.height.value) * 100),
              );
              const expectedWidthMultiplier = hasLeft
                ? tempSize.width.multiplier
                : parseAsFixedFloat((((width / tempSize.width.value)) * 100));
              // const expectedLeftMultiplier = (originalSize.left.value / width) * 100;
              const expectedTopSize = originalSize.top.value - (diffHeight / 2);
              const expectedTopMultiplier = (expectedTopSize / height) * 100;

              image.top = expectedTopMultiplier;
              // image.left = expectedLeftMultiplier;
              image.width = expectedWidthMultiplier;
              image.height = expectedHeightMultiplier;
            } else {
              // console.log('newWidth is smaller than original width');
              const expectedWidthMultiplier = parseAsFixedFloat(
                ((originalSize.width.value / width) * 100),
              );
              const expectedLeft = (originalSize.left.value / width) * 100;
              this.prevSetWidth = hasLeft ? absoluteOriginalWidth : originalSize.width.value;

              image.top = originalSize.top.multiplier;
              image.left = expectedLeft;
              image.width = expectedWidthMultiplier;
              image.height = originalSize.height.multiplier;
            }
          } else if (this.stick === 'bm') {
            // console.debug('bm changes', { width, height });
            const absoluteOriginalHeight = originalSize.height.value + originalSize.top.value;
            const hasTop = originalSize.top.value !== 0;
            if (height > absoluteOriginalHeight) {
              // console.log('newHeight is bigger than original height');
              const diffHeight = height - this.prevSetHeight;
              const diffWidth = diffHeight * (
                hasTop
                  ? (originalSize.width.value / absoluteOriginalHeight)
                  : this.item.data.aspectRatio.wh
              );
              const expectedWholeWidth = originalSize.width.value + diffWidth;
              const expectedWidthMultiplier = parseAsFixedFloat(
                ((expectedWholeWidth / tempSize.width.value) * 100),
              );
              const expectedHeightMultiplier = hasTop
                ? tempSize.height.multiplier
                : parseAsFixedFloat((((height / tempSize.height.value)) * 100));
              // const expectedTopMultiplier = (originalSize.top.value / height) * 100;
              const expectedLeftSize = originalSize.left.value - (diffWidth / 2);
              const expectedLeftMultiplier = (expectedLeftSize / width) * 100;

              // image.top = expectedTopMultiplier;
              image.left = expectedLeftMultiplier;
              image.width = expectedWidthMultiplier;
              image.height = expectedHeightMultiplier;
            } else {
              // console.log('newHeight is smaller than original height');
              this.prevSetHeight = hasTop ? absoluteOriginalHeight : originalSize.height.value;
              const expectedHeightMultiplier = parseAsFixedFloat(
                ((originalSize.height.value / height) * 100),
              );
              const expectedTop = (originalSize.top.value / height) * 100;

              image.top = expectedTop;
              image.left = originalSize.left.multiplier;
              image.width = originalSize.width.multiplier;
              image.height = expectedHeightMultiplier;// - originalSize.height.subtractedMultiplier;
            }
          } else if (this.stick === 'ml') {
            // console.debug('ml changes', { width, height });
            const originalWidth = originalSize.width.value / (originalSize.width.multiplier / 100);
            const originalWidthWithLeft = originalWidth - originalSize.left.value;
            const hasWidthMultiplier = originalSize.width.multiplier > 100;
            if (width > originalWidthWithLeft) {
              const diffWidth = width - this.prevSetWidth;
              // console.log('diffHeight', diffWidth)
              // console.log('newWidth is bigger than original width', hasWidthMultiplier);
              const diffHeight = diffWidth * (
                hasWidthMultiplier
                  ? (originalSize.height.value / originalWidth)
                  : this.item.data.aspectRatio.hw
              );
              // console.log('diffHeight', diffHeight)
              const expectedWholeHeight = originalSize.height.value + diffHeight;
              const expectedHeightMultiplier = parseAsFixedFloat(
                ((expectedWholeHeight / height) * 100),
              );
              const expectedWidthMultiplier = hasWidthMultiplier ? tempSize.width.multiplier : 100;
              const expectedLeftMultiplier = 0;
              const expectedTopSize = originalSize.top.value - (diffHeight / 2);
              const expectedTopMultiplier = (expectedTopSize / height) * 100;

              image.top = expectedTopMultiplier;
              image.left = expectedLeftMultiplier;
              image.width = expectedWidthMultiplier;
              image.height = expectedHeightMultiplier;
            } else {
              // console.log('newWidth is smaller than original width');
              const expectedWidthMultiplier = (originalSize.width.value / width) * 100;
              const diffWidth = width - originalWidth;
              // console.log('diffHeight', diffWidth)
              const expectedLeftSize = originalSize.left.value + diffWidth;
              const expectedLeftMultiplier = (expectedLeftSize / width) * 100;
              this.prevSetWidth = width;

              image.top = originalSize.top.multiplier;
              image.left = expectedLeftMultiplier;
              image.width = expectedWidthMultiplier;
              image.height = originalSize.height.multiplier;
            }
          }

          console.debug('updateRect:image', {
            image,
            tempSize,
            originalSize,
          });
          this.updateTempSize({
            width: {
              value: width,
              multiplier: image.width,
            },
            height: {
              value: height,
              multiplier: image.height,
            },
          });
          if (image.width) image.width = intToPercentage(image.width);
          if (image.height) image.height = intToPercentage(image.height);
          if (image.top) image.top = intToPercentage(image.top);
          if (image.left) image.left = intToPercentage(image.left);
        }

        const offsetX = this.parentRect.x - (this.parentRect.w / 2);
        const offsetY = this.parentRect.y - (this.parentRect.h / 2);

        const rectX = this.parentRect.x - offsetX;
        const rectY = this.parentRect.y - offsetY;

        // console.log('rotate is', this.percentageObject.angle, parentRect.angle, this.item.rotate)

        const rotate = this.percentageObject.angle + this.parentRect.angle;

        let relative = {
          x: this.percentageObject.x * rectX,
          y: this.percentageObject.y * rectY,
        };

        if (this.parentRect.angle !== 0) {
          const radAngle = Vector.rad(this.parentRect.angle);
          relative = new Vector(relative.x, relative.y).rotate(radAngle);
        }

        const x = relative.x + rectX + offsetX;
        const y = relative.y + rectY + offsetY;

        // console.log('isChanging', this.isChanging)
        const position = {
          id: this.item.data.id,
          size: {
            width,
            height,
          },
          position: {
            x: x / this.getCanvasZoom,
            y: y / this.getCanvasZoom,
          },
          rotate,
        };

        position.image = image;

        if (this.isRoundedCornerHtml
          && (this.stick === 'tl' || this.stick === 'tr' || this.stick === 'br' || this.stick === 'bl')) {
          // if it's rounded corner html and user is resizing from corner
          // resize the rounded corner as well
          position.borderRadius = borderRadius;
        }

        // console.log('rotate', rotate, this.parentRect.x);
        this.updateCanvasElementRect(position); // we still need this for parent to be reactive

        if (this.isText && this.isResizing && this.stick !== 'ml' && this.stick !== 'mr') {
          let newFontSize = parseFloat(parseFloat(this.ratio * this.parentRect.w).toFixed(3) - 0.07);
          const MIN_FONT_SIZE = 12;

          if (newFontSize < MIN_FONT_SIZE) newFontSize = MIN_FONT_SIZE;

          newFontSize = Math.floor(newFontSize);

          // font size should be a round number
          this.updateCanvasElementFontSize({
            id: this.item.data.id,
            fontSize: newFontSize,
          });

          this.item.data.fontSize = newFontSize;
        }
      }
    },
    updateTempSize(paramSize = null) {
      let size = paramSize;
      if (!size) {
        const percentageToInt = percentage => parseInt(percentage, 10);
        size = {
          width: {
            value: this.item.size.width,
            multiplier: percentageToInt(this.item.data.image.width),
          },
          height: {
            value: this.item.size.height,
            multiplier: percentageToInt(this.item.data.image.height),
          },
        };
      }
      this.tempSize = size;
    },
    updateOriginalSize() {
      this.originalSize = neutralize(this.computedOriginalSize);
    },
    updateDependenciesToKeepAspectRatio() {
      if (this.isCroppable) {
        this.updateTempSize();
        this.updateOriginalSize();
      }
    },
    editText() {
      if (this.getActiveElements.length === 1 && this.isText) this.setIsTextEditing(true);
    },
  },
  mounted() {
    this.updateDependenciesToKeepAspectRatio();

    this.prevSetHeight = this.percentageObject.height * this.parentRect.h / this.getCanvasZoom;
    this.prevSetWidth = this.percentageObject.width * this.parentRect.w / this.getCanvasZoom;
  },
  watch: {
    isResizing(val) {
      // console.log('isResizing', val)
      if (this.isText) {
        // set the ratio
        this.updateRatio();
        this.$root.$emit('canvasElementSizeUpdated');
      }
      if (!val) {
        this.prevSetHeight = this.percentageObject.height * this.parentRect.h / this.getCanvasZoom;
        this.prevSetWidth = this.percentageObject.width * this.parentRect.w / this.getCanvasZoom;
      }
    },
    angle(val) {
      this.rotateElement(val);
    },
    'parentRect.x': {
      handler() {
        this.updateRect();
      },
    },
    'parentRect.y': {
      handler() {
        this.updateRect();
      },
    },
    'parentRect.w': {
      handler() {
        this.updateRect();
      },
    },
    'parentRect.h': {
      handler() {
        this.updateRect();
      },
    },
    'parentRect.angle': {
      handler() {
        this.updateRect();
      },
    },
    computedOriginalSize: {
      handler() {
        if (!this.isResizing) {
          this.updateDependenciesToKeepAspectRatio();
        }
      },
    },
    isCropping(val) {
      if (!val) {
        this.updateDependenciesToKeepAspectRatio();
      }
    },
  },
};
</script>

<style lang="scss">
.resizer-element {
  position: absolute;

  &:not(.group-style) {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      outline: 1px solid $lightBlue;
    }
  }

  .text-content {
    // color: red !important;
    // background: red!important;
    opacity: 0 !important;
    pointer-events: none;
    white-space: pre-wrap;
  }
}
</style>
