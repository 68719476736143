<template lang="pug">
.brand-tab-logo(:class="{'tab-logo--extra-space': isWatermarkEnabled}")
  BasePreloader(
    v-if="isLoading"
    :type="12"
  )
  template(v-else)
    .brand-tab-logo__options(
      v-if="showFileOptions"
      :style="styleObject"
      @mouseenter="isHoveringOptions = true"
      @mouseleave="closeFileOptions(true)"
    )
      button(
        v-if="isNotWatermark"
        type="button"
        @click="setAsWatermark"
      ) Set as watermark
      button(
        v-else
        type="button"
        @click="removeAsWatermark"
      ) Remove as watermark
      button.btn-delete(
        type="button"
        @click="deleteTeamLogo"
      ) Delete

    .brand-tab-logo__container
      .brand-tab-logo__content
        .brand-tab-logo__upload-container(v-if="!isTeamMember")
          button.brand-tab-logo__upload(type="button")
            span.content
              i.icon.icon-plus
              span Upload logo

          input.brand-tab-logo__upload-btn(
            type="file"
            accept="image/*"
            @change="uploadTeamLogo"
          )

        BrandTabLogoItem(
          v-for="logo in getTeamLogos"
          :key="logo.id"
          :id="logo.id"
          :file-name="logo.fileName"
          :preview-url="logo.preview_url"
          :url="logo.file"
          :ref="`logo-${logo.id}`"
          @toggle-file-options="openFileOptions($event, `logo-${logo.id}`)"
        )

    BrandTabLogoWatermark(
      @open-colorpicker="openWatermarkColorPicker"
    )
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'; // eslint-disable-line
import { getImageSize } from '@/assets/scripts/utilities';

import BrandTabLogoItem from '@/components/ProjectCanvas/AssetsSidebar/children/BrandTabLogoItem.vue';
import BrandTabLogoWatermark from '@/components/ProjectCanvas/AssetsSidebar/children/BrandTabLogoWatermark.vue';

export default {
  name: 'BrandTabLogo',
  components: {
    BrandTabLogoItem,
    BrandTabLogoWatermark,
  },
  props: {
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      showFileOptions: false,
      isHoveringOptions: false,
      hoveredOptionsTop: 0,
      hoveredOptionsLeft: 0,
      hoveredId: 0,
      hoveredUrl: '',
      isWatermark: false,
    };
  },
  computed: {
    ...mapState('team', ['teamBrandIndividualLoadingType']),
    ...mapGetters('team', ['isInTeamWorkspace', 'getTeamLogos', 'isTeamMember']),
    ...mapGetters('canvasElements', ['getWatermark', 'getCanvasSize']),
    styleObject() {
      const styleObject = {};

      styleObject.top = `${this.hoveredOptionsTop}px`;
      styleObject.left = `${this.hoveredOptionsLeft}px`;

      return styleObject;
    },
    isNotWatermark() {
      return this.hoveredUrl !== this.getWatermark.url;
    },
    isWatermarkEnabled() {
      return this.getWatermark.isEnabled && !this.isTeamMember;
    },
  },
  methods: {
    ...mapActions('team', ['addTeamBrandLogo']),
    ...mapActions('assetsSidebar', ['promptConfirmDeleteUserFile']),
    ...mapMutations('canvasElements', ['setWatermark']),
    uploadTeamLogo(event) {
      this.addTeamBrandLogo(event.target.files[0]);
    },
    deleteTeamLogo() {
      const isSameAsCurrentWatermark = this.hoveredUrl === this.getWatermark.url;
      if (isSameAsCurrentWatermark) this.removeAsWatermark();

      this.promptConfirmDeleteUserFile({
        itemId: this.hoveredId,
        isTeamBrandLogo: this.isInTeamWorkspace,
      });
    },
    openFileOptions(emitData, ref) {
      const { url, id } = emitData;

      // // console.log('elementData',elementData);
      const clientRect = this.$refs[ref][0].$el.getBoundingClientRect();
      // console.log('ref', ref, clientRect);

      this.hoveredOptionsTop = clientRect.top - document.getElementById('main-header').getBoundingClientRect().height;
      this.hoveredOptionsLeft = clientRect.right - document.querySelector('.assets-container .assets-navigation').getBoundingClientRect().width;

      this.hoveredId = id;
      this.hoveredUrl = url;

      this.showFileOptions = true;
    },
    closeFileOptions() {
      this.showFileOptions = false;
    },
    async setAsWatermark() {
      const url = this.hoveredUrl;

      await getImageSize(url)
        .then((image) => {
          const { width, height } = image;

          let newWidth = width;
          let newHeight = height;
          const ratio = newWidth / newHeight;

          const maxWidth = 0.75 * this.getCanvasSize.width;
          const maxHeight = 0.75 * this.getCanvasSize.height;

          if (newWidth > maxWidth) {
            newWidth = maxWidth;
            newHeight = newWidth / ratio;
          }

          if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = newHeight * ratio;
          }

          this.setWatermark({
            url: this.hoveredUrl,
            size: {
              width: newWidth,
              height: newHeight,
            },
          });
        });
    },
    removeAsWatermark() {
      this.setWatermark({ url: '' });
    },
    openWatermarkColorPicker() {
      this.$emit('open-colorpicker');
    },
  },
};
</script>

<style lang="scss">
.brand-tab-logo {
  overflow: auto;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;

  &.tab-logo--extra-space {
    padding-right: 10px; // for padding with scroller
  }

  .preloader {
    height: 100%;
  }

 .brand-tab-logo__upload-container {
    position: relative;

    ::-webkit-file-upload-button {
      cursor: pointer;
    }

    .brand-tab-logo__upload {
      background: $darkGrey600;
      border-radius: $componentBorderRadius;
      display: flex;
      color: $light;
      position: relative;
      font-size: 0.75rem;
      min-height: 100px;
      width: 100%;
      height: 100%;
      transition: background 0.3s cubic-bezier(0, 0, 0, 1);

      &:hover {
        background: $darkGrey500;
      }

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      .icon {
        font-size: 1.5rem;
        margin-bottom: 5px;
      }

      .content {
        position: absolute;
        display: flex;
        top: 50%;
        left: 50%;
        width: 100%;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-direction: column;
        transform: translate(-50%, -50%);
      }
    }

    .brand-tab-logo__upload-btn {
      cursor: pointer;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 3;
      opacity: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.brand-tab-logo__options {
  position: absolute;
  padding: 10px;
  width: 100%;
  max-width: 275px;
  border-radius: 4px;
  width: calc(100% - 8px);
  background: var(--white-darkgrey600);
  z-index: 25;
  box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.1), 0px 26px 26px rgba(10, 31, 68, 0.12);

  button {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    font-size: 0.875rem;
    padding: 10px;
    font-weight: 500;
    margin: 0;
    color: var(--darkgrey400-white);

    &.btn-delete {
      color: $red;
    }

    &:hover {
      background: var(--lightgrey200-sidebardarkgrey);
    }
  }
}

.brand-tab-logo__container {
  overflow: auto;
  flex: 1 0 20%;
  min-height: 0;
}

.brand-tab-logo__content {
  display: flex;
  flex-wrap: wrap;

  & > * {
    width: calc(50% - 10px);
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 10px 10px 0;

    &:nth-child(2n) {
      margin: 0 0 10px;
    }
  }
}

.brand-tab-logo__watermark {
  margin-top: 20px;
  flex: 0 0 auto;

  .watermark-btn {
    width: 100%;
    justify-content: space-between;
  }
}
</style>
