<template lang="pug">
.smart-guides
  template(v-for="guide in lines")
    SmartGuidesLine(
      :guide="guide"
    )
</template>

<script>
// import { mapGetters } from 'vuex';
import SmartGuidesLine from './SmartGuidesLine.vue';

export default {
  name: 'SmartGuides',
  components: {
    SmartGuidesLine,
  },
  props: {
    lines: { type: Array, default: () => [] },
  },
  computed: {
    // ...mapGetters('canvasElements', [
    //   'getSmartGuides',
    // ]),
  },
};
</script>

<style lang="scss">
.smart-guides {
  pointer-events: none !important;
}
</style>
