<template lang="pug">
div.color-tab(
  :class="colorTabClass"
  :style="colorTabStyle"
)
  BaseButton.color-tab__back-button(
    v-if="showEditTab"
    :is-canvas="true"
    :is-hollow="true"
    @click="closeColorTab"
  )
    i.icon-to-left

  div.search-color__container
    input.input(
      autocomplete="off"
      type="text"
      placeholder="Search..."
      v-model="searchColorInput"
      @keyup.enter="doSearchColor()"
    )
    div.btn-actions
      button.btn-search-clear(
        type="button"
        v-if="searchColorInput != ''"
        @click="searchColorInput = ''"
      )
        i.icon.icon-cross-line
      button.btn-search(type="button" @click="doSearchColor()")
        i.icon.icon-search

  SubGroups(v-if="isBackgroundColour")

  div.color-tab__accordion-list(v-if="!showSearchResultList")
    div.color-tab__accordion-container(
      :class="{'accordion--low-opacity': isShowBrandPaletteDropdown}"
    )
      BaseButton.accordion__toggle(
        is-tall=true
        :icon-after="showProjectColour ? 'icon-up-arrow' : 'icon-down-arrow'"
        @click="showProjectColour = !showProjectColour"
      ) Project

      .accordion__content(:class="{'is-show': showProjectColour}")
        .color__item(data-type='project')
          .color__content
            .btn-add-color__container
              div.playing-button__background-container(
                :class="{'container--show': showColorPicker}"
              )
                Lottie.playing-button__background(
                  :width="lottieAnimationSize.width"
                  :height="lottieAnimationSize.height"
                  :options="playingMusicAnimationOptions"
                )
              button.color__button.btn-add-color(
                type='button'
                @click="toggleColorPicker"
              )
            template(v-for="(color, index) in getProjectColors.solid")
              ColorButton(
                :color="color"
                :index="index"
                category="solid"
                isProjectColor=true
              )
            template(v-if="showGradientColor")
              template(v-for="(color, index) in getProjectColors.gradient")
                ColorButton(
                  :color="color"
                  :index="index"
                  category="gradient"
                  isProjectColor=true
                )


    div.color-tab__accordion-container.accordion--brand(
      v-if="getNonEmptyColorBrand.length"
      :class="{'accordion--low-opacity': showColorPicker}"
    )
      BaseButton.accordion__toggle(
        is-tall=true
        :icon-after="showBrandPaletteColour ? 'icon-up-arrow' : 'icon-down-arrow'"
        @click="showBrandPaletteColour = !showBrandPaletteColour"
      ) {{personalPaletteText}}

      .accordion__content(:class="{'is-show': showBrandPaletteColour}")
        .color__item(data-type='brand')
          BrandPaletteDropdown.accordion-dropdown(
            v-if="getNonEmptyColorBrand.length"
            :is-active="isShowBrandPaletteDropdown"
            :options="getNonEmptyColorBrandNames"
            @change="setSelectedColorBrandName"
            @toggle-dropdown="isShowBrandPaletteDropdown = !isShowBrandPaletteDropdown"
            @close-dropdown="isShowBrandPaletteDropdown = false"
          )
          .color__content(v-if="selectedColorBrandName === 'branded-designs' && !hasBrandedDesignColors")
            BaseButton.setup-brand__button(
              :is-primary="true"
              :is-full-width="true"
              :is-canvas="true"
              :href="brandSetupUrl"
            ) Setup Brand

          .color__content(v-else)
            template(v-for="color in getSelectedBrandColors")
              template(v-if="hasColor(color)")
                ColorButton(
                  :color="color"
                )


    div.color-tab__accordion-container.accordion--team-brand(
      v-if="isInTeamWorkspace && getNonEmptyTeamColorBrand.length"
      :class="{'accordion--low-opacity': showColorPicker}"
    )
        BaseButton.accordion__toggle(
          is-tall=true
          :icon-after="showTeamBrandPaletteColour ? 'icon-up-arrow' : 'icon-down-arrow'"
          @click="showTeamBrandPaletteColour = !showTeamBrandPaletteColour"
        ) Team Palette

        .accordion__content(:class="{'is-show': showTeamBrandPaletteColour}")
          .color__item(data-type='brand')
            BrandPaletteDropdown.accordion-dropdown(
              v-if="getNonEmptyTeamColorBrand.length"
              :is-active="isShowTeamBrandPaletteDropdown"
              :options="getNonEmptyTeamColorBrandNames"
              :has-branded-designs="false"
              @change="setSelectedTeamColorBrandName"
              @toggle-dropdown="isShowTeamBrandPaletteDropdown = !isShowTeamBrandPaletteDropdown"
              @close-dropdown="isShowTeamBrandPaletteDropdown = false"
            )
            .color__content
              template(v-for="color in getSelectedTeamBrandColors")
                template(v-if="hasColor(color)")
                  ColorButton(
                    :color="color"
                  )

    div.color-tab__accordion-container(
      :class="{'accordion--low-opacity': showColorPicker}"
    )
      BaseButton.accordion__toggle(
        is-tall=true
        :icon-after="showDefaultColour ? 'icon-up-arrow' : 'icon-down-arrow'"
        @click="showDefaultColour = !showDefaultColour"
      ) Presets

      .accordion__content(:class="{'is-show': showDefaultColour}")
        .color__item(data-type='default')
          .color__content
            template(v-for="item in getColorSolid")
              ColorButton(
                :color="item"
              )
            template(v-if="showGradientColor")
              template(v-for="item in getColorGradient")
                ColorButton(
                  :color="item"
                )

  SearchColorResultList(
    v-else
    :show-search-result-list="showSearchResultList"
    :show-loader="showLoader"
    :color-palettes="colorPalettes"
    @do-add-palette="doAddPalette"
    @do-remove-palette="doRemovePalette"
  )

  .overlay(
    v-if="showColorPicker || isShowBrandPaletteDropdown"
  )

  ColorPicker(
    :color="textColor()"
    :element-id="elementId"
    :is-from-color-tab="true"
    @close="closeColorPicker"
    ref="colorPicker"
  )
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import vClickOutside from 'v-click-outside';
import { isEmptyObject, checkIsCollapseSidebarButton, checkIsColorTabTriggerButton } from '@/assets/scripts/utilities';
import { groups, subgroups } from '@/assets/scripts/enums';
import CollectionsApi from '@/services/api/CollectionsApi';
import rotatingColorWheelJson from '@/assets/data/rotating-color-wheel.json';

import Lottie from '@/components/ProjectCanvas/AssetSidebarCanvas/LottieShared.vue';
import ColorButton from './children/ColorButton.vue';
import ColorPicker from './children/ColorPicker.vue';
import BrandPaletteDropdown from './children/BrandPaletteDropdown.vue';
import SearchColorResultList from './children/SearchColorResultList.vue';
import SubGroups from '@/components/ProjectCanvas/AssetsSidebar/SubGroups.vue'; // eslint-disable-line

export default {
  name: 'ColorTab',
  components: {
    Lottie,
    ColorButton,
    ColorPicker,
    BrandPaletteDropdown,
    SearchColorResultList,
    SubGroups,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showProjectColour: true,
      showBrandPaletteColour: false,
      showTeamBrandPaletteColour: false,
      showDefaultColour: false,

      showSearchResultList: false,
      showLoader: false,
      isColorShow: true,
      isBigDesktopSize: false,
      isShowBrandPaletteDropdown: false,
      isShowTeamBrandPaletteDropdown: false,

      selectedColorBrandName: '',
      selectedTeamColorBrandName: '',

      elementId: '',
      searchColorInput: '',
      colorPalettes: [],
    };
  },
  methods: {
    ...mapMutations(['setShowColorTab', 'setShowColorPicker', 'setShowSidebar']),
    ...mapMutations('canvasElements', ['updateActiveColorId', 'addSingleActiveElement', 'emptyActiveElements']),
    ...mapMutations('colorPreset', ['addColorBrand']),
    closeColorTab(event) {
      const paths = event.path || event.composedPath();

      const isCollapseSidebarButton = checkIsCollapseSidebarButton(paths);
      const isColorTabTriggerButton = checkIsColorTabTriggerButton(paths);

      if (isColorTabTriggerButton) return;
      if (this.showTimeline && isCollapseSidebarButton) this.setShowSidebar(false);
      if (this.getActiveColorId !== 'background') this.setShowColorTab(false);
    },
    hasColor(item) {
      return !isEmptyObject(item);
    },
    closeColorPicker(e) {
      if (this.showColorPicker && !e) {
        // console.debug('closeColorPicker:ColorTab', e);
        this.setShowColorPicker(false);
      }
    },
    toggleColorPicker(e) {
      e.stopPropagation();
      e.preventDefault();

      let elementId = '';

      if (!this.showColorPicker) {
        // check if the first layer is a background
        const isBackgroundShape = this.getLastCanvasElement && !!this.getLastCanvasElement.data.background && !!this.getLastCanvasElement.data.shape;

        // if opening color picker
        if (this.getActiveElements.length) {
          // if it's background color tab
          // and it has more than one active elements
          // or it only has 1 element and it's not the background layer
          // empty the active elements
          if (this.isBackgroundColour
            && (this.getActiveElements.length > 1
              || (isBackgroundShape && this.getActiveElements[0].data.id !== this.getLastCanvasElement.data.id)
            )) {
            // if we are opening sidebar and it's for background, empty the active elements
            this.emptyActiveElements();
          } else {
            elementId = this.getActiveElements[0] && this.getActiveElements[0].data.id;
          }
        }

        if (this.isBackgroundColour && isBackgroundShape) {
          elementId = this.getLastCanvasElement.data.id;
          this.addSingleActiveElement(elementId);
        }
      }
      this.elementId = elementId;

      this.setShowColorPicker(!this.showColorPicker);
    },
    textColor() {
      let color = '#000';
      if (this.getActiveElements.length && this.getActiveElements[0] === 'texts') {
        // eslint-disable-next-line
        color = this.getActiveElements[0].data.color[0].color;
      }
      return color;
    },
    doSearchColor() {
      this.showLoader = true;
      this.showSearchResultList = true;

      CollectionsApi.getItems('stocks', {
        search: this.searchColorInput,
        type: 'colors',
      })
        .then((response) => {
          this.colorPalettes = response.data.results;
          this.showLoader = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    doAddPalette(index) {
      const colorBrand = {
        name: this.colorPalettes[index].palette_name,
        colors: this.colorPalettes[index].colors,
      };

      this.addColorBrand(colorBrand);
    },
    doRemovePalette(index) {
      this.colorPalettes.splice(index, 1);
    },
    setSelectedColorBrandName(value) {
      this.selectedColorBrandName = value;
    },
    setSelectedTeamColorBrandName(value) {
      this.selectedTeamColorBrandName = value;
    },
    setupBrandDropdownName() {
      if (this.getNonEmptyColorBrand.length) {
        this.selectedColorBrandName = this.getNonEmptyColorBrand[0].name;
      }

      if (this.isInTeamWorkspace && this.getNonEmptyTeamColorBrand.length) {
        this.selectedTeamColorBrandName = this.getNonEmptyTeamColorBrand[0].name;
      }
    },
  },
  computed: {
    ...mapState([
      'showSidebar',
      'showColorTab',
      'showColorPicker',
      'showEditTab',
      'showTimeline',
    ]),
    ...mapGetters(['isTextShadow', 'getIsTemplate']),
    ...mapGetters('assetsSidebar', [
      'getBrandedDesignColors',
      'getActiveGroup',
      'getActiveSubGroup',
    ]),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getLastCanvasElement',
      'getProjectColors',
      'getActiveColorId',
      'getProjectId',
    ]),
    ...mapGetters('colorPreset', ['getColorBrand', 'getColorSolid', 'getColorGradient', 'getColorTeamBrand']),
    ...mapGetters('brand', ['getUserBrand', 'hasSetupBrand']),
    ...mapGetters('team', ['isInTeamWorkspace']),
    showGradientColor() {
      if (this.getActiveElements.length === 0) return true; // if this is background color
      if (this.isText && this.isTextShadow) return false; // if this is text
      if (this.getActiveElements[0].animated) {
        // when animated check if it is a gradient
        if (this.getActiveColorId && this.getActiveColorId.startsWith('#gradient')) return true;
        return false;
      }
      if (this.getActiveColorId && this.getActiveColorId.substring(6, 0) === 'stroke') return false;
      return true;
    },
    isText() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type !== 'texts') return false;
      if (this.getActiveElements[0].type === 'texts') return true;
      return false;
    },
    personalPaletteText() {
      return this.isInTeamWorkspace ? 'Personal Palette' : 'Brand Palette';
    },
    getNonEmptyColorBrand() {
      return this.getColorBrand.filter(color => color.colors.length);
    },
    getNonEmptyColorBrandNames() {
      const formattedArray = this.getNonEmptyColorBrand.map(brand => ({
        name: brand.name,
        value: brand.name,
      }));

      return formattedArray;
    },
    getSelectedBrandColors() {
      if (this.selectedColorBrandName === 'branded-designs') return this.brandedDesignColors || [];

      const selectedBrand = this.getNonEmptyColorBrand.find(
        brand => brand.name === this.selectedColorBrandName,
      );
      return (selectedBrand && selectedBrand.colors) || [];
    },
    colorTabClass() {
      const cssClass = [];
      if (this.showColorTab || (this.showSidebar && this.isBackgroundColour)) cssClass.push('is-open');
      if (this.showColorPicker || this.isShowBrandPaletteDropdown) cssClass.push('no-scroll');

      return cssClass.join(' ');
    },
    colorTabStyle() {
      const styleObject = {};
      styleObject.overflowY = this.showColorPicker ? 'unset' : 'auto';
      return styleObject;
    },
    hasBrandedDesignColors() {
      return this.getIsTemplate || this.hasSetupBrand;
    },
    brandedDesignColors() {
      if (this.getIsTemplate) {
        return this.getBrandedDesignColors;
      }
      if (this.getUserBrand.brandMeta.color) {
        return this.getUserBrand.brandMeta.color;
      }
      return JSON.parse(this.getUserBrand.brandMeta.find(meta => meta.key === 'color').value);
    },
    playingMusicAnimationOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: rotatingColorWheelJson,
      };
    },
    brandSetupUrl() {
      return `${process.env.VUE_APP_DASHBOARD_URL}/brand-setup?return=${this.getProjectId}`;
    },
    lottieAnimationSize() {
      return {
        width: this.isBigDesktopSize ? 93 : 70,
        height: this.isBigDesktopSize ? 93 : 70,
      };
    },
    isBackgroundColour() {
      return this.getActiveGroup === groups.BACKGROUNDS && this.getActiveSubGroup === subgroups.COLOUR;
    },
    getNonEmptyTeamColorBrand() {
      return this.getColorTeamBrand.filter(color => color.colors.length);
    },
    getNonEmptyTeamColorBrandNames() {
      const formattedArray = this.getNonEmptyTeamColorBrand.map(brand => ({
        name: brand.name,
        value: brand.name,
      }));

      return formattedArray;
    },
    getSelectedTeamBrandColors() {
      const selectedBrand = this.getNonEmptyTeamColorBrand.find(
        brand => brand.name === this.selectedTeamColorBrandName,
      );
      return (selectedBrand && selectedBrand.colors) || [];
    },
  },
  created() {
    if (window && window.innerWidth > 1600) {
      this.isBigDesktopSize = true; // since lottie can't use css to define animation json width & height
    }
  },
  mounted() {
    this.setupBrandDropdownName();
  },
  watch: {
    showColorTab(val) {
      console.log('-showColorTab changed', val);
      if (val && this.isBackgroundColour) {
        // if we are opening sidebar and it's for background, empty the active elements
        this.emptyActiveElements();
      }
      if (val) {
        this.isColorShow = true;
      } else if (!this.isBackgroundColour) {
        this.updateActiveColorId(null);
      }
    },
    searchColorInput(val) {
      if (!val) {
        this.showSearchResultList = false;
      }
    },
  },
};
</script>

<style lang="scss">
.color-tab {
  background: $darkBg2;
  height: 100%;
  padding: 10px 30px 10px;
  position: absolute;
  left: 72px;
  top: 0;
  width: 361px;
  pointer-events: none;
  z-index: 5;
  overflow-y: auto;

  @include smallest {
    width: 270px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @include fb-requirement {
    width: 240px;
  }

  &.is-open {
    pointer-events: auto;
  }

  &.no-scroll {
    overflow: hidden !important;
  }

  .slider.is-disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &.is-shadow {
    .text-shadow {
      display: block;
    }
  }

  &.is-background {
    .text-background {
      display: block;
    }
  }

  .color-tab__accordion-list {
    position: relative;
    z-index: 10;
  }

  .overlay {
    position: absolute;
    width: calc(100% + 5px);
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0.7;
    background-color: $darkGrey800;
    z-index: 6;
  }

  .color-tab__back-button {
    color: $light;
    font-size: 0.625rem;
    margin: 5px 0 12px;
    border-color: $light;
    padding: 7px;

    &:hover {
      background: $light;
      color: $darkGrey;
    }
  }
}

.colorpicker {
  &.is-active ~ * {
    // make sure the rest is not clickable when colorpicker is opened
    pointer-events: none;
  }
}

.color-tab__input {
  width: 30px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;

  input[type='number'],
  input[type='text'] {
    width: 35px;
    background: transparent;
    color: $darkGrey;
    text-align: left;
    padding: 0 0 2px;
    font-size: 1em;
    border-bottom: 1px solid $lightGrey;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.color-tab__label {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;

  span {
    font-size: 0.75rem;
    line-height: 1.4;
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;

    & + .text {
      &::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        border: 1px solid $darkGrey;
        margin-right: 5px;
        vertical-align: middle;
      }

      &::after {
        content: '\2713';
        display: none;
        position: absolute;
        left: 3px;
        top: 3px;
        color: #fff;
      }
    }

    &:checked + .text {
      &::before {
        background: $darkGrey;
      }

      &::after {
        display: inline-block;
      }
    }
  }

  .text-background__title,
  .text-shadow__title {
    display: block;
  }
}

.color-tab__input {
  width: 30px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;

  input[type='number'],
  input[type='text'] {
    width: 35px;
    background: transparent;
    color: $darkGrey;
    text-align: left;
    padding: 0 0 2px;
    font-size: 1em;
    border-bottom: 1px solid $lightGrey;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.close-colorpicker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $lightBg;
  opacity: 0.6;
  z-index: 2;
  display: none;
}

.color__item {
  margin-bottom: 20px;

  .color__button.btn-add-color {
    position: relative;
    background-image: url('~@/assets/images/general/rainbow-bg.jpg');
    background-size: cover;
    cursor: pointer;
    border: 0;
    margin: 0;

    &::before {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 80%;
      top: 50%;
      left: 50%;
      color: $darkGrey700;
      transform: translate(-50%, -50%);
      background: $light;
      content: $icon-plus;
      font-family: 'icomoon';
      z-index: 5;
      border-radius: 4px;
      transition: background 0.25s ease-in-out;
    }
  }

  .playing-button__background-container {
    width: 48px;
    height: 48px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 4px;

    @include smallest {
      width: 38px;
      height: 38px;
    }

    &.container--show {
      opacity: 1;
    }
  }

  .playing-button__background {
    position: absolute;
    top: -22px;
    left: -22px;

    @include smallest {
      top: -16px;
      left: -16px;
    }
  }

  .btn-add-color__container {
    position: relative;
    margin: 0 10px 10px 0;

    &:hover {
      .playing-button__background-container {
        opacity: 1;
      }
    }
  }
}

.color__item[data-type='brand'] {
  .color__content {
    .color__title {
      font-weight: normal;
      text-transform: none;
      margin: 4px 0;
    }
  }

  .setup-brand__button {
    display: block;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.color__title {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 0.875rem;
  color: $lightWhite;
  transition: opacity 0.25s ease-in-out;
}

.color__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .title {
    text-align: left;
    margin-bottom: 5px;
    line-height: 1.2;
  }
}

.search-color__container {
  border-radius: $componentBorderRadius;
  color: $lightGrey400;
  border: 1px solid $darkGrey700;
  background-color: $darkGrey600;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  margin-bottom: 15px;

  &:focus-within {
    border: 1px solid $lightWhite;

    .input {
      &::-webkit-input-placeholder {
        color: $lightWhite;
      }
    }

    .btn-search,
    .btn-search-clear {
      color: $lightWhite !important;
    }
  }

  .input {
    border: 0;
    border-radius: 0;
    font-size: 0.75rem;
    background-color: transparent;
    color: $lightWhite;
    display: block;
    outline: none;
    flex-grow: 1;

    &::-webkit-input-placeholder {
      color: $darkGrey100;
    }
  }

  .btn-actions {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn-search,
    .btn-search-clear {
      cursor: pointer;
      font-size: 1.125rem;
      color: $darkGrey100;
      margin-left: 5px;
      padding: 0;
    }

    .btn-search-clear {
      font-size: 0.9375rem;
    }
  }
}

.color-tab__accordion-container {
  border-bottom: 1px solid rgba($lightGrey100, 0.1);

  &.accordion--brand {
    // so the dropdown can overlap the content below them + team brand content below
    position: relative;
    z-index: 7;
  }

  &.accordion--team-brand {
    // so the dropdown can overlap the content below them
    position: relative;
    z-index: 5;
  }

  &.accordion--low-opacity {
    opacity: 0.2;
  }

  .accordion__toggle {
    padding: 15px 10px;
    background: transparent;
    font-weight: 600;
    color: $light;
    box-shadow: none;
    padding-left: 0;
    width: 100%;
    text-align: left;

    .btn__circle {
      display: none;
    }

    .btn__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .icon--after {
      margin-left: 15px;
      margin-right: 0;
      font-size: 1.25rem;
      font-weight: 200;
    }
  }

  .accordion__content {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
    height: 0;
    transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out, transform 0.3s ease-in-out;

    &.is-show {
      visibility: visible;
      opacity: 1;
      height: auto;
      transform: translateY(0);
    }
  }
}
</style>
