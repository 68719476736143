<template lang="pug">
CanvasModal.common-sizes(
  title="Common sizes"
  subtitle="Choose from all our common sizes for social media to maximise your contents"
  icon="icon-size-menu"
  :isShow="isShow"
  is-grey=true
  @closeOverlay="closeModal"
)
  .common-sizes__content
    .common-sizes__menu
      h2.title Sizes
      .menu__list
        button.menu__item(
          type="button"
          :class="{ 'is-active': index === activeIndex}"
          v-for="(size, index) in sizesArr"
          :key="index"
          @click="changeActiveSize(index)"
        ) {{ size.name }}
    .common-sizes__main
      h2.title {{ activeTitle }}
      .common-sizes__list
        CommonSizesItem(
          v-for="(size, index) in activeSizes"
          :key="index"
          v-bind="size"
          @click="clickEvent"
        )
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import CommonSizesItem from './children/CommonSizesItem.vue';

export default {
  name: 'CommonSizes',
  data() {
    return {
      isShow: true,
      sizesArr: [
        {
          name: 'Facebook',
          sizes: [
            {
              name: 'Post Image',
              width: 1440,
              height: 830,
              image: 'image-facebook-post.jpg',
            },
            {
              name: 'Cover Image',
              width: 1640,
              height: 624,
              image: 'image-facebook-cover.jpg',
            },
            {
              name: 'Carousel Ad',
              width: 1080,
              height: 1080,
              image: 'image-facebook-carousel.jpg',
            },
            {
              name: 'Image Ad',
              width: 1200,
              height: 638,
              image: 'image-facebook-ads.jpg',
            },
            {
              name: 'Event',
              width: 1920,
              height: 1080,
              image: 'image-facebook-events.jpg',
            },
            {
              name: 'Extended Post',
              width: 1080,
              height: 1350,
              image: 'image-facebook-vertical.jpg',
            },
          ],
        },
        {
          name: 'Instagram',
          sizes: [
            {
              name: 'Landscape Ad',
              width: 1200,
              height: 628,
              image: 'image-instagram-landscape.jpg',
            },
            {
              name: 'Post Image',
              width: 1080,
              height: 1080,
              image: 'image-instagram-post.jpg',
            },
            {
              name: 'Instagram Story',
              width: 1080,
              height: 1920,
              image: 'image-instagram-story.jpg',
            },
            {
              name: 'Extended Post',
              width: 1080,
              height: 1350,
              image: 'image-instagram-vertical.jpg',
            },
          ],
        },
        {
          name: 'Twitter',
          sizes: [
            {
              name: 'In-Stream',
              width: 1024,
              height: 512,
              image: 'image-twitter-instream.jpg',
            },
            {
              name: 'Cover Image',
              width: 1500,
              height: 500,
              image: 'image-twitter-cover.jpg',
            },
            {
              name: 'Website Card',
              width: 800,
              height: 418,
              image: 'image-twitter-card.jpg',
            },
          ],
        },
        {
          name: 'Pinterest',
          sizes: [
            {
              name: 'Feed Pin',
              width: 736,
              height: 1102,
              image: 'image-pinterest-feed.jpg',
            },
            {
              name: 'Promoted Pin',
              width: 600,
              height: 900,
              image: 'image-pinterest-promoted.jpg',
            },
          ],
        },
      ],
      activeIndex: 0,
    };
  },
  components: {
    CommonSizesItem,
  },
  computed: {
    activeTitle() {
      return this.sizesArr[this.activeIndex].name;
    },
    activeSizes() {
      return this.sizesArr[this.activeIndex].sizes;
    },
  },
  methods: {
    ...mapMutations([
      'setShowCommonSizes',
    ]),
    ...mapActions('canvasElements', [
      'saveProject',
      'updateCanvasSize',
    ]),
    changeActiveSize(index) {
      this.activeIndex = index;
    },
    clickEvent(size) {
      this.updateCanvasSize(size);
      this.setShowCommonSizes(false);
      this.saveProject();
    },
    closeModal() {
      this.setShowCommonSizes(false);
    },
  },
};
</script>

<style lang="scss">
.common-sizes {
  .canvas-modal__header {
    background: var(--lightgrey-darkgrey900) !important;
    color: var(--darkgrey800-white) !important;

    .icon {
      color: var(--blue700-white) !important;
    }
  }

  .canvas-modal__content {
    background: var(--lightgrey100-darkgrey700);

    .content {
      &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba($darkGrey600, 0) !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--lightgrey500-black800) !important;
        border-radius: 4px;
      }
    }
  }

  .canvas-modal__closebtn {
    color: var(--darkgrey-white) !important;
  }
}

.common-sizes__content {
  display: flex;
  text-align: left;
}

.common-sizes__menu {
  width: 150px;
  margin-right: 40px;
  flex: 1 0 auto;

  .title {
    color: var(--darkgrey800-white);
    font-size: 0.875em;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 0;
  }

  .menu__item {
    color: var(--darkgrey800-white);
    padding: 12px;
    font-size: 0.75em;
    border-top: 1px solid var(--bordergrey-darkgrey400);
    width: 100%;
    text-align: left;
  }
}

.common-sizes__main {
  flex: 90%;

  .title {
    color: var(--darkgrey800-white);
    font-size: 1.25em;
    font-weight: 500;
    margin-top: 0;
  }
}

.common-sizes__list {
  display: flex;
  flex-wrap: wrap;
  min-height: 590px;
  align-content: flex-start;
}
</style>
