<template lang="pug">
.brand-tab-font
  BasePreloader(
    v-if="isLoading"
    :type="12"
  )
  template(v-else)
    AddTextButton(
      type="heading"
      text="+ Add a brand heading"
      :font="teamBrandFontStyles.selected_h1"
    )
    AddTextButton(
      type="subheading"
      text="+ Add a sub-heading heading"
      :font="teamBrandFontStyles.selected_h2"
    )
    AddTextButton(
      v-if="teamBrandTextStyle.length"
      v-for="textStyle in teamBrandTextStyle"
      :key="textStyle.title"
      type="text"
      :text="getTeamTextStyleText(textStyle)"
      :font="textStyle"
    )

    BaseButton.font-style__button(
      v-if="!isTeamMember"
      is-hollow=true
      is-tall=true
      @click="showFontOverlay"
    ) Edit font style
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import AddTextButton from './AddTextButton.vue';

export default {
  name: 'BrandTabFont',
  components: {
    AddTextButton,
  },
  props: {
    isLoading: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('team', ['teamBrandFontStyles']),
    ...mapGetters('team', ['isTeamMember']),
    teamBrandTextStyle() {
      return this.teamBrandFontStyles.text_style;
    },
  },
  methods: {
    ...mapMutations(['setShowBrandTabFontOverlay']),
    getTeamTextStyleText(textStyle) {
      return `Add a ${textStyle.title} text`;
    },
    showFontOverlay() {
      this.setShowBrandTabFontOverlay(true);
    },
  },
};
</script>

<style lang="scss">
.brand-tab-font {
  overflow-y: auto;

  .font-style__button {
    span {
      color: $light;
    }

    &:hover {
      span {
        color: $darkGrey900;
      }
    }
  }
}
</style>
