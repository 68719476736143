<template lang="pug">
button.btn-trim-video(
  title="Trim video"
  @click="trimVideo"
)
  i.icon.icon-trim
  | Trim video

</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'VideoTrimButton',
  props: {
    id: { type: Number, default: 0 },
    url: { type: String, required: true },
  },
  methods: {
    ...mapMutations([
      'setShowPermanentTrimElement',
      'setPermanentTrimElement',
    ]),
    ...mapMutations('canvasElements', [
      'updateTrimmedElement',
    ]),
    trimVideo() {
      this.setShowPermanentTrimElement(true);
      const updatedTrimData = {
        type: 'videos',
        file_id: this.id,
        max_duration: 'full_video_duration',
        data: {
          url: this.url,
          time_start: 0,
        },
      };
      this.updateTrimmedElement(updatedTrimData);
    },
  },
};
</script>

<style lang="scss">
</style>
