<template lang="pug">
.canvas-edit-tab(
  v-click-outside="closeEditTab"
  :class="{'edit-tab--filter': isShowFilterEditTab, 'edit-tab--effects': isShowEffectsEditTab}"
)
  .edit-tab__navigation(:class="{'navigation--content-center': isContentCenterNavigation}")
    BaseButton.navigation-item(
      v-for="navigation in editTabNavigationList"
      :key="navigation.value"
      :class="{ 'is-active': activeEditTabGroup === navigation.value }"
      :is-text="true"
      :plain-hover="true"
      @click="setActiveEditNavigation(navigation.value)"
    ) {{ navigation.name }}

  template(v-if="isShowUpdateEditTab")
    EditStrokeTab(:element-stroke="elementStroke")

  template(v-if="isShowAdjustEditTab")
    AdjustTab

  template(v-if="isShowFilterEditTab")
    FilterTab

  template(v-if="isShowEffectsEditTab")
    EffectsTab(
      @set-active-effect-group="setActiveEffectGroup"
    )

  template(v-if="isShowTextEffectsEditTab")
    TextEffects(
      :active-effect-group="activeEffectGroup"
      @reset-active-effect-group="setActiveEffectGroup('')"
    )

  template(v-if="isShowMediaEffectsEditTab")
    MediaEffects(
      :active-effect-group="activeEffectGroup"
      @reset-active-effect-group="setActiveEffectGroup('')"
    )
</template>

<script>
import {
  mapState, mapGetters, mapMutations,
} from 'vuex';
import { editTabs } from '@/assets/scripts/enums';
import { checkIsCollapseSidebarButton, checkIsColorTabTriggerButton } from '@/assets/scripts/utilities';

import EditStrokeTab from './children/EditStrokeTab.vue';
import AdjustTab from '@/components/ProjectCanvas/AssetsSidebar/AdjustTab.vue';
import FilterTab from '@/components/ProjectCanvas/AssetsSidebar/FilterTab.vue';
import EffectsTab from './children/EffectsTab.vue';
import MediaEffects from '@/components/ProjectCanvas/AssetsSidebar/MediaEffects.vue';
import TextEffects from '@/components/ProjectCanvas/AssetsSidebar/TextEffects.vue';

export default {
  name: 'EditTab',
  components: {
    EditStrokeTab,
    AdjustTab,
    FilterTab,
    EffectsTab,
    TextEffects,
    MediaEffects,
  },
  data() {
    return {
      elementStroke: [],
      activeEffectGroup: '',
    };
  },
  computed: {
    ...mapState(['activeEditTabGroup', 'showColorTab', 'editTabEffectsType', 'showTimeline']),
    ...mapGetters('canvasElements', ['getActiveElements']),
    editTabNavigationList() {
      let navigationList = [
        { name: 'Adjust', value: editTabs.ADJUST },
        { name: 'Filter', value: editTabs.FILTER },
        { name: 'Effects', value: editTabs.EFFECTS },
      ];

      if (!this.showAdjustNav) {
        const navListWithoutAdjust = navigationList.filter(nav => nav.name !== 'Adjust');
        navigationList = navListWithoutAdjust;
      }

      if (!this.showFilterNav) {
        const navListWithoutFilter = navigationList.filter(nav => nav.name !== 'Filter');
        navigationList = navListWithoutFilter;
      }

      if (this.showEditNav) {
        navigationList.unshift({ name: 'Edit', value: editTabs.UPDATE });
      }

      return navigationList;
    },
    hasActiveEffectGroup() {
      return this.activeEffectGroup !== '';
    },
    isShowUpdateEditTab() {
      return this.activeEditTabGroup === editTabs.UPDATE;
    },
    isShowAdjustEditTab() {
      return this.activeEditTabGroup === editTabs.ADJUST;
    },
    isShowFilterEditTab() {
      return this.activeEditTabGroup === editTabs.FILTER;
    },
    isShowEffectsEditTab() {
      return this.activeEditTabGroup === editTabs.EFFECTS && !this.hasActiveEffectGroup;
    },
    isShowTextEffectsEditTab() {
      return this.editTabEffectsType === 'text' && this.hasActiveEffectGroup;
    },
    isShowMediaEffectsEditTab() {
      return this.editTabEffectsType === 'media' && this.hasActiveEffectGroup;
    },
    isContentCenterNavigation() {
      return this.editTabNavigationList.length !== 3;
    },

    // ------------
    // Validations
    // ------------
    showEditNav() {
      return !this.isMedia && !this.isText && this.elementHasStroke;
    },
    showAdjustNav() {
      return (this.isMedia || this.isPng) && !this.isBrandedGraphic;
    },
    showFilterNav() {
      return !this.isWebm && this.isMedia && !this.isMask && !this.isBrandedGraphic;
    },
    isWebm() {
      if (
        this.getActiveElements.length === 1
        && this.getActiveElements[0].data.url
        && this.getActiveElements[0].data.url.split('.').pop() === 'webm'
      ) {
        return true;
      }
      return false;
    },
    isSvg() {
      return !this.isMask && this.getActiveElements[0].data.url && this.getActiveElements[0].data.url.split('.').pop() === 'svg';
    },
    isBrandedGraphic() {
      if (
        this.getActiveElements.length === 1
        && this.getActiveElements[0].isBrandedAsset
        && this.getActiveElements[0].data.svg_url
      ) {
        return true;
      }
      return false;
    },
    isMask() {
      let isMask = false;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (element.type === 'masks') {
          isMask = true;
          return true;
        }
      });

      return isMask;
    },
    isPng() {
      let isPng = true;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (!element.data.url || element.data.url.split('.').pop() !== 'png') {
          isPng = false;
          return true;
        }
      });

      return isPng;
    },
    isMedia() {
      let isMedia = true;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (element.type !== 'videos' && element.type !== 'images' && element.type !== 'masks') {
          isMedia = false;
          return true;
        }
      });

      return isMedia;
    },
    elementHasStroke() {
      // can't use 'isSvg' above because previously the 'isSvg' from 'ControlElement.vue' is not the same when moved here.
      let isSvgWithStroke = true;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (
          element.type !== 'shapes'
          || (element.data.url && element.data.url.split('.').pop() !== 'svg')
        ) {
          isSvgWithStroke = false;
          return true;
        }
      });

      return isSvgWithStroke && this.elementStroke.length;
    },
  },
  watch: {
    getActiveElements: {
      handler() {
        this.updateStroke();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(['setActiveEditTabGroup', 'setShowEditTab', 'setShowSidebar']),
    ...mapMutations('canvasElements', ['updateCanvasElementStroke']),
    closeEditTab(event) {
      const paths = event.path || event.composedPath();

      const isCollapseSidebarButton = checkIsCollapseSidebarButton(paths);
      const isColorTabTriggerButton = checkIsColorTabTriggerButton(paths);
      const isActiveCanvasElement = paths.findIndex(
        path => path.className
            && typeof path.className === 'string'
            && path.className.includes('resizer-element'),
      ) > -1;
      const isCloseColorTabButton = paths.findIndex(
        path => path.className
            && typeof path.className === 'string'
            && path.className.includes('color-tab__back-button'),
      ) > -1;
      const isDoNothing = this.showColorTab || isCloseColorTabButton || isActiveCanvasElement;

      if (this.showTimeline && isCollapseSidebarButton) this.setShowSidebar(false);
      if (isDoNothing && !isColorTabTriggerButton) return;
      this.setShowEditTab(false);
    },
    setActiveEditNavigation(value) {
      this.setActiveEditTabGroup(value);
      this.setActiveEffectGroup('');
    },

    setActiveEffectGroup(effect) {
      this.activeEffectGroup = effect;
    },
    updateStroke() {
      let strokeArray = [];

      if (this.getActiveElements.length && this.getActiveElements[0].data.stroke) {
        strokeArray = this.getActiveElements[0].data.stroke;
      }

      this.elementStroke = strokeArray;
    },
    onCanvasElementStrokeUpdated() {
      setTimeout(() => {
        this.updateStroke();
      }, 500);
    },
  },
  mounted() {
    this.updateStroke();

    // TODO: Better way to do this? - taken from ControlElement.vue - 29/10/2021
    this.$root.$on('canvas-element-stroke-updated', this.onCanvasElementStrokeUpdated);
  },
  beforeDestroy() {
    // need to off to avoid memory leak
    this.$root.$off('canvas-element-stroke-updated', this.onCanvasElementStrokeUpdated);
  },
};
</script>

<style lang="scss">
.canvas-edit-tab {
  background: $darkGrey700;
  height: 100%;
  left: 72px;
  padding: 15px 40px 0;
  position: absolute;
  top: 0;
  width: 360px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 3;
  overflow: auto;

  @include smallest {
    width: 270px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
  }

  &.edit-tab--filter {
    padding-right: 20px !important;

    .edit-tab__navigation {
      padding-right: 15px;
    }
  }

  &.edit-tab--effects {
    padding-right: 30px !important;

    @include smallest {
      padding-right: 20px !important;
    }

    .edit-tab__navigation {
      padding-right: 10px;
    }
  }

  .edit-tab__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 4px 0;
    user-select: none;
    max-width: 220px;
    width: 100%;

    &.navigation--content-center {
      justify-content: center;

      .navigation-item {
        &:nth-child(1) {
          margin-left: 10px;
        }

        &:nth-child(2) {
          margin: 0;
          margin-left: 20px;
        }
      }
    }

    .navigation-item {
      color: $light !important;
      opacity: 0.2;
      padding: 0 2px 5px;
      border-radius: 0;
      font-size: 0.9375rem;
      font-weight: 400;
      transition: opacity 0.2s ease-in-out;

      @include smallest {
        font-size: 0.875rem;
      }

      &:hover {
        opacity: 1;
      }

      &.is-active {
        opacity: 1;
        border-bottom: 2px solid $green700;
      }

      &:nth-child(2) {
        margin: 0 20px;
      }
    }
  }

  // -----------------------
  // Styling for TextEffects.vue & MediaEffects.vue
  // -----------------------
  .effects-tab {
    width: 100%;
    color: $light;

    .slider.is-disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  .effects__back-button {
    font-size: 0.875rem;
    outline: 0;
    border: 0;
    padding: 0;
    color: $light;
    transition: color 0.2s ease-in-out;
    margin: 20px 0 25px;

    &:hover {
      color: $lightGrey500;
    }
  }

  .effect__switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1rem;

    .switch__label {
      background: transparent;
      flex: auto;
      text-align: left;
      font-size: 0.875em;
      color: $lightGrey100;
    }

    .switch__input {
      margin-right: 5px;
    }

    .switch__label ~ .slider {
      right: 0;
    }
  }

  .effect__content {
    opacity: 0.2;
    pointer-events: none;
    transition: opacity 0.15s ease-in-out;

    &.effect-content--enabled {
      opacity: 1;
      pointer-events: initial;
    }
  }

  .effect__color {
    margin: 30px 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 0.875rem;

      @include smallest {
        font-size: 0.8125rem;
      }
    }

    .color {
      width: 35px;
      height: 35px;
      background: $borderGrey;
      border-radius: 4px;
    }
  }

  .effects__base-slider {
    margin: 20px 0;
    width: 100%;

    &.slider--extra-margin-top {
      margin: 30px 0 20px;
    }

    &.slider--bigger-input {
      .slider__value {
        width: 50px;
      }
    }

    .slider__container {
      display: flex;
      justify-content: space-between;
    }

    .slider__title {
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 10px;

      @include smallest {
        font-size: 0.8125rem;
      }
    }

    .range-wrapper {
      max-width: calc(100% - 70px);

      @include smallest {
        max-width: calc(100% - 60px);
      }
    }

    .slider__value {
      width: 50px;
      background: $darkGrey600 !important;
      border: 0 !important;
      outline: 0;

      @include smallest {
        width: 40px;
      }
    }

    input[type='range']::-webkit-slider-thumb {
      background: $light !important;
      width: 14px !important;
      height: 14px !important;
      margin-top: -1px;
    }

    .range-before {
      background: $lightGrey400 !important;
    }
  }

  .effects__checkbox {
    margin: 40px 0 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.checkbox--on-middle {
      margin: 30px 0 40px;
    }

    .label {
      cursor: pointer;
      font-size: 0.8125rem;
      color: $light;
      font-weight: 600;
      padding: 0;
    }

    .checkbox {
      width: 50px;
      height: 30px;
      background: $darkGrey600;
      border-radius: 4px;
      position: relative;
      transition: background 0.15s ease-in-out;

      @include smallest {
        width: 40px;
      }

      &:hover {
        background: darken($darkGrey600, 2%);
      }

      &.is-checked {
        &::after {
          display: block;
        }
      }

      &:after {
        display: none;
        content: $icon-checked;
        font-family: 'icomoon';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 1rem;
        color: $green;
      }
    }
  }

  .effects__button-list-container {
    .button-list__title {
      font-size: 0.875rem;
      font-weight: 500;
      color: $light;
      margin-top: 0;
      margin-bottom: 20px;
    }

    .button-list {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: 0;
      width: calc(100% - 70px);

      @include smallest {
        width: calc(100% - 60px);
      }
    }

    .button-list__item {
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 5px;
      width: 22%;
      height: 50px;
      font-size: 1.125rem;
      background: $darkGrey800;
      color: $darkGrey200;

      @include smallest {
        width: 28%;
        height: 42px;
        font-size: 1rem;
      }

      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(9) {
        margin-right: 0;
      }

      &:hover,
      &.is-selected {
        background: $lightGreen2;
        color: $light;
      }

      &.button--hidden {
        visibility: hidden;
        pointer-events: none;
      }

      i {
        &.icon-to-top-left,
        &.icon-to-top-right,
        &.icon-to-bottom-left,
        &.icon-to-bottom-right {
          font-size: 0.8em;
        }
      }
    }
  }

  .effects__reset-button {
    margin-top: 10px;
    background: $darkGrey5;
    font-size: 0.875rem;

    @include smallest {
      font-size: 0.8125rem;
    }

    &:hover {
      background: $darkGrey900;
      color: $light;
    }
  }
}
</style>
