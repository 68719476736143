<template lang="pug">
  .favourites-filter__navigation(
    :class="{ 'is-mobile': isMobile}"
  )
    BaseButton.carousel-icon.icon-left-arrow(
      v-show="isMobile"
      type="button"
      :is-icon="true"
      :plain-hover="true"
      :is-disabled="!hasPrev"
      @click="moveCarousel('prev')"
    )

    VueSlickCarousel.carousel__list(
      ref="favouriteTabCarousel"
      v-bind="slickOptions"
      key="favourite-tab-carousel"
      :class="{ 'is-mobile': isMobile}"
    )
      BaseButton.nav(
        v-for="(filter, key, index) in favouriteOptions"
        :key="filter"
        :class="{ 'nav--active': activeFavFilter === filter }"
        :is-hollow="true"
        :plain-hover="true"
        v-html="favouriteOptionsName[key]"
        @click="moveCarousel('custom', index)"
      )

    BaseButton.carousel-icon.icon-right-arrow(
      v-show="isMobile"
      type="button"
      :is-icon="true"
      :plain-hover="true"
      :is-disabled="!hasNext"
      @click="moveCarousel('next')"
    )

</template>

<script>
import { mapState, mapMutations } from 'vuex';
// since the naming of assets in FE is different with
// the BE, need to have 2 different enums
import { favouriteFilters, favouriteFiltersName } from '@/assets/scripts/enums';
import { isMobile } from '@/assets/scripts/utilities'; // eslint-disable-line
import slickMixin from '@/components/mixins/slick-mixins';

export default {
  name: 'FavouritesFilterNav',
  mixins: [slickMixin],
  data() {
    return {
      favouriteOptions: favouriteFilters,
      favouriteOptionsName: favouriteFiltersName,
      slickOptions: {
        arrows: false,
        dots: false,
        draggable: false,
        focusOnSelect: false,
        infinite: false,
        speed: 500,
        centerMode: false,
        slidesToShow: 4,
        slidesToScroll: 0,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
      },
      isMobile: isMobile(),
      activeFavIndex: 0,
    };
  },
  computed: {
    ...mapState('assetsSidebar', ['activeFavFilter']),
    hasPrev() {
      return this.activeFavIndex > 0;
    },
    hasNext() {
      const options = Object.values(this.favouriteOptions);
      return this.activeFavIndex < options.length - 1;
    },
  },
  watch: {
    activeFavFilter: {
      handler(val) {
        if (val) {
          this.setActiveFavIndex();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('assetsSidebar', ['setActiveFavFilter']),
    updateFavouriteOptions() {
      this.favouriteOptions = favouriteFilters;
    },
    setActiveFavIndex() {
      Object.values(this.favouriteOptions).find((option, index) => {
        if (option === this.activeFavFilter) {
          this.activeFavIndex = index;
        }
      });
    },
    moveCarousel(type, index) {
      if (type === 'custom') {
        this.activeFavIndex = index;

        if (index === 1) this.$refs.favouriteTabCarousel.prev();
        if (index === 2) this.$refs.favouriteTabCarousel.next();
      }

      if (type === 'prev') {
        this.activeFavIndex -= 1;
        if (this.activeFavIndex === 1) this.$refs.favouriteTabCarousel.prev();
      }

      if (type === 'next') {
        this.activeFavIndex += 1;
        if (this.activeFavIndex === 2) this.$refs.favouriteTabCarousel.next();
      }

      const options = Object.values(this.favouriteOptions);
      this.setActiveFavFilter(options[this.activeFavIndex]);
    },
  },
  mounted() {
    this.updateFavouriteOptions();
    this.setActiveFavIndex();
  },
};
</script>

<style lang="scss">
.favourites-filter__navigation {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0 5px;

  &.is-mobile {
    display: block !important;
  }

  @include smallest() {
    display: block !important;
  }

  .nav {
    color: $lightGrey400;
    background: transparent;
    text-transform: capitalize;
    font-weight: 500;
    margin-right: 5px;
    width: 33%;
    padding: 10px 0;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    &:last-of-type {
      margin-right: 0;
    }

    &:not(.nav--active) {
      color: $darkBlue5;
      border-color: $darkBlue5;
      font-weight: 400;

      &:hover {
        color: $light;
        border-color: $darkGrey5;
        background: $darkGrey5;
      }
    }

    .btn__circle {
      display: none;
    }
  }

  .carousel__list {
    width: 100%;
    padding: 0;

    &.is-mobile {
      padding: 0 25px;
    }

    @include smallest() {
      padding: 0 15px !important;
    }

    .slick-slide {
      padding: 0 2.5px;
    }
  }

  .carousel-icon {
    position: absolute;
    top: 8px;
    color: $light;
    display: none;
    padding: 0;
    z-index: 1;
    font-size: 16px;

    &:hover {
      color: $light;
    }

    &:disabled {
      background: none;
      opacity: 0.25;
    }

    .btn__content {
      display: none;
    }

    @include smallest() {
      display: block !important;
    }

    &.icon-left-arrow {
      left: -6px;
    }

    &.icon-right-arrow {
      right: -6px;
    }
  }
}
</style>
