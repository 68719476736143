<template lang="pug">
.smart-guides__line(
  :style="styleObject"
)
</template>

<script>
export default {
  name: 'SmartGuidesLine',
  props: {
    guide: { type: Object, default: () => ({}) },
  },
  computed: {
    styleObject() {
      const styleObject = {};

      let borderStyle = 'solid';

      if (this.guide.type === 'elements') {
        borderStyle = 'dashed';
      }

      styleObject.top = `${this.guide.top}px`;
      styleObject.left = `${this.guide.left}px`;

      if (this.guide.isRect) {
        styleObject.borderWidth = '1px';
        styleObject.borderStyle = borderStyle;
        styleObject.height = `${this.guide.bottom - this.guide.top}px`;
        styleObject.width = `${this.guide.right - this.guide.left}px`;
      } else {
        if (this.guide.direction === 'x') {
          styleObject.borderLeftWidth = '1px';
          styleObject.borderLeftStyle = borderStyle;
          styleObject.height = `${this.guide.bottom - this.guide.top}px`;
          styleObject.transform = 'translateX(-50%)';
        }

        if (this.guide.direction === 'y') {
          styleObject.borderTopWidth = '1px';
          styleObject.borderTopStyle = borderStyle;
          styleObject.width = `${this.guide.right - this.guide.left}px`;
          styleObject.transform = 'translateY(-50%)';
        }
      }

      return styleObject;
    },
  },
};
</script>

<style lang="scss">
.smart-guides__line {
  position: absolute;
  width: 0;
  height: 0;
  border-color: $purple;
  z-index: 4;
}
</style>
