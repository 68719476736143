<template lang="pug">
.element-light-sweep.effect
  BaseSwitch.element-light-sweep__switch.effect__switch(
    name="element-light-sweep"
    :isChecked="isEnabled('light-sweep')"
    :isBeta="true"
    @change="toggleEnable('light-sweep')"
    label="Light Sweep"
  )
  VueSlideUpDown.element-light-sweep__content.effect__content(
    :class="{'effect-content--enabled': isEnabled('light-sweep')}"
    :active="true"
  )
    BaseSlider.effects__base-slider.slider--bigger-input.slider--extra-margin-top(
      v-if="isMediaEffectsType"
      is-dark=true
      :min="0"
      :max="5"
      :step="0.1"
      title="Intensity"
      :value="intensity"
      @blur="onBlurInput($event, 'intensity')"
      @update="updateIntensity"
    )
    .light-sweep__color.effect__color(v-if="isTextEffectsType")
      span.title Colour
      button.color.btn-add-color(
        :class="{ 'is-active': isTextLightSweep || isMediaLightSweep }"
        type="button"
        :style="colorStyle"
        @click="openColorPicker"
      )

    .effects__checkbox.checkbox--on-middle
      button.label(type="button" @click="toggleIsLoop") Loop
      span.checkbox(:class="{'is-checked': isLoop}" @click="toggleIsLoop")

    BaseSlider.effects__base-slider.slider--bigger-input(
      is-dark=true
      :min="0"
      :max="100"
      title="Speed"
      :value="speed"
      @blur="onBlurInput($event, 'speed', '%')"
      @update="updateSpeed"
      suffix="%"
    )
    BaseSlider.effects__base-slider.slider--bigger-input(
      is-dark=true
      :min="0"
      :max="maxRadius"
      title="Radius"
      :value="radius"
      @blur="onBlurInput($event, 'radius')"
      @update="updateRadius"
    )

    .effects__button-list-container
      p.button-list__title Angle
      .button-list
        BaseButton.button-list__item(
          v-for="angle in angleList"
          :key="angle.value"
          :plain-hover="true"
          :is-canvas="true"
          :class="{'is-selected': isSelectedAngle(angle.value), 'button--hidden': angle.value === 'center-center'}"
          @click="updateAngle(angle.value)"
        )
          i(:class="angle.icon")

    BaseButton.effects__reset-button(
      :is-canvas="true"
      is-dark=true
      is-full-width=true
      @click="resetLightSweep"
    ) Reset
</template>

<script>
import { mapState } from 'vuex';
import VueSlideUpDown from 'vue-slide-up-down';
import { groups, subgroups } from '@/assets/scripts/enums';
import { elementFormat, textDataFormat, fallbackLightsweepSelectedAngle } from '@/assets/scripts/variables';
import { hexToRgb } from '@/assets/scripts/utilities';
import effectsMixin from '@/components/mixins/effects-mixins';

export default {
  name: 'LightSweepControls',
  components: {
    VueSlideUpDown,
  },
  mixins: [effectsMixin],
  data() {
    return {
      angleList: [
        {
          value: 'bottom-right',
          icon: 'icon-to-bottom-right',
        },
        {
          value: 'bottom-center',
          icon: 'icon-to-bottom',
        },
        {
          value: 'bottom-left',
          icon: 'icon-to-bottom-left',
        },
        {
          value: 'center-right',
          icon: 'icon-to-right',
        },
        {
          value: 'center-center',
          icon: 'icon-from-center',
        },
        {
          value: 'center-left',
          icon: 'icon-to-left',
        },
        {
          value: 'top-right',
          icon: 'icon-to-top-right',
        },
        {
          value: 'top-center',
          icon: 'icon-to-top',
        },
        {
          value: 'top-left',
          icon: 'icon-to-top-left',
        },
      ],
    };
  },
  computed: {
    ...mapState(['isTextLightSweep', 'isMediaLightSweep']),
    activeElement() {
      return this.getActiveElements[0];
    },
    isTextElementType() {
      return this.activeElement.type === groups.TEXTS;
    },
    isImageElementType() {
      return this.activeElement.type === subgroups.IMAGES || this.isMask || this.isSvg || this.isBrandedGraphic;
    },
    selectedLightSweep() {
      return this.isTextEffectsType ? 'textLightSweep' : 'mediaLightSweep';
    },
    maxRadius() {
      return this.isImageElementType ? 5 : 15;
    },
    colorStyle() {
      const styleObject = {};

      if (this.isMediaEffectsType) return styleObject;

      let lightSweepColor = '#ffffff';

      if (this.getActiveElements.length) {
        const { color } = this.activeElement.data.textLightSweep;

        const { r, g, b } = hexToRgb(color);
        lightSweepColor = `rgb(${r}, ${g}, ${b})`;
      }

      styleObject.background = lightSweepColor;

      return styleObject;
    },
    intensity() {
      if (this.isTextEffectsType) return 0;
      return this.activeElement.data.mediaLightSweep.intensity;
    },
    speed() {
      return this.activeElement.data[this.selectedLightSweep].speed;
    },
    radius() {
      return this.activeElement.data[this.selectedLightSweep].radius;
    },
    isLoop() {
      return this.activeElement.data[this.selectedLightSweep].isLoop;
    },
    selectedAngle() {
      return this.activeElement.data[this.selectedLightSweep].selectedAngle || fallbackLightsweepSelectedAngle;
    },
  },
  mounted() {
    if (!this.isEnabled('light-sweep')) {
      this.toggleEnable('light-sweep');
    }
  },
  methods: {
    isSelectedAngle(angle) {
      const splitAngle = angle.split('-');
      const angleX = splitAngle[1];
      const angleY = splitAngle[0];

      return this.selectedAngle.x === angleX && this.selectedAngle.y === angleY;
    },
    updateIntensity(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementMediaLightSweep({ intensity: value });

        this.$root.$emit('canvas-container-reset-timeline');
      }
    },
    updateSpeed(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        if (this.isTextEffectsType) this.updateCanvasElementTextLightSweep({ speed: value });
        if (this.isMediaEffectsType) this.updateCanvasElementMediaLightSweep({ speed: value });

        this.$root.$emit('canvas-container-reset-timeline');
      }
    },
    updateAngle(value) {
      const splitAngle = value.split('-');
      const angleY = splitAngle[0];
      const angleX = splitAngle[1];

      this.catchHistory('element');
      if (this.isTextEffectsType) this.updateCanvasElementTextLightSweep({ selectedAngle: { x: angleX, y: angleY } });
      if (this.isMediaEffectsType) this.updateCanvasElementMediaLightSweep({ selectedAngle: { x: angleX, y: angleY } });

      this.$root.$emit('canvas-container-reset-timeline');
    },
    updateRadius(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        if (this.isTextEffectsType) this.updateCanvasElementTextLightSweep({ radius: value });
        if (this.isMediaEffectsType) this.updateCanvasElementMediaLightSweep({ radius: value });

        this.$root.$emit('canvas-container-reset-timeline');
      }
    },
    toggleIsLoop() {
      this.catchHistory('element');
      if (this.isTextEffectsType) this.updateCanvasElementTextLightSweep({ isLoop: !this.isLoop });
      if (this.isMediaEffectsType) this.updateCanvasElementMediaLightSweep({ isLoop: !this.isLoop });

      this.$root.$emit('canvas-container-reset-timeline');
    },
    onBlurInput(event, type, suffix) {
      this.onBlurEffectInput(event, {
        effect: this.selectedLightSweep,
        selectedInput: type,
        suffix,
      });
    },
    openColorPicker() {
      if (this.isTextEffectsType) this.setIsTextLightSweep(true);
      this.$emit('open-colorpicker');

      this.$root.$emit('canvas-container-reset-timeline');
    },
    resetLightSweep() {
      this.catchHistory('element');

      if (this.isTextEffectsType) {
        this.updateCanvasElementTextLightSweep({
          color: textDataFormat.textLightSweep.color,
          speed: textDataFormat.textLightSweep.speed,
          selectedAngle: textDataFormat.textLightSweep.selectedAngle,
          radius: textDataFormat.textLightSweep.radius,
          isLoop: textDataFormat.textLightSweep.isLoop,
        });
      }

      if (this.isMediaEffectsType) {
        this.updateCanvasElementMediaLightSweep({
          intensity: elementFormat.data.mediaLightSweep.intensity,
          speed: elementFormat.data.mediaLightSweep.speed,
          selectedAngle: elementFormat.data.mediaLightSweep.selectedAngle,
          radius: elementFormat.data.mediaLightSweep.radius,
          isLoop: elementFormat.data.mediaLightSweep.isLoop,
        });
      }

      this.$root.$emit('canvas-container-reset-timeline');
    },
  },
};
</script>

<style lang="scss">
</style>
