<template lang="pug">
.project-details
  .project-details__settings
    ProjectSettings
    form(
      autocomplete="off"
      @submit.prevent
    )
      BaseInputField#project-name(
        v-model="projectName"
        :autowidthParams="{maxWidth: '250px', comfortZone: 0}"
        @change="updateProjectName"
      )

  .project-details__actions
    BaseButton.btn-save(
      :is-light="true"
      :is-text="true"
      :plain-hover="true"
      @click="saveProject"
    ) {{ getSaveButtonText }}
    BaseButton.icon-undo.btn-canvas-history(
      v-if="!getIsTemplate"
      title="Undo"
      :class="{'is-disabled': isUndoDisabled}"
      :is-light="true"
      :is-icon="true"
      :plain-hover="true"
      @click="undo"
    )
    BaseButton.icon-redo.btn-canvas-history(
      v-if="!getIsTemplate"
      title="Redo"
      :class="{'is-disabled': isRedoDisabled}"
      :is-light="true"
      :is-icon="true"
      :plain-hover="true"
      @click="redo"
    )

    GlobalEvents(
      :filter="(event, handler, eventName) => allowGlobalEvents(event)"
      @keydown.ctrl.z.exact.prevent="ctrlUndo"
      @keydown.ctrl.shift.z.exact.prevent="ctrlRedo"
      @keydown.meta.z.exact.prevent="cmdUndo"
      @keydown.meta.shift.z.exact.prevent="cmdRedo"
    )
  CommonSizes(
    v-if="!isLiteMode && showCommonSizes"
  )
</template>

<script>
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';

import helperMixins from '@/components/mixins/helper-mixins';

import { isMac } from '@/assets/scripts/utilities';
import ProjectSettings from './children/ProjectSettings.vue';
import CommonSizes from '@/components/ProjectCanvas/PageOverlay/CommonSizes.vue';

export default {
  name: 'ProjectDetailsContainer',
  components: {
    ProjectSettings,
    CommonSizes,
  },
  mixins: [helperMixins],
  data() {
    return {
      projectName: '',
    };
  },
  computed: {
    ...mapState(['showCommonSizes', 'showSwap', 'showCrop']),
    ...mapGetters(['isLiteMode', 'getIsTemplate']),
    ...mapGetters('canvasHistory', ['hasUndo', 'hasRedo']),
    ...mapGetters('canvasElements', ['getSaveButtonText', 'getProjectDetails']),
    isUndoDisabled() {
      if (!this.hasUndo) return true;
      return false;
    },
    isRedoDisabled() {
      if (!this.hasRedo) return true;
      return false;
    },
  },
  methods: {
    ...mapMutations('assetsSidebar', ['clearActiveKeyword']),
    ...mapMutations('canvasElements', ['updateProjectDetails']),
    ...mapActions('canvasElements', ['saveProject']),
    ...mapActions('canvasHistory', ['undoCanvasHistory', 'redoCanvasHistory']),
    allowGlobalEvents(event) {
      return event.target.tagName !== 'INPUT'
      && event.target.tagName !== 'TEXTAREA'
      && event.target.getAttribute('contenteditable') !== 'true'
      && !this.showSwap
      && !this.showCrop;
    },
    activateTab() {
      this.clearActiveKeyword();
    },
    cmdUndo() {
      if (!isMac()) return;
      this.undo();
    },
    cmdRedo() {
      if (!isMac()) return;
      this.redo();
    },
    ctrlUndo() {
      this.undo();
    },
    ctrlRedo() {
      this.redo();
    },
    undo() {
      this.undoCanvasHistory();
    },
    redo() {
      this.redoCanvasHistory();
    },
    updateProjectName(name) {
      this.projectName = name;

      const params = {};
      if (this.getIsTemplate) params.template_name = name;
      else params.project_name = name;

      this.updateProjectDetails(params);
      this.saveProject();

      this.alertSuccess('', `Project renamed to ${name}`, 3000, 'TopCenterNotif');
    },
  },
  watch: {
    getProjectDetails: {
      handler() {
        this.projectName = this.getProjectDetails.name;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.project-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 100%;
  margin-left: 10px;

  .project-details__actions {
    display: flex;

    .btn-save {
      font-size: 14px;
      font-weight: 600;
      padding: 8px 10px;
      margin-right: 20px;

      &:hover {
        color: $green600;
        background: transparent !important;
      }

      @include fb-requirement {
        margin-right: 10px;
      }
    }

    .btn-canvas-history {
      font-size: 1.125rem !important;
      padding: 4px 0;

      &.icon-undo {
        padding-right: 10px;
        padding-left: 4px;
      }

      &.icon-redo {
        padding-left: 10px;
        padding-right: 4px;
      }

      &:active,
      &:focus {
        background: none;
      }

      &:hover {
        color: $green600;
        background: transparent !important;
      }

      &.is-disabled {
        color: $darkGrey100;
        pointer-events: none;
        cursor: auto;
      }
    }
  }
}

.project-details__settings {
  text-align: center;
  width: auto;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  #project-name {
    .input-field {
      display: inline-block;
      font-size: 0.875em;
      color: $lightWhite;
      font-weight: 500;
      padding: 10px;
      border-color: transparent;
      background: transparent;
      text-overflow: ellipsis;
      overflow: hidden;

      @include fb-requirement {
        max-width: 155px !important;
      }

      &:active,
      &:focus,
      &:hover {
        border-color: $subBlue;
      }
    }
  }
}
</style>
