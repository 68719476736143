<template lang="pug">
.category-item.category-item--skeleton(
  :class="{'is-preparing': isPreparingVideo}"
  :style="styleObject"
  @mouseenter="skeletonHovered = true"
  @mouseleave="skeletonHovered = false"
  ref="skeletonWrapper"
)
  span.skeleton__loop-wrapper
    span.skeleton__loop.text(
      :class="{ 'is-looping': isLooping }"
      ref="skeletonText"
      v-html="text"
    )
  span.bar
    span.bar-progress(:style="progressStyle")
</template>

<script>
export default {
  name: 'SkeletonItem',
  data() {
    return {
      imageHeight: 100,
      skeletonHovered: false,
    };
  },
  props: {
    fileProgress: { type: Number, default: 0 },
    isPreparingVideo: { type: Boolean, default: false },
    isRetrying: { type: Boolean, default: false },
    fileName: { type: String, default: '' },
  },
  computed: {
    styleObject() {
      const styleObject = {};

      styleObject.height = `${this.imageHeight}px`;

      return styleObject;
    },
    progressStyle() {
      const styleObject = {};
      const percentage = this.isPreparingVideo ? 1 : this.fileProgress / 100;

      styleObject.transform = `scaleX(${percentage})`;

      return styleObject;
    },
    text() {
      // if it is retrying means it's failed
      if (this.isRetrying) return 'Uploading failed';
      if (!this.isPreparingVideo) return 'Uploading';
      if (this.skeletonHovered) return this.fileName;
      return 'Optimizing';
    },
    isLooping() {
      return false;
      // return this.skeletonHovered && !this.isRetrying && this.isPreparingVideo;
    },
  },
  methods: {
    repeatContent(till) {
      const { skeletonText } = this.$refs;
      const html = skeletonText.innerHTML;
      let counter = 0; // prevents infinite loop

      while (skeletonText.offsetWidth < till && counter < 100) {
        skeletonText.innerHTML += html;
        counter += 1;
      }
    },
  },
  mounted() {
    const { skeletonWrapper, skeletonText } = this.$refs;
    const isOverflownX = skeletonText.scrollWidth > skeletonWrapper.clientWidth;

    if (isOverflownX) {
      this.repeatContent(skeletonWrapper.offsetWidth);
      skeletonText.innerHTML += skeletonText.innerHTML;
    }
  },
};
</script>

<style lang="scss">
.category-item--skeleton {
  background: $darkGrey600;
  position: relative;

  .text {
    font-size: 12px;
    display: block;
    position: absolute;
    bottom: 50px;
    text-align: center;
    width: 100%;
    text-transform: capitalize;
    color: $light;
  }

  .bar {
    display: block;
    position: absolute;
    background: $darkGrey700;
    border-radius: 4px;
    height: 5px;
    left: 25px;
    bottom: 37px;
    width: calc(100% - 50px);
    overflow: hidden;
  }

  .bar-progress {
    position: absolute;
    background: linear-gradient(124deg, $red400, $orange400, $green400, $lighterGreen, $blue700, $purple);
    background-size: 1800% 1800%;
    animation: rainbow 8s ease infinite;
    top: 0;
    left: 0;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    transition: transform 5s ease-in-out;
    transform-origin: left;
  }

  &.is-preparing {
    z-index: 10;

    .bar-progress {
      background: linear-gradient(90deg, $skeleton2 40%, $skeleton1, $skeleton2 60%);
      background-size: 220% 220%;
      animation: skeletonLoader 10s infinite;
      animation-fill-mode: forwards;
    }
  }

  .skeleton__loop-wrapper {
    display: inline-block;

    .skeleton__loop {
      white-space: nowrap;

      &.is-looping {
        animation: loopLeft 10s linear infinite;
      }
    }
  }
}

@keyframes skeletonLoader {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@keyframes loopLeft {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -50%;
  }
}
</style>
