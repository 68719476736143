<template lang="pug">
.modular-template__sceneitem(
  :style="sceneItemStyle"
  :class="{ 'is-selected': selectedScenesIds.indexOf(id) >= 0, 'btn-add-modular-scene': !id && getIsTemplate, 'is-loaded': imageIsLoaded }"
  @mouseenter="mouseEnter"
  @mouseleave="mouseLeave"
)
  span.modular-template__index(
    v-if="selectedOrder"
  ) {{ selectedOrder }}
  template(v-if="id")
    //- Disable the button if the duration reached max and this scene is not selected yet
    //- or this scene duration is longer than the duration
    //- Don't use :disabled so that it can emit the click event
    button.image(
      type="button"
      ref="image"
      :class="{ 'is-disabled': isDisabled, 'is-select-scene-image':!isAddSceneModal }"
      :style="imageStyle"
      @click="sceneClickEvent"
    )
      template(v-if="hasImagePreview")
        .modular-template__sceneitem--video(
          v-if="showVideo"
          :class="{'is-loaded': videoIsLoaded}"
        )
          video(
            ref="video"
            :loop="true"
            :autoplay="true"
            :muted="true"
          )
            source(:src="previewVideoUrl")
        template
          img(
            ref="imagePreview"
            :src="getImagePreview"
          )
      template(v-else)
        CanvasScene(
          :elements="getElements"
          sceneClass="scenes__canvas"
          :scale="sceneScale"
          :background="background"
          animationTimeline="sceneTimeline"
          isScenePreview=true
          :showMidScene="true"
          :duration="duration"
          :id="id"
        )

    span.duration(
      v-if="!isAddSceneModal"
      v-html="`${duration}s`"
    )

    .scene-item__description(v-if="isAddSceneModal")
      span.text-description(v-html="sceneTitle")
      span.text-description(v-html="`${duration}s`")

  template(v-if="showConfirmDeleteScene")
    BaseDialog(
      isShow=true
      isDanger=true
      text="Are you sure you want to delete this scene?"
      @confirm="deleteScene"
      @closeOverlay="showConfirmDeleteScene=false"
    )
  template(v-if="showSceneDeleted")
    BaseDialog(
      isShow=true
      isSuccess=true
      text="Scene has been deleted"
      noCancel=true
      button1Text="Close"
      @confirm="showSceneDeleted=false"
    )

  template(v-if="showConfirmRemoveHeroSceneTag")
    BaseDialog(
      isShow=true
      isDanger=true
      button1Text="Remove Tag"
      :text="confirmRemoveHeroSceneTagText"
      @confirm="setHeroSceneTag(heroTag.value, false)"
      @closeOverlay="showConfirmRemoveHeroSceneTag=false"
    )
  template(v-if="showConfirmOverwriteHeroSceneWithBgTag")
    BaseDialog(
      isShow=true
      isDanger=true
      button1Text="Overwrite Tag"
      :text="confirmOverwriteHeroSceneTagWithBgText"
      @confirm="setHeroSceneTag(overwriteKey, true)"
      @closeOverlay="showConfirmOverwriteHeroSceneWithBgTag=false"
    )
  template(v-if="showConfirmOverwriteHeroSceneWithoutBgTag")
    BaseDialog(
      isShow=true
      isDanger=true
      button1Text="Overwrite Tag"
      :text="confirmOverwriteHeroSceneTagWithoutBgText"
      @confirm="setHeroSceneTag(overwriteKey, true)"
      @closeOverlay="showConfirmOverwriteHeroSceneWithoutBgTag=false"
    )
</template>

<script>
// import { Drag } from 'vue-drag-drop';
import {
  mapGetters, mapMutations, mapActions, mapState,
} from 'vuex';

import { debounce } from 'debounce';
import vClickOutside from 'v-click-outside';
import CanvasScene from '@/components/ProjectCanvas/CanvasContainer/children/CanvasScene.vue';
import HeroSceneTag from '@/components/ProjectCanvas/PageOverlay/children/HeroSceneTag.vue';
import TemplateApi from '@/services/api/TemplateApi';
import helperMixins from '@/components/mixins/helper-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';
import { isEmptyObject } from '@/assets/scripts/utilities';
import { designsPerRow } from '@/assets/scripts/variables';
import { designTabs } from '@/assets/scripts/enums';


export default {
  name: 'ModularTemplateScene',
  mixins: [helperMixins, darkModeMixin],
  components: {
    // Drag,
    CanvasScene,
    HeroSceneTag,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    description: { type: String, default: '' },
    id: { type: Number },
    duration: { type: Number, default: 0 },
    availableDuration: { type: Number, default: 0 },
    activeCategory: { type: Number, default: 0 },
    sceneCategory: { type: Number, default: 0 },
    background: { type: [String, Object], default: '#fff' },
    scene: { type: Object, default: () => ({}) },
    selectedScenesIds: { type: Array, default: () => [] },
    reachMaxDuration: { type: Boolean, default: false },
    is_hero_w_bg: { type: Boolean, default: false },
    is_hero_wo_bg: { type: Boolean, default: false },
    isAddSceneModal: { type: Boolean, default: false },
    width: { type: Number, default: 0 },
    height: { type: Number, default: 0 },
  },
  data() {
    return {
      name: '',
      showConfirmDeleteScene: false,
      showSceneDeleted: false,
      showVideo: false,
      videoIsLoaded: false,
      isSceneCategoryOpened: false,
      showConfirmRemoveHeroSceneTag: false,
      showConfirmOverwriteHeroSceneWithBgTag: false,
      showConfirmOverwriteHeroSceneWithoutBgTag: false,
      overwriteKey: '',
      heroSceneTagsList: [
        {
          name: 'Hero w Mask',
          value: 'hero-w-bg',
        },
        {
          name: 'Hero w/o Mask',
          value: 'hero-wo-bg',
        },
      ],
      isHeroSceneWithBg: false,
      isHeroSceneWithoutBg: false,
      imageIsLoaded: false,
    };
  },
  computed: {
    ...mapState(['showDesignContainer']),
    ...mapState('assetsSidebar', ['activeDesignTab']),
    ...mapGetters(['getIsTemplate']),
    ...mapGetters('userData', ['isDesigner']),
    ...mapGetters('canvasElements', [
      'getModularTemplateScenes',
      'getScenes',
      'getScenesElementsById',
      'getActiveSceneId',
      'getCanvasSize',
      'getProjectId',
      'getCurrentSceneDuration',
      'getModularTemplateScenesElementsById',
      'getModularCategories',
      'getHeroSceneWithBg',
      'getHeroSceneWithoutBg',
      'getProjectDetails',
    ]),
    getModularCategoriesWithId() {
      return this.getModularCategories.filter(cat => !!cat.id);
    },
    hasImagePreview() {
      if (!this.scene) return false;
      if (this.scene.preview) return true;
      return false;
    },
    hasVideoPreview() {
      return this.scene && !this.isEmpty(this.scene.video_preview);
    },
    previewVideoUrl() {
      return this.hasVideoPreview ? this.scene.video_preview : '';
    },
    getImagePreview() {
      const { preview } = this.scene;

      if (!preview.includes('place-hold.it') && preview.indexOf('http') !== -1) {
        return preview;
      }

      // if (preview) {
      //   // Replace backslash. Replace trailing/leading spaces
      //   preview = preview.replace(/\//g, '/').replace(/^\s+|\s+$/g, '');
      //   let jsonPreview = JSON.parse(preview);
      //   if (jsonPreview.image && jsonPreview.image.thumb) return jsonPreview.image.thumb;
      // }

      // return 'https://dummyimage.com/190x190/f9f9f9/aaa';
      return this.noPreviewImage;
    },
    noPreviewImage() {
      const theme = this.isDarkMode ? 'dark' : 'light';
      // eslint-disable-next-line
      return require(`@/assets/images/backgrounds/no-preview-${theme}.svg`);
    },
    getElements() {
      if (!this.isDesigner && this.getModularTemplateScenes.length) {
        return [...this.getModularTemplateScenesElementsById(this.id)].reverse();
      }
      return [...this.getScenesElementsById(this.id)].reverse();
    },
    sceneScale() {
      return this.$refs.image.offsetWidth / this.getCanvasSize.width;
    },
    isDisabled() {
      return (
        (this.reachMaxDuration || this.duration > this.availableDuration)
        && this.selectedScenesIds.indexOf(this.id) === -1
      );
    },
    sceneItemStyle() {
      const styleObject = {};

      const perRow = designsPerRow[designTabs.BRANDEDDESIGNS][this.proportion];

      styleObject.width = `calc(${100 / perRow}% - 9px)`;

      return styleObject;
    },
    imageStyle() {
      const styleObject = {};

      styleObject.width = '100%';
      styleObject.paddingTop = `${(this.height / this.width) * 100}%`;

      return styleObject;
    },
    selectedSceneCategoryName() {
      const categoryId = this.scene.modular_category_id;
      const sceneObj = categoryId > 0 ? this.getModularCategories.find(o => o.id === categoryId) : '';
      if (sceneObj && sceneObj.name) return sceneObj.name;
      return '';
    },
    heroTag() {
      return {
        name: this.isHeroSceneWithBg ? this.heroSceneTags[0].name : this.heroSceneTags[1].name,
        value: this.isHeroSceneWithBg ? this.heroSceneTags[0].value : this.heroSceneTags[1].value,
      };
    },
    confirmRemoveHeroSceneTagText() {
      return `You are attempting to remove ${this.heroTag.name} tag, which is a compulsory component. Do you want to continue removing it?`;
    },
    confirmOverwriteHeroSceneTagWithBgText() {
      return `${this.getHeroSceneWithBg.categoryName} is currently the ${this.heroSceneTags[0].name}.<br/>Do you want to overwrite it?`;
    },
    confirmOverwriteHeroSceneTagWithoutBgText() {
      return `${this.getHeroSceneWithoutBg.categoryName} is currently the ${this.heroSceneTags[1].name}.<br/>Do you want to overwrite it?`;
    },
    heroSceneTags() {
      // Check if logo template
      if (this.isDesigner && this.getProjectDetails.isLogo) {
        return this.heroSceneTagsList.filter(obj => obj.value !== 'hero-w-bg');
      }

      return this.heroSceneTagsList;
    },
    sceneTitle() {
      let title = '';

      if (this.getProjectDetails.isPermutation) {
        switch (this.scene.scene_category_type) {
          case 'product-w-bg':
            title = 'Image';
            break;
          case 'product-wo-bg':
            title = 'Image';
            break;
          case 'text':
            title = 'Text';
            break;
          case 'text-logo':
            title = 'Logo + Text';
            break;
          case 'logo':
            title = 'Logo';
            break;
          default:
            break;
        }
      }

      return title;
    },
    proportion() {
      if (this.width > this.height) return 'landscape';
      if (this.width < this.height) return 'vertical';
      return 'square';
    },
    selectedOrder() {
      if (!this.selectedScenesIds.length) return 0;
      return this.selectedScenesIds.indexOf(this.id) + 1;
    },
  },
  methods: {
    ...mapMutations('canvasElements', [
      'updateActiveSceneId',
      'updateHeroSceneWithBg',
      'updateHeroSceneWithoutBg',
    ]),
    ...mapActions('canvasElements', ['saveProject']),
    // eslint-disable-next-line func-names
    mouseEnter: debounce(function () {
      if (this.hasVideoPreview) {
        this.showVideo = true;
        this.videoIsLoaded = false;
      } else if (this.isAnimated) this.loadAnimation();
    }, 300),
    mouseLeave() {
      // this.showPreview = true;
      if (this.showVideo) {
        this.showVideo = false;
        this.videoIsLoaded = true;
      }
    },
    sceneClickEvent() {
      if (this.isDisabled) {
        this.$emit('click-disabled');
      } else {
        this.$emit('select', this.id);
      }
    },
    // confirmSceneDescription(e) {
    //   const oldName = this.name;
    //   const name = e.target.value;

    //   if (name) {
    //     const params = {
    //       template_description: name,
    //       duration: this.getCurrentSceneDuration,
    //       modular_category_id: this.activeCategory ? this.activeCategory : null,
    //     };
    //     TemplateApi.updateProjectScene(this.getProjectId, this.id, params)
    //       .then((response) => {
    //         console.log('update scene', response);
    //         if (!response.data.success) {
    //           // if fail put back to old name
    //           this.name = oldName;
    //         }

    //         // Update name in array
    //         this.scene.description = this.name;
    //       }).catch((error) => {
    //         console.error(error);
    //         this.name = oldName;
    //       });
    //   }
    // },
    deleteScene() {
      if (!this.isDesigner) return;

      TemplateApi.deleteProjectScene(this.getProjectId, this.id)
        .then((response) => {
          if (response.data.success) {
            this.$emit('deleteScene', this.id);
            this.showConfirmDeleteScene = false;
            this.showSceneDeleted = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editScene() {
      if (!this.isDesigner) return;

      this.saveProject();

      this.updateActiveSceneId(this.id);
      this.$emit('close-scene-builder');
    },
    onClickOutsideCategory() {
      this.isSceneCategoryOpened = false;
    },
    updateSelectedSceneCategory(category) {
      this.scene.modular_category_id = category.id;

      const params = {
        duration: this.getCurrentSceneDuration,
        modular_category_id: category.id ? category.id : null,
      };
      TemplateApi.updateProjectScene(this.getProjectId, this.id, params)
        .then((response) => {
          // console.log('update scene', response);

          if (!response.data.success) {
            this.scene.modular_category_id = response.data.new_scene_details.modular_category_id;
            this.alertError(
              '',
              'Oops, something went wrong. Try again later.',
              2000,
              'TopCenterNotif',
            );
          } else {
            const successMsg = typeof category.name !== 'undefined'
              ? `Scene category updated to ${category.name}`
              : 'Scene category removed';
            this.alertSuccess('', successMsg, 2000, 'TopCenterNotif');
          }
        })
        .catch((error) => {
          console.error(error);
        });

      this.isSceneCategoryOpened = false;
    },
    isSelectedSceneCategory(category) {
      return this.scene.modular_category_id === category.id;
    },
    setHeroSceneTag(key, value) {
      // Clear key's hero
      const heroKey = `is_${key.replace(/-/g, '_')}`;

      if (key === 'hero-w-bg') {
        this.isHeroSceneWithBg = value;
      } else if (key === 'hero-wo-bg') {
        this.isHeroSceneWithoutBg = value;
      }

      console.log('key', key, this.isHeroSceneWithBg, this.isHeroSceneWithoutBg);

      if (!value) {
        this.showConfirmRemoveHeroSceneTag = false;
      } else if (this.overwriteKey !== '') {
        this.overwriteKey = '';
        this.showConfirmOverwriteHeroSceneWithBgTag = false;
        this.showConfirmOverwriteHeroSceneWithoutBgTag = false;
      }

      const params = {
        duration: this.getCurrentSceneDuration,
      };
      params[heroKey] = value;

      TemplateApi.updateProjectScene(this.getProjectId, this.id, params)
        .then((response) => {
          if (!response.data.success) {
            if (key === 'hero-w-bg') {
              this.isHeroSceneWithBg = !value;
            } else if (key === 'hero-wo-bg') {
              this.isHeroSceneWithoutBg = !value;
            }
            this.alertError(
              '',
              'Oops, something went wrong. Try again later.',
              2000,
              'TopCenterNotif',
            );
          } else {
            this.updateChosenHeroScene(key);
            const successMsg = `Hero scene ${this.getBgType(key)} background ${
              value ? 'updated' : 'removed'
            }`;
            this.alertSuccess('', successMsg, 2000, 'TopCenterNotif');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getBgType(key) {
      if (key === 'hero-w-bg') {
        return 'with';
      }
      return 'without';
    },
    checkHeroSceneTag(key) {
      if (key === 'hero-w-bg' && !isEmptyObject(this.getHeroSceneWithBg)) {
        // Check if empty
        this.overwriteKey = key;
        this.showConfirmOverwriteHeroSceneWithBgTag = true;
      } else if (key === 'hero-wo-bg' && !isEmptyObject(this.getHeroSceneWithoutBg)) {
        // Check if empty
        this.overwriteKey = key;
        this.showConfirmOverwriteHeroSceneWithoutBgTag = true;
      } else {
        this.setHeroSceneTag(key, true);
      }
    },
    updateChosenHeroScene(value) {
      const sceneParams = {
        id: this.id,
        categoryName: this.selectedSceneCategoryName,
      };
      if (value === 'hero-w-bg') {
        this.updateHeroSceneWithBg(sceneParams);
      } else if (value === 'hero-wo-bg') {
        this.updateHeroSceneWithoutBg(sceneParams);
      }
    },
    setImageIsLoaded() {
      console.log('image is loaded');
      this.imageIsLoaded = true;
    },
  },
  mounted() {
    this.name = this.description;

    if (this.isDesigner && this.id) {
      this.isHeroSceneWithBg = this.is_hero_w_bg;
      this.isHeroSceneWithoutBg = this.is_hero_wo_bg;
    }

    if (this.$refs.imagePreview) {
      this.$refs.imagePreview.addEventListener('load', this.setImageIsLoaded);
    }
  },
  beforeDestroy() {
    if (this.$refs.imagePreview) {
      this.$refs.imagePreview.addEventListener('unload', this.setImageIsLoaded);
    }
  },
  watch: {
    is_hero_w_bg(val) {
      this.isHeroSceneWithBg = val;
    },
    is_hero_wo_bg(val) {
      this.isHeroSceneWithoutBg = val;
    },
    showVideo(val) {
      if (val) {
        this.$nextTick(() => {
          const { video } = this.$refs;
          if (video) {
            if (video.readyState >= 3) {
              this.showPreview = false;
              this.videoIsLoaded = true;
            } else {
              video.onloadedmetadata = () => {
                this.showPreview = false;
                this.videoIsLoaded = true;
              };
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.modular-template__sceneitem {
  width: 25%;
  margin: 0 8px 8px 0;
  padding: 0;
  background: var(--lightgrey100-darkgrey900);
  font-size: 0.875em;
  position: relative;
  overflow: visible;
  line-height: 10px;

  .lite-mode & {
    width: calc(25% - 10px) !important;
  }

  &.is-active,
  &.is-selected {
    .image::after {
      border: 3px solid $blue2;
    }
  }

  &:hover {
    .image {
      box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
    }

    .buttons button {
      transform: translateX(0);
    }

    .modular-template-scene-tags .hero-scene-tag {
      transform: translateY(0);
    }
  }

  &.is-loaded {
    background: none;

    .image {
      background: none;

      img {
        opacity: 1;
      }
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &.btn-add-modular-scene {
    width: 230px;
    height: 50px; // need to set this so the size doesn't go to same height as the other scene with name
    border: 2px solid $blue;
    align-items: center;
    border-radius: 4px;
    color: $darkGrey;
    transition: color 0.25s ease-in-out, background 0.25s ease-in-out;

    .design-overlay & {
      width: 163px;
    }

    .icon {
      font-size: 48px;
      margin-bottom: 15px;
      color: $blue;
    }

    span {
      font-weight: 500;
      display: block;
      width: 100%;
    }

    &:hover {
      background: $btnPrimaryHollowHover;
    }
  }

  &--video {
    position: absolute;
    padding: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: 40px;
    background-image: url('~@/assets/images/preloader/offeo_preloader.svg');
    background-repeat: no-repeat;
    background-position: center center;
    transition: opacity 0.25s 0.25s ease-in-out, transform 0.25s ease-in-out;

    &.is-loaded {
      background-image: none;

      video {
        opacity: 1;
      }
    }

    video {
      opacity: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: opacity 0.25s ease-in;
    }
  }

  .add-scene {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;
  }

  .image {
    position: relative;
    background: $lightGrey;
    padding: 0;
    z-index: 2;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    width: 100%;
    transition: box-shadow 0.25s ease-in-out;

    &:after {
      border: 3px solid transparent;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 6px);
      border-radius: 8px 8px 0 0;
      height: calc(100% - 6px);
      z-index: 3;
    }

    &.is-select-scene-image {
      border-radius: 4px;

      &:after {
        border-radius: 4px;
      }
    }

    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .scene {
      pointer-events: none;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: contain;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
    }

    .scene {
      position: absolute;
      top: 0;
      left: 0;
    }

    &.is-disabled {
      opacity: 0.3;
      cursor: auto;
    }
  }

  .duration {
    padding: 10px 10px 5px 0;
    cursor: auto;
    font-weight: 500;
    font-size: 0.875em;
    text-align: right;
    color: var(--darkgrey-white);
    background: var(--lightgrey300-darkgrey700);
    width: 100%;
    display: block;
  }

  .scene-item__description {
    height: 40px;
    background: var(--canvasbg-darkgrey600);
    color: var(--darkgrey-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.785rem;
    font-weight: 500;
    width: 100%;
    border-radius: 0 0 8px 8px;
    padding: 5px 8px 0;
    margin-top: -5px;
    position: relative;
    z-index: 5;
  }

  .buttons {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 150px;
    overflow: hidden;
    z-index: 3;

    button {
      transform: translateX(40px);
      transition: transform 0.15s ease-in-out;
    }
  }

  .modular-template-scene-tags {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 47px;
    z-index: 3;
    width: 100%;
    overflow: hidden;
    pointer-events: none;

    .btn {
      pointer-events: auto;
    }
  }

  .btn-modular-scene-item {
    position: absolute;
    width: 24px;
    height: 24px;
    background: $darkGrey;
    color: #fff;
    padding: 3px 5px;
    line-height: 1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 8px;
    right: 8px;
    font-size: 0.875em;
    font-weight: 400;
    z-index: 3;

    &:nth-child(2) {
      top: 40px;
    }

    &:nth-child(3) {
      top: 72px;
    }

    &:hover {
      background: $blue;
    }
  }
}

.modular-template__index {
  position: absolute;
  z-index: 4;
  background: $blue2;
  color: $light;
  width: 30px;
  height: 30px;
  font-size: 0.875rem;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 10px;
  left: 10px;
}
</style>
