<template lang="pug">
.storyboard-overlay
  button.storyboard-overlay__closebtn(
    type='button'
    @click="closeOverlay"
  )
    i.icon.icon-cancel
  .storyboard__title
    span.tag(:class="{'is-dark': isDarkMode}")
      | Total Duration:
      strong {{ allScenesDuration }}
    BaseButton(
      iconBefore="icon-play-all-scene"
      is-primary=true
      :is-canvas="true"
      @click="openProjectPreview"
    ) Play all scenes


  .scenes-container
    .scenes(
      v-scroll="closeActiveAnimationList"
    )
      draggable.scenes__list(
        v-model="scenes"
        draggable=".scenes--item--container"
        :animation="100"
      )
        SceneItem(
          v-for="(scene, index) in scenes"
          :key="scene.id"
          :scene="scene"
          :id="scene.id"
          :duration="scene.duration"
          :index="index"
          :leftoverDuration="leftoverDuration"
          :sceneWidth="sceneWidth"
          :is_hero_w_bg="scene.is_hero_w_bg"
          :is_hero_wo_bg="scene.is_hero_wo_bg"
          :selectedScenes="selectedScenes"
          @updateDuration="updateDuration"
          @select="updateSelectedScenes"
          @show-max-scene-warning="showMaxSceneWarning = true"
        )
      .js-add-scene.btn-add-scene(
        slot="footer"
        key="footer"
        :title="!showAddSceneOverlay ? 'Add Scene': ''"
        :style='buttonStyle'
        :class="{'is-dark': isDarkMode}"
        v-click-outside="hideShowAddSceneOverlay"
      )
        button.btn-add-scene__full(
          v-if="!showAddSceneOverlay"
          type='button'
          @click="openAddSceneModal"
        )
          i.icon.icon-plus
          span Add Scene
        div.storyboard__add-scene-button-list(v-else)
          button.empty-canvas__option(
            type="button"
            @click="createBlankScene"
          ) Empty Canvas
          button.add-scene__template-option(
            type="button"
            @click="openDesignTemplate"
          ) Template

    div.scene__overlay(
      v-show="showScenesOverlay"
      :class="{'is-dark': isDarkMode}"
    )
      div.loader-container
        Lottie.loader__animation(:options="canvasLoaderOptions")
        p.loader__text Please wait..

  AddSceneModal(
    v-if="showAddSceneModal"
    :modular-scenes="modularScenes"
    @close="closeAddSceneModal"
    @create-blank="createBlankScene"
    @create-image="createImageScene"
    @create-text="createTextScene"
    @create-logo="createLogoScene"
    @create-text-logo="createTextLogoScene"
    @open-design="openDesignTemplate"
  )
  ReplaceLogoOverlay(
    v-if="showLogoModal"
    title="My Brand"
    :isShow="showLogoModal"
    @closeOverlay="closeReplaceLogoOverlay"
    @select="selectLogoAndClose"
  )
  ReplaceImagesOverlay(
    v-if="showImageModal"
    title="Add images"
    :isShow="showImageModal"
    @closeOverlay="closeReplaceImagesOverlay"
    @selected-image-object="selectImageAndClose"
  )
  RemoveBgOption(
    v-if="showRemoveBgOption"
    @confirm="checkImageAndConfirmRemoveBg"
    @close="setShowRemoveBgOption(false)"
    @back="reopenImageModal"
  )
  BaseDialog(
    :isShow="showMissingCategoryWarning"
    isDanger=true
    noCancel=true
    text="Scene category is required"
    @confirm="showMissingCategoryWarning = false"
    @closeOverlay="showMissingCategoryWarning = false"
  )
  BaseDialog(
    :isShow="showSceneDurationWarning"
    noCancel=true
    button1Text="Close"
    :text="sceneDurationWarningText"
    @confirm="showSceneDurationWarning = false"
    @closeOverlay="showSceneDurationWarning = false"
  )
</template>

<script>
import {
  mapGetters, mapMutations, mapActions, mapState,
} from 'vuex';
import draggable from 'vuedraggable';
import vClickOutside from 'v-click-outside';
import { sceneFormat, maxDuration } from '@/assets/scripts/variables';
import { permutableTypes, groups } from '@/assets/scripts/enums';
import CanvasLoader from '@/assets/data/canvas-loader.json';

import ProjectApi from '@/services/api/ProjectApi';
import TemplateApi from '@/services/api/TemplateApi';

import SceneItem from './children/SceneItem.vue';
import AddSceneModal from './children/AddSceneModal.vue';
import ReplaceImagesOverlay from '@/components/SmartCreate/PageOverlay/ReplaceImagesOverlay.vue';
import ReplaceLogoOverlay from '@/components/SmartCreate/PageOverlay/ReplaceLogoOverlay.vue';
import RemoveBgOption from '@/components/SmartCreate/PageOverlay/RemoveBgOption.vue';
import Lottie from '@/components/ProjectCanvas/AssetSidebarCanvas/LottieShared.vue';

import uploadPermutationMixin from '@/components/mixins/upload-permutation-mixins';
import newSceneMixins from '@/components/mixins/new-scene-mixins';
import storyboardMixin from '@/components/mixins/storyboard-mixins';
import templateMixins from '@/components/mixins/template-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

let sceneReorderTimeout;

export default {
  name: 'StoryboardOverlay',
  mixins: [uploadPermutationMixin, newSceneMixins, templateMixins, darkModeMixin, storyboardMixin],
  components: {
    SceneItem,
    AddSceneModal,
    draggable,
    ReplaceImagesOverlay,
    ReplaceLogoOverlay,
    RemoveBgOption,
    Lottie,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      leftoverDuration: this.isLiteMode ? maxDuration.liteScene : maxDuration.scene,
      showOption: false,
      isModularTemplate: false,
      firstLoad: true,
      showImageModal: false,
      showLogoModal: false,
      isTextLogo: false,
      heroData: {},
      sceneWidth: 235,
      showMissingCategoryWarning: false,
      selectedScenes: [],
      showSceneDurationWarning: false,
      sceneDurationWarningText: '',
      showMaxSceneWarning: false,
      selectedSceneId: null,
      showAddSceneOverlay: false,
    };
  },
  computed: {
    ...mapState(['isLoggedIn', 'isTextTemplate', 'showAddSceneModal']),
    ...mapState('canvasElements', [
      'permutationId',
      'lastSelectedTemplateId',
      'lastSelectedLiteTemplateId',
      'templateModularId',
    ]),
    ...mapGetters(['getIsTemplate', 'isLiteMode']),
    ...mapGetters('userData', ['isFreeUser', 'isDesigner']),
    ...mapGetters('canvasElements', [
      'getScenes',
      'getAllScenesDuration',
      'getCurrentSceneDuration',
      'getCanvasSize',
      'getProjectId',
      'getMaxNewSceneDuration',
      'showScenesOverlay',
      'getProjectDetails',
      'getActiveSceneId',
      'getPermutationSceneIdBySlug',
      'getSceneDurationById',
      'getLiteTemplateScenes',
      'getModularTemplateScenes',
      'getCanvasSizeWithTemplate',
    ]),
    ...mapState('inspirationList', ['isTemporaryFile']),
    ...mapState('userData', ['userId']),
    subtitle() {
      return this.isLiteMode
        ? 'Manage all your scenes here. You can add, remove or reorder scenes to make a video of up to 15s.'
        : 'Manage your scenes. You can add, reorder or remove scenes here.';
    },
    allScenesDuration() {
      return `${parseFloat(this.getAllScenesDuration).toFixed(1)}s`;
    },
    totalScenes() {
      return this.getScenes.length;
    },
    buttonStyle() {
      const styleObject = {};

      const width = this.sceneWidth;
      const ratio = this.getCanvasSize.width / this.getCanvasSize.height;
      const height = width / ratio;

      styleObject.width = `${width}px`;
      styleObject.height = `${height}px`;

      return styleObject;
    },
    scenes: {
      get() {
        return this.getScenes;
      },
      set(value) {
        console.log('value', value);
        this.updateScenes(value);
        clearTimeout(sceneReorderTimeout);
        sceneReorderTimeout = setTimeout(() => {
          this.saveProject();
        }, 2000);
      },
    },
    modularScenes() {
      if (this.isLiteMode) {
        return this.getLiteTemplateScenes;
      }
      if (
        this.templateModularId === this.lastSelectedTemplateId
        && this.getCanvasSize.width === this.getCanvasSizeWithTemplate.width
        && this.getCanvasSize.height === this.getCanvasSizeWithTemplate.height
      ) {
        return this.getModularTemplateScenes;
      }
      return [];
    },
    canvasLoaderOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: CanvasLoader,
      };
    },
  },
  methods: {
    ...mapMutations([
      'setShowLastSelectedTemplate',
      'setShowSidebar',
      'setIsAddNewScene',
      'setThirtySecondsWarning',
      'setIsDownloadPreviewOpened',
      'setIsProjectPreview',
      'setShowCanvasPreloader',
      'closeActiveAnimationList',
      'setShowAddSceneModal',
      'setShowDesignContainer',
    ]),
    ...mapMutations('assetsSidebar', [
      'setActiveSubGroup',
      'setActiveGroup',
      'setShowDesignPreloader',
    ]),
    ...mapMutations('canvasElements', [
      'addSceneToArray',
      'updateScenes',
      'setModular',
      'updateActiveSceneId',
      'updateCanvasElementsEndingTime',
      'updateSceneDurationById',
    ]),
    ...mapMutations('inspirationList', ['setFiles']),
    ...mapActions('canvasElements', [
      'saveProject',
      'saveProjectScene',
      'updateScenesElements',
      'getStoryBoardPreview',
    ]),
    ...mapActions('canvasHistory', ['catchHistory']),
    checkOption() {
      const hitMax = this.checkForMaxScenes();
      if (hitMax) return;
      this.saveProject();
      this.showOption = true;
    },
    updateDuration(newDuration, lastSelectedScene) {
      // user can change duration of any scene
      // instead of just active scene
      const sceneId = typeof lastSelectedScene !== 'undefined' ? lastSelectedScene : this.selectedScenes[0];
      const selectedDuration = this.getSceneDurationById(sceneId);
      const durationWithoutThisScene = this.getAllScenesDuration - selectedDuration;
      const projectMaxDuration = this.maxDurationProject;

      if (durationWithoutThisScene + parseInt(newDuration, 10) > projectMaxDuration) {
        this.sceneDuration = selectedDuration;
        this.$forceUpdate();
        this.setThirtySecondsWarning(true);
        return;
      }

      if (newDuration > this.maxDurationScene) {
        this.sceneDurationWarningText = `The maximum duration of a scene is ${
          this.maxDurationScene
        } second${this.maxDurationScene > 1 ? 's' : ''}.`;
        this.showSceneDurationWarning = true;
        return;
      }

      if (newDuration < this.minDurationScene) {
        this.sceneDurationWarningText = `The minimum duration of a scene is ${
          this.minDurationScene
        } second${this.minDurationScene > 1 ? 's' : ''}.`;
        this.showSceneDurationWarning = true;
        return;
      }

      const oldValue = selectedDuration;
      let tempDuration = newDuration;

      if (newDuration <= this.minDurationScene) {
        tempDuration = this.minDurationScene;
      }

      this.checkMax();

      if (newDuration > this.maxDurationScene) {
        tempDuration = this.maxDurationScene;
      }

      console.table({
        newDuration,
        sceneId,
        selectedDuration,
        tempDuration,
      });

      const newSceneDuration = Math.round(tempDuration * 10) / 10;

      this.updateCanvasElementsEndingTime({
        sceneId,
        oldValue,
        newSceneDuration,
      });

      if (sceneId === this.getActiveSceneId) {
        this.$root.$emit('canvas-container-reset-timeline');

        this.catchHistory('scene');
      }

      this.updateSceneDurationById({
        sceneId,
        duration: newSceneDuration,
      });

      if (typeof newSceneDuration === 'number') {
        this.saveProjectScene({ sceneId });
      }
    },
    updateSelectedScenes(ids) {
      this.selectedScenes = ids;
    },
    openDesignTemplate() {
      this.setIsAddNewScene(true);
      // if last selected template id is 0 means it's not modular, then open design template
      // if (this.lastSelectedTemplateId === 0) {
      this.setShowSidebar(true);
      this.setActiveGroup(groups.DESIGNS); // open designs tab
      this.setShowDesignPreloader(false);
      this.setShowDesignContainer(true);
      // } else {
      //   // if modular then open the modular modal
      //   this.openSceneBuilder();
      // }
      this.closeAddSceneModal();
      this.showAddSceneOverlay = false;
    },
    addBlankScene() {
      // this.addSceneToArray();
      const projectId = this.getProjectId;
      const params = sceneFormat[0];
      const maxNewSceneDuration = this.getMaxNewSceneDuration;

      if (maxNewSceneDuration <= 0) {
        // eslint-disable-next-line
        alert('You have reached the maximum 30 sec duration for the project.');
        return;
      }

      this.setShowCanvasPreloader(true);
      if (params.duration > maxNewSceneDuration) {
        params.duration = Math.round(maxNewSceneDuration * 10) / 10;
      }

      if (this.leftoverDuration < 5) {
        params.duration = this.leftoverDuration;
      }

      const APIREQUEST = this.getIsTemplate ? TemplateApi : ProjectApi;

      APIREQUEST.addProjectScene(projectId, params)
        .then((response) => {
          // console.log('completed', response.data);
          if (response.data.success) {
            this.addSceneToArray({
              newId: response.data.results.id,
            });

            this.updateActiveSceneId(response.data.results.id);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.setShowCanvasPreloader(false);
        });

      this.showOption = false;
    },
    openSceneBuilder() {
      this.setShowLastSelectedTemplate(true);
    },
    closeSceneBuilder() {
      this.setShowLastSelectedTemplate(false);
    },
    onClickOutside(e) {
      const isAddScene = e?.path?.findIndex(
        path => path.className
            && typeof path.className === 'string'
            && path.className.indexOf('btn-add-scene') > -1,
      ) > -1;
      if (!isAddScene && this.showOption) this.showOption = false;
    },
    closeOverlay() {
      this.selectedSceneId = null;
      if (this.isDesigner && !this.isTextTemplate) {
        // check if all scenes has category
        const hasNoCategory = this.getScenes.findIndex(scene => !scene.modular_category_id) > -1;
        if (hasNoCategory) {
          this.showMissingCategoryWarning = true;
          return;
        }
      }

      this.$emit('close');
    },
    openProjectPreview() {
      this.setIsDownloadPreviewOpened(true);
      this.setIsProjectPreview(true);
    },
    closeAddSceneModal() {
      this.setShowAddSceneModal(false);
    },
    openAddSceneModal() {
      const hitMax = this.checkForMaxScenes();
      const isModularTemplate = !!this.modularScenes.length;
      if (hitMax) return;
      this.saveProject();

      if (this.isLiteMode && !this.getLiteTemplateScenes.length) {
        // for lite mode, if template only has 0 scene, create blank scene
        this.createBlankScene();
      } else if (this.isLiteMode && this.getLiteTemplateScenes.length === 1) {
        // for lite mode, if template only has 1 scene, auto duplicate preset template
        const templateId = this.lastSelectedLiteTemplateId;
        const sceneId = this.getLiteTemplateScenes[0].id;
        this.addNewLiteTemplateScene(templateId, sceneId);
      } else if (!this.isLiteMode && !isModularTemplate) {
        this.showAddSceneOverlay = true;
      } else {
        this.setShowAddSceneModal(true);
      }
    },
    // -----------------------
    // START all the create set
    createBlankScene() {
      this.selectedSceneId = null;
      this.addBlankScene();
      this.closeAddSceneModal();
      this.showAddSceneOverlay = false;
    },
    createImageScene(sceneId) {
      this.selectedSceneId = sceneId;
      this.showImageModal = true;
      this.closeAddSceneModal();
      // set the group so that it upload to the right tab
      this.setActiveGroup('my-files');
      this.$nextTick(() => {
        this.setActiveSubGroup('images');
      });
    },
    createLogoScene(sceneId) {
      this.selectedSceneId = sceneId;
      this.isTextLogo = false;
      this.showLogoModalSelection();
    },
    createTextScene(sceneId) {
      this.selectedSceneId = sceneId;
      this.addScene('text');
      this.closeAddSceneModal();
    },
    createTextLogoScene(sceneId) {
      this.selectedSceneId = sceneId;
      this.isTextLogo = true;
      this.showLogoModalSelection();
    },
    // END all the create set
    // -----------------------
    closeReplaceImagesOverlay() {
      this.showImageModal = false;
    },
    closeReplaceLogoOverlay() {
      this.showLogoModal = false;
    },
    selectImageAndClose(selectedImageObject) {
      this.heroData = {}; // clear the data

      if (typeof selectedImageObject.urls !== 'undefined') {
        // stock photos
        this.heroData.urlHd = selectedImageObject.urls.raw
          || selectedImageObject.urls.full
          || selectedImageObject.urls.regular
          || '';
        this.heroData.url = selectedImageObject.urls.regular
          || selectedImageObject.urls.small
          || selectedImageObject.urls.thumb
          || '';
        this.heroData.thumb = selectedImageObject.urls.small || selectedImageObject.urls.thumb || '';
      } else {
        /* users_files
         {
          "id": 367178,
          "user_id": 1,
          "folder_id": null,
          "file": "https://offeo-staging-assets.s3.us-east-2.amazonaws.com/user-uploads/1/image/1-850pQjAIHo.png",
          "preview_url": "https://offeo-staging-assets.s3.us-east-2.amazonaws.com/user-uploads/1/image/1-PbY0fLkfPn.png",
          "ext": "png",
          "is_preparing": false,
          "has_removed_bg": false
        }
       */
        // user files
        this.heroData.urlHd = selectedImageObject.file;
        this.heroData.url = selectedImageObject.file;
        this.heroData.thumb = selectedImageObject.preview_url;
        this.heroData.has_removed_bg = selectedImageObject.has_removed_bg || false;

        if (selectedImageObject.has_removed_bg) {
          // if image has removed bg, no need to show remove or retain background, insert the image directly
          this.setConfirmRemoveBg(true);
          this.checkImageAndConfirmRemoveBg(false);
        }
      }
      this.showImageModal = false;
      this.selectImage(this.heroData.url);
      this.closeReplaceLogoOverlay();
      if (!selectedImageObject.has_removed_bg) {
        this.setShowRemoveBgOption(true);
      }
    },
    selectLogoAndClose(url) {
      const additionalParams = {
        logoUrl: url,
        sceneId: this.selectedSceneId,
      };
      const type = this.isTextLogo ? permutableTypes.TEXT_LOGO : permutableTypes.LOGO;
      console.log('selectLogoAndClose', type, additionalParams);

      this.selectImage(url);
      this.addScene(type, additionalParams);
      this.closeReplaceLogoOverlay();
    },
    checkImageAndConfirmRemoveBg(item) {
      this.setShowRemoveBgOption(false);
      this.closeReplaceImagesOverlay();

      if (!item) {
        // Don't remove background image
        this.addScene('image', {
          heroData: this.heroData,
          sceneId: this.selectedSceneId,
        });
      } else {
        this.doConfirmRemoveBg(item);
      }
    },
    generateSceneWithImage(val) {
      console.log(val);
      // this.addScene('image');
      this.selectImage(val.data[0].w_960);
      this.heroData = {
        temporary_file: val.id,
        user_id: this.isTemporaryFile ? this.tmpUserId : this.userId,
      };
      this.addScene('image', { heroData: this.heroData });
      this.setShowRemoveBgOption(false);
      this.closeReplaceImagesOverlay();
    },
    showLogoModalSelection() {
      this.showLogoModal = true;
      this.closeAddSceneModal();
      // set the group so that it upload to the right tab
      this.setActiveGroup('my-files');
      this.$nextTick(() => {
        this.setActiveSubGroup('logos');
      });
    },
    reopenImageModal() {
      this.showImageModal = true;
      this.setShowRemoveBgOption(false);
    },
    addNewLiteTemplateScene(templateId, sceneId) {
      this.setShowCanvasPreloader(true);

      const params = {
        project_id: this.getProjectId,
        template_scenes: [sceneId],
      };

      ProjectApi.chooseTemplate(templateId, params)
        .then((response) => {
          if (response.data.success) {
            this.applyDesign({
              response,
            });

            const { scenes } = response.data.results;


            for (let i = 0; i <= scenes.length; i += 1) {
              // use websocket
              this.wsEventGenerateStoryboardThumbnail(this.getProjectId, this.getIsTemplate, scenes[i].id);

              // setTimeout(() => {
              //   if (scenes[i]) {
              //     this.getStoryBoardPreview({
              //       id: this.getProjectId,
              //       sceneId: scenes[i].id,
              //       isTemplate: this.getIsTemplate,
              //     });
              //   }
              // }, i * 3000);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.setShowCanvasPreloader(false);
        });
    },
    hideShowAddSceneOverlay() {
      this.showAddSceneOverlay = false;
    },
  },
  mounted() {
    this.clickedImageIndex = 0;
    this.selectedScenes.push(this.getActiveSceneId);
  },
  watch: {
    getScenes(value) {
      // Automatically convert between `template` and `modular` for designers
      if (this.getIsTemplate) {
        this.isModularTemplate = value.length > 1 && !this.getProjectDetails.isMultiScene;

        if (
          value.length > 1
          && !this.getProjectDetails.isModular
          && !this.getProjectDetails.isMultiScene
        ) {
          // Update to modular
          this.isModularTemplate = true;
          if (!this.firstLoad) this.openSceneBuilder();
          else this.firstLoad = false;
        } else if (value.length === 1 && this.getProjectDetails.isModular) {
          this.isModularTemplate = false;
        }

        // only need to call API if isModular is different
        if (
          !this.getProjectDetails.isMultiScene
          && this.isModularTemplate !== this.getProjectDetails.isModular
        ) {
          this.setModular(this.isModularTemplate);

          const params = {
            template_name: this.getProjectDetails.name,
            type: this.isModularTemplate ? 'modular' : 'template',
          };

          TemplateApi.update(this.getProjectId, params)
            .then((response) => {
              console.log('update template type', response);
              if (!response.data.success) {
                this.isModularTemplate = !this.isModularTemplate;
                this.setModular(this.isModularTemplate);
                this.closeSceneBuilder();
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    },
    showAddSceneModal() {
      if (this.showAddSceneModal) this.openAddSceneModal();
      else this.closeAddSceneModal();
    },
  },
};
</script>

<style lang="scss">
.storyboard__title {
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 35px;

  .title {
    display: inline-block;
    font-size: 1.75em;
    margin: 0.5em 0;
    vertical-align: middle;
  }

  .tag {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1;
    text-align: center;
    color: var(--blue800-white);
    background: $borderGrey;
    padding: 12px;
    border-radius: 4px;
    cursor: default;
    margin-left: 10px;

    &.is-dark {
      border: 1px solid $light;
      background: transparent;
    }

    strong {
      margin-left: 6px;
    }
  }

  .btn {
    margin-left: 10px;
    font-size: 0.875rem;
    height: 38px;
    font-weight: 500;

    .icon-play-all-scene {
      font-size: 1.125rem;
    }
  }
}

.storyboard__subtitle {
  margin-top: 0;
}

.storyboard-overlay {
  // need to be on top of navigation
  // update .nav-item__user z-index if you update this
  z-index: 22;
  background: var(--canvasbg-darkgrey700);
  position: absolute;
  width: 100%;
  height: calc(100% - 54px);
  top: 54px;
  left: 0;
  padding: 30px 60px 0;

  &:hover {
    box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
    border-color: $blue;
  }

  @include fb-requirement {
    padding: 20px;
  }
}

.storyboard-overlay__closebtn {
  background: none;
  border: 0;
  position: absolute;
  top: 35px;
  cursor: pointer;
  right: 20px;
  z-index: 2;
  font-size: 20px;
  color: var(--darkgrey-white);

  &:hover,
  &:focus {
    background: transparent;
  }
}
</style>
<style lang="scss" scoped>
// need to be scoped so won't clash with the other scenes container
.storyboard-overlay {
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba($darkGrey600, 0);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--timelinegrey-darkgrey800);
    border-radius: 8px;
  }
}

.scenes-container {
  width: 100%;
  text-align: left;
  height: calc(100% - 50px);
  overflow: auto;
}

.btn {
  &-open-scene-builder {
    margin-top: 20px;
    width: calc(100% - 60px);
  }
}

.scenes {
  padding: 5px 0 0 0;
  height: calc(100% - 25px);
  // height: calc(100% + 80px);
  position: relative;
  overflow: auto;

  .scenes__list {
    display: inline;
  }
}

.btn-add-scene {
  margin: 59px 13px 8px;
  background: var(--white-darkgrey600);
  color: var(--blue-white);
  cursor: pointer;
  display: inline-block;
  min-height: 140px;
  transition: color 0.25s ease-in-out, background 0.25s ease-in-out;
  width: 200px;
  border-radius: $componentBorderRadius;
  position: relative;
  padding: 5px;
  box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
  }

  .btn {
    margin: 3px 0;
    padding-left: 5px;
    padding-right: 5px;
    vertical-align: middle;
  }

  &:not(.is-dark) {
    border: 1px solid $blue;

    &:hover,
    &:focus {
      background: $btnPrimaryHollowHover;
    }
  }

  &:disabled,
  &:disabled:hover {
    background: lighten($darkGrey, 5%);
    color: $darkGrey;
    cursor: not-allowed;
    opacity: 0.5;
  }

  .btn-add-scene__full {
    background: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    border-radius: $componentBorderRadius;
    cursor: pointer;

    .icon {
      color: var(--blue-white);
      font-size: 1.5em;
      display: block;
      margin-bottom: 15px;
      margin-top: -6px;
    }

    span {
      font-size: 0.8125rem;
      font-weight: 500;
      color: var(--black-white);
    }
  }
}

.storyboard__add-scene-button-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .empty-canvas__option,
  .add-scene__template-option {
    width: 80%;
    font-size: 0.8125rem;
    color: var(--blue-white);
    padding: 11px 20px;
    border-radius: 4px;
    border: 1px solid var(--blue-white);
    font-weight: 500;
    background: transparent;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;

    &:hover {
      border-color: $lightGreen2;
      background: $lightGreen2;
      color: $light;
    }
  }

  .add-scene__template-option {
    margin-top: 10px;
  }
}

.scene__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($light, 0.8);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  &.is-dark {
    background: rgba($black, 0.8);
  }

  .loader-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .loader__animation {
    width: 100px !important;
    height: 100px !important;
  }

  .loader__text {
    margin: 5px 0 0;
    font-size: 1rem;
    font-weight: 400;
    color: var(--darkgrey-white);
  }
}
</style>
