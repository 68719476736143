<template lang="pug">
.element-outline.effect
  BaseSwitch.element-outline__switch.effect__switch(
    name="element-outline"
    :isChecked="isEnabled('outline')"
    :isBeta="true"
    @change="toggleEnable('outline')"
    label="Outline"
  )
  VueSlideUpDown.element-outline__content.effect__content(
    :class="{'effect-content--enabled': isEnabled('outline')}"
    :active="true"
  )
    .outline__color.effect__color
      span.title Colour
      button.color.btn-add-color(
        :class="{ 'is-active': isTextOutline }"
        type="button"
        :style="colorStyle"
        @click="openColorPicker"
      )
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="5"
      title="Size"
      :value="size"
      :step="0.1"
      @blur="onBlurInput($event, 'size')"
      @update="updateSize"
    )
    .effects__checkbox
      button.label(type="button" @click="toggleIsFillText") Fill Text
      span.checkbox(:class="{'is-checked': isFillText}" @click="toggleIsFillText")

    BaseButton.effects__reset-button(
      :is-canvas="true"
      is-dark=true
      is-full-width=true
      @click="resetOutline"
    ) Reset
</template>

<script>
import { mapState } from 'vuex';
import VueSlideUpDown from 'vue-slide-up-down';
import { textDataFormat } from '@/assets/scripts/variables';
import { hexToRgb } from '@/assets/scripts/utilities';
import effectsMixin from '@/components/mixins/effects-mixins';

export default {
  name: 'OutlineControls',
  components: {
    VueSlideUpDown,
  },
  mixins: [effectsMixin],
  computed: {
    ...mapState(['isTextOutline']),
    activeElement() {
      return this.getActiveElements[0];
    },
    colorStyle() {
      const styleObject = {};

      let outlineColor = '#ffffff';

      if (this.getActiveElements.length) {
        const { color } = this.activeElement.data.textOutline;

        const { r, g, b } = hexToRgb(color);
        outlineColor = `rgb(${r}, ${g}, ${b})`;
      }

      styleObject.background = outlineColor;

      return styleObject;
    },
    size() {
      return this.activeElement.data.textOutline.size;
    },
    isFillText() {
      return this.activeElement.data.textOutline.isFillText;
    },
  },
  mounted() {
    if (!this.isEnabled('outline')) {
      this.toggleEnable('outline');
    }
  },
  methods: {
    updateSize(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementTextOutline({ size: value });
      }
    },
    toggleIsFillText() {
      this.catchHistory('element');
      this.updateCanvasElementTextOutline({ isFillText: !this.isFillText });
    },
    onBlurInput(event, type) {
      this.onBlurEffectInput(event, {
        effect: 'textOutline',
        selectedInput: type,
      });
    },
    openColorPicker() {
      this.setIsTextOutline(true);
      this.$emit('open-colorpicker');
    },
    resetOutline() {
      this.catchHistory('element');

      this.updateCanvasElementTextOutline({
        color: textDataFormat.textOutline.color,
        size: textDataFormat.textOutline.size,
        isFillText: textDataFormat.textOutline.isFillText,
      });
    },
  },
};
</script>

<style lang="scss">
</style>
