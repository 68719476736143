<template lang="pug">
button.btn.hero-scene-tag(
  :class="[(isRemove ? 'remove-' : '' ) + `hero-scene-tag--${value}`]"
  type="button"
  @click="$emit('click')"
)
  | {{ name }}
  template(v-if="isRemove")
    i.icon.icon-cancel
</template>

<script>
export default {
  name: 'HeroSceneTag',
  props: {
    value: { type: String, default: '' },
    name: { type: String, default: '' },
    isHeroSceneWithBg: { type: Boolean, default: false },
    isHeroSceneWithoutBg: { type: Boolean, default: false },
  },
  computed: {
    isRemove() {
      if (this.value === 'hero-w-bg') return this.isHeroSceneWithBg;
      if (this.value === 'hero-wo-bg') return this.isHeroSceneWithoutBg;
      return false;
    },
  },
};
</script>

<style lang="scss">
.hero-scene-tag {
  border-radius: 20px;
  width: 100%;
  margin: 10px;
  margin-bottom: 8px;
  padding: 8px;
  transition: transform 0.25s ease-in-out;
}

.remove-hero-scene-tag--hero-w-bg,
.remove-hero-scene-tag--hero-wo-bg {
  justify-content: space-around;
  max-width: 50%;
}

.remove-hero-scene-tag--hero-w-bg,
.hero-scene-tag--hero-w-bg {
  background: $blue;
  color: $light;

  &:hover,
  &:focus {
    background: darken($btnPrimaryHover, 7%);
  }
}

.remove-hero-scene-tag--hero-wo-bg,
.hero-scene-tag--hero-wo-bg {
  background: $green;
  color: $light;

  &:hover,
  &:focus {
    background: darken($btnSuccessHover, 7%);
  }
}

.modular-template__sceneitem .modular-template-scene-tags,
.scenes--item {
  .hero-scene-tag {
    transform: translateY(40px);
  }

  .remove-hero-scene-tag--hero-w-bg,
  .remove-hero-scene-tag--hero-wo-bg {
    transform: translateY(0);
  }
}
</style>
