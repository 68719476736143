<template lang="pug">
  div.audio__fade-control
    h4.control-title Fade

    div.fadeinout__button-list(:class="{'is-dark': isDarkMode}")
      BaseButton(
        :class="{'btn--active': hasAudioFadeIn}"
        :is-hollow="true"
        :is-canvas="true"
        @click="toggleMusicFade('fade_in')"
      )
        span In
      BaseButton(
        :class="{'btn--active': hasAudioFadeOut}"
        :is-hollow="true"
        :is-canvas="true"
        @click="toggleMusicFade('fade_out')"
      )
        span Out
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'AudioFadeControl',
  mixins: [darkModeMixin],
  data() {
    return {
      hasAudioFadeIn: false,
      hasAudioFadeOut: false,
    };
  },
  computed: {
    ...mapGetters('canvasElements', ['getSelectedMusic']),
  },
  mounted() {
    this.hasAudioFadeIn = !!this.getSelectedMusic.fade_in;
    this.hasAudioFadeOut = !!this.getSelectedMusic.fade_out;
  },
  methods: {
    ...mapMutations('canvasElements', ['updateSelectedMusic']),
    toggleMusicFade(action = 'fade_in') {
      const selectedMusic = this.getSelectedMusic;

      if (action === 'fade_out') {
        selectedMusic.fade_out = !(
          !typeof selectedMusic.fade_out !== 'undefined' && selectedMusic.fade_out === true
        );
      } else {
        selectedMusic.fade_in = !(
          !typeof selectedMusic.fade_in !== 'undefined' && selectedMusic.fade_in === true
        );
      }

      this.updateSelectedMusic(selectedMusic);

      if (action === 'fade_out') {
        this.hasAudioFadeOut = !!this.getSelectedMusic.fade_out;
      } else {
        this.hasAudioFadeIn = !!this.getSelectedMusic.fade_in;
      }
    },
  },
};
</script>

<style lang="scss">
.audio__fade-control {
  width: 156px;
  display: flex;
  align-items: center;

  .control-title {
    font-size: 0.9375rem;
    color: var(--darkgrey-white);;
    margin: 0;
    font-weight: 500;
  }

  .fadeinout__button-list {
    margin-left: 15px;

    .btn {
      width: 50px;
      padding: 0;
      height: 36px;
      border-radius: 5px;
      font-size: 0.875rem;
      margin: 0;

      &:last-child {
        margin-left: 5px;
      }

      &.btn--active {
        background: $green600;
        border-color: $green600 !important;
        color: $light;

        &:hover {
          background: $green700;
          border-color: $green700 !important;
        }
      }
    }

    &.is-dark {
      .btn {
        border-color: $light;
        color: $light;

        &:hover {
          border-color: $light !important;
          background: $light;
          color: $darkGrey;
        }
      }
    }
  }
}
</style>
