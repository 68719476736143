<template lang="pug">
div.element-round-corners.effect
  BaseSwitch.element-round-corners__switch.effect__switch(
    name="element-round-corners"
    :isChecked="isEnabled('round-corners')"
    :isBeta="true"
    @change="toggleEnable('round-corners')"
    label="Round Corners"
  )
  VueSlideUpDown.element-round-corners__content.effect__content(
    :class="{'effect-content--enabled': isEnabled('round-corners')}"
    :active="true"
  )
    BaseSlider.effects__base-slider.slider--extra-margin-top(
      is-dark=true
      :min="0"
      :max="100"
      title="Rounding"
      :value="rounding"
      @blur="onBlurInput($event, 'rounding')"
      @update="updateRounding"
    )

    BaseButton.effects__reset-button(
      :is-canvas="true"
      is-dark=true
      is-full-width=true
      @click="resetRoundCorners"
    ) Reset
</template>

<script>
import VueSlideUpDown from 'vue-slide-up-down';
import { elementFormat } from '@/assets/scripts/variables';
import effectsMixin from '@/components/mixins/effects-mixins';

export default {
  name: 'RoundCornersControls',
  components: {
    VueSlideUpDown,
  },
  mixins: [effectsMixin],
  computed: {
    activeElement() {
      return this.getActiveElements[0];
    },
    rounding() {
      return this.activeElement.data.mediaRoundCorners.rounding;
    },
  },
  mounted() {
    if (!this.isEnabled('round-corners')) {
      this.toggleEnable('round-corners');
    }
  },
  methods: {
    updateRounding(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementMediaRoundCorners({ rounding: value });
      }
    },
    onBlurInput(event, type) {
      this.onBlurEffectInput(event, {
        effect: 'mediaRoundCorners',
        selectedInput: type,
      });
    },
    resetRoundCorners() {
      this.catchHistory('element');

      this.updateCanvasElementMediaRoundCorners({
        rounding: elementFormat.data.mediaRoundCorners.rounding,
      });
    },
  },
};
</script>

<style lang="scss">
</style>
