<template lang="pug">
div.element-border.effect
  BaseSwitch.element-border__switch.effect__switch(
    name="element-border"
    :isChecked="isEnabled('border')"
    :isBeta="true"
    @change="toggleEnable('border')"
    label="Border"
  )
  VueSlideUpDown.element-border__content.effect__content(
    :class="{'effect-content--enabled': isEnabled('border')}"
    :active="true"
  )
    div.border__color.effect__color
      span.title Colour
      button.color.btn-add-color(
        :class="{ 'is-active': isMediaBorder }"
        type="button"
        :style="colorStyle"
        @click="openColorPicker"
      )
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="20"
      title="Size"
      :value="size"
      :step="1"
      @blur="onBlurInput($event, 'size')"
      @update="updateSize"
    )

    BaseButton.effects__reset-button(
      :is-canvas="true"
      is-dark=true
      is-full-width=true
      @click="resetBorder"
    ) Reset
</template>

<script>
import { mapState } from 'vuex';
import VueSlideUpDown from 'vue-slide-up-down';
import { elementFormat, defaultMediaBorderSize } from '@/assets/scripts/variables';
import { hexToRgb } from '@/assets/scripts/utilities';
import effectsMixin from '@/components/mixins/effects-mixins';

export default {
  name: 'BorderControls',
  components: {
    VueSlideUpDown,
  },
  mixins: [effectsMixin],
  computed: {
    ...mapState(['isMediaBorder']),
    activeElement() {
      return this.getActiveElements[0];
    },
    colorStyle() {
      const styleObject = {};

      let borderColor = '#ffffff';

      if (this.getActiveElements.length) {
        const { color } = this.activeElement.data.mediaBorder;

        const { r, g, b } = hexToRgb(color);
        borderColor = `rgb(${r}, ${g}, ${b})`;
      }

      styleObject.background = borderColor;

      return styleObject;
    },
    size() {
      return this.activeElement.data.mediaBorder.size;
    },
  },
  mounted() {
    if (!this.isEnabled('border')) {
      this.toggleEnable('border');
    }
  },
  methods: {
    updateSize(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementMediaBorder({ size: value, isModifyingSize: true });
        this.$root.$emit('canvasElementSizeUpdated');
      }
    },
    onBlurInput(event, type) {
      this.onBlurEffectInput(event, {
        effect: 'mediaBorder',
        selectedInput: type,
      });
    },
    openColorPicker() {
      this.setIsMediaBorder(true);
      this.$emit('open-colorpicker');
    },
    resetBorder() {
      this.catchHistory('element');

      this.updateCanvasElementMediaBorder({
        color: elementFormat.data.mediaBorder.color,
        size: defaultMediaBorderSize,
      });
      this.$root.$emit('canvasElementSizeUpdated');
    },
  },
};
</script>

<style lang="scss">
</style>
