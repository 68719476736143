<template lang="pug">
.category-item(
  :class="categoryItemClass"
  :style="[styleObject, isShutterstockImage ? { 'height': `${imageHeight}px` } : {}]"
  @mouseenter="mouseEnter"
  @mouseleave="mouseLeave"
  ref="item"
  v-if="filterOnSwap"
)
  SkeletonItem(
    :fileProgress="fileProgress"
    :isPreparingVideo="isPreparing"
    :fileName="fileName || file_name || ''"
    v-if="isSkeleton"
  )
  Drag(
    v-else
    :draggable="isDraggable"
    :transfer-data="{ elementData }"
  )
    SkeletonItem(
      :isPreparingVideo="is_preparing"
      :isRetrying="showRetry"
      :fileName="fileName || file_name || ''"
      v-if="!isFileUsable"
    )
    //- Only for files
    .item-multiselect(v-if="isLoggedIn && !isExternalFile && isUserFile && !isSelectedTeamUpload && !showSwap")
      button.multiplefile-checkbox(
        type="button"
        :title="isMultipleSelected ? 'Deselect': 'Select'"
        :class="isMultipleSelected ? 'checked' : ''"
        @click="multipleFileClick"
      )

    //- .is-file-not-usable(
    //-   v-if="!isFileUsable"
    //-   :class="{'is-preparing' : is_preparing, 'is-show-retry' : showRetry}"
    //- ) {{isFileUsableText}}

    .category-item__preview(
      @click="addItem"
      :style="[!isFileUsable ? {'pointer-events': 'none'} : {}, isShutterstockImage && imageWidth > 0 ? {'width': `${imageWidth}px`} : {}]"
    )
      .category-item__height-controller(
        v-if="isDesign"
        :style="designItemHeightStyle"
      )
      //- If there is a preview
      template(v-if="hasPreview")

        //- If it has video
        .category-item__video(
          v-if="showVideo && isFileUsable"
          :class="{'is-loaded': videoIsLoaded}"
          :style="{ backgroundColor: bg_color }"
        )
          video(
            ref="video"
            :loop="true"
            :autoplay="false"
            :muted="true"
            :src="videoUrl"
          )
          BasePreloader.loader__animation(
            v-show="!videoIsLoaded"
            :type="12"
          )

        //- If it is animated json
        div.lottie-preloader(
          v-if="(isAnimated && showLottiePreloader)"
        )
        .category-item__lottie(
          :style="animatedPreviewStyle"
          v-if="isAnimated"
        )
          Lottie(
            v-if="lottieIsLoaded"
            :options="animatedPreviewOptions"
            v-on:animCreated="handleAnimation"
          )

        img.category-item__image(
          v-if="showPreview"
          :src="svgPreview"
          ref="image"
          :style="imagePreviewStyle"
        )
      template(v-else-if="hasUrls")
        img.category-item__image(:src="urls.thumb" ref="image")
        template(v-if="getActiveSubGroup === 'photo' && user.name && user.url")
          .credits__overlay
          span.credits
            i.icon.icon-info
            .info
              div by&nbsp;
              a(
                :href="user.url"
                target="_blank"
              ) {{ user.name }}
      template(v-else)
        | {{ name }}

    //- For design templates with multiple scenes
    template(v-if="isDesign && scenes.length > 1")
      span.overlay-box.total-scenes 1 of {{ scenes.length }}

    //- For animated json or video
    template(v-if="showAnimationIcon")
      i.overlay-box.icon.animated-icon(
        :class="{'icon-animated-json': (isAnimated && !isPattern), 'icon-play-triangle': (isVideo || isPattern) }"
        :title="(isVideo || isPattern) ? 'Video' : 'Animated element'"
      )

    //- Favourite button for non stock file
    template(v-if="isLoggedIn && !isExternalFile && !isSelectedTeamUpload")
      button.multiplefile-checkbox(
        type="button"
        :title="isMultipleSelected ? 'Deselect': 'Select'"
        v-if="showMultiSelectCheckbox || isMultipleFileSelection"
        :class="{ 'checked': isMultipleSelected, 'is-multiple-selection': isMultipleFileSelection }"
        @mouseenter="isMultiSelecting = true"
        @mouseleave="isMultiSelecting = false"
        @click="addItem"
      )

      .category-item__top-overlay(
        v-if="!isDesign && !isOnStylesTab"
        :style="[favouriteStyle, overlayStyle]"
      )
        AssetsFavourite.file-options__button.button--favourite-tab(
          v-if="!showSwap && (isFavourite || isOnBackgroundsTab || isUserFile || isGraphics)"
          :type="favType"
          :id="elementData.file_id"
          :fav-id="favId"
          :is-favourite="isFavourite"
          :is-html-shape="elementData.data.isHtml"
          :is-changing-icon-hover="true"
        )
        BaseButton.icon-trash-solid(
          v-if="!showSwap && isUserFile"
          class="file-options__button button--delete-tab"
          :is-icon="true"
          :is-danger="true"
          :plain-hover="true"
          @click.stop="$emit('delete-file', elementData.file_id)"
        )
</template>
<script>
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';
import { Drag } from 'vue-drag-drop';
import { debounce } from 'debounce';
import { groups, subgroups, favouriteFilters } from '@/assets/scripts/enums';
import { homeUrl, _time, elementFormat } from '@/assets/scripts/variables';
import {
  randomId, getVideoSize, getImageSize, optional,
} from '@/assets/scripts/utilities';
import Api from '@/services/api/Api';
import ProjectApi from '@/services/api/ProjectApi';
import TemplateApi from '@/services/api/TemplateApi';

import Lottie from '@/components/ProjectCanvas/AssetSidebarCanvas/LottieShared.vue';
import TemplateWarning from '@/components/ProjectCanvas/AssetsSidebar/children/TemplateWarning.vue';
import DeleteItem from '@/components/ProjectCanvas/AssetsSidebar/children/DeleteItem.vue';
import userFilesAndFolderMixins from '@/components/mixins/user-files-and-folder-mixins';
import templateMixins from '@/components/mixins/template-mixins';
import helperMixins from '@/components/mixins/helper-mixins';
import SkeletonItem from './SkeletonItem.vue';
import AssetsFavourite from '@/components/ProjectCanvas/AssetsSidebar/children/AssetsFavourite.vue';

let doCheckVideoReadyTimeout;

export default {
  name: 'CategoryItem',
  // inheritAttrs: false,
  mixins: [userFilesAndFolderMixins, templateMixins, helperMixins],
  data() {
    return {
      elementData: JSON.parse(JSON.stringify(elementFormat)),
      showVideo: false,
      showPreview: true,
      showLottiePreloader: false,
      imageIsLoaded: false,
      imageHeight: 100,
      imageWidth: 0,
      contentHeight: 40,
      lottieIsLoaded: false,
      anim: null,
      showAnimation: false,
      videoIsLoaded: false,
      showTemplateWarning: false,
      favId: 0,
      showRetry: false,
      isMultiSelecting: false,
    };
  },
  components: {
    Drag,
    Lottie,
    TemplateWarning,
    DeleteItem,
    SkeletonItem,
    AssetsFavourite,
  },
  props: {
    background: { type: String, default: 'transparent' },
    file: { type: String, default: '' },
    upload: { type: String, default: '' },
    preview_url: { type: String, default: '' },
    video_preview_url: { type: String, default: '' },
    thumbnailUrl: { type: String, default: '' },
    name: { type: String, default: '' },
    type: { type: String, default: '' },
    urls: { type: Object, default: () => ({}) },
    width: { type: Number, default: 500 },
    height: { type: Number, default: 500 },
    thumb_width: { type: Number, default: 100 },
    thumb_height: { type: Number, default: 100 },
    isShowThumbnail: { type: Boolean, default: false },
    template_type: { type: String, default: '' },
    preview: { type: Number, default: 500 },
    animation_type: { type: String, default: '' },
    url: { type: String, default: '' },
    urlHd: { type: String, default: '' },
    element_menu: { type: Array, default: () => [] },
    element_category_id: { type: Array, default: () => [] },
    element_category: { type: Array, default: () => [] },
    element_category_meta: { type: Array, default: () => [] },
    background_menu: { type: Array, default: () => [] },
    background_category_id: { type: Array, default: () => [] },
    background_category: { type: Array, default: () => [] },
    background_category_meta: { type: Array, default: () => [] },
    scenes: { type: Array, default: () => [] },
    template_name: { type: String, default: '' },
    is_brand: { type: Boolean, default: false },
    is_user_file: { type: Boolean, default: false },
    is_preparing: { type: Boolean, default: false },
    is_failed_upload: { type: Boolean, default: false },
    itemId: { type: Number, default: 0 },
    isLineCategory: { type: Boolean, default: false },
    isHoveringFileOptions: { type: Boolean, default: false },
    hoveredId: { type: Number, default: -1 },
    bg_color: { type: String, default: '' },
    is_html: { type: Boolean, default: false },
    shape: { type: String, default: '' },
    user: { type: Object, default: () => ({}) },
    has_removed_bg: { type: Boolean, default: false },
    file_name: { type: String, default: '' },
    start_time: { type: Number, default: 0.0 },
    end_time: { type: Number, default: 5.0 },
    start_trim: { type: Number, default: null },
    end_trim: { type: Number, default: null },
    fileName: { type: String }, // for skeleton
    id: [String, Number],
    fileType: { type: String },
    fileProgress: { type: Number },
    isPreparing: { type: Boolean }, // for skeleton
    isFailed: { type: Boolean },
    svg_url: { type: String, default: '' },
    is_branded_asset: { type: Boolean, default: false },
    isUnsplashImage: { type: Boolean, default: false },
    isSelectedTeamUpload: { type: Boolean, default: false },
  },
  computed: {
    ...mapState([
      'isLoggedIn',
      'showMultipleSwap',
      'showSwap',
      'isAddNewScene',
      'favouritedCollection',
      'showStoryboardOverlay',
      'showDesignContainer',
    ]),
    ...mapState('assetsSidebar', [
      'activeFavFilter',
      'multipleSelectedFiles',
      'isMultipleFileSelection',
    ]),
    ...mapState('canvasElements', [
      'elementsList',
      'selectedTemplateModular',
      'lastSelectedTemplateId',
      'shutterstockLoadingIds',
    ]),
    ...mapGetters('assetsSidebar', [
      'getActiveGroup',
      'getActiveCategory',
      'getActiveSubGroup',
      'getActiveGroupings',
      'getShutterstockSearchId',
      'getStockEndpointVersion',
    ]),
    ...mapGetters('canvasElements', [
      'getProjectId',
      'getSwappedElement',
      'getCurrentSceneDuration',
      'getActiveSceneId',
      'getCanvasSize',
    ]),
    ...mapGetters(['getCanvasZoom']),
    categoryItemClass() {
      const cssClass = [];
      if (this.isDesign) cssClass.push('category-item--design');
      if (this.imageIsLoaded) cssClass.push('is-loaded');
      if (this.isLineCategory) cssClass.push('is-line');
      if (this.isShape) cssClass.push('is-shape');
      if (this.isHtml) cssClass.push('is-html');
      if (this.isOnFavTab) cssClass.push('is-favourite');
      if (this.isHoveringFileOptions && this.id === this.hoveredId) cssClass.push('is-hovering-file-options');
      if (this.showRetry) cssClass.push('is-show-retry');
      if (this.isShutterstockImage) cssClass.push('is-stock');
      if (this.isUnsplashImage) cssClass.push('is-unsplash');

      return cssClass.join(' ');
    },
    debouncedMouseEnter: () => debounce(() => this.mouseEnter, 300),
    // id() {
    //   // not using props because stock photo / video is using string
    //   return this.$attrs.id;
    // },
    favType() {
      if (this.isOnFavTab) {
        if (this.isHtml) return 'htmlshape';
        return this.activeFavFilter;
      }

      let { type } = this;

      if (this.isBackground) {
        type = 'backgrounds';
      } else if (this.isDesign) {
        type = 'design';
      } else if (this.isMusic) {
        type = 'audio';
      } else if (this.isUserFile) {
        type = 'myfiles';
      } else if (this.isHtml) {
        type = 'htmlshape';
      } else if (this.template_type === 'text') {
        type = 'text';
      } else if (this.type !== 'text') {
        type = 'elements';
      }

      return type;
    },
    isFavourite() {
      if (this.getActiveGroup === groups.FAVOURITES) return true;
      return !!(this.getFavouriteElement() && this.getFavouriteElement().id);
    },
    isOnTextTab() {
      return this.getActiveGroup === groups.TEXTS;
    },
    isOnFavTab() {
      return this.getActiveGroup === groups.FAVOURITES;
    },
    isOnStylesTab() {
      return this.getActiveGroup === groups.STYLE;
    },
    isOnMyFilesTab() {
      return (
        this.getActiveGroup === groups.MYFILES
        || (this.isOnFavTab && this.activeFavFilter === favouriteFilters.MYFILES)
      );
    },
    isOnElementsTab() {
      return (
        this.getActiveGroup === groups.ELEMENTS
        || (this.isOnFavTab && this.activeFavFilter === favouriteFilters.ELEMENTS)
      );
    },
    isOnBackgroundsTab() {
      return (
        this.getActiveGroup === groups.BACKGROUNDS
        || (this.isOnFavTab && this.activeFavFilter === favouriteFilters.BACKGROUNDS)
      );
    },
    isNotLogo() {
      if (this.getActiveGroup !== groups.MYFILES) return true;
      if (this.getActiveSubGroup === subgroups.LOGO) return false;
      return true;
    },
    isExternalFile() {
      if (
        this.getActiveGroup === 'media'
        && (this.getActiveSubGroup === 'photo' || this.getActiveSubGroup === 'video')
      ) {
        return true;
      }

      return false;
    },
    elementMenu() {
      const { menu } = this.$attrs;

      return menu;
    },
    showAnimationIcon() {
      if (this.isVideo || this.isAnimated) return true;
      return false;
    },
    downloadLink() {
      if (this.file) {
        // modified in api to return full link
        return this.file;
      }

      let arr = [];
      let link = '';
      if (this.upload) {
        arr = JSON.parse(this.upload);
      }

      if (arr[0] && arr[0].download_link) {
        link = arr[0].download_link;
      }

      // eslint-disable-next-line
      if (link && link.indexOf('http') == -1) {
        // TODO: fix when CORS issue is fixed
        // cannot use staging url cause of CORS issue
        if (
          this.elementType === 'videos'
          || this.fileExt(link) === 'svg'
          || this.fileExt(link) === 'webm'
        ) {
          link = `/storage/${link}`;
        } else if (this.fileExt(link) === 'json') {
          link = `/public/storage/${link}`;
        } else {
          link = `${homeUrl()}/public/storage/${link}`;
        }
      }

      return link;
    },
    svgPreview() {
      if (!this.preview_url) return;
      let svgPreview = null;
      let url = '';

      if (this.preview_url.indexOf('http') !== -1) {
        svgPreview = this.preview_url;
        const newUrl = new URL(svgPreview);
        newUrl.searchParams.set('session', _time());
        url = newUrl.href;
      } else {
        // eslint-disable-next-line
        url = require(`@/assets/images/elements/${this.preview_url}`);
      }

      // return `${svgPreview}?session=${_time()}`;
      // eslint-disable-next-line
      return url;
    },
    hasPreview() {
      return this.preview_url !== '';
    },
    hasUrls() {
      return !!Object.keys(this.urls).length;
    },
    isCustom() {
      return this.getActiveSubGroup === subgroups.CUSTOMELEMENTS;
    },
    isImage() {
      return (
        (this.urls.thumb && this.urls.thumb !== '')
        || (this.fileExt(this.downloadLink) === 'svg' && this.elementType !== 'shapes')
        || this.fileExt(this.downloadLink) === 'jpg'
        || this.fileExt(this.downloadLink) === 'jpeg'
        || this.fileExt(this.downloadLink) === 'png'
      );
    },
    isMask() {
      return this.elementType === 'masks';
    },
    isSvg() {
      return this.fileExt(this.downloadLink) === 'svg';
    },
    isVideo() {
      return (
        (this.elementType === 'videos' && !this.isAnimated)
        || (this.downloadLink && this.fileExt(this.downloadLink) === 'webm')
      );
    },
    isAnimated() {
      return this.fileExt(this.downloadLink) === 'json';
    },
    isPattern() {
      return this.elementType === 'patterns' || (this.elementType === 'videos' && this.isAnimated);
    },
    isBackground() {
      return this.getActiveGroup === 'backgrounds';
    },
    isGraphics() {
      return this.getActiveGroup === groups.ELEMENTS;
    },
    isDesign() {
      return this.template_type === 'modular' || this.template_type === 'template';
    },
    isSkeleton() {
      return typeof this.fileName !== 'undefined';
    },
    isUserFile() {
      return this.getActiveGroup === groups.MYFILES;
    },
    isHtml() {
      return this.is_html;
    },
    isShape() {
      let isShape = false;
      const categoryMeta = this.element_category_meta;

      // html are shapes
      if (this.isHtml) return this.isHtml;

      if (categoryMeta) {
        isShape = categoryMeta.findIndex(cm => cm.key === 'is_shape' && cm.value) > -1;
      }
      return isShape;
    },
    isDesignerSvg() {
      return this.getActiveGroup === groups.MYFILES && this.isSvg;
    },
    elementType() {
      const subgroup = this.getActiveSubGroup;
      let type = subgroup;

      if (this.isOnStylesTab) {
        if (this.is_brand) {
          type = 'logos';
        } else if (this.svg_url) {
          type = 'graphics';
        } else {
          type = 'images';
        }
      } else if (this.isOnFavTab) {
        if (this.isOnElementsTab) {
          [type] = this.element_menu;
        } else if (this.isOnBackgroundsTab) {
          [type] = this.background_menu;
        } else if (this.isOnMyFilesTab) {
          if (this.isImage) type = 'images';
          else type = 'videos';
        }
      } else {
        if (subgroup === 'video') type = 'videos';
        if (subgroup === 'photo') type = 'images';

        if (typeof this.element_category_id !== 'undefined' && this.element_category_id !== null) {
          if ((!this.isBackground && this.getActiveCategory.isShape) || false) return 'shapes';
          if (this.isShape) return 'shapes';
        }

        if (this.getActiveCategory.isLine) type = 'lines';

        if (this.elementMenu && this.elementMenu.slug === 'masks') {
          type = 'masks';
        }

        // Custom elements will always be treated as graphics
        if (this.isCustom) {
          type = 'graphics';
        }

        if (this.isDesignerSvg) {
          type = 'shapes';
        }
      }

      return type;
    },
    styleObject() {
      const styleObject = {};

      const height = this.imageHeight;

      // if (this.isDesign) height += 66;
      // TODO: fix content height when user change page on design tab
      styleObject.height = `${height}px`;

      if (this.is_preparing) {
        styleObject.cursor = 'no-drop';
      }

      return styleObject;
    },
    favouriteStyle() {
      const styleObject = {};
      const width = this.$refs.item ? this.$refs.item.clientWidth : 230;

      if (this.isDesign && !this.isOnFavTab) styleObject.top = `${0.75 * width}px`;

      return styleObject;
    },
    overlayStyle() {
      const styleObject = {};
      if (this.isMyFilesCategory) styleObject.bottom = 38;

      return styleObject;
    },
    animatedPreviewStyle() {
      return {
        opacity: this.showPreview ? 0 : 1,
      };
    },
    imagePreviewStyle() {
      if (!this.isAnimated) return {};

      // - If there is json preview, hide it when animation is being shown
      return {
        opacity: this.showPreview ? 1 : 0,
      };
    },
    animatedPreviewOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: this.file,
      };
    },
    videoUrl() {
      if (this.video_preview_url) {
        const url = new URL(this.video_preview_url);
        url.searchParams.set('session', _time());
        return url.href;
      }

      if (this.downloadLink && this.fileExt(this.downloadLink) === 'webm') {
        const url = new URL(this.downloadLink);
        url.searchParams.set('session', _time());
        return url.href;
      }

      if (this.file && this.fileExt(this.file) === 'mp4') {
        // for my video files, the video url is in this.file
        return this.file;
      }

      return '';
    },
    isFileUsable() {
      return !this.is_preparing && !this.is_failed_upload;
    },
    isFileUsableText() {
      if (this.showRetry) {
        return 'Uploading failed';
      }
      if (this.is_preparing) {
        return 'Preparing Video';
      }
      return '';
    },
    isDraggable() {
      if (
        this.elementType === 'videos'
        || this.elementType === 'effects'
        || this.elementType === 'overlays'
        || this.elementType === 'images'
        || this.elementType === 'graphics'
        || this.elementType === 'masks'
        || this.elementType === 'patterns'
        || this.elementType === 'logos'
      ) {
        return true;
      }
      return false;
    },
    filterOnSwap() {
      if (this.showSwap) {
        if (this.getActiveGroup === groups.ELEMENTS) {
          const swappedElementType = this.getSwappedElement.type;

          if (swappedElementType === 'videos') {
            if (!this.isImage) {
              return true;
            }
            return false;
          }

          if (swappedElementType === 'graphics') {
            return true;
          }
        }
      } else if (this.showMultipleSwap) {
        // since multiple swap is only for image, hide the videos
        if (this.isVideo || this.isAnimated) return false;
      }
      return true;
    },
    isMultipleSelected() {
      const fileId = this.elementData.file_id;
      return this.multipleSelectedFiles.includes(fileId);
    },
    showMultiSelectCheckbox() {
      const { group, subgroup } = this.getActiveGroupings;
      return (
        this.isMultipleFileSelection && group === groups.MYFILES && subgroup !== subgroups.LOGO
      );
    },
    isSolid() {
      if (!this.shape) return false;
      return this.shape.indexOf('-solid') > -1;
    },
    isRounded() {
      if (!this.shape) return false;
      return this.shape.indexOf('-rounded') > -1;
    },
    isMyFilesCategory() {
      return this.getActiveGroup === groups.MYFILES;
    },
    designItemHeightStyle() {
      const styleObject = {};

      styleObject.width = '100%';
      styleObject.paddingTop = `${(this.height / this.width) * 100}%`;

      return styleObject;
    },
    isShutterstockAsset() {
      return (
        (this.getActiveSubGroup === 'photo' || this.getActiveSubGroup === 'video')
        && this.getStockEndpointVersion === 'v2'
        && !this.isUnsplashImage
      );
    },
    isShutterstockImage() {
      return this.getActiveSubGroup === 'photo' && this.getStockEndpointVersion === 'v2' && !this.isUnsplashImage;
    },
  },
  methods: {
    ...mapMutations([
      'setShowModularTemplate',
      'setShowDesignContainer',
      'setShowSidebar',
      'setShowCanvasPreloader',
    ]),
    ...mapMutations('assetsSidebar', [
      'setActiveGroup',
      'setActiveSubGroup',
      'setActiveCategory',
      'clearActiveCategory',
      'setIsMultipleFileSelection',
    ]),
    ...mapMutations('canvasElements', [
      'emptyActiveElements',
      'updateSwappedElement',
      // 'addTextTemplateToCanvas',
      'updateActiveSceneId',
      'updateTemplateModularId',
      'setSelectedTemplateModular',
      'updateCanvasBackground',
      'updateLastSelectedTemplateId',
      'updateCanvasSize', // mutation so that it doesn't save history
      'updateShutterstockElement',
      'updateShutterstockLoadingIds',
      'removeShutterstockLoadingId',
    ]),
    ...mapActions('canvasElements', [
      'addElementToCanvas',
      'updateScenesElements',
      'addTextTemplateToCanvas',
      'deleteElementFromCanvas',
    ]),
    ...mapActions('assetsSidebar', ['addMultipleSelectedFiles', 'removeMultipleSelectedFiles']),
    async addItem() {
      // console.log(
      //   'file: ' + this.file,
      //   'upload: ' + this.upload,
      //   'preview_url: ' + this.preview_url,
      //   'thumbnailUrl: ' + this.thumbnailUrl,
      //   'name: ' + this.name,
      //   'type: ' + this.type,
      //   'urls: ' + this.urls,
      //   'width: ' + this.width,
      //   'height: ' + this.height,
      //   'isShowThumbnail: ' + this.isShowThumbnail,
      //   'template_type: ' + this.template_type,
      //   'preview: ' + this.preview,
      //   'animation_type: ' + this.animation_type,
      //   'url: ' + this.url,
      //   'urlHd: ' + this.urlHd
      // );

      if ((this.isMultiSelecting && this.showMultiSelectCheckbox) || this.isMultipleFileSelection) {
        this.setIsMultipleFileSelection(true);
        this.multipleSelectItem();
        return;
      }

      if (!this.isFileUsable) return;

      if (this.showSwap) {
        this.$root.$emit('replace-image-is-loading', true);
        if (this.getStockEndpointVersion === 'v2' && this.isShutterstockAsset) {
          const elementId = this.getSwappedElement.data.id;
          const srcId = this.elementData.data.src_id;
          this.updateShutterstockLoadingIds({
            srcId,
            elementId,
          });
          await this.captureShutterstockAssetAndUpdateElement(
            {
              src_id: srcId,
            },
            elementId,
            typeof this.shutterstockLoadingIds[srcId] === 'undefined',
            true,
          );
        }

        this.updateSwappedElement(this.elementData);
      } else if (!this.showMultipleSwap) {
        if (this.template_type === 'text') {
          this.chooseTemplate();
        } else if (this.template_type) {
          if (this.template_type === 'template') {
            if (this.isAddNewScene) {
              // if user add from right sidebar add new scene button
              this.addTemplateScene();
            } else if (this.elementsList.allIds.length === 0) {
              // if there is no element, don't need warning
              this.replaceTemplateScene();
            } else {
              this.$root.$emit('show-confirm-design', { id: this.id });
            }
          } else if (this.template_type === 'modular') {
            this.useModularTemplate();
          }
        } else {
          this.addElement();
        }
      }
    },
    addTemplateScene() {
      this.chooseTemplate();
      this.showTemplateWarning = false;
    },
    replaceTemplateScene() {
      this.chooseTemplate(true);
      this.showTemplateWarning = false;
    },
    // cancelTemplate() {
    //   this.showTemplateWarning = false;
    // },
    addElement() {
      this.emptyActiveElements();
      setTimeout(async () => {
        this.elementData.data.id = randomId();
        const elementId = this.elementData.data.id;
        let isNewShutterstockAsset = false;

        if (this.isShutterstockAsset) {
          isNewShutterstockAsset = typeof this.shutterstockLoadingIds[this.elementData.data.src_id] === 'undefined';
          this.updateShutterstockLoadingIds({
            srcId: this.elementData.data.src_id,
            elementId,
          });
        }

        if (this.isBackground) {
          // make sure to set the background to default: #FFFFFF
          this.updateCanvasBackground('#FFFFFF');
        }

        this.addElementToCanvas(this.elementData);
        // console.log('add element', JSON.stringify(this.elementData));
        // console.log('-- add element --', this.elementData, elementFormat);

        // console.log('getActiveSubGroup', this.getActiveGroup, this.getActiveSubGroup);

        if (
          this.getActiveGroup === groups.MEDIA
          && (subgroups.PHOTOS === this.getActiveSubGroup
            || subgroups.VIDEOS === this.getActiveSubGroup)
        ) {
          // is stock media
          // capture assets
          const { data } = this.elementData;
          const params = {
            src_id: this.elementData.data.src_id,
          };

          if (this.getStockEndpointVersion === 'v1') {
            params.data_url = data.url;
            params.data_urlhd = data.urlHd || '';

            Api.captureAssets(params, this.getStockEndpointVersion);
          } else if (this.getStockEndpointVersion === 'v2') {
            await this.captureShutterstockAssetAndUpdateElement(
              params,
              elementId,
              isNewShutterstockAsset,
            );
          }
        }
      }, 100);

      if (this.getActiveGroup !== groups.MYFILES && this.getActiveGroup !== groups.MEDIA) {
        const elementTable = this.elementData.background === true ? 'backgrounds' : 'elements';
        this.trackData(elementTable, this.elementData.file_id); // track usage of template
      }
    },
    fileExt(url) {
      return url.split('.').pop();
    },
    useModularTemplate() {
      this.setShowModularTemplate(true);
      const templateId = this.id;
      this.updateTemplateModularId(templateId);
      this.setSelectedTemplateModular({
        id: this.id,
        name: this.template_name,
      });
      // this.setShowDesignContainer(false);

      // if the template modular id is the same as last selected template id,
      // don't need to call the api
      if (this.selectedTemplateModular.id === this.lastSelectedTemplateId) return;

      // get modular details
      TemplateApi.get(templateId, false)
        .then((response) => {
          if (response.data.success) {
            const result = response.data.results;
            const { scenes } = result;
            if (scenes.length) {
              this.updateScenesElements({
                canvasHeight: result.canvas_height,
                canvasWidth: result.canvas_width,
                newScenes: scenes,
                isModularTemplate: result.is_modular,
              });
              console.log('modular scenes updated');
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    chooseTemplate(isReplace) {
      // NOTE!!
      // if you update this function, update the confirmNewTemplate() in ModularTemplate.vue and BrandedDesignItem.vue too

      if (this.template_type !== 'text') {
        this.setShowCanvasPreloader(true);
      }

      // console.log('chooseTemplate', isReplace);
      // get scenes elements
      const templateId = this.id;

      const params = {
        project_id: this.getProjectId,
        override_selected_template: !this.showStoryboardOverlay,
      };

      if (isReplace) {
        // if replacing, add active_scene_id
        params.active_scene_id = this.getActiveSceneId;
      }

      console.table({
        isAddNewScene: this.isAddNewScene,
        isReplace,
      });

      if (!this.isAddNewScene && !isReplace) {
        // if it's not from right sidebar add scene button, add after_scene_id
        params.after_scene_id = this.getActiveSceneId;
      }

      if (this.elementsList.allIds.length === 0) {
        // if there is no elements, auto change the canvas dimension
        params.change_canvas_dimension = true;
      }

      console.log('params', params);
      console.log('Choose template', this.getProjectId);

      this.emptyActiveElements();

      ProjectApi.chooseTemplate(templateId, params)
        .then((response) => {
          if (response.data.success) {
            // console.log('response', JSON.parse(JSON.stringify(response.data.results)));

            if (this.template_type === 'text') {
              const result = response.data.results;
              // console.log('add text template', element, templateId)
              const { elements } = result.scenes[0];

              // text templates animateOutStart is '' or null so have to assign default 4.
              // when adding exit animation per each element, it throws error.
              /* eslint-disable */
              elements.forEach((element) => {
                element.time_out = this.getCurrentSceneDuration;
                element.timeline_settings.animateOutStart = element.timeline_settings.animateOutStart || 4;
              });
              /* eslint-enable */

              this.addTextTemplateToCanvas(elements);
            } else {
              this.applyDesign({
                response,
                isReplace,
              });
            }
          } else {
            this.setShowCanvasPreloader(false);
          }

          this.trackData('templates', templateId); // track usage of template
        })
        .catch((error) => {
          console.log(error);
          // if failed, show the design option again
          this.setShowCanvasPreloader(false);
          this.setShowSidebar(false);
          // this.getElements();
          this.setShowDesignContainer(true);
        });
    },
    setupElementData() {
      // this is where the element data is being set up so that it can add to canvas accordingly
      this.elementData.menu = this.getActiveGroup;
      // this.elementData.element_id = this.id;
      this.elementData.file_id = this.id;

      if (
        this.getActiveGroup === groups.MEDIA
        && (subgroups.PHOTOS === this.getActiveSubGroup || subgroups.VIDEOS === this.getActiveSubGroup)
      ) {
        // if stock store src_id
        this.elementData.data.src_id = this.id;
      }

      // isHtml is for html shapes
      this.elementData.data.isHtml = this.is_html;

      if (this.is_html) {
        const defaultShapeColor = '#a3a3a3';
        if (this.isSolid) {
          // #49ptgu - for filled shapes we default with 0 stroke
          this.elementData.data.color = [{ color: defaultShapeColor }];
          this.elementData.data.stroke = [
            {
              stroke: 0,
              strokeColor: defaultShapeColor,
            },
          ];
        } else {
          this.elementData.data.stroke = [
            {
              stroke: 10,
              strokeColor: defaultShapeColor,
            },
          ];
        }
        if (this.isRounded) {
          this.elementData.data.borderRadius = 50;
        }
      }

      if (!this.isAnimated && !this.isSvg) {
        this.elementData.color = [
          {
            color: '#000000',
          },
        ];
      }

      // isLine is for the line that is resizable
      this.elementData.data.isLine = this.name && this.name.toLowerCase().indexOf('resizable') > -1;

      if (this.is_html) {
        this.elementData.data.shape = this.shape;
        this.elementData.data.url = this.svgPreview;
      }

      if (this.isOnMyFilesTab || this.isOnStylesTab) {
        this.elementData.data.has_removed_bg = this.has_removed_bg;
        this.elementData.is_brand = this.is_brand;
      }

      if (this.svg_url) {
        this.elementData.data.svg_url = this.svg_url;
      }

      if (this.is_branded_asset) {
        this.elementData.isBrandedAsset = this.is_branded_asset;
      }

      if (this.isCustom) {
        this.elementData.data.has_removed_bg = true;
      }

      if (this.isOnElementsTab) {
        this.elementData.category = this.element_menu;
        this.elementData.sub_category_id = this.element_category_id;
        this.elementData.sub_category_name = this.element_category;
        this.elementData.sub_category_meta = this.element_category_meta;
      }

      if (this.isOnBackgroundsTab) {
        this.elementData.category = this.background_menu;
        this.elementData.sub_category_id = this.background_category_id;
        this.elementData.sub_category_name = this.background_category;
        this.elementData.sub_category_meta = this.background_category_meta;
      }

      this.elementData.type = this.elementType;
      this.elementData.background = this.isBackground;
      this.elementData.data.background = this.isBackground;
      this.elementData.animated = this.isAnimated;
      this.elementData.isImage = this.isImage;

      if (this.elementData.data.isLine) this.elementData.isImage = false;

      this.elementData.time_out = this.getCurrentSceneDuration;
      this.elementData.timeline_settings.animationDuration = this.elementData.time_out - this.elementData.time_in;
      this.elementData.timeline_settings.animateOutStart = this.elementData.time_out - this.elementData.timeline_settings.animateOutDuration;

      this.elementData.filters = {};

      if (this.fileExt(this.downloadLink) === 'webm') {
        this.elementData.type = 'videos';
      }

      if (this.elementType === 'videos' && this.isAnimated) {
        this.elementData.type = 'patterns';
      }

      if (this.downloadLink) {
        this.elementData.data.thumb = this.svgPreview;
        this.elementData.data.url = this.downloadLink;
        this.elementData.data.urlHd = this.downloadLink;
      }

      if (this.isImage && !this.isSvg) {
        if (this.urls.regular) {
          this.elementData.data.thumb = this.urls.thumb;
          this.elementData.data.url = this.urls.regular;
          this.elementData.data.urlHd = this.urls.full;
        }
      }

      if (this.isVideo || this.isAnimated) {
        this.elementData.animation_type = this.animation_type ? this.animation_type : 'none';
      }

      if (this.isAnimated) {
        this.elementData.animated = true;
        this.elementData.animation_preview = this.preview;

        this.elementData.data.animType = 'svg';
        this.elementData.data.autoplay = false;

        this.elementData.data.loop = this.elementData.animation_type === 'loop';

        this.elementData.data.prerender = true;
        this.elementData.data.thumb = this.svgPreview;
        this.elementData.data.path = this.downloadLink;
      }

      if (this.isVideo) {
        if (this.url) {
          this.elementData.data.thumb = this.thumbnailUrl;
          this.elementData.data.url = this.url;
          this.elementData.data.urlHd = this.urlHd;
        }
        this.elementData.data.time_start = optional(this.start_trim, 0);
        this.elementData.data.time_end = optional(this.end_trim, this.elementData.time_out);
        // this.elementData.time_out = this.getCurrentSceneDuration;
        this.elementData.time_out = optional(this.end_trim, this.getCurrentSceneDuration);
      }

      if (this.isVideo || (this.isImage && !this.isMask)) {
        this.elementData.blend = '';
        this.elementData.tint = 0;
        this.elementData.filters = {
          blur: 0,
          brightness: 1,
          contrast: 1,
          hue: 0,
          name: '',
          saturate: 1,
          sepia: 0,
        };
        this.elementData.data.image = {
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        };
      }

      /* eslint-disable */
      if (this.isMask) {
        this.elementData.data.image = {
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          url: require('@/assets/images/backgrounds/replace.jpg'),
          isNew: true,
        };
        // isNew is so that the mask can recalculate the position and size of the image
      }
      /* eslint-enable */

      // this.elementData.data.name = this.elementData.type;
      let layerName = '';

      if (this.getActiveGroup !== groups.TEXTS) {
        if (this.getActiveGroup === groups.STYLE) {
          layerName = 'Branded';
        } else {
          layerName = this.getActiveSubGroup;
          if (this.isOnElementsTab) {
            // eslint-disable-next-line
            layerName = this.element_menu[0];

            if (this.isCustom) {
              layerName = 'Graphic';
            }
          } else if (this.isOnBackgroundsTab) {
            // eslint-disable-next-line
            layerName = this.background_menu[0];
          } else if (this.isOnMyFilesTab) {
            if (this.is_brand) layerName = 'Logo';
            else if (this.isImage) layerName = 'Image';
            else layerName = 'Video';
          }
        }
      }

      if (layerName) {
        layerName = layerName.replace(/s$/, '');
        layerName = layerName.replace(/^\w/, c => c.toUpperCase());
        this.elementData.data.name = layerName;
      }

      if (this.elementData.size.width !== 'auto' && this.elementData.size.width > 700) {
        const ratio = this.elementData.size.width / 700;
        this.elementData.size = {
          width: 700,
          height: this.elementData.size.height / ratio,
        };
      }

      if (this.element_category_meta.length) {
        this.element_category_meta.forEach((meta) => {
          this.updateElementDataSize(meta);

          if (meta.key === 'is_screen_mode' && parseInt(meta.value, 10) === 1) {
            this.elementData.blend = 'screen';
          }
        });
      }

      this.elementData.isCustom = this.isCustom;

      if (this.isDesignerSvg) {
        // Typically from designer upload - Force all attributes so it acts as shape
        this.elementData.category = 'graphics';
        this.elementData.data.aspectRatio = {
          wh: 'auto',
          hw: 'auto',
        };
        this.elementData.data.menu = 'elements';
        this.elementData.data.name = 'Graphic';
        this.elementData.data.originalSize = {
          width: {
            value: 'auto',
            multiplier: '100%',
          },
          height: {
            value: 'auto',
            multiplier: '100%',
          },
        };
        this.elementData.type = 'graphics';
      }

      if (this.isImage && !this.isOnBackgroundsTab) {
        getImageSize(this.elementData.data.url, (imageWidth, imageHeight) => {
          const maxWidth = this.getCanvasSize.width * 0.75;
          const maxHeight = this.getCanvasSize.height * 0.75;

          const maxSize = maxWidth > maxHeight ? maxHeight : maxWidth; // get the lowest value;

          let imageRatio = 1;
          let newImageWidth = imageWidth;
          let newImageHeight = imageHeight;

          // set minimum width to 0.75 of canvas first
          // don't allow image added to be too small, eg 100px
          if (imageWidth < maxSize) {
            imageRatio = maxSize / newImageWidth;
            newImageWidth = maxSize;
            newImageHeight = imageHeight * imageRatio;
          }

          // need to set maximum size to 0.75 of canvas
          // don't allow image added to be too big
          if (imageWidth > maxSize || imageHeight > maxSize) {
            if (imageWidth > maxSize) {
              imageRatio = maxSize / newImageWidth;
              newImageWidth = maxSize;
              newImageHeight = imageHeight * imageRatio;
            }
            if (newImageHeight > maxSize) {
              // vertical
              imageRatio = maxSize / newImageHeight;
              newImageHeight = maxSize;
              newImageWidth *= imageRatio;
            }
          }

          this.elementData.size = {
            width: newImageWidth,
            height: newImageHeight,
          };

          this.elementData.position = {
            x: this.getCanvasSize.width / 2,
            y: this.getCanvasSize.height / 2,
          };
        });
      }
    },
    mouseEnter() {
      if (this.isVideo || (this.isDesign && this.videoUrl !== '')) {
        this.showVideo = true;
        this.videoIsLoaded = false;
      } else if (this.isAnimated) {
        this.loadAnimation();
      }
    },
    mouseLeave() {
      this.debouncedMouseEnter.clear();
      this.showPreview = true;

      if (this.isVideo || (this.isDesign && this.videoUrl !== '')) {
        this.showVideo = false;
        this.videoIsLoaded = false;
      } else if (this.isAnimated) {
        this.showLottiePreloader = false;
        this.stopLottie();
      }
    },
    // checkForVideo() {
    // if (!this.isVideo) return;

    // this.showVideo = true;
    // },
    loadAnimation() {
      this.lottieIsLoaded = true;
      this.showAnimation = true;
      this.showLottiePreloader = true;

      if (this.anim) {
        this.playLottie();
      }
    },
    handleAnimation(anim) {
      this.anim = anim;
      this.playLottie();
    },
    playLottie() {
      if (this.showAnimation) {
        this.anim.play();
        this.showLottiePreloader = false;
        this.showPreview = false;
      }
    },
    stopLottie() {
      if (this.anim) this.anim.stop();
      this.showAnimation = false;
      this.showPreview = true;
    },
    doCheckVideoIfReady() {
      if (this.is_preparing) {
        Api.checkIfFileReady(this.id)
          .then((response) => {
            if (response.status === 200) {
              const url = response.data.results.file;
              const filename = url.substring(url.lastIndexOf('/') + 1);

              if (this.isMyFilesCategory) {
                this.setActiveSubGroup('videos');
                this.clearActiveCategory();
              }

              this.alertSuccess(
                'Hey there!',
                `${filename} is ready for use in your project.`,
                5000,
                'TopCenterNotif',
              );
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              const { message, results } = error.response.data;

              // eslint-disable-next-line
              let fileName = 'Your file';

              if (message === 'Failed to upload file') {
                if (typeof results.file_name !== 'undefined') fileName = results.file_name;
                // this.alertError('', `${fileName} failed to upload.`, 5000, 'TopCenterNotif'); // alert will be on websockets-mixins
                this.showRetry = true;
              }
            }
          });
      }
    },
    multipleSelectItem() {
      const fileId = this.elementData.file_id;

      if (this.isMultipleSelected) {
        this.removeMultipleSelectedFiles(fileId);
      } else {
        this.addMultipleSelectedFiles(fileId);
      }
    },
    updateElementDataSize(meta) {
      if (meta.key === 'is_overlay' && parseInt(meta.value, 10) === 1) {
        if (this.isVideo) {
          getVideoSize(this.elementData.data.url, (videoWidth, videoHeight) => {
            this.processGetElementSize(videoWidth, videoHeight);
          });
        } else if (this.isImage) {
          getImageSize(this.elementData.data.url, (imageWidth, imageHeight) => {
            this.processGetElementSize(imageWidth, imageHeight);
          });
        }
      }
    },
    processGetElementSize(elementWidth, elementHeight, canvasScale = 1) {
      const canvasWidth = this.getCanvasSize.width * canvasScale;
      const canvasHeight = this.getCanvasSize.height * canvasScale;

      let overlayWidth;
      let overlayHeight;
      let ratio;

      if (elementWidth < canvasWidth || overlayWidth == null) {
        ratio = canvasWidth / elementWidth;
        overlayHeight = elementHeight * ratio;
        overlayWidth = canvasWidth;
      }

      if (overlayHeight < canvasHeight || overlayHeight == null) {
        ratio = canvasHeight / elementHeight;
        overlayWidth = elementWidth * ratio;
        overlayHeight = canvasHeight;
      }

      if (overlayWidth === 0 && overlayHeight === 0) {
        overlayWidth = elementWidth;
        overlayHeight = elementHeight;
      }

      this.elementData.size = {
        width: overlayWidth,
        height: overlayHeight,
      };

      this.elementData.position = {
        x: canvasWidth / 2 / canvasScale,
        y: canvasHeight / 2 / canvasScale,
      };
    },
    getFavouriteElement() {
      const collection = this.favouritedCollection[this.favType];

      if (!collection || collection.length === 0) return false;

      // it may be comparing string and int
      // check whether item exist since getFavourite getters will also check that
      const favourite = collection.find(
        // eslint-disable-next-line
        element => element.element_id == this.id && !!element.item,
      );

      return favourite;
    },
    updateFavId(id) {
      this.favId = id;
    },
    retryUpload() {
      if (!this.showRetry) return;

      console.log('retry upload');

      Api.retryUpload({
        file_id: this.id,
      })
        .then(() => {
          this.alertSuccess('Hey there!', 'Retrying upload...', 3000, 'TopCenterNotif');
          this.showRetry = false;
        })
        .catch((error) => {
          console.log(error);
          this.alertError('', 'Retrying upload failed.', 5000, 'TopCenterNotif');
        });
    },
    checkImageHeight() {
      // console.log('is loaded')
      if (!this.isShutterstockImage) this.imageIsLoaded = true;
      const height = this.$refs.image && this.$refs.image.height ? this.$refs.image.height : 100;

      // if (this.isDesign && this.isOnFavTab && this.$refs.item) {
      //   height = this.$refs.item.clientWidth;
      // } else if (this.isDesign && this.$refs.item) {
      //   height = 0.75 * this.$refs.item.clientWidth;
      // }
      // console.log('get image', height, this.$refs.item.clientWidth);
      this.imageHeight = height;
    },
    removeWhitestripOnShutterstockImage() {
      // Set the width based on actual aspect ratio to remove Shutterstock whitestrip
      if (this.$refs.image) {
        getImageSize(this.urls.thumb, (imageWidth, imageHeight) => {
          if (this.thumb_width !== imageWidth) {
            // Whitestrip is on the right
            this.imageHeight = this.$refs.image.width / (this.thumb_width / this.thumb_height);
            this.imageWidth = this.imageHeight * (imageWidth / imageHeight);
          } else if (this.thumb_height !== imageHeight) {
            // Whitestrip is at the bottom
            this.imageHeight -= imageHeight - this.thumb_height;
          }

          this.imageIsLoaded = true;
        });
      }
    },
    checkBeforeChooseTemplate({ id, isReplace }) {
      if (id === this.id) {
        this.chooseTemplate(isReplace);
      }
    },
    multipleFileClick() {
      console.log('multipleFileClick');
      this.setIsMultipleFileSelection(true);
      this.multipleSelectItem();
    },
    async captureShutterstockAssetAndUpdateElement(
      defaultParams,
      elementId,
      isNewShutterstockAsset = false,
      isSwapping = false,
    ) {
      const params = { ...defaultParams };
      const srcId = params.src_id;
      if (this.getActiveSubGroup === 'photo') params.type = 'image';
      else if (this.getActiveSubGroup === 'video') params.type = 'video';
      params.search_id = this.getShutterstockSearchId;

      this.$root.$emit('capture-ss-asset', {
        id: params.src_id,
        type: params.type,
        elementData: this.elementData,
      });

      let assetData;
      await Api.captureAssets(params, this.getStockEndpointVersion)
        .then((response) => {
          console.log(response);
          assetData = response;
        })
        .catch((error) => {
          // console.error('!!!!captureShutterstockAssetAndUpdateElement', error.response);
          this.alertWarn(
            '',
            error.response.data.message || 'The selected stock is not available at the moment.',
            5000,
            'TopCenterNotif',
          );
          this.postShutterstockLoading(elementId, srcId, params.type);
          this.deleteElementFromCanvas(elementId);
        });

      if (!assetData) {
        return;
      }
      // console.log('assetData', assetData.data.results);

      if (this.getActiveSubGroup === 'photo') {
        this.elementData.data.thumb = assetData.data.results.thumb;
        this.elementData.data.url = assetData.data.results.url;
        this.elementData.data.urlHd = assetData.data.results.urlHd;
      } else if (this.getActiveSubGroup === 'video') {
        this.elementData.data.url = assetData.data.results.url;
        this.elementData.data.urlHd = assetData.data.results.urlHd;
      }

      if (isSwapping) {
        if (srcId) {
          this.elementData.data.src_id = srcId;
        }
        this.updateSwappedElement(this.elementData);
      } else if (assetData.data.results.exists) {
        this.updateShutterstockElement({
          id: elementId,
          elementData: this.elementData,
        });

        // Need to add faux delay to prevent Shutterstock watermark from appearing if image hasn't loaded before
        if (!isNewShutterstockAsset) {
          this.postShutterstockLoading(elementId, srcId, params.type);
        } else {
          setTimeout(() => {
            this.postShutterstockLoading(elementId, srcId, params.type);
          }, 1500);
        }
      }
    },
    postShutterstockLoading(elementId, srcId, type) {
      this.removeShutterstockLoadingId({ elementId, srcId });
      this.$root.$emit('capture-ss-asset-done', { id: srcId, type });
    },
  },
  beforeDestroy() {
    if (this.$refs.image) {
      this.$refs.image.removeEventListener('load', this.checkImageHeight);
      if (this.isShutterstockImage) {
        this.$refs.image.removeEventListener('load', this.removeWhitestripOnShutterstockImage);
      }
    }
    this.$root.$off('confirm-design', this.checkBeforeChooseTemplate);
    window.removeEventListener('resize', this.checkImageHeight);
  },
  mounted() {
    console.log('fileProgress', this.fileProgress);
    clearTimeout(doCheckVideoReadyTimeout);
    doCheckVideoReadyTimeout = setTimeout(() => {
      this.doCheckVideoIfReady();
    }, 30000);
    this.setupElementData();
    if (this.$refs.content) this.contentHeight = this.$refs.content.clientHeight;

    if (this.$refs.image) {
      this.$refs.image.addEventListener('load', this.checkImageHeight);
      if (this.isShutterstockImage) {
        this.$refs.image.addEventListener('load', this.removeWhitestripOnShutterstockImage);
      }
    } else {
      this.imageIsLoaded = true;
    }

    if (this.isOnFavTab) {
      this.favId = this.$attrs.favId || 0;
    } else {
      this.favId = this.isFavourite ? this.getFavouriteElement().id : 0;
    }

    this.$emit('item-is-loaded', true);

    if (this.is_failed_upload) {
      this.showRetry = true;
    }

    this.$root.$on('confirm-design', this.checkBeforeChooseTemplate);

    window.addEventListener(
      'resize',
      debounce(() => {
        this.checkImageHeight();
      }, 300),
    );
  },
  watch: {
    getActiveSubGroup() {
      this.setupElementData();
    },
    id() {
      // console.log('this height', this.contentHeight);
      if (this.$refs.content) this.contentHeight = this.$refs.content.clientHeight;
      // console.log('this height', this.contentHeight);
    },
    imageIsLoaded(val) {
      if (val) {
        this.$nextTick(() => {
          const { item } = this.$refs;
          if (item) {
            let background = 'transparent';
            if (this.isOnMyFilesTab) {
              // eslint-disable-next-line
              background = `url(${require('@/assets/images/backgrounds/transparent-dark.jpg')})`;
            }

            this.$refs.item.style.background = background;

            this.$emit('item-is-loaded', true);
          }
        });
      }
    },
    showVideo(val) {
      if (val) {
        this.$nextTick(() => {
          const { video } = this.$refs;
          if (video) {
            video.onloadedmetadata = () => {
              /**
               * make sure video should play or not before hiding preview
               * (or) it will become blank because of hiding both video and preview
               */
              video.play();
              this.showPreview = !this.showVideo;
              this.videoIsLoaded = true;
              this.$emit('item-is-loaded', true);
            };
          }
        });
      } else {
        const { video } = this.$refs;
        if (video) {
          video.pause();
          video.src = '';
          video.load();
        }
      }
    },
    lottieIsLoaded() {
      if (this.lottieIsLoaded) this.$emit('item-is-loaded', true);
    },
    getCanvasSize: {
      handler() {
        if (this.element_category_meta.length) {
          this.element_category_meta.forEach((meta) => {
            this.updateElementDataSize(meta);
          });
        }
        this.setupElementData();
      },
      deep: true,
    },
    sidebarElements: {
      handler() {
        this.setupElementData();
      },
      deep: true,
    },
    is_failed_upload(val) {
      this.showRetry = val;
      this.$forceUpdate();
      this.setupElementData();
    },
    is_preparing() {
      this.$forceUpdate();
      this.setupElementData();
    },
  },
};
</script>

<style lang="scss">
.category-item {
  width: 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  height: 100px;
  background: $darkGrey2;
  text-transform: capitalize;
  transition: height 0.25s ease-in-out, background 0.25s 0.5s ease-in-out;
  min-height: 110px;

  &.is-unsplash {
    height: 100% !important;

    .category-item__preview {
      width: 100% !important;
    }
  }

  &.is-show-retry,
  &:hover,
  &.is-hovering-file-options {
    .category-item__top-overlay,
    .category-item__bottom-overlay,
    .multiplefile-checkbox {
      opacity: 1;

      button {
        opacity: 1;
      }
    }
  }

  &.is-html,
  &.is-shape {
    border-radius: 0;
  }

  &.is-loaded {
    background: transparent;

    .category-item__image {
      opacity: 1;
    }

    .is-preparing {
      // re-trimming old video permanently
      position: absolute;
      height: 100% !important;
      opacity: 0.9;
    }
  }

  &.is-line {
    min-height: 40px;
  }

  &.is-stock {
    min-height: 0;
  }

  .is-file-not-usable {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $light;
    font-size: 0.75rem;
    pointer-events: none;

    &.is-preparing {
      cursor: no-drop;
    }
  }

  .template-type {
    background: $blue;
    color: $light;
    padding: 5px 10px;
    display: inline-block;
    border-radius: 4px;
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  div {
    width: 100%;
  }

  .category-item__video {
    position: absolute;
    z-index: 2;
    height: 100%;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity 0.25s 0.25s ease-in-out, transform 0.25s ease-in-out;

    &.is-loaded {
      video {
        opacity: 1;
      }
    }

    video {
      opacity: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 0.25s ease-in;
    }

    .loader__animation {
      width: 45px !important;
      height: 45px !important;
      position: absolute;
      z-index: 3;
      height: 100%;
      margin: auto;
      left: -5px;
      bottom: -3px;
    }
  }

  .category-item__lottie {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $lightBg;
  }

  .btn-favourite.is-active {
    opacity: 1;
  }

  &:hover,
  &.is-hovering-file-options {
    .category-item__image {
      transform: scale(1.05);
    }

    .category-item__bottom-overlay::before {
      transform: translateY(0);
    }

    .overlay-box.total-scenes {
      opacity: 1;
    }
  }

  .overlay-box {
    position: absolute;
    min-width: 24px;
    height: 24px;
    background: $darkGrey;
    color: $light;
    padding: 3px 5px;
    line-height: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 8px;
    right: 8px;
    font-size: 0.6em;
    font-weight: 500;
    z-index: 3;

    &::before {
      margin-left: 2px;
    }

    &.icon {
      font-weight: 400;
    }

    &.total-scenes {
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      bottom: 8px;
      right: 8px;
      left: auto;
      text-transform: uppercase;
      padding: 2px 7px;
    }
  }

  .multiplefile-checkbox {
    border: 2px solid $checkmarkBorder;
    width: 18px;
    height: 18px;
    display: inline-block;
    position: absolute;
    bottom: 5px;
    left: 5px;
    z-index: 11;
    border-radius: 3px;
    pointer-events: all;
    opacity: 0;
    padding: 0;

    &:hover,
    &.checked {
      border-color: $blue;
    }

    &.checked {
      background: $blue;

      &:before {
        content: '';
        width: 6px;
        height: 12px;
        border: 2px solid $light;
        position: absolute;
        border-top: 0px;
        border-left: 0px;
        transform: rotate(35deg);
        top: -1px;
        left: 4px;
      }
    }

    &.is-multiple-selection {
      opacity: 1;
    }
  }
}

.category-item__preview {
  // tested by inspect, with 'display grid' the image below this container can expand fully. If want to find our more,
  // can uncomment the 'display flex' section below.
  display: grid;
  min-height: 110px;
  // display: flex;
  // justify-content: center;
  // text-align: center;
  // align-items: center;

  &:hover {
    .credits,
    .credits__overlay {
      opacity: 1;
    }
  }

  .lottie-preloader {
    position: absolute;
    z-index: 3;
    height: 100%;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: 40px;
    background-image: url('~@/assets/images/preloader/offeo_preloader.svg');
    background-repeat: no-repeat;
    background-position: center center;
  }

  .credits__overlay {
    background-image: url('~@/assets/images/media/shutterstock-bg-gradient.png');
    background-size: contain;
    position: absolute;
    height: 25%;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 8px;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
    opacity: 0;
  }

  .credits {
    font-weight: 500;
    position: absolute;
    height: 25%;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    font-size: 1em;
    font-weight: 500;
    color: $light;
    text-align: left;
    transition: opacity 0.25s ease-in-out;
    text-transform: initial;
    opacity: 0;

    .icon-info {
      z-index: 2;
      transition: opacity 0.25s ease-in-out;

      &:hover {
        opacity: 0;
        z-index: 1;

        ~ .info {
          opacity: 1;
          z-index: 2;
          display: block;
        }
      }
    }

    .info {
      background: $darkGrey;
      padding: 8px;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: 1;
      position: absolute;
      font-size: 0.65em;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      display: none;

      &:hover {
        opacity: 1;
        z-index: 3;
        display: block;
      }

      div {
        display: inline;
      }

      a {
        color: $light !important;
      }
    }
  }
}

.category-item--design {
  border: 3px solid $borderLightGrey;
  border-radius: $componentBorderRadius;

  &:not(.is-favourite) {
    //   box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
    //   display: flex;
    //   flex: 25%;
    //   width: 25%;
    //   max-width: calc((100% - 90px) / 4);
    //   margin: 0 30px 30px 0;
    .overlay-box {
      background: rgba(0, 0, 0, 0.6);
    }
  }

  &.is-loaded {
    height: auto;
  }

  &:nth-child(4n) {
    margin-right: 0;
  }

  .icon-modular {
    top: 8px;
  }

  .category-item__preview {
    position: relative;
    overflow: hidden;
    background: $lightGrey;
    cursor: pointer;

    &::before {
      display: none;
    }

    .category-item__video video,
    .category-item__image {
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: center center;
      width: 100%;
      height: 100%;
      object-fit: cover;
      box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
    }
  }

  &.is-favourite {
    .category-item__preview::before {
      padding-top: 100%;
    }
  }

  &:hover {
    border-color: $blue;

    .category-item__video video,
    .category-item__image {
      transform: none;
    }
  }

  .category-item__bottom-overlay {
    transform: translateY(-100%);
  }
}

.category-item__image {
  opacity: 1;
  transition: opacity 0.25s 0.25s ease-in-out, transform 0.25s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transform-origin: center center;
}

.category-item__top-overlay {
  background: linear-gradient(0deg, rgba(10, 31, 68, 0), rgba(10, 31, 68, 0.7));
  position: absolute;
  width: 100%;
  height: 45px;
  pointer-events: none;
  left: 0;
  top: 0;
  z-index: 3;
  overflow: hidden;
  opacity: 0;

  .file-options__button {
    position: absolute;
    color: $light;
    background: rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    display: block;
    z-index: 10;
    cursor: pointer;
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: $componentBorderRadius;
    pointer-events: auto;

    &:hover,
    &.is-hovering-file-options {
      background: $blue;
    }

    &.button--favourite-tab {
      background: rgba(0, 0, 0, 0.5);
      font-size: 0.875rem;
      left: 5px;
      top: 5px;
    }

    &.button--delete-tab {
      top: 7px;
      right: 2px;
      background: none;
      font-size: 16px;

      &:hover {
        background: none;
      }
    }
  }
}

.category-item__bottom-overlay {
  background: linear-gradient(0deg, rgba(10, 31, 68, 0.7), rgba(10, 31, 68, 0));
  position: absolute;
  min-height: 40px;
  width: 100%;
  pointer-events: none;
  left: 0;
  bottom: 0;
  z-index: 3;
  overflow: hidden;
  opacity: 0;

  .category-item--design:not(.is-favourite) &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(10, 31, 68, 0.5), rgba(10, 31, 68, 0));
    transform: translateY(100%);
    transition: transform 0.25s ease-in-out;
  }

  .btn-retry,
  .btn-favourite,
  .btn-delete {
    position: absolute;
    bottom: 5px;
    opacity: 0;
    pointer-events: auto;
    z-index: 3;

    .category-item:hover & {
      opacity: 1;
    }
  }

  .btn-retry,
  .btn-favourite {
    right: 5px;
    color: #fff;

    & + div .btn-delete {
      right: 26px;
    }
  }

  .btn-delete {
    padding: 0;
    font-size: 18px;
    width: 20px;
    height: 20px;
    background: transparent;
    right: 5px;
    color: $light;
  }
}

.category-item__content {
  padding: 15px 20px;
  word-break: break-word;

  .is-favourite & {
    font-size: 0.875em;
    padding: 5px;
  }

  .name {
    font-weight: 600;
    color: $inputLabel;
    text-transform: capitalize;
  }
}

.grid-item {
  &:nth-child(2n) {
    margin-right: 0;
  }

  &:hover {
    .asset-credits {
      opacity: 1;
    }

    .sidebar-icon-top {
      color: $darkGrey;
      opacity: 1;
    }
  }
}
</style>
