<template lang="pug">
form.stepper-container.duration-stepper(
  autocomplete="off"
  @submit.prevent
)
  button.js-stepper.btn-stepper.minus(
    v-if="showStepper"
    type="button"
    @click="decreaseDuration()"
    :disabled="getCurrentSceneDuration <= minCurrentDuration"
  )
    i.icon.icon-minus

  .project-duration-input__wrapper
    i.icon-clock
    input#project-duration-input(
      type="text"
      v-model.lazy="sceneDuration"
      ref="duration"
      data-lpignore="true"
      @keypress="checkIfNumber($event)"
      @focus="onFocusSceneDuration"
      @blur="onBlurSceneDuration"
    )
  button.js-stepper.btn-stepper.plus(
    v-if="showStepper"
    type="button"
    @click="increaseDuration()"
    :disabled="getCurrentSceneDuration >= maxCurrentDuration"
  )
    i.icon.icon-plus
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { isNumber } from '@/assets/scripts/utilities';
import { minDuration, maxDuration } from '@/assets/scripts/variables';
import liteModeMixin from '@/components/mixins/lite-mode-mixins';

// import ProjectApi from '@/services/api/ProjectApi';
// import TemplateApi from '@/services/api/TemplateApi';
export default {
  name: 'ProjectSceneDurationInput',
  props: {
    showStepper: { type: Boolean, default: false },
    showText: { type: Boolean, default: false },
  },
  mixins: [
    liteModeMixin,
  ],
  data() {
    return {
      maxCurrentDuration: this.isLiteMode ? maxDuration.liteScene : maxDuration.scene,
      minCurrentDuration: minDuration.scene,
      leftoverDuration: this.isLiteMode ? maxDuration.liteScene : maxDuration.scene,
    };
  },
  computed: {
    ...mapGetters([
      'isLiteMode',
    ]),
    ...mapGetters('userData', [
      'isFreeUser',
    ]),
    ...mapGetters('canvasElements', [
      'getCurrentSceneDuration',
      'getAllScenesDuration',
      'getActiveSceneId',
    ]),
    sceneDuration: {
      get() {
        return `${this.currentDuration}s`;
      },
      set(value) {
        if (value <= 30) this.setPreviousSceneDuration(this.currentDuration);
        this.doCheckSceneDuration(value);
      },
    },
    currentDuration() {
      return this.getCurrentSceneDuration > this.maxCurrentDuration ? this.maxCurrentDuration : this.getCurrentSceneDuration;
    },
  },
  methods: {
    ...mapMutations([
      'setThirtySecondsWarning',
    ]),
    ...mapMutations('canvasElements', [
      'updateCurrentSceneDuration',
      'updateCanvasElementsEndingTime',
      'setPreviousSceneDuration',
    ]),
    ...mapActions('canvasHistory', [
      'catchHistory',
    ]),
    ...mapActions('canvasElements', [
      'saveProjectScene',
    ]),
    checkMaxDuration() {
      const totalDuration = this.getAllScenesDuration;
      const totalDurationWithoutThisScene = totalDuration - this.getCurrentSceneDuration;

      // if the total duration will be more than what's allowed for the project, set the max
      this.maxCurrentDuration = this.maxDurationProject - totalDurationWithoutThisScene;
      this.leftoverDuration = this.maxDurationProject - totalDuration;
      // console.log('leftOver', this.leftoverDuration)

      // if the duration is more than what's allowed for each scene, set the max
      if (this.maxCurrentDuration > this.maxDurationScene) {
        this.maxCurrentDuration = this.maxDurationScene;
      }

      if (this.leftoverDuration > this.maxDurationScene) {
        this.leftoverDuration = this.maxDurationScene;
      }
      // console.log('totalDuration', totalDuration, 'totalDurationWithoutThisScene', totalDurationWithoutThisScene, this.leftoverDuration)
    },
    decreaseDuration() {
      let value = this.getCurrentSceneDuration - 0.1;
      if (value <= this.minDurationScene) value = this.minDurationScene;
      value = Math.round(value * 10) / 10;
      // console.log(value)
      this.updateSceneDuration(value);
    },
    increaseDuration() {
      let value = this.getCurrentSceneDuration + 0.1;

      this.checkMaxDuration();

      if (value > this.maxCurrentDuration) {
        value = this.maxCurrentDuration;
      }

      value = Math.round(value * 10) / 10;
      // console.log(value)
      this.updateSceneDuration(value);
    },
    checkIfNumber($event) {
      return isNumber($event);
    },
    // addScene() {
    //   this.addSceneToArray();
    // },
    updateSceneDuration(newSceneDuration) {
      this.catchHistory('scene');
      this.updateCurrentSceneDuration(newSceneDuration);

      if (typeof newSceneDuration === 'number') {
        const sceneId = this.getActiveSceneId;
        this.saveProjectScene({ sceneId });
      }
    },
    doCheckSceneDuration(duration) {
      if (this.isFreeUser) {
        const durationWithoutThisScene = this.getAllScenesDuration - this.getCurrentSceneDuration;
        if (durationWithoutThisScene + parseInt(duration, 10) > this.maxDurationScene) {
          this.sceneDuration = this.getCurrentSceneDuration;
          this.$forceUpdate();
          this.setThirtySecondsWarning(true);
          return;
        }
      }

      const oldValue = this.getCurrentSceneDuration;
      let tempDuration = duration;

      if (duration <= this.minDurationScene) {
        tempDuration = this.minDurationScene;
      }

      this.checkMaxDuration();

      if (duration > this.maxDurationScene) {
        tempDuration = this.maxDurationScene;
      }

      const value = Math.round(tempDuration * 10) / 10;

      this.updateCanvasElementsEndingTime({
        oldValue,
        value,
      });
      this.$root.$emit('canvas-container-reset-timeline');

      this.updateSceneDuration(value);

      if (duration > this.maxDurationScene) {
        this.$emit('show-max-duration-tooltip');
      }

      // console.log('check scene duration', duration, value);
      // console.log(duration);
      // console.log(value);
      // console.log('check scene duration');
    },
    onFocusSceneDuration(event) {
      // Remove the "s" string when doing edit
      // eslint-disable-next-line
      event.target.value = event.target.value.replace('s', '');
    },
    onBlurSceneDuration(event) {
      // eslint-disable-next-line
      event.target.value = `${this.currentDuration}s`;
    },
    checkDuratinInputStyle(isSelect) {
      const durationInput = document.getElementById('project-duration-input');

      if (!isSelect) {
        if (durationInput.value.length === 4) {
          durationInput.style.paddingRight = '10px';
        } if (durationInput.value.length === 3) {
          durationInput.style.paddingRight = '10px';
        } else if (durationInput.value.length === 2) {
          durationInput.style.paddingRight = '10px';
        } else if (durationInput.value.length === 1) {
          durationInput.style.paddingRight = '7px';
        }
      } else {
        durationInput.style.paddingRight = '2px';
      }
    },
  },
  mounted() {
    this.checkDuratinInputStyle();

    const durationInput = document.getElementById('project-duration-input');
    durationInput.addEventListener('focus', () => {
      this.checkDuratinInputStyle(true);
    });

    durationInput.addEventListener('blur', () => {
      this.checkDuratinInputStyle();
    });
  },
  watch: {
    getAllScenesDuration() {
      this.checkMaxDuration();
    },
    getCurrentSceneDuration: {
      handler() {
        setTimeout(() => {
          this.checkDuratinInputStyle();
        }, 100);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.stepper-container {
  display: flex !important;
  align-items: center !important;

  .btn-stepper {
    color: var(--white-black);
    background: var(--black-white);
    padding: 10px;

    &.minus {
      margin-right: 6px;
    }

    &.plus {
      margin-left: 6px;
    }
  }
}

.duration-stepper {
  .text {
    font-size: 0.875em;
    font-weight: 600;
    display: inline-block;
    margin-right: 10px;
  }

  .project-duration-input__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey7-darkgrey900);
    border-radius: 4px;
    background: transparent;
    text-align: center;
    width: 80px;
    font-size: 0.875rem;
    padding: 6px;

    .icon-clock {
      color: var(--black-white);
      margin-right: 6px;
    }

    #project-duration-input {
      width: 100%;
      max-width: 40px;
      height: 100%;
      padding-bottom: 2px;
      background: none;
      color: var(--black-white);
      text-align: center;

      &:focus {
        outline: none;
      }
    }
  }
}
</style>
