<template lang="pug">
.option.fontfamily__option(
  :class="{ 'has-image': hasImage, 'is-selected': isSelected }"
  @click="updateActiveElementFont"
)
  template(v-if="text && !isCustom")
    img(:src="fontSelected")
    template(v-if="fontCategory !== 'latin' && fontCategory !== 'latin-ext'")
      img.image--category(:src="fontCategorySelected")
  template(v-else)
    span(v-html="text" :style="{ 'font-family': fontFamily }")
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import textMixin from '@/components/mixins/text-mixins';

export default {
  name: 'FontFamilyItem',
  mixins: [textMixin],
  props: {
    fontCategory: { type: String, default: '' },
    text: { type: String, default: '' },
    fontFamily: { type: String, default: '' },
    isSelected: { type: Boolean, default: false },
    isLight: { type: Boolean, default: false },
    isBrandFont: { type: Boolean, default: false },
  },
  computed: {
    fontSelected() {
      return this.isLight ? this.imageUrlLight(this.text) : this.imageUrl(this.text);
    },
    fontCategorySelected() {
      return this.isLight ? this.imageCategoryUrlLight(this.text, this.fontCategory) : this.imageCategoryUrl(this.text, this.fontCategory);
    },
    hasImage() {
      return this.fontCategory !== 'custom';
    },
    isCustom() {
      return !this.fontCategory || this.fontCategory === 'custom';
    },
  },
  methods: {
    ...mapMutations('canvasElements', ['updateActiveFontFamily']),
    ...mapActions('canvasElements', ['updateCanvasElementFontFamily']),
    ...mapActions('canvasHistory', ['catchHistory']),
    updateActiveElementFont() {
      if (this.isBrandFont) {
        this.$emit('select', {
          fontFamily: this.fontFamily,
          fontCategory: this.fontCategory,
        });
      } else {
        this.catchHistory('element');

        this.updateActiveFontFamily(this.fontFamily);
        setTimeout(() => {
          const font = {
            fontFamily: this.fontFamily,
            fontCategory: this.fontCategory,
          };

          this.updateCanvasElementFontFamily(font);
        }, 100);
      }
    },
  },
};
</script>

<style lang="scss">
.fontfamily__option {
  position: relative;
  color: $light;
  padding: 25px 18px 3px;
  font-size: 18px;
  cursor: pointer;

  &.is-selected {
    &::after {
      opacity: 0.4;
    }

    &::before {
      content: $icon-checked;
      font-family: 'icomoon';
      position: absolute;
      right: 12px;
      top: 65%;
      transform: translateY(-50%);
      font-size: 18px;
      z-index: 2;
      color: $green700;
    }
  }
}
</style>
