<template lang="pug">
BaseButton.control-general__fit-to-button(
  :icon-before="icon"
  :is-canvas="true"
  @click="fitElement(direction)"
) {{ title }}
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'FitToButton',
  props: {
    icon: { type: String, default: '' },
    title: { type: String, default: '' },
    direction: { type: String, default: '' },
    index: { type: Number, default: 0 },
  },
  computed: {
    ...mapGetters('canvasElements', ['getCanvasElements', 'getActiveElements', 'getCanvasSize']),
  },
  methods: {
    ...mapMutations('canvasElements', ['updateCanvasElementRect']),
    fitElement(direction) {
      this.getActiveElements.forEach((activeElement) => {
        const canvasWidth = this.getCanvasSize.width;
        const canvasHeight = this.getCanvasSize.height;
        const elementWidth = activeElement.size.width;
        const elementHeight = activeElement.size.height;

        let ratio;
        let size;

        if (direction === 'width') {
          ratio = canvasWidth / elementWidth;
          size = {
            width: canvasWidth,
            height: elementHeight * ratio,
          };
        } else if (direction === 'height') {
          ratio = canvasHeight / elementHeight;
          size = {
            width: elementWidth * ratio,
            height: canvasHeight,
          };
        }

        const position = {
          x: canvasWidth / 2,
          y: canvasHeight / 2,
        };

        this.updateCanvasElementRect({
          id: activeElement.data.id,
          size,
          position,
        });

        this.$root.$emit('canvasElementSizeUpdated');
      });
    },
  },
};
</script>

<style lang="scss"></style>
