<template lang="pug">
#has-new-update-overlay
  CanvasModal(
    :hideCloseButton="true"
    :isShow="true"
    :maxWidth="600"
    :extraClass="extraClass"
    @closeOverlay="closeModal"
  )
    img(
      src="@/assets/images/general/new-update-user-prompt.jpg"
      alt="New update user prompt"
    )

    p Yay! We made some updates and bug fixes.
      br
      strong Your project will be saved.

    p.new-version(v-if="newVersion") ({{newVersion}})

    BaseButton(
      :isPrimary="true"
      :isTall="true"
      @click="saveAndRefresh"
      :disabled="disableSaveAndRefreshButton"
    ) Save Project and Refresh

</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'NewUpdateOverlay',
  data() {
    return {
      isShaken: false,
      disableSaveAndRefreshButton: false,
    };
  },
  computed: {
    ...mapState([
      'offeoAppDetails',
      'hasNewUpdates',
      'offeoAppDetails',
    ]),
    ...mapState('canvasElements', [
      'savingStatus',
    ]),
    extraClass() {
      const cssClass = [];

      if (this.isShaken) cssClass.push('is-shaken');

      return cssClass.join(' ');
    },
    newVersion() {
      const { release, old_release } = this.offeoAppDetails;
      if (!old_release) {
        return '';
      }
      return `${old_release} > ${release}`;
    },
  },
  methods: {
    ...mapMutations([
      'setOffeoAppDetails',
    ]),
    ...mapActions('canvasElements', [
      'saveProject',
    ]),
    closeModal() {
      this.isShaken = true;
      setTimeout(() => {
        this.isShaken = false;
      }, 500);
    },
    saveAndRefresh() {
      if (this.disableSaveAndRefreshButton) return;
      this.saveProject();
      this.disableSaveAndRefreshButton = true;
    },
  },
  watch: {
    savingStatus(val) {
      if (this.hasNewUpdates && val === 'Saved') {
        // console.log('savingStatus', val);
        // refresh page

        const { origin, pathname } = window.location;
        const offeoAppDetails = JSON.parse(JSON.stringify(this.offeoAppDetails));
        delete offeoAppDetails.old_release;
        this.setOffeoAppDetails(offeoAppDetails);

        const queries = this.$route.query;
        queries.ver = this.offeoAppDetails.release;

        setTimeout(() => {
          const queryString = Object.keys(queries).map(key => `${key}=${queries[key]}`).join('&');
          window.location.href = `${origin}${pathname}?${queryString}`;
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss">
#has-new-update-overlay {
  .content{
    background: #fff;
    border-top: 6px solid #0C66FF;
  }

  .new-version{
    color: #f4b4c4;
    font-size: .85em;
    font-weight: bold;
  }
}
</style>
