import {
  mapMutations, mapState, mapGetters,
} from 'vuex';

import Echo from 'laravel-echo';
import helperMixins from '@/components/mixins/helper-mixins';
import { optional } from '@/assets/scripts/utilities';

import UserApi from '@/services/api/UserApi';

let alertTimeout;
let awsWebsocket;

const websocketMixins = {
  mixins: [helperMixins],
  data() {
    return {
      offeoWebSocket: '', // laravel-echo
      offeoChanelPrefix: '',
    };
  },
  methods: {
    ...mapMutations([
      'setOffeoAppDetails',
      'setHasNewUpdates',
      'setIsDownloadPreviewOpened',
      'setIsProjectPreview',
      'setShowMultipleLoginWarning',
      'setUnauthenticatedAPI',
    ]),
    ...mapMutations('exportDetails', ['updateExportDetailState']),
    ...mapMutations('canvasElements', ['setStoryBoardPreviews', 'removeCachedVideo', 'clearAutoSaveInterval']),
    ...mapMutations('assetsSidebar', ['updateSidebarElement', 'clearSkeletonList', 'deleteSkeletonList']),
    initWebSocket() {
      if (!process.env.VUE_APP_PUSHER_ENABLE) return; // socket disabled

      this.setOffeoAppDetails({}); // clear app details first to avoid prompting on first load

      const {
        VUE_APP_OFFEO_API, VUE_APP_PUSHER_APP_KEY, VUE_APP_PUSHER_PORT, VUE_APP_WEBSOCKET_URI, VUE_APP_WEBSOCKET_ENABLE_WSS,
      } = process.env;

      // ["https:", "", "staging-v4-api.offeo.com", "api"];
      const wsHost = typeof VUE_APP_WEBSOCKET_URI !== 'undefined' ? VUE_APP_WEBSOCKET_URI : VUE_APP_OFFEO_API.split('/')[2];

      const echoConfig = {
        broadcaster: 'pusher',
        key: VUE_APP_PUSHER_APP_KEY,
        wsHost,
        wsPort: VUE_APP_PUSHER_PORT || 6001,
        enabledTransports: ['ws'],
        disableStats: true,
        encrypted: false,
        forceTLS: false,
      };

      if (VUE_APP_WEBSOCKET_ENABLE_WSS === 'true') {
        echoConfig.wssPort = VUE_APP_PUSHER_PORT || 6001;
        echoConfig.enabledTransports.push('wss');
        echoConfig.encrypted = true;
        echoConfig.forceTLS = true;
      }

      console.log(echoConfig);

      this.offeoWebSocket = new Echo(echoConfig);
      this.offeoWebSocket.connector.pusher.connection.bind('connected', (response) => {
        console.log('connected', response);
      });
      this.awsWebsocketConnect();

      // console.log(ws);

      console.log('%c%s', 'color: #0C66FF; font-size: 14px; padding: 5px', 'Websockets started');
    },
    initChannels() {
      if (!process.env.VUE_APP_PUSHER_ENABLE) return; // socket disabled

      if (!this.offeoWebSocket || !this.offeoChanelPrefix) return;

      this.createUserWebsocketEvent();
      // console.log(`channel initialized : ${this.offeoChanelPrefix}_export_progress`);
      const channels = {
        offeoAppDetails: 'offeo_app_details',
        exportProgress: `${this.offeoChanelPrefix}_export_progress`,
        saveImage: `${this.offeoChanelPrefix}_save_image`,
        storyBoardPreviews: `${this.offeoChanelPrefix}_storyboard_preview`,
      };

      // SET APP DETAILS in able to prompt users when ever there is new update on the site.

      if (process.env.VUE_APP_ENABLE_NEW_UPDATE_PROMPT) {
        this.offeoWebSocket.channel(channels.offeoAppDetails).listen('WebSocketEvent', (res) => {
          console.log(`Listen: ${channels.offeoAppDetails}@offeoAppDetails`, res);

          // console.log('offeoAppDetails:', this.offeoAppDetails);

          const { release } = res.data;

          if (typeof this.offeoAppDetails.release === 'undefined') {
            console.log('res.data', res.data);
            this.setOffeoAppDetails(res.data);
          } else if (release && typeof this.offeoAppDetails.release !== 'undefined' && release !== this.offeoAppDetails.release) {
            const newOffeoAppDetails = res.data;
            newOffeoAppDetails.old_release = this.offeoAppDetails.release;
            this.setOffeoAppDetails(newOffeoAppDetails);
            this.setHasNewUpdates(true);
          }

          // console.log('offeoAppDetails:', this.offeoAppDetails);
        });
      }

      // PROJECT BUILD EXPORT LISTENER
      this.offeoWebSocket
        .channel(channels.exportProgress)
        .listen('WebSocketEvent', (res) => {
          console.log(`Listen: ${channels.exportProgress}@exportProgress`, res);

          const {
            running, extraParams, completed,
          } = res.data;

          // different response between v2 and v1 export
          const queuePosition = res?.data?.position || res?.data?.queue_position || 1;
          const isCancelled = res?.data?.cancelled || res?.data?.is_cancelled;
          const isFailed = res?.data?.failed || res?.data?.is_failed;
          const isTemplate = !!res?.data?.is_template;
          const newProgress = res?.data?.progress || res.data?.percent;

          let isCompleted = false;

          if (res?.data?.completed) {
            isCompleted = true;
          }

          const updateExportDetailState = {};

          if (isTemplate && res?.data?.percent >= 100 && res?.data?.preview_link) {
            isCompleted = true;
          } else if ((res?.data?.is_video_ready || res?.data?.percent >= 100) && ((res?.data?.download_link && res?.data?.preview_link && res?.data?.cdn_link) || res?.data?.published_url)) {
            if (!this.isPublishingToFB) {
              isCompleted = true;
            }
          }

          updateExportDetailState.isCancelled = isCancelled;
          updateExportDetailState.isTerminated = isFailed;
          updateExportDetailState.isCompleted = isCompleted;

          if (newProgress > this.progress) {
            updateExportDetailState.progress = newProgress;
          }

          updateExportDetailState.queuePosition = queuePosition;

          if (updateExportDetailState.isCancelled || updateExportDetailState.isTerminated) {
          // updateExportDetailState.isExporting = false;
            this.updateExportDetailState(updateExportDetailState);
            return;
          }

          if (!this.isExporting && running) {
            updateExportDetailState.isExporting = true;
          }

          if (isCompleted) {
            const defaultPreview = extraParams?.previews?.thumb || res?.data?.preview_link || '';
            updateExportDetailState.previewLink = extraParams?.previews?.mp4 || defaultPreview;

            let previewKey = this.type;
            if (previewKey === 'mov' || this.quality === 'high') {
              previewKey += '-hd';
            }
            updateExportDetailState.downloadLink = extraParams?.previews[previewKey] || res?.data?.download_link;
            updateExportDetailState.cdnLink = extraParams?.previews[previewKey] || res?.data?.cdn_link || defaultPreview;
            updateExportDetailState.isExporting = false;
            updateExportDetailState.isCompleted = true;
          }

          // const {
          //   download_link,
          //   preview_link,
          //   cdn_link,
          //   percent,
          //   is_cancelled,
          //   is_failed,
          //   is_video_ready,
          //   queue_position,
          //   quality,
          //   is_template,
          //   published_to_fb,
          //   published_id,
          //   published_url,
          //   published_type,
          // } = res.data;

          // const updateExportDetailState = {
          //   percent,
          //   isCancelled: is_cancelled,
          //   isTerminated: is_failed,
          //   queuePosition: queue_position || 1,
          // };

          // // eslint-disable-next-line
          // if (quality === 'high') type += '-hd';

          // if (is_template && percent >= 100 && preview_link) {
          //   updateExportDetailState.isCompleted = true;
          //   updateExportDetailState.previewLink = preview_link;
          // } else if ((is_video_ready || percent >= 100) && ((download_link && preview_link && cdn_link) || published_url)) {
          //   if (this.isPublishingToFB && published_to_fb) {
          //     updateExportDetailState.isExporting = false;
          //     updateExportDetailState.publishedToFB = published_to_fb;
          //     updateExportDetailState.publishedId = published_id;
          //     updateExportDetailState.publishedUrl = published_url;
          //     updateExportDetailState.publishedType = published_type;
          //   } else if (!this.isPublishingToFB) {
          //     updateExportDetailState.isCompleted = true;
          //     updateExportDetailState.downloadLink = download_link;
          //     updateExportDetailState.previewLink = preview_link;
          //     updateExportDetailState.cdnLink = cdn_link;
          //   }
          // }

          this.updateExportDetailState(updateExportDetailState);
        });

      // EXPORT AS IMAGE LISTENER
      this.offeoWebSocket.channel(channels.saveImage).listen('WebSocketEvent', (res) => {
        console.log(`Listen: ${channels.saveImage}@saveImage`, res);
        const {
          download_link,
          is_template,
          success,
          scene_id,
          scene_preview,
          inspiration_assets,
          project_previews,
          published_to_fb,
          published_id,
          published_url,
          published_type,
        } = res.data;

        if (success) {
          if (is_template) {
            // console.log('scene preview', scene_id, scene_preview);
            const newScenePreviewLinks = JSON.parse(JSON.stringify(this.scenePreviewLinks));

            if (inspiration_assets) {
              newScenePreviewLinks.concat(Object.values(inspiration_assets));
            } else if (scene_preview) {
              newScenePreviewLinks.push({
                id: scene_id,
                url: scene_preview,
              });
            }

            if (project_previews) {
              this.updateProjectPreviews(project_previews);
            }

            console.log('saveImage:isCompleted', newScenePreviewLinks.length >= this.totalScenes);
            if (newScenePreviewLinks.length >= this.totalScenes) {
              this.updateExportDetailState({
                isExporting: false,
                isCompleted: false, // template image previews - leave it first (set to true to show complete modal)
                scenePreviewLinks: newScenePreviewLinks,
                scenePreviewTextCount: '',
              });
            } else {
              const scenePreviewTextCount = `${newScenePreviewLinks.length} of ${this.totalScenes}`;
              this.updateExportDetailState({
                scenePreviewLinks: newScenePreviewLinks,
                scenePreviewTextCount,
              });
            }
          } else if (download_link) {
            if (published_to_fb) {
              this.updateExportDetailState({
                publishedToFB: published_to_fb,
                publishedId: published_id,
                publishedUrl: published_url,
                publishedType: published_type,
              });

              this.setIsDownloadPreviewOpened(false);
              this.setIsProjectPreview(false);
            } else {
              if (document.getElementById('download_frame') === null) {
                const iframe = document.createElement('iframe');
                iframe.id = 'download_frame';
                iframe.name = 'download_frame';
                iframe.style.height = '0';
                iframe.style.width = '0';
                iframe.style.position = 'absolute';
                iframe.style.opacity = '0';
                iframe.style.top = 0;
                iframe.style.left = 0;
                document.body.appendChild(iframe);
              }
              // target to iframe to avoid popup blocker
              const a = document.createElement('A');
              a.href = download_link;
              a.target = 'download_frame';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
          }
        }

        this.updateExportDetailState({
          isDownloadingImage: false,
        });
      });

      // STORYBOARD LISTENER
      // this.offeoWebSocket.channel(channels.storyBoardPreviews).listen('WebSocketEvent', (res) => {
      //   // console.log(`Listen: ${channels.storyBoardPreviews}@storyBoardPreviews`, res);
      //   const { success, base64, scene_id } = res.data;

      //   if (success && base64 && scene_id) {
      //     this.setStoryBoardPreviews({
      //       sceneId: scene_id,
      //       base64,
      //     });
      //   }
      // });

      // subscribe to a certain channel


      console.log('%c%s', 'color: #0C66FF; font-size: 14px; padding: 5px', 'Websocket channels created');
    },
    createUserWebsocketEvent() {
      // this handle all users's websocket events;
      // TODO: move all other websockets connected to the user.
      if (!process.env.VUE_APP_PUSHER_ENABLE) return; // socket disabled
      if (!this.offeoWebSocket || !this.offeoChanelPrefix) return;

      const channelName = `user_websocket_event_${this.userId}`;
      this.offeoWebSocket.channel(channelName).listen('WebSocketEvent', (response) => {
        console.log(channelName, response);
        if (response.data.event === 'login' || response.data.event === 'logout') {
          this.eventNewLogin();
        }
      });

      const userFileChannelName = `user_file_${this.userId}`;
      this.offeoWebSocket.channel(userFileChannelName).listen('WebSocketEvent', (res) => {
        console.log(`Listen: ${userFileChannelName}@userFile`, res, res.data.is_preparing);

        const { data } = res;

        const item = {
          id: data.id,
          user_id: data.user_id,
          folder_id: data.folder_id,
          ext: data.ext,
          start_trim: optional(data.start_trim, null),
          end_trim: optional(data.end_trim, null),
          is_preparing: data.is_preparing,
        };

        if (data.data) {
          item.file = data.data[0].download_link;
          item.file_name = data.data[0].original_name;
          item.preview_url = data.data[0].thumb_link;
        } else {
          item.file = data.file;
          item.file_name = data.file_name;
          item.preview_url = data.preview_url;
        }

        console.log('socket', res, item);
        this.updateSidebarElement({
          id: data.id,
          group: 'my-files',
          subgroup: 'videos',
          slug: this.getActiveFileCategory,
          data: item,
        });

        // get the unique identifier
        // may have an issue if there are 2 of same file
        let uniqueIdentifier = '';
        for (let i = 0; i < Object.keys(this.skeletonList).length; i += 1) {
          const id = Object.keys(this.skeletonList)[i];
          const currentSkeleton = this.skeletonList[id];
          const name = item.file_name.split('.')[0]; // get name from name.mp4
          console.log('skeleton file name', currentSkeleton.fileName, name);
          if (currentSkeleton.fileName.includes(name)) {
            uniqueIdentifier = id;
          }
        }
        if (uniqueIdentifier) {
          this.deleteSkeletonList(uniqueIdentifier);
        } else {
          this.clearSkeletonList();
        }

        // remove cache
        this.removeCachedVideo(item.file);

        const elements = this.getSidebarElements[`my-files/videos/${this.getActiveFileCategory}`];
        if (typeof elements === 'undefined') return;
        if (elements.length === 0) return;

        if (data.ext !== 'webm' && data.ext !== 'mov' && data.ext !== 'mp4') return;

        clearTimeout(alertTimeout);
        alertTimeout = setTimeout(() => {
          if (data.is_failed_upload) {
            const filename = data.file_name || 'Your file';
            this.alertError('Hey there!', `${filename} failed to upload.`, 5000, 'TopCenterNotif');
          } else if (!data.is_failed_upload && !data.is_preparing) {
            const filename = data.file_name || 'Your file';
            this.alertSuccess('Hey there!', `${filename} is ready for use in your project.`, 5000, 'TopCenterNotif');
          }
        }, 1500);
      });
    },
    eventNewLogin(tries = 0) {
      if (tries > 2) {
        console.error('Event new login max attempt reached.');
      }
      // if there is new login do request to accounts to check if the cookies are still valid.
      UserApi.userDetails()
        .then((response) => {
          console.log(response);
          if (response.data.success) {
            this.clearAutoSaveInterval();
            this.setShowMultipleLoginWarning(false);
            this.setUnauthenticatedAPI(false);
          }
        })
        .catch((error) => {
          if (error.response.status !== 401) {
            setTimeout(() => {
              this.eventNewLogin(tries + 1);
            }, 1000);
          }
          console.log(error);
        });
    },
    awsWebsocketConnect() {
      const {
        VUE_APP_AWS_API_GATEWAY_ENDPOINT, VUE_APP_PUSHER_APP_KEY,
      } = process.env;

      try {
        awsWebsocket = new WebSocket(`wss://${VUE_APP_AWS_API_GATEWAY_ENDPOINT}?app=${VUE_APP_PUSHER_APP_KEY}`);
      } catch (error) {
        console.error('AWS Websocket:error', error);
      }

      awsWebsocket
        .addEventListener('open', (e) => {
          console.log('AWS WebSocket connected');
        });

      awsWebsocket
        .addEventListener('close', (e) => {
          // console.log('AWS WebSocket is closed', e);
          setTimeout(() => {
            console.log('Reconnecting websocket...');
            this.awsWebsocketConnect();
          }, 2000);
        });

      awsWebsocket
        .addEventListener('error', (e) => {
          console.error('AWS WebSocket error:', e);
        });

      awsWebsocket.addEventListener('message', (e) => {
        // console.log('AWS WebSocket received a message:', e);
        const message = JSON.parse(e.data);
        console.log('Aws websocket message', message);

        // storyboard thumbnail
        if (message.event === 'storyboard_response') {
          const { sceneId, data } = message;
          this.setStoryBoardPreviews({
            sceneId,
            base64: data,
          });
        }
      });
    },
    awsWebsocketSend(data, action = 'default') {
      const payload = {
        action,
        data,
      };
      // console.log('awsWebsocketSend', awsWebsocket);
      awsWebsocket.send(JSON.stringify(payload));
    },
    awsWebsocketMessage(data) {
      this.awsWebsocketSend(data, 'message');
    },
  },
  computed: {
    ...mapState(['offeoAppDetails']),
    ...mapState('exportDetails', ['isExporting', 'scenePreviewLinks', 'totalScenes', 'quality', 'type', 'progress']),
    ...mapState('userData', ['userId']),
    ...mapState('assetsSidebar', ['skeletonList']),
    ...mapGetters('assetsSidebar', ['getSidebarElements', 'getActiveFileCategory']),
    ...mapGetters(['getDownloadPreviewType']),
    isPublishingToFB() {
      return this.getDownloadPreviewType === 'publish';
    },
  },
};

export default websocketMixins;
