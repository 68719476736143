<template lang="pug">
  div.audio__trim-control(ref="audioTrimControl")
    span.control__trimmer-overlay-tooltip(
      :class="{'is-show': isShowTrimmerTooltip}"
      :style="{left: `${trimmerTooltipStartPos}px`}"
      :data-title="trimmerTooltipText"
    )

    div(
      v-if="!isMusicTrimToolbarLoading"
      @mouseover="setTrimmerTooltip(true, $event)"
      @mousemove="setTrimmerTooltip(true, $event)"
      @mouseleave="setTrimmerTooltip(false, $event)"
    )
      VueDragResize.control__trimmer-overlay(
        :isActive="true"
        :w="elementDurationWidth"
        :h="39"
        :x="startPos"
        :y="-7"
        axis="x"
        :is-draggable="!getIsPlaying"
        :is-resizable="false"
        :sticks="['ml', 'mr']"
        :parentLimitation="true"
        @dragging="updateDuration"
        @dragstop="confirmTrim"
      )
      span.control__current-scene-overlay(
        :style="currentSceneOverlayStyle"
        ref="currentSceneOverlay"
      )

    div.control__audio-soundwave(
      :class="{'is-loading': isMusicTrimToolbarLoading, 'soundwave--show-playbar': isShowPlaybar}"
      ref="peakAudioContainer"
    )
    //- this playbar is different with the one from peaks.js, this one will only appear when
    //- user click play button from timeline / play current scene and open the music toolbar.
    span.control__timeline-playbar(
      v-if="getIsPlaying && !isPlaying && !isMusicTrimToolbarLoading"
      :style="timelinePlaybarStyle"
    )

    div.control__audio-source
      audio#peaksjs-audio-source(
        :src="trimAudioUrl"
        ref="peakAudioSource"
      )
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

import Peaks from 'peaks.js';
import VueDragResize from 'vue-drag-resize';
import cloneDeep from 'lodash.clonedeep';
import { debounce } from 'debounce';

import darkModeMixin from '@/components/mixins/dark-mode-mixins';
import helperMixin from '@/components/mixins/helper-mixins';

export default {
  name: 'AudioTrimControl',
  mixins: [darkModeMixin, helperMixin],
  components: {
    VueDragResize,
  },
  props: {
    timelineTime: { type: Number, default: 0 },
    isPlaying: { type: Boolean, default: false },
  },
  data() {
    return {
      peakInstanceContainer: null,
      updatedSegment: false,
      containerWidth: 1040,
      audioDuration: 0,
      trimAudioUrl: '',

      isDragging: false,
      isShowPlaybar: false,
      isShowTrimmerTooltip: false,
      hoveredTrimmerTooltipType: 'all',
    };
  },
  computed: {
    ...mapState(['isMusicTrimToolbarLoading']),
    // "getIsPlaying" is Timeline's Scene Playing Indicator - from Timeline's play button
    ...mapGetters(['getIsPlaying']),
    ...mapGetters('canvasElements', [
      'getTrimmedElement',
      'getCurrentSceneDuration',
      'getAllScenesDuration',
      'getScenes',
      'getSelectedMusic',
      'getActiveSceneId',
    ]),
    startPos() {
      const pos = this.percentage(this.startTime) * this.containerWidth;
      return pos;
    },
    duration() {
      return this.endTime - this.startTime;
    },
    currentSceneStartPos() {
      const pos = this.percentage(this.currentSceneStartTime) * this.containerWidth;
      return pos.toFixed();
    },
    currentSceneEndPos() {
      const pos = this.percentage(this.currentSceneEndTime) * this.containerWidth;
      return pos.toFixed();
    },
    previousSceneDuration() {
      // for multiple scene, if the scene is not the first but the second, third, etc.
      let getPreviousSceneDuration = 0;
      const findCurrentSceneIndex = this.getScenes.findIndex(
        scene => scene.id === this.getActiveSceneId,
      );

      this.getScenes.forEach((scene, index) => {
        if (index < findCurrentSceneIndex) {
          getPreviousSceneDuration += scene.duration;
        }
      });

      return getPreviousSceneDuration;
    },
    currentSceneDuration() {
      const getCurrentScene = this.getScenes.find(scene => scene.id === this.getActiveSceneId);
      return (getCurrentScene && getCurrentScene.duration) || 0;
    },
    currentSceneStartTime() {
      return this.startTime + this.previousSceneDuration;
    },
    currentSceneEndTime() {
      return this.currentSceneStartTime + this.currentSceneDuration;
    },
    startTime: {
      get() {
        const time = this.getTrimmedElement.data.time_start
          ? this.getTrimmedElement.data.time_start
          : 0;
        return parseFloat(parseFloat(time).toFixed(1));
      },
      set(value) {
        const newElement = JSON.parse(JSON.stringify(this.getTrimmedElement));
        newElement.data.time_start = parseFloat(parseFloat(value).toFixed(1));
        this.updateTrimmedElement(newElement);
      },
    },
    endTime: {
      get() {
        const time = this.getTrimmedElement.data.time_end
          ? this.getTrimmedElement.data.time_end
          : this.getCurrentSceneDuration;
        return parseFloat(parseFloat(time).toFixed(1));
      },
      set(value) {
        const newElement = JSON.parse(JSON.stringify(this.getTrimmedElement));
        newElement.data.time_end = parseFloat(parseFloat(value).toFixed(1));
        this.updateTrimmedElement(newElement);
      },
    },
    fullDuration: {
      get() {
        const fullDuration = this.getTrimmedElement.max_duration || this.audioDuration;
        return fullDuration;
      },
      set() {
        //
      },
    },
    elementDurationWidth() {
      let elementDuration = this.duration;
      if (elementDuration === 0) elementDuration = 1;

      const width = this.percentage(elementDuration) * this.containerWidth;

      return width;
    },
    currentSceneDurationWidth() {
      const width = this.percentage(this.currentSceneDuration) * this.containerWidth;
      return `${width}px`;
    },
    currentSceneOverlayStyle() {
      return {
        width: this.currentSceneDurationWidth,
        left: `${this.currentSceneStartPos}px`,
      };
    },
    timelinePlaybarStyle() {
      // percentage = searching percentage of current timeline time to scene duration
      const percentage = this.timelineTime / this.currentSceneDuration;
      const currentSceneDurationPos = this.currentSceneEndPos - this.currentSceneStartPos;
      const currentTimelineTimePosition = (percentage * currentSceneDurationPos).toFixed();
      const leftPosition = Number(this.currentSceneStartPos) + Number(currentTimelineTimePosition);

      return {
        left: `${leftPosition}px`,
      };
    },
    trimmerTooltipStartPos() {
      return this.hoveredTrimmerTooltipType === 'all' ? this.startPos : this.currentSceneStartPos;
    },
    trimmerTooltipText() {
      return this.hoveredTrimmerTooltipType === 'all' ? 'All Scenes' : 'Current Scene';
    },
    durationSegmentColor() {
      return this.isDarkMode ? '#25b98d' : '#5aacff';
    },
    waveFormColor() {
      return this.isDarkMode ? '#445571' : '#c9ced6';
    },
    playbarColor() {
      return this.isDarkMode ? '#ffffff' : '#000000';
    },
  },

  beforeDestroy() {
    this.$emit('pause-trim');
    window.removeEventListener('resize', this.calculateContainerWidth);

    if (this.getSelectedMusic.url) {
      this.confirmTrimmedElement();
    }
  },
  beforeMount() {
    let { url } = this.getTrimmedElement;
    // eslint-disable-next-line
    url = url.split('#t=')[0];

    this.trimAudioUrl = url;
  },
  mounted() {
    this.getAudioDuration();
    this.calculateContainerWidth();
    this.createPeakInstance();

    window.addEventListener(
      'resize',
      debounce(() => {
        this.calculateContainerWidth();
      }, 300),
    );
  },
  watch: {
    isPlaying(val) {
      const currentSceneDurationSegment = this.peakInstanceContainer.segments.getSegment(
        'currentSceneDurationSegment',
      );

      if (val) {
        this.peakInstanceContainer.player.playSegment(currentSceneDurationSegment);
      } else {
        this.peakInstanceContainer.player.pause();
        this.isShowPlaybar = false;
      }
    },
    trimAudioUrl() {
      this.setIsMusicTrimToolbarLoading(true);
      this.createPeakInstance();
    },
    isDarkMode() {
      this.setIsMusicTrimToolbarLoading(true);
      this.createPeakInstance();
    },
    getActiveSceneId() {
      // when user go to storyboard & select a new active scene
      this.setIsMusicTrimToolbarLoading(true);
      this.createPeakInstance();
    },
    getScenes: {
      // when user change duration
      async handler() {
        await this.resetTrimmer();
        this.setIsMusicTrimToolbarLoading(true);
        this.createPeakInstance();
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations(['setShowTrim', 'setIsMusicTrimToolbarLoading']),
    ...mapMutations('canvasElements', ['updateTrimmedElement', 'confirmTrimmedElement']),
    createPeakInstance() {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      const audioContext = new AudioContext();

      const options = {
        containers: {
          overview: this.$refs.peakAudioContainer,
        },
        mediaElement: this.$refs.peakAudioSource,
        webAudio: {
          audioContext,
        },
        overviewWaveformColor: this.waveFormColor,
        playheadColor: this.playbarColor,
        height: 100,
        emitCueEvents: true,
        keyboard: false,
        segments: [
          {
            id: 'currentSceneDurationSegment',
            startTime: this.currentSceneStartTime,
            endTime: this.currentSceneEndTime,
            editable: true,
            color: this.durationSegmentColor,
          },
          {
            id: 'fullDurationSegment',
            startTime: this.startTime,
            endTime: this.endTime,
            editable: true,
            color: this.durationSegmentColor,
          },
        ],
      };

      const vm = this;
      Peaks.init(options, (err, peakInstance) => {
        if (err) {
          console.error(`Failed to initialize Peaks instance: ${err.message}`);

          this.showErrorNotification();
          return;
        }

        vm.peakInstanceContainer = peakInstance;

        peakInstance.on('peaks.ready', () => {
          this.setIsMusicTrimToolbarLoading(false);
        });

        peakInstance.on('player.playing', () => {
          this.isShowPlaybar = true; // remove span time where playbar displayed first in a wrong position when audio is loading to play.
        });

        peakInstance.on('segments.exit', () => {
          if (!this.isDragging) {
            this.$emit('done-playing');
          }
        });
      });
    },
    // eslint-disable-next-line func-names
    showErrorNotification: debounce(function () {
      this.alertError('', 'Please use another music at the moment.', 5000, 'TopCenterNotif');
    }, 100),

    resetTrimmer() {
      if (!this.getSelectedMusic.url) return;

      const selectedMusic = this.getSelectedMusic;
      const splitTime = selectedMusic.url.split('#t=');
      let timeStart = 0;
      let timeEnd = this.$refs.peakAudioSource.duration;
      const maxDuration = this.$refs.peakAudioSource.duration;

      if (splitTime.length > 1) {
        const time = splitTime[1].split(',');
        timeStart = parseFloat(time[0]);

        if (typeof time[1] !== 'undefined') timeEnd = parseFloat(time[1]);
      }

      if (timeEnd - timeStart > this.getAllScenesDuration) timeEnd = parseFloat(timeStart + this.getAllScenesDuration);

      selectedMusic.type = 'audio';
      selectedMusic.max_duration = maxDuration;
      selectedMusic.data = {
        time_start: timeStart,
        time_end: timeEnd,
      };

      this.setShowTrim(true);
      this.updateTrimmedElement(selectedMusic);

      // pause temp audio and canvas when trimming audio
      this.$root.$emit('pause-all-audio');
    },

    // ---------------
    // vue-drag-resize
    // ---------------
    calculateContainerWidth() {
      const audioControlRef = this.$refs.audioTrimControl;

      if (audioControlRef && audioControlRef.clientWidth) {
        this.containerWidth = audioControlRef.clientWidth;
      }
    },
    percentage(value) {
      let percentage = value / this.fullDuration;
      if (percentage > 1) percentage = 1;

      return percentage;
    },
    getAudioDuration() {
      const audio = this.$refs.peakAudioSource;
      audio.onloadedmetadata = () => {
        this.audioDuration = audio.duration;
      };
    },
    updateDuration(el) {
      this.$emit('pause-playing');
      this.isDragging = true;

      const { width, left } = el;
      const timeStart = parseFloat(((left / this.containerWidth) * this.fullDuration).toFixed(1));
      const duration = parseFloat(((width / this.containerWidth) * this.fullDuration).toFixed(1));

      const newElement = cloneDeep(this.getTrimmedElement);
      newElement.data.time_start = timeStart;
      newElement.data.time_end = timeStart + duration;

      this.updateTrimmedElement(newElement);
      this.updateSoundwaveSegment();
    },
    confirmTrim() {
      this.confirmTrimmedElement();
      this.$emit('play');

      setTimeout(() => {
        this.isDragging = false; // to fix issue where after drag the music won't play
      }, 250);
    },
    updateSoundwaveSegment() {
      const currentSceneDurationSegment = this.peakInstanceContainer.segments.getSegment(
        'currentSceneDurationSegment',
      );
      const fullDurationSegment = this.peakInstanceContainer.segments.getSegment('fullDurationSegment');

      fullDurationSegment.update({ startTime: this.startTime, endTime: this.endTime });
      currentSceneDurationSegment.update({
        startTime: this.currentSceneStartTime,
        endTime: this.currentSceneEndTime,
      });
    },
    // eslint-disable-next-line func-names
    setTrimmerTooltip: debounce(function (value, event) {
      // formula to see if user is hovering on the current scene section.
      const currentSceneHorizontalStartPoint = this.$refs.currentSceneOverlay.getBoundingClientRect().left;
      const currentSceneHorizontalEndPoint = this.$refs.currentSceneOverlay.getBoundingClientRect().right;
      const isHoveringCurrentScene = event && event.x < currentSceneHorizontalEndPoint;

      // because if it's not the first scene then user will be able to hover to the left of current scene which supposely show 'all scenes' not 'current scene'
      const isHoveringAllScene = event && event.x < currentSceneHorizontalStartPoint;

      this.isShowTrimmerTooltip = value;
      if (isHoveringCurrentScene && !isHoveringAllScene) {
        this.hoveredTrimmerTooltipType = 'current';
      } else {
        this.hoveredTrimmerTooltipType = 'all';
      }
    }, 50),
  },
};
</script>

<style lang="scss">
.audio__trim-control {
  // width below = 100% - (AudioFadeControl.vue's width) - ('.controls__music-header' width) - ('.controls-music__divider-line' margin)
  width: calc(100% - 156px - 75px - 30px);
  background: var(--lightgrey100-darkgrey600);
  position: relative;
  cursor: grabbing;

  .control__audio-soundwave {
    width: 100%;
    height: 30px;

    &.is-loading {
      opacity: 0; // use opacity because peaks.js couldn't work with v-show / v-if
    }

    .konvajs-content {
      :nth-child(1),
      :nth-child(2),
      :nth-child(6) {
        z-index: 10;
        pointer-events: none !important;
      }

      :nth-child(5) {
        display: none !important;
      }

      :nth-child(6) {
        opacity: 0;
      }
    }

    &.soundwave--show-playbar {
      .konvajs-content {
        :nth-child(6) {
          opacity: 1 !important;
        }
      }
    }
  }

  .control__timeline-playbar {
    background-color: var(--black-white);
    width: 1px;
    position: absolute;
    height: 30px;
    z-index: 14;
    top: 0;
    left: 160px;
  }

  .control__current-scene-overlay {
    position: absolute;
    top: -4px;
    left: 0;
    z-index: 10;
    height: 39px;
    background: var(--lightblue3-darkgrey900);
    border-radius: 4px;
    pointer-events: none;
  }

  .control__trimmer-overlay-tooltip {
    position: absolute !important;
    top: 43px;
    z-index: 3;
    width: 100px;
    height: 20px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;

    &[data-title]:after {
      bottom: 0;
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease-in-out;
      pointer-events: none;
    }

    &.is-show {
      opacity: 1;
      visibility: visible;
    }
  }

  .control__trimmer-overlay {
    top: -4px !important;
    z-index: 2 !important;
    outline: 0 !important;
    border: 0 !important;
    background: var(--lightblue2-darkgrey4);
    border-radius: 5px;

    &::before {
      outline: 0 !important;
    }

    .content-container {
      height: 39px !important;
    }

    .vdr-stick {
      width: 12px !important;
      height: 39px !important;
      background: $blue;
      border-radius: 4px;
      position: absolute;
      top: 0;
      margin: -2px !important;

      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: 4;
        top: 0;
        font-size: 0.375em;
        line-height: 1;
        height: 24px;
        margin-top: 0;
        border: 0;
        background: #fff;
        box-shadow: none;
        justify-content: center;
        align-items: center;
        cursor: ew-resize;
        width: 2px;
        border: 2px solid $blue;
        border-top: 18px solid $blue;
        border-bottom: 18px solid $blue;
        border-radius: 4px;
        display: flex;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    .bar {
      width: 2px;
      height: 100%;
      background: $darkGrey;
      top: 0;
      position: absolute;
      left: 0;
      transition: transform 0.1s linear;
    }
  }
}
</style>
