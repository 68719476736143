<template lang="pug">
.category-item-lite-container
  template(
    v-if="isMediaTab"
  )
    .category-item-lite.category-item-lite--skeleton(
      v-for="skeleton in skeletonList"
    )
      span.name {{ skeleton.fileName }}
      BasePreloader
  CategoryItemLite(
    v-for="(element, index) in selectedElements"
    :key="element.data.id"
    :index="index"
    :id="element.data.id"
    :element="element"
    :isImage="isMediaTab"
    :isText="isTextTab"
  )
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { groups } from '@/assets/scripts/enums';

import CategoryItemLite from './children/CategoryItemLite.vue';

export default {
  name: 'CategoryItemLiteContainer',
  components: {
    CategoryItemLite,
  },
  computed: {
    ...mapState('assetsSidebar', ['skeletonList']),
    ...mapGetters('assetsSidebar', ['getActiveGroup']),
    ...mapGetters('canvasElements', ['getCanvasElements']),
    isMediaTab() {
      return this.getActiveGroup === groups.MEDIA;
    },
    isTextTab() {
      return this.getActiveGroup === groups.TEXTS;
    },
    selectedElements() {
      if (this.isTextTab) {
        return this.getCanvasElements.filter(element => this.isElementText(element));
      }

      if (this.isMediaTab) {
        return this.getCanvasElements.filter(element => this.isElementImage(element));
      }

      return [];
    },
  },
  methods: {
    isElementText(element) {
      return element.type === 'texts';
    },
    isBrandedGraphic(element) {
      return element.isBrandedAsset && element.data.svg_url;
    },
    isElementImage(element) {
      return (
        !this.isBrandedGraphic(element)
        && (element.isImage
          || element.type === 'images'
          || (element.data.url && element.data.url.split('.').pop() === 'png'))
      );
    },
  },
};
</script>

<style lang="scss">
.category-item-lite-container {
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
</style>
