import { render, staticRenderFns } from "./ProjectDetails.vue?vue&type=template&id=16d526e6&lang=pug&"
import script from "./ProjectDetails.vue?vue&type=script&lang=js&"
export * from "./ProjectDetails.vue?vue&type=script&lang=js&"
import style0 from "./ProjectDetails.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports