import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import { subgroups } from '@/assets/scripts/enums';
import { defaultMediaBorderSize, defaultText3dDistance, defaultTextGlowRadius } from '@/assets/scripts/variables';

const effectsMixin = {
  computed: {
    ...mapState(['editTabEffectsType']),
    ...mapGetters('canvasElements', ['getActiveElements']),
    isTextEffectsType() {
      return this.editTabEffectsType === 'text';
    },
    isMediaEffectsType() {
      return this.editTabEffectsType === 'media';
    },
    isTextBackgroundEnabled() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type !== 'texts') return false;
      if (this.getActiveElements[0].type === 'texts') return this.getActiveElements[0].data.textBackground.isEnabled;
      return false;
    },
    isDropShadowEnabled() {
      let isEnabled = true;
      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (element.type === 'texts' && !element.data.textShadow.isEnabled) {
          isEnabled = false;
        }

        if (element.type !== 'texts' && !element.data.mediaShadow.isEnabled) {
          isEnabled = false;
        }
      });
      return isEnabled;
    },
    isBlurEnabled() {
      let isEnabled = true;
      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (element.type === 'texts' && !element.data.textBlur.isEnabled) {
          isEnabled = false;
        }

        if (element.type !== 'texts' && !element.data.mediaBlur.isEnabled) {
          isEnabled = false;
        }
      });
      return isEnabled;
    },
    isGlowEnabled() {
      let isEnabled = true;
      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (element.type === 'texts' && !element.data.textGlow.isEnabled) {
          isEnabled = false;
        }

        if (element.type !== 'texts' && !element.data.mediaGlow.isEnabled) {
          isEnabled = false;
        }
      });
      return isEnabled;
    },
    isLightSweepEnabled() {
      let isEnabled = true;
      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (element.type === 'texts' && !element.data.textLightSweep.isEnabled) {
          isEnabled = false;
        }

        if (element.type !== 'texts' && !element.data.mediaLightSweep.isEnabled) {
          isEnabled = false;
        }
      });
      return isEnabled;
    },
    isGlitchEnabled() {
      let isEnabled = true;
      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (element.type === 'texts' && !element.data.textGlitch.isEnabled) {
          isEnabled = false;
        }

        if (element.type !== 'texts' && !element.data.mediaGlitch.isEnabled) {
          isEnabled = false;
        }
      });
      return isEnabled;
    },
    isTextOutlineEnabled() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type !== 'texts') return false;
      if (this.getActiveElements[0].type === 'texts') return this.getActiveElements[0].data.textOutline.isEnabled;
      return false;
    },
    isText3DEnabled() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type !== 'texts') return false;
      if (this.getActiveElements[0].type === 'texts') return this.getActiveElements[0].data.text3D.isEnabled;
      return false;
    },
    isMediaRoundCornersEnabled() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type === 'texts') return false;
      if (this.getActiveElements[0].type !== 'texts') return this.getActiveElements[0].data.mediaRoundCorners.isEnabled;
      return false;
    },
    isMediaBorderEnabled() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type === 'texts') return false;
      if (this.getActiveElements[0].type !== 'texts') return this.getActiveElements[0].data.mediaBorder.isEnabled;
      return false;
    },
    isReflectionEnabled() {
      let isEnabled = true;
      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (!element.data.reflection.isEnabled) {
          isEnabled = false;
        }
      });
      return isEnabled;
    },
    isGroundShadowEnabled() {
      let isEnabled = true;
      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (!element.data.groundShadow.isEnabled) {
          isEnabled = false;
        }
      });
      return isEnabled;
    },

    // -----------------
    // Element Types
    // -----------------
    elementType() {
      return this.getActiveElements[0].type;
    },
    isAnimated() {
      return this.getActiveElements[0].animated === true;
    },
    isBrandedGraphic() {
      return this.getActiveElements[0].isBrandedAsset && this.getActiveElements[0].data.svg_url;
    },
    isSvg() {
      return !this.isMask && this.getActiveElements[0].data.url && this.getActiveElements[0].data.url.split('.').pop() === 'svg';
    },
    isMask() {
      let isMask = false;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (element.type === subgroups.MASKS) {
          isMask = true;
          return true;
        }
      });

      return isMask;
    },
    isWebmElement() {
      if (
        this.getActiveElements.length === 1
        && this.getActiveElements[0].data.url
        && this.getActiveElements[0].data.url.split('.').pop() === 'webm'
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('canvasHistory', ['catchHistory']),
    ...mapMutations('canvasElements', [
      'updateCanvasElementColor',
      'updateCanvasElementTextBackground',
      'updateCanvasElementTextShadow',
      'updateCanvasElementTextBlur',
      'updateCanvasElementTextGlow',
      'updateCanvasElementTextLightSweep',
      'updateCanvasElementTextGlitch',
      'updateCanvasElementTextOutline',
      'updateCanvasElementText3D',
      'updateCanvasElementMediaShadow',
      'updateCanvasElementMediaBlur',
      'updateCanvasElementMediaGlow',
      'updateCanvasElementMediaLightSweep',
      'updateCanvasElementMediaGlitch',
      'updateCanvasElementMediaRoundCorners',
      'updateCanvasElementMediaBorder',
      'updateCanvasElementReflection',
      'updateCanvasElementGroundShadow',
    ]),
    ...mapMutations([
      'setIsTextBackground',
      'setIsTextShadow',
      'setIsTextBlur',
      'setIsTextGlow',
      'setIsTextLightSweep',
      'setIsTextGlitch',
      'setIsTextOutline',
      'setIsText3D',
      'setIsMediaShadow',
      'setIsMediaBlur',
      'setIsMediaGlow',
      'setIsMediaLightSweep',
      'setIsMediaGlitch',
      'setIsMediaRoundCorners',
      'setIsMediaBorder',
      'setIsReflection',
      'setIsGroundShadow',
    ]),
    isEnabled(value) {
      if (value === 'text-bg') return this.isTextBackgroundEnabled;
      if (value === 'drop-shadow') return this.isDropShadowEnabled;
      if (value === 'blur') return this.isBlurEnabled;
      if (value === 'reflection') return this.isReflectionEnabled;
      if (value === 'ground-shadow') return this.isGroundShadowEnabled;
      if (value === 'glow') return this.isGlowEnabled;
      if (value === 'light-sweep') return this.isLightSweepEnabled;
      if (value === 'glitch') return this.isGlitchEnabled;
      if (value === 'outline') return this.isTextOutlineEnabled;
      if (value === 'text-3d') return this.isText3DEnabled;
      if (value === 'round-corners') return this.isMediaRoundCornersEnabled;
      if (value === 'border') return this.isMediaBorderEnabled;

      return false;
    },
    toggleEnable(value) {
      if (value === 'text-bg') return this.toggleEnableTextBackground();
      if (value === 'drop-shadow' && this.isTextEffectsType) return this.toggleEnableTextDropShadow();
      if (value === 'drop-shadow' && this.isMediaEffectsType) return this.toggleEnableMediaDropShadow();
      if (value === 'blur' && this.isTextEffectsType) return this.toggleEnableTextBlur();
      if (value === 'blur' && this.isMediaEffectsType) return this.toggleEnableMediaBlur();
      if (value === 'reflection') return this.toggleEnableReflection();
      if (value === 'ground-shadow') return this.toggleEnableGroundShadow();
      if (value === 'glow' && this.isTextEffectsType) return this.toggleEnableGlow('text');
      if (value === 'glow' && this.isMediaEffectsType) return this.toggleEnableGlow('media');
      if (value === 'light-sweep' && this.isTextEffectsType) return this.toggleEnableLightSweep('text');
      if (value === 'light-sweep' && this.isMediaEffectsType) return this.toggleEnableLightSweep('media');
      if (value === 'glitch' && this.isTextEffectsType) return this.toggleEnableGlitch('text');
      if (value === 'glitch' && this.isMediaEffectsType) return this.toggleEnableGlitch('media');
      if (value === 'outline') return this.toggleEnableTextOutline();
      if (value === 'text-3d') return this.toggleEnableText3D();
      if (value === 'round-corners') return this.toggleEnableMediaRoundCorners();
      if (value === 'border') return this.toggleEnableMediaBorder();

      return false;
    },
    isDisabled(effect) {
      const { animateInOption, animateOutOption, animateDuringValue } = this.getActiveElements[0].timeline_settings;
      const isEmphasisAnimationByLetter = animateDuringValue === 'peek' || animateDuringValue === 'snake';
      const isAnimationNotByObject = animateInOption !== 'default' || animateOutOption !== 'default';
      const isGradient = typeof this.getActiveElements[0].data.color[0] === 'object';

      if (this.isTextEffectsType) {
        if (effect === 'light-sweep') return isGradient || isAnimationNotByObject || isEmphasisAnimationByLetter;
        if (effect === 'glow' || effect === 'glitch' || effect === 'text-3d') return isAnimationNotByObject || isEmphasisAnimationByLetter;
      }

      if (this.isMediaEffectsType) {
        if (effect === 'light-sweep') return this.isWebmElement || this.isAnimated;
        if (effect === 'glitch') return this.elementType === 'videos' || this.elementType !== subgroups.IMAGES;
      }

      return false;
    },

    // ----------------------------
    // ----------------------------

    toggleEnableTextBackground() {
      this.catchHistory('element');
      this.updateCanvasElementTextBackground({ isEnabled: !this.isEnabled('text-bg') });
      this.$root.$emit('canvasElementSizeUpdated');

      if (!this.isEnabled('text-bg')) {
        this.setIsTextBackground(false);
      }
    },
    toggleEnableMediaDropShadow() {
      this.catchHistory('element');
      this.updateCanvasElementMediaShadow({ isEnabled: !this.isEnabled('drop-shadow') });
      if (!this.isEnabled('drop-shadow')) {
        this.setIsMediaShadow(false);
      }
    },
    toggleEnableTextDropShadow() {
      this.catchHistory('element');
      this.updateCanvasElementTextShadow({ isEnabled: !this.isEnabled('drop-shadow') });
      if (!this.isEnabled('drop-shadow')) {
        this.setIsTextShadow(false);
      }
    },
    toggleEnableMediaBlur() {
      this.catchHistory('element');
      this.updateCanvasElementMediaBlur({ isEnabled: !this.isEnabled('blur') });
      if (!this.isEnabled('blur')) {
        this.setIsMediaBlur(false);
      }
    },
    toggleEnableTextBlur() {
      this.catchHistory('element');
      this.updateCanvasElementTextBlur({ isEnabled: !this.isEnabled('blur') });
      if (!this.isEnabled('blur')) {
        this.setIsTextBlur(false);
      }
    },
    async toggleEnableGlow(type) {
      this.catchHistory('element');

      if (type === 'text') {
        const textGlowModifiedRadius = this.getActiveElements[0].data.textGlow.radius;
        const radius = textGlowModifiedRadius || defaultTextGlowRadius; // if its a first time enable or empty, start with default value - if modified already, use the modified value
        this.updateCanvasElementTextGlow({ isEnabled: !this.isEnabled('glow'), radius });
      }
      if (type === 'media') this.updateCanvasElementMediaGlow({ isEnabled: !this.isEnabled('glow') });

      if (!this.isEnabled('glow')) {
        if (type === 'text') this.setIsTextGlow(false);
        if (type === 'media') this.setIsMediaGlow(false);
      }

      this.$root.$emit('canvasElementSizeUpdated');
    },
    toggleEnableLightSweep(type) {
      this.catchHistory('element');

      if (type === 'text') this.updateCanvasElementTextLightSweep({ isEnabled: !this.isEnabled('light-sweep') });
      if (type === 'media') this.updateCanvasElementMediaLightSweep({ isEnabled: !this.isEnabled('light-sweep') });

      if (!this.isEnabled('light-sweep')) {
        if (type === 'text') this.setIsTextLightSweep(false);
        if (type === 'media') this.setIsMediaLightSweep(false);
      }

      this.$root.$emit('canvas-container-reset-timeline');
    },
    toggleEnableGlitch(type) {
      this.catchHistory('element');

      if (type === 'text') this.updateCanvasElementTextGlitch({ isEnabled: !this.isEnabled('glitch') });
      if (type === 'media') this.updateCanvasElementMediaGlitch({ isEnabled: !this.isEnabled('glitch') });

      if (!this.isEnabled('glitch')) {
        if (type === 'text') this.setIsTextGlitch(false);
        if (type === 'media') this.setIsMediaGlitch(false);
      }

      this.$root.$emit('canvas-container-reset-timeline');
    },
    toggleEnableTextOutline() {
      this.catchHistory('element');
      this.updateCanvasElementTextOutline({ isEnabled: !this.isEnabled('outline') });
      if (!this.isEnabled('outline')) {
        this.setIsTextOutline(false);
      }
    },
    toggleEnableText3D() {
      this.catchHistory('element');
      const text3dModifiedDistance = this.getActiveElements[0].data.text3D.distance;
      const distance = text3dModifiedDistance || defaultText3dDistance; // if its a first time enable or empty, start with default value - if modified already, use the modified value

      this.updateCanvasElementText3D({ isEnabled: !this.isEnabled('text-3d'), distance });
      if (!this.isEnabled('text-3d')) {
        this.setIsText3D(false);
      }

      this.$root.$emit('canvasElementSizeUpdated');
    },
    toggleEnableMediaRoundCorners() {
      this.catchHistory('element');
      this.updateCanvasElementMediaRoundCorners({ isEnabled: !this.isEnabled('round-corners') });
      if (!this.isEnabled('round-corners')) {
        this.setIsMediaRoundCorners(false);
      }
    },
    toggleEnableMediaBorder() {
      this.catchHistory('element');
      const mediaBorderModifiedSize = this.getActiveElements[0].data.mediaBorder.size;
      const size = mediaBorderModifiedSize || defaultMediaBorderSize; // if its a first time enable or empty, start with default value - if modified already, use the modified value

      this.updateCanvasElementMediaBorder({ isEnabled: !this.isEnabled('border'), size });
      this.$root.$emit('canvasElementSizeUpdated');

      if (!this.isEnabled('border')) {
        this.setIsMediaBorder(false);
      }
    },
    toggleEnableReflection() {
      this.catchHistory('element');
      this.updateCanvasElementReflection({ isEnabled: !this.isEnabled('reflection') });
      if (!this.isEnabled('reflection')) {
        this.setIsReflection(false);
      }
    },
    toggleEnableGroundShadow() {
      this.catchHistory('element');
      this.updateCanvasElementGroundShadow({ isEnabled: !this.isEnabled('ground-shadow') });
      if (!this.isEnabled('ground-shadow')) {
        this.setIsGroundShadow(false);
      }
    },
    /**
     * onBlurEffectInput
        To check & validate if user delete the value from the input, and click outside
        The input value will return Empty or undefined or NaN value.
     */
    onBlurEffectInput(event, item) {
      const { effect, selectedInput, suffix } = item;
      const selectedEffect = this.getActiveElements[0].data[effect];
      const { value } = event.target;

      if (!value) {
        if (selectedInput === 'opacity') {
          const data = Math.floor(100 - (selectedEffect[selectedInput] * 100));
          // eslint-disable-next-line no-param-reassign
          event.target.value = data + suffix;
        } else if (suffix) {
          // eslint-disable-next-line no-param-reassign
          event.target.value = selectedEffect[selectedInput] + suffix;
        } else {
          // eslint-disable-next-line no-param-reassign
          event.target.value = selectedEffect[selectedInput];
        }
      }
    },
  },
};

export default effectsMixin;
