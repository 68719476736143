<template lang="pug">
.resizer
  drr.resizer-overlay(
    :class="{ 'is-show': isShow, 'is-text': isText, 'is-mask': isMask, 'is-media': isMedia, 'is-multiple': activeElementsLength > 1, 'is-resizing': isResizing, 'is-hidden': isTextEditing || getIsPlaying, 'is-mobile-bg': isOnMobileBackground, 'group-style': groupStyle, 'is-small': isSmall }"
    :x="itemObject.x"
    :y="itemObject.y"
    :w="itemObject.w"
    :h="itemObject.h"
    :angle="itemObject.angle"
    :position="position"
    :aspectRatio="true"
    :selectable="isSelectable"
    :selected="true"
    :hasActiveContent="isEditableContent"
    :draggable="!getIsPlaying"
    :minSize="minSize"
    @content-active="onResizeActive"
    @resize="updateRect"
    @drag="updateRect"
    @rotate="updateRect"
    @dragstart="startDragging"
    @dragstop="stopDragging"
    @resizestart="resizeStart"
    @resizestop="resizeStop"
    @rotatestart="startRotating"
    @rotatestop="stopRotating"
    :onlyAspectResize="onlyAspectResize"
    @dblclick.native="doubleClickResizer"
    ref="resizerOverlay"
  )
    .resizer-overlay__mover(
      v-if="isSmall"
    )
      i.icon.icon-move
    .resizer-element-badge(
      :style="elementBadgeStyle"
      v-html="elementBadgeText"
    )
    template(v-for="(item, index) in resizerElements")
      template(v-if="item.size.width !== 'auto'")
        ResizerElement(
          :item="item"
          :index="index"
          :totalElements="resizerElements.length"
          :parentRect="rect"
          :originalRect="originalRect"
          :isChanging="isChanging"
          :stick="stick"
          @elementIsRotated="checkIfAllUpdated"
          :angle="currentAngle"
          :isResizing="isResizing"
          :isTextEditing="isTextEditing"
        )
  //- this is to test the aligning
  //- uncomment this to see the bounding box for alignment
  //- drr.resizer-overlay__outline(
  //-   :x="boundingBox.x"
  //-   :y="boundingBox.y"
  //-   :w="boundingBox.w"
  //-   :h="boundingBox.h"
  //-   :angle="boundingBox.angle"
  //-   :selectable="false"
  //- )
</template>

<script>
/* eslint-disable */
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import drr from '@/plugins/@minogin/vue-drag-resize-rotate';
import Vector from '@minogin/vector';
import cloneDeep from 'lodash.clonedeep';
import { isMobile } from '@/assets/scripts/utilities';

import ResizerElement from './ResizerElement.vue';
import elementMixin from '@/components/mixins/element-mixins';

export default {
  name: 'ResizerOverlay',
  mixins: [elementMixin],
  components: {
    drr,
    ResizerElement,
  },
  data() {
    return {
      position: {
        x: 0,
        y: 0,
      },
      rect: {
        x: 0,
        y: 0,
        w: 0,
        h: 0,
        angle: 0,
      },
      originalRect: {
        x: 0,
        y: 0,
        w: 0,
        h: 0,
        angle: 0,
      },
      currentAngle: 0,
      resizerElements: [],
      isChanging: false,
      isResizing: false,
      stick: '',
      updatedElement: 0,
      boundingBox: {
        x: 0,
        y: 0,
        w: 2,
        h: 2,
        angle: 0,
      },
      textResizingTimeout: null,
      documentRoot: document.getElementById('app'),
      mouseCall: true,
    };
  },
  computed: {
    ...mapState(['showSwap', 'showCrop', 'showLiteReplaceModal']),
    ...mapState('inspirationList', ['showRemoveBgOption']),
    ...mapGetters(['getCanvasZoom', 'getIsPlaying', 'isTextEditing', 'isLiteMode']),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getActiveElementsIds',
      'getCanvasElements',
      'getCanvasSize',
    ]),
    isSmall() {
      const minSize = isMobile() ? 50 : 30;
      return this.rect.h < minSize || this.rect.w < minSize;
    },
    isOnMobileBackground() {
      if (!isMobile() || !this.getCanvasElements.length) return false;

      const lastIndex = this.getCanvasElements.length - 1;
      return this.getActiveElementsIds[0] === this.getCanvasElements[lastIndex].data.id;
    },
    onlyAspectResize() {
      // return (this.activeElementsLength > 1)
      //         ? true
      //         : (
      //               (this.getActiveElements && this.getActiveElements.length > 0)
      //             ? !(this.isText || this.isImage)
      //             : false
      //           );

      /**
       * Conditions with aspect ratio only if
       * - multiple elements
       * - mask
       * - video
       * - animated element
       * - shape element that is not html
       */

      return (
        this.activeElementsLength > 1 ||
        this.isMask ||
        this.isAnimated ||
        this.isShapeNotHtml ||
        this.isBrandedGraphic
      );
    },
    canvasSize() {
      return {
        width: this.getCanvasSize.width * this.getCanvasZoom,
        height: this.getCanvasSize.height * this.getCanvasZoom,
      };
    },
    isSelectable() {
      if (this.isOnMobileBackground) return false;
      if (this.getActiveElements.length === 0) return false;
      if (this.getActiveElements[0].lock) return false;
      return true;
    },
    itemObject() {
      const itemObject = this.getBoundingBox();
      return itemObject;
    },
    isShapeNotHtml() {
      if (this.getActiveElements.length === 0) return false;
      return (
        this.getActiveElements.length === 1 &&
        this.getActiveElements[0].type === 'shapes' &&
        !this.getActiveElements[0].data.isHtml
      );
    },
    isText() {
      if (this.getActiveElements.length === 0) return false;
      return this.getActiveElements.length === 1 && this.getActiveElements[0].type === 'texts';
    },
    isImage() {
      if (this.getActiveElements.length === 0) return false;
      return this.getActiveElements.length === 1 && this.getActiveElements[0].type === 'images';
    },
    isMask() {
      if (this.getActiveElements.length === 0) return false;
      return this.getActiveElements.length === 1 && this.getActiveElements[0].type === 'masks';
    },
    isBrandedGraphic() {
      if (
        this.getActiveElements.length === 1 &&
        this.getActiveElements[0].isBrandedAsset &&
        this.getActiveElements[0].data.svg_url
      )
        return true;
      return false;
    },
    isVideo() {
      if (this.getActiveElements.length === 0) return false;
      return this.getActiveElements.length === 1 && this.getActiveElements[0].type === 'videos';
    },
    isAnimated() {
      if (this.getActiveElements.length === 0) return false;
      return this.getActiveElements.length === 1 && this.getActiveElements[0].animated;
    },
    isMedia() {
      let isMedia = false;
      if (this.getActiveElements.length > 0) {
        for (let i = 0; i < this.getActiveElements.length; i++) {
          const element = this.getActiveElements[i];
          if (element.type === 'videos' || element.type === 'images') {
            isMedia = true;
          } else {
            isMedia = false;
          }
        }
      }
      return isMedia;
    },
    activeElementsLength() {
      return this.getActiveElements.length;
    },
    isEditableContent() {
      if (this.isText) {
        return true;
      } else {
        return false;
      }
    },
    minSize() {
      const minSize = {};
      if (this.isText) {
        minSize.width = 35;
      } else {
        minSize.width = 20;
        minSize.height = 20;
      }
      return minSize;
    },
    groupStyle() {
      let isGroup = true;

      this.resizerElements.forEach((element) => {
        if (element.data.isGroup === undefined || !element.data.isGroup) {
          isGroup = false;
        }
      });

      return isGroup;
    },
    isShow() {
      return this.activeElementsLength && !this.showSwap && !this.showCrop;
    },
    elementBadgeStyle() {
      const badgeStyle = {};

      const isMultiple = this.getActiveElements.length > 1;
      const item = this.getActiveElements[0];

      if (!item) return badgeStyle;

      if (isMultiple) {
        badgeStyle.background = '#3ED3A3';
        badgeStyle.width = '130px';
        badgeStyle.textOverflow = 'ellipsis';
        badgeStyle.overflow = 'hidden';
      } else {
        if (item.isBrandedAsset) {
          if (item.data.svg_url) {
            badgeStyle.background = '#FFCA65';
            badgeStyle.width = '114px';
          } else if (item.is_brand) {
            badgeStyle.background = '#FF5D5D';
            badgeStyle.width = '97px';
          } else if (item.type === 'images') {
            badgeStyle.background = '#FF5D5D';
            badgeStyle.width = '104px';
          }
        } else if (item.animated) {
          badgeStyle.background = '#3ED3A3';
          badgeStyle.width = '74px';
        } else if (this.isItemGraphics(item)) {
          badgeStyle.background = '#FFCA65';
          badgeStyle.width = '62px';
        } else if (item.type === 'texts') {
          badgeStyle.background = '#5AACFF';
          badgeStyle.width = '52px';
        } else if (item.type === 'images' || item.type === 'masks') {
          badgeStyle.background = '#FF5D5D';
          badgeStyle.width = '52px';
        } else if (item.type === 'videos') {
          badgeStyle.background = '#FC5AFF';
          badgeStyle.width = '52px';
        }

        badgeStyle.position = 'absolute';
        if ((item.rotate >= 225 && item.rotate <= 270) || item.rotate < -45) {
          badgeStyle.transform = 'rotate(90deg)';
          badgeStyle.top = '40px';
          badgeStyle.right = '-40px';
        } else if (item.rotate >= 135) {
          badgeStyle.transform = 'rotate(180deg)';
          badgeStyle.bottom = '-30px';
          badgeStyle.right = '0px';
        } else if (item.rotate >= 45) {
          badgeStyle.transform = 'rotate(-90deg)';
          badgeStyle.bottom = '10px';
          badgeStyle.left = '-40px';
        }
      }

      if (this.isOnMobileBackground) {
        badgeStyle.background = '#00865A';
        badgeStyle.width = '88px';
      }
      return badgeStyle;
    },
    elementBadgeText() {
      const isMultiple = this.getActiveElements.length > 1;
      const item = this.getActiveElements[0];
      // console.log('this.getActiveElements', this.getActiveElements, this.activeGroups);

      if (!item) return '';

      if (this.isOnMobileBackground) {
        return 'Background';
      }

      if (isMultiple) {
        return this.activeGroups.length === 1 && this.activeGroups[0]
          ? this.activeGroups[0]
          : 'Multiple elements';
      } else {
        if (item.isBrandedAsset) {
          if (item.data.svg_url) {
            return 'Branded Graphic';
          } else if (item.is_brand) {
            return 'Branded Logo';
          } else if (item.type === 'images') {
            return 'Branded Image';
          }
        } else if (item.animated) {
          return 'Animated';
        } else if (this.isItemGraphics(item)) {
          return 'Graphic';
        } else if (item.type === 'texts') {
          return 'Text';
        } else if (item.type === 'images' || item.type === 'masks') {
          return 'Image';
        } else if (item.type === 'videos') {
          return 'Video';
        }
      }
    },
    activeGroups() {
      if (this.getActiveElements.length <= 1) return;

      const elements = cloneDeep(this.getActiveElements);

      const groups = elements.reduce((unique, item) => {
        if (typeof unique !== 'undefined' && typeof item.data.groupId !== 'undefined') {
          const { groupName } = item.data;
          return unique.includes(groupName) ? unique : [...unique, groupName];
        }
      }, []);

      return typeof groups !== 'undefined' ? groups : [];
    },
  },
  methods: {
    ...mapMutations(['setIsTextEditing']),
    ...mapMutations('canvasElements', [
      // 'updateEdittedTextId',
      'emptyActiveElements',
      // 'changeActiveElements',
      'addActiveElements',
      'setIsChangingElements',
    ]),
    ...mapActions('canvasHistory', ['catchHistory']),
    // elementIndex(id) {
    //   if (typeof id === 'undefined') id = this.getActiveElements[0].data.id;
    //   return this.getCanvasElements.findIndex(el => el.data.id === id);
    // },
    isItemGraphics(item) {
      // some element is returning type as static therefore we need to use category
      return (
        item.type === 'graphics' ||
        item.category === 'graphics' ||
        (item.category && item.category[0] === 'graphics')
      );
    },
    updateRect(rect) {
      this.rect.x = rect.x;
      this.rect.y = rect.y;
      this.rect.w = rect.w;
      this.rect.h = rect.h;
      this.rect.angle = rect.angle;
    },
    getBoundingBox(isAlignmentBound) {
      let itemObject = {
        x: 0,
        y: 0,
        w: 2,
        h: 2,
        angle: 0,
      };

      // console.log('resizerElements length', this.resizerElements.length)

      if (this.resizerElements.length) {
        const boundingBox = {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        };

        let angle = this.currentAngle;

        for (let i = 0; i < this.resizerElements.length; i++) {
          const element = this.resizerElements[i];
          // console.log('element.size.width', element.size.width, element.size.height)
          if (
            element.size.width === 'auto' ||
            element.size.height === 'auto' ||
            element.size.width === null ||
            element.size.height === null
          )
            continue; // if there is no exact with or height, skip this element

          const position = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          };

          if (i === 0) {
            boundingBox.left = this.getCanvasSize.width;
            boundingBox.top = this.getCanvasSize.height;
          }

          position.left = (element.position.x - element.size.width / 2) * this.getCanvasZoom;
          position.right = (element.position.x + element.size.width / 2) * this.getCanvasZoom;
          position.top = (element.position.y - element.size.height / 2) * this.getCanvasZoom;
          position.bottom = (element.position.y + element.size.height / 2) * this.getCanvasZoom;

          if (element.rotate !== 0 && (isAlignmentBound || this.resizerElements.length !== 1)) {
            let rotate = element.rotate;

            let radAngle = Vector.rad(element.rotate);
            // console.log('angle', element.rotate, radAngle)
            let relative = {
              top: position.top - element.position.y * this.getCanvasZoom,
              left: position.left - element.position.x * this.getCanvasZoom,
              bottom: position.bottom - element.position.y * this.getCanvasZoom,
              right: position.right - element.position.x * this.getCanvasZoom,
            };
            // console.table(relative);
            let topLeft = new Vector(relative.left, relative.top).rotate(radAngle);
            let topRight = new Vector(relative.right, relative.top).rotate(radAngle);
            let bottomLeft = new Vector(relative.left, relative.bottom).rotate(radAngle);
            let bottomRight = new Vector(relative.right, relative.bottom).rotate(radAngle);

            let mostCorner = {
              top: Math.min(topLeft.y, topRight.y, bottomLeft.y, bottomRight.y),
              bottom: Math.max(topLeft.y, topRight.y, bottomLeft.y, bottomRight.y),
              left: Math.min(topLeft.x, topRight.x, bottomLeft.x, bottomRight.x),
              right: Math.max(topLeft.x, topRight.x, bottomLeft.x, bottomRight.x),
            };

            position.left = element.position.x * this.getCanvasZoom + mostCorner.left;
            position.right = element.position.x * this.getCanvasZoom + mostCorner.right;
            position.top = element.position.y * this.getCanvasZoom + mostCorner.top;
            position.bottom = element.position.y * this.getCanvasZoom + mostCorner.bottom;

            // console.table({
            //   vectortop: topLeft.y,
            //   vectorleft: topLeft.x,
            //   vectorbottom: bottomRight.y,
            //   vectorright: bottomRight.x,
            // })
            // console.table({
            //   mosttop: mostCorner.top,
            //   mostleft: mostCorner.left,
            //   mostbottom: mostCorner.bottom,
            //   mostright: mostCorner.right,
            // })
          }

          if (boundingBox.top > position.top) boundingBox.top = position.top;
          if (boundingBox.bottom < position.bottom) boundingBox.bottom = position.bottom;
          if (boundingBox.left > position.left) boundingBox.left = position.left;
          if (boundingBox.right < position.right) boundingBox.right = position.right;

          let width = Math.abs(boundingBox.right - boundingBox.left);
          let height = Math.abs(boundingBox.bottom - boundingBox.top);

          if (isAlignmentBound) angle = 0;

          itemObject = {
            x: boundingBox.left + width / 2,
            y: boundingBox.top + height / 2,
            w: width,
            h: height,
            angle,
          };
        }
      }
      return itemObject;
    },
    editText() {
      // this.updateEdittedTextId());
      this.setIsTextEditing(true);
    },
    onResizeActive() {
      if (this.isText) {
        this.editText();
        setTimeout(() => {
          for (const child of this.$children) {
            child.$emit('content-inactive');
          }
        }, 200);
      }
    },
    updateOriginalRect(rect) {
      this.originalRect.w = rect.w;
      this.originalRect.h = rect.h;
      this.originalRect.x = rect.x;
      this.originalRect.y = rect.y;
      // console.log('set original rect', this.originalRect)
    },
    resizeStart(rect, stick) {
      this.isChanging = true;
      this.isResizing = true;
      this.stick = stick;
      this.setIsChangingElements(true);
      this.$root.$emit('element-is-resizing', this.getActiveElementsIds);
    },
    resizeStop(rect) {
      this.isChanging = false;
      this.isResizing = false;
      this.stick = '';
      this.setIsChangingElements(false);
      this.checkAutoWidthActiveElement();
      if (this.isText && this.$refs.resizerOverlay) {
        this.updateRectBasedOnBoundingBox();
        this.updateTextElementSize();
        this.$root.$emit('element-stop-resizing', this.getActiveElementsIds);
      }
    },
    updateTextElementSize() {
      if (!(this.isText && this.$refs.resizerOverlay)) return;
      const element = this.getActiveElements[0];
      const activeId = element.data.id;

      clearTimeout(this.textResizingTimeout);
      this.textResizingTimeout = setTimeout(() => {
        // console.log('element text size', element)
        // only update position if text is not editing
        // or if the current active id is the same as previous one
        if (
          this.isTextEditing ||
          (this.getActiveElementsIds.length && activeId !== this.getActiveElementsIds[0])
        )
          return;

        const canvasZoom = this.getCanvasZoom;
        const { offsetHeight, offsetTop } = this.$refs.resizerOverlay.$el;

        const y = (offsetTop + offsetHeight / 2) / canvasZoom;
        console.log('updateTextElementSize', y, offsetHeight / canvasZoom);
        // don't update if height is 0
        if (offsetHeight) {
          this.updateCanvasElementRect({
            id: element.data.id,
            position: {
              y,
            },
            size: {
              height: offsetHeight / canvasZoom,
            },
          });
        }
      }, 500);
    },
    updateRectBasedOnBoundingBox() {
      this.resizerElements = cloneDeep(this.getActiveElements);

      let angle = 0;

      if (this.resizerElements.length === 1) angle = this.resizerElements[0].rotate;

      this.boundingBox = this.getBoundingBox(true);
      const newObject = {
        w: this.itemObject.w,
        h: this.itemObject.h,
        x: this.itemObject.x,
        y: this.itemObject.y,
        angle,
      };
      // console.log('rect is', newObject)
      this.updateRect(newObject);
      this.updateOriginalRect(newObject);
    },
    checkAutoWidthActiveElement() {
      // only update the bounding box if there is no auto width on active elements
      let hasAutoWidth =
        this.getActiveElements.findIndex((element) => element.size.width === 'auto') >= 0;
      // console.log('-> has auto width', hasAutoWidth)
      if (!hasAutoWidth) {
        this.updateRectBasedOnBoundingBox();
      }
    },
    checkIfAllUpdated() {
      this.updatedElement++;

      if (this.updatedElement === this.getActiveElementsIds.length) {
        // this.checkAutoWidthActiveElement();
        this.rotate = 0;
      }
    },
    updateElement(rect) {
      // this.emptyActiveElements();
      // this.changeActiveElements(this.rect);
    },
    doubleClickResizer() {
      // if (this.isText) this.editText();
      if (this.isMask) this.$root.$emit('swap-mask');
    },
    alignElements(direction) {
      let position = {
        x: this.position.x,
        y: this.position.y,
      };
      switch (direction) {
        case 'left':
          position.x = this.boundingBox.w / 2;
          break;
        case 'right':
          position.x = this.canvasSize.width - this.boundingBox.w / 2;
          break;
        case 'center':
          position.x = this.canvasSize.width / 2;
          break;
        case 'top':
          position.y = this.boundingBox.h / 2;
          break;
        case 'bottom':
          position.y = this.canvasSize.height - this.boundingBox.h / 2;
          break;
        case 'middle':
          position.y = this.canvasSize.height / 2;
          break;
        case 'mc':
          position.x = this.canvasSize.width / 2;
          position.y = this.canvasSize.height / 2;
          break;
      }
      this.alignResizerOverlay(position);
    },
    alignResizerOverlay(position) {
      this.isChanging = true;
      this.rect.x = position.x;
      this.rect.y = position.y;
      setTimeout(() => {
        this.isChanging = false;
        this.position = position;
      }, 0);
    },
    resetActiveElements() {
      let activeElementsIds = this.getActiveElementsIds.slice();
      this.emptyActiveElements();
      this.addActiveElements(activeElementsIds.shift());
      // After rotating multiple elements, the first element has to be added first
      // for the resizer to show correctly. Then the remaining elements are added.
      setTimeout(() => {
        for (let elementId in activeElementsIds) {
          this.addActiveElements(activeElementsIds[elementId]);
        }
      });
    },
    startDragging() {
      this.isChanging = true;
      this.setIsChangingElements(true);
    },
    stopDragging() {
      this.isChanging = false;
      this.setIsChangingElements(false);
    },
    startRotating() {
      this.isChanging = true;
      this.setIsChangingElements(true);
      this.documentRoot.addEventListener('mousemove', this.addStyle);
    },
    stopRotating() {
      this.isChanging = false;
      this.resetActiveElements();
      this.setIsChangingElements(false);
      this.documentRoot.removeEventListener('mousemove', this.addStyle);
    },
    addStyle(e) {
      if (!this.mouseCall) return;
      this.mouseCall = false;

      this.documentRoot.style.setProperty('--mouse-x', `${e.clientX}px`);
      this.documentRoot.style.setProperty('--mouse-y', `${e.clientY}px`);

      setTimeout(() => {
        this.mouseCall = true;
      }, 0);
    },
  },
  mounted() {
    this.documentRoot = document.getElementById('app');

    this.$root.$on('canvasElementSizeUpdated', (data) => {
      // console.log('on canvasElementSizeUpdated')
      this.checkAutoWidthActiveElement();
      // this.updateTextElementSize();
    });

    this.$root.$on('canvas-element-font-updated', (data) => {
      // console.log('on canvas-element-font-updated')
      this.checkAutoWidthActiveElement();
      // this.updateTextElementSize();
      // this.$root.$on('canvas-container-reset-timeline');
    });

    this.$root.$on('canvasElementRotateUpdated', (data) => {
      this.catchHistory('element');

      // if (this.getActiveElementsIds.indexOf(this.item.data.id) >= 0) {
      // this.isChanging = true;
      const { direction } = data;

      let angle = this.currentAngle;

      if (direction === 'ccw') {
        angle -= 90;
      }
      if (direction === 'cw') {
        angle += 90;
      }

      if (angle <= 0) angle += 360;
      if (angle >= 360) angle -= 360;

      // console.log('angle is', angle, this.percentageObject.angle)

      // console.log('angle', {angle, direction})
      // this.rotateElement(angle);
      this.currentAngle = angle;
      console.log('canvasElementRotateUpdated', this.currentAngle);

      const position = {
        rotate: angle,
      };
      this.updateCanvasElementRect(position);
      // this.isChanging = false;

      setTimeout(() => {
        this.resetActiveElements();
      }, 200);
      // }
    });

    // this.$root.$on('canvasElementPositionUpdated', (data) => {
    //   this.originalRect.x = data.x;
    //   this.originalRect.y = data.y;

    //   this.rect.x = data.x;
    //   this.rect.y = data.y;

    //   this.itemObject.x = data.x;
    //   this.itemObject.y = data.y;
    // });

    this.$root.$on('alignElements', (direction) => {
      this.alignElements(direction);
    });
  },
  watch: {
    getCanvasZoom: {
      handler() {
        // update originalRect on canvas zoom changes
        // to update bounding box of the child component(ResizerElement).
        this.updateOriginalRect(this.itemObject);
      },
      immediate: true,
    },
    itemObject: {
      handler(itemObject) {
        this.position = {
          x: itemObject.x,
          y: itemObject.y,
        };
      },
      deep: true,
      immediate: true,
    },
    getActiveElementsIds: {
      handler: function(val) {
        // console.log('===============getActiveElementsIds=================', val.length)

        if (val.length === 0) {
          this.resizerElements.length = 0;
          let itemObject = {
            x: 0,
            y: 0,
            w: 2,
            h: 2,
            angle: 0,
          };
          this.updateRect(itemObject);
          this.updateOriginalRect(itemObject);
        } else {
          // check if the active elements contain element with size auto
          if (val.length === 1) {
            this.currentAngle = this.getActiveElements[0].rotate;
          } else {
            this.currentAngle = 0;
          }
          this.checkAutoWidthActiveElement();
        }

        // console.log('================end=================')
      },
      deep: true,
      immediate: true,
    },
    isChanging(val) {
      if (!val) {
        this.boundingBox = this.getBoundingBox(true);
      }
    },
  },
};
</script>

<style lang="scss">
.resizer-overlay {
  opacity: 0;
  pointer-events: none !important;

  &.stop-resizing {
    display: none;
  }

  &.active::before {
    outline: 1px solid $lightBlue !important;
  }

  &.is-resizing {
    .drr-stick,
    .ro-stick-handle {
      display: none;
    }
  }

  &.is-auto-height {
    height: auto !important;

    .resizer-element {
      height: auto !important;
      position: relative;
    }
  }

  &.is-multiple:not(.group-style) {
    &:before {
      outline: 1px dashed #5aacff !important;
    }
  }

  &.is-hidden {
    visibility: hidden;
  }

  &.is-mobile-bg {
    pointer-events: none;

    & * {
      pointer-events: none !important;
    }

    .drr-stick,
    .ro-stick-handle {
      display: none;
    }
  }

  .temp {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      z-index: 3;
      padding: 5px;
      line-height: 1;
      background: rgba(255, 255, 255, 0.8);
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      display: block;
      background: pink;
      z-index: 2;
    }

    &::after {
      top: 50%;
      left: 0;
      height: 2px;
      width: 100%;
      transform: translateY(-50%);
    }

    &::before {
      top: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      transform: translateX(-50%);
    }
  }

  &.is-show:not(.content-active) {
    opacity: 1;

    & * {
      pointer-events: auto;
    }
  }

  .resizer-element-badge {
    border-radius: 4px;
    color: #fcfcfd;
    margin: -22px 0px 7px 0px;
    padding: 1px 8px;
    font-size: 12px;
    text-align: center;
    text-transform: capitalize;
  }

  .drr-stick-ro {
    // on hold & see if need to use rotate cursor in future, in case need can just uncomment below
    // cursor: url('~@/assets/images/cursors/rotate-cursor-16x16.png') 12 12, pointer;
    transition: color 0.15s ease-in-out, background 0.15s ease-in-out;

    &:before {
      content: $icon-rotate-cw;
      font-family: 'icomoon';
      display: block;
      font-size: 1rem;
    }

    &:hover {
      background: $blue4;
      color: $light;
    }
  }

  .resizer-overlay__mover {
    & ~ .drr-stick-ro {
      margin-left: 10px !important;
      transition: all 0.3s ease-in-out;
    }
  }

  .drr-stick-ro,
  .resizer-overlay__mover {
    width: 24px !important;
    height: 24px !important;
    top: -44px !important;
    background: $light;
    border: $darkGrey200;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .ro-stick-handle {
    border: 0;
  }

  &.is-rotating {
    .drr-stick-ro {
      background: $light !important;
    }
  }

  &.is-small {
    // for short resizer, only show bottom right
    .drr-stick-tl,
    .drr-stick-bl,
    .drr-stick-tr,
    .drr-stick-tm,
    .drr-stick-bm,
    .drr-stick-ml,
    .drr-stick-mr {
      opacity: 0;
    }

    .resizer-element-badge {
      display: none;
    }
  }

  &.is-text.is-small {
    .drr-stick-tl,
    .drr-stick-mr {
      display: block;
    }

    .drr-stick-br {
      display: none;
    }
  }
}

.resizer-overlay__mover {
  position: absolute;
  left: 50%;
  transform: translateX(-22px);
}

.drr.resizer-overlay {
  &.is-auto-height,
  &.is-multiple {
    .resizer-element.is-text {
      width: auto !important;
    }
  }
}

.resizer-overlay__outline {
  outline: 2px solid yellow;
  pointer-events: none;
}
</style>
