<template lang="pug">
  .mask-tab(
    v-click-outside="closeMaskTab"
  )
    template(v-if="isFetchingMaskElements")
      BasePreloader(
        :type=8
      )

    .mask-tab__list(v-else)
      .mask-tab__list-column.column--left
        .list__item-container.container--none-option(
          :class="{'is-active': activeMaskId === -1}"
        )
          button.list__item(
            type="button"
            title="None"
            @click="selectMask('none')"
          ) None
        .list__item-container(
          v-for="mask in evenMaskOptions"
          :key="mask.id"
          :class="{'is-active': mask.id === activeMaskId}"
        )
          button.list__item(
            type="button"
            :title="mask.name"
            @click="selectMask(mask)"
          )
            img.mask-image(
              :src="mask.preview_url"
              :alt="mask.name"
            )

      .mask-tab__list-column.column--right
        .list__item-container(
          v-for="mask in oddMaskOptions"
          :key="mask.id"
          :class="{'is-active': mask.id === activeMaskId}"
        )
          button.list__item(
            type="button"
            :title="mask.name"
            @click="selectMask(mask)"
          )
            img.mask-image(
              :src="mask.preview_url"
              :alt="mask.name"
            )
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import { groups, subgroups } from '@/assets/scripts/enums';
import { checkIsCollapseSidebarButton, getImageSize } from '@/assets/scripts/utilities';

export default {
  name: 'MaskTab',
  data() {
    return {
      activeMaskId: -1,
    };
  },
  computed: {
    ...mapState(['showTimeline']),
    ...mapState('assetsSidebar', ['isFetchingMaskElements']),
    ...mapGetters('assetsSidebar', ['getSidebarElements']),
    ...mapGetters('canvasElements', ['getActiveElements']),
    activeElement() {
      return this.getActiveElements[0];
    },
    maskOptions() {
      const sidebarElementKey = `${groups.MEDIA}/${subgroups.MASKS}/all`;
      return this.getSidebarElements[sidebarElementKey] || [];
    },
    oddMaskOptions() {
      if (!this.maskOptions.length) return [];
      return this.maskOptions.filter((option, index) => index % 2 !== 0);
    },
    evenMaskOptions() {
      if (!this.maskOptions.length) return [];
      return this.maskOptions.filter((option, index) => index % 2 === 0);
    },
  },
  created() {
    if (!this.maskOptions.length) this.getMaskElements();
    this.setupActiveMaskId();
  },
  methods: {
    ...mapActions('canvasHistory', ['catchHistory']),
    ...mapActions('assetsSidebar', ['getMaskElements']),
    ...mapMutations(['setShowMaskTab', 'setShowSidebar']),
    ...mapMutations('canvasElements', ['insertElementToCanvas']),
    closeMaskTab(event) {
      const paths = event.path || event.composedPath();
      const isCollapseSidebarButton = checkIsCollapseSidebarButton(paths);

      if (this.showTimeline && isCollapseSidebarButton) this.setShowSidebar(false);
      this.setShowMaskTab(false);
    },
    setupActiveMaskId() {
      const findSimilarMask = this.maskOptions.find(mask => mask.file === this.activeElement.data.url);
      if (findSimilarMask) this.activeMaskId = findSimilarMask.id;
    },
    selectMask(mask) {
      this.catchHistory('element');

      if (mask === 'none') this.activeMaskId = -1;
      else this.activeMaskId = mask.id;

      // ------------
      // convertion between image to mask / mask to image
      // ------------
      const tempElement = cloneDeep(this.activeElement);
      const activeElementType = this.activeElement.type;

      let convertionType = 'image-to-mask';
      if (mask === 'none') {
        convertionType = 'mask-to-image';
      } else if (mask !== 'none' && activeElementType === subgroups.MASKS) {
        convertionType = 'mask-to-mask';
      }

      if (convertionType === 'image-to-mask') {
        tempElement.type = subgroups.MASKS;
        tempElement.data.name = tempElement.data.name + ' (Mask)'; // eslint-disable-line
        tempElement.data.url = mask.file;
        tempElement.data.urlHd = mask.file;
        tempElement.data.thumb = mask.preview_url;
        tempElement.data.image.url = this.activeElement.data.url;
        tempElement.data.image.isNew = true;
      } else if (convertionType === 'mask-to-image') {
        tempElement.type = subgroups.IMAGES;
        tempElement.data.name = tempElement.data.name.split(' ')[0]; // eslint-disable-line
        tempElement.data.url = this.activeElement.data.image.url;
        tempElement.data.urlHd = this.activeElement.data.image.url;
        tempElement.data.thumb = this.activeElement.data.image.url;
        this.$delete(tempElement.data.image, 'url');
        this.$delete(tempElement.data.image, 'isNew');
      } else {
        // convertionType of 'mask-to-mask' section
        tempElement.data.url = mask.file;
        tempElement.data.urlHd = mask.file;
        tempElement.data.thumb = mask.preview_url;
      }

      if (!this.activeElement.data.image.initialSize) {
        tempElement.data.image.initialSize = {
          width: tempElement.size.width,
          height: tempElement.size.height,
        };
      }

      getImageSize(tempElement.data.url, (width, height) => {
        tempElement.size.width = width;
        tempElement.size.height = height;

        const newElement = {
          element: tempElement,
          isSwap: true,
          isElementConvertion: true,
          convertionType,
          id: this.activeElement.data.id,
        };
        this.insertElementToCanvas(newElement);

        this.$root.$emit('canvasElementSizeUpdated');
      });
    },
  },
};
</script>

<style lang="scss">
.mask-tab {
  background: $darkGrey700;
  height: 100%;
  left: 72px;
  padding: 15px 30px 0;
  position: absolute;
  top: 0;
  width: 360px;
  display: flex;
  flex-direction: column;
  z-index: 3;

  @include smallest {
    width: 270px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
  }

  .mask-tab__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 98%;
    overflow: auto;

    .mask-tab__list-column {
      &.column--left {
        margin-right: 5px;
      }
    }

    .list__item-container {
      margin-top: 5px;
      padding: 5px;
      border-radius: 4px;

      &.is-active {
        pointer-events: none;
      }

      &.container--none-option {
        &.is-active {
          .list__item {
            border: 1px solid $green600;
          }
        }

        &:not(.is-active) {
          &:hover {
            .list__item {
              background: darken($darkGrey600, 1.5);
            }
          }
        }

        .list__item {
          background: $darkGrey600;
          border-radius: 4px;
          height: 110px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: $light;
          font-weight: 500;

          @include smallest {
            height: 95px;
          }
        }
      }

      &:not(.container--none-option) {
        &.is-active {
          .mask-image {
            filter:
              drop-shadow($green600 1px 1px 0px)
              drop-shadow($green600 -1px 1px 0px)
              drop-shadow($green600 1px -1px 0px)
              drop-shadow($green600 -1px -1px 0px);
          }
        }

        &:not(.is-active) {
          &:hover {
            background: linear-gradient(180deg, $darkGrey800, $darkGrey700);
          }
        }
      }
    }

    .list__item {
      width: 110px;
      padding: 0;
      outline: 0;
      background: transparent;

      @include smallest {
        width: 95px;
      }
    }

    .mask-image {
      width: 100%;
    }
  }
}
</style>
