import { mapGetters } from 'vuex';
import { minDuration, maxDuration } from '@/assets/scripts/variables';

const liteModeMixin = {
  computed: {
    ...mapGetters(['isLiteMode']),
    ...mapGetters('userData', [
      'isFreeUser',
    ]),
    // These 3 are also in new scene mixins
    maxDurationScene() {
      return this.isLiteMode ? maxDuration.liteScene : maxDuration.scene;
    },
    minDurationScene() {
      return this.isLiteMode ? minDuration.liteScene : minDuration.scene;
    },
    // maxDurationProject is also in new scene and template mixins
    maxDurationProject() {
      if (this.isLiteMode) {
        return maxDuration.liteProject;
      }
      return this.isFreeUser ? maxDuration.freeUserProject : maxDuration.payingUserProject;
    },
  },
};

export default liteModeMixin;
