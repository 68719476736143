<template lang="pug">
.text-shadow.text-effect
  BaseSwitch.text-shadow__switch.effect__switch(
    name="text-shadow"
    :isChecked="isEnabled('drop-shadow')"
    :isBeta="!isMobile"
    @change="toggleEnable('drop-shadow')"
    :label="label"
  )
  VueSlideUpDown.text-shadow__content.effect__content(
    :class="{'effect-content--enabled': isAccordionOpened && !isMobile || isMobile}"
    :active="isAccordionOpened && isMobile || !isMobile"
  )
    .text-shadow__color.effect__color(v-if="!isMobile")
      span.title Colour
      button.color.btn-add-color(
        :class="{ 'is-active': isTextShadow }"
        type="button"
        :style="colorStyle"
        @click="openColorPicker"
      )

    BaseSlider(
      :class="{'effects__base-slider': !isMobile}"
      is-dark=true
      :min="-200"
      :max="200"
      title="Horizontal"
      :value="textX"
      @blur="onBlurInput($event, 'x')"
      @update="updateTextX"
    )
    BaseSlider(
      :class="{'effects__base-slider': !isMobile}"
      is-dark=true
      :min="-200"
      :max="200"
      title="Vertical"
      :value="textY"
      @blur="onBlurInput($event, 'y')"
      @update="updateTextY"
    )
    BaseSlider(
      :class="{'effects__base-slider': !isMobile}"
      is-dark=true
      :min="0"
      :max="100"
      title="Blur"
      :value="blur"
      @blur="onBlurInput($event, 'blur')"
      @update="updateBlur"
    )
    //- Transparency 100 = Opacity 0
    //- they are inverse
    BaseSlider(
      :class="{'effects__base-slider': !isMobile}"
      is-dark=true
      :min="0"
      :max="100"
      title="Transparent"
      :value="opacity"
      @blur="onBlurInput($event, 'opacity', '%')"
      @update="updateOpacity"
      suffix="%"
    )

    BaseButton.effects__reset-button(
      v-if="!isMobile"
      :is-canvas="true"
      is-dark=true
      is-full-width=true
      @click="resetShadow"
    ) Reset
</template>

<script>
import { mapState } from 'vuex';
import VueSlideUpDown from 'vue-slide-up-down';
import { hexToRgb } from '@/assets/scripts/utilities';
import { textDataFormat } from '@/assets/scripts/variables';
import effectsMixin from '@/components/mixins/effects-mixins';

export default {
  name: 'TextShadow',
  components: {
    VueSlideUpDown,
  },
  mixins: [effectsMixin],
  props: {
    isMobile: { type: Boolean, default: false },
  },
  computed: {
    ...mapState(['isTextShadow']),
    label() {
      return this.isMobile ? 'Shadow' : 'Drop Shadow';
    },
    isAccordionOpened() {
      if (this.isMobile) {
        // for mobile, always open this
        return true;
      }
      return this.isEnabled('drop-shadow');
    },
    // isGradient() {
    //   if (this.getActiveElements.length === 0 || this.getActiveElements[0].type !== 'texts') return false;
    //   if (this.getActiveElements[0].type === 'texts') return this.getActiveElements[0].data.textShadow.color.color1;
    //   return false;
    // },
    textX() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type !== 'texts') return false;
      return this.getActiveElements[0].data.textShadow.x;
    },
    textY() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type !== 'texts') return false;
      return this.getActiveElements[0].data.textShadow.y;
    },
    opacity() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type !== 'texts') return false;
      const opacity = this.getActiveElements[0].data.textShadow.opacity * 100;
      return Math.floor(100 - opacity);
    },
    blur() {
      if (this.getActiveElements.length === 0 || this.getActiveElements[0].type !== 'texts') return false;
      return this.getActiveElements[0].data.textShadow.blur;
    },
    colorStyle() {
      const styleObject = {};

      let shadow = '#000000';

      if (this.getActiveElements.length && this.getActiveElements[0].type === 'texts') {
        const { color } = this.getActiveElements[0].data.textShadow;

        const { r, g, b } = hexToRgb(color);
        shadow = `rgb(${r}, ${g}, ${b})`;
      }

      styleObject.background = shadow;

      return styleObject;
    },
  },
  mounted() {
    if (!this.isEnabled('drop-shadow') && !this.isMobile) {
      this.toggleEnable('drop-shadow');
    }
  },
  methods: {
    updateTextX(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementTextShadow({ x: value });
      }
    },
    updateTextY(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementTextShadow({ y: value });
      }
    },
    updateOpacity(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        const opacity = (100 - parseInt(value, 10)) / 100;
        this.updateCanvasElementTextShadow({ opacity });
      }
    },
    updateBlur(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementTextShadow({ blur: value });
      }
    },
    onBlurInput(event, type, suffix) {
      this.onBlurEffectInput(event, {
        effect: 'textShadow',
        selectedInput: type,
        suffix,
      });
    },
    openColorPicker() {
      this.setIsTextShadow(true);
      this.$emit('open-colorpicker');
    },
    resetShadow() {
      const elementId = this.getActiveElements[0].data.id || null;
      const colorObject = {
        id: elementId,
        color: [textDataFormat.textShadow.color],
        silent: true,
      };

      this.setIsTextShadow(true);

      // didn't use async await because with it the 'updateCanvasElementColor' mutation still not receive 'isTextShadow' properly.
      setTimeout(() => {
        this.catchHistory('element');
        if (elementId) this.updateCanvasElementColor(colorObject);

        this.updateCanvasElementTextShadow({
          opacity: textDataFormat.textShadow.opacity,
          x: textDataFormat.textShadow.x,
          y: textDataFormat.textShadow.y,
          blur: textDataFormat.textShadow.blur,
        });
      }, 100);
    },
  },
};
</script>
