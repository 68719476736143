<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="2" height="2" rx="0.3" fill="white" />
    <rect x="4" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.5" x="8" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.3" x="12" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.1" x="16" width="2" height="2" rx="0.3" fill="white" />
    <rect y="8" width="2" height="2" rx="0.3" fill="white" />
    <rect x="4" y="8" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.5" x="8" y="8" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.3" x="12" y="8" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.1" x="16" y="8" width="2" height="2" rx="0.3" fill="white" />
    <rect y="4" width="2" height="2" rx="0.3" fill="white" />
    <rect x="4" y="4" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.5" x="8" y="4" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.3" x="12" y="4" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.1" x="16" y="4" width="2" height="2" rx="0.3" fill="white" />
    <rect y="12" width="2" height="2" rx="0.3" fill="white" />
    <rect x="4" y="12" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.5" x="8" y="12" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.3" x="12" y="12" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.1" x="16" y="12" width="2" height="2" rx="0.3" fill="white" />
    <rect y="16" width="2" height="2" rx="0.3" fill="white" />
    <rect x="4" y="16" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.5" x="8" y="16" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.3" x="12" y="16" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.1" x="16" y="16" width="2" height="2" rx="0.3" fill="white" />
    <rect x="2" y="2" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.6" x="6" y="2" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.4" x="10" y="2" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.2" x="14" y="2" width="2" height="2" rx="0.3" fill="white" />
    <rect x="2" y="10" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.6" x="6" y="10" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.4" x="10" y="10" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.2" x="14" y="10" width="2" height="2" rx="0.3" fill="white" />
    <rect x="2" y="14" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.6" x="6" y="14" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.4" x="10" y="14" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.2" x="14" y="14" width="2" height="2" rx="0.3" fill="white" />
    <rect x="2" y="6" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.6" x="6" y="6" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.4" x="10" y="6" width="2" height="2" rx="0.3" fill="white" />
    <rect opacity="0.2" x="14" y="6" width="2" height="2" rx="0.3" fill="white" />
  </svg>
</template>

<script>
export default {
  name: 'TransparencySvg',
};
</script>

<style lang="scss"></style>
