<template lang="pug">
.branded-style__container--designer
  template(v-if="hasSidebarElements && !hasBrandedAssets")
    | No assets found
  template(v-else)
    template(v-for="item in group")
      template(v-if="getSidebarElements[`branded/${item.key}/all`] && getSidebarElements[`branded/${item.key}/all`].length")
        .branded-style__group(
          :key="item.key"
        )
          | {{ item.name }}
          ul.grid-lists(
            ref="gridList"
          )
            .col-1of2.col-half
              li.grid-item(
                v-for="element in evenElements(item.key)"
              )
                CategoryItem(
                  v-bind="element"
                )
            .col-1of2.col-half
              li.grid-item(
                v-for="element in oddElements(item.key)"
              )
                CategoryItem(
                  v-bind="element"
                )
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import TemplateApi from '@/services/api/TemplateApi';

import CategoryItem from '@/components/ProjectCanvas/AssetsSidebar/children/CategoryItem.vue';

export default {
  name: 'BrandedAssetContainerDesigner',
  data() {
    return {
      group: [
        { key: 'graphic', name: 'Graphic' },
        { key: 'image', name: 'Image' },
        { key: 'logo', name: 'Logo' },
      ],
    };
  },
  components: {
    CategoryItem,
  },
  computed: {
    ...mapGetters('assetsSidebar', ['getSidebarElements']),
    brandedGraphics() {
      return this.getSidebarElements['branded/graphic/all'] || [];
    },
    brandedImages() {
      return this.getSidebarElements['branded/image/all'] || [];
    },
    brandedLogos() {
      return this.getSidebarElements['branded/logo/all'] || [];
    },
    hasSidebarElements() {
      return this.getSidebarElements['branded/graphic/all']
      || this.getSidebarElements['branded/image/all']
      || this.getSidebarElements['branded/logo/all'];
    },
    hasBrandedAssets() {
      return this.brandedGraphics.length || this.brandedImages.length || this.brandedLogos.length;
    },
  },
  methods: {
    ...mapMutations('assetsSidebar', [
      'addElementToSidebar',
    ]),
    evenElements(group) {
      return this.getSidebarElements[`branded/${group}/all`]
        ? this.getSidebarElements[`branded/${group}/all`].filter((element, index) => index < 20 && index % 2 === 0)
        : [];
    },
    oddElements(group) {
      return this.getSidebarElements[`branded/${group}/all`]
        ? this.getSidebarElements[`branded/${group}/all`].filter((element, index) => index < 20 && index % 2 !== 0)
        : [];
    },
    mapImage(group, item, index) {
      if (group === 'graphic') {
        // eslint-disable-next-line
        item = JSON.parse(item.value);
      }

      const fileUrl = group === 'graphic' ? item.png : item.value;
      const data = {
        ext: fileUrl.split('.').pop(),
        file: fileUrl,
        id: index + 1,
        preview_url: fileUrl,
      };

      data.has_removed_bg = true;
      data.is_branded_asset = true;

      if (group === 'logo') {
        data.is_brand = true;
      } else if (group === 'graphic') {
        data.svg_url = item.svg;
      }

      return data;
    },
  },
  mounted() {
    // Get layout settings
    const params = {
      group: this.group.map(group => group.key).join(','),
    };

    TemplateApi.getLayoutSettings(params)
      .then((response) => {
        console.log('layout settings', response);

        if (response.data.success) {
          this.group.forEach((group) => {
            const keyPrefix = group.key;
            const retrievedGroup = response.data[keyPrefix];
            const brandedGroup = [];

            retrievedGroup.forEach((item, i) => {
              brandedGroup.push(this.mapImage(keyPrefix, item, i));
            });

            this.addElementToSidebar({
              group: 'branded',
              subgroup: keyPrefix,
              slug: 'all',
              data: brandedGroup,
            });
          });
        }
      }).catch((error) => {
        console.error(error);
      })
      .then(() => {
        this.$emit('api-requested');
      });
  },
};
</script>

<style lang="scss">
</style>
