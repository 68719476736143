<template lang="pug">
.assets-navigation
  ul(
    ref="list"
  )
    li.nav-item(
      v-for="item in filteredMenu"
      ref="navItem"
      :class="{ 'nav-item--shortcut': item.group === 'shortcut'}"
    )
      AssetsButton(
        :group="item.group"
        :iconClass="item.iconClass"
        :name="item.name"
      )
    li.nav-item.nav-item__user(
      :class="{ 'is-active': showUserMenu, 'is-rendering': isExporting }"
    )
      UserMenu
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { debounce } from 'debounce';
import { groups } from '@/assets/scripts/enums';

import AssetsButton from './children/AssetsButton.vue';
import UserMenu from '@/components/UserMenu/UserMenu.vue'; // eslint-disable-line

export default {
  name: 'AssetsNavigation',
  data() {
    return {
      menu: [
        { group: 'designs', iconClass: 'icon-template-menu2', name: 'Designs' },
        { group: 'texts', iconClass: 'icon-text-menu2', name: 'Text' },
        { group: 'elements', iconClass: 'icon-graphic-menu2', name: 'Graphics' },
        { group: 'brand', iconClass: 'icon-brand-1', name: 'Brand' },
        { group: 'media', iconClass: 'icon-media-menu2', name: 'Media' },
        { group: 'backgrounds', iconClass: 'icon-background-menu2', name: 'Bkground' },
        { group: 'music', iconClass: 'icon-music-menu2', name: 'Music' },
        { group: 'my-files', iconClass: 'icon-upload-menu2', name: 'Upload' },
        { group: 'favourites', iconClass: 'icon-saved-menu2', name: 'Saved' },
        { group: 'style', iconClass: 'icon-style-menu', name: 'Style' },
      ],
      liteMenu: [
        { group: 'media', iconClass: 'icon-stocks-menu', name: 'Media' },
        { group: 'texts', iconClass: 'icon-text-menu', name: 'Text' },
        { group: 'music', iconClass: 'icon-music', name: 'Music' },
      ],
      activeMenuIndex: 0,
      topPosition: 0,
      offsetScroll: 10,
    };
  },
  components: {
    AssetsButton,
    UserMenu,
  },
  computed: {
    ...mapState(['showSwap']),
    ...mapState('userData', ['showUserMenu']),
    ...mapState('exportDetails', ['isExporting']),
    ...mapGetters(['isLiteMode']),
    ...mapGetters('canvasElements', ['getActiveSceneDetails']),
    ...mapGetters('assetsSidebar', ['getActiveGroup']),
    ...mapGetters('team', ['isInTeamWorkspace']),
    filteredMenu() {
      if (this.isLiteMode) {
        return this.liteMenu;
      }

      let newMenu = [...this.menu];

      if (!this.isInTeamWorkspace) {
        newMenu = this.menu.filter(nav => nav.group !== groups.BRAND);
      }

      if (!this.getActiveSceneDetails.is_branded) {
        return newMenu.filter(nav => nav.group !== groups.STYLE);
      }

      return newMenu;
    },
  },
  watch: {
    getActiveGroup: {
      handler(value) {
        this.setActiveMenuIndex(value);
      },
      immediate: true,
    },
    showSwap() {
      // when swap is toggled, trigger update on position
      setTimeout(() => {
        this.getActiveMenuPosition();
      }, 350);
    },
  },
  mounted() {
    this.offsetScroll = this.$refs.list.scrollTop;
    this.getActiveMenuPosition();

    window.addEventListener(
      'resize',
      debounce(() => {
        this.getActiveMenuPosition();
      }, 500),
    );

    this.$refs.list.addEventListener(
      'scroll',
      debounce(() => {
        this.offsetScroll = this.$refs.list.scrollTop;
        this.getActiveMenuPosition();
      }, 50),
    );
  },
  methods: {
    setActiveMenuIndex(value) {
      this.activeMenuIndex = this.filteredMenu.findIndex(el => el.group === value);
      this.getActiveMenuPosition();
    },
    getActiveMenuPosition() {
      if (this.$refs.navItem && this.$refs.navItem[this.activeMenuIndex]) {
        this.topPosition = this.$refs.navItem[this.activeMenuIndex].offsetTop - this.offsetScroll;
      } else {
        this.topPosition = this.offsetScroll;
      }
    },
  },
};
</script>

<style lang="scss">
.assets-navigation {
  padding: 0;
  width: 72px;
  background: var(--darkgrey800-sidebardarkgrey);
  position: relative;
  z-index: 10;
  top: 0;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  vertical-align: top;
  transition: transform 0.35s ease-in-out, border-radius 0.5s ease-in-out;

  ul {
    list-style: none;
    margin: 0;
    padding: 20px 0 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 2;

    li {
      margin: 0;
    }
  }

  .nav-item--shortcut {
    margin-top: auto;
    margin-bottom: 0;
    border-top: 1px solid $blackBorder;
    padding-top: 8px;

    & + .nav-item__user {
      margin-top: 0;
    }

    .assets-navigation__button {
      min-height: 54px;

      .icon {
        font-size: 1.5em;
      }
    }
  }

  .nav-item__user {
    align-items: center;
    background: none;
    border: 0;
    color: $darkGrey;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    position: relative;
    text-align: center;
    user-select: none;
    width: 54px;
    padding: 10px 0;
    border-radius: 4px;
    margin-left: 8px;
    margin-bottom: 1px;
    margin-top: auto;
    transition: background-color 0.2s ease-in-out;

    &:hover,
    &.is-active {
      background: none;

      &::before {
        content: '';
        width: 4px;
        height: 100%;
        top: 0;
        left: -8px;
        border-radius: 4px;
        background: $btnPrimaryHollowText;
        position: absolute;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }

  .lite-mode & .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lite-mode & ul {
    margin-top: 120px;
  }
}

.nav-item:not(.nav-item__user) {
  .lite-mode & {
    // height: calc((100% - 80px) / 3);
    height: 20%;
  }
}
</style>
