<template lang="pug">
.timeline__item(
  :class="timelineItemRightStyle"
  ref="timelineItem"
  @mouseenter="isHovered = true"
  @mouseleave="isHovered = false"
  :style="[minWidthStyleObject, layerStyle, groupLayerStyle]"
)
  VueDraggableResizable.timeline__path(
    v-if="item.groupElement && !isTimelineReloading"
    axis="x"
    :active="isHovered"
    :w="timelinePathWidthMultiplied"
    :h="height"
    :x="pathLeftMultiplied"
    :y="pathTopMultiplied"
    :parent="true"
    :handles="[]"
    :min-width="minAnimateWidth * timelineMultiplier"
    :max-width="maxTimelineWidth * timelineMultiplier"
    :resizable="!isEndingNone && !isLocked"
    :draggable="!isLocked"
    :id="item.data.id + '-timeline-path'"
    :grid="[timelineGrid, 1]"
    :style="[minWidthStyleObject, groupStyle]"
    :onDragStart="dragStart"
    @click.native="checkTimelinePosition"
    @dragging="draggingGroupTimeline"
    @dragstop="dragStop"
  )
  VueDraggableResizable.timeline__path(
    v-if="!item.groupElement && !isTimelineReloading"
    :class="{'timeline__path--alpha': isAlpha, 'timeline__path--video': isVideo}"
    axis="x"
    :active="isHovered"
    :w="timelinePathWidthMultiplied"
    :h="height"
    :x="pathLeftMultiplied"
    :y="pathTopMultiplied"
    :parent="true"
    :handles="['ml', 'mr']"
    :min-width="minAnimateWidth * timelineMultiplier"
    :max-width="maxTimelineWidth * timelineMultiplier"
    :resizable="!isEndingNone && !isLocked"
    :draggable="!isLocked"
    :id="item.data.id + '-timeline-path'"
    :grid="[timelineGrid, 1]"
    :style="[minWidthStyleObject, transformStyleObject]"
    :onDragStart="dragStart"
    @click.native="timelinePathOnClick"
    @resizing="resizing"
    @resizestop="resizeStop"
    @dragstop="dragStop"
    @dragging="draggingTimeline"
    @deactivated="onTimelineBlur"
  )
    VueDraggableResizable.timeline__section.timeline--in(
      axis="x"
      :active="isHovered"
      :w="animateInWidthMultiplied"
      :h="height"
      :handles="['mr']"
      :min-width="minAnimateInOutWidth * timelineMultiplier"
      :max-width="maxAnimateInWidth * timelineMultiplier"
      :draggable="false"
      :resizable="!isLocked"
      ref="animateIn"
      :grid="[timelineGrid, 1]"
      @click.native="openAnimationList"
      @resizestop="resizeStopAnimateIn"
      @resizing="resizingAnimateIn"
      :class="{ 'has-animation': animationInValue }"
      @mouseover="onHover"
    )
      .vdr-in-animate
      template(v-if="animationInValue")
        i.icon.icon-entrance-animation
      template(v-else)
        i.icon.icon-plus
    .timeline__section.timeline--during(
      @click="openAnimationList"
      :style="duringStyleObject"
      :class="{ 'has-animation': animationDuringValue }"
    )
      template(v-if="animationDuringValue")
        i.icon.icon-emphasis-animation
      template(v-else)
        i.icon.icon-plus
    VueDraggableResizable.timeline__section.timeline--out(
      axis="x"
      :active="isHovered"
      :w="animateOutWidthMultiplied"
      :h="height"
      :handles="['ml']"
      :min-width="minAnimateInOutWidth * timelineMultiplier"
      :max-width="maxAnimateOutWidth * timelineMultiplier"
      :draggable="false"
      :resizable="!isLocked"
      ref="animateOut"
      :grid="[timelineGrid, 1]"
      @click.native="openAnimationList"
      @resizestop="resizeStopAnimateOut"
      @resizing="resizingAnimateOut"
      :class="{ 'has-animation': animationOutValue }"
    )
      .vdr-out-animate
      template(v-if="animationOutValue")
        i.icon.icon-exit-animation
      template(v-else)
        i.icon.icon-plus
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import VueDragResize from 'vue-drag-resize';
import VueDraggableResizable from 'vue-draggable-resizable';
import layerMixin from '@/components/mixins/layer-mixins';
import liteModeMixin from '@/components/mixins/lite-mode-mixins';
import { snap, pixelToSec, secToPixel, minDuration, maxDuration } from '@/assets/scripts/variables';
import { getBoundingClientRect } from '@/assets/scripts/utilities';
// import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'TimelineLayerRight',
  mixins: [layerMixin, liteModeMixin],
  data() {
    return {
      top: 0,
      left: 0,
      width: 100,
      timelineGrid: snap,
      isHovered: false,
      minAnimateWidth: secToPixel(minDuration.fullAnimation),
      minAnimateInOutWidth: secToPixel(minDuration.animateInOut),
      maxAnimateInWidth: 100,
      maxAnimateOutWidth: 100,
      disabledAnimation: false,
      rectWatchers: {},
      showAnimationList: false,
      timelinePositionCache: '',
      snapTolerance: 10, //snap every 10px
      _animateInWidth: null,
      _animateOutWidth: null,
      groupTimelineLeft: 0,
      isTimelineDragged: false, // to ensure stopDrag is not called when the timeline is not dragged
      isTimelineReloading: false,
    };
  },
  props: {
    index: { type: Number, default: 0 },
    hoveredIndex: { type: Number, default: -1 },
    focusedIndex: { type: Number, default: -1 },
    item: { type: Object, default: () => ({}) },
    subItem: { type: Boolean, default: false },
    isDarkMode: { type: Boolean, default: false },
  },
  components: {
    VueDragResize,
    VueDraggableResizable,
    secToPixel,
  },
  computed: {
    ...mapState(['animationList']),
    ...mapState('canvasElements', ['activeElementsIds']),
    ...mapGetters('canvasLayouts', ['timelineMultiplier']),
    timelineItemRightStyle() {
      return {
        'is-locked': this.isLocked,
        'is-hidden': this.isHidden,
        'is-active': this.isActive,
        'disabled-animation': this.disabledAnimation,
        'is-hovered': this.hoveredIndex === this.index,
        'is-clicked': this.clickedLayer === this.index,
        'is-dark': this.isDarkMode,
      };
    },
    duration() {
      return this.item.time_out - this.item.time_in;
    },
    timelinePosition() {
      return secToPixel(this.item.time_in);
    },
    timelinePositionOut() {
      return secToPixel(this.item.time_out);
    },
    timelinePathWidthMultiplied() {
      const multiplier = this.timelineMultiplier || 1;
      return this.timelineWidth * multiplier;
    },
    pathLeftMultiplied() {
      // console.log('path left multiplied', this.left, this.timelineMultiplier, this.left * this.timelineMultiplier)
      const leftPath = this.left * this.timelineMultiplier;
      return leftPath < 0 ? 0 : leftPath;
    },
    pathTopMultiplied() {
      return 0;
    },
    timelineWidth() {
      // return secToPixel(this.animateInWidth + this.animateOutWidth);
      return this.width;
    },
    maxTimelineWidth() {
      // added 3 seconds offset
      return secToPixel(this.maxDurationScene + 3);
    },
    animationInValue() {
      if (!this.item.timeline_settings) return '';
      if (this.item.timeline_settings.animateInValue === 'none') return '';
      return this.item.timeline_settings.animateInValue || '';
    },
    animateInDuration() {
      if (!this.item.timeline_settings) return 1;
      return this.item.timeline_settings.animateInDuration || 1;
    },
    animateInWidth() {
      const duration = this.animateInDuration;
      return secToPixel(duration);
    },
    animationDuringValue() {
      if (!this.item.timeline_settings) return '';
      if (this.item.timeline_settings.animateDuringValue === 'none') return '';
      return this.item.timeline_settings.animateDuringValue || '';
    },
    animationOutValue() {
      if (!this.item.timeline_settings) return '';
      if (this.item.timeline_settings.animateOutValue === 'none') return '';
      return this.item.timeline_settings.animateOutValue || '';
    },
    animateOutDuration() {
      if (!this.item.timeline_settings) return 1;
      return this.item.timeline_settings.animateOutDuration || 1;
    },
    // animateInSpeed() {
    //   if (!this.item.timeline_settings) return 1;
    //   return this.item.timeline_settings.animateInSpeed || 1;
    // },
    // animateOutSpeed() {
    //   if (!this.item.timeline_settings) return 1;
    //   return this.item.timeline_settings.animateOutSpeed || 1;
    // },
    animateOutWidth() {
      // console.log('animateInWidth', this.animateOutDuration)
      const duration = this.animateOutDuration;
      return secToPixel(duration);
    },
    animateInWidthMultiplied() {
      const width = this.animateInWidth === 33 ? 30 : this.animateInWidth;
      return width * this.timelineMultiplier;
    },
    animateOutWidthMultiplied() {
      const width = this.animateOutWidth === 33 ? 30 : this.animateOutWidth;
      return width * this.timelineMultiplier;
    },
    isEndingNone() {
      return this.isVideo && this.item.animation_type === 'none';
    },
    duringStyleObject() {
      const styleObject = {};

      const right = this.animateOutWidthMultiplied > 0 ? this.animateOutWidthMultiplied : 0;
      const left = this.animateInWidthMultiplied > 0 ? this.animateInWidthMultiplied : 0;

      styleObject.right = `${right}px`;
      styleObject.left = `${left}px`;

      return styleObject;
    },
    minWidthStyleObject() {
      // need to set minimum width or else it the animate out will resize together
      const styleObject = {};

      // styleObject.minWidth = `${this.width + this.timelinePosition}px`;
      styleObject.height = `${this.height}px`;

      return styleObject;
    },
    transformStyleObject() {
      const styleObject = {};
      const leftPath = this.left * this.timelineMultiplier;
      if (leftPath < 0) styleObject.transform = `translate(0,0)`;

      return styleObject;
    },
    height() {
      return this.layerHeight - 7;
    },
    isActiveElement() {
      return this.activeElementsIds.includes(this.item.data.id);
    },
    isFocused() {
      return this.focusedIndex === this.index;
    },
    isMultipleElementSelected() {
      return this.activeElementsIds.length > 1;
    },
    shouldControlBatchActions() {
      // determine current timeline layer should control batch actions(dragging, resizing by batch)
      return !this.isFocused && this.isActiveElement;
    },
    groupWidth() {
      const timeIns = [];
      const timeOuts = [];

      this.item.elements.forEach((element) => {
        timeIns.push(element.time_in);
        timeOuts.push(element.time_out);
      });

      const minTimeIn = Math.min(...timeIns) * 100;
      const maxTimeOut = Math.max(...timeOuts) * 100;

      const width = maxTimeOut - minTimeIn;

      return width;
    },
    groupLeft() {
      const timeIns = [];

      this.item.elements.forEach((element) => {
        timeIns.push(element.time_in);
      });

      const minTimeIn = Math.min(...timeIns) * 100;

      return minTimeIn;
    },
    groupTimelinePosition() {
      const styleObject = {};
      styleObject.left = `${this.groupLeft}px`;
      styleObject.width = `${this.groupWidth}px`;
      return styleObject;
    },
    groupStyle() {
      if (this.isGroupActive && this.item.show) {
        if (!this.isDarkMode) {
          return { background: '#99CCFF' };
        }
        return { background: '#6DDFBA' };
      }

      if (!this.isDarkMode) {
        return { backgroundColor: '#C9CED6' };
      }
      return { backgroundColor: '#273A5B' };
    },
  },
  methods: {
    ...mapMutations(['setActiveAnimationList', 'setIsShowAnimationModal']),
    ...mapMutations('canvasElements', [
      'addActiveElements',
      'changeActiveElements',
      'updateCanvasElementTime',
    ]),
    ...mapActions('canvasHistory', ['catchHistory']),
    concernRectWatchers() {
      // invoke watcher only for a focused element
      if (this.isFocused) {
        this.enableRectWatchers();
      } else {
        this.disableRectWatchers();
      }
    },
    pingBatchAction(eventName, data = null) {
      // Logic here is, only a focused layer will throw the event
      // and the other active layers will listen and update on changes
      if (this.isFocused) {
        this.$root.$emit(eventName, data);
      }
    },
    controlBatchAction() {
      this.$root.$on('timeline-resizing', ({ width, left }) => {
        if (this.shouldControlBatchActions) {
          // make sure updated width is valid
          if (width) {
            const newWidth = this.width + width;
            // update width if it won't exceed the min limitation.
            if (newWidth >= this.minAnimateWidth) {
              this.width = newWidth;
            }
          }
          // update left position if only width its not exceeding the min limitation.
          if (this.width >= this.minAnimateWidth) {
            const newLeft = this.left + left;
            // make sure new left position will be valid
            if (newLeft > -1) {
              this.left = newLeft;
            }
          }

          // this.updateInOut({
          //   left: this.left,
          //   width: this.width,
          // });
        }
      });

      this.$root.$on('timeline-resized', () => {
        if (this.shouldControlBatchActions) {
          const left = this.left * this.timelineMultiplier;
          const width = this.width * this.timelineMultiplier;
          this.resizeStop(left, null, width);
        }
      });

      this.$root.$on('timeline-dragged', () => {
        if (this.shouldControlBatchActions) {
          const left = this.left * this.timelineMultiplier;
          this.dragStop(left);
        }
      });

      this.$root.$on('timeline-resizing-animate-in-out', ({ animationType, width }) => {
        if (this.shouldControlBatchActions) {
          switch (animationType) {
            case 'in':
              const newAnimateInWidth = (this.animateInWidth + width) * this.timelineMultiplier;
              if (
                newAnimateInWidth &&
                newAnimateInWidth >= this.minAnimateInOutWidth &&
                newAnimateInWidth <= this.maxAnimateInWidth
              ) {
                // make sure updated width is valid
                // update width if it won't exceed the min limitation.
                if (newAnimateInWidth >= this.minAnimateWidth) {
                  this.updateAnimateIn(0, 0, newAnimateInWidth, 0);
                }
              }
              break;
            case 'out':
              const newAnimateOutWidth = (this.animateOutWidth + width) * this.timelineMultiplier;
              if (
                newAnimateOutWidth &&
                newAnimateOutWidth >= this.minAnimateInOutWidth &&
                newAnimateOutWidth <= this.maxAnimateOutWidth
              ) {
                console.debug('timeline-resizing-animate-in-out:' + this.index, width);
                // make sure updated width is valid
                // update width if it won't exceed the min limitation.
                if (newAnimateOutWidth >= this.minAnimateWidth) {
                  this.updateAnimateOut(0, 0, newAnimateOutWidth, 0);
                }
              }
              break;
          }
        }
      });

      this.$root.$on('timeline-resized-animate-in-out', () => {
        if (this.shouldControlBatchActions) {
          this.updateAnimation();
        }
      });
    },
    disableRectWatchers() {
      try {
        this.rectWatchers.width.unwatch();
        this.rectWatchers.left.unwatch();
      } catch (error) {
        // this is an expected error doesn't need to be displayed.
      }
    },
    enableRectWatchers() {
      this.rectWatchers = {
        width: {
          unwatch: this.$watch('width', this.onWidthChanges, { immediate: true }),
        },
        left: {
          unwatch: this.$watch('left', this.onLeftChanges, { immediate: true }),
        },
      };
    },
    focusTimelineLayer() {
      // timeline dragging/resizing , animate in/out dragging/resizing
      // need to focus the one active elements to update by batch
      if (!this.isFocused) {
        // set focus only if there's more than one of elements are selected (or) remove focus
        const index = this.isMultipleElementSelected ? this.index : -1;
        // set dumped prev animationIn/Out widths to null if current timeline will be focused
        if (index !== -1) this.resetDumpedAnimationInOutWidths();
        // set focus
        this.$emit('focused', index);
      }
    },
    resetDumpedAnimationInOutWidths() {
      this._animateInWidth = null;
      this._animateOutWidth = null;
    },
    onWidthChanges(newWidth, oldWidth) {
      // calc diff between old and new width
      // to update widths of other active timelines
      const width = newWidth - oldWidth;
      this.$root.$emit('timeline-resizing', {
        width,
      });
    },
    onLeftChanges(newLeft, oldLeft) {
      // calc diff between old and new left position
      // to update left position of other active timelines
      const left = newLeft - oldLeft;
      this.$root.$emit('timeline-resizing', {
        left,
      });
    },
    checkTimelinePosition(e) {
      this.timelinePositionCache = e.srcElement.closest('.timeline__path').style.left || 0;
      // console.log('checkTimelinePosition', this.timelinePositionCache);
    },
    draggingTimeline(x) {
      const rect = {
        left: x,
        width: this.timelinePathWidthMultiplied,
      };

      if (!this.item.data.isGroup) {
        this.focusTimelineLayer();
      }

      let dragPosition = rect.left + 'px';
      this.left = Math.round(rect.left / this.timelineMultiplier);
      this.width = Math.round(rect.width / this.timelineMultiplier);

      if (this.timelinePositionCache != dragPosition) {
        this.isTimelineDragged = true;
      }

      rect.handle = 'timeline';
      this.$emit('dragging', rect, this.item.data.id);
    },
    onTimelineBlur() {
      this.showAnimationList = false;
    },
    resizing(x, y, width) {
      this.focusTimelineLayer();

      this.left = Math.round(x / this.timelineMultiplier);
      this.width = Math.round(width / this.timelineMultiplier);
    },
    resizingAnimateIn(left, top, width, height) {
      this.focusTimelineLayer();
      if (this.isFocused) {
        // ping and give updated width to other active timeline layer to resize
        // only if current timeline layer is focused and is selected by batch

        if (!this._animateInWidth) this._animateInWidth = this.animateInWidth;

        this.updateAnimateIn(left, top, width, height);

        // we resize the animate duration individually
        // this.pingBatchAction('timeline-resizing-animate-in-out', {
        //   animationType: 'in',
        //   width: this.animateInWidth - this._animateInWidth,
        // });

        // save width before changes to calc diff in next changes
        this._animateInWidth = this.animateInWidth;
      } else {
        this.updateAnimateIn(left, top, width, height);
      }
    },
    resizingAnimateOut(left, top, width, height) {
      this.focusTimelineLayer();
      if (this.isFocused) {
        // ping and give updated width to other active timeline layer to resize
        // only if current timeline layer is focused and is selected by batch

        if (!this._animateOutWidth) this._animateOutWidth = this.animateOutWidth;

        this.updateAnimateOut(left, top, width, height);
        // we resize the animate duration individually
        // this.pingBatchAction('timeline-resizing-animate-in-out', {
        //   animationType: 'out',
        //   width: this.animateOutWidth - this._animateOutWidth,
        // });

        // save width before changes to calc diff in next changes
        this._animateOutWidth = this.animateOutWidth;
      } else {
        this.updateAnimateOut(left, top, width, height);
      }
    },
    timelinePathOnClick(e) {
      // console.log('timelinePathOnClick');
      this.checkTimelinePosition(e);
    },
    resizeStop(x, y, width) {
      const timeline = {
        left: x,
        width,
      };
      this.pingBatchAction('timeline-resized');
      this.updateTimeline(timeline);
    },
    dragStart(e) {
      this.timelinePositionCache = this.pathLeftMultiplied;
      // console.log('dragStart timelinePositionCache', this.timelinePositionCache);
      this.showAnimationList = true;
    },
    dragStop(x) {
      const timeline = {
        left: x,
        width: this.timelinePathWidthMultiplied,
      };
      // console.log('dragStop', timeline);
      // only trigger this when it's actually being dragged
      if (this.isTimelineDragged) {
        //   console.log('dragStop');
        this.pingBatchAction('timeline-dragged');
        // only update drag stop if it has been dragged
        // if (parseInt(this.timelinePositionCache) !== timeline.left) this.updateTimeline(timeline);
        if (this.item.groupElement) {
          this.updateGroupTimeline(timeline);
        } else {
          this.updateTimeline(timeline);
        }
      } else if (!this.item.groupElement && this.shouldControlBatchActions) {
        // when it's dragged together with other element
        this.updateTimeline(timeline);
      }

      if (this.timelinePositionCache == x) {
        this.showAnimationList = true;
      } else {
        this.showAnimationList = false;
      }

      this.isTimelineDragged = false;
    },
    updateTimeline(timeline) {
      this.catchHistory('element');

      const timeIn = pixelToSec(timeline.left / this.timelineMultiplier) ;
      const timeOut = pixelToSec(timeline.left / this.timelineMultiplier) + pixelToSec(timeline.width / this.timelineMultiplier);

      const item = {
        id: this.item.data.id,
        timeIn: Math.round(timeIn * 10) / 10,
        timeOut: Math.round(timeOut * 10) / 10,
      };

      // console.log('updateTimeline',timeline, item)
      this.updateCanvasElementTime(item);
      this.updateAnimation();
    },
    updateInOut(rect) {
      // const beforeRect = JSON.parse(JSON.stringify(rect));

      // rect.left = Math.round(rect.left / this.snapTolerance) * this.snapTolerance;

      // if( beforeRect.left < rect.left ){
      // to avoid width staggering when resizing from left timeline handle
      // rect.width = Math.floor(rect.width / this.snapTolerance) * this.snapTolerance;
      // }
      // else{
      //   rect.width = Math.round(rect.width / this.snapTolerance) * this.snapTolerance;
      // }

      this.left = rect.left;
      this.width = rect.width;

      // console.log(this.item);
      // this.changeActiveElements(this.item.data.id);
      // update the animate in and out depending on the duration
      const { left, top, width, height } = rect;
      const duration = pixelToSec(rect.width);
      const { animateInDuration, animateOutDuration } = this.item.timeline_settings;

      // console.log('updateInOut', left, width, duration)
      // when user is resizing
      // collapse animate in and out if the duration is less than in + out
      if (animateInDuration + animateOutDuration >= duration - 0.1) {
        // for example duration is 1.9 sec
        // animate in and out need to be 0.9
        let halfDuration = Math.round((duration / 2) * 10) / 10;
        if (halfDuration < minDuration.animateInOut) halfDuration = minDuration.animateInOut;
        const item = {
          id: this.item.data.id,
          animateInDuration: halfDuration,
          animateOutDuration: halfDuration,
        };

        // console.log('updateInOuthalf duration', item);
        this.updateCanvasElementTime(item);
      }

      if (duration < minDuration.showAnimation) {
        this.disabledAnimation = true;
      } else {
        this.disabledAnimation = false;
      }

      this.$emit('resizing', rect, this.item.data.id);
    },
    updateAnimateIn(left, top, width, height) {
      width = width / this.timelineMultiplier;
      let animationInDuration = pixelToSec(width);

      if (animationInDuration == 0.3) {
        animationInDuration = 0.33;
      }

      const item = {
        id: this.item.data.id,
        animateInDuration: animationInDuration,
      };

      this.updateCanvasElementTime(item);

      const rect = {
        left,
        width,
      };

      rect.handle = 'animate-in';

      this.$emit('dragging', rect, this.item.data.id);
    },
    resizeStopAnimateIn(left, top, width) {
      this.updateAnimateIn(left, top, width);
      this.updateAnimation();
    },
    resizeStopAnimateOut(left, top, width) {
      this.updateAnimateOut(left, top, width);
      this.updateAnimation();
    },
    updateAnimation() {
      this.pingBatchAction('timeline-resized-animate-in-out');
      this.catchHistory('element');
      // emit so the tweenmax is updated
      this.updateMaxWidth();
      this.$root.$emit('canvas-container-reset-timeline');
    },
    updateAnimateOut(left, top, width, height) {
      width = width / this.timelineMultiplier;
      let animationOutDuration = pixelToSec(width);

      if (animationOutDuration == 0.3) {
        animationOutDuration = 0.33;
      }

      const item = {
        id: this.item.data.id,
        animateOutDuration: animationOutDuration,
        animateOutStart: pixelToSec(this.timelinePositionOut - width),
      };

      this.updateCanvasElementTime(item);

      const rect = {
        left,
        width,
      };

      rect.handle = 'animate-out';

      this.$emit('dragging', rect, this.item.data.id);
    },
    async openAnimationList(e) {
      if (this.isLocked) {
        this.clickLayer(e);
        return;
      }
      if (this.showAnimationList) {
        let value = 'in';

        // const isDuring =
        //   e?.path?.findIndex((path) => {
        //     return (
        //       path.className &&
        //       typeof path.className === 'string' &&
        //       path.className.indexOf('timeline--during') > -1
        //     );
        //   }) > -1;
        // const isOut =
        //   e?.path?.findIndex((path) => {
        //     return (
        //       path.className &&
        //       typeof path.className === 'string' &&
        //       path.className.indexOf('timeline--out') > -1
        //     );
        //   }) > -1;

        const target = e?.target.closest('.timeline__section');
        const position = getBoundingClientRect(target);

        const isDuring = target?.className?.indexOf('timeline--during') > -1;
        const isOut = target?.className?.indexOf('timeline--out') > -1;

        if (isDuring) {
          value = 'during';
        } else if (isOut) {
          value = 'out';
        }
        const timelineRight = document.getElementsByClassName('timeline__right')[0];

        // need to make sure the timeline popup will not go beyond the timeline right
        const timelineRightPosition = timelineRight.getBoundingClientRect();
        let { x, left, right, width, top, height } = position;

        // AnimationContainer only needs position x, y, width and height
        if (left < timelineRightPosition.left) {
          // note: need to do this cause some browser doesn't have x position
          x = timelineRightPosition.left;
          width = right - x;
        }

        // console.log('e', cloneDeep(position), position, timelineRightPosition)

        position.x = x + width / 2;
        position.width = width;
        // note: need to do this cause some browser doesn't have y position
        position.y = top + height / 2;

        const partOfActiveElements = this.activeElementsIds.includes(this.item.data.id);

        if (e && (e.ctrlKey || e.shiftKey)) {
          this.addActiveElements(this.item.data.id);
        } else if (!partOfActiveElements) {
          this.changeActiveElements(this.item.data.id);
        }

        // console.log('openAnimationList', [...this.activeElementsIds])
        await this.setIsShowAnimationModal(false); // so when changing to another animation modal it will mount again
        this.setIsShowAnimationModal(true);
        this.setActiveAnimationList({
          type: value,
          id: [...this.activeElementsIds],
          position,
          animationPosition: 'bottom',
        });
      }
    },
    // animationText(value) {
    //   let text = '';
    //   let animationObject = null;

    //   // if (this.getActiveElements.length === 0) return text;

    //   if (value === 'in') {
    //     animationObject = this.animationList[0].list.find(obj => obj.value === this.item.timeline_settings.animateInValue);
    //   } else if (value === 'during') {
    //     animationObject = this.animationList[1].list.find(obj => obj.value === this.item.timeline_settings.animateDuringValue);
    //   } else if (value === 'out') {
    //     animationObject = this.animationList[2].list.find(obj => obj.value === this.item.timeline_settings.animateOutValue);
    //   }

    //   if (animationObject && animationObject.name) text = animationObject.name;

    //   return text;
    // },
    // updatingMaxDuration() {
    //   let animation_in_duration = this.animateInWidth
    //   let animation_out_duration = this.animateOutWidth

    //   this.minAnimateWidth = animation_in_duration + animation_out_duration
    // },
    updateMaxWidth() {
      // const duration = this.item.timeline_settings.animationDuration;
      const duration = this.item.time_out - this.item.time_in;
      const animateInDuration = this.item.timeline_settings.animateInDuration;
      const animateOutDuration = this.item.timeline_settings.animateOutDuration;

      if (duration < minDuration.showAnimation) {
        this.disabledAnimation = true;
      } else {
        this.disabledAnimation = false;
      }
      // console.log('duration', duration, minDuration.animateInOut * 2, this.disabledAnimation)

      // check the changed handle is left or right
      let isLeft = false;
      let isRight = false;

      if (this.left !== secToPixel(this.item.time_in)) {
        // this.left = secToPixel(this.item.time_in);
        isLeft = true;
      }
      if (this.width !== secToPixel(duration)) {
        // this.width = secToPixel(duration);
        isRight = true;
      }

      let maxDurationIn = maxDuration.animateInOut;
      let maxDurationOut = maxDuration.animateInOut;

      if (maxDurationIn >= duration - animateOutDuration) {
        maxDurationIn = duration - animateOutDuration;

        if (maxDurationIn < minDuration.animateInOut) {
          maxDurationIn = minDuration.animateInOut;
        }
      }

      if (maxDurationOut >= duration - animateInDuration) {
        maxDurationOut = duration - animateInDuration;

        if (maxDurationOut < minDuration.animateInOut) {
          maxDurationOut = minDuration.animateInOut;
        }
      }

      this.maxAnimateInWidth = secToPixel(maxDurationIn);
      this.maxAnimateOutWidth = secToPixel(maxDurationOut);

      // check if after dragged, the duration is bigger than maxDuration
      if (animateInDuration > maxDurationIn || animateOutDuration > maxDurationOut) {
        const item = {
          id: this.item.data.id,
        };

        // if from the left, then reduce the right side first until it's the smallest
        if (isLeft) {
          if (animateOutDuration > maxDurationOut) {
            // check if max duration out is less than the minimum 0.5
            item.animateOutDuration = maxDurationOut;

            // check if max duration in is more than duration - duration out
            if (maxDurationIn > duration - maxDurationOut) {
              maxDurationIn = duration - maxDurationOut;

              if (maxDurationIn < minDuration.animateInOut) {
                maxDurationIn = minDuration.animateInOut;
              }

              item.animateInDuration = maxDurationIn;
            }
          }
        } else if (isRight) {
          if (duration < animateInDuration + animateOutDuration) {
            if (maxDurationIn == 0.3) {
              maxDurationIn = 0.33;
            }
            item.animateInDuration = maxDurationIn;

            if (maxDurationIn == 0.33) {
              let decrease_out = duration - maxDurationIn;
              if (decrease_out <= 0.3) {
                item.animateOutDuration = 0.33;
              } else {
                item.animateOutDuration = decrease_out;
              }
            }
          }
        }
        this.updateCanvasElementTime(item);
      }
    },
    // resizeAnimate(el) {
    //   if (el.width > 200) {
    //     return false;
    //   }
    // },
    draggingGroupTimeline({ left }) {
      this.focusTimelineLayer();
      const rect = {};

      rect.width = Math.round(this.groupWidth / this.timelineGrid) * this.timelineGrid;
      rect.left = Math.round(left / this.timelineGrid) * this.timelineGrid;

      let dragPosition = rect.left + 'px';
      // this.left = rect.left;
      // this.width = rect.width;

      if (this.timelinePositionCache == dragPosition) {
        // this.showAnimationList = true;
      } else {
        // this.showAnimationList = false;
        this.isTimelineDragged = true;
      }

      rect.handle = 'timeline';
      this.$emit('dragging', rect, this.item.data.id);
    },
    updateGroupTimeline(timeline) {
      this.catchHistory('element');

      timeline.left = timeline.left / this.timelineMultiplier;

      this.item.elements.forEach((element) => {
        let timeIn = 0;
        let timeOut = 0;

        if (this.groupTimelineLeft < timeline.left) {
          timeIn = secToPixel(element.time_in) + (timeline.left - this.groupTimelineLeft);
        } else if (this.groupTimelineLeft > timeline.left) {
          timeIn = secToPixel(element.time_in) - (this.groupTimelineLeft - timeline.left);
        }

        timeOut = timeIn + secToPixel(element.time_out - element.time_in);

        const item = {
          id: element.data.id,
          timeIn: pixelToSec(timeIn),
          timeOut: pixelToSec(timeOut), // fix when sometimes quick dragging it compute +/- 0.1
        };

        this.updateCanvasElementTime(item);
      });

      this.groupTimelineLeft = timeline.left;
      this.$root.$emit('canvas-container-reset-timeline');
    },
    onHover() {
      // console.log('hover trigger');
    },
    appendStylisedResizer() {
      this.$nextTick(() => {
        let leftNode = document.createElement('div');
        let rightNode = document.createElement('div');
        leftNode.classList.add('vdr-left-animate');
        rightNode.classList.add('vdr-right-animate');

        let parentNode = document.getElementById(this.item.data.id + '-timeline-path');

        if (parentNode) {
          parentNode.append(leftNode);
          parentNode.append(rightNode);
        }
      });
    },
  },
  mounted() {
    this.updateMaxWidth();
    this.concernRectWatchers();
    // this.minAnimateWidth = this.animateInWidth + this.animateOutWidth
    this.controlBatchAction();

    if (this.item.groupElement) {
      this.width = this.groupWidth;
      this.left = this.groupLeft;

      this.groupTimelineLeft = this.groupLeft;
    } else {
      this.width = secToPixel(this.duration);
      this.left = secToPixel(this.item.time_in);
    }
    // console.log('this.left', this.left, this.item.time_in)

    this.appendStylisedResizer();
  },
  watch: {
    isFocused(isFocused) {
      if (isFocused && this.item.data.id) {
        if (!this.isActiveElement) this.changeActiveElements(this.item.data.id);
      }
      this.concernRectWatchers();
    },
    isHovered(val) {
      if (val) this.$emit('hovered', this.index);
    },
    duration(val) {
      // console.log('duration', val)
      this.updateMaxWidth();
      this.width = secToPixel(val);
      this.$root.$emit('canvas-container-reset-timeline');
    },
    'item.elements': {
      handler() {
        if (this.item.groupElement) {
          this.width = this.groupWidth;
          this.left = this.groupLeft;
          this.groupTimelineLeft = this.groupLeft;
        }
      },
      deep: true,
    },
    'item.time_in': {
      handler(val) {
        this.left = secToPixel(val);
        this.width = secToPixel(this.duration);
        this.updateMaxWidth();
        this.$root.$emit('canvas-container-reset-timeline');
      },
    },
    timelineMultiplier: {
      handler(val) {
        // console.log('timelineMultiplier', val, this.item.time_in)
        this.timelineGrid = 1;
        this.isTimelineReloading = true;

        if (this.item.groupElement) {
          this.width = this.groupWidth;
          this.left = this.groupLeft;
          this.groupTimelineLeft = this.groupLeft;
        }

        setTimeout(() => {
          this.timelineGrid = snap * val;
          // this is a cheat because when the timeline multiplier is updated, VueDraggableResizable's x wasn't computed properly
          this.isTimelineReloading = false;
          this.appendStylisedResizer();
          // console.log('timelineMultiplier 2', val, this.timelineGrid)
        }, 1);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.timeline__right {
  .timeline__item {
    height: 43px;
    overflow: hidden;

    &.disabled-animation {
      .timeline--in,
      .timeline--out {
        display: none !important;
      }
    }

    &.is-dark .handle {
      cursor: url('~@/assets/images/cursors/col-resize-dark.svg') 12 12, col-resize;
    }
  }

  .vdr.active:before {
    display: none;
  }

  .handles.not-resizable {
    display: none !important;
  }
}

.vdr.timeline__path {
  // need to add .vdr because on npm run build, it get overwritten by default vdr
  background: $timelineGrey;
  height: 100%;
  width: 100%;
  position: relative !important;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border-radius: 4px;

  .icon {
    color: $light;
    transition: opacity 0.25s ease-in-out;
    font-size: 0.65em;

    // .timeline.is-compact & {
    //   font-size: 0.65em;
    // }
  }

  &.ending-none {
    & > .vdr-stick {
      display: none;
    }
  }

  // instead of making it pointer events
  // use js so that user still can hover on the hide and lock button
  // .timeline__item.is-locked &,
  // .timeline__item.is-hidden & {
  //   pointer-events: none;
  // }

  &.disable-animation {
    .timeline__section:not(.timeline--during) {
      display: none;
    }
  }

  .timeline__right .timeline__item.is-active:not(.is-hidden):not(.is-locked) & {
    background: $blue;

    .vdr-left-animate,
    .vdr-right-animate,
    .vdr-in-animate,
    .vdr-out-animate {
      background-color: #0a44a5;
    }
  }

  & > .handle {
    position: absolute;
    z-index: 4;
    top: 0;
    font-size: 0.375em;
    line-height: 1;
    height: 36px !important;
    margin-top: 0 !important;
    border: 0;
    background: transparent;
    box-shadow: none;
    justify-content: center;
    align-items: center;
    cursor: url('~@/assets/images/cursors/col-resize-light.svg') 12 12, col-resize;
    width: 10px !important;
    border-radius: 4px;
    display: flex !important;

    &.handle-ml {
      left: -3px !important;

      &:hover ~ .vdr-left-animate {
        width: 12px;
      }
    }

    &.handle-mr {
      right: -3px !important;

      &:hover ~ .vdr-right-animate {
        width: 12px;
      }
    }

    .timeline.is-compact & {
      height: 23px !important;
      border-top-width: 4px;
      border-bottom-width: 4px;
    }
  }
}

.timeline__section {
  height: 100%;
  border-collapse: collapse;
  transition: padding 0.25s ease-in-out, background 0.25s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 2;
  color: $light;
  text-transform: uppercase;
  background: var(--lightgrey500-darkgrey700);
  transform: none !important;

  &::after {
    content: '';
    position: absolute;
    left: 6px;
    top: 3px;
    right: 6px;
    bottom: 3px;
    border-radius: 4px;
  }

  .timeline__item.is-hovered & {
    background: var(--lightgrey600-darkgrey800);
  }

  &:hover {
    .icon {
      animation: swing 0.5s infinite;
      transform-origin: top center;
    }
  }

  &.timeline--during {
    width: auto;
    z-index: 1;

    &::after {
      left: 0;
      right: 0;
    }
  }

  &:not(.timeline--during) {
    z-index: 3 !important;
  }

  .timeline__text {
    font-size: 0.625rem;
    margin-left: 2px;
    line-height: 0.8;
  }

  .icon {
    transition: margin 0.25s ease-in-out;
    position: relative;
    z-index: 2;
    display: inline-block;
  }

  .timeline__item.is-active:not(.is-hidden):not(.is-locked) & {
    background: var(--blue700-green700);
  }

  .timeline__item.is-locked & {
    &.timeline--in,
    &.timeline--out {
      &::before {
        content: '';
        height: 100%;
        width: 2px;
        display: block;
        background: var(--white-darkgrey600);
        top: 0;
        position: absolute;
      }
    }

    &.timeline--in {
      &::before {
        right: 0;
        left: auto;
      }
    }

    &.timeline--out {
      &::before {
        left: 0;
        right: auto;
      }
    }
  }

  &.timeline--out {
    left: auto !important;
    right: 0;
  }

  .handle {
    padding: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.25s ease-in-out;
    margin-top: 0 !important;
    cursor: url('~@/assets/images/cursors/col-resize-light.svg') 12 12, col-resize;
    display: block !important;
    width: 10px !important;
    height: 100%;
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      background: var(--white-darkgrey600);
      left: 0;
      top: 0;
    }

    .timeline.is-compact & {
      border-top-width: 4px;
      border-bottom-width: 4px;
    }

    &.handle-ml {
      left: -5px;

      &::after {
        left: 5px;
      }
    }

    &.handle-mr {
      right: -5px;

      &::after {
        left: auto;
        right: 5px;
      }
    }
  }
}

.vdr-left-animate,
.vdr-right-animate,
.vdr-in-animate,
.vdr-out-animate {
  width: 0px;
  position: absolute;
  top: 4px;
  height: 14px;
  border-radius: 10px;
}

.vdr-left-animate,
.vdr-right-animate {
  z-index: 3;
  background-color: $lightGrey900;
  transition: width 0.3s ease-in-out;
}

.vdr-left-animate {
  left: -12px;
  transform: translateX(50%);
}

.vdr-right-animate {
  right: -12px;
  transform: translateX(-50%);
}

.vdr-in-animate,
.vdr-out-animate {
  z-index: 1;
  background-color: $lightGrey900;
  transition: width 0.3s ease-in-out;
}

.vdr-in-animate {
  right: 1px;
  transform: translateX(50%);
}

.vdr-out-animate {
  left: 1px;
  transform: translateX(-50%);
}

@keyframes swing {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }

  25% {
    -webkit-transform: rotateZ(-5deg);
    transform: rotateZ(-5deg);
  }

  75% {
    -webkit-transform: rotateZ(5deg);
    transform: rotateZ(5deg);
  }

  100% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(6px);
  }
}
</style>
