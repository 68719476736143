<template lang="pug">
li.category-list-item
  button.category-item(
    :class="{ 'is-loaded': imageIsLoaded}"
    @click="selectCategory"
  )
    img.category-item__image(:src="image" v-if="image" ref="image")
    span.name {{ name }}
</template>

<script>
import { mapMutations } from 'vuex';
import categoryMixins from '@/components/mixins/category-mixins';

import CategoryItem from './CategoryItem.vue';

export default {
  name: 'Category',
  mixins: [categoryMixins],
  components: {
    CategoryItem,
  },
  data() {
    return {
      imageIsLoaded: false,
    };
  },
  props: {
    id: { type: Number, default: null },
    name: { type: String, default: '' },
    previews: { type: Array, default: () => [] },
    image: { type: String, default: '' },
    slug: { type: String, default: '' },
    meta: { type: Array, default: () => [] },
  },
  methods: {
    ...mapMutations('assetsSidebar', ['setActiveCategory', 'setTagSearch']),
    selectCategory() {
      const activeCategoryData = this.getActiveCategoryData({
        id: this.id,
        slug: this.slug,
        type: 'category',
        meta: this.meta,
      });
      this.setActiveCategory(activeCategoryData);
      this.setTagSearch(false);
    },
  },
  mounted() {
    if (this.$refs.image) {
      this.$refs.image.onload = () => {
        this.imageIsLoaded = true;
      };
    } else {
      this.imageIsLoaded = true;
    }
  },
};
</script>

<style scoped lang="scss">
.category-list-item {
  width: 100%;
  padding: 2px 3px;
  margin: 0;
  display: inline-block;
  cursor: pointer;
  vertical-align: top;

  &:not(.item--equal-height) {
    .category-item {
      &::before {
        content: '';
        padding-top: 100%;
        display: block;
      }
    }
  }

  .category-item {
    padding: 0;
    height: auto;

    &.is-loaded {
      .name {
        opacity: 1;
      }
    }

    .category-item__image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .name {
    position: absolute;
    height: 75%;
    width: 100%;
    pointer-events: none;
    background: linear-gradient(0deg, rgba(10, 31, 68, 0.8), rgba(10, 31, 68, 0));
    left: 0;
    bottom: 0;
    padding: 0 8px 6px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    font-size: 0.7rem;
    font-weight: 400;
    color: $light;
    text-align: left;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }
}
</style>
