<template lang="pug">
.controls__item
  template(
    v-for="(color, index) in normalisedElementColor"
    v-if="(hasColor && !hasMultiColor) || (!hasColor && hasMultiColor)"
  )
    //- use == for getActiveColorId because it is a string
    //- while getId might return integer
    button.btn.btn--text.btn-element-color(
      :class="{ 'is-active': getActiveColorId == getId(color, index) }"
      type='button'
      @click="toggleColorTab(getId(color, index))"
    )
      span.btn-color(
        :style="{ background: backgroundColor(color) }"
      )
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { groups } from '@/assets/scripts/enums';

export default {
  name: 'ControlElement',
  data() {
    return {
      elementColor: [],
    };
  },
  computed: {
    ...mapGetters('canvasElements', ['getActiveElements', 'getActiveColorId']),
    ...mapGetters('assetsSidebar', [
      'getActiveGroup',
    ]),
    normalisedElementColor() {
      const colorArray = [];

      if (!this.elementColor) return colorArray;

      for (let i = 0; i < this.elementColor.length; i += 1) {
        const color = this.elementColor[i];

        let isExist = false;

        for (let j = 0; j < colorArray.length; j += 1) {
          if (colorArray[j].nm && colorArray[j].nm === color.nm) {
            isExist = true;
            break;
          }
        }

        if (!isExist) colorArray.push(color);
      }

      return colorArray;
    },
    hasColor() {
      let hasColor = true;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (element.data.color.length !== 1) {
          hasColor = false;
          return true;
        }
      });

      return hasColor;
    },
    hasMultiColor() {
      let hasMultiColor = true;

      // eslint-disable-next-line
      this.getActiveElements.some((element) => {
        if (element.data.color.length <= 1) {
          hasMultiColor = false;
          return true;
        }
      });

      return hasMultiColor;
    },
  },
  methods: {
    ...mapMutations('assetsSidebar', [
      'setActiveGroup',
    ]),
    ...mapMutations(['setMaintainShowBackgroundColorTab']),
    toggleColorTab(id) {
      // change tab so that it wouldn't change background colour
      if (this.getActiveGroup === groups.BACKGROUNDS) {
        this.setActiveGroup(groups.ELEMENTS);
      }
      this.setMaintainShowBackgroundColorTab(true);
      this.$emit('toggle-color-tab', id);
    },
    backgroundColor(color) {
      // console.log('backgroundColor', cloneDeep(color));
      let newColor = '#ffffff';
      if (typeof color === 'string') {
        // for solid color
        newColor = color;
      } else if (color.color) {
        // for solid color for animated json
        // since animated json need `nm` key
        newColor = color.color;
      } else if (color.points) {
        // for multiple gradient color
        const degree = typeof color.degree === 'number' ? color.degree : 135;
        newColor = `linear-gradient(${degree}deg`;
        const { points } = color;
        for (let i = 0; i < points.length; i += 1) {
          const point = points[i];
          newColor += `, ${point.color} ${point.percentage}%`;
        }
        newColor += ')';
      }
      return newColor;
    },
    getId(color, index) {
      if (color.nm) return color.nm;
      return index;
    },
    updateColor() {
      if (this.getActiveElements.length === 0) return;
      this.elementColor = this.getActiveElements[0].data.color;
    },
    onCanvasElementColorUpdated() {
      setTimeout(() => {
        this.updateColor();
      }, 500);
    },
  },
  mounted() {
    this.updateColor();

    // TODO: Better way to do this?
    this.$root.$on('canvas-element-color-updated', this.onCanvasElementColorUpdated);
  },
  beforeDestroy() {
    // need to off to avoid memory leak
    this.$root.$off('canvas-element-color-updated', this.onCanvasElementColorUpdated);
  },
  watch: {
    getActiveElements: {
      handler() {
        this.updateColor();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.controls__item {
  .btn-stroke {
    position: relative;
    margin-right: 4px;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    border: 1px solid $timelineGrey;

    &:last-child {
      margin-right: 0;
    }
  }

  .btn-element-color.btn--text {
    min-width: 20px;
    padding: 2px;
    background: transparent !important;
  }

  .btn-color {
    background: transparent;
    margin-right: 4px;
    border-radius: 2px;
    min-width: 18px;
    min-height: 18px;
    padding: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
