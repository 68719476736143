<template lang="pug">
  .edit-tab__effects-tab
    button.effects-item-container(
      v-for="effect in effectsOptions"
      :key="effect.text"
      :class="{'effects-container--disabled': isDisabled(effect.value)}"
      type="button"
      @click="onClickEffectContainer(effect.value)"
    )
      BaseSwitch.effects-item__switch(
        :name="effect.value"
        :is-checked="isEnabled(effect.value)"
        @change="toggleEnable(effect.value)"
      )

      .effects-item(
        @click="onClickEffectItem(effect.value)"
      )
        img.item-image(
          :src="url(effect.image)"
          :alt="effect.text"
        )
        p.item-text {{effect.text}}
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { subgroups } from '@/assets/scripts/enums';
import effectsMixin from '@/components/mixins/effects-mixins';
import helperMixin from '@/components/mixins/helper-mixins';

export default {
  name: 'EffectsTab',
  mixins: [effectsMixin, helperMixin],
  computed: {
    ...mapState(['editTabEffectsType']),
    ...mapGetters('canvasElements', ['getActiveElements']),
    activeElement() {
      return this.getActiveElements[0];
    },
    effectsOptions() {
      let options = [
        {
          text: 'Text Bg',
          image: 'effects-text-background.png',
          value: 'text-bg',
          effectOnlyFor: 'text',
          onlyOnSingleText: true,
        },
        {
          text: 'Drop Shadow',
          image: 'effects-drop-shadow.png',
          value: 'drop-shadow',
          effectOnlyFor: 'text & media',
        },
        {
          text: 'Blur',
          image: 'effects-blur.png',
          value: 'blur',
          effectOnlyFor: 'text & media',
        },
        {
          text: 'Reflection',
          image: 'effects-reflection.png',
          value: 'reflection',
          effectOnlyFor: 'text & media',
        },
        {
          text: 'Ground Shadow',
          image: 'effects-ground-shadow.png',
          value: 'ground-shadow',
          effectOnlyFor: 'text & media',
        },
        {
          text: 'Glow',
          image: 'effects-glow.png',
          value: 'glow',
          effectOnlyFor: 'text & media',
        },
        {
          text: 'Light Sweep',
          image: 'effects-lightsweep.png',
          value: 'light-sweep',
          effectOnlyFor: 'text & media',
          notForElement: ['videos', 'shapes'],
        },
        {
          text: 'Glitch',
          image: 'effects-glitch.png',
          value: 'glitch',
          effectOnlyFor: 'text & media',
        },
        {
          text: 'Outline',
          image: 'effects-outline.png',
          value: 'outline',
          effectOnlyFor: 'text',
        },
        {
          text: '3D Text',
          image: 'effects-3d-text.png',
          value: 'text-3d',
          effectOnlyFor: 'text',
        },
        {
          text: 'Round Corners',
          image: 'effects-round-corners.png',
          value: 'round-corners',
          effectOnlyFor: 'media',
        },
        {
          text: 'Border',
          image: 'effects-border.png',
          value: 'border',
          effectOnlyFor: 'media',
          notForElement: ['shapes'],
        },
      ];
      const isMediaEffectsType = this.editTabEffectsType === 'media';
      const isTextEffectsType = this.editTabEffectsType === 'text';

      if (isMediaEffectsType) {
        const onlyMediaEffects = options.filter(effect => effect.effectOnlyFor !== 'text');
        options = onlyMediaEffects;
      }

      if (isTextEffectsType && this.isSingleText) {
        const onlyTextEffects = options.filter(effect => effect.effectOnlyFor !== 'media');
        options = onlyTextEffects;
      }

      if (isTextEffectsType && this.isGroupOfTextElements) {
        const onlyTextEffects = options.filter(effect => effect.effectOnlyFor !== 'media' && !effect.onlyOnSingleText);
        options = onlyTextEffects;
      }

      options = options.filter((effect) => {
        const isRestrictedElementExist = effect.notForElement && effect.notForElement.includes(this.elementType);
        return !isRestrictedElementExist;
      });

      return options;
    },
    isSingleText() {
      return this.getActiveElements.length === 1 && this.getActiveElements[0].type === 'texts';
    },
    isGroupOfTextElements() {
      let isGroup = true;
      let isAllTextElements = true;
      const isMultipleSelection = this.getActiveElements.length > 1;

      this.getActiveElements.forEach((element) => {
        if (element.data.isGroup === undefined || !element.data.isGroup) isGroup = false;
        if (element.type !== 'texts') isAllTextElements = false;
      });

      return (isGroup && isAllTextElements) || (isMultipleSelection && isAllTextElements);
    },
  },
  methods: {
    url(image) {
      // eslint-disable-next-line
      return require(`@/assets/images/toolbar/${image}`);
    },
    onClickEffectItem(effect) {
      if (this.isDisabled(effect)) return;
      this.$emit('set-active-effect-group', effect);
    },
    // eslint-disable-next-line
    onClickEffectContainer(effect) {
      const { animateInOption, animateOutOption, animateDuringValue } = this.activeElement.timeline_settings;
      const isEmphasisAnimationByLetter = animateDuringValue === 'peek' || animateDuringValue === 'snake';
      const isAnimationNotByObject = animateInOption !== 'default' || animateOutOption !== 'default';
      const isGradient = typeof this.activeElement.data.color[0] === 'object';
      const removedPluralText = this.elementType.includes('s') ? this.elementType.slice(0, -1) : this.elementType;

      if (!this.isDisabled(effect)) return;

      /* eslint-disable */
      if (this.isTextEffectsType) {
        if (effect === 'glow') {
          if (isAnimationNotByObject) return this.alertWarn('', `The animation type you apply doesn't support this effect. Change to animate by "object"`, 8000, 'TopCenterNotif');
          if (isEmphasisAnimationByLetter) return this.alertWarn('', `The emphasis animation you apply doesn't support this effect. Change to another emphasis animation.`, 8000, 'TopCenterNotif');
        }

        if (effect === 'light-sweep') {
          if (isGradient) return this.alertWarn('', 'Light Sweep Effect is not available on Gradient Text.', 5000, 'TopCenterNotif');
          if (isAnimationNotByObject) return this.alertWarn('', `The animation type you apply doesn't support this effect. Change to animate by "object"`, 8000, 'TopCenterNotif');
          if (isEmphasisAnimationByLetter) return this.alertWarn('', `The emphasis animation you apply doesn't support this effect. Change to another emphasis animation.`, 8000, 'TopCenterNotif');
        }

        if (effect === 'glitch') {
          if (isAnimationNotByObject) return this.alertWarn('', `The animation type you apply doesn't support this effect. Change to animate by "object"`, 8000, 'TopCenterNotif');
          if (isEmphasisAnimationByLetter) return this.alertWarn('', `The emphasis animation you apply doesn't support this effect. Change to another emphasis animation.`, 8000, 'TopCenterNotif');
        }

        if (effect === 'text-3d') {
          if (isAnimationNotByObject) return this.alertWarn('', `The animation type you apply doesn't support this effect. Change to animate by "object"`, 8000, 'TopCenterNotif');
          if (isEmphasisAnimationByLetter) return this.alertWarn('', `The emphasis animation you apply doesn't support this effect. Change to another emphasis animation.`, 8000, 'TopCenterNotif');
        }
      }

      if (this.isMediaEffectsType) {
        if (effect === 'light-sweep') {
          if (this.isAnimated) return this.alertWarn('', `Light Sweep Effect is not available on ${removedPluralText} elements.`, 5000, 'TopCenterNotif');
          if (this.isWebmElement) return this.alertWarn('', 'Light Sweep Effect is not available on WEBM videos.', 5000, 'TopCenterNotif');
        }

        if (effect === 'glitch') {
          if (this.elementType === 'videos') return this.alertWarn('', 'Glitch Effect is not available for videos.', 5000, 'TopCenterNotif');
          if (this.elementType !== subgroups.IMAGES) return this.alertWarn('', `Glitch Effect is not available for ${removedPluralText} elements.`, 5000, 'TopCenterNotif');
        }
      }
      /* eslint-enable */
    },
  },
};
</script>

<style lang="scss">
.edit-tab__effects-tab {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  max-height: 91%;
  overflow: auto;
  padding-right: 5px;

  @include smallest {
    max-height: 90%;
  }

  .effects-item-container {
    width: 50%;
    display: flex;
    padding: 4px;
    margin-top: 10px;
    position: relative;

    &:nth-child(1),
    &:nth-child(2) {
      margin-top: 0;
    }

    &.effects-container--disabled {
      opacity: 0.35;
      cursor: default;

      .effects-item__switch {
        pointer-events: none;
      }
    }

    &:not(.effects-container--disabled) {
      &:hover {
        .item-image {
          transition: filter 0.2s;
          filter: brightness(90%);
        }
      }
    }

    .effects-item__switch {
      position: absolute;
      right: 12px;
      top: 12px;
      z-index: 5;

      @include smallest {
        right: 8px;
        top: 8px;
      }

      input {
        &:checked + .slider {
          background-color: $slider;

          &:before {
            transform: translateX(9px);
          }
        }
      }

      .slider {
        width: 24px;
        height: 13px;
        background-color: $checkmarkBorder;

        &:before {
          height: 9px;
          width: 9px;
          left: 3px;
          bottom: 2px;
        }
      }
    }
  }

  .effects-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .item-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
  }

  .item-text {
    margin: 10px 0 0;
    font-weight: 400;
    font-size: 0.875rem;
    color: $light;
    text-align: center;

    @include smallest {
      font-size: 0.75rem;
    }
  }
}
</style>
