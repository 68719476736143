<template lang="pug">
.shortcut
  .tab__head
    BaseButton.shortcut-nav(
      v-for="item, itemKey in shortcuts"
      :key="itemKey"
      :class="{ 'nav--active': activeTab === itemKey }"
      type="button"
      :is-hollow="true"
      :plain-hover="true"
      @click="setActiveTab(itemKey);"
    )
      span {{ itemKey }}
  .tab__body
    .shortcut__item(
      v-for="item, itemKey in shortcuts[activeTab]"
    )
      span.name {{ itemKey }}
      span.key {{ item }}
</template>

<script>
// NOTE:
// Move layer backward forward in mac cannot use cmd [
// because they are reserved for changing tab and prev next page
export default {
  name: 'ShortcutContainer',
  data() {
    return {
      shortcuts: {
        Canvas: {
          'Play from start': 'Spacebar',
          'Playback from current': 'Ctrl/Cmd + Spacebar',
          Undo: 'Ctrl/Cmd + Z',
          Redo: 'Ctrl/Cmd + Shift + Z',
          Save: 'Ctrl/Cmd + S',
          'Zoom in': 'Ctrl/Cmd + +',
          'Zoom out': 'Ctrl/Cmd + -',
          'Zoom to fit': 'Ctrl/Cmd + 0',
          'Cancel selection': 'Esc',
          'Align left': 'Ctrl/Cmd + Shift + Left',
          'Align right': 'Ctrl/Cmd + Shift + Right',
          'Align top': 'Ctrl/Cmd + Shift + Top',
          'Align bottom': 'Ctrl/Cmd + Shift + Bottom',
          'Align horizontally': 'Ctrl/Cmd + Shift + ;',
          'Align vertically': "Ctrl/Cmd + Shift + '",
          'Select all objects': 'Ctrl/Cmd + Alt + A',
          'Select all texts': 'Ctrl/Cmd + Alt + T',
          'Add new text layer': 'Ctrl/Cmd + Shift + Alt + T',
          'Toggle grid': 'Ctrl/Cmd + Alt + G',
          'Preview/export': 'Ctrl/Cmd + Shift + P',
        },
        Layer: {
          Duplicate: 'Ctrl/Cmd + D',
          Delete: 'Backspace/Delete',
          'Edit text': 'Enter',
          'Bold text': 'Ctrl/Cmd + B',
          'Italic text': 'Ctrl/Cmd + I',
          'Toggle text alignment': 'Ctrl/Cmd + Shift + 1',
          'Move layer forward': 'Ctrl + ]',
          'Move layer to the front': 'Ctrl + Shift + ]',
          'Move layer backward': 'Ctrl + [',
          'Move layer to the back': 'Ctrl + Shift + [',
          'Group layers': 'Ctrl/Cmd + G',
        },
        Object: {
          'Move object 1px': 'Arrow Keys',
          'Move object 10px': 'Shift + Arrow Keys',
          'Move vertically/horizontally': 'Shift + Mouse',
          'Rotate clockwise': 'Ctrl/Cmd + Shift + ,',
          'Rotate counter-clockwise': 'Ctrl/Cmd + Shift + .',
        },
      },
      activeTab: 'Canvas',
    };
  },
  methods: {
    setActiveTab(value) {
      this.activeTab = value;
    },
  },
};
</script>

<style lang="scss">
.shortcut {
  height: 100%;

  .tab__body {
    padding: 0 10px 10px 0;
    overflow: auto;
    margin-right: -10px;
    margin-top: 20px;
    height: calc(100% - 52px);

    &::-webkit-scrollbar-track {
      background-color: rgba(230, 230, 230, 0);
      transition: background-color 0.5s ease-in-out;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($timelineGrey, 0);
      transition: background-color 0.5s ease-in-out;
    }

    &:hover::-webkit-scrollbar-track {
      background-color: rgba(230, 230, 230, 0.1);
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: $timelineGrey;
    }
  }

  .shortcut-nav {
    color: $lightGrey400;
    background: transparent;
    text-transform: capitalize;
    font-weight: 500;
    margin-right: 5px;
    width: 33%;
    padding: 10px 8px;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    &:last-of-type {
      margin-right: 0;
    }

    &:not(.nav--active) {
      color: $darkBlue5;
      border-color: $darkBlue5;
      font-weight: 400;

      &:hover {
        color: $light;
        border-color: $darkGrey5;
        background: $darkGrey5;
      }
    }

    .btn__circle {
      display: none;
    }
  }

  .shortcut__item {
    font-size: 0.875em;
    display: flex;
    justify-content: space-between;
    line-height: 1.4;
    margin: 15px 0;

    @include smallest {
      font-size: 0.75rem;
    }

    &:first-child {
      margin-top: 0;
    }

    .name {
      color: $lightGrey;
      font-weight: bold;
      margin-right: 5px;
    }

    .key {
      color: $lightGrey3;
      text-align: right;
    }
  }
}
</style>
