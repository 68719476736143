<template lang="pug">
.brand-tab-switch-team
  .brand-tab-switch-team__name(
    :class="{'is-loading': isRenameBrandLoading}"
  )
    BasePreloader.rename-brand-loader(
      v-if="isRenameBrandLoading"
      :type="12"
      :width="40"
    )
    div.selected-brand-name__container(@dblclick="editName")
      input.selected-brand-name(
        type="text"
        ref="name"
        :disabled="isInputDisabled"
        :value="activeBrandName"
        :class="{'name--ellipsis': isInputDisabled}"
        v-autowidth="{maxWidth: '180px', comfortZone: 0}"
        @keyup.enter="$event.target.blur()"
        @blur="confirmName"
      )
    button(
      v-if="!isTeamMember"
      type="button"
      title="Edit brand name"
      @click="editName"
    )
      i.icon.icon-edit

  div(v-click-outside="closeBrandsDropdownActive")
    BaseButton.btn-switch-brand(
      is-dark=true
      :is-active="isBrandsDropdownActive"
      @click="toggleBrandsDropdownActive"
    )
      i.icon.icon-switch-brand

    .brand-tab-switch-team__options(
      v-show="isBrandsDropdownActive"
    )
      button.brand-tab-switch-team__option.btn-add-new-brand(
        v-if="!isTeamMember"
        type="button"
        v-show="!isAddingNewBrandName"
        @click="openNewBrandNameInput"
      )
        i.icon.icon-add
        span Create new
      .brand-tab-switch-team__option.brand-tab-switch-team__new-name(
        v-show="isAddingNewBrandName"
      )
        input(
          ref="newbrand"
          type="text"
          placeholder="Enter your brand name"
          :value="newBrandName"
          @blur="cancelNewBrandName"
          @keyup.enter="confirmNewBrandName"
        )
      .brand-tab-switch-team__option.brand-tab-switch-team__option--team(
        type="button"
        v-for="brand in getTeamBrands"
        :class="{'option--disabled': isActiveTeamBrand(brand.id)}"
      )
        button.btn-change-team-brand(
          type="button"
          :title="`Switch to ${brand.brand_name}`"
          @click="changeActiveTeamBrandId(brand.id)"
        ) {{ brand.brand_name }}
        DeleteItem(
          v-if="!isActiveTeamBrand(brand.id) && !isTeamMember"
          :item-id="brand.id"
          :is-brand="true"
          title="Delete brand"
        )
        i.icon.icon-checked(
          v-if="isActiveTeamBrand(brand.id)"
        )
      .brand-tab-switch-team__option.brand-tab-switch-team__loader(
        v-if="isNewBrandLoading"
      )
        BasePreloader(:width="32")
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import vClickOutside from 'v-click-outside';
import helperMixins from '@/components/mixins/helper-mixins';

import DeleteItem from '@/components/ProjectCanvas/AssetsSidebar/children/DeleteItem.vue';

export default {
  name: 'BrandTabSwitchBrand',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [helperMixins],
  components: {
    DeleteItem,
  },
  props: {
    string: { type: String, default: '' },
    number: { type: Number, default: 0 },
    array: { type: Array, default: () => [] },
    object: { type: Object, default: () => ({}) },
    boolean: { type: Boolean, default: false },
  },
  data() {
    return {
      isInputDisabled: true,
      isBrandsDropdownActive: false,
      isAddingNewBrandName: false,
      newBrandName: '',
      isNewBrandLoading: false,
    };
  },
  computed: {
    ...mapState('team', ['teamId', 'teamBrandIndividualLoadingType']),
    ...mapGetters('canvasElements', ['getProjectBrandId']),
    ...mapGetters('team', ['getTeamBrands', 'getActiveTeamBrand', 'isTeamMember']),
    activeBrandName() {
      return this.getActiveTeamBrand.brand_name || 'unnamed';
    },
    isRenameBrandLoading() {
      return this.teamBrandIndividualLoadingType === 'rename-brand';
    },
  },
  methods: {
    ...mapActions('team', ['addTeamBrand', 'switchTeamBrand', 'renameTeamBrand']),
    ...mapActions('assetsSidebar', [
      'promptConfirmDeleteUserFile',
    ]),
    editName() {
      if (this.isTeamMember) return;

      this.isInputDisabled = false;
      setTimeout(() => {
        this.$refs.name.focus();
      }, 1);
    },
    isActiveTeamBrand(id) {
      return id === this.getProjectBrandId;
    },
    confirmName(event) {
      const newValue = event.target.value;
      const exceedBrandNameLimit = newValue.length > 40;
      this.isInputDisabled = true;

      if (exceedBrandNameLimit) this.alertWarn('', 'Max character for a brand name is 40, please update your brand name.', 5000, 'TopCenterNotif');
      if (!newValue || this.activeBrandName === newValue || exceedBrandNameLimit) return;

      this.renameTeamBrand({
        brandId: this.getProjectBrandId,
        newBrandName: newValue,
      });
    },
    changeActiveTeamBrandId(id) {
      this.$emit('switch-brand');
      this.switchTeamBrand(id);
      this.isBrandsDropdownActive = false;
    },
    toggleBrandsDropdownActive() {
      this.isBrandsDropdownActive = !this.isBrandsDropdownActive;
    },
    closeBrandsDropdownActive() {
      if (this.isAddingNewBrandName) {
        this.isAddingNewBrandName = false;
      } else if (this.isBrandsDropdownActive) {
        this.isBrandsDropdownActive = false;
      }
    },
    openNewBrandNameInput() {
      this.isAddingNewBrandName = true;

      this.$nextTick(() => {
        this.$refs.newbrand.focus();
      });
    },
    confirmNewBrandName(event) {
      const { value } = event.target;

      if (value.length > 40) {
        this.alertWarn('', 'Max character for a brand name is 40, please update your brand name.', 5000, 'TopCenterNotif');
      } else if (value) {
        // add brand
        this.addTeamBrand({
          teamId: this.teamId,
          brandName: value,
        });
        this.isNewBrandLoading = true;
      }

      setTimeout(() => {
        this.$refs.name.blur();
        this.closeNewBrandName();
      }, 1);
    },
    cancelNewBrandName() {
      // Set timeout so it gets triggered after v-click-outside runs
      setTimeout(() => {
        this.closeNewBrandName();
      }, 300);
    },
    closeNewBrandName() {
      this.isAddingNewBrandName = false;
      this.newBrandName = '';
    },
  },
  watch: {
    isBrandsDropdownActive(val) {
      if (!val) {
        this.closeNewBrandName();
      }
    },
    getTeamBrands() {
      this.isNewBrandLoading = false;
    },
  },
};
</script>

<style lang="scss">
.brand-tab-switch-team {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  color: $lightWhite;
  font-size: 0.875rem;
  margin-bottom: 10px;
  position: relative;
  z-index: 4; // to be on top of the category item

  .btn-switch-brand {
    padding: 3px;
    font-size: 1rem;

    &.is-active,
    &:hover {
      background: $darkGrey500;
    }
  }
}

.brand-tab-switch-team__name {
  position: relative;

  &.is-loading {
    input,
    button {
      display: none;
    }
  }

  .selected-brand-name__container {
    display: inline-block;
  }

  .selected-brand-name,
  button {
    background: transparent;
    color: $lightWhite;
  }

  .selected-brand-name {
    display: inline-block;
    outline: 0;
    padding: 2px 5px;
    border-bottom: 1px solid transparent;

    &:focus:not(:read-only) {
      border-color: $subBlue;
    }

    &.name--ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .rename-brand-loader {
    position: absolute;
    top: -2px;
    left: 15px;
  }
}

.brand-tab-switch-team__options {
  background: $darkGrey600;
  border-radius: $componentBorderRadius;
  left: 0;
  padding: 10px;
  position: absolute;
  flex-wrap: wrap;
  z-index: 3;
  top: 32px;
  width: 100%;
  box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08), 0px 3px 4px rgba(10, 31, 68, 0.1);
}

.brand-tab-switch-team__option {
  position: relative;
  color: #fff;
  padding: 13px 15px;
  width: 100%;
  text-align: left;
  background: transparent;

  i,
  span,
  .btn-change-team-brand,
  input {
    position: relative;
    z-index: 2;
  }

  &:before {
    content: '';
    display: block;
    background: $darkGrey400;
    top: -1px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 1px);
    opacity: 0;
    position: absolute;
    transition: opacity 0.3s cubic-bezier(0, 0, 0, 1);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0px;
    height: 1px;
    width: 100%;
    background: $darkGrey400;
  }

  &.btn-add-new-brand {
    padding-left: 10px;

    .icon {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    .btn-delete {
      opacity: 1;
      visibility: visible;
    }
  }

  &.option--disabled {
    pointer-events: none;
    cursor: default;

    &::before {
      opacity: 0;
    }
  }

  .icon-checked,
  .btn-delete {
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
    padding: 2px;
    width: 24px;
    height: 24px;
    font-size: 1rem;
  }

  .icon-checked {
    color: $green;
  }

  .btn-delete {
    opacity: 0;
    visibility: hidden;
    color: $salmon;
    z-index: 2;
  }

  &--team {
    padding: 0;
  }

  .btn-change-team-brand {
    padding: 13px 15px;
    display: block;
    width: calc(100% - 25px);
    text-align: left;
    color: $lightWhite;
  }
}

.brand-tab-switch-team__new-name {
  padding: 0;

  input {
    background: transparent;
    width: 100%;
    outline: 0;
    padding: 13px 15px;
    color: $lightWhite;

    &::placeholder {
      color: $lightGrey800;
    }
  }
}

.brand-tab-switch-team__loader {
  padding: 0;
  height: 42px;
}
</style>
