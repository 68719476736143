<template lang="pug">
  div.search-color__result-list(v-if="showSearchResultList")
    template(v-if="!showLoader")
      .palette-item(v-for="(palette, index) in colorPalettes" :key="palette.id")
        .palette-list-header
          .palette-name
            .overflow-name {{ shortName(palette.palette_name) }}
            //- only show full name if the name is not shown fully / longer than 13
            .full-name(
              v-if="shortName(palette.palette_name) !== palette.palette_name"
            ) {{ palette.palette_name }}
          .palette-actions-container
            .actions
              button.add(
                type="button"
                @click="$emit('do-add-palette', index)"
              )
                i.icon.icon-add
                span Add Palette
              button.delete(
                title="Delete palette"
                type="button"
                @click="$emit('do-remove-palette',index)"
              )
                i.icon.icon-delete
        .colors
          template(v-for="(color, index_color) in palette.colors")
            ColorButton(
              :color="`#${color}`"
              isPaletteColor=true
            )

    template(v-else)
      BasePreloader
</template>

<script>
import ColorButton from './ColorButton.vue';

export default {
  name: 'SearchColorResultList',
  components: {
    ColorButton,
  },
  props: {
    showSearchResultList: { type: Boolean, default: false },
    showLoader: { type: Boolean, default: false },
    colorPalettes: { type: Array, default: () => [] },
  },
  methods: {
    shortName(name) {
      return name.length <= 15 ? name : `${name.substring(0, 15)}...`;
    },
  },
};
</script>

<style lang="scss">
.search-color__result-list {
  .palette-item {
    margin: 10px 0;

    &:hover {
      .palette-list-header {
        .palette-actions-container {
          opacity: 1;
        }
      }
    }

    .palette-list-header {
      position: relative;
      margin-bottom: 5px;
      color: $lightWhite;

      .palette-name {
        font-size: 0.75rem;
        position: relative;
        height: 16px;
        width: 125px;
        font-weight: 400;

        &:hover {
          .full-name {
            opacity: 1;
          }
        }

        .overflow-name {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          font-weight: 600;
        }

        .full-name {
          position: absolute;
          top: -5px;
          left: -5px;
          z-index: 2;
          background-color: #fff;
          border-radius: $componentBorderRadius;
          font-weight: 600;
          color: $darkGrey800;
          box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.1), 0px 18px 18px rgba(10, 31, 68, 0.12);
          padding: 5px;
          opacity: 0;
          white-space: pre;
          transition: 0.3s;
          cursor: auto;
        }
      }

      .palette-actions-container {
        position: absolute;
        bottom: 2px;
        right: 0;
        opacity: 0;
        transition: 0.3s;
        color: $lightGrey700;

        .actions {
          display: flex;
          justify-content: flex-end;

          .add {
            font-size: 0.6667rem;

            .icon {
              margin-right: 3px;
            }
          }

          .delete {
            margin-left: 5px;
          }

          .add,
          .delete {
            background: transparent;
            color: $lightGrey700;
            transition: color 0.3s ease-in-out;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0;
            font-size: 0.6667rem;
            line-height: 1;

            .icon {
              font-size: 0.875rem;
            }

            &:hover {
              color: $lightGrey500;
            }
          }
        }
      }
    }

    .colors {
      display: flex;
      align-items: center;

      .item {
        width: 30px;
        height: 30px;
        margin-right: 5px;
        margin-bottom: 5px;
        position: relative;
        cursor: pointer;

        &:hover {
          .add-to-palette {
            opacity: 1;
          }
        }

        .add-to-palette {
          position: absolute;
          top: -3px;
          right: -3px;
          z-index: 1;
          font-size: 8px;
          color: #fff;
          background-color: $darkGrey;
          opacity: 0;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          transition: 0.3s;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
