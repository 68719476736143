<template lang="pug">
.effects-tab
  button.effects__back-button.icon-to-left(
    type="button"
    @click="$emit('reset-active-effect-group')"
  )

  template(v-if="activeEffectGroup === 'drop-shadow'")
    MediaShadow(@open-colorpicker="openColorPicker()")

  template(v-if="activeEffectGroup === 'blur'")
    MediaBlur

  template(v-if="activeEffectGroup === 'reflection'")
    ReflectionControls

  template(v-if="activeEffectGroup === 'ground-shadow'")
    GroundShadowControls(@open-colorpicker="openColorPicker()")

  template(v-if="activeEffectGroup === 'glow'")
    GlowControls(
      @open-colorpicker="openColorPicker()"
    )

  template(v-if="activeEffectGroup === 'light-sweep'")
    LightSweepControls(
      @open-colorpicker="openColorPicker()"
    )

  template(v-if="activeEffectGroup === 'glitch'")
    GlitchControls

  template(v-if="activeEffectGroup === 'round-corners'")
    RoundCornersControls

  template(v-if="activeEffectGroup === 'border'")
    BorderControls(
      @open-colorpicker="openColorPicker()"
    )

  //- cannot use v-if on colorpicker so that it can save the color
  ColorPicker(
    :color="elementColor()"
    :elementId="elementId"
    @close="closeColorPicker"
  )
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';

import ColorPicker from './children/ColorPicker.vue';
import MediaShadow from './children/MediaShadow.vue';
import MediaBlur from './children/MediaBlur.vue';
import ReflectionControls from './children/ReflectionControls.vue';
import GroundShadowControls from './children/GroundShadowControls.vue';
import GlowControls from './children/GlowControls.vue';
import LightSweepControls from './children/LightSweepControls.vue';
import GlitchControls from './children/GlitchControls.vue';
import RoundCornersControls from './children/RoundCornersControls.vue';
import BorderControls from './children/BorderControls.vue';

export default {
  name: 'MediaEffects',
  components: {
    GroundShadowControls,
    ColorPicker,
    MediaShadow,
    MediaBlur,
    ReflectionControls,
    GlowControls,
    LightSweepControls,
    GlitchControls,
    RoundCornersControls,
    BorderControls,
  },
  props: {
    activeEffectGroup: { type: String, default: '' },
  },
  data() {
    return {
      elementId: '',
    };
  },
  computed: {
    ...mapState(['showColorPicker']),
    ...mapGetters('canvasElements', ['getActiveElements']),
    isAnActiveElement() {
      return this.getActiveElements.length && this.getActiveElements.length === 1;
    },
  },
  methods: {
    ...mapMutations(['setShowColorPicker']),
    ...mapMutations('canvasElements', ['updateActiveColorId']),
    convertToObject(item) {
      if (typeof item === 'string') {
        return { color: item };
      }
      return item;
    },
    closeColorPicker() {
      console.log('closecolorpicker');
      this.setShowColorPicker(false);
    },
    openColorPicker() {
      this.updateActiveColorId('effects');

      this.elementId = this.getActiveElements[0].data.id;
      console.log('showColorPicker', this.elementId, this.showColorPicker);
      this.setShowColorPicker(true);
    },
    elementColor() {
      let color = '#000000';
      if (
        this.isAnActiveElement
        && this.getActiveElements[0].data.color
        && this.getActiveElements[0].data.color[0]
      ) {
        // eslint-disable-next-line
        color = this.getActiveElements[0].data.color[0].color;
      }
      return color;
    },
  },
};
</script>

<style lang="scss"></style>
