<template lang="pug">
.user-menu__profile-picture
  img(
    :src="profilePicture"
    v-if="hasProfilePicture"
    @error="imageLoadError"
  )
  .user-menu__initial(
    v-if="!hasProfilePicture"
  ) {{ getInitial }}
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'UserMenuProfilePicture',
  data() {
    return {
      hasProfilePicture: false, // TODO: the files from offeo server files didn't migrated yet to buckets, disabled this profile first to show initials.
    };
  },
  computed: {
    ...mapState('userData', ['profilePicture']),
    ...mapGetters('userData', ['getInitial']),
  },
  methods: {
    imageLoadError() {
      this.hasProfilePicture = false;
    },
  },
};
</script>

<style scoped lang="scss">
.user-menu__profile-picture {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  overflow: hidden;
  background: linear-gradient(180deg, #1285ff 0%, #20d1ff 100%);
  // background: linear-gradient(133.15deg, #FF5980 1.46%, #FFA882 98.22%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.user-menu__initial {
  color: $light;
  font-size: 0.75em;
  text-transform: uppercase;
  margin-top: 2px;
}
</style>
