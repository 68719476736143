<template lang="pug">
.multiple-swap
  CanvasModal(
    icon="icon-replace"
    title="Replace All Images"
    :isShow="isShow"
    @closeOverlay="closeOverlay"
    :noOverlay="true"
  )

    .multiple-swap__header
      h1.text Scene :
      BaseButton.icon-left-arrow.btn-scene-paginate(
        v-if="hasPrevPage"
        title="Go to previous page"
        :is-icon="true"
        :plain-hover="true"
        @click="updatePage('prev')"
      )
      template(v-for="(scene, index) in scenesList")
        BaseButton.btn-scene(
          :class="{ 'is-active': activeSceneId === scene.id, 'is-dark': isDarkMode }"
          :is-text="true"
          :is-short="true"
          :plain-hover="true"
          type="button"
          padding="8px"
          @click="changeActiveSceneId(scene.id)"
        ) {{ getSceneNumber(index) }}
      BaseButton.icon-right-arrow.btn-scene-paginate(
        v-if="hasNextPage"
        title="Go to next page"
        :is-icon="true"
        :plain-hover="true"
        @click="updatePage('next')"
      )

    .multiple-swap__scenes
      template(v-for="(scene, index) in getScenes")
        SwapScene(
          :class="{ 'is-active': activeSceneId === scene.id }"
          :scene="scene"
          :index="getScenesIndexById(scene.id)"
          :is-active="activeSceneId === scene.id"
          :is-first="index === 0"
        )

    .multiple-swap__button
      BaseButton.btn-confirm-swap(
        :is-primary="true"
        :is-tall="true"
        :is-canvas="true"
        padding="14px 65px"
        @click="confirmSwap"
      ) Done

</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import SwapScene from '@/components/ProjectCanvas/PageOverlay/children/SwapScene.vue';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'MultipleSwap',
  components: {
    SwapScene,
  },
  mixins: [darkModeMixin],
  data() {
    return {
      isShow: false,
      isFirst: true,
      activeSceneId: '0',
      scenesWithImageOrMask: [],
      activePage: 1,
      maxScenePerPage: 10,
    };
  },
  computed: {
    ...mapState(['showMultipleSwap']),
    ...mapGetters('canvasElements', [
      'getScenes',
      'getCanvasElementById',
      'getScenesIndexById',
      'getActiveSceneId',
      'getSceneById',
    ]),
    activeSceneIndex() {
      return this.getScenes.findIndex(scene => scene.id === this.activeSceneId);
    },
    currentScenesWithImageOrMaskIndex() {
      return this.scenesWithImageOrMask.findIndex(scene => scene.id === this.activeSceneId);
    },
    currentSceneImageOrMasksElements() {
      const elements = [];
      // eslint-disable-next-line
      for (let i = 0; i < this.getSceneById(this.activeSceneId).elements.length; i++) {
        const id = this.getSceneById(this.activeSceneId).elements[i];
        if (this.isImage(id) || this.isMask(id)) {
          elements.push(id);
        }
      }
      return elements;
    },
    totalScenePages() {
      if (this.getScenes.length > this.maxScenePerPage) {
        return this.getScenes.length / this.maxScenePerPage;
      }
      return 1;
    },
    hasPrevPage() {
      return this.getScenes.length > this.maxScenePerPage && this.activePage !== 1;
    },
    hasNextPage() {
      return this.getScenes.length > this.maxScenePerPage && this.activePage < this.totalScenePages;
    },
    scenesList() {
      if (this.getScenes.length > this.maxScenePerPage) {
        let startDeleteParams = this.maxScenePerPage;
        let scenes = this.activePage * this.maxScenePerPage;

        // Validate for first scene page
        if (this.activePage === 1) startDeleteParams = 0;

        // Validate for last scene page
        if (scenes > this.getScenes.length) {
          startDeleteParams = -(this.getScenes.length % this.maxScenePerPage);
          scenes = this.getScenes.length;
        }
        return this.getScenes.slice(startDeleteParams, scenes);
      }
      return this.getScenes;
    },
  },
  methods: {
    ...mapMutations(['setShowMultipleSwap', 'setShowSidebar']),
    ...mapMutations('assetsSidebar', ['setActiveGroup', 'setActiveSubGroup']),
    ...mapMutations('canvasElements', [
      'updateMultipleSwappedElements',
      'confirmMultipleSwappedElements',
    ]),
    closeOverlay() {
      this.setShowMultipleSwap(false);
    },
    confirmSwap() {
      this.confirmMultipleSwappedElements();
      this.setShowMultipleSwap(false);
    },
    getSceneNumber(index) {
      let number = index + 1;
      if (this.activePage > 1) {
        number = this.maxScenePerPage * (this.activePage - 1) + index + 1;
      }
      return number;
    },
    isImage(id) {
      // don't include graphics elements
      return this.getCanvasElementById(id).type === 'images';
    },
    isMask(id) {
      return this.getCanvasElementById(id).type === 'masks';
    },
    changeActiveSceneId(id) {
      this.activeSceneId = id;
    },
    setupMultipleSwappedElements() {
      // state.multipleSwappedElement is needed as temporary holder before user confirm the swap
      const elements = {};

      // eslint-disable-next-line
      for (let i = 0; i < this.getScenes.length; i++) {
        const scene = this.getScenes[i];

        // eslint-disable-next-line
        for (let j = 0; j < scene.elements.length; j++) {
          const elementId = scene.elements[j];

          if (this.isImage(elementId)) {
            elements[elementId] = {
              url: this.getCanvasElementById(elementId).data.url,
              urlHd: this.getCanvasElementById(elementId).data.urlHd,
              thumb: this.getCanvasElementById(elementId).data.thumb,
            };
          }

          if (this.isMask(elementId)) {
            elements[elementId] = {
              url: this.getCanvasElementById(elementId).data.image.url,
            };
          }
        }
      }

      this.updateMultipleSwappedElements(elements);
    },
    updatePage(type) {
      let page = 1;
      if (type === 'prev') {
        page = this.activePage - 1;
      } else {
        page = this.activePage + 1;
      }
      this.activePage = page;
    },
  },
  beforeMount() {
    this.activeSceneId = this.getActiveSceneId;
    this.setActiveGroup('media');
    this.setActiveSubGroup('photo');
    this.setShowSidebar(true);
  },
  mounted() {
    if (this.showMultipleSwap) this.isShow = true;
    this.setupMultipleSwappedElements();
  },
};
</script>

<style lang="scss">
.multiple-swap {
  .no-overlay {
    left: 336px;
  }

  .canvas-modal__content {
    height: auto;
    width: 90%;
    background: var(--canvasbg-darkgrey700);
  }

  .canvas-modal__header {
    padding: 30px 0 0 40px;
    color: var(--black-white) !important;
    background: var(--canvasbg-darkgrey700) !important;

    .icon-replace {
      color: var(--black-white) !important;
    }
  }

  .canvas-modal__closebtn {
    top: 32px;
    color: var(--black-white) !important;

    @include smallest {
      top: 32px;
    }
  }

  .content {
    padding: 0 40px;
  }

  .multiple-swap__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 30px 0 0;
    height: 40px;

    .text {
      font-size: 1.2em;
      font-weight: 600;
      margin-right: 5px;
      color: var(--black-white);
    }

    .btn-scene {
      width: 40px;
      height: 40px;
      margin: 0 4px;
      font-size: 18px;
      color: $darkGrey100;

      &.is-dark {
        color: rgba($lightGrey400, 0.2);
      }

      &:hover,
      &:active,
      &.is-active {
        font-weight: bold;
        border-radius: 50%;
        color: $light;
        background: var(--blue700-green700);
      }
    }

    .btn-scene-paginate {
      color: var(--black-white);
      font-size: 22px;
    }
  }

  .multiple-swap__scenes {
    height: 400px;
    position: relative;
    overflow: hidden;
    margin: 20px 0;
  }

  .btn-confirm-swap {
    margin-bottom: 20px;
  }
}
</style>
