<template lang="pug">
.bottom-bar(
  ref="bottomBar"
  :class="{ 'has-more-options': showBottomBarMoreOptions, 'lite-mode': isLiteMode }"
)
  .bottom-bar-collapse-container(
    v-show="showTimeline"
  )
    button.btn-collapse-bottom-bar(
      type="button"
      @mousedown="initResize"
      @mouseup="stopResize"
    )
      .horizontal-line1
      .horizontal-line2
    button.btn-toggle-height-bottom-bar(
      type="button"
      @click="toggleTimelineHeight"
    )
      i.icon(
        :class="toggleIcon"
      )
  .bottom-bar__container(
    ref="bottomBarContainer"
  )
    TimelineController(
      @update-time="updateTime"
    )
    .right(v-if="!isLiteMode && !isMobile")
      .max-duration__tooltip(
        :class="{ 'is-show': isMaxSceneDuration ,'is-timeline-open': showTimeline }"
      )
        BaseButton.icon-cancel.tooltip-close__button(
          :is-icon="true"
          :plain-hover="true"
          @click="isMaxSceneDuration = false"
        )
        .tooltip-text
          span Max duration per scene is
          strong 30s
          i.icon.icon-info-line
      ProjectSceneDurationInput(
        :showStepper="false"
        :showText="true"
        @show-max-duration-tooltip="setShowMaxDurationTooltip"
      )

      template(v-if="!isLiteMode && !isMobile")
        CanvasZoom(
          :is-show-more-options="isShowMoreOptions"
          @set-show-canvas-zoom="setShowCanvasZoom"
        )
        .more-options__container
          button.bottom-bar__button.btn-more-options.js-more-options(
            type="button"
            data-title="More Options"
            ref="optionsButton"
            @click="showMoreOptions(false)"
            @mouseenter="showMoreOptions(false)"
            @mouseleave="hideMoreOptions(false)"
          )
            i.icon.icon-options

          .bottom-bar__more-options__list(
            :class="{ 'is-show': isShowMoreOptions }"
            ref="optionsList"
            @mouseenter="showMoreOptions(true)"
            @mouseleave="hideMoreOptions(true)"
          )
            button.bottom-bar__more-options__button.btn-grid.js-grid(
              type="button"
              :class="{ 'is-active': showGrid }"
              @click="setShowGrid"
            )
              i.icon.icon-grid
              | Toggle Grid
            button.bottom-bar__more-options__button.btn-title-safe(
              type="button"
              :class="{ 'is-active': showTitleSafe }"
              @click="toggleTitleSafe"
            )
              i.icon.icon-safe-guide
              | Toggle Title Safe
            button.bottom-bar__more-options__button.btn-zoom-fit(
              type="button"
              @click="fitToScreen"
            )
              i.icon.icon-fit-to-screen
              | Fit To Screen

        BaseButton.btn-toggle-timeline(
          :class="{ 'is-dark': isDarkMode }"
          @click="toggleIsTimelineOpened"
          :is-hollow="true"
          :is-canvas="true"
          :is-dark="true"
        ) {{ timelineText }}
  GlobalEvents(
    v-if="isLiteMode"
    @keydown.ctrl.alt.71.exact.prevent="toggleGrid"
    @keydown.meta.alt.71.exact.prevent="toggleGridMac"
  )
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import vClickOutside from 'v-click-outside';
import { debounce } from 'debounce';
import { isMac, isMobile } from '@/assets/scripts/utilities';
import CanvasZoom from '@/components/ProjectCanvas/CanvasZoom/CanvasZoom.vue';
import TimelineController from '@/components/ProjectCanvas/TimelineController/TimelineController.vue';
import ProjectSceneDurationInput from '@/components/ProjectCanvas/ProjectDetails/children/ProjectSceneDurationInput.vue';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'BottomBar',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    TimelineController,
    ProjectSceneDurationInput,
    CanvasZoom,
  },
  props: {
    showMaxDurationTooltip: { type: Boolean, default: false },
  },
  mixins: [darkModeMixin],
  data() {
    return {
      isShowMoreOptions: false,
      isShowPersist: false,
      windowInnerWidth: 1366,
      windowInnerHeight: 768,
      showManageScenes: false,
      oldY: 0,
      timelineHeight: 0,
      previouslyOpened: {
        showSidebar: false,
        showLayer: false,
      },
      isMobile: isMobile(),
      isMaxSceneDuration: false,
      isShowCanvasZoom: false,
    };
  },
  computed: {
    ...mapState(['showGrid', 'showTitleSafe', 'showTimeline', 'showLayer', 'showSidebar']),
    ...mapGetters(['isLiteMode']),
    ...mapGetters('canvasElements', ['getCanvasElements']),
    ...mapGetters('canvasLayouts', ['isCanvasSectionPaddingBottomUpdated', 'rightSidebarWidth']),
    scenesManageText() {
      return this.isLiteMode
        ? 'Need a longer video?'
        : 'Creating a longer video? Add more scenes in your storyboard.';
    },
    showBottomBarMoreOptions() {
      return this.windowInnerWidth <= 1350;
    },
    maxTimelineHeight() {
      return 0.6 * window.innerHeight;
    },
    halfTimelineHeight() {
      return 0.5 * window.innerHeight;
    },
    shortTimelineHeight() {
      return 0.25 * window.innerHeight;
    },
    timelineHeightLessThanHalf() {
      return this.timelineHeight < this.halfTimelineHeight;
    },
    toggleIcon() {
      return this.timelineHeightLessThanHalf ? 'icon-up-arrow' : 'icon-down-arrow';
    },
    timelineText() {
      if (this.showTimeline) return 'Close Timeline';
      return 'Timeline';
    },
  },
  methods: {
    ...mapMutations([
      'setShowGrid',
      'setShowTitleSafe',
      'setShowTimeline',
      'setShowLayer',
      'setShowSidebar',
    ]),
    ...mapMutations('canvasLayouts', [
      'setBottomBarHeight',
      'setCanvasSectionPaddingBottom',
      'setCanvasSectionPaddingRight',
    ]),
    ...mapMutations('canvasElements', ['emptyActiveElements']),
    ...mapActions(['updateShowStoryboardOverlay']),
    toggleIsTimelineOpened() {
      this.setShowTimeline(!this.showTimeline);

      console.log('this.showTimeline', this.showTimeline);

      // when timeline is opened, hide left right sidebar
      if (this.showTimeline) {
        this.previouslyOpened.showLayer = this.showLayer;
        this.previouslyOpened.showSidebar = this.showSidebar;
        this.setCanvasSectionPaddingRight(0);
        this.setShowLayer(false);
        this.setShowSidebar(false);
      } else {
        this.setShowLayer(this.previouslyOpened.showLayer);
        this.setShowSidebar({ boolean: this.previouslyOpened.showSidebar, maintainOverrideBackgroundColorTabState: true });

        if (this.previouslyOpened.showLayer) {
          this.setCanvasSectionPaddingRight(this.rightSidebarWidth);
        }
      }

      this.$nextTick(() => {
        this.resizeToShortHeight();
      });
    },
    toggleGrid() {
      if (isMac()) return;
      this.setShowGrid();
    },
    toggleGridMac() {
      if (!isMac()) return;
      this.setShowGrid();
    },
    toggleTitleSafe() {
      this.setShowTitleSafe(!this.showTitleSafe);
    },
    showMoreOptions(persist) {
      if (persist) this.isShowPersist = true;
      else this.isShowMoreOptions = true;
    },
    // eslint-disable-next-line func-names
    hideMoreOptions: debounce(function (removePersist) {
      if (removePersist) this.isShowPersist = false;
      else if (this.isShowPersist) return;

      this.isShowMoreOptions = false;
    }, 300),
    updateCanvasSectionHeight() {
      if (!this.isCanvasSectionPaddingBottomUpdated) {
        this.setCanvasSectionPaddingBottom(0);
      }
    },
    updateTime(val) {
      this.$emit('update-time', val);
    },
    setShowMaxDurationTooltip() {
      this.isMaxSceneDuration = true;
    },
    onClickOutsideScenesManage(e) {
      if (e.target !== this.$refs.scenesManage) {
        this.showManageScenes = false;
        this.$emit('hide-max-duration-tooltip', {});
      }
    },
    showStoryboard() {
      this.updateShowStoryboardOverlay(true);
      this.emptyActiveElements();
    },
    fitToScreen() {
      this.$root.$emit('zoom-fit-to-screen');
    },
    initResize() {
      window.addEventListener('mousemove', this.resize, false);
      window.addEventListener('mouseup', this.stopResize, false);
    },
    resize(e) {
      const resizedHeight = this.windowInnerHeight - e.clientY - 58;
      const newHeight = Math.min(resizedHeight, this.maxTimelineHeight);

      const timelineContent = document.getElementById('timeline-content');
      timelineContent.style.height = `${newHeight}px`;

      this.updateTimelineHeightDebounced();
    },
    stopResize() {
      window.removeEventListener('mousemove', this.resize, false);
      window.removeEventListener('mouseup', this.stopResize, false);
    },
    // eslint-disable-next-line func-names
    draggingBottomBarDebounced: debounce(function () {
      this.$emit('dragging-bottom-bar');
    }),
    // eslint-disable-next-line func-names
    updateTimelineSizeDebounced: debounce(function () {
      this.$root.$emit('timeline-size-update');
    }),
    resizeToShortHeight() {
      const timelineContent = document.getElementById('timeline-content');
      timelineContent.style.height = `${this.shortTimelineHeight}px`;
      setTimeout(() => {
        this.updateTimelineHeight();
      }, 300);
    },
    resizeToTallHeight() {
      const timelineContent = document.getElementById('timeline-content');

      // sometimes the height is reduced by 0.5 so need to add 1px to ensure it will be bigger than the half timeline height
      timelineContent.style.height = `${this.halfTimelineHeight + 1}px`;
      setTimeout(() => {
        this.updateTimelineHeight();
      }, 300);
    },
    toggleTimelineHeight() {
      if (this.timelineHeightLessThanHalf) {
        this.resizeToTallHeight();
      } else {
        this.resizeToShortHeight();
      }
    },
    updateTimelineHeight() {
      const timelineContent = document.getElementById('timeline-content');
      this.timelineHeight = timelineContent.offsetHeight;
      console.log('updateTimelineHeight', timelineContent.offsetHeight);
      this.draggingBottomBarDebounced();
      this.updateTimelineSizeDebounced();
    },
    // eslint-disable-next-line func-names
    updateTimelineHeightDebounced: debounce(function () {
      this.updateTimelineHeight();
    }),
    setShowCanvasZoom(value) {
      this.isShowCanvasZoom = value;
    },
  },
  watch: {
    showMaxDurationTooltip(val) {
      if (val) {
        this.setShowMaxDurationTooltip();
      }
    },
    isMaxSceneDuration: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.isMaxSceneDuration = false;
          }, 2000);
        }
      },
      immediate: true,
    },
    isShowCanvasZoom: {
      handler(val) {
        if (val) this.isShowMoreOptions = false;
      },
      immediate: true,
    },
  },
  mounted() {
    this.windowInnerWidth = window.innerWidth;
    this.windowInnerHeight = window.innerHeight;
    window.addEventListener(
      'resize',
      debounce(() => {
        this.windowInnerWidth = window.innerWidth;
        this.windowInnerHeight = window.innerHeight;
      }, 300),
    );
  },
};
</script>

<style lang="scss">
.bottom-bar {
  width: calc(100vw - 65px);
  background: var(--lightgrey100-darkgrey600);
  cursor: context-menu;
  height: 46px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  transition: height 0.35s ease-in-out;
  padding-left: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1.5px solid var(--lightgrey400-darkgrey500);

  @include smallest {
    padding-left: 260px;
  }

  @include fb-requirement {
    padding-left: 240px;
  }

  &.lite-mode {
    background: none !important;
  }

  [data-title]:after {
    left: 0px;
    top: -30px;
    bottom: 0;
    pointer-events: none;
  }

  .timeline.is-collapsed & {
    padding-bottom: 10px;
    height: 52px;
  }

  .bottom-bar__container {
    width: 100%;
    height: 100%;
    padding: 0 15px;
    margin: auto;
    display: flex;
    align-content: center;
    justify-content: space-between;

    @include fb-requirement {
      padding-left: 5px;
    }

    // add space for freshchat
    .lite-mode & {
      padding-right: 90px;
    }
  }

  .timeline.is-collapsed & {
    .bottom-bar__container {
      padding-top: 10px;
    }
  }

  .main-sidebar-is-hidden &,
  .collapse-all & {
    padding-left: 0;
  }

  .duration-stepper {
    display: inline-block;
  }

  .right {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 320px;
    margin-left: 2%;
    justify-content: flex-start;

    @include fb-requirement {
      display: none;
    }
  }

  .duration-stepper {
    margin-right: 10px;
  }

  .more-options__container {
    position: relative;
  }

  .btn-more-options {
    position: relative;
    width: 25px;
    height: 25px;
    font-size: 26px;
    padding: 0;

    i {
      pointer-events: none;
    }

    .icon-options {
      color: var(--black-white);
    }
  }

  .bottom-bar__more-options__list {
    position: absolute;
    width: 220px;
    transition: transform 0.15s ease-in-out;
    top: -16px;
    left: -95px;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -90%);
    padding: 5px 20px;
    background: var(--lightgrey100-darkgrey600);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    &.is-show {
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, -100%);
    }

    .bottom-bar__more-options__button {
      border: 0;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 15px;
      text-align: left;
      background: transparent;
      cursor: pointer;
      opacity: 1;
      position: relative;
      z-index: 2;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 500;
      color: var(--black-white);

      .icon {
        font-size: 1.25em;
        display: inline-block;
        margin-right: 10px;
        margin-top: 3px;
      }

      &:hover {
        color: var(--blue700-green700);
      }

      &:last-child {
        border-bottom: 0;
      }

      &.is-selected::before {
        opacity: 1;
      }
    }

    .bottom-bar__more-options__toggle-timeline {
      // width: 100%;
      margin: 10px 0;
    }
  }

  .btn-toggle-timeline {
    max-height: 32px;
    margin-left: 5px;
    padding: 10px 30px;
    font-size: 12.5px;
    color: $light;
    background: $darkGrey600;
    border: none;

    &.is-dark {
      border: 1px solid $light;
      color: $light;
      background: none;

      &:hover {
        background: $light;
        border: none;
      }
    }

    &:hover {
      border: 1px solid $darkGrey900;
      color: $black;
    }
  }
}

.bottom-bar__scenes-manage__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: $lightGrey100;

  .lite-mode & {
    color: $darkGrey900;
  }
}

.bottom-bar__scenes-manage__button {
  font-weight: 500;
  font-size: 13px;
  text-align: right;
  color: $lightGrey100;
  margin: 1em 0px;
  float: right;
  text-transform: uppercase;

  .lite-mode & {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    border-radius: $componentBorderRadius;
    font-family: $baseFont;
    font-weight: 500;
    font-size: 0.75em;
    transition: color 0.3s cubic-bezier(0, 0, 0, 1), background 0.3s cubic-bezier(0, 0, 0, 1);
    cursor: pointer;
    outline: 0;
    border: 0;
    line-height: 1.2;
    text-decoration: none;
    text-transform: none;
    margin-top: 0;
    float: none;
    background: $btnPrimaryBackground;
    color: $light;

    &:focus {
      background: $btnPrimaryBackground;
    }

    &:active,
    &.is-active,
    &.is-selected,
    &:hover {
      background: $btnPrimaryHover;
    }
  }
}

.bottom-bar__button {
  background: transparent;
  width: 28px;
  height: 28px;
  padding: 4px;
  font-size: 18px;
}

.bottom-bar-collapse-container {
  top: -27px;
  display: flex;
  position: absolute;
}

.btn-collapse-bottom-bar {
  position: static;
  background: var(--white-darkgrey600);
  border: 1px solid var(--lightgrey400-darkergrey100);
  border-radius: 4px 0 0 0;
  width: 100px;
  height: 25px;
  padding: 0;
  cursor: grabbing;
  transition: background-color 0.3s cubic-bezier(0, 0, 0, 1);

  &.active::before {
    outline: none;
  }

  &:focus {
    background: var(--white-darkgrey600) !important;
  }

  &:hover {
    background: $lightGrey300;
  }

  .horizontal-line1,
  .horizontal-line2 {
    width: 40px;
    height: 2px;
    background: $darkGrey100;
    border-radius: 2px;
    overflow: hidden;
    margin: 3px auto 0px auto;
  }

  .horizontal-line1 {
    margin-top: 4px;
  }

  .horizontal-line2 {
    margin-bottom: 4px;
  }
}

.btn-toggle-height-bottom-bar {
  color: $darkGrey100;
  background: var(--white-darkgrey600);
  height: 25px;
  width: 32px;
  padding: 0;
  border-radius: 0 4px 0 0;
  border: 1px solid var(--lightgrey400-darkergrey100);
  transition: background-color 0.3s cubic-bezier(0, 0, 0, 1);

  &:focus {
    background: var(--white-darkgrey600);
  }

  &:hover {
    background: $lightGrey300;
  }

  .icon {
    font-size: 20px;
  }
}

.max-duration__tooltip {
  position: absolute;
  top: -59px;
  left: -116px;
  display: flex;
  color: $light;
  background: $orange700;
  padding: 14px 30px;
  border-radius: 100px;
  font-weight: 400;
  z-index: 1;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;

  &.is-show {
    opacity: 1;
    visibility: visible;
  }

  &.is-timeline-open {
    top: -49px;
  }

  .tooltip-close__button {
    position: absolute;
    right: 0;
    top: -13px;
    background: $darkGrey4;
    color: $light;
    padding: 7px;
    border-radius: 50%;
    transition: 0.3s;

    &:hover {
      background: $lightGrey300;
    }
  }

  .tooltip-text {
    display: flex;
    align-items: center;

    span,
    strong {
      margin-right: 5px;
    }

    .icon-info-line {
      margin-left: 5px;
      font-size: 20px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    border-width: 8px 7px 0;
    border-style: solid;
    border-color: $orange700 transparent transparent;
    bottom: -8px;
    left: 47%;
  }
}
</style>
