<template lang="pug">
.element-ground-shadow.effect
  BaseSwitch.element-ground-shadow__switch.effect__switch(
    name="element-ground-shadow"
    :isChecked="isEnabled('ground-shadow')"
    @change="toggleEnable('ground-shadow')"
    label="Ground Shadow"
  )
  VueSlideUpDown.element-ground-shadow__content.effect__content(
    :class="{'effect-content--enabled': isEnabled('ground-shadow')}"
    :active="true"
  )
    .element-ground-shadow__color.effect__color
      span.title Colour
      button.color.btn-add-color(
        :class="{ 'is-active': isGroundShadow }"
        type="button"
        :style="colorStyle"
        @click="openColorPicker"
      )

    //- Distance value 1 per 2px
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="100"
      title="Distance"
      :value="y"
      @blur="onBlurInput($event, 'y')"
      @update="updateY"
    )
    //- ScaleX value 1% per 3px
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="100"
      title="Width"
      :value="scaleX"
      @blur="onBlurInput($event, 'scaleX')"
      @update="updateScaleX"
    )
    //- ScaleY value 1% per 6px
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="100"
      title="Height"
      :value="scaleY"
      @blur="onBlurInput($event, 'scaleY')"
      @update="updateScaleY"
    )
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="15"
      title="Blur"
      :value="blur"
      @blur="onBlurInput($event, 'blur')"
      @update="updateBlur"
    )
    //- Transparency 100 = Opacity 0
    //- they are inverse
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="100"
      title="Transparent"
      :value="opacity"
      @blur="onBlurInput($event, 'opacity', '%')"
      @update="updateOpacity"
      suffix="%"
    )

    BaseButton.effects__reset-button(
      :is-canvas="true"
      is-dark=true
      is-full-width=true
      @click="resetShadow"
    ) Reset
</template>

<script>
import { mapState } from 'vuex';
import VueSlideUpDown from 'vue-slide-up-down';
import { isEmpty, hexToRgb } from '@/assets/scripts/utilities';
import { elementFormat } from '@/assets/scripts/variables';
import effectsMixin from '@/components/mixins/effects-mixins';

export default {
  name: 'GroundShadowControls',
  components: {
    VueSlideUpDown,
  },
  mixins: [effectsMixin],
  computed: {
    ...mapState(['isGroundShadow']),
    y() {
      return this.getActiveElements[0].data.groundShadow.y;
    },
    opacity() {
      const opacity = this.getActiveElements[0].data.groundShadow.opacity * 100;
      return Math.floor(100 - opacity);
    },
    blur() {
      return this.getActiveElements[0].data.groundShadow.blur;
    },
    scaleX() {
      const { scaleX } = this.getActiveElements[0].data.groundShadow;
      return !isEmpty(scaleX) ? scaleX : elementFormat.data.groundShadow.scaleX;
    },
    scaleY() {
      const { scaleY } = this.getActiveElements[0].data.groundShadow;
      return !isEmpty(scaleY) ? scaleY : elementFormat.data.groundShadow.scaleY;
    },
    colorStyle() {
      const styleObject = {};

      let shadow = '#000000';

      if (this.getActiveElements.length > 0) {
        let { color } = this.getActiveElements[0].data.groundShadow;
        if (typeof this.getActiveElements[0].data.groundShadow === 'string') {
          color = this.getActiveElements[0].data.groundShadow;
        }

        const { r, g, b } = hexToRgb(color);
        shadow = `rgb(${r}, ${g}, ${b})`;
      }

      styleObject.background = shadow;

      return styleObject;
    },
  },
  mounted() {
    if (!this.isEnabled('ground-shadow')) {
      this.toggleEnable('ground-shadow');
    }
  },
  methods: {
    updateY(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementGroundShadow({ y: value });
      }
    },
    updateOpacity(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        const opacity = (100 - parseInt(value, 10)) / 100;
        this.updateCanvasElementGroundShadow({ opacity });
      }
    },
    updateBlur(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementGroundShadow({ blur: value });
      }
    },
    updateScaleX(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementGroundShadow({ scaleX: value });
      }
    },
    updateScaleY(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementGroundShadow({ scaleY: value });
      }
    },
    onBlurInput(event, type, suffix) {
      this.onBlurEffectInput(event, {
        effect: 'groundShadow',
        selectedInput: type,
        suffix,
      });
    },
    openColorPicker() {
      this.setIsGroundShadow(true);
      this.$emit('open-colorpicker');
    },
    resetShadow() {
      const elementId = this.getActiveElements[0].data.id || null;
      const colorObject = {
        id: elementId,
        color: [elementFormat.data.groundShadow.color],
        silent: true,
      };

      this.setIsGroundShadow(true);

      // didn't use async await because with it the 'updateCanvasElementColor' mutation still not receive 'isGroundShadow' properly.
      setTimeout(() => {
        this.catchHistory('element');
        if (elementId) this.updateCanvasElementColor(colorObject);

        this.updateCanvasElementGroundShadow({
          opacity: elementFormat.data.groundShadow.opacity,
          y: elementFormat.data.groundShadow.y,
          blur: elementFormat.data.groundShadow.blur,
          scaleX: elementFormat.data.groundShadow.scaleX,
          scaleY: elementFormat.data.groundShadow.scaleY,
        });
      }, 100);
    },
  },
};
</script>
