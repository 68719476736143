<template lang="pug">
.user-menu__theme
  .user-menu__loader-container(v-if="isLoading")
    Lottie.loader__animation(:options="canvasLoaderOptions")
  template(v-else)
    .theme-list__item(
      v-for="mode in themeMode"
      :class="themeClass(mode)"
    )
      BaseButton(
        :is-icon="true"
        :plain-hover="true"
        @click="changeThemeMode(mode.name)"
      )
        i(:class="mode.icon")
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import VueCookies from 'vue-cookies';
import helperMixin from '@/components/mixins/helper-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';
import Lottie from '@/components/ProjectCanvas/AssetSidebarCanvas/LottieShared.vue';

import UserApi from '@/services/api/UserApi';
import CanvasLoader from '@/assets/data/canvas-loader.json';

export default {
  name: 'UserMenuTheme',
  components: {
    Lottie,
  },
  mixins: [helperMixin, darkModeMixin],
  data() {
    return {
      themeMode: [
        { name: 'light', icon: 'icon-sun-thin' },
        { name: 'dark', icon: 'icon-moon-thin' },
      ],
      isLoading: false,
    };
  },
  computed: {
    ...mapState(['theme']),
    canvasLoaderOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: CanvasLoader,
      };
    },
  },
  methods: {
    ...mapMutations(['setOffeoTheme']),
    themeClass(mode) {
      const classList = [];
      if (mode.name !== this.theme) {
        classList.push('non-active');
      } else {
        classList.push('is-active');
      }

      if (this.isDarkMode) classList.push('is-dark');

      return classList.join(' ');
    },
    changeThemeMode(theme) {
      if (this.theme !== theme) {
        this.isLoading = true;
        UserApi.setPreference({
          key: 'theme',
          value: theme,
        })
          .then((response) => {
            if (response.data.success) {
              this.setOffeoTheme(theme);
              VueCookies.set('offeo-theme', theme);
              // 'documentElement' select the root tag of our html which is <html/>
              // for dark-mode usage purpose
              document.documentElement.setAttribute('data-theme', theme);

              this.isLoading = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss">
.user-menu__theme {
  display: flex;
  align-items: center;
  margin-right: 10px;

  .theme-list__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin-right: 2px;
    border-radius: 4px;

    .btn {
      color: var(--darkgrey-white);

      .btn__content span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &:hover {
      background: $lightGrey400;

      &.is-dark {
        background: $darkGrey500;
      }
    }

    &.is-active {
      cursor: default;
      pointer-events: none;
      background: none;
    }

    &.non-active {
      opacity: 0.4;
    }

    .icon-sun-thin {
      font-size: 1.375rem;
    }

    .icon-moon-thin {
      font-size: 1rem;
    }
  }

  .user-menu__loader-container {
    margin-right: 10px;

    .loader__animation {
      width: 38px !important;
      height: 38px !important;
    }
  }
}
</style>
