<template lang="pug">
.filter-tab
  h3.filter__title(v-if="isMobile") Filters
  .filters(:class="{'hide-scroller': isMobile}")
    template(v-for="item in getFilterLists")
      FilterItem(
        :item="item"
      )
</template>

<script>
import { mapGetters } from 'vuex';
import FilterItem from './children/FilterItem.vue';

export default {
  name: 'FilterTab',
  components: {
    FilterItem,
  },
  props: {
    isMobile: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(['getFilterLists']),
  },
};
</script>

<style lang="scss">
@import '~@/assets/styles/components/filter-tab.scss';
.filter-tab {
  margin-top: 15px;
  height: 100%;
  max-height: calc(100% - 50px);

  .filters {
    max-height: 97%;
    padding-right: 10px;
  }
}
</style>
