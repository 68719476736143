<template lang="pug">
.color__button(
  :class="{ 'is-gradient': isGradient }"
  :style="{ background: backgroundColor }"
  @click="colorClickHandler"
)
  template(v-if="isProjectColor || isPaletteColor")
    button.btn-remove-color(
      type="button"
      @click="deleteColor"
    )
      i.icon-cancel
  template(v-if="isColourLoverColor")
    button.btn-add-color(
      type="button"
    )
      i.icon.icon-plus
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'; // eslint-disable-line
import cloneDeep from 'lodash.clonedeep';
import { getSelectedText } from '@/assets/scripts/utilities';

import elementMixins from '@/components/mixins/element-mixins';

export default {
  name: 'ColorButton',
  mixins: [elementMixins],
  props: {
    color: { type: [String, Object, Array], default: () => ({}) },
    index: { type: Number, default: 0 },
    category: { type: String, default: '' },
    isProjectColor: { type: Boolean, default: false },
    isColourLoverColor: { type: Boolean, default: false },
    isPaletteColor: { type: Boolean, default: false },
    isColorPicker: { type: Boolean, default: false },
    /**
     * brandPalette: { name, color, index }
     */
    brandPalette: { type: Object, default: () => {} },
  },
  computed: {
    ...mapState([
      'showBrandTab',
    ]),
    ...mapState('team', ['teamId']),
    ...mapGetters(['isLiteMode']),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getActiveElementsIds',
      'getActiveColorId',
      'getProjectBrandId',
    ]),
    ...mapGetters('assetsSidebar', ['getActiveGroup', 'getActiveSubGroup']),
    degree() {
      return typeof this.color.degree === 'number' ? this.color.degree : 135;
    },
    backgroundColor() {
      let background = '#fff';
      const { color } = this;
      if (typeof color === 'object') {
        if (color.points) {
          // Multiple Point Gradient
          background = `linear-gradient(${this.degree}deg`;
          const { points } = color;
          points.forEach((point) => {
            background += `, ${point.color} ${point.percentage}%`;
          });
          background += ')';
        } else if (color.color) {
          // for solid color for animated json
          // since animated json need `nm` key
          background = color.color;
        }
      } else {
        background = this.color;
      }
      return background;
    },
    isGradient() {
      return typeof this.color === 'object';
    },
    isBackgroundColor() {
      return this.getActiveColorId === 'background' || this.getActiveColorId === null;
    },
  },
  methods: {
    ...mapMutations('canvasElements', [
      'updateCanvasElementStroke',
      'deleteProjectColors',
      'updateCanvasElementContent',
      'insertElementToCanvas',
      'updateCanvasElementColor',
    ]),
    ...mapActions('canvasElements', [
      'updateCanvasBackground',
    ]),
    ...mapActions('canvasHistory', [
      'catchHistory',
    ]),
    colorClickHandler() {
      console.log('colorClickHandler', this.isPaletteColor);
      if (this.isPaletteColor) {
        this.$emit('click');
      } else {
        this.updateColor();
      }
    },
    updateColor() {
      // console.log('update color', getSelectedText());
      let { color } = this;
      const colorObject = {};

      if (color.nm) delete color.nm; // color.nm shouldn't be included when choosing from list of project colors

      if (color.color) {
        /**
         * in older project, the color object from canvas bg is
         * color = {
         *   color: '#fff'
         * };
         * so we need to convert them to string
         */
        // eslint-disable-next-line
        color = color.color;
      }

      if (this.getActiveColorId && this.getActiveColorId.toString().startsWith('stroke')) {
        // if it's for stroke color
        this.catchHistory('element');

        const activeElementsStroke = this.getActiveElements[0].data.stroke;

        colorObject.stroke = [];
        const newColor = color.color || color;

        if (activeElementsStroke.length > 1) {
          // if there are more than 1 color
          const activeElementsColor = this.getActiveElements[0].data.color;
          activeElementsColor.forEach((activeColor, index) => {
            const { stroke, strokeColor } = this.getActiveElements[0].data.stroke[index];

            if (this.getActiveColorId.substring(7) === index.toString()) {
              // if this is the one being changed
              colorObject.stroke.push({
                stroke,
                strokeColor: newColor,
              });
            } else {
              // otherwise, keep the old value
              colorObject.stroke.push({
                stroke,
                strokeColor,
              });
            }
          });
        } else {
          // #49ptgu - for filled shapes we default with 0 stroke
          // if user changed the color, set to 10
          const stroke = this.getActiveElements[0].data.stroke[0].stroke || 10;
          colorObject.stroke.push({
            stroke,
            strokeColor: newColor,
          });
        }

        this.$root.$emit('canvas-element-stroke-updated');
        this.updateCanvasElementStroke(colorObject);
      } else {
        if (color.color1) {
          color.degree = this.degree;
        }

        colorObject.color = [];

        // console.log('color button', this.isBackgroundColor, color);

        if (this.isBackgroundColor) {
          // this is for canvas background

          this.catchHistory('element');
          // for mobile, insert new svg shape to present as background

          colorObject.color.push(color);

          // Add layer for background color
          const element = this.setupBackgroundColor(color);
          colorObject.id = element.data.id;

          this.addBackgroundColorLayer(element, colorObject);
        } else {
          console.log('NOT CANVAS BACKGROUND');
          // this is for elements color
          this.catchHistory('element');

          colorObject.id = this.getActiveElements[0].data.id;
          const activeElementsColor = this.getActiveElements[0].data.color;

          // console.log('this.getActiveColorId', this.getActiveColorId, activeElementsColor.length, this.getActiveElements[0].type);

          if (this.getActiveColorId && this.getActiveColorId.startsWith('#gradient')) {
            delete color.degree; // don't need degree
            // multi gradient
            if (!color.color1 && color.color) {
              color.points = [
                {
                  color: color.color,
                  percentage: 0,
                },
                {
                  color: color.color,
                  percentage: 100,
                },
              ];
              delete color.color;
            }
          }

          if (activeElementsColor.length > 1) {
            // if there are more than 1 color
            activeElementsColor.forEach((activeColor, index) => {
              // console.log('colors', activeElementsColor[index], this.getActiveColorId, index);
              // some color may not have nm
              // use == because getActiveColorId is string
              if (
                // eslint-disable-next-line
                (!activeColor.nm && this.getActiveColorId == index)
                || (activeColor.nm && activeColor.nm === this.getActiveColorId)
              ) {
                let newColor = {};

                if (activeColor.nm && typeof color === 'string') {
                  // for solid color for animated json
                  // since animated json need `nm` key
                  newColor.color = color;
                  newColor.nm = this.getActiveColorId;
                } else if (typeof color === 'string') {
                  // for svg
                  newColor = color;
                } else {
                  newColor = Object.assign({}, color);
                  if (activeColor.nm) newColor.nm = this.getActiveColorId;
                }
                colorObject.color.push(newColor);
              } else {
                colorObject.color.push(activeColor);
                console.log('activeColor', activeColor);
              }
            });
          } else if (
            this.getActiveElements[0].type === 'texts'
            && getSelectedText()
            && !this.isLiteMode
          ) {
            // Lite mode only can change all text
            // console.log('color', color);
            document.execCommand('foreColor', false, color);
            const textContent = document
              .getElementsByClassName('text-temp')[0]
              .getElementsByClassName('text-content')[0];
            const content = textContent.innerHTML;
            const activeDataColor = this.getActiveElements[0].data.color[0];

            // console.log('content', content, color.color);
            // console.table({
            //   innerHTML: textContent.innerHTML,
            //   innerText: textContent.innerText,
            //   textContent: textContent.textContent,
            // });
            if (content) {
              const passedContent = {
                id: this.getActiveElementsIds[0],
                content,
              };

              // if user was using gradient text before this
              // reset it to the first colour of the gradient
              console.log('activeDataColor is', activeDataColor);
              if (typeof activeDataColor !== 'string') {
                const newColor = activeDataColor.color1
                  ? activeDataColor.color1
                  : activeDataColor.points[0].color;

                passedContent.color = [{ color: newColor }];
              }
              this.updateCanvasElementContent(passedContent);
            }
          } else if (this.getActiveColorId && this.getActiveColorId.charAt(0) === '#') {
            // for json with single color
            colorObject.color.push({
              color,
              nm: this.getActiveColorId,
            });
          } else {
            colorObject.color.push(color);
          }
        }

        if (
          !this.getActiveElements.length
          || !(this.getActiveElements[0].type === 'texts' && getSelectedText() && !this.isLiteMode)
        ) {
          if (this.getActiveElements.length <= 1) {
            this.updateCanvasElementColor(colorObject);
          } else {
            const activeElementIds = cloneDeep(this.getActiveElementsIds);
            activeElementIds.forEach((elementId) => {
              colorObject.id = elementId;
              this.updateCanvasElementColor(colorObject);
            });
          }
        }
      }
    },
    deleteColor(e) {
      console.log('delete color', e, this.index);
      e.preventDefault();
      e.stopPropagation();
      if (this.isProjectColor) {
        this.deleteProjectColors({
          category: this.category,
          index: this.index,
        });
      }
      if (this.isPaletteColor) {
        this.$emit('delete');
      }
    },
  },
};
</script>

<style lang="scss">
.color__button {
  display: inline-block;
  height: 48px;
  width: 48px;
  margin: 0 13px 13px 0;
  cursor: pointer;
  position: relative;
  transition: opacity 0.25s ease-in-out, border-color 0.25s ease-in-out;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
  // without user-select none, highlighted text will keep being deselected
  user-select: none; // important! so that getSelectedText() can stay

  @include smallest {
    height: 38px;
    width: 38px;
    margin: 0 8px 8px 0;
  }

  &:nth-child(5n) {
    margin-right: 0;
  }

  &.is-disabled {
    display: none;
  }

  &.is-active,
  &:hover {
    border-color: $grey;
  }

  &:hover {

    .btn-remove-color {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
    }

    .btn-add-color {
      opacity: 1;
    }
  }

  .btn-remove-color {
    align-items: center;
    background: $blue;
    border-radius: 50%;
    color: $light;
    cursor: pointer;
    display: flex;
    font-size: 0.4375rem;
    height: 12px;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: -3px;
    text-shadow: none;
    top: -3px;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    visibility: hidden;
    width: 13px;
  }

  .btn-add-color {
    position: absolute;
    top: -3px;
    right: -3px;
    z-index: 1;
    font-size: 8px;
    color: #fff;
    background-color: $darkGrey;
    opacity: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>
