<template lang="pug">
.crop-temp(
  :class="{ 'is-show': showCrop}"
  )
  //- cropper will be inner bound for cropper-image
  //- cropper-image will be outer bound for cropper
  drr.cropper(
    :class="{ 'is-flipped-x': isFlippedX, 'is-flipped-y': isFlippedY }"
    :x="cropperObject.x"
    :y="cropperObject.y"
    :w="cropperObject.w"
    :h="cropperObject.h"
    :angle="cropperObject.angle"
    :selected="true"
    :selectable="true"
    :rotatable="false"
    :outerBound="imageObject"
    ref="cropper"
    @resize="resizeElement"
    @resizestop="updateElement"
    @drag="dragElement"
    @dragstop="updateElement"
    @dblclick.native="$root.$emit('confirm')"
    :isCropper="true"
  )
    .element(
      :style="style"
    )
  drr.cropper-image(
    :class="{ 'is-flipped-x': isFlippedX, 'is-flipped-y': isFlippedY }"
    :x="imageObject.x"
    :y="imageObject.y"
    :w="imageObject.w"
    :h="imageObject.h"
    :angle="imageObject.angle"
    :innerBound="cropperObject"
    ref="cropperImage"
    :selected="true"
    :selectable="true"
    :rotatable="false"
    @drag="dragImage"
    @dragstop="updateImage"
  )
  //- use this to test if outerbound and innerbound is correct
  //- drr.outerbound-temp(
  //-   :x="imageObject.x"
  //-   :y="imageObject.y"
  //-   :w="imageObject.w"
  //-   :h="imageObject.h"
  //-   :draggable="false"
  //-   :resizable="false"
  //-   :rotatable="false"
  //- )
  //- drr.innerbound-temp(
  //-   :x="cropperObject.x"
  //-   :y="cropperObject.y"
  //-   :w="cropperObject.w"
  //-   :h="cropperObject.h"
  //-   :draggable="false"
  //-   :resizable="false"
  //-   :rotatable="false"
  //- )

</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
// eslint-disable-next-line
import drr from '@/plugins/@minogin/vue-drag-resize-rotate';

export default {
  name: 'CropTemp',
  components: {
    drr,
  },
  data() {
    return {
      cropperObject: {
        x: 0,
        y: 0,
        w: 100,
        h: 100,
        angle: 0,
      },
      imageObject: {
        x: 0,
        y: 0,
        w: 100,
        h: 100,
        angle: 0,
      },
      cropperStyle: {
        backgroundImage: '',
        backgroundSize: '',
        backgroundPosition: '',
      },
      position: {
        left: 0,
        top: 0,
      },
      isDragging: false,
    };
  },
  computed: {
    ...mapState([
      'showCrop',
    ]),
    ...mapGetters([
      'getCanvasZoom',
    ]),
    ...mapGetters('canvasElements', [
      'getCroppedElement',
      'getActiveElements',
    ]),
    // hasCrop() {
    //   const activeElement = this.getCroppedElement;
    //   return !(activeElement.data.image.width === '100%'
    //     && activeElement.data.image.width === '100%');
    // },
    targetedElement() {
      return this.getActiveElements[0];
    },
    isFlippedX() {
      return this.getActiveElements.length && this.getActiveElements[0].flipX;
    },
    isFlippedY() {
      return this.getActiveElements.length && this.getActiveElements[0].flipY;
    },
    style() {
      const styleObject = {};

      styleObject.backgroundImage = this.cropperStyle.backgroundImage;
      styleObject.backgroundSize = this.cropperStyle.backgroundSize;
      styleObject.backgroundPosition = this.cropperStyle.backgroundPosition;

      return styleObject;
    },
  },
  methods: {
    ...mapMutations('canvasElements', [
      'updateCroppedElement',
    ]),
    updateImageObject() {
      const activeElement = this.getCroppedElement;

      if (this.showCrop
      && activeElement
      && activeElement.size.width !== 'auto'
      && activeElement.size.height !== 'auto') {
        const croppedImage = {
          x: activeElement.position.x * this.getCanvasZoom,
          y: activeElement.position.y * this.getCanvasZoom,
          width: activeElement.size.width * this.getCanvasZoom,
          height: activeElement.size.height * this.getCanvasZoom,
        };

        croppedImage.left = croppedImage.x - (croppedImage.width / 2);
        croppedImage.top = croppedImage.y - (croppedImage.height / 2);
        croppedImage.right = croppedImage.x + (croppedImage.width / 2);
        croppedImage.bottom = croppedImage.y + (croppedImage.height / 2);

        const fullImage = {
          left: croppedImage.width * parseFloat(activeElement.data.image.left) / 100,
          top: croppedImage.height * parseFloat(activeElement.data.image.top) / 100,
          width: croppedImage.width * parseFloat(activeElement.data.image.width) / 100,
          height: croppedImage.height * parseFloat(activeElement.data.image.height) / 100,
          right: -1 * croppedImage.width * parseFloat(activeElement.data.image.left) / 100,
          bottom: -1 * croppedImage.height * parseFloat(activeElement.data.image.top) / 100,
        };

        // left is not the x and top is not the y
        fullImage.x = (croppedImage.left + fullImage.left) + (fullImage.width / 2);
        fullImage.y = (croppedImage.top + fullImage.top) + (fullImage.height / 2);

        if (this.isFlippedX) fullImage.x = croppedImage.x + (croppedImage.width / 2) - fullImage.left - (fullImage.width / 2);
        if (this.isFlippedY) fullImage.y = croppedImage.y + (croppedImage.height / 2) - fullImage.top - (fullImage.height / 2);


        this.imageObject.x = fullImage.x;
        this.imageObject.y = fullImage.y;
        this.imageObject.w = fullImage.width;
        this.imageObject.h = fullImage.height;

        this.cropperObject.x = fullImage.x;
        this.cropperObject.y = fullImage.y;
      }
    },
    setCropper() {
      const activeElement = this.getCroppedElement;

      if (activeElement) {
        this.$refs.cropperImage.$el.innerHTML = '';

        const elementWidth = activeElement.size.width * this.getCanvasZoom;
        const elementHeight = activeElement.size.height * this.getCanvasZoom;

        const imageLeft = parseFloat(activeElement.data.image.left) / 100 * elementWidth;
        const imageTop = parseFloat(activeElement.data.image.top) / 100 * elementHeight;

        this.position.x = imageLeft;
        this.position.y = imageTop;

        // console.table({
        //   imageTop,
        //   imageBottom,
        //   imageHeight,
        //   elementHeight,
        //   elementTop,
        //   elementBottom,
        // });

        if (activeElement.type === 'images'
          || activeElement.type === 'textures'
          || activeElement.type === 'gradients'
          || activeElement.isImage) {
          const cropperImage = new Image();

          cropperImage.src = activeElement.data.url;
          this.$refs.cropperImage.$el.appendChild(cropperImage);
        } else if (activeElement.type === 'videos') {
          const selectedVideo = document.body.querySelector('.canvas-container .drr.active video');

          if (selectedVideo) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const width = activeElement.size.width * this.getCanvasZoom;
            const height = activeElement.size.height * this.getCanvasZoom;

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(selectedVideo, 0, 0, width, height);

            const cropperImage = new Image();

            // only can draw to image if it's not cross origin
            // if there is any error, default it to use the thumbnail
            try {
              if (canvas.toDataURL('image/jpg')) {
                cropperImage.src = canvas.toDataURL('image/jpg');
                cropperImage.crossOrigin = 'anonymous';
              } else {
                cropperImage.src = activeElement.data.thumb;
              }
            } catch (error) {
              cropperImage.src = activeElement.data.thumb;
            }

            this.$refs.cropperImage.$el.appendChild(cropperImage);
          }
        }

        if (this.$refs.cropperImage) {
          const imageUrl = this.$refs.cropperImage.$el.querySelector('img').src;

          if (imageUrl) {
            this.cropperObject.w = elementWidth;
            this.cropperObject.h = elementHeight;
            this.cropperObject.angle = 0; // activeElement.rotate;

            this.cropperObject.x = activeElement.position.x * this.getCanvasZoom;
            this.cropperObject.y = activeElement.position.y * this.getCanvasZoom;

            this.cropperStyle.backgroundImage = `url(${imageUrl})`;
            this.cropperStyle.backgroundSize = `${this.imageObject.w}px ${this.imageObject.h}px`;

            console.log('setElement', this.cropperObject.y);

            this.cropperStyle.backgroundPosition = `${this.position.x}px ${this.position.y}px`;
          }
        }
      }
    },
    resizeElement(rect, stick) {
      const isLeft = stick.indexOf('l') >= 0;
      const isTop = stick.indexOf('t') >= 0;

      const imageLeft = this.imageObject.x - (this.imageObject.w / 2);
      const imageRight = this.imageObject.x + (this.imageObject.w / 2);
      const imageTop = this.imageObject.y - (this.imageObject.h / 2);
      const imageBottom = this.imageObject.y + (this.imageObject.h / 2);
      const elementLeft = rect.x - (rect.w / 2);
      const elementRight = rect.x + (rect.w / 2);
      const elementTop = rect.y - (rect.h / 2);
      const elementBottom = rect.y + (rect.h / 2);

      // if resizing from right or top, position should stay
      if (isLeft && !this.isFlippedX) this.position.x = imageLeft - elementLeft;
      if (!isLeft && this.isFlippedX) this.position.x = (imageRight - elementRight) * -1;
      if (isTop && !this.isFlippedY) this.position.y = imageTop - elementTop;
      if (!isTop && this.isFlippedY) this.position.y = (imageBottom - elementBottom) * -1;

      this.cropperStyle.backgroundPosition = `${this.position.x}px ${this.position.y}px`;

      this.cropperObject.x = rect.x;
      this.cropperObject.y = rect.y;
      this.cropperObject.w = rect.w;
      this.cropperObject.h = rect.h;
    },
    dragImage(rect) {
      // console.log('dragImage', rect)
      // this.isDragging = true;

      // this.imageObject.x = rect.x;
      // this.imageObject.y = rect.y;
      // this.imageObject.w = rect.w;
      // this.imageObject.h = rect.h;
      const imageLeft = rect.x - (rect.w / 2);
      const imageRight = rect.x + (rect.w / 2);
      const imageTop = rect.y - (rect.h / 2);
      const imageBottom = rect.y + (rect.h / 2);
      const elementLeft = this.cropperObject.x - (this.cropperObject.w / 2);
      const elementRight = this.cropperObject.x + (this.cropperObject.w / 2);
      const elementTop = this.cropperObject.y - (this.cropperObject.h / 2);
      const elementBottom = this.cropperObject.y + (this.cropperObject.h / 2);

      // this.position.x = imageLeft - elementLeft;
      // this.position.y = imageTop - elementTop;

      if (this.isFlippedX) this.position.x = (imageRight - elementRight) * -1;
      else this.position.x = imageLeft - elementLeft;

      if (this.isFlippedY) this.position.y = (imageBottom - elementBottom) * -1;
      else this.position.y = imageTop - elementTop;
      // console.log('imageLeft', rect.w, rect.x, this.cropperObject.w, this.cropperObject.x);

      console.log('dragElement', this.position.x);

      this.cropperStyle.backgroundPosition = `${this.position.x}px ${this.position.y}px`;
    },
    dragElement(rect) {
      this.isDragging = true;

      const imageLeft = this.imageObject.x - (this.imageObject.w / 2);
      const imageRight = this.imageObject.x + (this.imageObject.w / 2);
      const imageTop = this.imageObject.y - (this.imageObject.h / 2);
      const imageBottom = this.imageObject.y + (this.imageObject.h / 2);
      const elementLeft = rect.x - (rect.w / 2);
      const elementRight = rect.x + (rect.w / 2);
      const elementTop = rect.y - (rect.h / 2);
      const elementBottom = rect.y + (rect.h / 2);

      if (this.isFlippedX) this.position.x = (imageRight - elementRight) * -1;
      else this.position.x = imageLeft - elementLeft;

      if (this.isFlippedY) this.position.y = (imageBottom - elementBottom) * -1;
      else this.position.y = imageTop - elementTop;

      console.log('dragElement', this.position.x);

      this.cropperStyle.backgroundPosition = `${this.position.x}px ${this.position.y}px`;

      this.cropperObject.x = rect.x;
      this.cropperObject.y = rect.y;
      this.cropperObject.w = rect.w;
      this.cropperObject.h = rect.h;
    },
    resetCropper() {
      console.log('resetCropper');
      this.cropperObject.x = 0;
      this.cropperObject.y = 0;
      this.cropperObject.w = 100;
      this.cropperObject.h = 100;
      this.cropperObject.angle = 0;

      this.imageObject.x = 0;
      this.imageObject.y = 0;
      this.imageObject.w = 100;
      this.imageObject.h = 100;
      this.imageObject.angle = 0;

      this.imageObject.x = 0;
      this.imageObject.y = 0;
      this.imageObject.w = 100;
      this.imageObject.h = 100;
      this.imageObject.angle = 0;

      this.position.x = 0;
      this.position.y = 0;
    },
    updateElement(rect) {
      this.isDragging = false;
      // update store
      const croppedElement = cloneDeep(this.getCroppedElement);

      croppedElement.position.x = rect.x / this.getCanvasZoom;
      croppedElement.position.y = rect.y / this.getCanvasZoom;
      croppedElement.size.width = rect.w / this.getCanvasZoom;
      croppedElement.size.height = rect.h / this.getCanvasZoom;

      croppedElement.data.image.left = `${this.position.x / rect.w * 100}%`;
      croppedElement.data.image.top = `${this.position.y / rect.h * 100}%`;
      croppedElement.data.image.width = `${this.imageObject.w / rect.w * 100}%`;
      croppedElement.data.image.height = `${this.imageObject.h / rect.h * 100}%`;

      this.cropperObject.w = rect.w;
      this.cropperObject.h = rect.h;
      this.cropperObject.x = rect.x;
      this.cropperObject.y = rect.y;

      this.updateCroppedElement(croppedElement);
    },
    updateImage(rect) {
      const croppedElement = cloneDeep(this.getCroppedElement);

      croppedElement.position.x = this.cropperObject.x / this.getCanvasZoom;
      croppedElement.position.y = this.cropperObject.y / this.getCanvasZoom;
      croppedElement.size.width = this.cropperObject.w / this.getCanvasZoom;
      croppedElement.size.height = this.cropperObject.h / this.getCanvasZoom;

      croppedElement.data.image.left = `${this.position.x / this.cropperObject.w * 100}%`;
      croppedElement.data.image.top = `${this.position.y / this.cropperObject.h * 100}%`;
      croppedElement.data.image.width = `${rect.w / this.cropperObject.w * 100}%`;
      croppedElement.data.image.height = `${rect.h / this.cropperObject.h * 100}%`;

      this.imageObject.w = rect.w;
      this.imageObject.h = rect.h;
      this.imageObject.x = rect.x;
      this.imageObject.y = rect.y;

      this.updateCroppedElement(croppedElement);
    },
  },
  watch: {
    showCrop(value) {
      console.log('showCrop', value);
      if (value) {
        this.updateImageObject();
        this.setCropper();
      } else {
        this.resetCropper();
      }
    },
  },
};
</script>

<style lang="scss">
.crop-temp {
  pointer-events: auto;
  display: none;
  position: relative;

  &.is-show {
    display: block;
  }

  &.stop-resizing {
    .cropper {
      display: none;
    }
  }

  .innerbound-temp {
    background: red;
  }
}

.cropper {
  z-index: 2;
  min-width: 20px;
  min-height: 20px;
  position: relative;

  .element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.is-flipped-x .element {
    transform: scaleX(-1);
  }

  &.is-flipped-y .element {
    transform: scaleY(-1);
  }

  &.is-flipped-y.is-flipped-x .element {
    transform: scale(-1, -1);
  }
}

.cropper-image {
  opacity: 0.6;

  &.is-flipped-x img {
    transform: scaleX(-1);
  }

  &.is-flipped-y img {
    transform: scaleY(-1);
  }

  &.is-flipped-y.is-flipped-x img {
    transform: scale(-1, -1);
  }
  // pointer-events: none;

  &::before {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.outerbound-temp {
  outline: 2px solid red;
}

.innerbound-temp,
.outerbound-temp {
  pointer-events: none;
}
</style>
