<template lang="pug">
.layers__item(
  :class="layerClass"
  @click.left.exact="leftClickLayer"
  @click.ctrl.left.exact="ctrlLeftClickLayer"
  @click.meta.left.exact="ctrlLeftClickLayerMac"
  @click.shift.left.exact="$emit('addRangeActiveElements')"
  @dblclick="editName"
  @mousedown="addIsClicked"
  :style="[groupLayerStyle]"
)
  template(v-if="isMobile && !isLastElement")
    button.layers__drag(
      type="button"
    )
      i.icon.icon-drag
  span.layers__icon(
    :class="{ 'is-sub-item': subItem, 'is-folder': item.groupElement }"
  )
    template(v-if="item.groupElement")
      i.icon.icon-files-menu
    template(v-else-if="isText")
      i.icon.icon-text-menu
    template(v-else-if="isHtml")
      HtmlElement(
        :color="item.data.color"
        :stroke="item.data.stroke"
        :shape="item.data.shape"
        :size="item.size"
        isLayerPreview=true
        :opacity="1"
        :borderRadius="item.data.borderRadius"
      )
    template(v-else-if="isSvg")
      SvgElement(
        :url="item.data.url"
        :color="item.data.color"
        :stroke="item.data.stroke"
        :id="item.data.id"
        :isShape="item.type === 'shapes'"
        :opacity="1"
        :isCanvas="false"
      )
    template(v-else-if="isAnimated")
      AnimatedElement(
        :data="item.data"
        :index="index"
        :opacity="1"
        :animationType="item.animation_type"
      )
    template(v-else-if="isVideo")
      template(v-if="videoThumb")
        img.timeline__preview(:src="videoThumb")
      template(v-else)
        canvas.timeline__canvas
    template(v-else)
      img.timeline__preview(
        :class="{ 'contain': item.type === 'masks' }"
        :src="item.data.url"
      )
  span.layers__icon.layer__arrow(
    v-if="item.groupElement"
    @click.left.exact="toggleGroupFold(item)"
  )
    i.icon.icon-right-arrow(
      :style="arrowStyle"
    )
  input.layers__input(
    v-if="!item.groupElement"
    :class="{'has-new-background': hasNewBackground && isLastElement}"
    :style="subItemAudioStyle"
    autocomplete="off"
    type="text"
    ref="layersInput"
    disabled
    readonly
    v-model="layerName"
    :placeholder="layerName"
    @blur="saveNewName"
    @keyup.enter="saveNewNameAndBlur"
  )
  input.layers__input(
    v-if="item.groupElement"
    type="text"
    ref="layersInput"
    disabled
    readonly
    v-model="item.groupName"
    :placeholder="item.groupName"
    @blur="saveNewName"
    @keyup.enter="saveNewNameAndBlur"
  )
  template(v-else)
    button.layers__button.js-mute-timeline(
      v-if="isVideo && hasAudio"
      type="button"
      @click="toggleMute"
      :title="titleMute"
    )
      i.icon(
        :class="{ 'icon-sound-on': !isMuted, 'icon-sound-off': isMuted }"
      )
    .layers__tags(
      v-if="isTemplate"
    )
      button.btn.btn-remove-layer-tag(
        :class="`remove-layer-tag--${tag}`"
        type="button"
        v-for="tag in layerTags"
        :key="tag"
        @click="removeLayerTag(tag)"
      )
        span {{ tagName(tag) }}
        i.icon.icon-cancel

  button.layers__button.js-lock-layer(
    type="button"
    @click="toggleLock"
    :title="titleLock"
  )
    i.icon.icon-lock2
  button.layers__button.js-view-timeline(
    type="button"
    @click="toggleShow"
    :title="titleShow"
  )
    i.icon(
      :class="{ 'icon-show': !isHidden, 'icon-hide': isHidden }"
    )
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import layerMixin from '@/components/mixins/layer-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

import SvgElement from '@/components/ProjectCanvas/CanvasContainer/children/SvgElement.vue';
import HtmlElement from '@/components/ProjectCanvas/CanvasContainer/children/HtmlElement.vue';
import AnimatedElement from '@/components/ProjectCanvas/CanvasContainer/children/AnimatedElement.vue';
import { isMac, isMobile } from '@/assets/scripts/utilities';
import { layerTagsArray } from '@/assets/scripts/variables';

export default {
  name: 'LayerItem',
  mixins: [layerMixin, darkModeMixin],
  components: {
    SvgElement,
    HtmlElement,
    AnimatedElement,
  },
  props: {
    index: { type: Number, default: 0 },
    item: { type: Object, default: () => ({}) },
    showTag: { type: Boolean, default: false },
    subItem: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
    hasNewBackground: { type: Boolean, default: false },
  },
  data() {
    return {
      layerName: '',
    };
  },
  computed: {
    ...mapState(['isTemplate']),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getActiveElementsIds',
      'getCanvasElements',
    ]),
    layerClass() {
      const cssClass = [];
      if (this.isLocked) cssClass.push('is-locked');
      if (this.isMuted) cssClass.push('is-muted');
      if (this.isHidden) cssClass.push('is-hidden');
      if (this.isActive) cssClass.push('is-active');
      if (this.isClicked) cssClass.push('is-clicked');
      if (this.hasAudio) cssClass.push('has-audio');
      if (this.item.groupElement) cssClass.push('layers__item--group');
      if (this.subItem) cssClass.push('layers__item--sub');
      if (this.isLine) cssClass.push('layers__item--line');
      if (this.isLastElement) cssClass.push('is-last');
      if (this.isDarkMode) cssClass.push('is-dark');

      return cssClass.join(' ');
    },
    layerTags() {
      const tagArray = [];

      for (let i = 0; i < Object.keys(this.item.data.layerTag).length; i += 1) {
        const tag = Object.keys(this.item.data.layerTag)[i];
        if (this.item.data.layerTag[tag]) {
          tagArray.push(tag);
        }
      }

      return tagArray;
    },
    arrowStyle() {
      if (this.item.groupFold) {
        return {};
      }
      return { transform: 'rotate(90deg)' };
    },
    subItemAudioStyle() {
      const styleObject = {};

      if (this.hasAudio && this.subItem) {
        styleObject.width = '27%';
      }

      return styleObject;
    },
    isLastElement() {
      return this.index === this.getCanvasElements.length - 1;
    },
  },
  mounted() {
    if (this.isMobile && this.isLastElement) {
      this.layerName = 'Background';
    } else {
      this.layerName = this.item.data.name;
    }
  },
  methods: {
    ...mapMutations('canvasElements', [
      'changeActiveElements',
      'toggleActiveElements',
      'insertElementToCanvas',
      'updateCanvasElementName',
      'updateGroupName',
      'toggleGroupFold',
    ]),
    ...mapMutations('canvasElements', ['updateLayerTag']),
    leftClickLayer() {
      if (!this.item.lock) {
        if (this.item.groupElement) {
          this.emptyActiveElements();

          this.item.elements.forEach((element) => {
            this.addActiveElements(element.data.id);
          });
        } else {
          this.changeActiveElements(this.item.data.id);
        }
      }
    },
    ctrlLeftClickLayer() {
      if (this.item.groupElement) {
        this.item.elements.forEach((element) => {
          if (!element.lock) {
            this.toggleActiveElements(element.data.id);
          }
        });
      } else if (!this.item.lock) {
        this.toggleActiveElements(this.item.data.id);
      }
    },
    ctrlLeftClickLayerMac() {
      if (!isMac()) return;
      this.ctrlLeftClickLayer(this.item);
    },
    editName() {
      if (!this.$refs.layersInput || isMobile()) return;
      this.$refs.layersInput.disabled = false;
      this.$refs.layersInput.readOnly = false;
      this.$refs.layersInput.focus();
      this.$refs.layersInput.setSelectionRange(0, this.$refs.layersInput.value.length);
    },
    saveNewName(e) {
      const { value } = e.target;
      this.$refs.layersInput.setSelectionRange(0, 0);
      this.$refs.layersInput.disabled = true;
      this.$refs.layersInput.readOnly = true;

      if (value) {
        if (this.item.groupElement) {
          this.updateGroupName({
            name: value,
            group: this.item,
          });
        } else {
          this.updateCanvasElementName({
            id: this.item.data.id,
            name: value,
            hasChanged: true,
          });
        }
      }
    },
    saveNewNameAndBlur(e) {
      this.saveNewName(e);
      this.$refs.layersInput.blur();
    },
    removeLayerTag(tag) {
      this.updateLayerTag({
        ids: this.getActiveElementsIds,
        layerTag: tag,
        isRemoved: true,
      });
    },
    tagName(tag) {
      // to make fg and bg uppercase
      const tagObj = layerTagsArray.find(t => t.value === tag);
      return tagObj.name;
    },
  },
};
</script>

<style scoped lang="scss">
.layers__item {
  display: flex;
  padding: 13px 15px;
  position: relative;
  transition: background 0.25s ease-in-out;
  flex: 0 0 auto;
  overflow: hidden;
  user-select: none;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--bordergrey-darkblue2);

  &:hover:not(.is-locked):not(.is-hidden),
  &.is-active:not(.is-locked):not(.is-hidden) {
    background: rgba($blue600, 0.1);

    &.is-dark {
      background: rgba($green600, 0.1);
    }
  }

  &:hover {
    .layers__button {
      visibility: visible;
      transform: translateX(0);
    }
  }

  &.is-active,
  &.is-locked {
    .layers__button {
      transition: none;
      visibility: visible;
      transform: translateX(0);
    }
  }

  &.is-locked,
  &.is-hidden {
    &.is-clicked {
      animation: blinkLocked 0.2s ease 3;
    }
  }

  &.is-locked {
    .js-lock-layer {
      transform: translateX(0);
      opacity: 0.8;
    }
  }

  &.is-muted {
    .js-mute-timeline {
      transform: translateX(0);
      opacity: 0.8;
    }
  }

  &.is-hidden {
    &:not(.is-dark) {
      background: $hoverGrey;
    }

    .js-view-timeline {
      transform: translateX(0);
      opacity: 0.8;
    }

    .layers__button,
    .layers__input {
      color: var(--grey-white);
      opacity: 0.5;
    }

    .layers__icon {
      opacity: 0.4;
    }

    &.is-dark {
      .layers__button,
      .layers__input,
      .layers__icon {
        opacity: 0.2;
      }
    }
  }
}

.layers__input,
.layers__buttons {
  display: inline-block;
  vertical-align: middle;
}

.layers__input {
  background: transparent;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  flex: 1 0 auto;
  line-height: 1;
  pointer-events: none;
  text-overflow: ellipsis;
  user-select: none;
  width: 45%;
  font-size: 0.75rem;
  border: 0;
  font-weight: 400;
  color: var(--baseblack-white);

  .has-audio & {
    width: 38%;
  }

  .layers__item--group &,
  .layers__item--sub & {
    width: 38%;
  }

  .layers__item--group.has-audio &,
  .layers__item--sub.has-audio & {
    width: 35%;
  }

  &:not(:read-only) {
    cursor: auto;
    user-select: none;
    pointer-events: auto;
  }

  &:not(:read-only):focus {
    border-bottom: 1px solid $darkGrey;
    outline: 0;
  }

  &.has-new-background {
    animation: greenHighlight 1.5s;
  }

  @keyframes greenHighlight {
    0% {
      color: $lightGreen2;
    }
    10% {
      color: $lightGreen2;
    }
    100% {
      color: $lightGrey100;
    }
  }
}

.layers__button {
  cursor: pointer;
}

.layers__button {
  align-items: center;
  background: transparent;
  cursor: pointer;
  display: inline-flex;
  height: 20px;
  justify-content: center;
  visibility: visible;
  width: 20px;
  cursor: pointer;
  transform: translateX(70px);
  opacity: 0.2;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  color: var(--baseblack-white);

  &.is-active {
    opacity: 0.8;
    visibility: visible;
  }

  &:hover {
    opacity: 0.8;
  }

  &:hover {
    opacity: 0.8;
  }

  &.layer-number {
    color: var(--darkgrey800-lightgrey400);
  }

  .icon-show {
    font-size: 1rem;
  }

  .icon-lock2 {
    font-size: 0.875rem;
  }
}

.layers__tags {
  width: 100%;
  padding-left: 38px;
}

.btn-remove-layer-tag {
  text-transform: capitalize;
  padding: 5px 10px;
  border-radius: 20px;
  display: inline-block;
  margin: 0 4px 4px 0;
  font-size: 0.675em;

  span,
  i {
    display: inline-block;
    vertical-align: middle;
  }

  .icon {
    font-size: 0.8em;
    margin-left: 3px;
  }
}

.is-sub-item {
  margin-left: 17px;
}

@keyframes blinkLocked {
  50% {
    background: var(--lightgrey-darkgrey4);
  }
}

.is-folder {
  margin: 0px !important;
}

.layer__arrow {
  width: 12px !important;

  .icon-right-arrow {
    font-size: 1rem !important;
  }
}
</style>

<style lang="scss">
.layers__drag {
  margin-right: 10px;
}

.layers__icon {
  align-items: center;
  flex: 1 0 1;
  display: inline-flex;
  height: 32px;
  justify-content: center;
  margin-right: 8px;
  width: 32px;
  overflow: hidden;
  color: var(--baseblack-white);

  .icon {
    font-size: 1.5rem;

    &.icon-text-menu {
      font-size: 1.25rem;
    }
  }

  .element-wrap,
  svg,
  canvas,
  img {
    width: 32px;
    height: auto;
    object-fit: cover;

    .layers__item--line & {
      flex: 1 0 auto;
      max-width: none;
      width: 100px !important;
      height: auto !important;
    }
  }

  img {
    object-fit: cover;

    &.contain {
      object-fit: contain;
    }
  }

  svg {
    position: static;
  }
}
</style>
