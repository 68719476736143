<template lang="pug">
CanvasModal(
  :title="title"
  :isShow="isShow"
  @closeOverlay="closeOverlay"
  :extraClass="extraClass"
  :maxWidth="700"
  :isGrey="true"
  :hideCloseButton="true"
)
  .text
    p(v-html="text")
  template(v-for="button in buttons")
    button.btn.btn-default(
      :class="button.class"
      type="button"
      @click="button.clickevent"
      v-html="button.text"
    )
</template>

<script>
export default {
  name: 'WarningOverlay',
  data() {
    return {
      isShaken: false,
    };
  },
  props: {
    text: { type: String, default: '' },
    buttons: { type: Array, default: () => [] },
    isShow: { type: Boolean, default: false },
    title: { type: String, default: '<i class=\'icon icon-warning\'></i> WARNING' },
  },
  computed: {
    extraClass() {
      const cssClass = ['warning-overlay'];

      if (this.isShaken) cssClass.push('is-shaken');

      return cssClass.join(' ');
    },
  },
  methods: {
    closeOverlay() {
      this.isShaken = true;
      setTimeout(() => {
        this.isShaken = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.warning-overlay {
  z-index: 101;

  .canvas-modal__content {
    padding: 30px 50px;
  }

  .title {
    color: $darkGrey;
    font-size: 0.875em;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.5em;
    line-height: 1.2;
    text-align: center;
  }

  .text {
    font-size: 0.875em;
    margin: 1.75em 0;
  }

  .btn {
    padding: 10px 30px;
    margin: 0 2px;
  }

  // .btn:last-child {
  //   background: $grey !important;
  //   color: $darkGrey !important;

  //   &:hover,
  //   &:focus {
  //     background: darken($grey, 5%) !important;
  //     color: $darkGrey !important;
  //   }
  // }
}
</style>
