<template lang="pug">
.team-font-dropdown(
  v-click-outside="closeDropdown"
  :class="{ 'is-active': isActive, 'is-empty': isEmpty }"
)
  BaseButton.team-font-dropdown__selected(
    :is-hollow="true"
    icon-after="icon-down-arrow"
    :is-canvas="true"
    @click="openDropdown"
  ) {{ selectedSubOptionName }}

  .team-font-dropdown__content
    ul.team-font-dropdown__list
      li.team-font-dropdown__item(
        v-for="option in fontStyleOptions"
        :key="option.value"
        :class="{ 'is-selected': option.value === selectedValue }"
        @click="setValue(option.value, option.value, option)"
      )
        span.text {{option.name}}
        i.icon-right-arrow(v-if="!singleFontStyleOption(option)")
        i.team-font__tick-icon.icon-tick(v-if="option.value === selectedSubValue && singleFontStyleOption(option)")

        .sub-lists(
          v-if="!singleFontStyleOption(option)"
          :class="{'direction--right': direction === 'right'}"
        )
          .sub-list__wrapper(
            v-for="subOption in option.extraOptions"
            :key="subOption.value"
          )
            p.sub-list.list--disabled(v-if="subOptionIsSelected(subOption.value)")
                span {{subOption.name}}
                i.team-font__tick-icon.icon-tick(v-if="subOption.value === selectedSubValue")

            p.sub-list(
              v-else
              :class="{ 'is-selected': subOption.value === selectedSubValue }"
              @click="setValue(option.value, subOption.value)"
            )
                span {{subOption.name}}
                i.team-font__tick-icon.icon-tick(v-if="subOption.value === selectedSubValue")
</template>

<script>
import { fontWeights } from '@/assets/scripts/enums';

export default {
  name: 'FontWeightUploadDropdown',
  props: {
    isEmpty: { type: Boolean, default: false },
    customOptions: { type: Boolean, default: false },
    startFromEmptyValue: { type: Boolean, default: false },
    isItalicValue: { type: Boolean, default: false },
    direction: { type: String, default: 'left' },
    fontWeightValue: { type: Number, default: 0 },
    selectedStyleList: { type: Array, default: () => [] },
    customFontWeights: { type: Array, default: () => [] },
    customItalicFontWeights: { type: Array, default: () => [] },
  },
  data() {
    return {
      isActive: false,
      selectedValue: '',
      selectedSubValue: '',

      fontStyleOptions: [
        {
          name: 'Extra Light',
          value: 'extra-light',
          extraOptions: [
            {
              name: 'Extra Light',
              value: 'extra-light',
            },
            {
              name: 'Extra Light Italic',
              value: 'extra-light-italic',
            },
          ],
        },
        {
          name: 'Light',
          value: 'light',
          extraOptions: [
            {
              name: 'Light',
              value: 'light',
            },
            {
              name: 'Light Italic',
              value: 'light-italic',
            },
          ],
        },
        {
          name: 'Regular',
          value: 'regular',
          extraOptions: [
            {
              name: 'Regular',
              value: 'regular',
            },
            {
              name: 'Regular Italic',
              value: 'regular-italic',
            },
          ],
        },
        {
          name: 'Medium',
          value: 'medium',
          extraOptions: [
            {
              name: 'Medium',
              value: 'medium',
            },
            {
              name: 'Medium Italic',
              value: 'medium-italic',
            },
          ],
        },
        {
          name: 'Semi-Bold',
          value: 'semi-bold',
          extraOptions: [
            {
              name: 'Semi Bold',
              value: 'semi-bold',
            },
            {
              name: 'Semi Bold Italic',
              value: 'semi-bold-italic',
            },
          ],
        },
        {
          name: 'Bold',
          value: 'bold',
          extraOptions: [
            {
              name: 'Bold',
              value: 'bold',
            },
            {
              name: 'Bold Italic',
              value: 'bold-italic',
            },
          ],
        },
      ],
    };
  },
  computed: {
    selectedSubOptionName() {
      let selectedSubOption;

      const selectedOption = this.fontStyleOptions.find(
        (option) => option.value === this.selectedValue // eslint-disable-line
      );

      if (selectedOption) {
        selectedSubOption = selectedOption.extraOptions.find(
          (subOption) => subOption.value === this.selectedSubValue // eslint-disable-line
        );
      }

      if (selectedSubOption && selectedSubOption.name) return selectedSubOption.name;
      else if (this.startFromEmptyValue) return ''; // eslint-disable-line
      return (this.fontStyleOptions[2] && this.fontStyleOptions[2].name) || 'Regular';
    },
  },
  mounted() {
    if (this.customOptions) {
      this.initCustomOptions();
    }
  },
  watch: {
    customFontWeights() {
      this.initCustomOptions();
    },
    customItalicFontWeights() {
      this.initCustomOptions();
    },
    fontWeightValue() {
      this.initSelectedFontValues();
    },
    isItalicValue() {
      this.initSelectedFontValues();
    },
  },
  beforeMount() {
    if (!this.startFromEmptyValue) {
      this.selectedValue = this.fontStyleOptions[2].value;
      this.selectedSubValue = this.fontStyleOptions[2].extraOptions[0].value;
    }

    if (this.fontWeightValue && !this.startFromEmptyValue) {
      this.initSelectedFontValues();
    }
  },
  methods: {
    openDropdown(event) {
      this.isActive = !this.isActive;
      if (this.isActive) this.$emit('open', event);
    },
    closeDropdown() {
      this.isActive = false;
    },
    subOptionIsSelected(subOptionValue) {
      const findSelectedSubOption = this.selectedStyleList.find(
        (item) => item.subFontStyle === subOptionValue // eslint-disable-line
      );
      return findSelectedSubOption;
    },
    setValue(value, subValue, fontStyle) {
      // this 'fontStyle' param is used for single selection in fontDropdown for fontStyle that only has 1 extraOptions (e.g bold-bold, regular-regular, etc)
      // so if 'fontStyle' has both extra options ( e.g bold-bold & bolditalic, regular-regular & regularitalic, etc ), then don't need to do any
      const singleFontStyleOption = fontStyle && fontStyle.extraOptions.length === 1;
      if (fontStyle && !singleFontStyleOption) return;

      this.selectedValue = value;
      this.selectedSubValue = subValue;
      this.$emit('change', { value, subValue });
      this.closeDropdown();
    },
    singleFontStyleOption(fontStyle) {
      return fontStyle.extraOptions.length === 1;
    },

    initSelectedFontValues() {
      this.selectedValue = fontWeights[this.fontWeightValue];
      this.selectedSubValue = fontWeights[this.fontWeightValue];

      if (this.isItalicValue) {
        const hasBothFontStyles = this.customFontWeights.find(
          (fontWeight) => fontWeight === this.fontWeightValue // eslint-disable-line
        );
        const hasCustomWeightOptions = this.customFontWeights.length && this.customItalicFontWeights.length;

        // hasBothFontStyles means it has 'light-italic' & also has 'light' font style,
        // if it only has 'light-italic' then its a single font style.
        if (hasCustomWeightOptions && !hasBothFontStyles) {
          this.selectedValue = this.selectedValue + '-italic'; // eslint-disable-line
        }

        this.selectedSubValue = this.selectedSubValue + '-italic'; // eslint-disable-line
      }
    },
    initCustomOptions() {
      const showExtraLightFont = this.customFontWeights.includes(200);
      const showExtraLightItalicFont = this.customItalicFontWeights.includes(200);
      const showLightFont = this.customFontWeights.includes(300);
      const showLightItalicFont = this.customItalicFontWeights.includes(300);
      const showRegularItalicFont = this.customItalicFontWeights.includes(400);
      const showMediumFont = this.customFontWeights.includes(500);
      const showMediumItalicFont = this.customItalicFontWeights.includes(500);
      const showSemiBoldFont = this.customFontWeights.includes(600);
      const showSemiBoldItalicFont = this.customItalicFontWeights.includes(600);
      const showBoldFont = this.customFontWeights.includes(700);
      const showBoldItalicFont = this.customItalicFontWeights.includes(700);

      // fill only with regular - because regular is a must have font
      this.fontStyleOptions = [
        {
          name: 'Regular',
          value: 'regular',
          extraOptions: [{ name: 'Regular', value: 'regular' }],
        },
      ];

      if (showExtraLightFont) {
        const extraLightObject = {
          name: 'Extra Light',
          value: 'extra-light',
          extraOptions: [{ name: 'Extra Light', value: 'extra-light' }],
        };
        // put extraLightObject at index 0, 2nd parameter (0) means dont remove anything
        this.fontStyleOptions.splice(0, 0, extraLightObject);
      }

      if (showExtraLightItalicFont) {
        const findExtraLightIndex = this.fontStyleOptions.findIndex((fontStyle) => { // eslint-disable-line
          return fontStyle.name === 'Extra Light';
        });

        if (findExtraLightIndex > -1) {
          // if there is extraLight font just push
          this.fontStyleOptions[findExtraLightIndex].extraOptions.push({
            name: 'Extra Light Italic',
            value: 'extra-light-italic',
          });
        } else {
          // otherwise, create new and put on index 0
          const extraLightItalicObject = {
            name: 'Extra Light Italic',
            value: 'extra-light-italic',
            extraOptions: [{ name: 'Extra Light Italic', value: 'extra-light-italic' }],
          };
          this.fontStyleOptions.splice(0, 0, extraLightItalicObject);
        }
      }

      if (showLightFont) {
        const lightObject = {
          name: 'Light',
          value: 'light',
          extraOptions: [{ name: 'Light', value: 'light' }],
        };
        this.fontStyleOptions.splice(1, 0, lightObject);
      }

      if (showLightItalicFont) {
        const findLightIndex = this.fontStyleOptions.findIndex((fontStyle) => { // eslint-disable-line
          return fontStyle.name === 'Light';
        });

        if (findLightIndex > -1) {
          this.fontStyleOptions[findLightIndex].extraOptions.push({
            name: 'Light Italic',
            value: 'light-italic',
          });
        } else {
          const lightItalicObject = {
            name: 'Light Italic',
            value: 'light-italic',
            extraOptions: [{ name: 'Light Italic', value: 'light-italic' }],
          };
          this.fontStyleOptions.splice(1, 0, lightItalicObject);
        }
      }

      if (showRegularItalicFont) {
        const findRegularIndex = this.fontStyleOptions.findIndex((fontStyle) => { // eslint-disable-line
          return fontStyle.name === 'Regular';
        });

        this.fontStyleOptions[findRegularIndex].extraOptions.push({
          name: 'Regular Italic',
          value: 'regular-italic',
        });
      }

      if (showMediumFont) {
        const mediumObject = {
          name: 'Medium',
          value: 'medium',
          extraOptions: [{ name: 'Medium', value: 'medium' }],
        };
        this.fontStyleOptions.splice(3, 0, mediumObject);
      }

      if (showMediumItalicFont) {
        const findMediumIndex = this.fontStyleOptions.findIndex((fontStyle) => { // eslint-disable-line
          return fontStyle.name === 'Medium';
        });

        if (findMediumIndex > -1) {
          this.fontStyleOptions[findMediumIndex].extraOptions.push({
            name: 'Medium Italic',
            value: 'medium-italic',
          });
        } else {
          const mediumItalicObject = {
            name: 'Medium Italic',
            value: 'medium-italic',
            extraOptions: [{ name: 'Medium Italic', value: 'medium-italic' }],
          };
          this.fontStyleOptions.splice(3, 0, mediumItalicObject);
        }
      }

      if (showSemiBoldFont) {
        const semiBoldObject = {
          name: 'Semi-Bold',
          value: 'semi-bold',
          extraOptions: [{ name: 'Semi-Bold', value: 'semi-bold' }],
        };
        this.fontStyleOptions.splice(4, 0, semiBoldObject);
      }

      if (showSemiBoldItalicFont) {
        const findSemiBoldIndex = this.fontStyleOptions.findIndex((fontStyle) => { // eslint-disable-line
          return fontStyle.name === 'Semi-Bold';
        });

        if (findSemiBoldIndex > -1) {
          this.fontStyleOptions[findSemiBoldIndex].extraOptions.push({
            name: 'Semi Bold Italic',
            value: 'semi-bold-italic',
          });
        } else {
          const semiBoldItalicObject = {
            name: 'Semi Bold Italic',
            value: 'semi-bold-italic',
            extraOptions: [{ name: 'Semi Bold Italic', value: 'semi-bold-italic' }],
          };
          this.fontStyleOptions.splice(4, 0, semiBoldItalicObject);
        }
      }

      if (showBoldFont) {
        const boldObject = {
          name: 'Bold',
          value: 'bold',
          extraOptions: [{ name: 'Bold', value: 'bold' }],
        };
        this.fontStyleOptions.splice(5, 0, boldObject);
      }

      if (showBoldItalicFont) {
        const findBoldIndex = this.fontStyleOptions.findIndex((fontStyle) => { // eslint-disable-line
          return fontStyle.name === 'Bold';
        });

        if (findBoldIndex > -1) {
          this.fontStyleOptions[findBoldIndex].extraOptions.push({
            name: 'Bold Italic',
            value: 'bold-italic',
          });
        } else {
          const boldItalicObject = {
            name: 'Bold Italic',
            value: 'bold-italic',
            extraOptions: [{ name: 'Bold Italic', value: 'bold-italic' }],
          };
          this.fontStyleOptions.splice(5, 0, boldItalicObject);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.team-font-dropdown {
  position: relative;
  font-size: 0.875rem;
  max-width: 120px;
  width: 100%;
}

.team-font-dropdown__selected {
  width: 100%;
  position: relative;
  justify-content: start;
  background: var(--lightgrey300-darkgrey5);
  border-color: var(--lightgrey300-darkgrey5);
  color: var(--black-white);
  font-size: 0.75rem;
  min-height: 30px;
  text-align: left;
  padding: 8px 15px;

  &:active,
  &:hover {
    background: var(--lightgrey300-darkgrey5) !important;
    color: var(--black-white) !important;
  }

  .team-font-dropdown.is-empty & {
    border-color: $red600 !important;
  }

  .team-font-dropdown.is-active & {
    border-color: $blue700;
  }

  .icon {
    position: absolute !important;
    right: 0;
  }
}

.team-font-dropdown__content {
  position: absolute;
  width: 100%;
  z-index: 3;
  background: var(--white-darkgrey900);
  color: var(--darkgrey900-lightgrey300);
  box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 2px rgba(10, 31, 68, 0.1);
  border-radius: 0 0 4px 4px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  display: flex;
  flex-direction: column;

  .team-font-dropdown.is-active & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  * {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(230, 230, 230, 0.5);
    }
    &::-webkit-scrollbar-thumb {
      background: $lightGrey800;
      transition: background 0.25s ease-in-out;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: darken($lightGrey800, 5%);
    }
  }
}

.team-font-dropdown__list {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
}

.team-font-dropdown__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: transparent;
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid var(--disabledgrey-darkgrey900);
  font-size: 0.75rem;
  font-weight: 400;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    .sub-lists {
      opacity: 1;
      visibility: visible;
      transform: translateX(0) !important;
    }
  }

  &.is-selected,
  &:hover {
    background: var(--lightgrey300-darkgrey5);
  }

  .sub-lists {
    z-index: 10;
    position: absolute;
    top: 0;
    right: 120px;
    width: calc(100% + 30px);
    box-shadow: 4px 4px 10px 2px rgba(0, 0, 0, 0.25);
    transition: opacity 0.25s, transform 0.4s;
    opacity: 0;
    transform: translateX(25px);
    visibility: hidden;
    background: var(--white-darkgrey900);

    &.direction--right {
      right: -150px;
      transform: translateX(-25px);
    }

    .sub-list__wrapper {
      border-bottom: 1px solid var(--disabledgrey-darkgrey900);

      &:last-child {
        border-bottom: 0;
      }
    }

    .sub-list {
      display: flex;
      margin: 0;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 12px;
      background: var(--white-darkgrey900);
      cursor: pointer;

      &:hover {
        background: var(--lightgrey300-darkgrey5);
      }

      &.list--disabled {
        background: var(--lightgrey300-darkgrey5);
        cursor: default;
        color: $darkGrey500;
      }
    }
  }

  .team-font__tick-icon {
    color: $green;
    font-size: 0.9325rem;
    transform: rotateZ(10deg);
    margin-left: 10px;
  }
}
</style>
