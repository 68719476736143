<template lang="pug">
BaseButton.control-general__rotate-button(
  :icon-before="icon"
  :is-canvas="true"
  @click="rotateElement(direction)"
) {{ title }}
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'RotateButton',
  props: {
    icon: { type: String, default: '' },
    title: { type: String, default: '' },
    direction: { type: String, default: '' },
    index: { type: Number, default: 0 },
  },
  computed: {
    ...mapGetters('canvasElements', ['getCanvasElements', 'getActiveElements']),
  },
  methods: {
    ...mapMutations('canvasElements', ['updateCanvasElementRect']),
    rotateElement(direction) {
      //   console.log('this.getActiveElements',this.getActiveElements)
      // this.getActiveElements.forEach((el, index) => {
      //   let angle = el.rotate;
      //   console.log('direction', direction, angle)
      //   if (direction === 'ccw') {
      //     angle -= 90;
      //     if (angle <= 0) angle += 360;
      //   }
      //   if (direction === 'cw') {
      //     angle += 90;
      //     if (angle <= 0) angle -= 360;
      //   }
      //   // this.updateCanvasElementRect({
      //   //   id: el.data.id,
      //   //   rotate: angle,
      //   // });
      // });

      this.$root.$emit('canvasElementRotateUpdated', { direction });
    },
  },
};
</script>

<style lang="scss"></style>
