<template lang="pug">
.confirmation(
  :class="{ 'is-show': isShow }"
)
  template(v-if="!isShow")
    button.btn--text.btn--control(
      v-if="isIcon"
      type="button"
      :data-title="dataTitle"
      :class="[ buttonClass ]"
      @click="$emit('show-confirmation')"
    )
    BaseButton.btn--control(
      v-else
      type='button'
      :iconBefore="iconBefore"
      @click="$emit('show-confirmation')"
      :isHollow="!isText"
      :isText="isText"
    ) {{ buttonText }}
  template(v-else)
    .confirmation-control
      BaseButton(
        @click="$emit('confirm')"
        is-hollow=true
        :is-canvas="true"
        :is-loading='isDoneButtonLoading'
        is-small=true
      ) Done
      BaseButton(
        @click="$emit('cancel')"
        is-salmon-hollow=true
        :is-canvas="true"
        is-small=true
      ) Cancel
  GlobalEvents(
    :filter="(event, handler, eventName) => allowGlobalEvents(event)"
    @keydown.prevent.esc="$emit('cancel')"
    @keydown.prevent.enter="$emit('confirm')"
  )
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ConfirmationControl',
  props: {
    isShow: { type: Boolean, default: false },
    isDoneButtonLoading: { type: Boolean, default: false },
    isIcon: { type: Boolean, default: false },
    isText: { type: Boolean, default: false },
    dataTitle: { type: String, default: '' },
    buttonClass: { type: String, default: '' },
    buttonText: { type: String, default: '' },
    iconBefore: { type: String, default: '' },
  },
  computed: {
    ...mapGetters('canvasElements', ['getActiveElements']),
  },
  beforeDestroy() {
    this.$root.$off('confirm', this.onConfirm);
  },
  mounted() {
    this.$root.$on('confirm', this.onConfirm);
  },
  methods: {
    allowGlobalEvents(event) {
      return event.target.tagName !== 'INPUT'
      && event.target.tagName !== 'TEXTAREA'
      && event.target.getAttribute('contenteditable') !== 'true'
      && this.isShow;
    },
    onConfirm() {
      if (this.isShow) this.$emit('confirm');
    },
  },
};
</script>

<style lang="scss">
.confirmation {
  position: relative;
  display: inline-flex;

  &.is-show {
    top: 0;
    position: absolute;
    margin-left: -10px;
    height: 100%;
    right: 0;
    left: 442px;

    @include smallest {
      left: 352px;
    }

    #main-wrapper.is-sidebar-collapsed:not(.has-extra-sidebar-opened) & {
      left: 75px;
    }
  }

  .btn--text {
    display: block;
  }

  .btn--confirm {
    padding: 8px 10px;

    .icon--before {
      margin-left: 0;
    }
  }
}

.confirmation-control {
  background: var(--white-darkgrey600);
  z-index: 12;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 11px;

  .lite-mode & {
    @include fb-requirement {
      margin-left: -20px;
      min-width: 180px;
    }
  }

  .btn {
    &:first-of-type {
      border-color: var(--darkgrey-white);
      color: var(--darkgrey-white);
      margin-right: 7px;

      &:hover {
        color: $darkGrey;
        background: var(--lightgrey400-white);
      }
    }

    &:last-of-type {
      border-color: $red500;
      color: $red500;
      margin-right: 0;

      &:hover {
        color: $light;
      }
    }
  }
}
</style>
