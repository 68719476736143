<template lang="pug">
  div.animation-tab__direction
    h4.direction__title Direction
    ul.direction__list
      li(
        v-for="direction in directionList"
        :key="direction"
        :data-title="directionText(direction)"
      )
        BaseButton.direction__list-item(
          :plain-hover="true"
          :class="directionListItemClass(direction)"
          @click="changeDirection(direction)"
        )
          i(
            v-if="notSvgIconsType(direction)"
            :class="iconClass(direction)"
          )
          template(v-else-if="direction === 'from-small' || direction === 'to-big'")
            svg.direction__from-size-icon(width="25" height="25" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg")
              path(fill-rule="evenodd" clip-rule="evenodd" d="M13.9532 20.6415C17.2807 20.6415 19.9783 17.944 19.9783 14.6164C19.9783 11.2888 17.2807 8.59131 13.9532 8.59131C10.6256 8.59131 7.9281 11.2888 7.9281 14.6164C7.9281 17.944 10.6256 20.6415 13.9532 20.6415ZM13.9532 22.1415C18.1092 22.1415 21.4783 18.7724 21.4783 14.6164C21.4783 10.4604 18.1092 7.09131 13.9532 7.09131C9.7972 7.09131 6.4281 10.4604 6.4281 14.6164C6.4281 18.7724 9.7972 22.1415 13.9532 22.1415Z" fill="white")
              path(opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M13.9532 26.9123C20.7441 26.9123 26.2492 21.4072 26.2492 14.6163C26.2492 7.82541 20.7441 2.32031 13.9532 2.32031C7.1623 2.32031 1.6572 7.82541 1.6572 14.6163C1.6572 21.4072 7.1623 26.9123 13.9532 26.9123ZM13.9532 28.4123C21.5725 28.4123 27.7492 22.2356 27.7492 14.6163C27.7492 6.99699 21.5725 0.820312 13.9532 0.820312C6.33387 0.820312 0.157196 6.99699 0.157196 14.6163C0.157196 22.2356 6.33387 28.4123 13.9532 28.4123Z" fill="white")
          template(v-else)
            svg.direction__from-size-icon(width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg")
              path(opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M14.6689 20.6415C17.9965 20.6415 20.694 17.944 20.694 14.6164C20.694 11.2888 17.9965 8.59131 14.6689 8.59131C11.3413 8.59131 8.64381 11.2888 8.64381 14.6164C8.64381 17.944 11.3413 20.6415 14.6689 20.6415ZM14.6689 22.1415C18.8249 22.1415 22.194 18.7724 22.194 14.6164C22.194 10.4604 18.8249 7.09131 14.6689 7.09131C10.5129 7.09131 7.14381 10.4604 7.14381 14.6164C7.14381 18.7724 10.5129 22.1415 14.6689 22.1415Z" fill="white")
              path(fill-rule="evenodd" clip-rule="evenodd" d="M14.6689 26.9123C21.4598 26.9123 26.9649 21.4072 26.9649 14.6163C26.9649 7.82541 21.4598 2.32031 14.6689 2.32031C7.87801 2.32031 2.37291 7.82541 2.37291 14.6163C2.37291 21.4072 7.87801 26.9123 14.6689 26.9123ZM14.6689 28.4123C22.2882 28.4123 28.4649 22.2356 28.4649 14.6163C28.4649 6.99699 22.2882 0.820312 14.6689 0.820312C7.04958 0.820312 0.87291 6.99699 0.87291 14.6163C0.87291 22.2356 7.04958 28.4123 14.6689 28.4123Z" fill="white")
</template>

<script>
export default {
  name: 'AnimationTabDirection',
  props: {
    directionList: { type: Array, default: () => [] },
    selectedTransition: { type: Object, default: () => ({}) },
    isModal: { type: Boolean, default: false },
    colorClass: { type: String, default: 'is-green' },
  },
  computed: {
    selectedDirection() {
      if (this.selectedTransition.direction) return this.selectedTransition.direction;
      return 'none';
    },
  },
  methods: {
    directionListItemClass(direction) {
      const cssClass = [];

      if (this.isSelectedDirection(direction)) cssClass.push('is-selected');
      if (this.isModal) cssClass.push('is-modal');
      cssClass.push(this.colorClass);

      return cssClass.join(' ');
    },
    directionText(text) {
      return text.replace(/-/g, ' ');
    },
    isSelectedDirection(direction) {
      return this.selectedDirection === direction;
    },
    notSvgIconsType(direction) {
      return (
        direction !== 'from-small'
        && direction !== 'from-big'
        && direction !== 'to-small'
        && direction !== 'to-big'
      );
    },
    iconClass(type) {
      let icon;
      if (type === 'default' || type === 'from-centre' || type === 'to-centre') icon = 'icon-from-center';
      if (type === 'from-left' || type === 'to-right') icon = 'icon-right-arrow';
      if (type === 'from-right' || type === 'to-left') icon = 'icon-left-arrow';
      if (type === 'from-top' || type === 'to-bottom') icon = 'icon-down-arrow';
      if (type === 'from-bottom' || type === 'to-top') icon = 'icon-up-arrow';
      if (type === 'horizontal') icon = 'icon-horizontal';
      if (type === 'vertical') icon = 'icon-vertical';
      if (type === 'top-right') icon = 'icon-top-right';
      if (type === 'top-left') icon = 'icon-top-left';
      if (type === 'bottom-right') icon = 'icon-bottom-right';
      if (type === 'bottom-left') icon = 'icon-bottom-left';

      return icon;
    },
    changeDirection(direction) {
      this.$emit('change-direction', direction);
    },
  },
};
</script>

<style lang="scss">
.animation-tab__direction {
  margin: 30px 0;

  .direction__title {
    font-size: 0.875rem;
    font-weight: 500;
    color: $light;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .direction__list {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: block;
      margin-right: 8px;

      &:last-of-type {
        margin-right: 0;
      }

      &:after {
        text-transform: capitalize;
      }
    }

    .direction__list-item {
      padding: 5px;
      width: 35px;
      height: 35px;
      font-size: 1.5rem;
      background: $darkGrey900;
      color: $darkGrey200;

      rect,
      path {
        fill: $darkGrey200 !important;
      }

      &.is-selected,
      &:hover {
        color: $darkGrey900;

        rect,
        path {
          fill: $darkGrey900 !important;
        }
      }

      &.is-modal {
        background: $darkBlue3;
      }

      &.is-green,
      &.is-orange,
      &.is-red {
        &:hover {
          color: $light;

          rect,
          path {
            fill: $light !important;
          }
        }

        &.is-selected {
          color: $light;

          rect,
          path {
            fill: $light !important;
          }
        }
      }

      &.is-green {
        .btn__circle {
          background: $lightGreen2;
        }

        &:hover {
          background: $lightGreen2;
        }

        &.is-selected {
          background: $lightGreen2;
        }
      }

      &.is-orange {
        .btn__circle {
          background: $orange600;
        }

        &:hover {
          background: $orange600;
        }

        &.is-selected {
          background: $orange600;
        }
      }

      &.is-red {
        .btn__circle {
          background: $red400;
        }

        &:hover {
          background: $red400;
        }

        &.is-selected {
          background: $red400;
        }
      }

      .btn__content span {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .icon-from-center {
        font-size: 1.125rem;
      }

      .icon-horizontal,
      .icon-vertical {
        font-size: 1.25rem;
      }
    }
  }
}
</style>
