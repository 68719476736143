<template lang="pug">
.media-blur.media-effect
  BaseSwitch.media-blur__switch.effect__switch(
    name="media-blur"
    :is-checked="isEnabled('blur')"
    @change="toggleEnable('blur')"
    label="Blur"
  )
  VueSlideUpDown.media-blur__content.effect__content(
    :class="{'effect-content--enabled': isEnabled('blur')}"
    :active="true"
  )
    BaseSlider.effects__base-slider.slider--extra-margin-top(
      is-dark=true
      :min="0"
      :max="100"
      :value="blur"
      @blur="onBlurInput($event, 'blur')"
      @update="updateBlur"
    )

    BaseButton.effects__reset-button(
      :is-canvas="true"
      is-dark=true
      is-full-width=true
      @click="resetBlur"
    ) Reset
</template>

<script>
import VueSlideUpDown from 'vue-slide-up-down';
import { elementFormat } from '@/assets/scripts/variables';
import effectsMixin from '@/components/mixins/effects-mixins';

export default {
  name: 'MediaBlur',
  components: {
    VueSlideUpDown,
  },
  mixins: [effectsMixin],
  computed: {
    blur() {
      return this.getActiveElements[0].data.mediaBlur.blur;
    },
  },
  mounted() {
    if (!this.isEnabled('blur')) {
      this.toggleEnable('blur');
    }
  },
  methods: {
    updateBlur(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        this.updateCanvasElementMediaBlur({ blur: value });
      }
    },
    resetBlur() {
      this.catchHistory('element');
      this.updateCanvasElementMediaBlur({ blur: elementFormat.data.mediaBlur.blur });
    },
    onBlurInput(event, type) {
      this.onBlurEffectInput(event, {
        effect: 'mediaBlur',
        selectedInput: type,
      });
    },
  },
};
</script>

<style lang="scss"></style>
