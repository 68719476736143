<template lang="pug">
#app.app-canvas(
  :class="{ 'layer-is-rotating': isLayerRotating, 'lite-mode': isLiteMode, 'desktop-app' : isDesktopApp, 'is-maximized': isMaximized }",
  @dragenter="dropHere($event, true)",
  @dragleave="dropHere($event, false)",
  @dragover.prevent,
  @drop.prevent="dropUpload($event)",
)
  DesktopTitleBar(
    v-if="isDesktopApp"
    :is-mac="isMac"
    dropdown-icon="icon-down-arrow obui-icon-chevron-down"
    reload-icon="icon-rotate"
    @maximized="screenMaximized"
  )

  template(
    v-if="!isLoggedIn"
  )
    #main-header
      BaseHeader
        span.project-name--simple {{ getProjectDetails.name || 'OFFEO' }}

    AccountController

  template(v-else-if="isPageLoaded")
    AccountController(v-if="isShowSubscriptionModal")
    #main-header(
      :class='{ "is-swapping": showSwap || showCrop || showMultipleSwap, "header--low-z-index": showUserMenu }'
    )
      CanvasHeader

    #main-content
      #main-wrapper(:class="mainWrapperStyle")
        ControlContainer(
          :timeline-time="timelineTime"
        )
        aside
          AssetsSidebar(
            :class='{ "show-only-user-menu": showStoryboardOverlay }'
            @do-select-file='doSelectFile($event)'
          )

        section.canvas-section(ref='canvasSection')
          CanvasContainer
        TimelineContainer(
          ref='timelineContainer'
          :timeline-time="timelineTime"
          @update-timeline-time="updateTimelineTime"
        )
        RightSidebar(ref='rightSidebarContainer')

      AnimationContainer(v-if='isShowAnimationModal')
      ProjectPreview(v-if='isDownloadPreviewOpened')
      TemplatePreview(v-if='isTemplatePreviewOpened')
      CompletedPreview(v-if='isRenderingCompleted')
      PublishedToFBPreview(v-if='isPublishingToFBCompleted')
      RenderingModal(
        v-if='isRendering',
        @download-cancelled='isDownloadCancelled = true'
        @download-failed='isDownloadFailed = true'
      )
      GenieAnimator(v-if="showGenieModal")
      TrimOverlay(v-if='showTrimOverlay')
      template(v-if='!isFreeUser')
        RemoveBgConfirm
      MusicCategories(v-if='showMusicCategories')
      DesignOverlay(v-if='showDesignContainer')
      SuccessfulSubscription(v-if='showSuccessfulSubscription')
    template(v-if="isLiteMode")
      LiteModeReplaceImage
      LiteModeUploadMusic(v-if="showLiteUploadMusic")
    UploadingModal(
      v-if='showUploadingModal',
      :isDone='isDoneUploading',
      :isSaving='isSaving',
      :isProcessing='isProcessing',
      :text='uploadingText',
      @confirm='confirmUploadingOverlay',
      @pause-upload='doPauseChunkUpload',
      @resume-upload='doStartChunkUpload',
      @cancel-upload='cancelUpload'
    )
    BeforeUploadOverlay(
      v-if='isTrimmingVideosBeforeUpload',
      :videos='unUploadedVideos',
      :uploadingVideos='uploadingVideos',
      :uploadedVideos='uploadedVideos',
      :isUploading='isVideoUploading',
      @remove='removeVideo',
      @upload='uploadTrimmedVideo',
      @closeOverlay='stopTrimmingVideosBeforeUpload'
    )

    //- this dialog is for drag and drop file.
    template(v-if='isUploadCancelled')
      BaseDialog.cancelled-uploading-modal(
        isCompact,
        isShow,
        isSuccess,
        text='Upload has been cancelled.',
        noCancel,
        button1Text='Close',
        :position='{ horizontal: "flex-end", vertical: "flex-end" }',
        @confirm='isUploadCancelled = false',
        @closeOverlay='isUploadCancelled = false'
      )
    template(v-if='isDownloadCancelled || isDownloadFailed')
      BaseDialog.cancelled-downloading-modal(
        isShow,
        hideCloseButton=true
        :isDanger="isDownloadFailed"
        :isSuccess="isDownloadCancelled",
        :text="cancelledDownloadingModalText",
        noCancel,
        button1Text='Close',
        @confirm='isDownloadCancelled = false; isDownloadFailed = false',
        @closeOverlay='isDownloadCancelled = false; isDownloadFailed = false'
      )
    template(v-if='isFileError')
      BaseDialog.file-validation-modal(
        isCompact,
        isShow,
        isSuccess,
        :text='fileErrorMessage',
        noCancel,
        button1Text='Close',
        :noUpgrade='!showUpgradeButton',
        :position='{ horizontal: "flex-end", vertical: "flex-end" }',
        @confirm='isFileError = false',
        @closeOverlay='isFileError = false',
        @open-upgrade-subscription='openUpgradeSubscription'
      )
    CanvasPreloader(
      v-if='showCanvasPreloader'
      text="Loading Assets.."
    )
    Notification(
      @do-reupload="doReuploadFiles"
    )
    DeleteSceneWarning
    template(v-if='showDeleteUserFileModal')
      BaseDialog(
        :isShow='true',
        :isDanger='true',
        :text='deleteConfirmationText',
        @confirm='confirmDeleteHandler',
        @closeOverlay='cancelDeleteUserFile'
      )

    BaseDialog.multiple-window__warning-modal(
      :extraClass='extraClass',
      :isShow='showMultipleWindowWarning',
      :isPrimary='true',
      :text='showMultipleWindowWarningText',
      button1Text="Go back projects",
      button2Text="Use here",
      :button2NoClose="true"
      :noCancel="!!showMultipleWindowWarningUser"
      @confirm="goToProjectDashboard"
      @button-two-click='useHere'
      @closeOverlay='shakeDialog'
    )

    BaseDialog(
      :isShow='showMaxSceneWarning',
      noCancel,
      button1Text='Close',
      :text='maxSceneWarningText',
      @confirm='setShowMaxSceneWarning(false)',
      @closeOverlay='setShowMaxSceneWarning(false)'
    )
    template(v-if='isThirtySecondsWarning')
      template(v-if="isLiteMode")
        BaseDialog(
          isShow,
          noCancel,
          button1Text='Close',
          :text="maximumLiteProjectDurationWarning",
          @confirm='setThirtySecondsWarning(false)',
          @closeOverlay='setThirtySecondsWarning(false)'
        )
      template(v-else-if='isFreeUser')
        BaseDialog(
          isShow,
          button1Text='Upgrade',
          :text="maximumFreeProjectDurationWarning",
          @confirm='openUpgradeSubscription',
          @closeOverlay='setThirtySecondsWarning(false)'
        )
      template(v-else)
        BaseDialog(
          isShow,
          noCancel,
          button1Text='Close',
          :text="maximumPremiumProjectDurationWarning",
          @confirm='setThirtySecondsWarning(false)',
          @closeOverlay='setThirtySecondsWarning(false)'
        )
    template(v-if='!isFreeUser')
      RemoveBgPurchaseOverlay
    img.drr-stick-cursor(
      v-show='isLayerRotating',
      :style='rotationStyle',
      src='@/assets/images/cursors/rotate.png',
      alt='cursor'
    )
    transition(name='fade')
      StoryboardOverlay(
        v-if='showStoryboardOverlay',
        @close='hideStoryboardOverlay'
      )
    BaseDialog(
      :extraClass='extraClass',
      :isShow='!isChrome && showNotChromeDialog',
      noCancel,
      hideCloseButton,
      button1Text='Okay',
      text='Our platform currently works best on Google Chrome. We are still working on support for other browsers.',
      @confirm='showNotChromeDialog = false',
      @closeOverlay='shakeDialog'
    )
    NewUpdateOverlay(v-if='hasNewUpdates')

    DesignerElementUploadModal(
      v-if="showDesignerElementUploadModal"
      :is-uploading="isUploadingDesignerElement"
      :is-image-scanning="isImageScanning",
      :scanned-image-tags="scannedImageTags"
      :show-own-library-button="!hasElementUploadUserFile",
      :show-elements-button="showElementsButton",
      :file-extension="designerElementfileExtension"
      @choose-option="chooseElementUploadOption"
      @submit="uploadDesignerFiles"
    )

    .drop-here(
      v-if="showDropHereOverlay && !showStoryboardOverlay && !isLiteModeUploading"
    )
      DropHere(
        :title="dropHereTitle"
        :text="dropHereText"
        @close-overlay="showDropHereOverlay = false"
      )

    BrandTabFontOverlay(v-if="showBrandTabFontOverlay")
    BrandTabColorEditModal(v-if="showBrandTabColorEditModal")

    //- cheat fix for desktop to know if there are changes made
    input#has-changes(
      v-model="hasChanges"
    )

  Freshchat(v-if="loadFreshchat")
</template>

<script>
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations'; // eslint-disable-line
import Vue from 'vue';
import VueFreshchat from 'vue-freshchat';
import VueCookies from 'vue-cookies';
import vueBraintree from 'vue-braintree';
import DesktopTitleBar from '@offeo/offeo-base-ui/src/components/DesktopTitleBar/DesktopTitleBar.vue';
import { isProduction, isDevMode, maxDuration, dashboardUrl } from '@/assets/scripts/variables'; // eslint-disable-line
import { isMobile, isDesktopApp, isMac, isMaximized } from '@/assets/scripts/utilities'; // eslint-disable-line
import { groups, designerElementUploadOptions } from '@/assets/scripts/enums';
import uploadingMixin from '@/components/mixins/uploading-mixins';
import helperMixin from '@/components/mixins/helper-mixins';
import websocketMixins from '@/components/mixins/websocket-mixins';
import prodScriptsMixin from '@/components/mixins/prod-scripts-mixins';


import UserApi from '@/services/api/UserApi';
import Api from '@/services/api/Api';
import AdminApi from '@/services/api/AdminApi';
import CollectionsApi from '@/services/api/CollectionsApi';

import AccountController from '@/components/Common/AccountController.vue';

import CanvasHeader from '@/components/ProjectCanvas/CanvasHeader/CanvasHeader.vue';
import AssetsSidebar from '@/components/ProjectCanvas/AssetsSidebar/AssetsSidebar.vue';
import CanvasContainer from '@/components/ProjectCanvas/CanvasContainer/CanvasContainer.vue';
import ControlContainer from '@/components/ProjectCanvas/ControlBar/ControlContainer.vue';
import RightSidebar from '@/components/ProjectCanvas/RightSidebar/RightSidebar.vue';
import TimelineContainer from '@/components/ProjectCanvas/TimelineContainer/TimelineContainer.vue';
import CanvasPreloader from '@/components/ProjectCanvas/PageOverlay/CanvasPreloader.vue';
import WarningOverlay from '@/components/ProjectCanvas/PageOverlay/WarningOverlay.vue';
import MultipleLoginOverlay from '@/components/ProjectCanvas/PageOverlay/MultipleLoginOverlay.vue';
import NewUpdateOverlay from '@/components/ProjectCanvas/PageOverlay/NewUpdateOverlay.vue';
import StoryboardOverlay from '@/components/ProjectCanvas/PageOverlay/StoryboardOverlay.vue';
import LiteModeReplaceImage from '@/components/ProjectCanvas/PageOverlay/LiteModeReplaceImage.vue';
import LiteModeUploadMusic from '@/components/ProjectCanvas/PageOverlay/LiteModeUploadMusic.vue';

Vue.use(vueBraintree);

export default {
  name: 'app',
  mixins: [uploadingMixin, helperMixin, websocketMixins, prodScriptsMixin],
  // () => import() means we are lazy loading them
  components: {
    AccountController,
    StoryboardOverlay,
    LiteModeReplaceImage,
    LiteModeUploadMusic,
    CanvasHeader,
    AssetsSidebar,
    CanvasContainer,
    ControlContainer,
    TimelineContainer,
    RightSidebar,
    DesktopTitleBar,
    ProjectPreview: () => import('@/components/ProjectCanvas/ProjectPreview/ProjectPreview.vue'),
    RenderingModal: () => import('@/components/ProjectCanvas/ProjectPreview/RenderingModal.vue'),
    CompletedPreview: () => import('@/components/ProjectCanvas/ProjectPreview/CompletedPreview.vue'),
    PublishedToFBPreview: () => import('@/components/ProjectCanvas/ProjectPreview/PublishedToFBPreview.vue'),
    GenieAnimator: () => import('@/components/ProjectCanvas/GenieAnimator/GenieAnimator.vue'),
    AnimationContainer: () => import(
        /* webpackPrefetch: true */ '@/components/ProjectCanvas/AnimationList/AnimationContainer.vue'
      ),
    TrimOverlay: () => import('@/components/ProjectCanvas/PageOverlay/TrimOverlay.vue'),
    MusicCategories: () => import('@/components/ProjectCanvas/PageOverlay/MusicCategories.vue'),
    DesignOverlay: () => import(
        /* webpackPrefetch: true */ '@/components/ProjectCanvas/DesignListing/DesignOverlay.vue'
      ),
    UploadingModal: () => import('@/components/ProjectCanvas/PageOverlay/UploadingModal.vue'),
    CanvasPreloader,
    SuccessfulSubscription: () => import('@/components/ProjectCanvas/PageOverlay/SuccessfulSubscription.vue'),
    DeleteSceneWarning: () => import('@/components/ProjectCanvas/PageOverlay/DeleteSceneWarning.vue'),
    WarningOverlay,
    MultipleLoginOverlay,
    RemoveBgConfirm: () => import('@/components/ProjectCanvas/PageOverlay/RemoveBgConfirm.vue'),
    RemoveBgPurchaseOverlay: () => import('@/components/ProjectCanvas/PageOverlay/RemoveBgPurchaseOverlay.vue'),
    TemplatePreview: () => import('@/components/ProjectCanvas/ProjectPreview/TemplatePreview.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '@/components/ProjectCanvas/PageOverlay/Notification.vue'),
    DesignerElementUploadModal: () => import(
        /* webpackPrefetch: true */ '@/components/ProjectCanvas/PageOverlay/DesignerElementUploadModal.vue'
      ),
    NewUpdateOverlay,
    DropHere: () => import('@/components/UI/DropHere.vue'),
    Freshchat: () => import('@/components/Common/Freshchat.vue'),
    BrandTabFontOverlay: () => import('@/components/ProjectCanvas/PageOverlay/BrandTabFontOverlay.vue'),
    BrandTabColorEditModal: () => import('@/components/ProjectCanvas/AssetsSidebar/children/BrandTabColorEditModal.vue'),
  },
  data() {
    return {
      isUploadCancelled: false,
      isDownloadCancelled: false,
      isDownloadFailed: false,
      channelId: this.getTemplateId() || this.getProjectId(),
      windowUUID: this.$uuid.v4(),
      shakeMultipleWindowWarning: false,
      showMultipleWindowWarning: false,
      showMultipleWindowWarningUser: null,
      buttons: [
        {
          text: 'Use Here',
          clickevent: this.useHere,
        },
      ],
      isLayerRotating: false,
      rotation: 0,
      isShaken: false,
      showNotChromeDialog: true,
      showDropHereOverlay: false,
      dropHereCounter: 0,
      isPageLoaded: false,
      loadFreshchat: false,
      handleTemporaryFileUpload: '',
      isUploadingDesignerElement: false,
      isImageScanning: false,
      scannedImageTags: [],
      timelineTime: 0,
      isDraggingVideoFile: false,
      isDraggingMusicFile: false,
      hasDroppableFile: false,
      isDesktopApp: isDesktopApp(),
      isMac: isMac(),
      isMaximized: isMaximized(),
    };
  },
  computed: {
    ...mapState([
      'theme',
      'showColorTab',
      'showAnimationTab',
      'showEditTab',
      'showMaskTab',
      'showDesignContainer',
      'isDownloadPreviewOpened',
      'showMultipleSwap',
      'showMusicCategories',
      'showSidebar',
      'showLayer',
      'showCanvasPreloader',
      'showCrop',
      'showModularTemplate',
      'showLastSelectedTemplate',
      'showSwap',
      'showGenieModal',
      'showTrimOverlay',
      'showPermanentTrimElement',
      'showSuccessfulSubscription',
      'bottomRightNotif',
      'isTemplatePreviewOpened',
      'showMultipleLoginWarning',
      'hasNewUpdates',
      'showMaxSceneWarning',
      'showLiteUploadMusic',
      'showLiteReplaceModal',
      'showBrandTabFontOverlay',
      'showBrandTabColorEditModal',
      'isShowSubscriptionModal',
      'isShowAnimationModal',
      'showDesignerElementUploadModal',
      'selectedDesignerElementUploadOption',
      'designerElementUploadUserFile',
    ]),
    ...mapState('inspirationList', ['showRemoveBgOption']),
    ...mapState('userData', ['userRole', 'userLevel', 'userId', 'showUserMenu']),
    ...mapState('canvasElements', ['hasChanges', 'savingTries', 'maxSavingTries']),
    ...mapState('canvasLayouts', ['canvasSectionPadding']),
    ...mapState('assetsSidebar', [
      'deleteItemId',
      'showDeleteUserFileModal',
      'deleteConfirmationText',
    ]),
    ...mapState('account', ['loginModalVisible']),
    ...mapState('team', ['teamSlug']),
    ...mapGetters(['isLiteMode']),
    ...mapState('exportDetails', ['isCompleted', 'isExporting']),
    ...mapGetters('exportDetails', ['getExportDetailStates']),
    ...mapGetters('canvasElements', ['getProjectDetails', 'getCanvasElementById']),
    ...mapGetters('userData', ['isAdmin', 'isDesigner', 'isMasterDesigner', 'isFreeUser']),
    ...mapGetters([
      'getActiveAnimationList',
      'getIsTemplate',
      'isThirtySecondsWarning',
      'showStoryboardOverlay',
    ]),
    ...mapGetters('assetsSidebar', ['getActiveGroup', 'getActiveSubGroup', 'getActiveFileCategory']),
    ...mapGetters('team', ['isInTeamWorkspace']),
    mainWrapperStyle() {
      return {
        'is-swapping': this.showSwap || this.showMultipleSwap,
        'is-cropping': this.showCrop,
        'is-sidebar-collapsed': !this.showSidebar,
        'has-extra-sidebar-opened':
          (this.getActiveGroup !== 'backgrounds' && this.getActiveSubGroup !== 'color')
          && (this.showColorTab || this.showAnimationTab || this.showEditTab || this.showMaskTab),
        'is-layer-collapsed': !this.showLayer,
      };
    },
    isLiteModeUploading() {
      return (
        this.isLiteMode
        && (this.showLiteReplaceModal || this.showLiteUploadMusic || this.showRemoveBgOption)
      );
    },
    showAccountController() {
      return !this.isLoggedIn || this.isFreeUser || this.loginModalVisible;
    },
    isRendering() {
      return this.isExporting;
    },
    isRenderingCompleted() {
      return this.isCompleted;
    },
    isPublishingToFBCompleted() {
      return this.getExportDetailStates.publishedToFB;
    },
    rotationStyle() {
      const styleObject = {
        transform: `translate(-50%, -50%) rotate(${this.rotation}deg)`,
      };

      return styleObject;
    },
    isChrome() {
      const isChromium = window.chrome;
      const winNav = window.navigator;
      const vendorName = winNav.vendor;
      const isOpera = typeof window.opr !== 'undefined';
      const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
      return (
        isChromium !== null
        && typeof isChromium !== 'undefined'
        && vendorName === 'Google Inc.'
        && isOpera === false
        && isIEedge === false
      );
    },
    extraClass() {
      const cssClass = [];

      if (this.isShaken) cssClass.push('is-shaken');

      return cssClass.join(' ');
    },
    maxSceneWarningText() {
      return `You have reached the maximim limit of ${process.env.VUE_APP_MAX_SCENES} scenes in this project.`;
    },
    isFreshchatEnabled() {
      return (
        isProduction()
        && window.location !== '/capture-frame'
        && !isMobile()
        && process.env.VUE_APP_ENABLE_FRESHCHAT === 'true'
        && !this.isAdmin
        && !this.isDesigner
      );
    },
    maximumLiteProjectDurationWarning() {
      return `The maximum duration of a lite project is ${maxDuration.liteProject} seconds.`;
    },
    maximumFreeProjectDurationWarning() {
      return `Free users can only create videos up to ${maxDuration.freeUserProject} seconds. Unlock all features by upgrading to Premium.`;
    },
    maximumPremiumProjectDurationWarning() {
      const maxInMinute = Math.round(maxDuration.payingUserProject / 60);
      return `The maximum duration of a project is ${maxInMinute} minutes.`;
    },
    hasElementUploadUserFile() {
      console.log('hasElementUploadUserFile', Object.keys(this.designerElementUploadUserFile));
      return Object.keys(this.designerElementUploadUserFile).length > 0;
    },
    designerElementUploadFile() {
      return this.hasElementUploadUserFile
        ? this.designerElementUploadUserFile.data.url
        : this.handleTemporaryFileUpload[0];
    },
    uploadCanvasItemFile() {
      return this.hasElementUploadUserFile
        ? this.designerElementUploadUserFile.file_id
        : this.handleTemporaryFileUpload[0];
    },
    showElementsButton() {
      // elements will only accept .mov|.webm and images.
      // console.log('designerElementUploadFile', this.designerElementUploadFile);
      // hide elements button if .mp4
      if (typeof this.designerElementUploadFile.type !== 'undefined') {
        return !this.designerElementUploadFile.type.includes('mp4');
      }

      return this.designerElementUploadFile.split('.').pop() !== 'mp4';
    },
    designerElementfileExtension() {
      if (typeof this.designerElementUploadFile.name !== 'undefined') {
        return this.designerElementUploadFile.name.split('.').pop();
      }
      return this.designerElementUploadFile.split('.').pop();
    },
    dropHereTitle() {
      if (this.isDraggingVideoFile) return 'Drop to upload your video here';
      if (this.isDraggingMusicFile) return 'Drop to upload your audio here';
      return 'Drop to upload your image here';
    },
    dropHereText() {
      if (this.isDraggingVideoFile) return 'You can add MP4, MOV and WEBM.';
      if (this.isDraggingMusicFile) return 'You can add MP3.';
      return 'You can add JPEG, JPG and PNG.';
    },
    showMultipleWindowWarningText() {
      if (this.showMultipleWindowWarningUser) {
        const { name, username, email } = this.showMultipleWindowWarningUser;
        const userName = name || username || email;
        return `${userName} has taken over and is currently editing. Your latest changes is been saved.`;
      }

      return 'This project is open on another computer or browser. Click \'Use Here\' to edit this project in this window.';
    },
    cancelledDownloadingModalText() {
      return this.isDownloadFailed ? 'Downloading failed.' : 'Download has been cancelled';
    },
  },
  beforeMount() {
    // Set theme via cookie
    const offeoThemeCookie = process.env.VUE_APP_OFFEO_THEME;
    const getOffeoTheme = VueCookies.get(offeoThemeCookie);
    const offeoTheme = getOffeoTheme ? getOffeoTheme : 'dark'; // eslint-disable-line
    document.documentElement.setAttribute('data-theme', offeoTheme);

    // Set mode via url
    const modeQuery = this.$route.query.mode;
    let modePreference = modeQuery;
    if (!modePreference) modePreference = 'pro';
    UserApi.setPreference({
      key: 'mode',
      value: modePreference,
    })
      .then((response) => {
        if (response.data.success) {
          let updatedMode = modePreference;
          if (updatedMode === 'pro') updatedMode = 'default';
          this.setOffeoMode(modePreference);
        }
      })
      .catch(() => {});

    // console.log('beforeMount:Project.vue');
    document.body.classList.add('no-scroll');
    // clear up expired storyboard
    this.clearExpiredStoryBoardPreviews();
    this.initWebSocket();
    console.log('this.isPageLoaded', this.isPageLoaded, this.isLoggedIn);

    UserApi.userDetails()
      .then((response) => {
        this.setShowMultipleLoginWarning(false);
        const { results } = response.data;
        const userLevel = results.subscription_level;
        const userRole = results.role_id;
        const userId = results.id;
        const userEmail = results.email;
        const userName = results.name;
        const userUsername = results.username;
        const userAvatar = results.avatar;
        const userRegistrationData = results.created_at;
        const storageSpaceLeft = results.storage_space_left;
        const removeBgCredits = results.remove_bg_credits;
        const usedNewPricing = results.used_new_pricing;

        const isNotified = results.is_notified;
        const isNotifiedOfExports = results.is_notified_of_exports;
        const isSubscribed = results.is_subscribed;
        const activeProjects = results.active_projects;
        const exportedProjectsAsVideos = results.exported_projects_as_videos;
        const exportedProjectsAsImages = results.exported_projects_as_images;
        const currentPlan = results.current_plan;
        const previousPlan = results.previous_plan;
        const customerId = results.customer_id;
        const subscriptionId = results.subscription_id;
        const ltdTransactionId = results.ltd_transaction_id;
        const latestProjects = results.latest_projects;
        const teamSeats = results.team_seats;
        const hasSetupBrand = results.has_setup_brand;
        const createdAt = results.created_at;
        const loginToken = results.login_token;

        console.log('userEmail', userEmail);

        // Set user data for first time
        this.setUserData({
          userLevel,
          userRole,
          name: userName,
          username: userUsername,
          profilePicture: userAvatar,
          userId,
          storageSpaceLeft,
          userEmail,
          removeBgCredits,
          usedNewPricing,
          isNotified,
          isNotifiedOfExports,
          isSubscribed,
          activeProjects,
          exportedProjectsAsVideos,
          exportedProjectsAsImages,
          currentPlan,
          previousPlan,
          customerId,
          subscriptionId,
          ltdTransactionId,
          latestProjects,
          teamSeats,
          hasSetupBrand,
          createdAt,
          loginToken,
        });

        this.setLoginOptions(response.data.user_login_options);

        UserApi.getPreferences()
          .then((preferencesResponse) => {
            // Set theme from server (previous user's theme)
            const { theme } = preferencesResponse.data.results;

            VueCookies.set(process.env.VUE_APP_OFFEO_THEME, theme);
            this.setOffeoTheme(theme);

            // 'documentElement' select the root tag of our html which is <html/>
            // for dark-mode usage purpose
            document.documentElement.setAttribute('data-theme', theme);
          })
          .catch((error) => {
            console.error(error);
          });

        this.initCustomFonts(userId);

        this.setIsLoggedIn(true);
        this.setExternalScripts();

        if (this.isDesigner || !isProduction()) {
          CollectionsApi.getItems('modular-template-categories')
            .then((res) => {
              this.setModularCategories(res.data.results);
            })
            .catch((error) => {
              console.log('error', error);
            });
        }

        // Only need these integrations in production
        /* eslint-disable */
        if (isProduction()) {
          // Only need these integrations if not designer
          if (!this.isDesigner) {
            if (process.env.VUE_APP_ENABLE_WISDOM === 'true') {
              // Wisdom integration
              wisdom('identify', userId);
              wisdom('setUserInfo', {
                email: userEmail,
                firstName: userName,
                traits: {
                  user_type: userLevel,
                  user_role: userRole,
                  registration_date: userRegistrationData,
                },
              });
            }

            if (process.env.VUE_APP_ENABLE_TOOLTIP === 'true') {
              // Tooltip integration
              window.TooltipUserData = {
                id: userId,
                name: userName,
                email: userEmail,
                user_type: userLevel,
                user_role: userRole,
                registration_date: userRegistrationData,
              };
            }

            if (typeof __insp !== 'undefined') {
              // Inspectlet integration
              __insp.push(['identify', userId]);
              __insp.push(['tagSession', 'direct']);
              __insp.push([
                'tagSession',
                {
                  email: userEmail,
                  userid: userId,
                  name: userName,
                  user_type: userLevel,
                  user_role: userRole,
                  registration_date: userRegistrationData,
                },
              ]);
            }
          }

          if (process.env.VUE_APP_ENABLE_SENTRY === 'true') {
            // Sentry integration
            Sentry.configureScope((scope) => {
              scope.setUser({
                id: userId,
                username: userUsername,
                email: userEmail,
              });
            });
          }
        }
        /* eslint-enable */

        // Freshchat Integration
        if (this.isFreshchatEnabled) {
          Vue.use(VueFreshchat, { appToken: process.env.VUE_APP_FRESHCHAT_APP_TOKEN });
          this.loadFreshchat = true;
        }

        if (isDevMode()) {
          if (this.getIsTemplate) {
            this.setUserRole(2);
          } else {
            this.setUserRole(1);
          }
        }

        this.createUserLoginChannel();
      })
      .catch((error) => {
        this.setIsLoggedIn(false);
        console.log(error);
      });


    if (process.env.VUE_APP_BROWSER_TAB_CHECKER === 'false') {
      this.showMultipleWindowWarning = false;
    } else {
      Api.getOrCreateBrowserTab({
        channel_id: this.channelId,
        tab_guid: this.windowUUID,
      })
        .then((response) => {
          if (this.windowUUID !== response.data.results.tab_guid) {
            const { results } = response.data;
            if (results.active_user && results.active_user.id === this.userId) return;

            // Cancel auto save and show blocker
            this.clearAutoSaveInterval();
            this.showMultipleWindowWarning = true;
          } else {
            // Create a websocket channel
            this.createBrowserTabChannel();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // has valid access token
    this.isPageLoaded = true;

    // GLOBAL MAINTENANCE ANNOUNCEMENT
    // setTimeout(() => {
    //  this.alertWarn('Notification', `OFFEO will be performing a server maintenance on 4th December 2023 between 10am - 12pm (GMT+8).
    //  Most services will be unavailable during this period until maintenance is completed.
    //  We apologize for the inconvenience caused.`, 15000);
    // }, 2000);
  },
  mounted() {
    // Check if project is being opened in another tab
    window.uuid = this.windowUUID;
    this.offeoChanelPrefix = this.channelId;

    if (!this.isLoggedIn) {
      return;
    }

    this.updateCanvasSectionPadding();

    // to change cursor rotation
    this.$root.$on('layer-is-rotating', ({ rotation }) => {
      // console.log('rotation', rotation);
      this.rotation = rotation;
      this.isLayerRotating = true;
    });

    this.$root.$on('layer-stops-rotating', () => {
      this.isLayerRotating = false;
    });

    // quick fix to ensure that project will have no-scroll class
    setTimeout(() => {
      document.body.classList.add('no-scroll');
    }, 1000);
  },
  methods: {
    ...mapMutations('userData', ['setUserData', 'setUserRole']),
    ...mapMutations([
      'pushCustomFonts',
      'setShowCanvasPreloader',
      'setShowMultipleLoginWarning',
      'setLoginOptions',
      'setShowUpgradeSubscription',
      'setThirtySecondsWarning',
      'setShowMaxSceneWarning',
      'setIsLoggedIn',
      'setOffeoMode',
      'setOffeoTheme',
      'setShowDesignerElementUploadModal',
      'setSelectedDesignerElementUploadOption',
    ]),
    ...mapMutations('assetsSidebar', ['setShowDeleteUserFileModal', 'setGeneralCategories', 'setActiveFileCategory']),
    ...mapMutations('canvasElements', [
      'clearAutoSaveInterval',
      'setModularCategories',
      'setSavingStatus',
      'replaceUserUploadWithCanvasAssets',
    ]),
    ...mapActions(['updateShowStoryboardOverlay']),
    ...mapActions('assetsSidebar', [
      'confirmDeleteUserFile',
      'getGeneralCategories',
      'getElementMenus',
      'getBackgroundMenus',
    ]),
    ...mapActions('canvasElements', [
      'setAutoSaveInterval',
      'resetAutoSaveInterval',
      'saveProject',
      'clearExpiredStoryBoardPreviews',
    ]),
    getTemplateId() {
      return typeof this.$router.currentRoute.params !== 'undefined'
        && typeof this.$router.currentRoute.params.templateId !== 'undefined'
        ? this.$router.currentRoute.params.templateId
        : '';
    },
    getProjectId() {
      return typeof this.$router.currentRoute.params !== 'undefined'
        && typeof this.$router.currentRoute.params.projectId !== 'undefined'
        ? this.$router.currentRoute.params.projectId
        : '';
    },
    updateCanvasSectionPadding() {
      if (this.$refs.canvasSection) {
        // console.debug('update canvas section padding');
        this.$refs.canvasSection.style.padding = `
            ${this.canvasSectionPadding.top}px
            ${this.canvasSectionPadding.right}px
            ${this.canvasSectionPadding.bottom}px
            ${this.canvasSectionPadding.left}px
          `;
      }
    },
    openUpgradeSubscription() {
      this.setThirtySecondsWarning(false);
      this.setShowUpgradeSubscription(true);
    },
    cancelDeleteUserFile() {
      this.setShowDeleteUserFileModal(false);
    },
    createUserLoginChannel() {
      if (!process.env.VUE_APP_PUSHER_ENABLE) return; // socket disabled
      if (!this.offeoWebSocket || !this.offeoChanelPrefix) return;

      // if (process.env.VUE_APP_ENV === 'local') return;

      const channelName = `user_login_check_${this.userId}`;

      console.log('createUserLoginChannel - channelName =', channelName);

      // USER LOGIN LISTENER
      this.offeoWebSocket.channel(channelName).listen('WebSocketEvent', (res) => {
        console.log(`Listen: user_login_check:${this.userId}@userLogin`, res);
        // const oAuthToken = window.$cookies.get(process.env.VUE_APP_OAUTH_TOKEN_COOKIE);
        // if is logged in to other browser / tabs

        if (
          (typeof res.data.extra_params.is_new_login !== 'undefined'
            && res.data.extra_params.is_new_login)
          || (typeof res.data.extra_params.is_logged_out !== 'undefined'
            && res.data.extra_params.is_logged_out)
        ) {
          this.setShowMultipleLoginWarning(true);
          this.clearAutoSaveInterval();
          this.offeoWebSocket.leaveChannel(channelName);
          this.offeoWebSocket.leaveChannel(`${this.offeoChanelPrefix}_browser_tab`);
        }
      });
    },
    createBrowserTabChannel() {
      if (!process.env.VUE_APP_PUSHER_ENABLE) return; // socket disabled

      if (!this.offeoWebSocket || !this.offeoChanelPrefix) return;

      const channelName = `${this.offeoChanelPrefix}_browser_tab`;

      console.log('WebSocketEvent:browser_tab - channelName =', channelName);

      this.offeoWebSocket.channel(channelName).listen('WebSocketEvent', (res) => {
        console.log('WebSocketEvent:browser_tab', res, this.userId);
        if (res.data.tab_guid !== this.windowUUID) {
          if (res.data.user && res.data.user.id === this.userId) return;

          this.clearAutoSaveInterval();
          this.showMultipleWindowWarningUser = res.data.user || null;
          this.showMultipleWindowWarning = true;
          this.offeoWebSocket.leaveChannel(channelName);
        }
      });
    },
    // createVideoTrimmerChannel() {
    //   if (!process.env.VUE_APP_PUSHER_ENABLE) return; // socket disabled
    //   const channelName = `user_file_${this.userId}`;
    //   this.offeoWebSocket
    //     .channel(channelName)
    //     .listen('WebSocketEvent', ({ data }) => {
    //       data.data.forEach((respondedFile) => {
    //         if (respondedFile.video_trim_complete) {
    //           const filename = respondedFile.original_name;
    //           const index = this.notifiedFiles.findIndex(val => val === filename);
    //           if (index < 0) {
    //             this.$notify({
    //               group: 'BottomRightNotif',
    //               type: 'success',
    //               title: 'Hey there!',
    //               text: `${respondedFile.original_name} is ready for use in your project.`,
    //               duration: 3000,
    //             });
    //             if (this.getActiveGroup === 'my-files') {
    //               this.setActiveSubGroup('videos');
    //               this.setActiveCategory({
    //                 id: 'all',
    //                 slug: 'all',
    //               });
    //             }
    //           } else {
    //             this.notifiedFiles.push(filename);
    //           }
    //         }
    //       });
    //     });
    // },
    useHere() {
      // Update browser tab API
      this.setShowCanvasPreloader(true);
      Api.updateBrowserTab(this.channelId, { tab_guid: this.windowUUID })
        .then((response) => {
          if (response.data.success) {
            this.$root.$emit('CanvasGet');
            this.setAutoSaveInterval();
            this.setSavingStatus('Save');
            this.showMultipleWindowWarning = false;
            this.createBrowserTabChannel();
            this.saveProject();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hideStoryboardOverlay() {
      this.updateShowStoryboardOverlay(false);
    },
    shakeDialog() {
      this.isShaken = true;
      setTimeout(() => {
        this.isShaken = false;
      }, 500);
    },
    dropHere(e, show) {
      const files = e.dataTransfer.items;
      const isVideo = files && files[0] && files[0].type.includes('video');
      const isMusic = files && files[0] && files[0].type.includes('audio');

      this.containsFiles(e); // we have to validate if the droppable item has a valid file.

      if (
        show
        && !this.showStoryboardOverlay
        && !this.isLiteModeUploading
        && this.hasDroppableFile
      ) {
        this.dropHereCounter += 1;
        this.showDropHereOverlay = true;
        this.isDraggingVideoFile = isVideo;
        this.isDraggingMusicFile = isMusic;
      } else {
        this.dropHereCounter -= 1;
        if (this.dropHereCounter === 0) {
          this.showDropHereOverlay = false;
          this.isDraggingVideoFile = false;
          this.isDraggingMusicFile = false;
        }
      }
    },
    containsFiles(e) {
      this.hasDroppableFile = false;
      if (e.dataTransfer.types) {
        for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
          if (e.dataTransfer.types[i] === 'Files' && e.dataTransfer.items[i].type) {
            this.hasDroppableFile = true;
          }
        }
      }
    },
    dropUpload($event) {
      if (this.isInTeamWorkspace && this.getActiveGroup === groups.MYFILES && this.getActiveFileCategory === 'team') {
        this.setActiveFileCategory('personal');
      }

      if (this.isLiteModeUploading || this.showDesignerElementUpload || !this.hasDroppableFile) return;
      console.log('dropUpload', this.hasDroppableFile);

      this.showDropHereOverlay = false;
      this.setSelectedDesignerElementUploadOption('');
      if (
        this.isAdmin
        || this.isDesigner
        || (this.isMasterDesigner && $event.dataTransfer.files.length === 1)
      ) {
        const file = $event.dataTransfer.files[0];
        if (!this.isValidExtension(file.name) || this.isFileSizeExceed(file.size)) {
          const errorType = this.isFileSizeExceed(file.size)
            ? this.errorResponse.file_size_exceeded
            : this.errorResponse.invalid_file;

          this.alertError('', `${file.name}: ${errorType}`, 5000, 'TopCenterNotif');
          return;
        }
        // for not normal users, when uploaded one file
        // show an option to upload for own libray, elements or background assets.
        this.setShowDesignerElementUploadModal(true);
        // console.log('$event', $event.dataTransfer.files);
        // we must store the files only, as storing the whole event in state, makes the file disappear;
        this.handleTemporaryFileUpload = $event.dataTransfer.files;
        return;
      }
      this.doDropFile($event);
    },
    confirmDeleteHandler() {
      this.confirmDeleteUserFile();
      this.$root.$emit('delete-user-file', this.deleteItemId);
    },
    chooseElementUploadOption(uploadOption) {
      if (uploadOption === designerElementUploadOptions.OWN) {
        // console.log('$event2', this.handleTemporaryFileUpload);
        const $event = {
          dataTransfer: {
            files: this.handleTemporaryFileUpload,
          },
        };
        this.doDropFile($event);
        this.setShowDesignerElementUploadModal(false);
        return;
      }

      console.log(this.designerElementUploadUserFile);
      this.isImageScanning = true;

      Api.googleVisionFileUpload(this.designerElementUploadFile)
        .then((response) => {
          console.log(response);
          if (response.data.success && typeof response.data.labels !== 'undefined') {
            this.scannedImageTags = response.data.labels || [];
          } else {
            const { message } = response.data || 'Something went wrong.';
            this.alertError('', `Scanning image error.<br>${message}`, 5000, 'TopCenterNotif');
          }
        })
        .catch((error) => {
          console.error(error);
          let { message } = error.response.data || '';
          const { errors } = error.response.data;
          if (typeof errors !== 'undefined') {
            const errorValues = Object.values(errors);
            if (errorValues.length) {
              message += `<br>* ${errorValues.join('<br>* ')}`;
            }
          }
          this.alertError('', `Scanning image error.<br>${message}`, 5000, 'TopCenterNotif');
        })
        .then(() => {
          this.isImageScanning = false;
        });

      this.setSelectedDesignerElementUploadOption(uploadOption);
    },
    uploadDesignerFiles(formData) {
      this.isUploadingDesignerElement = true;
      AdminApi.uploadCanvasItems(
        this.selectedDesignerElementUploadOption,
        this.uploadCanvasItemFile,
        formData,
      )
        .then((response) => {
          console.log(response);
          const { message } = response.data || 'Something went wrong.';
          if (!response.data.success) {
            this.alertError('', message, 5000, 'TopCenterNotif');
            return;
          }

          this.setShowDesignerElementUploadModal(false);
          this.alertSuccess('', message, 5000, 'TopCenterNotif');
          // replace the old element in canvas
          if (this.hasElementUploadUserFile) {
            const activeElementById = this.getCanvasElementById(
              this.designerElementUploadUserFile.data.id,
            );
            console.log('activeElementById', activeElementById, response);

            const url = response.data.data.file;
            if (typeof url === 'undefined') {
              this.alertError(
                '',
                'Failed to convert user file to element pack, try again.',
                5000,
                'TopCenterNotif',
              );
              return;
            }

            this.replaceUserUploadWithCanvasAssets({
              id: activeElementById.data.id,
              thumb: response.data.data.preview_url || url,
              urlHd: url,
              url,
            });
            this.saveProject();
          } else {
            // insert new file
            this.doAddElementToCanvas(response.data.data);
          }
          this.isUploadingDesignerElement = false;
          this.setSelectedDesignerElementUploadOption('');
        })
        .catch((error) => {
          this.isUploadingDesignerElement = false;
          console.log(error);
          let { message } = error.response.data;
          const { errors } = error.response.data;
          const errorValues = Object.values(errors);
          if (errorValues.length) {
            message += `<br>* ${errorValues.join('<br>* ')}`;
          }
          this.alertError('', message, 5000, 'TopCenterNotif');
        });
    },
    updateTimelineTime(val) {
      this.timelineTime = val;
    },
    goToProjectDashboard() {
      const personalDashboardUrl = dashboardUrl();
      const teamDashboardUrl = `${dashboardUrl()}/team/${this.teamSlug}/projects`;
      const getUrl = this.isInTeamWorkspace ? teamDashboardUrl : personalDashboardUrl;

      window.location.href = getUrl;
    },
    screenMaximized(event) {
      // for desktop app to customize the topbar alignment
      this.isMaximized = event;
    },
  },
  beforeCreate() {
    // Check if page is reopened and force refresh if so
    // TODO: Experimental && Not supported with Safari
    const perfEntries = performance.getEntriesByType('navigation');
    let isReloaded = false;

    for (let i = 0; i < perfEntries.length; i += 1) {
      const p = perfEntries[i];
      if (p.type === 'reload') {
        isReloaded = true;
        break;
      }
    }

    if (window.history.state && window.history.state.isReopened && !isReloaded) {
      window.history.pushState({ isReopened: false }, '');
      window.location.href = window.location.href;
    } else {
      window.history.pushState({ isReopened: true }, '');
    }
  },
  watch: {
    canvasSectionPadding: {
      handler() {
        this.updateCanvasSectionPadding();
      },
      deep: true,
      immediate: true,
    },
    hasChanges: {
      handler() {
        if (this.isDesktopApp) {
          // will use native electron dialog instead
          return;
        }

        if (this.hasChanges) {
          if (typeof window.onbeforeunload !== 'function') {
            window.onbeforeunload = function saveConfirm() {
              return 'Save confirm';
            };
          }
        } else {
          window.onbeforeunload = '';
        }
      },
      deep: true,
    },
    getProjectDetails: {
      handler() {
        window.document.title = `${this.getProjectDetails.name} | OFFEO`;
      },
      deep: true,
    },
    bottomRightNotif() {
      const {
        title, message, duration, type,
      } = JSON.parse(JSON.stringify(this.bottomRightNotif));
      if (type === 'error') {
        this.alertError(title, message, duration);
      } else {
        this.alertSuccess(title, message, duration);
      }
    },
    savingTries(val) {
      if (val >= this.maxSavingTries) {
        this.alertError(
          '',
          'We are having problem connecting to our server, please refresh your page or try again later.',
          3000,
          'TopCenterNotif',
        );
      }
    },
    showLayer(val) {
      if (val) {
        document.body.classList.remove('no-layer-tab');
      } else {
        document.body.classList.add('no-layer-tab');
      }
    },
    showDesignerElementUploadModal(val) {
      console.log('showDesignerElementUploadModal', val);
      if (val) {
        this.isUploadingDesignerElement = false;
        // console.log('get setGeneralCategories');
        this.getGeneralCategories();
        this.getElementMenus();
        this.getBackgroundMenus();
      }
    },
  },
};

(() => {
  console.log(
    '%c%s',
    'color: #fff; background-color: #0C66FF; font-size: 35px; margin: 10px ;padding: 5px 10px',
    'OFFEO is awesomeeeee!',
  );
})();

/* eslint-disable */
if (isProduction()) {
  // in prod mode, disable all console log, table and group
  console.log = function () {};
  console.table = function () {};
  console.group = function () {};
  console.debug = function () {};
}
/* eslint-enable */
</script>

<style lang="scss">
@import '~normalize.css';
@import '@offeo/offeo-base-ui/src/assets/styles/globals.scss';
// @import '@offeo/offeo-base-ui/src/assets/icomoon/style.scss';
@import '~vue2-animate/src/sass/vue2-animate.scss';
@import '~vue-slick-carousel/dist/vue-slick-carousel.css';
@import '~@/assets/icomoon/style.scss';
@import '~@/assets/styles/darkModeVariables.scss';

html,
body {
  height: 100%;
}

.visuallyhidden,
.vh {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

body {
  background: #fff;
  font-weight: 500;

  // disable double tap zoom in mobile ios
  touch-action: manipulation;

  // no scroll class is added beforeMount
  // only for canvas and capture frame
  &.no-scroll {
    overflow: hidden;
  }

  * {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(230, 230, 230, 0.5);
    }

    &::-webkit-scrollbar-thumb {
      background: $timelineGrey;
      transition: background 0.25s ease-in-out;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: darken($timelineGrey, 5%);
    }
  }

  [data-title] {
    position: relative;
  }

  [data-title]:after {
    content: attr(data-title);
    position: absolute;
    font-size: 0.75rem;
    background-color: rgba($darkGrey800, 0.8);
    color: $light;
    padding: 5px 10px;
    border-radius: 4px;
    bottom: -3.2em;
    left: 0;
    white-space: nowrap;
    z-index: 9999;
    opacity: 0;
    font-family: $baseFont;
    visibility: hidden;
    display: flex;
    align-items: center;
    height: 16px;
    pointer-events: none;
  }

  [data-title]:hover:after {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out;
  }

  .crisp-client * {
    visibility: hidden !important;
    opacity: 0;
    pointer-events: none;
  }
}

button {
  border: 0;
  cursor: pointer;
  background: transparent;

  &:active,
  &:hover {
    outline: 0;
    background: inherit;
  }

  &:not(.btn--canvas) {
    &:focus {
      outline: 0;
      background: inherit;
    }
  }
}

#app {
  font-family: $baseFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $baseBlack;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

#main-header,
#main-wrapper {
  &.is-swapping,
  &.is-cropping {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $black;
      opacity: 0.7;
      z-index: 10;
    }
  }

  &.is-swapping {
    .assets-container {
      z-index: 12;
    }
  }

  &.is-cropping {
    .assets-container {
      z-index: 9;
    }
  }
}

#main-header {
  &.is-swapping {
    border-bottom: 0;
  }
}

#app {
  height: 100%;
}

body > div {
  height: auto;
}

#main-wrapper {
  position: relative;
  background: var(--lightgrey400-darkergrey200);
  height: calc(100vh - 54px);

  @include non-desktop {
    height: 100%;
  }

  &.is-swapping,
  &.is-cropping {
    .top-controls,
    .canvas-working-space {
      z-index: inherit;
    }
  }

  // What happen when assets sidebar is collapsed
  &.is-sidebar-collapsed:not(.has-extra-sidebar-opened) {
    .top-controls {
      padding-left: 76px;
    }

    .canvas-working-space {
      margin-left: 66px;
      width: calc(100% - 66px - 200px);
    }

    .bottom-bar,
    .timeline__content {
      padding-left: 0;
    }
  }

  // What happen when layer sidebar is collapsed
  &.is-layer-collapsed,
  .lite-mode & {
    .canvas-working-space {
      margin-right: 0;
      width: 100%;
    }

    .timeline {
      right: 0;
      width: calc(100% - 66px);
    }
  }

  // What happen when assets is colapsed and layer sidebar is not collapsed
  &.is-sidebar-collapsed:not(.has-extra-sidebar-opened):not(.is-layer-collapsed) {
    .canvas-working-space {
      width: calc(100% - 66px);
    }
  }

  // What happen when assets and layer sidebar is collapsed
  &.is-sidebar-collapsed.is-layer-collapsed:not(.has-extra-sidebar-opened) {
    .canvas-working-space {
      width: calc(100% - 66px);
    }
  }

  .canvas-section {
    height: 100%;
    width: 100%;
    transition: padding 0.25s ease-in-out;
  }
}

button,
input {
  border: 0;
}

.t--blue {
  color: $blue;
}

.t--bold {
  font-weight: bold;
}

.stepper-container {
  .input-stepper {
    width: 40px;
    text-align: center;
    color: $darkGrey;
  }

  .btn-stepper {
    align-items: center;
    background: transparent;
    border: 2px solid $darkGrey;
    border-radius: 50%;
    color: $darkGrey;
    cursor: pointer;
    display: inline-flex;
    font-size: 0.875em;
    font-weight: bold;
    height: 16px;
    line-height: 1;
    justify-content: center;
    transition: background 0.25s ease-in-out, color 0.25s ease-in-out;
    width: 16px;

    .icon {
      line-height: 0.5;
    }

    &:hover {
      background: $darkGrey;
      color: #fff;
    }

    &:disabled,
    &:disabled:hover {
      cursor: context-menu;
      color: $darkGrey;
      border-color: $darkGrey;
      background: transparent;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
}

.col-xs-auto {
  max-width: 100%;
  flex-basis: 0px;
  flex-grow: 1;
}

.col-xs-3 {
  width: 25%;
}

.text-xs-right {
  text-align: right;
}

.tab-2 {
  background: $lightBg;

  .tab__head {
    background: $lightBg;
    padding: 0 10px;
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    justify-content: center;
    user-select: none;

    .tab__item {
      color: $grey;
      cursor: pointer;
      flex: 1 1 100%;
      height: 100%;
      line-height: 1;
      min-height: 32px;
      padding: 18px 5px;
      border: 0;
      border-radius: 0;
      border-bottom: 2px solid transparent;
      width: 50%;
      font-size: 1em;
      font-weight: 500;
      background: $lightBg;
      text-transform: capitalize;
      align-items: center;
      justify-content: center;
      display: flex;

      &:hover {
        color: $darkGrey;
        background: $lightBg;
      }

      &.is-active {
        color: $darkGrey;
        cursor: context-menu;
        border-bottom: 2px solid $blue;
      }

      &.has-icon {
        .icon {
          color: $yellow;
          margin-right: 10px;
        }
      }
    }
  }
}

.file-upload-modal,
.file-upload-processing-modal {
  .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    text-align: center;

    .modal-title {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 30px;
    }
  }
}

.multiple-window__warning-modal {
  .dialog-modal__closebtn {
    opacity: 0;
    visibility: hidden;
  }

  .dialog-modal__buttons .btn {
    max-width: 165px;
  }
}

select {
  border: 1px solid $defaultBorder;
  border-radius: $componentBorderRadius;
  padding: 8px;
  font-size: 0.875em;
}

.text-break-point {
  @include smallest {
    &.text-break-point {
      .text-show {
        display: inherit;

        &.text-capitalize {
          text-transform: capitalize;
        }
      }
      .text-hide {
        display: none;
      }
    }
  }
}

.drr-stick-cursor {
  position: absolute;
  left: var(--mouse-x);
  top: var(--mouse-y);
  display: none;
  z-index: 99999999; // make sure on top of everything
}

#app.layer-is-rotating {
  cursor: none;

  .drr-stick-cursor {
    display: block;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
  animation-duration: 0.35s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.project-name--simple {
  display: inline-block;
  font-size: 0.875rem;
  color: $lightWhite;
  margin-right: auto;
  margin-left: 10px;
  font-weight: bold;
  padding: 10px;
}

.no-mobile {
  @include mobile() {
    display: none;
  }
}

.app-canvas {
  &.desktop-app {
    margin-top: 30px;

    &.is-maximized {
      margin-top: 23px;

      .storyboard-overlay {
        margin-top: 23px;
      }
    }

    .assets-navigation {
      .nav-item__user {
        margin-bottom: 30px;
      }
    }

    .storyboard-overlay {
      margin-top: 30px;
    }

  }

  #desktop-title-bar {
    &.is-maximized {
      height: 23px;
    }

    .buttons {
      button {
        .icon {
          font-size: 14px;
          &.obui-icon-chevron-down {
            font-size: 25px;
          }
        }
      }
    }
  }
}

</style>

<style lang="scss" scoped>
.drop-here {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  z-index: 999;
  pointer-events: unset;
}
#has-changes {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 1px;
  height: 1px;
  padding: 0;
}
</style>
