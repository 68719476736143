<template lang="pug">
.branded-style(
  :class="{ 'no-brand': !getIsTemplate && !hasSetupBrand }"
)
  .branded-style__container(v-show="!isRequestingApi")
    BrandedAssetContainerDesigner(
      v-if="getIsTemplate"
      @api-requested="isRequestingApi = false"
    )
    BrandedAssetContainerUser(
      v-else
      @api-requested="isRequestingApi = false"
    )
  template(v-if="isRequestingApi")
    BasePreloader(
      :type=8
    )
</template>

<script>
import { mapGetters } from 'vuex';

import BrandedAssetContainerDesigner from '@/components/ProjectCanvas/AssetsSidebar/children/BrandedAssetContainerDesigner.vue';
import BrandedAssetContainerUser from '@/components/ProjectCanvas/AssetsSidebar/children/BrandedAssetContainerUser.vue';

export default {
  name: 'BrandedAssetContainer',
  data() {
    return {
      isRequestingApi: true,
    };
  },
  components: {
    BrandedAssetContainerDesigner,
    BrandedAssetContainerUser,
  },
  computed: {
    ...mapGetters([
      'getIsTemplate',
    ]),
    ...mapGetters('brand', [
      'hasSetupBrand',
    ]),
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style lang="scss">
.branded-style {
  flex-direction: column;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background-color: rgba(230, 230, 230, 0);
    transition: background-color 0.5s ease-in-out;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($timelineGrey, 0);
    transition: background-color 0.5s ease-in-out;
  }

  &:hover::-webkit-scrollbar-track {
    background-color: rgba(230, 230, 230, 0.1);
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: $timelineGrey;
  }

  &.no-brand {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .branded-style__container {
      padding-right: 0;
    }
  }

  .branded-style__container {
    padding-right: 5%;
  }

  .branded-style__group {
    margin-bottom: 20px;
  }
}
</style>
