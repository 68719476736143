<template lang="pug">
.scenes--item--container(
  :class="{'is-last' : index === getScenes.length - 1}"
  :style="containerStyle"
  @mouseenter="addToSelectedScenes"
  @mouseleave="removeSelectedScenes"
)
  .scenes__info
    span.left.scene-title {{ sceneNumber }}
    .right(
      v-if="isActive"
    )
      span.scenes__current
        | Currently editing
  .scenes--item(
    :style="sceneItemStyle"
    :class="{ 'is-active' : isActive, 'is-selected' : isSelected, 'is-dark': isDarkMode }"
  )
    .scenes__preview(
      :style="scenePreviewStyle"
      @click="editScene"
    )
      .preview(
        :class="{'story-board-image-preview' : !this.isActive, 'is-generating-preview' : !scenePreviewUrl}"
      )
        CanvasScene(
          v-if="isActive"
          :elements="getElements"
          sceneClass="scenes__canvas"
          :scale="sceneScale"
          :background="scene.background"
          isScenePreview=true
          animationTimeline="sceneTimeline"
          :showMidScene="true"
          :duration="scene.duration"
          :id="scene.id"
        )

        StoryBoardPreview(
          v-else
          :width="String(storyBoardPreviewSize.width)"
          :height="String(storyBoardPreviewSize.height)"
          :sceneId="scene.id"
          :scenePreviewUrl="scenePreviewUrl"
        )

    .scenes--controls
      .scenes__tags(
        v-if="isDesigner"
      )
        HeroSceneTag(
          v-for="tag in heroSceneTags"
          :key="tag.value"
          :value="tag.value"
          :name="tag.name"
          :isHeroSceneWithBg="isHeroSceneWithBg"
          :isHeroSceneWithoutBg="isHeroSceneWithoutBg"
          @click="checkHeroSceneTag(tag.value)"
        )
      .scenes__info.info--bottom
        span.left
          button.btn--scenes__duration-controls.icon.icon-minus(
            type="button"
            @click="decreaseSceneDuration"
          )
          input.text(
            autocomplete="off"
            type="text"
            ref="sceneDuration"
            v-model.lazy="sceneDuration"
            @keypress="checkIfNumber($event)"
            @focus="focusDuration"
          )
          button.btn--scenes__duration-controls.icon.icon-plus(
            type="button"
            @click="increaseSceneDuration"
          )
        span.right.scenes__buttons
          button.btn-hover.btn-scene-action(
            title='Duplicate scene'
            type='button'
            @click="duplicateScene"
          )
            i.icon.icon-copy-line
          button.btn-hover.btn-scene-action.btn-scene--delete(
            title='Delete scene'
            type='button'
            :style="deleteStyleObject"
            @click="showDeleteWarning"
          )
            i.icon.icon-delete
      .scenes__lower(
        v-if="isDesigner && !isTextTemplate"
      )
        .scenecategory(
          :class="{ 'is-active': isSceneCategoryOpened }"
          v-click-outside="onClickOutsideCategory"
        )
          span.scenecategory__input.btn.btn--hollow(
            @click="isSceneCategoryOpened = !isSceneCategoryOpened"
          ) {{ selectedSceneCategoryName }}
          .scenecategory__list
            div.option(
              v-for="category in getModularCategoriesWithId"
              :key="category.id"
              v-html="category.name"
              @click="updateSelectedSceneCategory(category)"
              :class="{ 'is-selected': isSelectedSceneCategory(category) }"
            )
  .btn-scene-transition-wrapper(
    :style="sceneTransitionWrapperStyle"
  )
    button.btn-scene-transition(
      type='button'
      @click="openTransitionList()"
      ref="sceneTransition"
      :class="{ 'has-transition': hasTransition, 'is-selected': isSelectedTransition }"
    )
      template(v-if="currentTransition.value && currentTransition.value !== 'none'")
        span.text(v-html="transitionText")
      template(v-else)
        i.icon.icon-plus

  template(v-if="showConfirmRemoveHeroSceneTag")
    BaseDialog(
      isShow=true
      isDanger=true
      button1Text="Remove Tag"
      :text="confirmRemoveHeroSceneTagText"
      @confirm="setHeroSceneTag(heroTag.value, false)"
      @closeOverlay="showConfirmRemoveHeroSceneTag=false"
    )
  template(v-if="showConfirmOverwriteHeroSceneWithBgTag")
    BaseDialog(
      isShow=true
      isDanger=true
      button1Text="Overwrite Tag"
      :text="confirmOverwriteHeroSceneTagWithBgText"
      @confirm="setHeroSceneTag(overwriteKey, true)"
      @closeOverlay="showConfirmOverwriteHeroSceneWithBgTag=false"
    )
  template(v-if="showConfirmOverwriteHeroSceneWithoutBgTag")
    BaseDialog(
      isShow=true
      isDanger=true
      button1Text="Overwrite Tag"
      :text="confirmOverwriteHeroSceneTagWithoutBgText"
      @confirm="setHeroSceneTag(overwriteKey, true)"
      @closeOverlay="showConfirmOverwriteHeroSceneWithoutBgTag=false"
    )
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import vClickOutside from 'v-click-outside';
import { randomId, isEmptyObject, isNumber } from '@/assets/scripts/utilities';
import storyboardMixin from '@/components/mixins/storyboard-mixins';
import helperMixins from '@/components/mixins/helper-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

import CanvasScene from '@/components/ProjectCanvas/CanvasContainer/children/CanvasScene.vue';
import HeroSceneTag from '@/components/ProjectCanvas/PageOverlay/children/HeroSceneTag.vue';
import ProjectApi from '@/services/api/ProjectApi';
import TemplateApi from '@/services/api/TemplateApi';
import StoryBoardPreview from './StoryBoardPreview.vue';

export default {
  name: 'SceneItem',
  components: {
    CanvasScene,
    HeroSceneTag,
    StoryBoardPreview,
  },
  mixins: [helperMixins, darkModeMixin, storyboardMixin],
  data() {
    return {
      transitionText: '',
      showWarning: false,
      isSceneCategoryOpened: false,
      showConfirmRemoveHeroSceneTag: false,
      showConfirmOverwriteHeroSceneWithBgTag: false,
      showConfirmOverwriteHeroSceneWithoutBgTag: false,
      overwriteKey: '',
      heroSceneTagsList: [
        {
          name: 'Hero w Mask',
          value: 'hero-w-bg',
        },
        {
          name: 'Hero w/o Mask',
          value: 'hero-wo-bg',
        },
      ],
      isHeroSceneWithBg: false,
      isHeroSceneWithoutBg: false,
      lastSelectedScene: null,
      isSelected: false,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    id: { type: Number, default: 0 },
    index: { type: Number, default: 0 },
    duration: { type: Number, default: 0 },
    scene: { type: Object, default: () => ({}) },
    transition: { type: Object, default: () => ({}) },
    leftoverDuration: { type: Number, default: 0 },
    sceneWidth: { type: Number, default: 0 },
    is_hero_w_bg: { type: Boolean, default: false },
    is_hero_wo_bg: { type: Boolean, default: false },
    selectedScenes: { type: Array, default: () => [] },
  },
  computed: {
    ...mapState(['transitionList', 'isTextTemplate']),
    ...mapState('canvasElements', [
      'elementsList',
      'scenesList',
      'activeSceneId',
      'storyBoardPreviews',
    ]),
    ...mapGetters(['getIsTemplate', 'getActiveAnimationList']),
    ...mapGetters('userData', ['isDesigner']),
    ...mapGetters('canvasElements', [
      'getCanvasSize',
      'getScenes',
      'getScenesElementsById',
      'getActiveSceneId',
      'getScenesIndexById',
      'getProjectId',
      'getModularCategories',
      'getCurrentSceneDuration',
      'getHeroSceneWithBg',
      'getHeroSceneWithoutBg',
      'getProjectDetails',
    ]),
    currentTransition() {
      return this.scene.transition;
    },
    isActive() {
      return this.id === this.getActiveSceneId;
    },
    isSelectedTransition() {
      if (
        this.getActiveAnimationList.id[0] === this.scene.id
        && this.getActiveAnimationList.position.position === 'left'
      ) return true;
      return false;
    },
    getElements() {
      return [...this.getScenesElementsById(this.scene.id)].reverse();
    },
    getModularCategoriesWithId() {
      return this.getModularCategories.filter(cat => !!cat.id);
    },
    sceneScale() {
      return this.sceneWidth / this.getCanvasSize.width;
    },
    scenePreviewStyle() {
      const styleObject = {};
      const width = this.sceneWidth;
      const height = (width / this.getCanvasSize.width) * this.getCanvasSize.height;

      styleObject.width = `${width}px`;
      styleObject.height = `${height}px`;

      return styleObject;
    },
    sceneItemStyle() {
      const styleObject = {};

      styleObject.width = `${this.sceneWidth}px`;

      return styleObject;
    },
    containerStyle() {
      const styleObject = {};

      styleObject.width = `${this.sceneWidth}px`;

      return styleObject;
    },
    sceneTransitionWrapperStyle() {
      const styleObject = {};
      const width = (this.sceneWidth / this.getCanvasSize.width) * this.getCanvasSize.height;

      styleObject.width = `${width}px`;

      return styleObject;
    },
    deleteStyleObject() {
      const styleObject = {};

      if (this.getScenes.length <= 1) {
        styleObject.display = 'none';
      }

      return styleObject;
    },
    sceneNumber() {
      return this.index + 1;
    },
    sceneDuration: {
      get() {
        const { duration } = this.scene;
        return `${duration.toFixed(1)}s`;
      },
      set(value) {
        this.$emit('updateDuration', parseInt(value, 10), this.lastSelectedScene);
      },
    },
    isMax() {
      if (this.leftoverDuration < this.scene.duration) return true;
      return false;
    },
    selectedSceneCategoryName() {
      const categoryId = this.scene.modular_category_id;
      const sceneObj = categoryId > 0 ? this.getModularCategories.find(o => o.id === categoryId) : '';
      if (sceneObj && sceneObj.name) return sceneObj.name;
      return '';
    },
    heroTag() {
      return {
        name: this.isHeroSceneWithBg ? this.heroSceneTags[0].name : this.heroSceneTags[1].name,
        value: this.isHeroSceneWithBg ? this.heroSceneTags[0].value : this.heroSceneTags[1].value,
      };
    },
    confirmRemoveHeroSceneTagText() {
      return `You are attempting to remove ${this.heroTag.name} tag, which is a compulsory component. Do you want to continue removing it?`;
    },
    confirmOverwriteHeroSceneTagWithBgText() {
      const heroSceneTagsName = this.heroSceneTags.length > 1 ? this.heroSceneTags[1].name : this.heroSceneTags[0].name;
      return `${this.getHeroSceneWithBg.categoryName} is currently the ${heroSceneTagsName}.<br/>Do you want to overwrite it?`;
    },
    confirmOverwriteHeroSceneTagWithoutBgText() {
      const heroSceneTagsName = this.heroSceneTags.length > 1 ? this.heroSceneTags[1].name : this.heroSceneTags[0].name;
      return `${this.getHeroSceneWithoutBg.categoryName} is currently the ${heroSceneTagsName}.<br/>Do you want to overwrite it?`;
    },
    hasTransition() {
      return (this.currentTransition.value && this.currentTransition.value !== 'none') || false;
    },
    heroSceneTags() {
      // Check if logo template
      if (this.isDesigner && this.getProjectDetails.isLogo) {
        return this.heroSceneTagsList.filter(obj => obj.value !== 'hero-w-bg');
      }

      return this.heroSceneTagsList;
    },
    storyBoardPreviewSize() {
      const width = this.sceneWidth;
      const height = (width / this.getCanvasSize.width) * this.getCanvasSize.height;

      return { width: `${width}px`, height: `${height}px` };
    },
    scenePreviewUrl() {
      return this.storyBoardPreviews[this.scene.id] !== 'undefined'
        ? this.storyBoardPreviews[this.scene.id]
        : '';
    },
  },
  methods: {
    ...mapMutations([
      'setActiveAnimationList',
      'setThirtySecondsWarning',
      'setIsShowAnimationModal',
    ]),
    ...mapMutations('canvasElements', [
      'updateActiveSceneId',
      'deleteSceneFromArray',
      'addSceneToArray',
      'updateShowScenesOverlay',
      'updateHeroSceneWithBg',
      'updateHeroSceneWithoutBg',
      'setStoryBoardPreviews',
    ]),
    ...mapActions('canvasElements', ['saveProject', 'saveProjectScene', 'getStoryBoardPreview']),
    ...mapActions(['updateShowStoryboardOverlay']),
    checkIfNumber($event) {
      return isNumber($event);
    },
    increaseSceneDuration() {
      this.$emit('updateDuration', this.duration + 1);
    },
    decreaseSceneDuration() {
      this.$emit('updateDuration', this.duration - 1);
    },
    openTransitionList() {
      const position = this.$refs.sceneTransition.getBoundingClientRect();
      if (this.hasTransition) {
        position.y += -160;
      } else {
        position.y += -25;
      }

      this.setIsShowAnimationModal(true);
      this.setActiveAnimationList({
        type: 'transition',
        id: this.scene.id,
        position,
        animationPosition: 'left',
        hasTransition: this.hasTransition,
      });
    },
    editScene() {
      const currentSceneId = this.getActiveSceneId;
      const newSceneId = this.scene.id;

      if (newSceneId === currentSceneId) {
        this.updateShowStoryboardOverlay(false);
        return;
      }

      this.saveProject(); // save scene before moving to new scene

      this.resetStoryBoardPreview(currentSceneId);

      this.updateActiveSceneId(newSceneId);
      this.updateShowStoryboardOverlay(false);
    },
    deleteScene() {
      const sceneId = this.scene.id;
      const projectId = this.getProjectId;

      this.updateShowScenesOverlay(true);

      // clear old preview
      this.setStoryBoardPreviews({
        sceneId,
        base64: '',
      });

      const APIREQUEST = this.getIsTemplate ? TemplateApi : ProjectApi;

      APIREQUEST.deleteProjectScene(projectId, sceneId)
        .then((response) => {
          // console.log('completed', response.data);

          if (response.data.success) {
            // if current one is active scene, go to the next scene or prev
            if (sceneId === this.getActiveSceneId) {
              const deletedSceneindex = this.getScenesIndexById(sceneId);

              let newSceneIndex = 0;

              // if the deleted one is last scene
              if (deletedSceneindex === this.getScenes.length - 1) {
                newSceneIndex = this.getScenes.length - 2;
              } else {
                newSceneIndex = this.index + 1;
              }

              const newSceneId = this.getScenes[newSceneIndex].id;
              this.updateActiveSceneId(newSceneId);
            }

            this.deleteSceneFromArray(sceneId);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.updateShowScenesOverlay(false);
        });
    },
    duplicateScene() {
      if (this.isMax) {
        this.setThirtySecondsWarning(true);
        return;
      }

      if (this.getScenes.length >= process.env.VUE_APP_MAX_SCENES) {
        this.$emit('show-max-scene-warning');
        return;
      }

      const sceneId = this.scene.id;

      // save before duplicate
      this.saveProjectScene({ sceneId });

      const projectId = this.getProjectId;
      const elements = JSON.parse(JSON.stringify(this.getScenesElementsById(sceneId)));

      // get all elements scenes and generate new data.ids
      elements.forEach((element) => {
        /* eslint-disable */
        if (typeof element.element_id !== 'undefined') delete element.element_id;
        element.data.id = randomId();
        /* eslint-enable */
      });

      console.log('dup elements', elements, this.scene.background);

      const params = {
        scene_elements: elements,
        background: JSON.parse(JSON.stringify(this.scene.background)),
        duration: this.scene.duration,
        transition: JSON.parse(JSON.stringify(this.transition)),
        after_scene_id: sceneId,
      };

      const maxNewSceneDuration = this.getMaxNewSceneDuration;

      this.updateShowScenesOverlay(true);
      if (params.duration > maxNewSceneDuration) params.duration = Math.round(maxNewSceneDuration * 10) / 10;

      const APIREQUEST = this.getIsTemplate ? TemplateApi : ProjectApi;

      APIREQUEST.addProjectScene(projectId, params)
        .then((response) => {
          console.log('completed', response.data);
          if (response.data.success) {
            const result = response.data.results;

            let canvasBackground = result.background;
            if (canvasBackground.color) {
              canvasBackground = canvasBackground.color;
            } else if (canvasBackground.scalar) {
              canvasBackground = canvasBackground.scalar;
            }

            this.resetStoryBoardPreview(this.getActiveSceneId);
            this.addSceneToArray({
              afterSceneId: sceneId,
              newId: result.id,
              background: canvasBackground,
              duration: result.duration,
              elements: result.elements,
              transition: result.transition,
            });
            this.updateActiveSceneId(result.id);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.updateShowScenesOverlay(false);
        });
    },
    getTransitionText() {
      const transitionObject = this.transitionList[0].list.find(
        obj => obj.value === this.currentTransition.value,
      );
      return transitionObject;
    },
    showDeleteWarning() {
      this.$root.$emit('show-delete-scene-warning', this.deleteScene);
    },
    onClickOutsideCategory() {
      this.isSceneCategoryOpened = false;
    },
    updateSelectedSceneCategory(category) {
      this.scene.modular_category_id = category.id;

      const params = {
        duration: this.getCurrentSceneDuration,
        modular_category_id: category.id ? category.id : null,
      };
      TemplateApi.updateProjectScene(this.getProjectId, this.id, params)
        .then((response) => {
          console.log('update scene', response);

          if (!response.data.success) {
            this.scene.modular_category_id = response.data.new_scene_details.modular_category_id;
            this.alertError(
              '',
              'Oops, something went wrong. Try again later.',
              2000,
              'TopCenterNotif',
            );
          } else {
            const successMsg = typeof category.name !== 'undefined'
              ? `Scene category updated to ${category.name}`
              : 'Scene category removed';
            this.alertSuccess('', successMsg, 2000, 'TopCenterNotif');
          }
        })
        .catch((error) => {
          console.error(error);
        });

      this.isSceneCategoryOpened = false;
    },
    isSelectedSceneCategory(category) {
      return this.scene.modular_category_id === category.id;
    },
    setHeroSceneTag(key, value) {
      // Clear key's hero
      const heroKey = `is_${key.replace(/-/g, '_')}`;

      if (key === 'hero-w-bg') {
        this.isHeroSceneWithBg = value;
      } else if (key === 'hero-wo-bg') {
        this.isHeroSceneWithoutBg = value;
      }

      console.log('key', key, this.isHeroSceneWithBg, this.isHeroSceneWithoutBg);

      if (!value) {
        this.showConfirmRemoveHeroSceneTag = false;
      } else if (this.overwriteKey !== '') {
        this.overwriteKey = '';
        this.showConfirmOverwriteHeroSceneWithBgTag = false;
        this.showConfirmOverwriteHeroSceneWithoutBgTag = false;
      }

      const params = {
        duration: this.getCurrentSceneDuration,
      };
      params[heroKey] = value;

      TemplateApi.updateProjectScene(this.getProjectId, this.id, params)
        .then((response) => {
          if (!response.data.success) {
            if (key === 'hero-w-bg') {
              this.isHeroSceneWithBg = !value;
            } else if (key === 'hero-wo-bg') {
              this.isHeroSceneWithoutBg = !value;
            }
            this.alertError(
              '',
              'Oops, something went wrong. Try again later.',
              2000,
              'TopCenterNotif',
            );
          } else {
            this.updateChosenHeroScene(key);
            const successMsg = `Hero scene ${this.getBgType(key)} background ${
              value ? 'updated' : 'removed'
            }`;
            this.alertSuccess('', successMsg, 2000, 'TopCenterNotif');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getBgType(key) {
      if (key === 'hero-w-bg') {
        return 'with';
      }
      return 'without';
    },
    checkHeroSceneTag(key) {
      console.log('checkHeroSceneTag', key, this.getHeroSceneWithBg, this.getHeroSceneWithoutBg);
      if (key === 'hero-w-bg' && !isEmptyObject(this.getHeroSceneWithBg)) {
        // Check if empty
        this.overwriteKey = key;
        this.showConfirmOverwriteHeroSceneWithBgTag = true;
      } else if (key === 'hero-wo-bg' && !isEmptyObject(this.getHeroSceneWithoutBg)) {
        // Check if empty
        this.overwriteKey = key;
        this.showConfirmOverwriteHeroSceneWithoutBgTag = true;
      } else {
        this.setHeroSceneTag(key, true);
      }
    },
    updateChosenHeroScene(value) {
      const sceneParams = {
        id: this.id,
        categoryName: this.selectedSceneCategoryName,
      };
      if (value === 'hero-w-bg') {
        this.updateHeroSceneWithBg(sceneParams);
      } else if (value === 'hero-wo-bg') {
        this.updateHeroSceneWithoutBg(sceneParams);
      }
    },
    addToSelectedScenes() {
      const scenes = [this.id];

      this.$emit('select', scenes);
    },
    removeSelectedScenes() {
      this.$emit('select', []);
    },
    checkIsSelected() {
      if (this.selectedScenes.indexOf(this.id) >= 0) this.isSelected = true;
      else this.isSelected = false;
    },
    resetStoryBoardPreview(currentSceneId) {
      // clear old preview
      this.setStoryBoardPreviews({
        sceneId: currentSceneId,
        base64: '',
      });

      // use websocket
      this.wsEventGenerateStoryboardThumbnail(this.getProjectId, this.getIsTemplate, currentSceneId);

      // generate last preview image when switching to another scene
      // this.getStoryBoardPreview({
      //   id: this.getProjectId,
      //   sceneId: currentSceneId,
      //   isTemplate: this.getIsTemplate,
      // });
    },
    focusDuration() {
      this.$refs.sceneDuration.select();
    },
  },
  mounted() {
    if (this.currentTransition.value && this.currentTransition.value !== 'none') {
      this.transitionText = this.getTransitionText().shortName;
    }

    if (this.isDesigner && this.id) {
      this.isHeroSceneWithBg = this.is_hero_w_bg;
      this.isHeroSceneWithoutBg = this.is_hero_wo_bg;
    }

    this.checkIsSelected();
  },
  watch: {
    is_hero_w_bg(val) {
      this.isHeroSceneWithBg = val;
    },
    is_hero_wo_bg(val) {
      this.isHeroSceneWithoutBg = val;
    },
    getActiveSceneId() {
      const canvasWrapper = document.getElementsByClassName('canvas-wrapper')[0];
      setTimeout(() => {
        canvasWrapper.classList.remove('move-down');
        canvasWrapper.classList.remove('move-up');
      }, 250);
    },
    selectedScenes() {
      this.checkIsSelected();
    },
    // scene: {
    //   handler(val) {
    //     console.log('scene is changed', val)
    //   },
    //   deep: true,
    // },
    currentTransition: {
      handler(val) {
        // console.log('transition is changed', val);
        if (val.value && val.value !== 'none') {
          this.transitionText = this.getTransitionText().shortName;
        }
      },
      deep: true,
    },
    activeSceneId(val) {
      // console.log('activeSceneId', val);
      // this watcher is from switching another by not editing a scene
      this.resetStoryBoardPreview(val);
    },
    isSelected(val) {
      if (!val) {
        if (this.sceneDuration !== this.$refs.sceneDuration.value) {
          this.$refs.sceneDuration.blur();
        }
        this.lastSelectedScene = null;
      } else {
        this.lastSelectedScene = this.scene.id;
      }
    },
  },
};
</script>
<style lang="scss">
.scenes--item {
  .drr {
    cursor: default;
  }

  .scenes__preview {
    .preview {
      .scene__element {
        * {
          pointer-events: none !important;
        }
      }
    }
  }

  .preloader__text {
    color: $light;
  }

  .element__loader {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.scenes--item--container {
  display: inline-block;
  margin: 10px 13px;
  vertical-align: top;
  position: relative;

  &:not(.is-last) {
    margin-right: 55px;
  }

  &.is-last {
    .btn-scene-transition {
      display: none;
    }
  }
}

.scenes--controls {
  position: relative;
  transition: height 0.25s ease-in-out;
  background: var(--canvasbg-darkgrey600);
}

.scenes__preview {
  min-width: 140px;
  position: relative;
  z-index: 2;
  border: unset;
  border-radius: unset;
  overflow: hidden;
  cursor: pointer;

  .scene {
    position: absolute;
    top: 0;
    left: 0;
  }

  .drr.active {
    &::before {
      display: none;
    }
  }
}

.scenes--item {
  text-align: center;
  position: relative;
  overflow: visible;
  border-radius: $componentBorderRadius;
  transition: border 0.25s ease-in-out;
  box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
  transition: box-shadow 0.25s ease-in-out;
  cursor: pointer;

  &:not(.is-dark) {
    &.is-active {
      &::after {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: 100%;
        height: 100%;
        pointer-events: none;
        border: 2px solid $blue;
        border-radius: 3px;
      }
    }
  }

  &:hover {
    box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
  }

  .btn-hover {
    border-radius: $componentBorderRadius;
    width: 26px;
    height: 26px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &.is-active {
      background: $borderGrey;
    }
  }

  &:last-child {
    .btn-scene-transition {
      display: none;
    }
  }
}

.btn--scenes__duration-controls {
  color: var(--darkgrey-white);
}

.scenes__canvas {
  overflow: hidden;
  background: $light;
  position: relative;
  display: inline-block;
  user-select: none;
  transform-origin: top left;
  left: 0;
  top: 0;

  & * {
    pointer-events: none !important;
  }
}

.scenes__info {
  display: flex;
  justify-content: space-between;
  font-size: 0.875em;
  margin: 3px 0 15px;
  align-items: center;

  &.info--bottom {
    margin: 0 0 5px 0;
    padding: 12px 5px;

    .text {
      color: var(--darkgrey-white);
    }
  }

  .text {
    margin: 0 4px;
    cursor: auto;
  }

  input.text {
    background-color: transparent;
    width: 40px;
    text-align: center;
    outline: none;

    &:focus {
      border-bottom: 1px solid;
    }
  }

  .scene-title {
    color: $light;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: var(--blue600-darkblue4);
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left {
    margin-right: auto;
  }

  .right {
    margin-left: auto;

    .scenes__current {
      background: var(--lightergreen-green600);
      color: var(--blue-white);
      border-radius: 20px;
      width: 100%;
      padding: 8px 24px;
      font-size: 0.8125rem;
    }
  }
}

.btn-scene-transition-wrapper {
  position: absolute;
  top: 50px;
  right: -54px;
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  transform: translateX(-20px) rotate(-90deg);
  transform-origin: center right;
  margin-top: -20px;

  .btn-scene-transition {
    background: transparent;
    color: var(--blue-white);
    cursor: pointer;
    line-height: 1;
    padding: 8px;
    transition: background 0.25s ease-in-out, color 0.25s ease-in-out,
      border-color 0.25s ease-in-out;
    border: 1px solid var(--blue-white);
    border-radius: $componentBorderRadius;
    min-height: 38px;
    display: flex;
    justify-content: center;

    &:hover {
      .icon,
      .text {
        animation: swing 0.5s infinite;
        transform-origin: top center;
      }
    }

    .icon {
      font-size: 20px;
    }

    &:hover,
    &:focus,
    &.is-selected {
      background: var(--btnprimaryhollowhover-darkgrey900);
      border-color: var(--blue-darkgrey900);
      color: var(--blue-white);
    }

    &.has-transition {
      width: 60%;
      font-size: 0.875em;
      padding: 15px 8px;

      .text {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: -7px;
      }
    }
  }
}

.scenes__buttons {
  display: flex;
  align-items: center;
  transition: transform 0.15s ease-in-out;

  .btn-scene-action {
    line-height: 1;
    font-weight: 400;
    z-index: 3;
    color: var(--darkgrey-white);
    margin-right: 4px;
    padding: 0;
    font-size: 1.25rem;

    &:nth-child(2) {
      top: 40px;
    }

    &:hover {
      background: var(--lightgrey400-darkgrey700);
    }
  }
}

.scenes__tags {
  z-index: 3;
  display: flex;
  position: absolute;
  width: 100%;
  padding: 5px 0;
  left: 0;
  top: 0;
  transform: translateY(-100%);
  overflow: hidden;

  .btn.hero-scene-tag {
    margin: 2px;
    font-size: 0.7em;
  }

  .scenes--item:hover & {
    .btn.hero-scene-tag {
      transform: translateY(0);
    }
  }
}

.scenes__lower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px;
}

.scenecategory {
  position: relative;
  max-width: 100%;
  width: calc(100% - 62px);
  z-index: 4;
  margin: 4px 0 10px;

  &.is-active {
    .scenecategory__button {
      transform: rotate(180deg);
    }

    .scenecategory__list {
      pointer-events: auto;
      opacity: 1;
      visibility: visible;
      transform: translateY(-100%);
    }

    .scenecategory__input {
      &::before {
        content: $icon-up-arrow;
      }
    }

    & + .fontfamily {
      pointer-events: none;
      opacity: 0.7;
      &::after {
        opacity: 0.8;
      }
    }
  }

  .scenecategory__list {
    padding: 0;
  }

  .scenecategory__input {
    display: block;
    cursor: pointer;
    padding: 6px 10px;
    padding-right: 20px;
    text-transform: capitalize;
    position: relative;
    height: 30px;
    text-align: left;
    background: #fff;
    border-color: $defaultBorder;

    &::before {
      content: $icon-down-arrow;
      font-family: 'icomoon';
      position: absolute;
      right: 4px;
      top: 6px;
      font-size: 1.25em;
    }
  }

  .scenecategory__list {
    position: absolute;
    z-index: 4;
    width: 100%;
    max-width: 179px;
    border-top: 1px solid $lightGrey;
    background: #fff;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-102%);
    margin-top: -33px;
    box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;

    .option {
      padding: 10px;
      cursor: pointer;
      display: block;
      text-align: left;
      text-transform: capitalize;
      min-height: 30px;
      font-size: 0.75em;

      &.has-image {
        padding: 10px;
      }

      &.is-selected::after,
      &:hover::after {
        opacity: 0.1;
      }

      &.is-selected {
        &::after {
          background: $blue;
        }
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background: $darkGrey;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
      }
    }
  }
}

.fade-storyboard-enter-active,
.fade-storyboard-leave-active {
  transition: opacity 0.25s;
  animation-duration: 0.35s;
}
.fade-storyboard-enter,
.fade-storyboard-leave-to {
  opacity: 0;
}

.preview {
  &.story-board-image-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &.is-generating-preview {
    background: $grey;
  }
}

@keyframes swing {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }

  25% {
    -webkit-transform: rotateZ(-5deg);
    transform: rotateZ(-5deg);
  }

  75% {
    -webkit-transform: rotateZ(5deg);
    transform: rotateZ(5deg);
  }

  100% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
}
</style>
