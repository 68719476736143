<template lang="pug">
.swap-scene(
  :class="{ 'is-active': isActive, 'is-empty': imageOrMasksElements.length === 0 }"
)
  template(v-if="imageOrMasksElements.length")
    template(v-for="id in imageOrMasksElements")
      SwapSceneImage(
        :elementId="id"
      )
  .empty-scene(v-else)
    p.empty-scene__text No images found in this scene.
</template>

<script>
import { mapGetters } from 'vuex';
import SwapSceneImage from '@/components/ProjectCanvas/PageOverlay/children/SwapSceneImage.vue';

export default {
  name: 'SwapScene',
  components: {
    SwapSceneImage,
  },
  props: {
    scene: { type: Object, default: () => ({}) },
    index: { type: Number, default: 0 },
    isActive: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('canvasElements', [
      'getCanvasElementById',
    ]),
    imageOrMasksElements() {
      const elements = [];
      // eslint-disable-next-line
      for (let i = 0; i < this.scene.elements.length; i++) {
        const id = this.scene.elements[i];
        if (this.isImage(id) || this.isMask(id)) {
          elements.push(id);
        }
      }

      return elements;
    },
  },
  methods: {
    isImage(id) {
      // don't include graphics elements
      return this.getCanvasElementById(id).type === 'images';
    },
    isMask(id) {
      return this.getCanvasElementById(id).type === 'masks';
    },
  },
};
</script>

<style lang="scss">
.swap-scene {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 100%;
  overflow: auto;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  transform: translateX(-110%);
  transition: transform 0.25s ease-in-out;

  &.is-empty {
    justify-content: center;
    align-items: center;
  }

  &.is-active {
    transform: translateX(0);

    & ~ .swap-scene {
      transform: translateX(110%);
    }
  }

  .empty-scene {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty-scene__text {
    color: var(--black-white);
  }
}
</style>
