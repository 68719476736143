<template lang="pug">
#share-project-container(:class="{ 'is-dark': isDarkMode }")
  .toggle__button-wrapper
    span Share template link
      i.icon.icon-star(v-if="showUpgradeIcon")
    BaseSwitch.share_template_link__switch(
      name="share-template-link"
      :isChecked="showTemplateLink && !isDisabled"
      label=" "
      :isDisabled="isDisabled"
      @change="changeShowTemplateLink"
      @click-disabled="clicked"
    )
  .template__link-wrapper(
    v-if="showTemplateLink && !isDisabled"
  )
    span Anyone with this link can &nbsp;
      strong use as template
    form.share-link(
      autocomplete="off"
      @submit.prevent
    )
      input(
        v-model="shareLink"
        readonly="true"
        ref="shareLink"
      )
      button.btn-copy(
        @click="copyShareLink"
      ) {{copyText}}


</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import helperMixins from '@/components/mixins/helper-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

import ProjectApi from '@/services/api/ProjectApi';

export default {
  name: 'ShareProjectContainer',
  mixins: [helperMixins, darkModeMixin],
  data() {
    return {
      showTemplateLink: false,
      copyText: 'Copy',
    };
  },
  computed: {
    ...mapGetters('canvasElements', ['getProjectId', 'getIsShareable']),
    ...mapGetters('userData', ['isPayingUser', 'isAdmin', 'isDesigner', 'isFreeUser']),
    ...mapState('canvasElements', ['project']),
    shareLink() {
      return `${process.env.VUE_APP_PUBLIC_URL}/create-project/user/${this.getProjectId}`;
    },
    isDisabled() {
      return !this.isPayingUser && !this.isAdmin && !this.isDesigner;
    },
    showUpgradeIcon() {
      return this.isFreeUser;
    },
  },
  methods: {
    ...mapMutations(['setShowUpgradeSubscription']),
    ...mapMutations('canvasElements', ['updateShareable']),
    copyShareLink() {
      const { shareLink } = this.$refs;
      shareLink.select();

      try {
        document.execCommand('copy');
        this.copyText = 'Copied';

        setTimeout(() => {
          this.copyText = 'Copy';
        }, 2000);
      } catch ($e) {
        console.log('error');
        this.alertError('Oops', 'Copying to clipboard failed.', 5000, 'TopCenterNotif');
      }
    },
    changeShowTemplateLink() {
      if (!this.isPayingUser && !this.isAdmin && !this.isDesigner) {
        this.showTemplateLink = false;
        this.setShowUpgradeSubscription(true);
        return;
      }
      this.showTemplateLink = !this.showTemplateLink;

      ProjectApi.shareProjectAsTemplate(this.getProjectId, {
        toggle: this.showTemplateLink ? 'on' : 'off',
      })
        .then((response) => {
          // if (data.success) {
          this.alertSuccess('', response.data.message, 5000, 'TopCenterNotif');
          this.updateShareable(this.showTemplateLink);
          // }
        })
        .catch((error) => {
          this.alertError('', error.message, 5000, 'TopCenterNotif');
        });
    },
    clicked() {
      console.log('clicked');
      this.changeShowTemplateLink();
    },
  },
  watch: {
    project() {
      this.showTemplateLink = this.project.isShareable;
    },
  },
  mounted() {
    this.showTemplateLink = this.project.isShareable;
  },
};
</script>

<style lang="scss">
#share-project-container {
  position: absolute;
  right: 2px;
  top: 58px;
  width: 400px;
  background: var(--lightgrey100-darkgrey600);
  border-radius: 4px;
  box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08), 0px 3px 4px rgba(10, 31, 68, 0.1);
  z-index: 20;
  display: flex;
  flex-direction: column;

  &.is-dark {
    box-shadow: 0px 0px 1px rgb(10 31 68 / 8%), 0px 3px 4px rgb(10 31 68 / 50%);
  }

  > div {
    padding: 10px 15px;
  }

  .toggle__button-wrapper {
    color: var(--darkgrey900-white);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid var(--lightgrey400-darkgrey200);
    display: flex;
    justify-content: space-between;

    &:last-child {
      border-bottom: 0;
    }

    .icon {
      color: $yellow;
      margin-left: 7px;
    }
  }

  .template__link-wrapper {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--darkgrey400-lightgrey500);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    .share-link {
      display: flex;
      justify-content: space-between;
      border: 1px solid $borderGrey;
      padding: 10px 15px;
      border-radius: 4px;
      margin-top: 10px;

      input {
        width: 100%;
        text-overflow: ellipsis;
        outline: none !important;
        background: transparent;
        color: var(--darkgrey900-white);
      }

      .btn-copy {
        text-transform: uppercase;
        color: $textLight;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
      }
    }
  }
}
</style>
