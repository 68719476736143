<template lang="pug">
.brand-tab-color
  BaseButton.color__open-button(
    v-if="!isTeamMember"
    :plain-hover="true"
    :is-dark="true"
    @click="setShowBrandTabColorEditModal(true)"
  ) Edit

  ColorPalette(
    v-for="(item, index) in getActiveTeamColors"
    :key="index"
    :colors="item.colors"
    :name="item.name"
    :index="index"
  )
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ColorPalette from './ColorPalette.vue';

export default {
  name: 'BrandTabColor',
  components: {
    ColorPalette,
  },
  computed: {
    ...mapGetters('team', ['getActiveTeamColors', 'isTeamMember']),
  },
  methods: {
    ...mapMutations(['setShowBrandTabColorEditModal']),
  },
};
</script>

<style lang="scss">
.brand-tab-color {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .color__open-button {
    margin-bottom: 25px;
  }

  .btn-add-color {
    background: $darkGrey600;
    border-radius: $componentBorderRadius;
    color: $light;
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 0.75rem;
    transition: background 0.3s cubic-bezier(0, 0, 0, 1);

    &:hover {
      background: $darkGrey500;
    }
  }
}
</style>
