<template lang="pug">
BaseButton.btn-add-text(
  :class="{ 'btn-add-heading': isHeading, 'btn-add-subheading': isSubheading }"
  isDark=true
  isFullWidth=true
  @click="addText(type)"
)
  span.text(
    :style="textStyle"
  ) {{ text }}
  span.type(v-if="type !== 'body'") {{ textType }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { defaultFontSize } from '@/assets/scripts/variables';
import { fontWeights } from '@/assets/scripts/enums';
import {
  getHasFontWeight,
  getHasItalicFontWeight,
} from '@/assets/scripts/utilities';

export default {
  name: 'AddTextButton',
  props: {
    type: { type: String, default: 'body' },
    text: { type: String, default: '+ Add a body text' },
    font: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters(['getActiveOffeoFonts', 'getDefaultFont']),
    selectedFontObj() {
      const fontFamily = this.font.fontFamily || this.getDefaultFont.h2.fontFamily;
      const workSansObject = this.getActiveOffeoFonts.find((font) => font.fontFamily === 'Work Sans'); // eslint-disable-line
      const selectedFontObject = this.getActiveOffeoFonts.find((font) => font.fontFamily === fontFamily); // eslint-disable-line

      return selectedFontObject || workSansObject;
    },
    isHeading() {
      return this.type === 'heading';
    },
    isSubheading() {
      return this.type === 'subheading';
    },
    fontFamily() {
      // use the h2 font family if it doesnt have
      return this.selectedFontObj.fontFamily;
    },
    fontFamilyText() {
      // fontFamily is not the same as fontFamilyText
      // fontFamily is auto generated when user upload
      const fontObj = this.selectedFontObj;
      let fontFamilyText = 'Work Sans';
      if (fontObj) {
        fontFamilyText = fontObj.text;
      }

      return fontFamilyText;
    },
    fontWeight() {
      return this.font.fontWeight || 400;
    },
    fontSize() {
      return this.font.fontSize || defaultFontSize.body;
    },
    fontColor() {
      return this.font.fontColor || '#000000';
    },
    fontStyle() {
      return this.font.fontStyle;
    },
    letterSpacing() {
      return this.font.letterSpacing;
    },
    lineHeight() {
      return this.font.lineHeight;
    },
    align() {
      return this.font.align;
    },
    textStyle() {
      const styleObject = {};

      styleObject.fontFamily = this.fontFamily;

      return styleObject;
    },
    textType() {
      return `${this.fontFamilyText} / ${this.actualFontStyle} / ${this.fontSize}`;
    },
    actualFontStyle() {
      let selectedFontHasFontWeight = false;
      const isItalic = this.fontStyle === 'italic' && getHasItalicFontWeight(this.fontWeight, this.selectedFontObj);
      let fontType = fontWeights[this.fontWeight];

      if (isItalic) selectedFontHasFontWeight = getHasFontWeight(this.fontWeight, this.selectedFontObj, 'italic');
      else selectedFontHasFontWeight = getHasFontWeight(this.fontWeight, this.selectedFontObj);

      if (isItalic) {
        fontType = fontType + '-italic'; // eslint-disable-line
      }

      if (!selectedFontHasFontWeight) {
        fontType = 'regular';
      }

      return fontType;
    },
  },
  methods: {
    ...mapActions('canvasElements', ['addTextElementToCanvas']),
    getActualFontWeight(fontWeight) {
      let weight = fontWeight;
      // if the font doesnt have the font weight, return 400
      if (!this.selectedFontObj.weight.includes(weight)) weight = 400;
      return weight;
    },
    addText() {
      const fontWeight = this.getActualFontWeight(this.fontWeight);

      const font = {
        fontFamily: this.fontFamily,
        fontSize: this.fontSize,
        fontWeight,
        fontColor: this.fontColor,
        type: this.type,
        align: this.align,
        fontStyle: this.fontStyle,
        letterSpacing: this.letterSpacing,
        lineHeight: this.lineHeight,
      };
      this.addTextElementToCanvas(font);
    },
  },
};
</script>

<style lang="scss">
.btn-add-text.btn--dark {
  margin-bottom: 10px;
  padding: 5px;
  background-color: $darkGrey500;
  display: block;
  font-size: 0.875rem;

  span {
    display: block;
    text-align: center;
    margin: 5px 0;
  }

  .type {
    color: $lightGrey900;
    font-size: 0.675rem;
    text-transform: capitalize;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $darkGrey600;
  }

  &:focus {
    background-color: $darkGrey500;
  }

  &.btn-add-heading {
    font-size: 1.75em;
    font-weight: 600;

    @include smallest {
      font-size: 1.625em;
    }
  }

  &.btn-add-subheading {
    font-size: 1em;
  }
}
</style>
