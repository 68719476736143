import { mapMutations, mapActions, mapState } from 'vuex';
import UserApi from '@/services/api/UserApi';
import helperMixins from '@/components/mixins/helper-mixins';

const userFolderMixins = {
  mixins: [
    helperMixins,
  ],
  data() {
    return {
      isCreatingFolder: false,
      showOptionsOnHover: false,
      isDropping: false,
      folderName: '',
    };
  },
  computed: {
    ...mapState('assetsSidebar', [
      'activeFolder',
      'isMultipleFileSelection',
      'multipleSelectedFiles',
      'moveAllFilesActiveFolder',
      'userFolders',
    ]),
    selectActionText() {
      return this.isMultipleFileSelection ? 'Cancel' : 'Select';
    },
    createFolderText() {
      return this.isCreatingFolder ? 'Creating...' : 'Create Folder';
    },
    showbackButton() {
      // Active Folder === 0 also belongs to all-files (root)
      return this.activeFolder > 0;
    },
  },
  methods: {
    ...mapMutations('assetsSidebar', [
      'setActiveFolder',
      'setIsMultipleFileSelection',
      'setMoveAllFilesFolder',
      'setMultipleSelectedFiles',
      'setShowMoveMultipleFilesContainer',
    ]),
    ...mapActions('assetsSidebar', [
      'addNewUserFolder',
      'renameUserFolder',
      'changeUserFilesFolder',
      'moveAllFilesComplete',
    ]),
    createUserFolder() {
      this.isCreatingFolder = true;
      const folderName = 'New folder';
      UserApi.createUserFolder(folderName)
        .then((response) => {
          if (response.data.success) {
            this.addNewUserFolder(response.data.results);
            this.isFolderCollapsed = false;
          } else {
            this.alertError('Hey there!', response.data.message, 5000);
          }
          // this.setUserFolders(response.data.results);
        }).catch((error) => {
          console.log(error.response);
          this.alertError('Hey there!', error.response.data.message, 5000);
        })
        .then(() => {
          this.isCreatingFolder = false;
        });
    },
    updateFolderName() {
      // this.folder_name = name;
      // console.log('change folder name');

      this.$refs.folderName.$refs.baseInput.blur();

      if (this.folderName === '' || this.folderName === this.folder_name) {
        this.folderName = this.folder_name; // revert back the old value
        return;
      }

      this.isFolderNameReadOnly = true;

      const params = {
        folder_name: this.folderName,
      };

      UserApi.updateUserFolder(this.id, params)
        .then((response) => {
          if (response.data.success) {
            // update vuex data
            this.renameUserFolder({
              id: this.id,
              folder_name: this.folderName,
            });
          } else {
            this.alertError(response.data.message || 'Updating folder name failed');
          }
          // this.setUserFolders(response.data.results);
        }).catch((error) => {
          console.log(error);
          this.alertError();
        });
    },
    dropFilesToFolder(data) {
      console.log('dropFilesToFolder', data);
      this.isDropping = false;
      if (!data.elementData.file_id) return;
      // console.log('ELEMENT ID', folderId, data.elementData.file_id);
      const folderId = this.id;
      const fileId = data.elementData.file_id;
      this.moveFile(folderId, fileId);
    },
    dropFilesToMainFolder(data) {
      this.isDropping = false;
      if (!data.elementData.file_id) return;
      // console.log('ELEMENT ID', folderId, data.elementData.file_id);
      const folderId = 0; // Use zero value to move files into root directory
      const fileId = data.elementData.file_id;
      this.moveFile(folderId, fileId);
    },
    moveMultipleFiles() {
      if (this.moveAllFilesActiveFolder === null) return;

      this.moveFile(this.moveAllFilesActiveFolder, this.multipleSelectedFiles.join('|'));

      this.$nextTick(() => {
        this.moveAllFilesComplete();
      });
    },
    moveFile(folderId, fileId) {
      const fileIds = String(fileId).split('|');
      // eslint-disable-next-line
      fileIds.map(newFileId => parseInt(newFileId));

      UserApi.moveMultipleFilesToFolder(folderId, fileIds)
        .then((response) => {
          if (response.data.success) {
            this.$notify({
              group: 'BottomRightNotif',
              title: 'Hey there!',
              type: 'success',
              text: response.data.message,
              duration: 5000,
            });

            // update element in vuex
            fileIds.forEach((id) => {
              this.changeUserFilesFolder({
                folderId,
                fileId: id,
              });
            });
          } else {
            this.alertError(response.data.message || 'Moving file failed');
          }
        }).catch(() => {
        // console.log(error.response);
          this.alertError('Moving file failed');
        })
        .then(() => {
          this.isCreatingFolder = false;
        });
    },
    showModalOverlay() {
      this.setShowMoveMultipleFilesContainer(true);
    },
    toggleSelectButton() {
      this.setIsMultipleFileSelection(!this.isMultipleFileSelection);
      this.setMultipleSelectedFiles([]);
      // this.$root.$emit('multi-selecting', this.isMultipleFileSelection);
    },
  },
};

export default userFolderMixins;
