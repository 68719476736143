<template lang="pug">
.brand-tab-logo__item
  SkeletonItem(
    :fileProgress="fileProgress"
    v-if="isSkeleton"
  )
  template(v-else)
    .brand-tab-logo__top-overlay
      button.file-dots(
        v-if="!isTeamMember"
        @click.stop="$emit('toggle-file-options', { id, url, fileName })"
      )
        i.icon.icon-options
    img(
      :src="previewUrl"
      @click="addElement"
    )
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'; // eslint-disable-line
import { randomId, getImageSize } from '@/assets/scripts/utilities';
import { elementFormat } from '@/assets/scripts/variables';
import SkeletonItem from './SkeletonItem.vue';

export default {
  name: 'BrandTabLogoItem',
  components: {
    SkeletonItem,
  },
  props: {
    id: { type: [Number, String], default: 0 },
    previewUrl: { type: String, default: '' },
    url: { type: String, default: '' },
    fileName: { type: String, default: '' },
    fileProgress: { type: Number, default: 0 },
    isSkeleton: { type: Boolean, default: false },
  },
  data() {
    return {
      elementData: JSON.parse(JSON.stringify(elementFormat)),
    };
  },
  computed: {
    ...mapState('team', ['teamId']),
    ...mapGetters('team', ['isTeamMember']),
    ...mapGetters('assetsSidebar', [
      'getActiveGroup',
      'getActiveSubGroup',
    ]),
    ...mapGetters('canvasElements', ['getCanvasSize', 'getProjectBrandId']),
  },
  watch: {
    getCanvasSize: {
      handler() {
        this.setupElementData();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.setupElementData();
  },
  methods: {
    ...mapMutations('canvasElements', ['emptyActiveElements']),
    ...mapActions('canvasElements', ['addElementToCanvas']),
    setupElementData() {
      this.elementData.menu = this.getActiveGroup;

      this.elementData.type = 'logos';
      this.elementData.isImage = true;

      this.elementData.data.thumb = this.previewUrl;
      this.elementData.data.url = this.url;
      this.elementData.data.urlHd = this.url;

      this.elementData.blend = '';
      this.elementData.tint = 0;
      this.elementData.filters = {
        blur: 0,
        brightness: 1,
        contrast: 1,
        hue: 0,
        name: '',
        saturate: 1,
        sepia: 0,
      };
      this.elementData.data.image = {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      };

      let layerName = 'Logo';
      if (layerName) {
        layerName = layerName.replace(/s$/, '');
        layerName = layerName.replace(/^\w/, c => c.toUpperCase());
        this.elementData.data.name = layerName;
      }

      if (this.elementData.size.width !== 'auto' && this.elementData.size.width > 700) {
        const ratio = this.elementData.size.width / 700;
        this.elementData.size = {
          width: 700,
          height: this.elementData.size.height / ratio,
        };
      }

      getImageSize(this.elementData.data.url, (imageWidth, imageHeight) => {
        const maxWidth = this.getCanvasSize.width * 0.75;
        const maxHeight = this.getCanvasSize.height * 0.75;

        const maxSize = maxWidth > maxHeight ? maxHeight : maxWidth; // get the lowest value;

        let imageRatio = 1;
        let newImageWidth = imageWidth;
        let newImageHeight = imageHeight;

        // set minimum width to 0.75 of canvas first
        // don't allow image added to be too small, eg 100px
        if (imageWidth < maxSize) {
          imageRatio = maxSize / newImageWidth;
          newImageWidth = maxSize;
          newImageHeight = imageHeight * imageRatio;
        }

        // need to set maximum size to 0.75 of canvas
        // don't allow image added to be too big
        if (imageWidth > maxSize || imageHeight > maxSize) {
          if (imageWidth > maxSize) {
            imageRatio = maxSize / newImageWidth;
            newImageWidth = maxSize;
            newImageHeight = imageHeight * imageRatio;
          }
          if (newImageHeight > maxSize) {
            // vertical
            imageRatio = maxSize / newImageHeight;
            newImageHeight = maxSize;
            newImageWidth *= imageRatio;
          }
        }

        this.elementData.size = {
          width: newImageWidth,
          height: newImageHeight,
        };

        this.elementData.position = {
          x: this.getCanvasSize.width / 2,
          y: this.getCanvasSize.height / 2,
        };
      });

      if (!this.elementData.data.team_element_origin) {
        // add a marker for remove bg purposes
        this.elementData.data.team_element_origin = {
          team_id: this.teamId,
          brand_id: this.getProjectBrandId,
        };
      }
    },
    addElement() {
      this.emptyActiveElements();
      setTimeout(async () => {
        this.elementData.data.id = randomId();
        this.addElementToCanvas(this.elementData);
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.brand-tab-logo__item {
  display: block;
  cursor: pointer;
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  &:hover,
  &.is-hovering-file-options {
    .brand-tab-logo__top-overlay {
      opacity: 1;
    }
  }
}

.brand-tab-logo__top-overlay {
  background: linear-gradient(0deg, rgba(10, 31, 68, 0), rgba(10, 31, 68, 0.7));
  position: absolute;
  min-height: 40px;
  width: 100%;
  pointer-events: none;
  left: 0;
  top: 0;
  z-index: 3;
  overflow: hidden;
  opacity: 0;

  .file-dots {
    position: absolute;
    top: 5px;
    right: 5px;
    color: $light;
    background: rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    display: block;
    z-index: 10;
    cursor: pointer;
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: $componentBorderRadius;
    pointer-events: auto;

    &:hover,
    &.is-hovering-file-options {
      background: $blue;
    }

    .icon-options {
      transform: rotate(90deg);
      display: block;
    }
  }
}
</style>
