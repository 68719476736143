<template lang="pug">
.element-glow.effect
  BaseSwitch.element-glow__switch.effect__switch(
    name="element-glow"
    :isChecked="isEnabled('glow')"
    :isBeta="true"
    @change="toggleEnable('glow')"
    label="Glow"
  )
  VueSlideUpDown.element-glow__content.effect__content(
    :class="{'effect-content--enabled': isEnabled('glow')}"
    :active="true"
  )
    .glow__color.effect__color
      span.title Colour
      button.color.btn-add-color(
        :class="{ 'is-active': isTextGlow || isMediaGlow }"
        type="button"
        :style="colorStyle"
        @click="openColorPicker"
      )
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="100"
      title="Radius"
      :value="radius"
      @blur="onBlurInput($event, 'radius')"
      @update="updateRadius"
    )
    //- Transparency 100 = Opacity 0
    //- they are inverse
    BaseSlider.effects__base-slider(
      is-dark=true
      :min="0"
      :max="100"
      title="Transparent"
      :value="opacity"
      @blur="onBlurInput($event, 'opacity', '%')"
      @update="updateOpacity"
      suffix="%"
    )

    BaseButton.effects__reset-button(
      :is-canvas="true"
      is-dark=true
      is-full-width=true
      @click="resetGlow"
    ) Reset
</template>

<script>
import { mapState } from 'vuex';
import VueSlideUpDown from 'vue-slide-up-down';
import { elementFormat, textDataFormat, defaultTextGlowRadius } from '@/assets/scripts/variables';
import { hexToRgb } from '@/assets/scripts/utilities';
import effectsMixin from '@/components/mixins/effects-mixins';

export default {
  name: 'GlowControls',
  components: {
    VueSlideUpDown,
  },
  mixins: [effectsMixin],
  computed: {
    ...mapState(['isTextGlow', 'isMediaGlow']),
    activeElement() {
      return this.getActiveElements[0];
    },
    selectedGlow() {
      return this.isTextEffectsType ? 'textGlow' : 'mediaGlow';
    },
    colorStyle() {
      const styleObject = {};

      let glowColor = '#ffffff';

      if (this.getActiveElements.length) {
        const { color } = this.activeElement.data[this.selectedGlow];

        const { r, g, b } = hexToRgb(color);
        glowColor = `rgb(${r}, ${g}, ${b})`;
      }

      styleObject.background = glowColor;

      return styleObject;
    },
    radius() {
      return this.activeElement.data[this.selectedGlow].radius;
    },
    opacity() {
      const opacity = this.activeElement.data[this.selectedGlow].opacity * 100;
      return Math.floor(100 - opacity);
    },
  },
  mounted() {
    if (!this.isEnabled('glow')) {
      this.toggleEnable('glow');
    }
  },
  methods: {
    updateRadius(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        if (this.isTextEffectsType) this.updateCanvasElementTextGlow({ radius: value, isModifyingRadius: true });
        if (this.isMediaEffectsType) this.updateCanvasElementMediaGlow({ radius: value });

        this.$root.$emit('canvasElementSizeUpdated');
      }
    },
    updateOpacity(value) {
      if (!Number.isNaN(value)) {
        this.catchHistory('element');
        const opacity = (100 - parseInt(value, 10)) / 100;
        if (this.isTextEffectsType) this.updateCanvasElementTextGlow({ opacity });
        if (this.isMediaEffectsType) this.updateCanvasElementMediaGlow({ opacity });
      }
    },
    onBlurInput(event, type, suffix) {
      this.onBlurEffectInput(event, {
        effect: this.selectedGlow,
        selectedInput: type,
        suffix,
      });
    },
    openColorPicker() {
      if (this.isTextEffectsType) this.setIsTextGlow(true);
      if (this.isMediaEffectsType) this.setIsMediaGlow(true);
      this.$emit('open-colorpicker');
    },
    resetGlow() {
      this.catchHistory('element');

      if (this.isTextEffectsType) {
        this.updateCanvasElementTextGlow({
          color: textDataFormat.textGlow.color,
          radius: defaultTextGlowRadius,
          opacity: textDataFormat.textGlow.opacity,
        });
      }

      if (this.isMediaEffectsType) {
        this.updateCanvasElementMediaGlow({
          color: elementFormat.data.mediaGlow.color,
          radius: defaultTextGlowRadius,
          opacity: elementFormat.data.mediaGlow.opacity,
        });
      }

      this.$root.$emit('canvasElementSizeUpdated');
    },
  },
};
</script>

<style lang="scss">
</style>
