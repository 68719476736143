<template lang="pug">
  div.controls__item.controls__item--color
    button.btn--icon.btn-change-text-color.btn--control(
      type='button'
      :data-title="`Change ${ isText ? 'Text' : 'Branded Graphic' } Color`"
      @click="doToggleColorTab"
      :style="{'background': activeElementColorPreview}"
    )
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { groups } from '@/assets/scripts/enums';

export default {
  name: 'ColorToggler',
  props: {
    isText: { type: Boolean, default: false },
    isGraphic: { type: Boolean, default: false },
    isMyFiles: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('canvasElements', ['getActiveElements']),
    ...mapGetters('assetsSidebar', [
      'getActiveGroup',
    ]),
    activeElementColorPreview() {
      let color = '#FFA89C';
      const isColorPreviewExist = this.getActiveElements[0]
        && this.getActiveElements[0].data
        && this.getActiveElements[0].data.color
        && this.getActiveElements[0].data.color.length;

      if (isColorPreviewExist) {
        const selectedColor = this.getActiveElements[0].data.color[0];
        const isGradient = typeof selectedColor === 'object';

        if (isGradient) {
          if (selectedColor.points) {
            // Multiple Point Gradient
            const { points, degree } = selectedColor;
            color = `linear-gradient(${degree}deg`;

            points.forEach((point) => {
              color += `, ${point.color} ${point.percentage}%`;
            });

            color += ')';
          } else if (selectedColor.color) {
            // for solid color for animated json
            // since animated json need `nm` key
            // eslint-disable-next-line
            color = selectedColor.color;
          }
        } else {
          color = selectedColor;
        }
      }

      return color;
    },
  },
  methods: {
    ...mapActions(['toggleColorTab']),
    ...mapMutations(['setShowSidebar', 'setMaintainShowBackgroundColorTab']),
    ...mapMutations('assetsSidebar', [
      'setActiveGroup',
    ]),
    doToggleColorTab() {
      this.setShowSidebar({ boolean: true, maintainColorTabState: true }); // avoid issue where opening color tab when timeline is closed will not shrink timeline.

      // if sidebar is showing background tab,
      // changed it to the tab based on element
      if (this.getActiveGroup === groups.BACKGROUNDS
        && (this.isText || this.isBrandedGraphic)) {
        // change tab so that it wouldn't change background colour
        let activeGroup = groups.TEXTS;

        if (this.isBrandedGraphic) {
          activeGroup = groups.ELEMENTS;
        }

        this.setActiveGroup(activeGroup);
      }

      if (this.isText) {
        this.toggleColorTab(0);
      } else if (this.isBrandedGraphic) {
        this.toggleColorTab('branded');
      }

      this.setMaintainShowBackgroundColorTab(true);
    },
  },
};
</script>

<style lang="scss">
.controls__item--color {
  margin: 0 4px 0 6px;

  .btn-change-text-color {
    border: 1px solid $lightGrey400;
    border-radius: 2px;
    min-width: 20px !important;
    min-height: 20px !important;
  }
}
</style>
