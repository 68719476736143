import VueSlickCarousel from 'vue-slick-carousel';

const slickMixin = {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      hasPrevItem: false,
      hasNextItem: true,
      activePage: 1,
      isCatchingLastPageOpened: false,
    };
  },
  beforeDestroy() {
    // if (this.$refs.slick) this.$refs.slick.destroy();
  },
  methods: {
    handleBeforeChange(currentSlide, nextSlide) {
      const slickContentLength = this.slickContent.length;
      if (nextSlide === 0) {
        this.hasPrevItem = false;
      } else {
        this.hasPrevItem = true;
      }

      if (nextSlide >= slickContentLength - this.slickOptions.slidesToScroll) {
        this.hasNextItem = false;
      } else {
        this.hasNextItem = true;
      }
      // console.log('handleBeforeChange', nextSlide, 'should =', this.shouldFetchDesign(nextSlide + 1));
      this.activePage = nextSlide + 1;

      if (this.shouldFetchDesign(nextSlide + 1)) {
        this.showPreloader = true;
        this.currentSlide = nextSlide;
      }
    },
  },
};

export default slickMixin;
