<template lang="pug">
.canvas-grid(
  :class="`canvas-grid--${showGrid}`"
)
  .canvas-grid__horizontal
    span.canvas-grid__item.grid-horizontal(
      v-for="n in (showGrid - 1)"
      :style="{ 'top': `${n * sizePercentage}%` }"
    )
  .canvas-grid__vertical
    span.canvas-grid__item.grid-vertical(
      v-for="n in (showGrid - 1)"
      :style="{ 'left': `${n * sizePercentage}%` }"
    )
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CanvasGrid',
  computed: {
    ...mapState([
      'showGrid',
    ]),
    sizePercentage() {
      return 100 / this.showGrid;
    },
  },
};
</script>

<style lang="scss">
.canvas-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: block;
  pointer-events: none;

  &--6 .canvas-grid__item:not(:nth-child(2n)),
  &--9 .canvas-grid__item:not(:nth-child(3n)) {
    opacity: 0.5;
    background: lighten($blue, 20%);
  }
}

.grid-vertical {
  width: 1px;
  height: 100%;
  top: 0;
  transform: translateX(-50%);
}

.grid-horizontal {
  height: 1px;
  width: 100%;
  left: 0;
  transform: translateY(-50%);
}

.grid-vertical,
.grid-horizontal {
  background: $blue;
  position: absolute;
}
</style>
