<template lang="pug">
.user-menu__name-level(:class="{'is-dark': isDarkMode}")
  .user-menu__name {{ name }}
  .user-menu__userlevel {{ isInTeamWorkspace ? `Team ${userTeamRole}` : getUserLevel }}
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'UserMenuNameLevel',
  mixins: [darkModeMixin],
  computed: {
    ...mapState('userData', ['name']),
    ...mapState('team', ['userTeamRole']),
    ...mapGetters('userData', ['getUserLevel']),
    ...mapGetters('team', ['isInTeamWorkspace']),
  },
};
</script>

<style lang="scss">
.user-menu__name-level {
  max-width: calc(95% - 50px);

  .user-menu__name {
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .is-dark & {
      color: $light;
    }
  }

  .user-menu__userlevel {
    color: $blue;
    margin-top: 5px;
    font-size: 0.75rem;

    .is-dark & {
      color: $green600;
    }
  }
}
</style>
