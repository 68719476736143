<template lang="pug">
  div.controls__music(v-click-outside="onClickOutside")
    div.controls__music-header
      button.btn-audio(
        type='button'
        :class="{ 'is-playing': isPlaying, 'is-disabled': isMusicTrimToolbarLoading || getIsTempAudioPlaying }"
        @click="togglePlayAudio"
      )
        i.icon.icon-play-triangle

      TooltipMenu.btn-volume(
        button-class="icon-volume-line"
        :no-header="true"
      )
        div.music__volume-drag-box
          div.volume-section
            p.volume-box-title Volume
            BaseSlider.volume-slider(
              :class="{'is-dark': isDarkMode}"
              :min="0"
              :max="100"
              :value="musicVolume"
              @update="setMusicVolume"
            )

    div.controls-music__preloader(v-if="isMusicTrimToolbarLoading")
        Lottie.loader__animation(:options="canvasLoaderOptions")
        p.loader__text Loading Soundwave...
    AudioTrimControl(
      v-if="isShowMusicToolbarTrimmer"
      :timeline-time="timelineTime"
      :is-playing="isPlaying"
      @play="isPlaying = true"
      @done-playing="isPlaying = false"
      @pause-playing="isPlaying = false"
    )

    hr.controls-music__divider-line(v-if="!isMusicTrimToolbarLoading")

    AudioFadeControl(v-if="!isMusicTrimToolbarLoading")
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import TooltipMenu from './children/TooltipMenu.vue';
import AudioTrimControl from './children/AudioTrimControl.vue';
import AudioFadeControl from './children/AudioFadeControl.vue';
import Lottie from '@/components/ProjectCanvas/AssetSidebarCanvas/LottieShared.vue';
import CanvasLoader from '@/assets/data/canvas-loader.json';

import helperMixin from '@/components/mixins/helper-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'ControlMusic',
  components: {
    TooltipMenu,
    AudioTrimControl,
    AudioFadeControl,
    Lottie,
  },
  mixins: [helperMixin, darkModeMixin],
  props: {
    timelineTime: { type: Number, default: 0 },
  },
  data() {
    return {
      isPlaying: false,
      musicVolume: 0,
    };
  },
  computed: {
    ...mapState(['isMusicTrimToolbarLoading', 'isShowMusicToolbarTrimmer']),
    ...mapGetters('canvasElements', ['getSelectedMusic']),
    // "getIsPlaying" is Timeline's Scene Playing Indicator - from Timeline's play button
    ...mapGetters(['getIsTempAudioPlaying', 'getIsPlaying']),
    canvasLoaderOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: CanvasLoader,
      };
    },
  },
  mounted() {
    let { volume } = this.getSelectedMusic;
    if (typeof volume === 'undefined' || volume === null) volume = 75;

    this.musicVolume = parseInt(volume, 10);
    this.setTrimControlAudioVolume(this.musicVolume);
  },
  watch: {
    getIsPlaying(val) {
      if (val) {
        this.isPlaying = false;
      }
    },
  },
  methods: {
    ...mapMutations('canvasElements', ['updateSelectedMusic']),
    onClickOutside() {
      this.isPlaying = false;
    },
    togglePlayAudio() {
      if (this.getIsPlaying) return;

      this.isPlaying = !this.isPlaying;
    },
    setMusicVolume(newValue) {
      const selectedMusic = this.getSelectedMusic;
      let volume = newValue;
      if (volume > 100) volume = 100;
      if (volume < 0) volume = 0;

      selectedMusic.volume = volume;
      this.updateSelectedMusic(selectedMusic);
      this.musicVolume = parseInt(volume, 10);

      if (this.isTempAudioPlaying && this.isPlaying) {
        // if temp audio is playing, change the volume accordingly
        const tempAudio = document.getElementById('temp-audio');
        tempAudio.volume = volume / 100;
      }

      this.setTrimControlAudioVolume(volume);
    },
    setTrimControlAudioVolume(volume) {
      // for the soundwave music in control bar
      const trimControlAudio = document.getElementById('peaksjs-audio-source');
      trimControlAudio.volume = volume / 100;
    },
  },
};
</script>

<style lang="scss">
.controls__music {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px;
  width: 100%;

  .controls__music-header {
    display: flex;
    align-items: center;
    width: 75px;

    .btn-volume,
    .btn-audio {
      padding: 0;
      transition: color 0.25s ease-in-out;
      outline: 0;
    }

    .btn-audio {
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-top: -2px;
      font-size: 1.25rem;
      width: 22px;
      cursor: pointer;
      color: var(--darkgrey800-white);

      &:hover {
        color: var(--blue-white);
      }

      &.is-playing {
        margin-top: 0;
        color: var(--blue-white);

        .icon::before {
          font-size: 1.625rem;
          margin-left: -3px;
          content: $icon-pause;
        }
      }

      &.is-disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
      }
    }

    .btn-volume {
      position: relative;
      display: flex;
      align-items: center;

      .icon-volume-line {
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--darkgrey800-white);
        padding: 0 5px;

        &:hover {
          color: var(--blue-white);
        }
      }

      .tooltip-menu__content {
        width: 350px;
        left: -47px;
        top: 44px;
        padding: 20px 30px;
        border: 0;

        @include smallest() {
          left: -50px;
        }
      }

      .volume-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .volume-box-title {
        margin: 0;
        width: 20%;
        font-size: 0.9375rem;
        color: var(--darkgrey800-white);
      }

      .volume-slider {
        width: 75%;
      }

      .volume-slider {
        margin: 0;

        &.is-dark {
          input[type='range']::-webkit-slider-thumb {
            background: $light !important;
          }

          .slider__value {
            color: $light;
            border-color: $light;
            background: transparent;
          }

          .slider__container .range-wrapper .range-before {
            background: $light;

            &:after {
              background: $lightGrey800;
            }
          }
        }
      }
    }
  }

  .controls-music__preloader {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    display: flex;
    align-items: center;

    .loader__animation {
      width: 40px !important;
      height: 40px !important;
    }

    .loader__text {
      font-size: 0.8125rem;
      color: var(--darkgrey-white);
      font-weight: 400;
      margin: 0;
      margin-left: 5px;
    }
  }

  .controls-music__divider-line {
    height: 53px;
    margin: 0 15px;
    border: 0;
    border-right: 1px solid var(--lightgrey400-darkgrey700);
  }
}
</style>
