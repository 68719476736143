<template lang="pug">
  .myfile-action-buttons(
    v-if="isMultipleFileSelection && filesSelected > 0"
    :class="{ 'has-no-options': filesSelected === 0 }"
  )
    .button-container(v-if="filesSelected > 0")
      .cancel-button__wrapper
        BaseButton.icon-cancel.cancel-button(
          :is-icon="true"
          :plain-hover="true"
          @click="toggleSelectButton"
        )
      .action-buttons__wrapper
        BaseButton.btn-myfile-action.btn-myfile-action__move(
          v-if="userFolders.length && isNotLogo"
          :is-tall="true"
          :is-canvas="true"
          :is-primary="true"
          :is-disabled="selectedIds.length === 0"
          @click="showModalOverlay(true)"
        ) Move to

        DeleteItem(
          :itemId="selectedIds"
          extraClass="btn-myfile-action btn-myfile-action__delete"
          :showIcon="false"
          :is-not-logo="isNotLogo"
          text="Delete"
        )

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Drop } from 'vue-drag-drop';
import { groups } from '@/assets/scripts/enums';
import userFilesAndFolderMixins from '@/components/mixins/user-files-and-folder-mixins';
import DeleteItem from './DeleteItem.vue';

export default {
  name: 'MyFileActionButtons',
  mixins: [userFilesAndFolderMixins],
  components: {
    DeleteItem,
    Drop,
  },
  data() {
    return {
      filesSelected: 0,
    };
  },
  computed: {
    ...mapState('assetsSidebar', ['multipleSelectedFiles']),
    ...mapGetters('assetsSidebar', ['getActiveGroup', 'getActiveSubGroup']),
    selectedIds() {
      return this.multipleSelectedFiles.join('|');
    },
    isNotLogo() {
      return this.getActiveGroup === groups.MYFILES && this.getActiveSubGroup !== 'logos';
    },
  },
  watch: {
    multipleSelectedFiles() {
      this.filesSelected = this.multipleSelectedFiles.length
        ? this.multipleSelectedFiles.length
        : 'No files ';
    },
  },
};
</script>

<style lang="scss">
.myfile-action-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 60px;
  left: 0;
  padding: 30px 10px 4px;
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.95) 8.67%,
    #000000 8.68%,
    rgba(0, 0, 0, 0) 90%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;
  box-shadow: 0px 8px 10px rgba(10, 31, 68, 0.1);
  border-radius: $componentBorderRadius;
  pointer-events: none;

  .files-selected {
    color: $lightWhite;
  }

  @include smallest {
    .files-selected {
      display: none;
    }

    &.has-no-options {
      .files-selected {
        display: block;
      }
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cancel-button__wrapper {
    text-align: right;

    .cancel-button {
      color: $light;
      font-size: 20px;
      margin-bottom: 10px;
      pointer-events: visible;
    }
  }

  .action-buttons__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: visible;

    .is-logo {
      width: 100%;

      .btn-myfile-action__delete {
        width: 100%;
      }
    }
  }

  .btn-myfile-action {
    font-size: 14px;
    font-weight: 500;
    transition: 0.3s;
    color: $lightWhite;
    margin: 0 4px;
  }

  .btn-myfile-action__move {
    padding: 14px 30px;

    &:focus {
      background: $blue700;
    }

    &:hover,
    &:active {
      background: $blue3;
    }
  }

  .btn-myfile-action__delete {
    padding: 14px 34px;
    border-radius: 4px;
    background: $red700;

    &:focus {
      background: $red700;
    }

    &:hover {
      background: $red500;
    }
  }

  .root-folder-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    &.is-dropping {
      background-color: $blue;
      color: #fff;
      padding: 0 3px 2px;

      button {
        color: #fff;
        margin-left: 5px;
      }
    }

    .drag-folder-text {
      font-size: 11px;
    }
  }

  .btn-back-folder {
    background: none;
  }
}
</style>
