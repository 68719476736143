<template lang="pug">
.brand-tab-logo__watermark(:class="{'is-disabled': isTeamMember}")
  BaseSwitch.watermark__switch(
    name="watermark"
    :is-checked="isWatermarkEnabled"
    @change="toggleWatermarkEnable"
    label="Watermark"
  )
  VueSlideUpDown(
    :active="isWatermarkEnabled"
  )
    .watermark__group
      .watermark__image
        template(v-if="!!watermarkUrl")
          img(:src="watermarkUrl")
          button.watermark__remove-button(
            type="button"
            title="Remove watermark"
            @click="removeWatermark"
          )
            i.icon.icon-delete
        span(v-else) Set logo as watermark under options
      .watermark__position
        button.btn-watermark-position(
          v-for="position in positions"
          type="button"
          :class="{ 'is-active': isActivePosition(position.direction) }"
          :disabled="!watermarkUrl"
          :title="position.title"
          @click="setWatermarkDirection(position.direction)"
        )
          i.icon(:class="position.icon")
    BaseButton.watermark-btn(
      :class="{ 'is-active': isWatermarkOptionsEnabled}"
      :is-hollow="true"
      :is-tall="true"
      :is-canvas="true"
      :plain-hover="true"
      icon-after="icon-down-arrow"
      :is-disabled="!watermarkUrl"
      @click="toggleWatermarkOptionsEnable"
    ) Show Watermark on
    VueSlideUpDown.watermark__container(
      :active="isWatermarkOptionsEnabled"
    )
      BaseSlider(
        :min="100"
        :max="maxLogoSize"
        is-dark=true
        :value="logoSize"
        title="Logo Size"
        @update="setLogoSize"
        @blur="checkWatermarkSettings('logo', $event)"
      )
      BaseSlider(
        :min="0"
        :max="100"
        is-dark=true
        :value="transparency"
        title="Transparency"
        @update="setTransparency"
        @blur="checkWatermarkSettings('transparency', $event)"
      )
      BaseSlider(
        :min="0"
        :max="maxDistanceX"
        is-dark=true
        :value="distanceX"
        :isDisabled="isDistanceXDisabled"
        title="Distance x"
        @update="setDistanceX"
        @blur="checkWatermarkSettings('distance-x', $event)"
      )
      BaseSlider(
        :min="0"
        :max="maxDistanceY"
        is-dark=true
        :value="distanceY"
        :isDisabled="isDistanceYDisabled"
        title="Distance y"
        @update="setDistanceY"
        @blur="checkWatermarkSettings('distance-y', $event)"
      )
    BaseSwitch.watermark__switch(
      name="background"
      :is-checked="isBackgroundEnabled"
      :is-disabled="!watermarkUrl"
      @change="toggleBackgroundEnable"
      label="Add Background"
    )
    VueSlideUpDown(
      :active="isBackgroundEnabled"
    )
      .watermark-background__color
        span.title Background Color
        button.color.btn-add-color(
          type="button"
          :style="colorStyle"
          @click="openColorPicker"
        )
      BaseSlider(
        :min="minScale"
        :max="maxScale"
        :step="0.1"
        is-dark=true
        :value="scaleX"
        title="Scale Horizontally"
        @update="setScaleX"
        @blur="checkWatermarkSettings('scale-x', $event)"
      )
      BaseSlider(
        :min="minScale"
        :max="maxScale"
        :step="0.1"
        is-dark=true
        :value="scaleY"
        title="Scale Vertically"
        @update="setScaleY"
        @blur="checkWatermarkSettings('scale-y', $event)"
      )
      BaseSlider(
        :min="0"
        :max="maxBorderRadius"
        is-dark=true
        :value="borderRadius"
        title="Border Radius"
        @update="setBorderRadius"
        @blur="checkWatermarkSettings('border-radius', $event)"
      )
      BaseSlider(
        :min="0"
        :max="100"
        is-dark=true
        :value="backgroundTransparency"
        title="Transparency"
        @update="setBackgroundTransparency"
        @blur="checkWatermarkSettings('transparency', $event)"
      )
</template>

<script>
import VueSlideUpDown from 'vue-slide-up-down';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'BrandTabLogoWatermark',
  components: {
    VueSlideUpDown,
  },
  data() {
    return {
      isWatermarkOptionsEnabled: false,
      positions: [
        {
          icon: 'icon-top-left',
          direction: 'left-top',
          title: 'Left Top',
        },
        {
          icon: 'icon-up',
          direction: 'top',
          title: 'Top',
        },
        {
          icon: 'icon-top-right-arrow-long',
          direction: 'right-top',
          title: 'Right Top',
        },
        {
          icon: 'icon-left-arrow-long',
          direction: 'left',
          title: 'Left',
        },
        {
          icon: 'icon-emphasis-animation',
          direction: 'center',
          title: 'Center',
        },
        {
          icon: 'icon-right-arrow-long',
          direction: 'right',
          title: 'Right',
        },
        {
          icon: 'icon-bottom-left-arrow-long',
          direction: 'left-bottom',
          title: 'Left Bottom',
        },
        {
          icon: 'icon-down',
          direction: 'bottom',
          title: 'Bottom',
        },
        {
          icon: 'icon-bottom-right',
          direction: 'right-bottom',
          title: 'Right Bottom',
        },
      ],
      minScale: 1,
      maxScale: 4,
    };
  },
  computed: {
    ...mapGetters('team', ['isTeamMember']),
    ...mapGetters('canvasElements', ['getWatermark', 'getCanvasSize']),
    canvasWidth() {
      return this.getCanvasSize.width;
    },
    canvasHeight() {
      return this.getCanvasSize.height;
    },
    maxLogoSize() {
      return this.canvasWidth;
    },
    maxDistanceX() {
      const maxDistance = this.canvasWidth - this.getWatermark.size.width;
      return maxDistance > this.distanceX ? maxDistance : this.distanceX;
    },
    maxDistanceY() {
      const maxDistance = this.canvasHeight - this.getWatermark.size.height;
      return maxDistance > this.distanceY ? maxDistance : this.distanceY;
    },
    watermarkUrl() {
      return this.getWatermark.url || '';
    },
    selectedDirection() {
      return this.getWatermark.direction || 'center';
    },
    isDistanceXDisabled() {
      const disabledDirections = ['center', 'top', 'bottom'];
      return disabledDirections.includes(this.selectedDirection);
    },
    isDistanceYDisabled() {
      const disabledDirections = ['center', 'left', 'right'];
      return disabledDirections.includes(this.selectedDirection);
    },
    isWatermarkEnabled() {
      return this.getWatermark.isEnabled && !this.isTeamMember;
    },
    logoSize() {
      return this.getWatermark.size.width;
    },
    transparency() {
      return 100 - this.getWatermark.opacity;
    },
    distanceX() {
      return this.isDistanceXDisabled ? 0 : this.getWatermark.position.x;
    },
    distanceY() {
      return this.isDistanceYDisabled ? 0 : this.getWatermark.position.y;
    },
    /** WATERMARK BACKGROUND */
    isBackgroundEnabled() {
      return this.getWatermark.background.isEnabled;
    },
    backgroundTransparency() {
      return 100 - this.getWatermark.background.opacity;
    },
    watermarkBgColor() {
      let color = null;
      const selectedColor = this.getWatermark.background.color;
      const isGradient = typeof selectedColor === 'object';

      if (isGradient) {
        if (selectedColor.points) {
          // Multiple Point Gradient
          const { points, degree } = selectedColor;
          color = `linear-gradient(${degree}deg`;

          points.forEach((point) => {
            color += `, ${point.color} ${point.percentage}%`;
          });

          color += ')';
        }
      } else {
        color = selectedColor;
      }

      return color;
    },
    scaleX() {
      return (this.getWatermark.background.scale && this.getWatermark.background.scale.x) || 0;
    },
    scaleY() {
      return (this.getWatermark.background.scale && this.getWatermark.background.scale.y) || 0;
    },
    borderRadius() {
      return this.getWatermark.background.borderRadius;
    },
    maxBorderRadius() {
      return Math.max(this.getWatermark.size.width, this.getWatermark.size.height);
    },
    colorStyle() {
      const styleObject = {};
      const fallbackBgColor = '#ffffff';

      styleObject.background = this.watermarkBgColor || fallbackBgColor;

      return styleObject;
    },
  },
  watch: {
    logoSize(val) {
      const isSmallerThanBorderRadius = val < this.borderRadius;
      if (isSmallerThanBorderRadius) this.setBorderRadius(val);
    },
    watermarkUrl() {
      this.resetWatermarkSettings();
    },
    selectedDirection() {
      this.checkLogoSize();
    },
  },
  methods: {
    ...mapMutations([
      'setShowColorPicker',
      'setIsWatermark',
    ]),
    ...mapMutations('canvasElements', [
      'setWatermark',
      'setWatermarkPosition',
      'setWatermarkBackground',
    ]),
    resetWatermarkSettings() {
      this.setWatermark({
        opacity: 50,
        direction: 'center',
        position: {
          x: 20,
          y: 20,
        },
      });
    },
    checkLogoSize() {
      const widthExceeded = this.logoSize + this.distanceX > this.canvasWidth;
      const heightExceeded = this.logoSize + this.distanceY > this.canvasHeight;

      if (widthExceeded) this.setDistanceX(0);
      if (heightExceeded) this.setDistanceY(0);
    },
    checkWatermarkSettings(type, event) {
      // resolve issue when slider has been set at its max, then manually input value to slider input (e.g max is 100, but manually input 500, the input value will still be 500, supposely 100)
      const value = (event && event.target && event.target.value) || 0;
      let maxAmount = 0;

      if (type === 'logo') maxAmount = this.maxLogoSize;
      if (type === 'transparency') maxAmount = 100;
      if (type === 'distance-x') maxAmount = this.maxDistanceX;
      if (type === 'distance-y') maxAmount = this.maxDistanceY;
      if (type === 'scale-x' || type === 'scale-y') maxAmount = this.maxScale;
      if (type === 'border-radius') maxAmount = this.maxBorderRadius;

      // eslint-disable-next-line
      event.target.value = value > maxAmount ? maxAmount : value;
    },
    setLogoSize(value) {
      // logo size will affect the width
      const ratio = this.getWatermark.size.width / this.getWatermark.size.height;
      const height = value / ratio;
      this.setWatermark({
        size: {
          width: value,
          height,
        },
      });
    },
    setTransparency(value) {
      const opacity = 100 - value;
      this.setWatermark({ opacity });
    },
    removeWatermark() {
      this.setWatermark({ url: '' });
    },
    setDistanceX(value) {
      this.setWatermarkPosition({ x: value });
    },
    setDistanceY(value) {
      this.setWatermarkPosition({ y: value });
    },
    toggleWatermarkEnable() {
      if (this.isTeamMember) return;
      this.setWatermark({ isEnabled: !this.isWatermarkEnabled });
    },
    toggleWatermarkOptionsEnable() {
      this.isWatermarkOptionsEnabled = !this.isWatermarkOptionsEnabled;
    },
    isActivePosition(direction) {
      return this.getWatermark.direction === direction;
    },
    setWatermarkDirection(direction) {
      this.setWatermark({ direction });
    },
    /** WATERMARK BACKGROUND */
    toggleBackgroundEnable() {
      this.setWatermarkBackground({ isEnabled: !this.isBackgroundEnabled });
    },
    setScaleX(value) {
      this.setWatermarkBackground({ scaleX: value });
    },
    setScaleY(value) {
      this.setWatermarkBackground({ scaleY: value });
    },
    setBorderRadius(value) {
      this.setWatermarkBackground({ borderRadius: value });
    },
    setBackgroundTransparency(value) {
      const opacity = 100 - value;
      this.setWatermarkBackground({ opacity });
    },
    openColorPicker() {
      this.$emit('open-colorpicker');
    },
  },
};
</script>

<style lang="scss">
.brand-tab-logo__watermark {
  color: $lightWhite;
  margin-bottom: 5px;

  &.is-disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .watermark-btn {
    color: $light;
    padding-left: 10px;
    margin-bottom: 5px;

    &:hover,
    &.is-active {
      background: $darkGrey500 !important;
    }

    .icon {
      position: relative;
    }

    &.is-active {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  .watermark__switch.is-disabled,
  .watermark-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;

    .slider {
      opacity: 0.2;
    }

    &:hover {
      background: inherit;
      color: inherit;
    }
  }
}

.watermark__switch {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px;
  border-radius: $componentBorderRadius;
  color: $textLight;
  border: 1px solid $darkGreyBorder;
  background-color: transparent;
  font-family: $baseFont;
  font-weight: 500;
  font-size: 0.875rem;
  transition: color 0.3s cubic-bezier(0, 0, 0, 1), background 0.3s cubic-bezier(0, 0, 0, 1);
  cursor: pointer;
  outline: 0;
  line-height: 1.2;
  text-decoration: none;
  width: 100%;
  margin-bottom: 5px;

  &.is-active,
  &:hover {
    background: $btnHollowDarkHover;
  }

  .switch__label::before {
    content: $icon-down-arrow;
    display: inline-block;
    font-family: 'icomoon';
    margin-right: 5px;
    position: relative;
  }

  &.is-active {
    .switch__label::before {
      transform: rotate(180deg);
    }
  }

  &:hover {
    background: $btnHollowDarkHover;
  }

  .slider {
    margin-left: auto;
    transform: scale(0.9);
    right: 10px;
  }
}

.watermark__group {
  display: flex;
  justify-content: space-between;
  margin: 8px 0 15px;
}

.watermark__image {
  width: calc(100% - 140px);
  margin-right: 10px;
  position: relative;
  background: url('~@/assets/images/backgrounds/transparent-dark.jpg');
  border-radius: $componentBorderRadius;

  img {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    object-fit: contain;
  }

  span {
    position: absolute;
    line-height: 1.2;
    text-align: center;
    top: 50%;
    width: 100%;
    left: 0;
    transform: translateY(-50%);
    padding: 5px;
    font-size: 0.875rem;
  }
}

.watermark__position {
  display: flex;
  flex-wrap: wrap;
  width: 130px;

  .btn-watermark-position {
    background: $lightGrey900;
    color: $light;
    width: 36px;
    height: 36px;
    margin: 0 10px 10px 0;
    border-radius: $componentBorderRadius;
    position: relative;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    font-size: 0.8125rem;

    &:focus {
      background: $lightGrey900;
    }

    &:hover {
      background: $lightGrey700;
    }

    &.is-active,
    &.is-active:hover {
      background: $blue;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(n+7) {
      margin-bottom: 0;
    }

    &:disabled {
      opacity: 0.1;
      pointer-events: none;
    }

    .icon-emphasis-animation {
      font-size: 1rem;
      margin-top: -1px;
    }

    .icon-left-arrow-long,
    .icon-right-arrow-long {
      font-size: 0.875rem;
    }

    .icon-top-right-arrow-long,
    .icon-top-left,
    .icon-bottom-right,
    .icon-bottom-left-arrow-long {
      font-size: 0.75rem;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.watermark__remove-button {
  position: absolute;
  top: 0;
  left: 0;
  color: $salmon;
  font-size: 1rem;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  padding: 0;
  transition: opacity 0.3s cubic-bezier(0, 0, 0, 1);

  &:hover {
    opacity: 0.8;
  }
}

.watermark-background__color {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;

  .title {
    font-size: 0.875em;
  }

  .color {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid $borderGrey;

    &.is-active {
      border-color: $blue;
    }
  }
}
</style>
