<template lang="pug">
  div(v-if="isGradientPointerReady")
    VueDragResize.gradient__pointer(
      :isActive="isSelected"
      :w="24"
      :h="32"
      :x="cx"
      :y="0"
      :parentW="parentW"
      :parentLimitation="true"
      axis="x"
      :isResizable="false"
      :isDraggable="draggable"
      @dragging="updating"
      @dragstop="updated"
      @clicked="select"
      :z="id"
    )
      button.gradient__item(
        type='button'
        :class="{ 'is-active': isSelected }"
      )
        span.gradient__item-color(
          :style='{ background: point.color }'
        )
        button.btn-remove(
          v-if="removable"
          type="button"
          @click="remove"
        )
          i.icon-cancel
</template>

<script>
import VueDragResize from 'vue-drag-resize';
import { mapGetters } from 'vuex';

export default {
  name: 'GradientPointer',
  components: {
    VueDragResize,
  },
  data() {
    return {
      isGradientPointerReady: false,
    };
  },
  props: {
    id: { type: Number, required: true },
    parentW: { type: Number, required: true }, // this will be the full width of gradient picker
    point: { type: Object, required: true },
    maxX: { type: Number, required: true }, // this will be the full width of gradient picker - the size of pointer
    selectedId: { type: Number, required: true },
    isAddingOrRemovingPoint: { type: Boolean, default: true }, // only when adding or removing gradient point we need to do preloader / remount the GradientPointer.vue
    removable: { type: Boolean, default: false },
    draggable: { type: Boolean, default: true },
  },
  computed: {
    ...mapGetters(['isTextBackground', 'isTextShadow']),
    ...mapGetters('canvasElements', ['getActiveElements']),
    cx() {
      // computed x
      return this.percentageToPosition(this.point.percentage);
    },
    isSelected() {
      return this.selectedId === this.id;
    },
    isTextColor() {
      return (
        this.getActiveElements.length
        && this.getActiveElements[0].type === 'texts'
        && !this.isTextBackground
        && !this.isTextShadow
      );
    },
  },
  watch: {
    point: {
      handler() {
        if (this.isAddingOrRemovingPoint) {
          this.isGradientPointerReady = false;
          this.startGradientPointer();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.startGradientPointer();
  },
  methods: {
    startGradientPointer() {
      this.$emit('start-gradient-loader');

      setTimeout(() => {
        /*
        should have some span time before mount/load, if not it will show an error like "Uncaught TypeError: Cannot read property 'getAttribute' of null"
        its an issue similar with video/image that hasn't load but already modified. So need to bind with "onload" event, after that then can modify.
        but in vue-drag-resize there is no any loaded event, so used timeout
        reference -> https://stackoverflow.com/questions/32542312/uncaught-typeerror-cannot-read-property-getattribute-of-null
        --- in this case, i add 2.5s before load ( tested multiple times, 2.5s is the least amount of time needed at the moment )
      */
        this.isGradientPointerReady = true;
        this.$emit('reset-is-add-remove-point');
        this.$emit('stop-gradient-loader');
      }, 2500);
    },
    remove() {
      this.$emit('remove', this.id);
    },
    percentageToPosition(percentage) {
      let ratio = percentage.toFixed(0);

      if (ratio > 100) ratio = 100;
      if (ratio < 0) ratio = 0;
      return (this.maxX / 100) * ratio;
    },
    positionToPercentage(position) {
      let ratio = position / this.maxX;
      if (ratio > 1) ratio = 1;
      if (ratio < 0) ratio = 0;

      if (this.isTextColor) {
        // the percentage need to be between 5 to 95 only for text color
        // because there is extra padding on the text to prevent cropping
        return ratio * 90 + 5;
      }

      return ratio * 100;
    },
    select() {
      if (!this.isSelected) this.$emit('select', this.id);
    },
    updating(rect) {
      const { left } = rect;
      this.$emit('updating', this.id, this.positionToPercentage(left));
    },
    updated(rect) {
      const { left } = rect;
      this.$emit('updated', this.id, left);
    },
  },
};
</script>

<style lang="scss">
.gradient__pointer {
  position: absolute;
  top: 0 !important;

  &.vdr.active::before {
    display: none;
  }

  .gradient__item {
    width: 18px;
    height: 18px;
    cursor: pointer;
    position: relative;
    border-radius: 3px;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.15);

    .gradient__item-color {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }

    .btn-remove {
      align-items: center;
      background: $darkGrey;
      border-radius: 50%;
      color: $light;
      cursor: pointer;
      display: flex;
      font-size: 6px;
      height: 12px;
      justify-content: center;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: -2px;
      text-shadow: none;
      top: -3px;
      transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
      visibility: hidden;
      width: 8px;
      z-index: 4;

      .icon-cancel {
        pointer-events: none;
      }
    }

    &::before {
      content: '';
      width: 6px;
      height: 6px;
      position: absolute;
      background: $light;
      border: 0;
      top: -11px;
      left: 9px;
      transform: rotate(45deg);
      border-radius: 3px 0 0 0;
      transform-origin: top left;
    }

    &.is-active {
      border-color: $blue;

      &:before {
        background: $blue;
      }
    }

    &:last-child {
      float: right;
    }

    &:hover {
      .btn-remove {
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
      }
    }
  }
}
</style>
