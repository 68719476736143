<template lang="pug">
.assets__search-container(
  v-click-outside="onClickOutsideSearchBox",
  :class="{ 'is-light': isLight }"
)
  form.tab-search(
    autocomplete="off",
    @submit.prevent,
    :class="{ 'is-category-search': isCategorySearch, 'tab-search--full-width': isOnMusicTab }"
  )
    input.form-input(
      autocomplete="off",
      :title="searchTerm",
      v-model="searchTerm",
      type="text",
      placeholder="Search...",
      :disabled="!isSearchable",
      @input="doShowSearchBox",
      @keyup.enter="runSearch(searchTerm); showSearchBox = false; doTrackUserSearch('broad')",
      ref="searchInput"
    )
    button.btn-clear-keyword(
      type="button",
      :class="{ 'is-show': searchTerm.length > 0 }",
      :disabled="!isSearchable",
      @click="clearSearch"
    )
      i.icon.icon-cancel
    button.btn.btn-sidebar-search(
      type="button",
      :disabled="!isSearchable",
      @click="runSearch(searchTerm); doTrackUserSearch('broad')"
    )
      i.icon.icon-search

    .form-search-suggestion(
      v-if="showSearchBox && isNotMedia && isNotMyFiles && isNotShutterstockMusic"
    )
      template(v-if="showPopularCategories")
        .suggestion__category(v-if="showDesignContainer")
          p.suggestion__title Popular Categories
          .suggestion__list
            template(v-for="(popular, index_popular) in popularCategories")
              button.suggestion__item(
                @click="doSearchPopularCategories(popular.type, popular); showSearchBox = false"
              ) {{ popular.name }}
      template(v-else)
        template(v-if="filteredCategoryList.length > 0")
          .suggestion__category
            p.suggestion__title Categories
            .suggestion__list
              template(
                v-for="(category, index_category) in filteredCategoryList"
              )
                button.suggestion__item(
                  @click="setCategory(category); showSearchBox = false; doTrackUserSearch('category')"
                ) {{ category.name }}
        template(v-if="filteredTagList.length > 0")
          .suggestion__tag
            p.suggestion__title Tag
            .suggestion__list
              template(v-for="(tag, index_tag) in filteredTagList")
                button.suggestion__item(
                  @click="setTag(tag); showSearchBox = false; doTrackUserSearch('tag')",
                  type="button"
                ) {{ tag.name }}
</template>

<script>
// What does search do?
// Call to 'https://staging-v3.offeo.com/api/search-cat-tags/elements/graphics' return array of 'categories' or 'tags' that can be searched on.
// Categories are already being listed, tags are a new type of data collection

// Selecting a 'category' from the search list is the same as selecting it from the listing
// Can then use the 'x' to remove the category and go back to the sub-group/category view
// If selecting a category, it must be show in the search bar except if it is a generic category like 'all'
// Selecting a 'tag' will make a call where the tag is a 'sub-group'
// 'https://staging-v3.offeo.com/api/tab-search/elements/graphics/backup?categories%5B%5D=all'
// The cateogry will always be 'all'

// Search for someting not there === 'No available element'
// https://staging-v3.offeo.com/api/tab-search/backgrounds/images/basic?categories%5B%5D=all

// Clear search when search bar not visible on the screen so that value does not get used else where

import {
  mapGetters, mapActions, mapMutations, mapState,
} from 'vuex';
import vClickOutside from 'v-click-outside';

import { debounce } from 'debounce';
import { groups } from '@/assets/scripts/enums';

import categoryMixins from '@/components/mixins/category-mixins';

import Api from '@/services/api/Api';

import {
  canvasSizesArray,
} from '@/assets/scripts/variables';

export default {
  name: 'AssetsSearch',
  mixins: [categoryMixins],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    isLight: { type: Boolean, default: false },
  },
  data() {
    return {
      searchFocused: false,
      searchTerm: '',
      searchResultsCategory: [],
      searchResultsTag: [],
      categoryList: [],
      tagList: [],
      showSearchBox: false,
      popularCategories: [
        {
          type: 'category',
          name: 'E-Commerce',
          slug: 'e-commerce',
          value: 3,
        },
        {
          type: 'category',
          name: 'Intros, Outros and Logos',
          slug: 'intros-outros-and-logos',
          value: 2,
        },
        {
          type: 'tag',
          name: 'Slideshow',
          value: 'slideshow',
        },
        {
          type: 'category',
          name: 'Important Days',
          slug: 'important-days',
          value: 26,
        },
        {
          type: 'tag',
          name: 'Food',
          value: 'food',
        },
        {
          type: 'category',
          name: 'Technology',
          slug: 'technology',
          value: 17,
        },
        {
          type: 'category',
          name: 'Beauty',
          slug: 'beauty',
          value: 20,
        },
        {
          type: 'category',
          name: 'Fashion',
          slug: 'fashion',
          value: 25,
        },
      ],
      showPopularCategories: false,
      isSearching: false,
    };
  },
  computed: {
    ...mapState(['showSwap', 'showDesignContainer']),
    ...mapGetters('assetsSidebar', [
      'getActiveGroup',
      'getActiveKeyword',
      'getActiveSubGroup',
      'getActiveCategory',
      'getSearchElements',
      'getMusicCategories',
      'getSidebarCategories',
      'getStockEndpointVersion',
    ]),
    ...mapGetters('canvasElements', []),
    filteredCategoryList() {
      return this.categoryList.filter(category => category.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
    filteredTagList() {
      return this.tagList.filter(tag => tag.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
    isNotMedia() {
      return this.getActiveGroup !== groups.MEDIA;
    },
    isCategorySearch() {
      // console.log('isCategorySearch', this.getActiveCategory.id);
      return (
        typeof this.getActiveCategory.id !== 'undefined' && this.getActiveCategory.id !== -1
      );
    },
    isSearchable() {
      return this.showSwap ? this.getActiveSubGroup !== 'effects' && this.getActiveSubGroup !== 'graphics' : true;
    },
    isNotMyFiles() {
      return this.getActiveGroup !== groups.MYFILES;
    },
    isNotShutterstockMusic() {
      return !(
        this.getStockEndpointVersion === 'v2' && this.getActiveGroup === groups.MUSIC
      );
    },
    isOnMusicTab() {
      return this.getActiveGroup === groups.MUSIC;
    },
  },
  methods: {
    ...mapActions('assetsSidebar', ['setSearchElements']),
    ...mapMutations('assetsSidebar', [
      'setActiveCategory',
      'clearActiveCategory',
      'setActiveKeyword',
      'clearActiveKeyword',
      'setTagSearch',
      'setShowDesignPreloader',
    ]),
    // filteredSearch(val) {
    //   if (String(val).length === 0 || !val) return [];

    //   console.log('filteredSearch val', val);

    //   const regexp = new RegExp(`.*${val.toLowerCase()}.*`, 'i');
    //   const elementGroup = this.getSearchElements[`${this.getActiveGroup}/${this.getActiveSubGroup}`];
    //   this.searchTerm = val;

    //   if (elementGroup) {
    //     return elementGroup.filter(element => regexp.test(`${element.name.toLowerCase()}`));
    //   }
    //   return [];
    // },
    // showSearchBox() {
    //   if (this.getActiveGroup === 'media' || this.getActiveGroup === 'music') {
    //     this.searchFocused = false;
    //   } else {
    //     this.searchFocused = true;
    //   }
    // },
    // hideSearchBox() {
    //   // TODO : Create better solution
    //   // If the blur causes the search box to be hidden immediately,
    //   // then the click handler of the search item will not fire
    //   setTimeout(() => {
    //     this.searchFocused = false;
    //   }, 150);
    // },
    clearSearch() {
      this.searchTerm = '';
      this.clearActiveKeyword();
      if (!this.showDesignContainer) {
        if (this.getActiveGroup === groups.MUSIC || this.getActiveGroup === groups.MEDIA) {
          this.clearActiveCategory();
        } else {
          this.clearActiveCategory();
        }

        if (this.getActiveGroup === groups.ELEMENTS) {
          if (this.showSwap) {
            this.clearActiveCategory();
          }
        }
      }
      this.showSearchBox = false;
    },
    setCategory(item) {
      // if this is design search,
      // let DesignFilters does the job
      console.log('setCategory', item);
      this.clearActiveCategory();
      if (this.showDesignContainer) {
        this.searchTerm = '';
        this.clearActiveKeyword();
        this.$root.$emit('set-design-category', item);
      } else {
        this.setShowDesignPreloader(true);
        this.searchTerm = item.name;
        this.clearActiveKeyword();
        this.elementType = item.type;
        const activeCategoryData = this.getActiveCategoryData({
          id: item.value,
          slug: item.name,
          meta: item.meta || [],
        });
        this.setActiveCategory(activeCategoryData);
        this.setTagSearch(false);
      }
    },
    setTag(item) {
      console.log('setTag', item);
      // this.searchTerm = item.name;
      // this.setActiveKeyword(item.value);
      // this.clearActiveCategory();
      // https://offeo.com/api/tab-search/elements/graphics/cat?categories%5B%5D=all
      // this.setShowDesignPreloader(true);
      this.clearActiveCategory();
      if (this.showDesignContainer) {
        this.showSearchBox = false;
      }

      if (typeof item === 'string') {
        this.searchTerm = item;
        this.setActiveKeyword(item);

        if (!this.showDesignContainer) {
          this.setActiveCategory({ id: -1, slug: item.toLowerCase() });
        }
      } else {
        this.searchTerm = item.name;
        this.setActiveKeyword(item.value);

        if (!this.showDesignContainer) {
          this.setActiveCategory({ id: -1, slug: item.name.toLowerCase() });
        }
      }

      this.setTagSearch(false);
    },
    runSearch(word) {
      if (this.searchTerm.trim().length === 0) return;

      if (this.getActiveGroup === 'media') {
        this.searchTerm = word.toLowerCase();
        this.setActiveKeyword(word.toLowerCase());
      } else {
        this.setTag(word.toLowerCase());
      }
      console.log('RUN SEARCH FUNCTION');
      this.isSearching = true;
      this.$emit('is-searching', true);
    },
    // eslint-disable-next-line func-names
    doShowSearchBox: debounce(function () {
      if (this.isSearching) return;

      // eslint-disable-next-line
      if (this.searchTerm.length != '') {
        this.showSearchBox = true;
        this.showPopularCategories = false;
      } else {
        this.showSearchBox = false;
        this.clearSearch();
      }
    }, 600),
    doRetrieveCategoriesTags() {
      if (
        this.getActiveGroup !== groups.MEDIA
        && this.getActiveGroup !== groups.TEXTS
        && this.getActiveGroup !== groups.MYFILES
        && this.getActiveGroup !== groups.FAVOURITES
        && this.isNotShutterstockMusic
      ) {
        const params = {
          tab: this.getActiveGroup,
          menu: this.getActiveSubGroup,
        };

        Api.searchCategoryTags(params)
          .then((response) => {
            if (response.data.success) {
              const { results } = response.data;
              const typeCategory = 'category';
              const typeTag = 'tag';

              this.categoryList = results.filter(result => typeCategory.includes(result.type.toLowerCase()));
              this.tagList = results.filter(result => typeTag.includes(result.type.toLowerCase()));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    doTrackUserSearch(type) {
      if (this.searchTerm.trim().length === 0) {
        return;
      }

      const navItem = this.getActiveGroup;
      const tabItem = this.getActiveSubGroup;
      const keyword = this.searchTerm;

      const params = {
        nav_item: navItem,
        tab_item: tabItem,
        type,
        keyword,
      };

      Api.userSearch(params)
        .then(() => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onClickOutsideSearchBox() {
      this.showSearchBox = false;
    },
    doShowPopularCategories() {
      if (this.searchTerm.trim() === '') {
        this.showSearchBox = true;
        this.showPopularCategories = true;
      } else {
        this.showPopularCategories = false;
      }
    },
    doSearchPopularCategories(type, value) {
      if (type === 'category') {
        this.setCategory(value);
      } else {
        this.setTag(value);
      }
    },
  },
  watch: {
    getActiveKeyword() {
      this.searchTerm = this.getActiveKeyword;
    },
    // searchTerm() {
    // this.categoryList = this.filteredSearch(val).filter(element => element.type === 'category');
    // this.tagList = this.filteredSearch(val).filter(element => element.type === 'tag');
    // },
    getActiveCategory: {
      handler(val) {
        // console.log('get active category', val, val.slug, this.getSidebarCategories[this.getActiveSubGroup]);
        if (this.getTagSearch === true) return;

        if (val.slug && val.slug.toLowerCase() !== 'all') {
          const activeCategoryGroup = this.getSidebarCategories[this.getActiveSubGroup];
          const isMusic = this.getActiveGroup === 'music';
          // console.log('activeCategoryGroup', activeCategoryGroup, this.getActiveSubGroup);

          if (isMusic || (activeCategoryGroup && activeCategoryGroup.categories)) {
            // music has different categories
            const categories = isMusic ? this.getMusicCategories : activeCategoryGroup.categories;
            const activeCategory = categories.find(cat => cat.slug === val.slug);
            if (activeCategory !== undefined) {
              this.searchTerm = activeCategory.name;
            } else {
              this.searchTerm = val.slug;
            }
          }
        }
        // console.log('getActiveCategory:watcer', this.searchTerm);
      },
      deep: true,
      immediate: true,
    },
    getActiveGroup() {
      this.setSearchElements();
      this.doRetrieveCategoriesTags();
    },
    getActiveSubGroup(val) {
      // console.log('get active subgroup', val)
      if (!this.showSwap) {
        if (this.isSearchable && !canvasSizesArray.includes(val)) {
          this.searchTerm = '';
          this.clearActiveKeyword();
        }
      } else if (val !== 'graphics' && val !== 'images') {
        this.searchTerm = '';
        this.clearActiveKeyword();
      }
    },
    // showSwap: {
    //   handler(showSwap) {
    //     if (showSwap) {
    //       console.debug('settingSearchTerm');
    //       // [this.searchTerm] = this.getSwappedElement.sub_category_name;
    //     }
    //   },
    //   immediate: true,
    // },
    isSearching: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.isSearching = false;
            this.$emit('is-searching', false);
          }, 2000);
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.$root.$off('doRetrieveCategoriesTags', this.doRetrieveCategoriesTags);
  },
  mounted() {
    this.setSearchElements();
    this.doRetrieveCategoriesTags();

    this.$root.$on('doRetrieveCategoriesTags', this.doRetrieveCategoriesTags);

    if (this.getActiveKeyword) {
      this.searchTerm = this.getActiveKeyword;
    }
  },
};
</script>

<style lang="scss" scoped>
.assets__search-container {
  width: 100%;
  margin: 0;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  z-index: 3;

  &.is-light {
    width: 300px;
    padding: 0;
    border-radius: 4px;
    margin-right: 10px;

    .tab-search {
      height: 40px;
      background: $light;
      border: 1px solid $lightGrey400;
    }

    .form-search-suggestion {
      top: 41px;
      z-index: 4;
    }

    .form-input,
    .btn-sidebar-search,
    .btn-clear-keyword {
      color: $darkGrey;
    }
  }

  // so the :focus styling will not apply in the light mode
  &:not(.is-light) > .tab-search {
    // :focus-within can only apply to the parent (.tab-search) of input, can't be the grandparent (.assets__search-container)
    &:focus-within {
      border: 1px solid transparent;
      background: rgba($lightGrey6, 0.3);

      .form-input {
        &::-webkit-input-placeholder {
          color: $light;
        }
      }

      .btn-sidebar-search,
      .btn-clear-keyword {
        color: $light !important;
      }
    }
  }

  .tab-search {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $darkGrey700;
    background: $darkGrey600;
    border-radius: 4px;
    padding: 8px 10px;
    margin-right: 10px;

    @include smallest() {
      font-size: 0.75em;
    }

    &.is-category-search {
      &:before {
        content: 'Category:';
        background: $blue;
        color: #fff;
        padding: 2px 5px;
        border-radius: 5px;
        font-size: 12px;
        position: absolute;
        left: 5px;

        @include smallest() {
          font-size: 0.75em !important;
        }
      }

      .form-input {
        padding-left: 65px;

        @include smallest() {
          padding-left: 55px;
        }
      }
    }

    &.tab-search--full-width {
      margin-right: 0;
    }
  }

  .btn-choose-category {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 10px;
    border-color: #fff;
    font-size: 0.75em;

    &.is-active,
    &:hover {
      border-color: $blue;
    }
  }

  .form-input {
    flex: 1 auto;
    border: 0;
    border-radius: 4px;
    font-size: 0.75rem;
    background-color: transparent;
    color: $lightWhite;
    outline: none;
    width: 100%;
    padding-right: 30px;

    &::-ms-clear {
      display: none;
    }
  }

  .form-input::placeholder {
    color: $darkGrey100;
  }

  select.form-input {
    // eslint-disable-next-line max-len
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAICAYAAAArzdW1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAClSURBVHgBfU4xCsJAENw94+FbbFLZ6yO00EZbbeIP9AUaVLCMoCCoGH9gqV1Ky3tCKhE8d80FLkVIMsXu7Oyws9gbTDxmdqEExOLmEGKEAIsy04/0XJz36zswBEWGJGEWHrdKmOEr69OkxTmH0m/pG1oz5RU9Pk231UDAdvYLonc9rZ6GCytqR/rIoOyVy2Gzs7vMFAbLmASO0niiDlSh2x8P89ofCU451UdA2WoAAAAASUVORK5CYII=');
  }

  .btn-clear-keyword {
    position: absolute;
    right: 32px;
    top: 9px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    font-size: 0.9375rem;
    color: $darkGrey100;
    background: transparent;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;

    .canvas-modal & {
      top: 12px;
    }

    &:hover {
      color: $blue;
    }

    &.is-show {
      opacity: 1;
      pointer-events: auto;
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      color: $btnDisabled;
    }
  }

  .btn-sidebar-search {
    background: transparent;
    color: $darkGrey100;
    padding: 0;
    font-size: 1.125rem;
    border-radius: 0;
    transition: color 0.25s ease-in-out;

    &:hover {
      color: $blue;
      background-color: transparent;
      box-shadow: none;
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      color: $btnDisabled;
    }
  }
}

.form-search-suggestion {
  position: absolute;
  background: var(--white-darkgrey900);
  top: 33px;
  width: 100% !important;
  left: 0;
  padding: 0;
  max-height: 400px;
  overflow: auto;
  z-index: 1;
}

.suggestion__title {
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 15px;
  padding: 0 10px;
  color: var(--darkgrey800-white);
  font-size: 0.85rem;
}

.suggestion__item {
  width: 100%;
  padding: 5px 30px;
  font-family: $baseFont;
  background: var(--white-darkgrey900);
  color: $grey;
  font-size: 0.75rem;
  text-align: left;
  cursor: pointer;
  transition: background 0.25s ease-in-out;
  text-transform: capitalize;

  &:hover {
    background: var(--blue700-green700);
    color: $light;
  }
}
</style>
