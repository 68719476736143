<template lang="pug">
.tab__head.tab--sub-groups(
  :class="{'tab--full-width': isOnMusicTab}"
)
  template(v-for="item in groups[getActiveGroup]")
    SubGroupsButton(
      :itemKey="item.key"
      :itemValue="item.value"
      :itemIcon="item.iconClass"
      :groups="groups"
      :activeSwap="activeSwap"
    )
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { groups } from '@/assets/scripts/enums';

import SubGroupsButton from './children/SubGroupsButton.vue';

export default {
  name: 'SubGroups',
  components: {
    SubGroupsButton,
  },
  data() {
    return {
      groups: {
        designs: [{ key: 'all', value: 'All' }],
        texts: [],
        elements: [
          { key: 'graphics', value: 'Graphics' },
          { key: 'overlays', value: 'Overlays' },
          { key: 'effects', value: 'Effects' },
        ],
        brand: [
          { key: 'logos', iconClass: 'icon-logo-menu', value: 'Team Logo' },
          { key: 'color', iconClass: 'icon-color', value: 'Team Color' },
          { key: 'font', iconClass: 'icon-text-menu1', value: 'Team Font' },
          // note - hide for now after clarification with Jerome { key: 'graphics', iconClass: 'icon-graphics-menu', value: 'Team Graphics' },
          // note - hide for now after clarification with Jerome { key: 'templates', iconClass: 'icon-template-menu', value: 'Team Templates' },
        ],
        media: [
          { key: 'photo', value: 'Photos' },
          { key: 'video', value: 'Videos' },
        ],
        backgrounds: [
          { key: 'colour', value: 'Colour' },
          { key: 'videos', value: 'Animated' },
          { key: 'images', value: 'Images' },
        ],
        music: [
          { key: 'music', value: 'Library' },
          { key: 'upload', value: 'My Tracks' },
        ],
        'my-files': [
          { key: 'images', value: 'Images' },
          { key: 'videos', value: 'Videos' },
        ],
        favourites: [],
        size: [],
      },
      activeSwap: '',
    };
  },
  computed: {
    ...mapState(['showMultipleSwap', 'showSwap']),
    ...mapGetters('canvasElements', ['getSwappedElement']),
    ...mapGetters('assetsSidebar', ['getActiveGroup', 'getActiveSubGroup', 'getCustomElements']),
    ...mapGetters('team', ['isInTeamWorkspace']),
    isOnMusicTab() {
      return this.getActiveGroup === groups.MUSIC;
    },
  },
  methods: {
    ...mapMutations('assetsSidebar', ['setActiveSubGroup']),
    updateActiveSwap() {
      if (this.showSwap) {
        const { type } = this.getSwappedElement;
        this.activeSwap = type;
        console.debug('showSwap:true', this.activeSwap);
      } else {
        this.activeSwap = '';
        console.debug('showSwap:false', this.activeSwap);
      }
    },
  },
  mounted() {
    this.updateActiveSwap();

    // Show custom subgroup if available
    if (this.getCustomElements.length > 0) {
      this.groups.elements.push({ key: 'customElements', value: 'Custom' });
    }

    if (this.isInTeamWorkspace) {
      const teamMusicNav = { key: 'team-upload', value: 'Team Tracks' };
      this.groups.music.push(teamMusicNav);
    }
  },
  watch: {
    getActiveGroup: {
      handler(value) {
        // console.log('SUBGROUPS get active subgroup', value, this.getActiveSubGroup)
        let subgroup = '';
        // what happen when the tab is changed
        if (this.showSwap) {
          // When there is swap, open the subgroup depending on the type
          const { type } = this.getSwappedElement;

          if (type === 'images' || type === 'masks') {
            if (value === 'media') subgroup = 'photo';
            else if (value === 'backgrounds' || value === 'my-files') subgroup = 'images';
          } else if (type === 'videos') {
            if (value === 'media') subgroup = 'video';
            else if (value === 'backgrounds' || value === 'my-files') subgroup = 'videos';
          }
        } else if (this.showMultipleSwap) {
          if (value === 'media') subgroup = 'photo';
          else subgroup = 'images';
        } else if (this.groups[value].length) {
          // if the existing subgroup is already selected, dont change it
          /* eslint-disable */
          if (
            this.groups[value].findIndex(subgroup => subgroup.key === this.getActiveSubGroup) > -1
          ) {
            return;
          }
          /* eslint-enable */
          // When a new 'Group' is selected, select the first category as the 'Category'
          subgroup = this.groups[value][0].key;
        }

        console.log('getActiveGroup', value, subgroup);
        this.setActiveSubGroup(subgroup);
      },
      immediate: true,
    },
    showSwap: {
      handler() {
        this.updateActiveSwap();
      },
      immediate: true,
    },
    showMultipleSwap(value) {
      if (value) {
        this.activeSwap = 'images';
      } else {
        this.activeSwap = '';
      }
    },
  },
};
</script>

<style lang="scss">
.tab {
  height: 100%;
}

.tab__head {
  display: flex;
  flex-wrap: nowrap;

  &.tab--sub-groups {
    width: calc(100% - 10px) !important;

    .tab__item {
      transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;

      &:not(.nav--active) {
        color: $darkBlue5;
        border-color: $darkBlue5;
        font-weight: 400;

        &:hover {
          color: $light;
          border-color: $darkGrey5;
          background: $darkGrey5;
        }
      }
    }
  }

  &.tab--full-width {
    width: 100% !important;
  }

  .tab__item {
    color: $lightGrey400;
    background: transparent;
    text-transform: capitalize;
    font-weight: 500;
    margin-right: 5px;
    width: 33%;
    padding: 10px 8px;

    &:last-of-type {
      margin-right: 0;
    }

    &.tab__item--2-column {
      width: 50%;
      padding: 10px;
    }

    .btn__circle {
      display: none;
    }
  }
}

.tab__body {
  height: calc(100% - 32px);
  overflow: hidden;
  position: relative;

  .tab__item {
    background: transparent;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    transition: none;
    width: 100%;

    &.is-active {
      transform: translateX(0);
      position: relative;
    }
  }
}
</style>
