<template lang="pug">
.swap-scene__image
  .swap-scene__preloader(
    v-if="isImgLoading"
  )
    BasePreloader
  Drop(
    @drop="handleDrop"
  )
    img(
      ref="img"
      :src="imageUrl"
    )
    span.swap-scene__text
      | Drag and drop
      br
      | from library
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { Drop } from 'vue-drag-drop';
import { groups, subgroups } from '@/assets/scripts/enums';
import Api from '@/services/api/Api';
import BasePreloader from '@/components/UI/BasePreloader.vue';

export default {
  name: 'SwapSceneImage',
  data() {
    return {
      imageUrl: '',
      isImgLoading: true,
    };
  },
  props: {
    elementId: { type: String, default: '' },
  },
  components: {
    BasePreloader,
    Drop,
  },
  computed: {
    ...mapState('canvasElements', [
      'shutterstockLoadingIds',
    ]),
    ...mapGetters('canvasElements', [
      'getCanvasElementById',
    ]),
    ...mapGetters('assetsSidebar', [
      'getActiveGroup',
      'getActiveSubGroup',
      'getShutterstockSearchId',
      'getStockEndpointVersion',
    ]),
    isMask() {
      return this.getCanvasElementById(this.elementId).type === 'masks';
    },
    isShutterstockAsset() {
      return (this.getActiveSubGroup === 'photo' || this.getActiveSubGroup === 'video') && this.getStockEndpointVersion === 'v2';
    },
  },
  mounted() {
    if (this.isMask) {
      this.imageUrl = this.getCanvasElementById(this.elementId).data.image.url;
    } else {
      this.imageUrl = this.getCanvasElementById(this.elementId).data.url;
    }
    this.$nextTick(() => {
      this.concernPreloader();
    });
  },
  methods: {
    ...mapMutations('canvasElements', [
      'updateSingleMultipleSwappedElements',
      'updateShutterstockElement',
      'updateShutterstockLoadingIds',
      'removeShutterstockLoadingId',
    ]),
    handleDrop(data) {
      if (data.elementData.isImage) {
        this.isImgLoading = true;
        setTimeout(() => {
          console.table(data.elementData.data);
          const srcId = data.elementData.data.src_id || null;
          let isNewShutterstockAsset = false;

          const imageData = {
            id: this.elementId,
            url: data.elementData.data.url,
          };

          if (typeof data.elementData.data.thumb !== 'undefined') {
            imageData.thumb = typeof data.elementData.data.thumb === 'object' ? data.elementData.data.thumb.href : data.elementData.data.thumb;
          }

          if (typeof data.elementData.data.urlHd !== 'undefined') {
            imageData.urlHd = data.elementData.data.urlHd;
          }

          imageData.has_removed_bg = !!data.elementData.data.has_removed_bg;

          if (this.isShutterstockAsset) {
            isNewShutterstockAsset = typeof this.shutterstockLoadingIds[srcId] === 'undefined';
            this.updateShutterstockLoadingIds({
              srcId,
              elementId: this.elementId,
            });
          }

          console.log('drop', data.elementData.data.has_removed_bg, srcId, this.isShutterstockAsset, isNewShutterstockAsset);
          this.captureElement(data.elementData, isNewShutterstockAsset);

          if (!this.isShutterstockAsset) {
            this.imageUrl = data.elementData.data.url;
            this.updateSingleMultipleSwappedElements(imageData);
            this.concernPreloader();
          }
        }, 0);
      }
    },
    async captureElement(elementData, isNewShutterstockAsset) {
      if (this.getActiveGroup === groups.MEDIA
        && (subgroups.PHOTOS === this.getActiveSubGroup || subgroups.VIDEOS === this.getActiveSubGroup)) {
        // is stock media
        // capture assets
        const { data } = elementData;
        const params = {
          src_id: elementData.data.src_id,
        };

        if (this.getStockEndpointVersion === 'v1') {
          params.data_url = data.url;
          params.data_urlhd = data.urlHd || '';

          Api.captureAssets(params, this.getStockEndpointVersion);
        } else if (this.getStockEndpointVersion === 'v2') {
          await this.captureShutterstockAssetAndUpdateElement(params, this.elementId, isNewShutterstockAsset, elementData);
        }
      }
    },
    async captureShutterstockAssetAndUpdateElement(defaultParams, elementId, isNewShutterstockAsset = false, data) {
      const elementData = { ...data };
      const params = { ...defaultParams };
      const srcId = params.src_id;
      if (this.getActiveSubGroup === 'photo') params.type = 'image';
      else if (this.getActiveSubGroup === 'video') params.type = 'video';
      params.search_id = this.getShutterstockSearchId;

      this.$root.$emit('capture-ss-asset', { id: params.src_id, type: params.type, elementData });

      const assetData = await Api.captureAssets(params, this.getStockEndpointVersion);

      const imageData = {
        id: elementId,
        url: assetData.data.results.url,
        urlHd: assetData.data.results.urlHd,
        thumb: assetData.data.results.thumb,
      };

      if (assetData.data.results.exists) {
        this.imageUrl = assetData.data.results.url;
        this.updateSingleMultipleSwappedElements(imageData);
        this.concernPreloader();

        // Need to add faux delay to prevent Shutterstock watermark from appearing if image hasn't loaded before
        if (!isNewShutterstockAsset) {
          this.postShutterstockLoading(elementId, srcId, params.type);
        } else {
          setTimeout(() => {
            this.postShutterstockLoading(elementId, srcId, params.type);
          }, 1500);
        }
      }
    },
    postShutterstockLoading(elementId, srcId, type) {
      this.removeShutterstockLoadingId({ elementId, srcId });
      this.$root.$emit('capture-ss-asset-done', { id: srcId, type });
    },
    concernPreloader() {
      this.$refs.img.addEventListener('load', () => {
        this.isImgLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.swap-scene__image {
  flex: 1 16.6%;
  position: relative;
  max-width: calc(16.6% - 10px);
  margin: 5px;
  background-repeat: repeat;
  padding: 10px;
  border-radius: 8px;
  font-size: 12px;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
  }

  &:hover {
    .swap-scene__text {
      opacity: 1;
    }
  }

  .swap-scene__preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: var(--lightgrey400-darkgrey600);
  }
}

.swap-scene__text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  color: $light;
  background: rgba(0, 0, 0, 0.7);
  margin: auto;
  padding: 10px;
  font-weight: 600;
  line-height: 1.25;
  font-size: 1.25em;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  pointer-events: none;
  white-space: normal;
  border-radius: 8px;
}
</style>
